<div class="userInfo-container">
    <div class="profileCard-container">
        <div class="profileImg">
            <img src="{{ selectedUser?.profilePic ? signedProfileUrl : 'https://ediindia.ac.in/alumni/web/profile.jpg'}}"
                alt="profile">
        </div>
        <div class="userDetails">
            <p class="userName">{{ selectedUser?.first_name || '-' }} {{ selectedUser?.last_name || '-' }}</p>
            <div class="userInfo">
                <p class="userInfo-text"><em class="pi pi-id-card"></em> {{ selectedUser?.uuid || '-' }}</p>
                <span class="divider"></span>
                <p class="userInfo-text"><em class="pi pi-at"></em> {{ selectedUser?.email || '-' }}</p>
                <span class="divider"></span>
                <p class="userInfo-text"><em class="pi pi-phone"></em> +91 {{ selectedUser?.mobile_number || '-' }}</p>
            </div>
        </div>
    </div>

    <!-- Personal Information -->
    <div class="userInfo-content">
        <div class="info-card personal-info">
            <p class="heading">Personal Info</p>
            <div class="info-grid">
                <div class="info-item">
                    <p class="info-field">Gender: <span class="info-value">{{ selectedUser?.gender ?? '-' }}</span></p>
                </div>
                <div class="info-item">
                    <p class="info-field">Date of Birth: <span class="info-value">
                        {{ selectedUser?.date_of_birth ? (selectedUser.date_of_birth | date: dateFormat) : '-' }}
                    </span></p>
                </div>
            </div>
        </div>
        
        

        <!-- Event Details Section -->
        <div class="info-card event-info" *ngIf="eventDetails.length; else noEvents">
            <p class="heading">Event Details</p>
            <div class="event-list">
                <div *ngFor="let event of eventDetails" class="event-box" (click)="onEventClick(event.eventId, selectedUser.uuid)">
                    <div class="event-header">
                        <p class="event-title">{{ event.field }}</p>
                    </div>
                    <div class="event-details">
                        <div *ngFor="let detail of event.details" class="event-item">
                            <p class="event-field">{{ detail.field }}</p>
                            <p class="event-value">{{ detail.value }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noEvents>
            <p class="no-events">No events found for this user.</p>
        </ng-template>        
    </div>
</div>
