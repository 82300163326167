import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { FileUploadService } from '../services/file-upload.service';
import { OtpService } from '../services/otp.service';
import { nanoid } from 'nanoid';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  @Input() companyLogo: string = '';

  passwordShow: boolean = false;
  height: string = window.innerHeight.toString() + 'px';
  isLoading: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  loginSection = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: new FormControl('', [Validators.required, Validators.minLength(1)])
  });
  invalidCred: boolean = false;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  sourceImg: string = '';
  coverImage: string = '';
  loginEmail: string = '';
  clicked: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  forgetPassword: boolean = false;
  schoolData: any;
  userDetails: any;
  routingDetails: any;
  privilegeData: any;
  constructor(
      private loginService: LoginService, 
      private globalService: GlobalServicesService, 
      private messageService: MessageService, 
      private otpService: OtpService,
      private cookieService: CookieService
    ) {
  }

  ngOnInit(): void {
    this.schoolData = localStorage.getItem('schoolData')
    ? JSON.parse(localStorage.getItem('schoolData') || '')
    : '';
    try {
      this.loginService.metadataFastApi().subscribe({
        next: (response: any) => {
          if (response.body?.company_details) {
            this.coverImage = response.body?.company_details.cover_image;
            this.sourceImg = response.body?.company_details.logo;
            localStorage.setItem('companyUUID', response.body?.company_details.uuid);
            // this.cookieService.set('company-uuid', response.company_details.uuid, 1, '/');
          }
        },
        error: (_err: any) => {
          console.error('Error fetching company metadata:', _err);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  onResize() {
    this.height = "100vh";
  }

  toggleShowHidePwd() {
    let x: any = document.getElementsByClassName("passwordInput")[0];
    if (x.type === "password") {
      x.type = "text";
      this.passwordShow = true;
    } else {
      x.type = "password";
      this.passwordShow = false;
    }
  }

  signIn() {
    this.clicked = true;
    this.loginSection.get('email')?.markAsTouched();
    this.loginSection.get('password')?.markAsTouched();
    if (this.loginSection.valid) {
      this.isLoading = true;
      let payload: any = {
        email: this.loginSection.value.email.toLowerCase(),
        password: this.loginSection.value.password
      }
      console.log("🚀🔥 ~ file: login.component.ts:100 ~ LoginComponent ~ signIn ~ payload:", payload)
      this.loginService.loginFastApi(payload).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.otpRequired = true;
          this.clicked = false;
          this.loginEmail = this.loginSection.value.email;
          console.log('response', response);
        },
        error: () => {
          this.invalidCred = true;
          this.isLoading = false;
        }
      });
    }
  }
}
