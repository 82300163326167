<div class="department-container">
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div class="department-header">
        <div class="pageInfo">
            <div style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
                <div style="display: flex;justify-content:space-between; align-items: center;">
                    <em class="pi pi-sitemap"></em>
                    <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Departments
                    </p>
                </div>
                <div *ngIf="true">
                    <button style="height:38px; font-size:var(--fs-medium)" pButton pRipple type="submit"
                        label="Add New" (click)="openAddTemplate()"></button>
                </div>
            </div>
        </div>
        <p-sidebar *ngIf="addDepartmentSidebar" class="addDepartment-slider" [(visible)]="addDepartmentSidebar"
            styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
            (onHide)="departmentDetails.reset(); showPlaceholder = false">
            <div class="addDepartment-header">
                <div class="addDepartment-heading"><em style="margin-right: 12.5px;" class="pi pi-map-marker"></em>Add
                    Department</div>
                <div class="header-btn">
                    <button pButton pRipple type="submit" label="Cancel" (click)='cancelDepartment()'
                        class="p-button-outlined button-align cancel-btn"></button>
                    <button pButton pRipple type="submit" label="Save" (click)="saveDepartment()"
                        class="p-button-raised p-button-text save-align" [loading]="saveBtnLoading"></button>
                </div>
            </div>
            <p-divider class="p-divider"></p-divider>
            <form class="departmentForm" [formGroup]="departmentDetails" (ngSubmit)="saveDepartment()">
                <span class="radioButtons">
                    <p-radioButton value="department" formControlName="departments" [(ngModel)]="selectedCategory">
                    </p-radioButton><label for="department">Department</label>
                    <p-radioButton value="sub-department"  formControlName="departments" [(ngModel)]="selectedCategory">
                    </p-radioButton><label for="sub-department">Sub-Department</label>
                </span>
                <div class="radio-content" *ngIf="selectedCategory == 'department'">
                    <span class="p-float-label">
                        <input type="text" id="inputtext" pInputText class="detailsList" 
                        [(ngModel)]="contentDetails" formControlName="departmentInput" placeholder="Enter Department Name">
                        <em class="pi pi-plus addIcon-plus"
                        (click)="addDepartmentName()"></em>
                    </span>
                    <div class="deleteDep" *ngFor="let department of departmentCopy; index as i">
                        <div id="displayDep">
                            <div class="deleteContainer">
                                <span class="Delete">
                                        <label style="padding-left: 15px;
                                        font-size: 14px;
                                        display: flex;
                                        align-items: center;
                                        margin: -2vw 6px 0 1.3vw !important;
                                        width: 25vw;">
                                        {{department.departmentName}}</label>
                                        <em class="pi pi-minus removeIcon-minus"
                                        (click)="deleteDepartmentName(i)"></em> 
                                    </span>
                            </div>
                        </div>
                        <!-- <input type="text" placeholder="{{department.departmentName}}"> -->
                    </div>
                </div>
                <div class="radio-content" *ngIf="selectedCategory == 'sub-department'">
                    <div class="subDeptContainer">
                        <span class="p-float-label">
                            <input type="text" id="subinputtext" pInputText class="subDetailsList" 
                            [(ngModel)]="subContent" formControlName="subDepartmentInput" placeholder="Enter Sub-Department Name">
                            <span class="p-input-icon-right p-float-label jobForm-elements">
                                <div class="subMainContainer">
                                    <input id="subinputtext" [ngClass]="{ 'p-filled': subDropdown }" style="width: 360px" type="text" pInputText
                                        inputId="dropdown" formControlName="subDropdown" [(ngModel)]="subDropdown" placeholder="Enter Department"
                                        (click)="subDropdownOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
                                    <em class="pi pi-angle-down dropdown"></em> 
                                </div>
                                <!-- <label for="dropdown">Primary Language<span [style]="{ color: '#E24A47' }"> *</span></label> -->
                                <p-overlayPanel #subDropdownOverlay>
                                    <ng-template pTemplate>
                                        <div class="department-options" *ngFor="let field of departmentCopy; index as i">
                                            <p (click)="subDropdown = field.departmentName!; subDropdownOverlay.hide()" class="department-options">
                                                {{ field.departmentName }}
                                            </p>
                                        </div>
                                    </ng-template>
                                </p-overlayPanel>
                            </span>
                            <em class="pi pi-plus addIcon-plus subDep"
                            (click)="addSubDepartmentName()"></em>
                        </span>
                    </div>
                    <div class="deleteDep" *ngFor="let department of departmentCopy; index as i">
                        <div class="displayDep" id="displayDep" *ngIf="department.subDepartment.length">
                            <span class="subDelete" *ngFor="let sub of department.subDepartment; index as j">
                                    <label style="display: inline-flex; align-items: center; margin-left: 20px; padding-left: 15px; font-size: 14px;">
                                    {{sub}}</label>
                                    <label style="display: inline-flex; align-items: center; margin-left: 20px; padding-left: 15px; font-size: 14px;">
                                    {{department.departmentName}}</label>
                                    <em class="pi pi-minus removeIcon-minus"
                                    (click)="deleteSubDepartmentName(i,j)"></em> 
                            </span>
                        </div>
                        <!-- <input type="text" placeholder="{{department.departmentName}}"> -->
                    </div>
                </div>
                <div class="radio-content">
                    <!-- Sub-Department -->
                </div>
            </form>
        </p-sidebar>
        <div class="info-stage">
            <div class="info-card" *ngFor="let department of departmentList; index as i">
                <div style="padding: 20px 20px 0px 20px;">
                    <div class="info-header"
                        style="padding: 20px 20px 10px 20px; display: flex; justify-content: space-between;align-items: center;">
                        <p style="font-size: var(--fs-large);font-weight:var(--fw-semi-bold)">{{department.departmentName}}</p>
                        <div>
                            <em style="cursor: pointer; margin-right: 10px;padding:10px; background-color:  rgba(50, 107, 238, 0.1);border-radius: 4px;"
                                 class="pi pi-trash" (click)="deleteDepartment(department)" ></em>
                                
                            <em style="cursor: pointer; margin: 10px;padding:10px;background-color:  rgba(50, 107, 238, 0.1);border-radius: 4px;"
                                class="pi pi-pencil" (click)="editDepartment(department)" ></em>
                        </div>
                    </div>
                    <div class="subDepartmentContainer">
                        <div class="subDepartmentDisplay" *ngFor="let subdep of department.subDepartment; index as i">
                            <p>{{subdep}}</p><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>