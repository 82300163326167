import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/services/settings.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-option-value-add',
  templateUrl: './option-value-add.component.html',
  styleUrls: ['./option-value-add.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class OptionValueAddComponent implements OnInit {
  @Input() activeComponent: string = '';

  optionValueList: any[] = [];

  isLoading: boolean = false;
  clicked: boolean = false;
  isFirstChange: boolean = true;
  addOptionSidebar: boolean = false;
  saveBtnLoading: boolean = false;
  edit: boolean = false;
  userPrivileges: Set<string> = new Set();

  _selectedInstitutionSelectType: any;

  institution_select_type: any[] = [
    { name: 'Single', value: 'single' },
    { name: 'Multiple', value: 'multiple' },
  ];
  optionValueForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required),
  });
  
  constructor(private settingService: SettingsService, private messageService: MessageService, private confirmationService: ConfirmationService, private globalService: GlobalServicesService) {
    this.initializeForm();
   }
   initializeForm() {
    this.optionValueForm = new FormGroup({
        id: new FormControl(''),
        name: new FormControl('', Validators.required),
        institution_select_type: new FormControl('', this.activeComponent === 'Event Type' ? Validators.required : null),
    });
}

  ngOnChanges(changes: SimpleChanges){
    if (this.isFirstChange) {
      this.isFirstChange = false;
      return;
    }
    if (changes['activeComponent']?.currentValue) {
      this.isLoading = true;
      this.clicked = false;
      this.fetchOptionValues();
  }
  }

  ngOnInit() {
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });
    
    if (this.activeComponent) {
      this.isLoading = true;
      this.fetchOptionValues();
    }
  }
  async fetchOptionValues() {
    let apiMethod;
    this.optionValueList = []
    switch (this.activeComponent) {
      case 'Candidate Source Name':
        apiMethod = this.settingService.getCandidateSourceNameFastApi();
        break;
      case 'Candidate Source Type':
        apiMethod = this.settingService.getCandidateSourceTypeFastApi();
        break;
      case 'Event Category':
        apiMethod = this.settingService.getEventCategoryFastApi();
        break;
      case 'Event Type':
        apiMethod = this.settingService.getEventTypeFastApi();
        break;
      case 'Role Type':
        apiMethod = this.settingService.getRoleTypesFastApi();
        break;
      default:
        this.isLoading = false; // Ensure loading state is handled
        return; // Exit if no matching component
    }
  
    if (apiMethod) {
      this.isLoading = true; // Set loading state to true before the API call
      apiMethod.subscribe({
        next: (res: any) => {
          if(res.status === 200){
          if (res.body.items) {
            this.optionValueList = res.body.items;
          } else {
            this.optionValueList = []; // Handle the case where items are not found
          }
          this.isLoading = false; // Reset loading state
        }
        },
        error: (_err) => {
          this.isLoading = false; // Reset loading state
        },
      });
    }
  }
  privilegeMap: Record<string, { create: string; update: string; delete: string }> = {
    'Candidate Source Name': {
      create: 'candidate_source.create',
      update: 'candidate_source.update',
      delete: 'candidate_source.delete',
    },
    'Candidate Source Type': {
      create: 'candidate_source.create',
      update: 'candidate_source.update',
      delete: 'candidate_source.delete',
    },
    'Event Category': {
      create: 'event_category.create',
      update: 'event_category.update',
      delete: 'event_category.delete',
    },
    'Event Type': {
      create: 'event_type.create',
      update: 'event_type.update',
      delete: 'event_type.delete',
    },
    'Role Type': {
      create: 'role_type.create',
      update: 'role_type.update',
      delete: 'role_type.delete',
    },
  };
  get canCreate(): boolean {
    const key = this.privilegeMap[this.activeComponent]?.create;
    return key ? this.userPrivileges.has(key) : false;
  }
  
  get canUpdate(): boolean {
    const key = this.privilegeMap[this.activeComponent]?.update;
    return key ? this.userPrivileges.has(key) : false;
  }
  
  get canDelete(): boolean {
    const key = this.privilegeMap[this.activeComponent]?.delete;
    return key ? this.userPrivileges.has(key) : false;
  }
    
  async addOptionValues() {
    this.saveBtnLoading = true;
    this.clicked = true;
    let optionValue;
    if (this.activeComponent === 'Event Type') {
      optionValue = { name: this.optionValueForm.value.name, institution_select_type: this.optionValueForm.value.institution_select_type };
    } else {
      optionValue = { name: this.optionValueForm.value.name };
    }
    let apiMethod;
    switch (this.activeComponent) {
      case 'Candidate Source Name':
        apiMethod = this.settingService.addCandidateSourceFastApi(optionValue);
        break;
      case 'Candidate Source Type':
        apiMethod = this.settingService.addCandidateSourceTypeFastApi(optionValue);
        break;
      case 'Event Category':
        apiMethod = this.settingService.addEventCategoryFastApi(optionValue);
        break;
      case 'Event Type':
        apiMethod = this.settingService.addEventTypeFastApi(optionValue);
        break;
      case 'Role Type':
        apiMethod = this.settingService.addRoleTypeFastApi(optionValue);
        break;
      default:
        return; // Exit if no matching component
    }
    if (apiMethod) {
      apiMethod.subscribe({
        next: (res: any) => {
          if (res.status === 201) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
            this.clicked = false;
            this.saveBtnLoading = false;
            this.addOptionSidebar = false;
            this.fetchOptionValues();
          }
        },
        error: (_error) => {
          this.clicked = false;
          this.saveBtnLoading = false;
          this.addOptionSidebar = false;
        }
      });
    }
  }
  
  async updateOptionValues() {
    let apiMethod;
    this.clicked = true;
    let payload;
    if (this.activeComponent === 'Event Type') {
      payload = { name: this.optionValueForm.value.name, institution_select_type: this.optionValueForm.value.institution_select_type };
    } else {
      payload = { name: this.optionValueForm.value.name };
    }
    switch (this.activeComponent) {
      case 'Candidate Source Name':
        apiMethod = this.settingService.updateCandidateSourceFastApi(this.optionValueForm.value.id, payload);
        break;
      case 'Candidate Source Type':
        apiMethod = this.settingService.updateCandidateSourceTypeFastApi(this.optionValueForm.value.id, payload);
        break;
      case 'Event Category':
        apiMethod = this.settingService.updateEventCategoryFastApi(this.optionValueForm.value.id, payload);
        break;
      case 'Event Type':
        apiMethod = this.settingService.updateEventTypeFastApi(this.optionValueForm.value.id, payload);
        break;
      case 'Role Type':
        apiMethod = this.settingService.updateRoleTypeFastApi(this.optionValueForm.value.id, payload);
        break;
      default:
        return; // Exit if no matching component
    }
    if (apiMethod) {
      apiMethod.subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
            this.isLoading = false;
            this.clicked = false;
            this.addOptionSidebar = false;
            this.fetchOptionValues();
          }
        },
        error: (_error) => {
          this.isLoading = false;
          this.clicked = false;
          this.addOptionSidebar = false;
        }
      });
    }
  }
  
  async deleteOptionValues(id: any) {
    let apiMethod;
    switch (this.activeComponent) {
      case 'Candidate Source Name':
        apiMethod = this.settingService.deleteCandidateSourceFastApi(id);
        break;
      case 'Candidate Source Type':
        apiMethod = this.settingService.deleteCandidateSourceTypeFastApi(id);
        break;
      case 'Event Category':
        apiMethod = this.settingService.deleteEventCategoryFastApi(id);
        break;
      case 'Event Type':
        apiMethod = this.settingService.deleteEventTypeFastApi(id);
        break;
      case 'Role Type':
        apiMethod = this.settingService.deleteRoleTypeFastApi(id);
        break;
      default:
        return; // Exit if no matching component
    }
    if (apiMethod) {
      apiMethod.subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
            this.isLoading = false;
            this.addOptionSidebar = false;
            this.fetchOptionValues();
          }
        },
        error: (_error) => {
          this.isLoading = false;
          this.addOptionSidebar = false;
        }
      });
    }
  }
  
  async getSpecificOption(id: string) {
    let apiMethod;
    switch (this.activeComponent) {
      case 'Candidate Source Name':
        apiMethod = this.settingService.getSpecificCandidateSourceFastApi(id);
        break;
      case 'Candidate Source Type':
        apiMethod = this.settingService.getSpecificCandidateSourceTypeFastApi(id);
        break;
      case 'Event Category':
        apiMethod = this.settingService.getSpecificEventCategoryFastApi(id);
        break;
      case 'Event Type':
        apiMethod = this.settingService.getSpecificEventTypeFastApi(id);
        break;
      case 'Role Type':
        apiMethod = this.settingService.getSpecificRoleTypeFastApi(id);
        break;
      default:
        return; // Exit if no matching component
    }
    if (apiMethod) {
      apiMethod.subscribe({
        next: (res: any) => {
          if (res.body.name) {
            if (this.activeComponent === 'Event Type') {
              this.optionValueForm.patchValue({ name: res.body.name, id: res.body.id, institution_select_type: res.body.institution_select_type });
            } else {
              this.optionValueForm.patchValue({ name: res.body.name, id: res.body.id });
            }
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No data found' });
          }
        },
      });
    }
  }
  openSideBar(){
    this.addOptionSidebar = true;
    this.optionValueForm.reset();
  }

  async deleteOptions(id: any, event: any) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => { // Mark this function as async
        this.isLoading = true;
        await this.deleteOptionValues(id);
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
        });
      },
    });
  }
  async saveOption(){
    this.clicked = true;
    if (this.optionValueForm.valid) {
      await this.addOptionValues();
    }
  }
  async updateOption(){
    this.clicked = true;
    if (this.optionValueForm.valid) {
      await this.updateOptionValues();
    }
  }
  editOption(option: any){
    this.openSideBar();
    this.getSpecificOption(option.id);
  }
}
