<!-- <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,500&display=swap"
    rel="stylesheet"> -->
<div class="registration-form" [ngClass]="{'hidden': otpRequired ? !otpVerified : false}"> 
    <div class="submittedMessage-overlay" *ngIf="submittedSuccessfully">
        <div class="submittedMessage">
            <div class="close-cont" (click)="closeWindow('success')">
                <div class="close-icon">&#x2715;</div>
            </div>
            <img src="../../../assets/icons-svg/success.svg" alt="Success">
            <p class="success">Success !</p>
            <p class="message">Your application has been submitted successfully.</p>
            <br>
            <strong class="message message-italic">Kindly check your email for further notifications.</strong>
        </div>
    </div>
    <div class="submittedMessage-overlay" *ngIf="emailExists || mobileExists">
        <div class="submittedMessage">
            <div class="close-cont" (click)="closeWindow('warning')">
                <div class="close-icon">&#x2715;</div>
            </div>
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">NOTE !</p>
            <!-- <p class="message">We found existing records based on your {{(emailExists && mobileExists) ? "email and mobile number" : emailExists ? "email" : mobileExists ? "mobile number" : null}}</p> -->
            <p class="message">This candidature is already registered with us!</p> <br>
            <br>
            <!-- <strong class="message message-italic">Kindly contact HR for further assistance.</strong> -->
        </div>
    </div>
    <div class="submittedMessage-page" *ngIf="offerFormSuccess">
        <div class="submittedMessage-cover_success">
            <img class="submittedMessage-cover-img" src="../../../assets/form-icons/success-banner.svg" alt="">
        </div>
        <div class="submittedMessage-logo">
            <div class="logo-magin">
                <img src="../../../assets/form-icons/success.svg" alt="">
            </div>
        </div>
        <div class="submittedMessage-info">
            <div>
                <p class="submittedMessage-info_header">Success</p>
                <p class="submittedMessage-info_note">Thank you for submitting the response.
            </div>
        </div>
    </div>
    <div class="submittedMessage-page" *ngIf="offerFormWarning">
        <div class="submittedMessage-cover_warning">
            <img class="submittedMessage-cover-img" src="../../../assets/form-icons/warning-banner.svg" alt="">
        </div>
        <div class="submittedMessage-logo">
            <div class="logo-magin">
                <img src="../../../assets/form-icons/warning.svg" alt="">
            </div>
        </div>
        <div class="submittedMessage-info">
            <div *ngIf="!invalidUrl">
                <p class="submittedMessage-info_header">Note</p>
                <p class="submittedMessage-info_note">You have already submitted the offer.<br>So you cannot submit again.</p>
            </div>
            <div *ngIf="invalidUrl">
                <p class="submittedMessage-info_header">Invalid Url</p>
                <p class="submittedMessage-info_note">Kindly verify the link</p>
            </div>
        </div>
    </div>
    <div class="submittedMessage-overlay" *ngIf="formExpired">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">NOTE !</p>
            <p class="message">Registration no longer accepted</p>
            <br>
        </div>
    </div>
    <div class="submittedMessage-overlay" *ngIf="offerFormExpired">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">NOTE !</p>
            <p class="message">Link Expired!</p>
            <br>
        </div>
    </div>
    <!-- <div class="submittedMessage-overlay" *ngIf="invalidUrl">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">Invalid URL</p>
            <p class="message">Kindly verify the link</p>
            <br>
        </div>
    </div> -->
    <div class="submittedMessage-overlay" *ngIf="existingFormSubmit">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">Form Already Submitted !</p>
            <p class="message">Kindly contact HR for further assistance</p>
            <br>
        </div>
    </div>
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div *ngIf="!hideForm && !isLoading">
        <div class="logo">
            <img class="logo-img" src={{companyLogo}} alt="" srcset="">
        </div>
        <div *ngIf="(schoolCode === 'wileyedge399' || schoolCode === 'wileyedge537' || schoolCode == 'nstech196' || schoolCode == 'kscollege408')"
            class="banner"
            [style]="{backgroundColor: this.formName == 'Registration Form' ? '#18C76F' : this.formName == 'Extended Details and Documentation Form' ? '#115C36' : this.formName == 'Registration Form Non-Technical' ? '#18C76F' : '#FFFFFF'}">
            
            <span [ngSwitch]=this.formName>
                <span *ngSwitchCase="'Registration Form'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'registration-banner.jpg'}}"
                        alt="" srcset="">
                </span>
            </span>

            <span [ngSwitch]="this.formName">
                <span *ngSwitchCase="'Extended Details and Documentation Form'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'document-banner.png'}}"
                        alt="" srcset="">
                </span>
            </span>

            <span [ngSwitch]=this.formName>
                <span *ngSwitchCase="'Offer Acceptance and Joining Confirmation Form'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'offer-acceptance-banner.jpg'}}"
                        alt="" srcset="">
                </span>
            </span>

            <span [ngSwitch]=this.formName>
                <span *ngSwitchCase="'Registration Form Non-Technical'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'registration-non-tech-banner.png'}}"
                        alt="" srcset="">
                </span>
            </span>     
        </div>
        <div style="display:flex; justify-content:center">
            <div style="max-width: 1140px; padding: 0 10vw; margin-bottom: 30px;">
                <div class="form-data" [ngClass]="{'widthAdject': (formName ==='Extended Details and Documentation Form')}">
                    <h2 *ngIf="formName ==='Extended Details and Documentation Form'" class="form-title" #title>Additional Questionnaire and Documents Submission Form</h2>
                    <h2 *ngIf="formName !=='Extended Details and Documentation Form'" class="form-title" #title>{{formName}}</h2>
                </div>

                <div *ngFor="let section of templateCollections; index as i">
                    <div class="personal-info">
                        <div *ngIf="i != 0">
                            <h3 class="personal-details_label">{{section[0].displaySection}}</h3>
                            <hr class="title-line">
                        </div>
                        <div *ngFor="let model of section">
                            <app-fields 
                                *ngIf="checkForDependancy(model)" 
                                (checkValidator)="checkValid($event, model)"
                                (fieldDataEmitter)="bindData($event, model)" 
                                [model]="model" 
                                [clicked]="clicked" 
                                [invalidField]="invalidField"
                                [inValidLocation]="inValidLocation"
                                [belowAge]="belowAge"
                                [duplicateData]="duplicateData"
                                [inEditMode]="inEditMode"
                                [ansChange]="ansChange"
                                [verifyByEmail]="verifyByEmail"
                                (enableEmailVerification)="enableEmailVerification($event)"
                                (enableMobileVerification)="enableMobileVerification($event)"
                                [p1EmailVerified]="p1EmailVerified"
                                [p1MobileVerified]="p1MobileVerified"
                                [enableMobile]="enableMobile"
                                >
                            </app-fields>
                        </div>
                    </div>
                </div>
                <button pButton pRipple type="button" label="Submit" (click)="verifyOnSubmit()" class="form-btn"
                    [loading]="submitBtnLoading"></button>
            </div>
        </div>
        <div *ngIf="schoolCode === 'wileyedge399' || schoolCode === 'wileyedge537' || schoolCode === 'nstech196'" class="footer">
            <p>Powered by mthree</p>
        </div>
    </div>
    <p-toast></p-toast>
</div>

<div *ngIf="otpRequired && !otpVerified">
    <app-otp-verification (otpVerified)="otpVerified = $event; prefillForm()" [emailToVerify]="emailToVerify" (emailVerified)="p1EmailVerified = true;" [candidateId]="candidateId" [eventId]="eventId" ></app-otp-verification>
</div>

<div class="reg-dialog">
    <p-dialog class="reg-head" header="OTP Verification" [(visible)]="otpSent" [modal]="true" [style]="{ width: '35vw'}"
     [draggable]="false" [resizable]="false" (onHide)="dialogClose()">
        <p-divider class="otp-divider" [style]="{marginTop: '0px'}"></p-divider>
        <div class="otpContainer">
            <p class="otp-content" style="margin-top: 2rem; margin-bottom: 1.3rem;">We have sent the 6 digit verification code to {{verifyChoice == 'email' ? '' : 'the mobile number'}}
                <span style="color: blue; display: block;" >{{verifyChoice == 'email' ? (emailToVerify ? emailToVerify : '') : (mobileToVerify ? '(+' + countryCode + ') ' + mobileNumber : '')}} </span></p>
            <span class="p-float-label">
                <input style="width: 100%" type="text" [(ngModel)]="otpValue" (ngModelChange)="verifyCheck($event)" 
                (keydown.enter)="(enableVerify ? verifyChoice == 'email' ? verifyEmailOtp() : verifyMobileOtp() : '')" id="inputtext" pInputText [maxlength]="6"/>
                <label for="inputtext">Enter OTP</label>
            </span>
            <div class="button-container" style="display: flex; flex-direction: column;">
                <div class="resend-info" style="display: flex; justify-content: end;margin-top: 10px;margin-bottom: 25px;">
                    <span *ngIf="waitToResend" class="resent-timer">Resend in <strong>{{countdown}}s</strong></span>
                    <span *ngIf="!waitToResend" (click)="verifyChoice == 'email' ? resendEmailOtp() : resendMobileOtp()" class="resend-btn">
                        <span *ngIf="!loadResend">Resend</span>
                        <p-progressSpinner *ngIf="loadResend" [style]="{height: '20px', width: '20px', marginRight: '30px'}" strokeWidth="7"
                            animationDuration="0.5s">
                        </p-progressSpinner>
                    </span>
                </div>
                <div class="card flex justify-content-center submit-btn mt-45" style="width: 100%;">
                    <p-button label="Verify" [loading]="verifyLoading" [disabled]="!enableVerify" [ngStyle]="{'pointer-events': (enableVerify ? 'auto' : 'none') }"
                    (click)="verifyChoice == 'email' ? verifyEmailOtp() : verifyMobileOtp()"></p-button>
                </div>
            </div>
        </div>
    </p-dialog>
</div>