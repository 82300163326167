import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { JobServicesService } from '../services/job-services.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { EventServicesService } from '../services/event-services.service';
import { FileUploadService } from '../services/file-upload.service';
import { Location } from '@angular/common';
import { TalentPoolService } from '../services/talent-pool.service';
import { GlobalServicesService } from '../services/global-services.service';
import {nanoid} from  'nanoid';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.css'],
  providers: [MessageService],
})
export class ApplicationFormComponent implements OnInit {

  degreeDetails: any;
  degreeDetailsControl: any;

  resume: File[] = [];
  profilePic: File[] = [];
  resumeBase64: any = '';
  profileBase64: any = '';

  yearOfExperience: any = '';
  jobId: string = '';
  eventId: string = '';
  jobDetails: any = {};
  jobName: string = '';
  eventName: string = '';
  sourceTag: string = '';
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/

  loadingButton: boolean = false;
  submittedSuccessfully: boolean = false;
  isInEvent: boolean = false;
  isInInvitation: boolean = false;
  pageLoading: boolean = false;
  clicked: boolean = false;
  schoolId: string = '';
  uploadingResume: boolean = false;
  uploadingProfile: boolean = false;
  s3Domain: string = 'https://s3.amazonaws.com';
  assetBucketName: string = this.globalService.assetBucketName;

  userDetailsForm: FormGroup = new FormGroup({

    // Personal Details
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    dateOfBirth: new FormControl('', [Validators.required]),
    maritalStatus: new FormControl(''),
    emailId: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    mobileNum: new FormControl('', [Validators.required]),
    citizenship: new FormControl(''),
    currentAddress: new FormControl('', [Validators.required]),
    location: new FormControl('', Validators.required),
    permanentAddress: new FormControl('', [Validators.required]),
    linguisticSkills: new FormControl(''),
    relocate: new FormControl(),

    // Education Details
    percentage10th: new FormControl(null, [Validators.required, Validators.min(0)]),
    percentage12th: new FormControl(null, [Validators.required, Validators.min(0)]),
    percentageUG: new FormControl(null, [Validators.required, Validators.min(0)]),
    percentagePG: new FormControl(null, Validators.min(0)),
    graduationYear: new FormControl(null, [Validators.required, Validators.min(0)]),
    degreeDetails: new FormArray([
      new FormGroup({
        degree: new FormControl('', [Validators.required]),
        specialization: new FormControl('', [Validators.required]),
        percentage: new FormControl(null, [Validators.required]),
      })
    ]),

    // WorkExperience
    yearOfExperience: new FormControl(null, [Validators.required, Validators.min(0)]),
    designation: new FormControl(''),
    expectedCTC: new FormControl(null),
    currentCTC: new FormControl(null),
    previousRole: new FormControl(''),
    previousRolePeriod: new FormControl(),

    // Document Uploads
    resumeUpload: new FormControl('', [Validators.required]),
    proPicUpload: new FormControl('', [Validators.required]),
  });

  constructor(
    private jobService: JobServicesService,
    private messageService: MessageService,
    private _router: Router,
    private fileUploadService: FileUploadService,
    private eventService: EventServicesService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private talentPoolService: TalentPoolService,
    private globalService: GlobalServicesService
  ) { }

  ngOnInit(): void {
    this.jobId = this.getJobId();
    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.jobName = window.localStorage.getItem('selectedJobName') || '';
    this.eventName = window.localStorage.getItem('selectedEventName') || '';
    this.isInEvent = this.jobId === this.eventId;
    this.isInInvitation = this.checkForInvitation();

    this.activatedRoute.queryParamMap.subscribe((param) => {
      this.sourceTag = param.get('sourceTag') || '';
    })

    this.degreeDetails = this.userDetailsForm.controls['degreeDetails'].value;
    if (!this.isInEvent && !this.isInInvitation) this.getJobDetails();
    let form: any = this.userDetailsForm.controls["degreeDetails"]
    this.schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';

  }

  getJobId() {
    let route = this.location.path().split('/');
    return route[route.length - 1];
  }

  checkForInvitation() {
    let route = this.location.path().replace('?', '/').split('/');
    return route.includes('invitation');
  }

  getJobDetails = () => {
    this.pageLoading = true;
    this.jobService.getJob(this.jobId).subscribe(res => {
      this.jobDetails = res.data;
      this.pageLoading = false;
    })
  }

  addDegreeDetails = () => {
    const group = new FormGroup({
      degree: new FormControl(''),
      specialization: new FormControl(''),
      percentage: new FormControl(null),
    })
    this.degreeDetailsControl = this.userDetailsForm.controls['degreeDetails'];
    this.degreeDetailsControl.push(group);
    this.degreeDetails = this.degreeDetailsControl.controls;
  }

  deleteDegreeDetails = (index: number) => {
    this.degreeDetailsControl.removeAt(index);
    this.degreeDetails = this.degreeDetailsControl.controls;
  }

  getPayload = () => {
    const val = this.userDetailsForm.value
    let payload = {
      name: val.firstName + ' ' + val.lastName,
      gender: val.gender,
      dob: val.dateOfBirth,
      maritalStatus: val.maritalStatus,
      email: val.emailId,
      mobile_number: Number(val.mobileNum),
      citizenship: val.citizenship,
      currentAddress: val.currentAddress,
      location: val.location,
      permanentAddress: val.permanentAddress,
      linguisticSkills: val.linguisticSkills,
      relocation: val.relocate,
      SSLC: val.percentage10th,
      HSC: val.percentage12th,
      UG: val.percentageUG,
      PG: val.percentagePG,
      graduationYear: val.graduationYear,
      degree: val.degreeDetails[0].degree,
      degreeDetails: val.degreeDetails,
      experience: val.yearOfExperience,
      designation: val.designation,
      current_ctc: val.currentCTC,
      expected_ctc: val.expectedCTC,
      previousRole: val.previousRole,
      previousRolePeriod: val.previousRolePeriod,
      resume: this.resume[0] ? this.resumeBase64 : "",
      profilePic: this.profilePic[0] ? this.profileBase64 : "",
      stageId: 1,
      userType: "candidate",
      status: "active",
      verifiedPic: "",
      customFields: [{
        fieldName: "",
        fieldValue: ""
      }],
      sourceTag: ""
    }

    let k: keyof typeof payload;
    for (k in payload) {
      if (k !== 'email' && k !== 'relocation') {
        if (payload[k] == '' || payload[k] == null) delete payload[k];
        if (k === 'degreeDetails' && payload[k][0].degree == null) delete payload[k];
      }
    }
    return payload
  }

  submitForm = () => {
    this.clicked = true;
    if (this.userDetailsForm.valid) {
      let payload = this.getPayload();
      this.loadingButton = true;
      if (!this.isInEvent && !this.isInInvitation) {
        this.jobService.createCandidate(payload).subscribe(res => {
          if (res.data == 'User Email already Exists') this.messageService.add({ severity: 'error', summary: 'Error', detail: res.data });
          else if (Object.keys(res.data).length === 0) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to add user!' });
          else if (res.data.id) this.submittedSuccessfully = true;
          this.loadingButton = false;
        })
      } else if (this.isInEvent && !this.isInInvitation) {
        this.eventService.createCandidate(payload, this.eventId, this.eventName).subscribe(res => {
          if (res.data == 'User Email already Exists') this.messageService.add({ severity: 'error', summary: 'Error', detail: res.data });
          else if (Object.keys(res.data).length === 0) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to add user!' });
          else if (res.data.id) this.submittedSuccessfully = true;
          this.loadingButton = false;
        })
      } else if (this.isInInvitation) {
        payload.sourceTag = this.sourceTag;

        this.talentPoolService.addUser(payload).subscribe(res => {
          if (res.error == 'User already exists!!') this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
          else if (Object.keys(res.data).length === 0) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to add user!' });
          else if (res.data.id) this.submittedSuccessfully = true;
          this.loadingButton = false;
        })
      }
    }
    else {
      return
    }
  }

  handleUpload(event: any, type: string) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, type)
    };
  }

  resetForm = (applicationForm: HTMLElement) => {
    if (this.isInInvitation) {
      this._router.navigateByUrl('talentpool-management')
    } else {
      this.clicked = false;
      this.userDetailsForm.reset();
      this.resume = [];
      this.profilePic = [];
      this.userDetailsForm.value.degreeDetails.length > 1 && this.degreeDetailsControl.controls.splice(1);
      this.submittedSuccessfully = false;
      applicationForm.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onSelectResume(event: any) {
    this.resume.push(...event.addedFiles);
    this.handleUpload(event, 'resume')
  }

  onRemoveResume(event: any) {
    this.resume.splice(this.resume.indexOf(event), 1);
  }

  onSelectProfilePic(event: any) {
    this.profilePic.push(...event.addedFiles);
    this.handleUpload(event, 'profile')
  }

  onRemoveProfilePic(event: any) {
    this.profilePic.splice(this.profilePic.indexOf(event), 1);
  }

  redirect = () => {
    if (this.isInEvent) this._router.navigate(['events/candidates/', this.eventName], { queryParams: { view: 'candidates' } });
    else this._router.navigate(['jobs/candidates/', this.jobName], { queryParams: { view: 'candidates' } });
  }

  uploadLoader(type: string, action: boolean) {
    if (type === 'resume') {
      this.uploadingResume = action;
    }
    else if (type === 'profile') {
      this.uploadingProfile = action;
    }
  }

  fileUpload(file: any, type: string) {

    this.uploadLoader(type, true);
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.assetBucketName,
      file_name: `hire-form/${nanoid(10)}/${new Date().getTime()}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;

    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                if (type === 'resume') this.resumeBase64 = `${this.s3Domain}/${this.assetBucketName}/${pay.file_name}`;
                if (type === 'profile') this.profileBase64 = `${this.s3Domain}/${this.assetBucketName}/${pay.file_name}`;
                this.uploadLoader(type, false);
              }
            }
          );
        }
      }
    );
  }
}
