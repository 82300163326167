import { Component, Input, OnInit, ChangeDetectorRef   } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { RoleServicesService } from 'src/app/services/role-services.service';
import { SettingsService } from 'src/app/services/settings.service';


@Component({
  selector: 'app-role-and-permission',
  templateUrl: './role-and-permission.component.html',
  styleUrls: ['./role-and-permission.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class RoleAndPermissionComponent implements OnInit {

  role_and_permission_list: any[] = [];
  module_slug_list: any = [];
  _module_slug_list: any = [];
  moduleList: any = [];
  isLoading: boolean = false;
  clicked: boolean = false;
  addRoleAndPermissionSidebar: boolean = false;
  saveBtnLoading: boolean = false;
  edit: boolean = false;
  saveDisable: boolean = false;
  roleData : any;
  assignedPermissionIds: Set<number> = new Set();
  userPrivileges: Set<string> = new Set();

  roleAndPermissionForm: FormGroup;
  constructor(private settingService: SettingsService,private roleService: RoleServicesService, private messageService: MessageService, private confirmationService: ConfirmationService,private globalService: GlobalServicesService, private cdRef: ChangeDetectorRef) {
    this.roleAndPermissionForm = new FormGroup({
      role_name: new FormControl('', [Validators.required]),
      module: new FormControl('', [Validators.required]),
      role_description: new FormControl('', [Validators.required]),
      permissions: new FormControl([])
    });
    this.roleAndPermissionFormData();
   }
  ngOnInit() {
    this.getRoleAndPermission();
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });
    
  }
  roleAndPermissionFormData(){
    this.isLoading = true;
    this.roleService.getRoleAndPermissionFormDataFastApi().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.module_slug_list = res.body.map((group: any) => ({
            label: group.group, 
            slug: group.group.toLowerCase().replace(/\s+/g, '_'), 
            permissions: group.permissions.map((perm: any) => ({
                id: perm.id,
                name: perm.display_name,
                slug: perm.slug_name,
                isChecked: false,
                isDisabled: false
            })),
            isSelectedAll: false
        }));
        this.moduleList = this.module_slug_list.map((module: any) => ({
            label: module.label, 
            value: module.slug
        }));
    },
    error: (error: any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
    }
    });
  }
  getRoleAndPermission() {
    this.isLoading = true;
    this.roleService.getRoleAndPermissionFastApi().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.role_and_permission_list = res.body.map((role: any) => ({
          ...role,
          permissions: role.permissions ?? [] 
        }));
      },
      error: (error:any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
    }
  });
}
openSideBar() {
  this.roleData = [];
  this.clicked = false;
  this.edit = false; 
  this._module_slug_list = [];
  this.assignedPermissionIds.clear();
  this.roleAndPermissionForm.reset();
  this.roleAndPermissionForm.patchValue({ module: '' });
  this.addRoleAndPermissionSidebar = true;
}
  cancelRoleAndPermission(){
    this.addRoleAndPermissionSidebar = false;
  }
  getAllPermissions() {
    if (!this._module_slug_list || this._module_slug_list.length === 0) {
      return [];
  }

  const selectedPermissions = this._module_slug_list.reduce((acc: number[], module: any) => {
      if (module.permissions) {
          module.permissions.forEach((permission: any) => {
              if (permission.isChecked) {
                  acc.push(Number(permission.id)); 
              }
          });
      }
      return acc;
  }, []);
  return selectedPermissions;
}

saveRoleAndPermission() {
this.clicked = true;
if (this.roleAndPermissionForm.invalid) {
    return;
  }
  const allPermissions = this.getAllPermissions();

  if (allPermissions.length === 0) {
      this.globalService.commonGrowl.next({
          severity: 'error',
          summary: 'Error',
          detail: 'Please select at least one permission before saving.',
      });
      return;
    }
    const createRoleAndPermissionPayload = {
      name: this.roleAndPermissionForm.value.role_name,
      description: this.roleAndPermissionForm.value.role_description,
      permissions: allPermissions,
    };
    this.roleService.createRoleAndPermissionFastApi(createRoleAndPermissionPayload).subscribe({
      next: (response) => {
        if (response.status === 201) {
          this.roleAndPermissionForm.reset();
          this.roleData = [];
          this.getRoleAndPermission();
          this.clicked = false;
          this.addRoleAndPermissionSidebar = false;
          this.globalService.commonGrowl.next({
              severity: 'success',
              summary: 'Success',
              detail: 'Role created successfully!',
          });
      }
      this.saveBtnLoading = false;
      },
      error: (_err) => {
        this.saveBtnLoading = false;
        this.roleData = [];
      }
    });
  }
  syncPermissionsWithUpdatedRole() {
      if (!this.roleData || !this.roleData.permissions) {
          console.warn("⚠ No updated role data available.");
          return;
      }
      this.assignedPermissionIds = new Set(this.roleData.permissions.map((perm: any) => perm.id));
      this._module_slug_list = this._module_slug_list.map((module: any) => {
          const updatedPermissions = module.permissions.map((permission: any) => ({
              ...permission,
              isChecked: this.assignedPermissionIds.has(permission.id)
          }));
          return {
              ...module,
              permissions: updatedPermissions,
              isSelectedAll: updatedPermissions.every((permission: any) => permission.isChecked)
          };
    });
    this.cdRef.detectChanges();
  }
  updateRoleAndPermission() {
    this.clicked = true;
    if (this.roleAndPermissionForm.invalid) {
      return;
    }
    const allPermissions = this.getAllPermissions();
    if (allPermissions.length === 0) {
      this.globalService.commonGrowl.next({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select at least one permission before updating.',
      });
      return;
    }
    const updatePayload = {
      name: this.roleAndPermissionForm.value.role_name,
      description: this.roleAndPermissionForm.value.role_description,
      permissions: [...allPermissions],
    };
    this.roleService.updateRoleAndPermissionFastApi(this.roleData.id, updatePayload).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.roleData = {
            ...this.roleData,
            name: updatePayload.name,
            description: updatePayload.description,
            permissions: updatePayload.permissions.map((id) => ({ id })),
          };

          this.assignedPermissionIds = new Set(updatePayload.permissions);
          this._module_slug_list = this.module_slug_list.map((module: any) => ({
            ...module,
            permissions: module.permissions.map((permission: any) => ({
              ...permission,
              isChecked: this.assignedPermissionIds.has(permission.id),
            })),
            isSelectedAll: module.permissions.every((permission: any) =>
              this.assignedPermissionIds.has(permission.id)
            ),
          }));

          this.cdRef.detectChanges();

          this.clicked = false;
          this.addRoleAndPermissionSidebar = false;
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: 'Role updated successfully!',
          });
      }
      this.saveBtnLoading = false;
      },
      error: () => {
        this.saveBtnLoading = false;
      },
    });
  }
  editOption(role: any) {
    this.addRoleAndPermissionSidebar = true;
    this.clicked = false;
    this.edit = true;
    this.isLoading = true;
    this.roleService.getSpecificRoleAndPermissionFastApi(role.id).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.roleData = res.body ?? {};
        const rolePermissions = this.roleData.permissions || [];
        this.roleAndPermissionForm.patchValue({
          role_name: this.roleData.name ?? '',
          role_description: this.roleData.description ?? '',
          module: ''
        });
  
        this.assignedPermissionIds = new Set(rolePermissions.map((perm: any) => Number(perm.id)));
  
        this._module_slug_list = this.module_slug_list.map((module: any) => ({
          ...module,
          permissions: module.permissions.map((permission: any) => ({
            ...permission,
            isChecked: this.assignedPermissionIds.has(permission.id) 
          })),
          isSelectedAll: module.permissions.every((permission: any) =>
            this.assignedPermissionIds.has(permission.id)
          )
        }));
  
        this.cdRef.detectChanges();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
      }
    });
  }
  updateSelectedCheckboxes(module: any, selected_all?: boolean) {
    if (!module.permissions) {
        return;
    }

    if (selected_all) {
      module.permissions.forEach((permission: any) => {
          permission.isChecked = module.isSelectedAll;
      });
    } else {
      module.isSelectedAll = module.permissions.every((permission: any) => permission.isChecked);
  }
  this.cdRef.detectChanges();
}

togglePermission(module: any, permission: any) {
  if (!module.permissions) {
      return;
  }
  permission.isChecked = !permission.isChecked;
  module.isSelectedAll = module.permissions.every((perm: any) => perm.isChecked);
  this.cdRef.detectChanges();
}
getModuleSlugList() {
    const selectedModuleSlug = this.roleAndPermissionForm.value.module;
    const selectedModule = this.module_slug_list.find(
        (module: any) => module.slug === selectedModuleSlug
    );

    if (!selectedModule) {
        console.warn("Warning: Selected module not found.");
        return;
    }
    const existingModule = this._module_slug_list.find((module: any) => module.slug === selectedModuleSlug);
    if (existingModule) {
        return;
    }

    const newModule = {
        ...selectedModule,
        permissions: selectedModule.permissions.map((permission: any) => ({
            ...permission,
            isChecked: this.assignedPermissionIds.has(permission.id)
        })),
        isSelectedAll: selectedModule.permissions.every((permission: any) => this.assignedPermissionIds.has(permission.id))
    };

    this._module_slug_list.push(newModule);
    this.cdRef.detectChanges();
  }
}
