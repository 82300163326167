import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})

export class EventServicesService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  bulk_invite_url = environment.HOST.BULK_INVITE;
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;
  private documentationForms: any[] = [];
  private feedbackForms: any[] = [];
  private testForms: any[] = [];
  userId = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails') || '').id
    : '';

  options() {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '',
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }

  constructor(private http: HttpClient, private globalService: GlobalServicesService) { }
  // fast APIs
    // New method to create an event
    createEventFastApi(payload: any): Observable<HttpResponse<any>> {
      return this.http.post<HttpResponse<any>>(this.fast_api_url + '/events', payload, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }
  
    // New method to get events with pagination and sorting
    getEventsFastApi(page = 1, size = 10, orderBy = 'desc', sortBy = 'created_at', searchEvent = ''): Observable<HttpResponse<any>> {
      const params = new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('order_by', orderBy)
        .set('sort_by', sortBy)
        .set('s', searchEvent)
  
      return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events`, {
        params,
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }
  
    // New method to update an event by ID
    updateEventFastApi(payload: any, eventId: string): Observable<HttpResponse<any>> {
      return this.http.put<HttpResponse<any>>(this.fast_api_url + '/events/' + eventId, payload, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }
  
    // New method to get a specific event by ID
    getSpecificEventFastApi(eventId: string): Observable<HttpResponse<any>> {
      return this.http.get<HttpResponse<any>>(this.fast_api_url + '/events/' + eventId, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }
    // New method to get a event form data
    getEventFormDataFastApi(): Observable<HttpResponse<any>> {
      return this.http.get<HttpResponse<any>>(this.fast_api_url + '/events/form-data', {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }
  
    // New method to delete an event by ID
    deleteEventFastApi(eventId: string): Observable<HttpResponse<any>> {
      return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/events/' + eventId, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }
    // New method to get all events stage candidates
    getEventStageCandidatesFastApi(stageId: string,eventId: string, page = 1, size = 10, orderBy = 'asc', sortBy = 'candidate_uuid', searchEmail = ''): Observable<HttpResponse<any>> {
      const params = new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('order_by', orderBy)
        .set('sort_by', sortBy)
        .set('s', searchEmail);
  
      return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/candidates`, {
        params,
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError(this.globalService.handleError.bind(this.globalService))
      );
    }

  // get event stage action form data fast api
  getEventStageActionFormDataFastApi(eventId:string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/actions/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  // New method to update an event stage candidate status 
  candidateStageUpdateStatusFastApi(payload: any,stageId:string, eventId:string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/${stageId}/actions/update-status`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  candidateStageMoveStageFastApi(payload: any,stageId:string, eventId:string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/${stageId}/actions/move-stage`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  sendTestLinkFastApi(payload: any, stageId: string, eventId: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/${stageId}/actions/send-test-link`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getRecruiterListByEventFastApi(eventId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/interviews/interviewers`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  // EVENT APIs
  getAllEvents(pageNumber?: number, pageSize?: number, userRole?: string): Observable<any> {
    if (userRole !== 'admin' && userRole !== 'superAdmin') {
      return this.http.get(this.api_url + '/event/all/' + userRole + '/' + this.userId + '/?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
    }
    else {
      return this.http.get(this.api_url + '/event?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
    }
  }

  getEventById(eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/event/' + eventId, this.options());
  }

  getEventJobById(eventId: string, jobId: string): Observable<any> {
    return this.http.get(this.api_url + '/event/' + eventId + '/' + jobId, this.options());
  }

  getCandidateListByEventId(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/getCandidatesByEventId', payload, this.options());
  }

  getUserByEmail(email: string, eventId?: string): Observable<any> {
    return this.http.get(this.api_url + '/users/' + email + '?jobId=' + eventId, this.options());
  }

  getUserById(id: string): Observable<any> {
    return this.http.get(this.api_url + '/users/candidate/particular/' + id, this.options());
  }

  getUserByIdNoAuth(id: string): Observable<any> {
    return this.http.get(this.api_url + '/users/user/particular/' + id, this.options());
  }

  getCompletedCandidateListByEventId(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/getCompletedCandidateListByEventId', payload, this.options());
  }

  getJitsiToken(userName: string, roomName: string, isCandidate: boolean, school_Id?: string, avatarUrl?: string): Observable<any> {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const data = {
      "roomName": roomName,
      "user_id": userName,
      "school_id": schoolId ? schoolId : school_Id,
      "isCandidate": isCandidate,
      "avatar": avatarUrl
    }
    return this.http.post(this.host_api_url + '/neohire/jititoken', data);
  }

  sendMessage(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/jobs', payload, this.options());
  }

  getFilterEvents(pageNumber?: number, pageSize?: number, status?: string): Observable<any> {
    return this.http.get(this.api_url + '/event?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&status=' + status, this.options());
  }

  createEvent(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/event/', payload, this.options());
  }

  cloneEvent(payload: any, eventId: string): Observable<any> {
    return this.http.post(this.api_url + '/event/create/clone/' + eventId, payload, this.options());
  }

  updateEvent(payload: any, eventId: string): Observable<any> {
    return this.http.put(this.api_url + /event/ + eventId, payload, this.options());
  }

  deleteEvent(eventId: string): Observable<any> {
    return this.http.delete(this.api_url + '/event/' + eventId, this.options());
  }

  getSchool(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.options());
  }

  // COLUMN APIs
  getUserPreferencesFastApi(type:string): Observable<any> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/user-preferences/metadata/'+ type, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  saveUserPreferencesFastApi(payload:any, type:string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/user-preferences/' + type , payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  deleteUserPreferencesFastApi(eventId: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/user-preferences/' + eventId, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  bulkInviteFileDownloadFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/candidates/invite/template`, {
      observe: 'response',
      responseType: 'blob' as 'json',  // Assuming it's a file download
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  bulkInviteFastApi(payload:any ,eventId: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>( `${this.fast_api_url}/events/${eventId}/candidates/bulk-invite`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  eventStageCandidateExportFastApi(eventId: string, stageId: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.post<Blob>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/candidates/export`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid(),
      responseType: 'blob' as 'json'
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  sendInterviewLinkFastApi(payload: any, eventId: string, stageId: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/${stageId}/actions/send-interview-link` , payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  sendInterviewLinkSampleExportFastApi(eventId: string, stageId: string, type:string = 'all'): Observable<HttpResponse<any>> {
    return this.http.post<Blob>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/actions/send-interview-link/template?export=${type}`, null, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid(),
      responseType: 'blob' as 'json'
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  bulkInterviewLinkFastApi(payload:any ,eventId: string, stageId: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>( `${this.fast_api_url}/events/${eventId}/stages/${stageId}/actions/send-interview-link/bulk`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  saveColumnCandidate(jobId: string, payload: Array<any>, eventId: string): Observable<any> {
    return this.http.put<any>(this.api_url + '/jobs/column/' + jobId + '?eventJobId=' + eventId, payload, this.options());
  }

  deleteColumnCandidates(jobId: string, columnId: string, eventId: string): Observable<any> {
    return this.http.delete(this.api_url + '/jobs/column/' + jobId + '?eventJobId=' + eventId + '/' + columnId, this.options());
  }

  getAllUser(pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    return this.http.get(this.api_url + '/users/?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  getAllRoles(): Observable<any> {
    return this.http.get(this.api_url + '/roles/list/job/roles', this.options());
  }

  getEventDetails(): Observable<any> {
    return this.http.get(this.api_url + '/event/all/list/events', this.options());
  }

  getRoleById(eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/roles/' + eventId, this.options());
  }

  createCandidate(payload: any, eventJobId: string, eventTitle: string): Observable<any> {
    return this.http.post(this.api_url + '/users/addCandidate/?eventJobId=' + eventJobId + '&eventTitle=' + eventTitle, payload, this.options());
  }

  // stageCandidate
  getAllCandidatesByStage(jobId: string, stageId: number): Observable<any> {
    return this.http.get(this.api_url + '/users/stages/' + jobId + '/' + stageId, this.options());
  }

  getCandidatesByStage(jobId: string, stageId: number, pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    return this.http.get(this.api_url + '/users/page/' + jobId + '/' + stageId + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  candidateMoveStage(jobId: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/stages/' + jobId + '/moveStage', payload, this.options());
  }

  multiCandidateMoveStage(jobId: string, payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/' + jobId + '/moveStage', payload, this.options());
  }

  candidteBulkMoveStage(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/import/file/moveStage', payload, this.options())
  }

  candidateChangeStatus(jobId: string, actionId: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/stages/' + jobId + '/' + actionId, payload, this.options());
  }

  multipleCandidateChangeStatus(jobId: string, actionId: string, payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/' + jobId + '/' + actionId, payload, this.options());
  }
  addCandidateEntry(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby', payload)
  }

  getSpcCandidateEntry(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/getSpcCandidateEntry', payload)
  }

  sendMessageWS(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/sendMessageWS', payload, this.options())
  }

  updateInterviewStatus(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/update/interviewStatus', payload)
  }

  getRecruiterListByEvent(eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/event/a/b/getRecruiterListByEvent/' + eventId, this.options())
  }

  sendInterviewLink(jobId: string, stageId: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interview/' + jobId + '/' + stageId, payload, this.options());
  }

  getSearchWiseCandidate(payload: any, stageId: string, jobId: string, searchTerm: string, pageNumber: number, pageSize: number) {
    return this.http.post(this.api_url + `/users/search/page/${jobId}/${stageId}?pageSize=${pageSize}&searchTerm=${searchTerm}&pageNumber=${pageNumber}`, payload, this.options());
  }

  checkIfUserInLobby(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + '/interviewLobby/checkIfUserInLobby/' + eventId + '/' + userId, this.options());
  }

  sendTestLink(payload: any, eventId: string, stageId: string, eventCategory: string): Observable<any> {
    return this.http.post(this.api_url + '/sendTestLink/' + eventId + '/' + stageId + '/' + eventCategory, payload, this.options());
  }

  sendTestLinkToAll(eventId: string, stageId: string, eventCategory: string, payload: any, stageNumber: number): Observable<any> {
    return this.http.post(`${this.api_url}/stages/allcandidates/${eventId}/${stageId}/${eventCategory}?stageNumber=${stageNumber}`, payload, this.options());
  }

  getStage(jobId?: string, stageId?: string): Observable<any> {
    return this.http.get(this.api_url + '/stages/' + jobId + '/' + stageId, this.options());
  }

  getParticularStage(jobId?: string, stageId?: string): Observable<any> {
    return this.http.get(this.api_url + '/stages/value/' + jobId + '/' + stageId, this.options());
  }

  getUserUnAuth(userId: string): Observable<any> {
    return this.http.get(this.api_url + '/interview/candidate/' + userId, this.options());
  }

  getCandiateStageActivity(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + '/stage-user-mapping/' + eventId + '/' + userId, this.options());
  }

  getCandiateStatusActivity(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + '/status-user-mapping/' + eventId + '/' + userId, this.options());
  }

  getJobUserMapping(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + `/job-user-mapping/${eventId}/${userId}`, this.options());
  }

  //Stage Candidates API
  getScheduleDownloadTemplate(eventId: string, stageId: string, type: string): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/cloudRun/download?stageId=' + stageId + '&eventId=' + eventId + '&type=' + type, this.options());
  }

  postScheduleUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/bulk/file/send-interview-email/', payload, this.options());
  }

  sendVettedPooleMail(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/email-template/vettedPool', payload, this.options());
  }

  getAllCohortId(): Observable<any> {
    return this.http.get(this.api_url + '/cohortLabel/getAllCohortLabels', this.options());
  }

  getAllCohortPagination(pageNumber: any, pageSize: any): Observable<any> {
    return this.http.get(this.api_url + '/cohortLabel/getCohorts?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  createCohortId(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/cohortLabel/add', payload, this.options());
  }

  updateCohortData(payload: any): Observable<any> {
    return this.http.put(this.api_url + '/cohortLabel/update', payload, this.options());
  }

  mapCohortId(eventId: string, payload: any) {
    return this.http.post(this.api_url + '/cohortLabel/mapCohort/' + eventId, payload, this.options());
  }

  //Bulk Upload
  sendBulkInvite(payload: any, actionId: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/event/registration/invite/' + actionId, payload, this.options());
  }

  sendSingleInvite(payload: any, actionId: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/event/registration/invite/' + actionId, payload, this.options());
  }

  getUserDetailsByJobUserId(jobUserId: string): Observable<any> {
    return this.http.get(this.api_url + '/job-user-mapping/getUserDetailsById/' + jobUserId, this.options());
  }

  //Cohort APIs
  getCohortDownload(eventId: any, stageId: any): Observable<any> {
    return this.http.get(this.api_url + '/users/cohort/' + eventId + '/' + stageId, this.options());
  }

  getMultipleCohortDownload(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/multiSelect/download', payload, this.options());
  }

  postCohortUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/cohort/upload', payload, this.options());
  }

  getAllTestScoreByEmail(jobId: string, email: string): Observable<any> {
    return this.http.get(this.api_url + '/test/' + `${jobId}/${email}`, this.options());
  }

  getFeedbackDownloadLink(eventId: string, userId: string, stageId: number) {
    return this.http.get(this.bulk_invite_url + '/cloudRun/downloadFeedback/' + `${eventId}/${userId}/${stageId}`, this.options());
  }

  //Workflow Cohort
  uploadCohortFile(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/cohort/upload', payload, this.options());
  }

  sendOfferCohort(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/bulk-cohort/send-offer/', payload, this.options());
  }

  downloadGenericCandidates(downloadName: string): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/bulk-cohort/' + downloadName, this.options());
  }

  downloadAllCandidates(downloadName: string, jobId: any, stageId: any): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/bulk-cohort/' + downloadName + '/?jobId=' + jobId + '&stageId=' + stageId, this.options());
  }

  downloadSelectedCandidates(downloadName: string, payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/bulk-cohort/' + downloadName, payload, this.options());
  }

  //Interview APIs
  getInterviewDetails(eventId: any, emailId: string): Observable<any> {
    return this.http.get(this.api_url + '/interview/allDetails/' + eventId + '/' + emailId, this.options());
  }

  //Candidate Events
  eventCandidateSearch(eventId: any, pageSize: any, pageNumber: any, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/search/' + eventId + '/candidate/?pageSize=' + pageSize + '&pageNumber=' + pageNumber, payload, this.options());
  }

  eventTemplateDownload(): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/cloudRun/downloadEventBulkTemplate', this.options());
  }

  eventBulkUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/event/bulk/invite', payload, this.options());
  }
  setForms(documentation: any[], feedback: any[], test: any[]) {
    this.documentationForms = documentation;
    this.feedbackForms = feedback;
    this.testForms = test;
  }
  
  // Add methods to get these forms if needed
  getDocumentationForms() {
    return this.documentationForms;
  }
  
  getFeedbackForms() {
    return this.feedbackForms;
  }
  
  getTestForms() {
    return this.testForms;
  }

}
