<!-- <div [ngStyle]="{'visibility':showEditor ? 'visible' : 'hidden'}">
  <div class="job-add-header">
    <div class="job-heading">Invite Candidate</div>
  </div>
  <p-divider class="p-divider"></p-divider>
  <div class="jobForm">
    <div>
      <span class="radioButtons">
        <p-radioButton value="single-invite" [(ngModel)]="_selectedCategory">
        </p-radioButton><label class="radioBtn" for="single-invite">Single-Invite</label>
        <p-radioButton value="bulk-invite" [(ngModel)]="_selectedCategory">
        </p-radioButton><label class="radioBtn" for="bulk-invite">Bulk-Invites</label>
      </span>
      <form *ngIf="_selectedCategory == 'single-invite'" class="departmentForm" [formGroup]="inviteForm">
        <div>
          <span class="p-float-label templateForm-elements input-field" style="margin-top: 40px;">
            <input type="text" id="first-name" pInputText formControlName="firstName"
              [ngClass]="{'p-filled': inviteForm.value.firstName}">
            <label for="first-name">First Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="last-name" pInputText formControlName="lastName"
              [ngClass]="{'p-filled': inviteForm.value.lastName}">
            <label for="last-name">Last Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="emailID" pInputText formControlName="emailID"
              [ngClass]="{'p-filled': inviteForm.value.emailId}">
            <label for="emailID">Email ID <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="first-name" pInputText formControlName="source"
              [ngClass]="{'p-filled': inviteForm.value.source}">
            <label for="emailID">Source Tag <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="radioButtons templateForm-elements input-field">
            <label [style]="{fontSize: '14px', fontWeight: 'var(--fw-semi-bold)'}">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="technical">Technical</label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form%20Non-Technical" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="non-technical">Non-Technical</label>
          </span> -->
          <!-- <span class="field-checkbox templateForm-elements input-field" style="margin-top: 30px;">
            <p-checkbox formControlName="nonTechnical" [binary]="true" inputId="binary"></p-checkbox>
            <label for="binary">Non-Technical</label>
          </span> -->
          <!-- <div class="send-invite_btn">
            <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite"
              class="p-button-outlined button-align invite-btn" [disabled]="inviteForm.invalid"
              [ngClass]="{'valid': inviteForm.valid, 'invalid' : !inviteForm.valid}"
              [loading]="sendingSingleInvite"></button>
          </div>
        </div>
      </form> -->

      <form *ngIf="_selectedCategory == 'bulk-invite'" [formGroup]="bulkInviteForm">
        <div>
          <p class="template-download-label">Download Template to Invite Candidates</p>
          <div>
            <div class="template-download">
              <div class="template-file_info">
                <p class="pi pi-file-excel template-file-icon"></p>
                <p class="file-name">Sample_Bulk_Invite.xlsx</p>
              </div>
              <p (click)="sampleData()" class="template-download-icon"><em class="pi pi-arrow-down"></em></p>
            </div>
          </div>
          <p class="template-upload-label">Upload File to Invite Bulk Candidates</p>
          <div class="profilePhoto form-details">
            <div class="template-upload fileUpload-dropzone" ngx-dropzone
              [accept]="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
              [multiple]="false" (change)="onSelectMedia($event)">
              <ngx-dropzone-label *ngIf="!uploadingFile">
                <div class="dropzone-label">
                  <em class="pi pi-cloud-upload"></em>
                  <p class="upload-text">
                    Click to
                    <span [style]="{ color: 'var(--fc-primary)' }">upload</span> or Drag
                    & Drop
                  </p>
                  <p class="format-text">.XLS, .XLSX, .CSV Formats only</p>
                </div>
              </ngx-dropzone-label>
              <div style="display: flex; justify-content:center; align-items:center" *ngIf="uploadingFile">
                <p-progressSpinner id="progress" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                  animationDuration="0.5s">
                </p-progressSpinner>
              </div>
              <div *ngIf="!uploadingFile">
                <ngx-dropzone-preview class="file-preview" ngProjectAs="ngx-dropzone-preview"
                  *ngFor="let f of mediaFile" [file]="f" [removable]="true" (removed)="onRemoveMedia(f)">
                  <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                  </ngx-dropzone-label>
                </ngx-dropzone-preview>
              </div>
            </div>
          </div>
          <span class="p-float-label jobForm-elements">
            <input formControlName="source" [ngClass]="{ 'p-filled': bulkInviteForm.value.source}" style="width: 100%"
              type="text" id="inputtext" pInputText />
            <label for="inputtext">Source Tag <span class="required-input">*</span></label>
          </span>
          <span class="radioButtons templateForm-elements input-field">
            <label [style]="{fontSize: '14px', fontWeight: 'var(--fw-semi-bold)'}">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="technical">Technical</label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form%20Non-Technical" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="non-technical">Non-Technical</label>
          </span>
          <div class="send-invite_btn">
            <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite"
              class="p-button-outlined button-align invite-btn" [disabled]="bulkInviteForm.invalid"
              [ngClass]="{'valid': bulkInviteForm.valid, 'invalid' : !bulkInviteForm.valid}"
              [loading]="sendingBulkInvite"></button>
          </div>
        </div>
      </form>
    <!-- </div>
  </div>
</div> -->
<p-toast></p-toast>