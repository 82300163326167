<div class="job-header">
  <div class="status">
    <p-splitButton label="Status: {{ statusSelected }}" [model]="this.status"
      styleClass="p-button-sm mr-2 mb-2 p-button-outlined p-button-secondary"></p-splitButton>
  </div>
  <div>
    <p-sidebar class="addJob-slider" [(visible)]="addJobsSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
      position="right" (onHide)="getAllJobs()">

      <app-job-add *ngIf="addJobsSlider"></app-job-add>
    </p-sidebar>
    <button pButton pRipple class="p-button-raised" type="button" (click)="addJobsSlider = true"
      label="New Job"></button>
  </div>
</div>

<div class="table">

  <!-- Filter SideBar -->
  <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
    [style]="{padding: '0', border: '2px solid white'}" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
    <p class="sidebar-title">Filter</p>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input type="text" pInputText placeholder="Search" [(ngModel)]="filterSearchTerm">
    </span>

    <div class="filterSidebarContent">
      <p-accordion [multiple]="true" *ngFor="let col of _selectedColumns" [collapseIcon]="'pi pi-minus'">
        <p-accordionTab [ngSwitch]="col.field" header={{col.header}} *ngIf="col.options">
          <span *ngSwitchCase="'status'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox [(ngModel)]="selectedStatusElements" name="status" [inputId]="option" [value]="option">
              </p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>

          <span *ngSwitchCase="'department'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox [(ngModel)]="selectedDepartmentElements" [value]="option" name="department"
                [inputId]="option"></p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>

          <span *ngSwitchCase="'jobType'">
            <div *ngFor="let option of col.options;" class="field-checkbox">
              <p-checkbox [(ngModel)]="selectedJobTypeElements" [value]="option" name="jobtype" [inputId]="option">
              </p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>
        </p-accordionTab>
      </p-accordion>

      <div class="sidebar-bottom">
        <div class="sidebar-bottom-apply">
          <p class="sidebar-bottom-text" (click)="displaySaveFilterSettings = true;">Save Selected Filters</p>
          <div class="sidebar-bottom-buttons">
            <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
              (click)="clearSelectedFilters()"></button>
            <button pButton pRipple type="button" label="Apply" (click)="applySelectedFilter()"></button>
          </div>
        </div>

        <div [ngClass]="{'hidden' : !displaySaveFilterSettings}" class="sidebar-saveSettings">
          <em (click)="displaySaveFilterSettings = false" class="pi pi-times"></em>
          <div class="p-inputgroup">
            <input pInputText placeholder="Enter Filter Name" type="text">
            <button pButton pRipple label="Save" type="button"></button>
          </div>
        </div>
      </div>

    </div>

  </p-sidebar>

  <!-- Column Settings Sidebar -->
  <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
    [style]="{ padding: '0', border: '2px solid white'}" [modal]="false">
    <p class="sidebar-title">Column Settings</p>

    <div class="{{savedColumnSettings.length ? 'savedColumns-continer' : 'hidden'}}">
      <p>Saved Column</p>
      <div *ngFor="let column of savedColumnSettings" class="savedColumns"
        (click)="applySavedSettings(column.columnValue)">
        <p>{{column.columnName}}</p>
        <em (click)="deleteSavedColumns(column.columnId)" class="pi pi-trash"></em>
      </div>
    </div>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input (ngModelChange)="searchColumns($event)" pInputText placeholder="Search" type="text"
        [(ngModel)]="columnSearchTerm" />
    </span>

    <div *ngFor="let col of _jobsCol" class="field-checkbox">
      <p-checkbox [value]="col" name="category" [inputId]="col.field" [(ngModel)]="checkedColumns"></p-checkbox>
      <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
    </div>

    <div class="sidebar-bottom">

      <div class="{{ displaySaveColumnSettings ? 'hidden' : 'sidebar-bottom-apply'}}">
        <p (click)="displaySaveColumnSettings = true" class="sidebar-bottom-text">Save Selected Columns</p>
        <div class="sidebar-bottom-buttons">
          <button type="button" pRipple pButton class="p-button-outlined" label="Clear"
            (click)="clearSelectedColumns()"></button>
          <button pRipple pButton label="Apply" (click)="applySelectedColumns()" type="button"></button>
        </div>
      </div>

      <div [ngClass]="{'hidden': !displaySaveColumnSettings}" class="sidebar-saveSettings">
        <em (click)="displaySaveColumnSettings = false" class="pi pi-times"></em>
        <form [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()" class="p-inputgroup">
          <input pInputText placeholder="Enter Filter Name" type="text" formControlName="columnName">
          <button pButton pRipple label="Save" type="submit"></button>
        </form>
      </div>
    </div>
  </p-sidebar>

  <!-- Download Sidebar -->
  <p-sidebar [transitionOptions]="'0ms'" [(visible)]="showDownloadSidebar" class="downloadSidebar jobListDownload"
    [modal]="false" [style]="{ padding: '0', border: '2px solid white'}">
    <p class="sidebar-title">Download Data</p>

    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input pInputText placeholder="Search" [(ngModel)]="downloadSearchTerm" type="text"
        (ngModelChange)="searchColumns($event)" />
    </span>

    <div class="field-checkbox" *ngFor="let col of colsToDownload">
      <p-checkbox [value]="col" [inputId]="col.field" [(ngModel)]="checkedColumns" name="category"></p-checkbox>
      <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
    </div>

    <div class="download-container">
      <p>Download as</p>
      <div class="radioButton-group">
        <div class="radioButton">
          <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons" inputId="excel"></p-radioButton>
          <label for="excel"
            [style]="{color: downloadAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
              class="pi pi-file"></em>Excel</label>
        </div>
        <div class="radioButton">
          <p-radioButton name="exportButtons" value="csv" inputId="csv" [(ngModel)]="downloadAs"></p-radioButton>
          <label for="csv" [style]="{color: downloadAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
              class="pi pi-file-excel"></em>CSV</label>
        </div>
      </div>

      <button pButton pRipple type="button" label="Download" class="p-button-raised"
        (click)="(downloadAs === 'excel') ? exportJob() : dt.exportCSV()"></button>
    </div>

  </p-sidebar>

  <!-- Table -->
  <p-table #dt [columns]="_selectedColumns" [value]="filteredJobList.length ? filteredJobList : jobList"
    [resizableColumns]="true" [rows]="pageSize" [loading]="loadingSpinner" [scrollable]="true" scrollHeight="76vh"
    scrollDirection="both" responsiveLayout="scroll" dataKey="id" [selection]="selectedJobs"
    (selectionChange)="onSelectedJobChange($event)" [globalFilterFields]="['jobName', 'jobType', 'location', 'status']"
    [exportFilename]="exportName">
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="table-header-left">
          <button pButton class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
            icon="pi pi-sliders-h"
            (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false">
          </button>
          <!-- <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}" icon="pi pi-filter"
            (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false">
          </button> -->
          <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
            icon="pi pi-download"
            (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false">
          </button>
          <p-divider layout="vertical"></p-divider>
          <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
        </span>
        <span class="table-menu">
          <p [style]="{paddingRight: '10px'}">View</p>
          <p-dropdown [(ngModel)]="pageSize" [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"
            (onChange)="getJobsByLimit()" [options]="pageLimits"></p-dropdown>
          <p-divider layout="vertical"></p-divider>
          <button class="disabled p-button-secondary p-button-text simple-btn" pButton>
            {{ first }} to {{ last }}
          </button>
          <button class="p-button-secondary p-button-text simple-btn" [disabled]="isFirstPage()" pButton
            icon="pi pi-angle-left" (click)="prev()"></button>
          <button (click)="next()" pButton class="p-button-secondary p-button-text simple-btn" icon="pi pi-angle-right"
            [disabled]="isLastPage()"></button>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
        class="table-heading">
        <th pFrozenColumn [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" scope="col">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th scope="col" pSortableColumn="jobName" pFrozenColumn [style]="{width: '15vw'}" pResizableColumn>
          Job Title / Role
          <p-sortIcon field="jobName" class="sort-icon"></p-sortIcon>
        </th>
        <th scope="col" *ngFor="let job of columns" pSortableColumn="{{ job.field }}" [style]="{width: '10vw'}"
          pResizableColumn>
          {{ job.header }}
          <p-sortIcon field="{{ job.field }}" class="sort-icon"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-job let-columns="columns">
      <tr class="row-job-list"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
        [style]="{cursor: 'pointer'}">
        <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
          <p-tableCheckbox [value]="job"></p-tableCheckbox>
        </td>
        <td (click)="openCandidates(job.jobName, job.id)" [style]="{width: '15vw'}" pFrozenColumn>{{ job.jobName }}</td>
        <td (click)="openCandidates(job.jobName, job.id)" *ngFor="let col of columns" [style]="{width: '10vw'}">

          <span [ngSwitch]="true">
            <span *ngSwitchCase="col.field === 'salary' || col.field === 'minSalary' || col.field === 'maxSalary'"
              [style]="{fontFamily: 'Arial, Helvetica, sans-serif'}">
              {{ job[col.field] ? (job[col.field] | currency: 'INR' : 'symbol-narrow' : '1.0-0') : '-' }}
            </span>

            <span *ngSwitchCase="col.field === 'status'" class="status-tag {{ job[col.field] }}-tag">
              {{ job[col.field] }}
            </span>

            <span *ngSwitchDefault>
              {{ job[col.field] ? job[col.field] : '-' }}
            </span>
          </span>

        </td>
      </tr>
    </ng-template>
  </p-table>
</div>


<p-menu #menu [popup]="true" [model]="jobOptions"></p-menu>
<p-toast></p-toast>