import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { TalentPoolService } from 'src/app/services/talent-pool.service';
import { nanoid } from 'nanoid'
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-invite-candidate',
    templateUrl: './invite-candidate.component.html',
    styleUrls: ['./invite-candidate.component.css'],
    providers: [MessageService]
})
export class InviteCandidateComponent implements OnInit {
  @Output() inviteSent = new EventEmitter<any>()
  @Output() bulkInviteSent = new EventEmitter<any>()

  showEditor: boolean = true;
  _selectedCategory: string = 'single-invite';
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  eventCategories: any = [];
  loadSingleInvite: boolean = false;
  loadExistingUser: boolean = false;
  userExist: boolean = false;
  cohortUploaded: boolean = false;
  uploadingFile: boolean = false;
  countDiv : boolean = false;
  clicked: boolean = false;
  eventList: any = [];
  genderList: any = [];
  sourceTypeList: any = [];
  sourceNameList: any = [];
  existingUserList: any = [];
  existingUserColumns: any = []
  cohortFileSize: any;
  requiredColumns: Array<any> = [];
  existingRequiredColumns: Array<any> = [];
  cohortFile: Array<any> = [];
  cohortFileCopy: Array<any> = [];
  errorFileData: any;
  errorFile: any;
  templateValue: Array<any> = [];
  counts: any;
  acknowledgeUsers: any;
  bulkAcknowledgeUsers: any = [];
  bulkValidatedData: any = [];
  existingUsers: any;
  mediaFile: File[] = [];
  loadEventList: boolean = false;
  allCategoryEvents: any;
  disableInvite: boolean = false;
  bulkEnable: boolean = false;
  messageEnable : boolean = false;
  fileMessage: any;
  fileUploadPayload : any;

  currentDate = new Date();

  schoolId: string = '';
  schoolData: any;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  static ageValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    const dateOfBirth = new Date(control.value);
    const today = new Date();
    const age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDiff = today.getMonth() - dateOfBirth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
      return { 'ageInvalid': true }; // Invalid if under 14
    }
    
    return age < 14 ? { 'ageInvalid': true } : null; // Return error if age is less than 14
  };
  inviteForm: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    mobileNumber: new FormControl(null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    eventCategory: new FormControl(null, Validators.required),
    gender: new FormControl(null, Validators.required),
    date_of_birth: new FormControl(null, [Validators.required, InviteCandidateComponent.ageValidator]),
    eventName: new FormControl(null, Validators.required),
    eventId: new FormControl('', Validators.required),
    sourceType: new FormControl(null, Validators.required),
    sourceName: new FormControl(null, Validators.required),
  })

  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
    actionId: new FormControl('')
  })

    constructor(
      private talentPoolService: TalentPoolService, 
      private messageService: MessageService,
      private fileUploadService: FileUploadService,
      private globalService: GlobalServicesService
      ) {
       
      this.getInviteFormData();
      this.genderList = [
        { label: 'Male', value: 'male'},
        { label: 'Female', value: 'female'},
        { label: 'Other', value: 'other'},
      ]
        
      this.existingUserColumns = [
        { field: 'firstName', header: 'First Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'mobileNumber', header: 'Mobile Number' },
        { field: 'eventCategory', header: 'Event Category' },
        { field: 'invitedEvents', header: 'Existing Events' },
        { field: 'sourceType', header: 'Source Type' },
        { field: 'sourceName', header: 'Source Name' },
      ]

      this.requiredColumns = [
        { field: 'firstName', header: 'First Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'email', header: 'Email' },
        { field: 'countryCode', header: 'Country Code' },
        { field: 'mobileNumber', header: 'Mobile Number' },
        { field: 'eventCategory', header: 'Event Category' },
        { field: 'invitedEvents', header: 'Existing Events' },
        { field: 'eventName', header: 'Event Name' },
        { field: 'eventId', header: 'Event ID' },
        { field: 'sourceType', header: 'Source Type' },
        { field: 'sourceName', header: 'Source Name' },
        ]

      this.existingRequiredColumns = [
        { field: 'firstName', header: 'First Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'email', header: 'Email' },
        { field: 'countryCode', header: 'Country Code' },
        { field: 'mobileNumber', header: 'Mobile Number' },
        { field: 'eventCategory', header: 'Event Category' },
        { field: 'eventName', header: 'Event Name' },
        { field: 'eventId', header: 'Event ID' },
        { field: 'sourceType', header: 'Source Type' },
        { field: 'sourceName', header: 'Source Name' },
        ]
     }
  getInviteFormData = async () => {
    this.talentPoolService.getCandidateInviteFormDataFastApi('event_id').subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          const mapForms = (forms: any[]) => forms.map((element: any) => ({
            label: element.name,
            value: element.id,
          }));
          const eventCategories = res.body.event_categories || [];
          this.eventCategories = mapForms(eventCategories);
          const sourceTypeList = res.body.candidate_source_types || [];
          this.sourceTypeList = mapForms(sourceTypeList);
          const sourceNameList = res.body.candidate_sources || [];
          this.sourceNameList = mapForms(sourceNameList);
        }
      },
      error: () => {
      }
    });
  }

    ngOnInit() {
      this.getAllCategoryEvents();
      this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';
      this.schoolId = this.schoolData.school_id;
    }
    formatDate(dateString: string): string {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
    }
    sendInvite() {
      if(this._selectedCategory == 'single-invite'){
        this.loadSingleInvite = true;
        console.log("🚀🔥 ~ file: invite-candidate.component.ts:172 ~ InviteCandidateComponent ~ sendInvite ~ payload: any.this.inviteForm.value:",this.inviteForm.value,this.inviteForm.value.date_of_birth)
        let payload = {
          first_name: this.inviteForm.value.firstName,
          last_name: this.inviteForm.value.lastName,
          email: this.inviteForm.value.email,
          gender: this.inviteForm.value.gender.value,
          date_of_birth: this.formatDate(this.inviteForm.value.date_of_birth),
          mobile_number: this.inviteForm.value.mobileNumber,
          candidate_source_id: this.inviteForm.value.sourceName.value,
          candidate_source_type_id: this.inviteForm.value.sourceType.value,
          recruiter_id: '1',
        };

        this.talentPoolService.candidateSingleInviteFastApi(this.inviteForm.value.eventName.value, payload).subscribe({
          next: (res: any) => {
            if (res.status === 201) {
              this.loadSingleInvite = false; // Hide loading state
              this.userExist = false;
              this.existingUserList = [];
              this.inviteForm.reset();
              this.inviteSent.emit({ severity: 'success', summary: 'Success', detail: res.body.detail });
            }
            // if (res?.body.data?.message === 'User exist in other events') {
            //   this.userExist = true;
            //   this.disableInvite = true;
            //   this.existingUserList.push(res?.body.data?.body.data);
            //   this.messageService.add({ severity: 'info', summary: 'Message', detail: res?.body.data?.message });
            // } else if (res?.body.data?.message === 'Mail sending initiated') {
            //   this.userExist = false;
            //   this.existingUserList = [];
            //   this.inviteForm.reset();
            //   this.inviteSent.emit({ severity: 'success', summary: 'Success', detail: 'Invite sent successfully' });
            // } else if (res.status === 500 && res?.error === 'User already exists in the event') {
            //   this.inviteSent.emit({ severity: 'error', summary: 'Error', detail: res.error });
            // } else {
            //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
            // }
          },
          error: () => {
            this.loadSingleInvite = false;
          }
        });
      }
      else if(this._selectedCategory == 'bulk-invite'){
        this.loadSingleInvite = true;
        if(this.cohortUploaded){
          let payload = {
            Bucket_name: this.bucketName,
            file_name: this.fileUploadPayload.file_name,
          }
          this.talentPoolService.bulkFileUpload(payload).subscribe((res:any) =>{
            this.loadSingleInvite = false;
            if (res.status === 200) {
              let result = res.data;
              if(result.count.errored > 0 && result.count.validated > 0){
                this.countDiv = true;
                this.counts = result?.count;
                this.errorFile = result.erroredFile;
                this.messageService.add({ severity: 'warn', summary: 'Partially Correct', detail: 'File is Partially Correct!' });
                this.messageEnable = true;
              } 
              else if(result.count.errored > 0 && result.count.validated == 0){
                this.countDiv = true;
                this.counts = result.count;
                this.errorFile = result.erroredFile;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All the entries are invalid!' });
                this.messageEnable = true;
              }
              else if(result.count.errored == 0 && result.count.validated > 0){
                this.countDiv = true;
                this.counts = result.count;
                this.bulkValidatedData = [];
                this.bulkValidatedData = result.validatedData;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Uploaded Successfully!' });
                this.acknowledgeUsers = result?.acknowledgeUsers;
                this.existingUsers = result?.existingUsers;
                if(this.acknowledgeUsers.length > 0 || this.counts.existing > 0){
                  this.messageEnable = true;
                  this.bulkEnable = true;
                }
              }
            }
            else if(res.status == 500 && res.error ){
              this.countDiv = false;
              this.messageEnable = false;
              this.fileMessage = res.error;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error});
             }
          })
        }
        else {
          this.loadSingleInvite = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload a file to proceed!' });
        }
        
      }
    }

    sendBulkInvite(){
      if(this.cohortUploaded && this.bulkAcknowledgeUsers?.length > 0){
        this.loadSingleInvite = true;
          let payload = {
            acknowledgeData : this.bulkAcknowledgeUsers,
          }
          this.talentPoolService.acknowlegdeBulkInvite(payload).subscribe((res: any) => {
            this.loadSingleInvite = false;
            if(res.status == 200){
              this.cohortUploaded = false;
              this.countDiv = false;
              this.counts = 0;
              this.cohortFile = [];
              this.cohortFileCopy = [];
              this.bulkAcknowledgeUsers = [];
              this.acknowledgeUsers = [];
              this.existingUsers = [];
              this.bulkValidatedData = [];
              this.messageEnable = false;
              this.bulkEnable = false;
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Sent Successfully!' });
            }else{
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });  
            }
          })
        }    
        else{
          this.loadSingleInvite = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select a user to proceed' });  
        }
    }

    acknowledgeInvite() {
      this.loadSingleInvite = true;
      let payload = this.inviteForm.value;
      payload.mobileNumber = Number(payload.mobileNumber);
      payload.schoolId = localStorage.getItem('schoolData')
        ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
        : '';
        this.talentPoolService.acknowlegdeSingleInvite(payload).subscribe(res => {
        if(res.data.message == 'Acknowledgement done and Mail sending Initiated'){
          this.userExist = false;
          this.existingUserList = [];
          this.loadSingleInvite = false;
          this.inviteForm.reset();
          this.inviteSent.emit({ severity: 'success', summary: 'Success', detail: 'Moved to event Successfully' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
          this.loadSingleInvite = true;
        }
      })
    }

    getAllEvents = async (event: any) => {
      console.log("🚀🔥 ~ file: invite-candidate.component.ts:313 ~ InviteCandidateComponent ~ getAllEvents= ~ event:", event)
      this.loadEventList = true;
  
      this.talentPoolService.getCategoryWiseEventsFastApi(event.value.value).subscribe({
          next: (res: any) => {
              const eventList = res.body;
              this.eventList = eventList.map((element: any) => ({
                label: element.name,
                value: element.uuid,
              }));
              console.log("🚀🔥 ~ file: invite-candidate.component.ts:301 ~ InviteCandidateComponent ~ this.eventList=eventList.map ~ this.eventList:", this.eventList)
              this.loadEventList = false;
          },
          error: () => {
              this.loadEventList = false;
          }
      });
  }

    getEventName(eventId: string):string{
      let eventName = '';
      this.allCategoryEvents.forEach((item:any) => {
        if(item.eventId == eventId){
          eventName = item.eventTitle;
        }
      });
      return eventName;
    }

    getAllCategoryEvents = async() => {
      let techEvents = [];
      let nonTechEvents = [];

      let getTechEvents = () => {
        return new Promise((resolve, rejects) => {
          this.talentPoolService.getAllEventsByCategory('technical').subscribe(res => {
            techEvents = res?.data ? res.data : [];
            return resolve(techEvents)
          })
        })
      }
      let getNonTechEvents = () => {
        return new Promise((resolve, rejects) => {
          this.talentPoolService.getAllEventsByCategory('nonTechnical').subscribe(res => {
            nonTechEvents = res?.data ? res.data : [];
            return resolve(nonTechEvents);
          })
        })
      }

      this.allCategoryEvents = (await Promise.all([getTechEvents(), getNonTechEvents()])).flat();
    }

    restrictNumber(event: any) {
      let e: any = event || window.event; 
      let key = e.keyCode || e.which;
  
      if (key < 48 || key > 57 || event.target.value.length >= 10) {
          if (e.preventDefault) e.preventDefault();
          e.returnValue = false;
      }
    }

    //Bulk Invite
    sampleData() {
      this.talentPoolService.bulkInviteFileDownloadFastApi().subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            // Create a Blob from the response body
            const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
            // Extract the filename from Content-Disposition header
            const contentDisposition = res.headers.get('content-disposition');
            let filename = 'bulk_invite_data.xlsx';  // Default filename
    
            if (contentDisposition) {
              const matches = contentDisposition.match(/filename="(.+)"/);
              if (matches?.[1]) {
                filename = matches[1];
              }
            }
    
            // Use FileSaver.js to save the file locally
            saveAs(blob, filename);
          }
        },
        error: (err) => {
          console.error('Error downloading the file:', err);
        }
      });
     
    }

    cohortUpload(event: any){
      let cohortFile = {
        file : event.addedFiles,
        time : new Date()
      }
      this.cohortFileCopy = event.addedFiles;
      this.cohortFile[0] = cohortFile;
      this.fileUpload(this.cohortFileCopy[0],this.cohortFile[0])
    }
  
    onRemoveExcel(){
      this.cohortUploaded = false;
      this.countDiv = false;
      this.bulkEnable = false;
      this.counts = 0;
      this.cohortFile = [];
      this.cohortFileCopy = [];
      this.bulkAcknowledgeUsers = [];
      this.existingUsers = [];
      this.bulkValidatedData = [];
      this.messageEnable = false;
      this.acknowledgeUsers = [];
    }

    errorReportDownload(){
      window.open(this.errorFile,'_blank')
    }

    fileUpload(fileCopy: any, file : any) {
      let fileName = fileCopy.name.split(".");
      let fileExtension = fileName.pop();
      fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
      const pay = {
        Bucket_name: this.bucketName,
        file_name: `manage-candidate/bulk-invite/${this.schoolId}/${nanoid(10)}/${fileName}`,
        type: fileCopy.type,
      };
      const blobData: any = fileCopy;
      this.uploadingFile = true;
      this.fileUploadService.getSignedUrl(pay).subscribe(
        (url: any) => {
          if (url) {
            const json = url;
            this.fileUploadService.uploadUsingSignedUrl(
              json.data,
              blobData
            ).subscribe(
              (r: any) => {
                if (r.status === 200) {
                  this.fileUploadPayload = pay;
                  this.uploadingFile = false;
                  this.cohortUploaded = true;
                }
              });
          }
        });
    }

    eventChange(event:any){
      this.eventList.forEach((each:any) => {
        if(each.value == event.value.value){
          this.inviteForm.controls['eventId'].setValue(each.value);
        }
      });
    }
}
