import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-date-time-settings',
  templateUrl: './date-time-settings.component.html',
  styleUrls: ['./date-time-settings.component.css']
})
export class DateTimeSettingsComponent implements OnInit {

  isLoading: boolean = false;
  saveBtnLoading: boolean = false;
  clicked: boolean = false;

  timeValue: string = '';  
  dateValue: string = '';
  userPrivileges: Set<string> = new Set();

  dateTimeDetails: FormGroup = new FormGroup({
    dateValue: new FormControl('', Validators.required),
    timeValue: new FormControl('', Validators.required),
  });

  timeList: any = [
    { name: '12 hours', value: '12' },
    { name: '24 hours', value: '24' },
  ];

  dateList: any = [
    { name: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
    { name: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
    { name: 'DD/MMM/YY', value: 'DD/MMM/YY' },
    { name: 'DD-MMM-YY', value: 'DD-MMM-YY' },
    { name: 'DD/MMM/YYYY', value: 'DD/MMM/YYYY' },
    { name: 'DD-MMM-YYYY', value: 'DD-MMM-YYYY' },
    { name: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
    { name: 'YYYY-MM-DD', value: 'YYYY-MM-DD' }
  ];
  companyDetails: any;

  constructor(
    private settings: SettingsService,
    private messageService: MessageService,
    private globalService: GlobalServicesService
  ) { }

  ngOnInit(): void {
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });
    
    this.globalService.getUserProfileFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.companyDetails = {
            company_metadata: res.body.company_metadata,
            company_settings: res.body.company_settings,
            company_details: res.body.company_details,
          };
          if (this.companyDetails && this.companyDetails.company_settings) {
            let dateValue = this.companyDetails.company_settings.date_format || '';
            this.dateList.forEach((item: any) => {
              if (item.value === dateValue) {
                this.dateValue = item.name;  
              }
            });
      
            let timeValue = this.companyDetails.company_settings.time_format || '12';  // Use the current setting
            this.timeList.forEach((item: any) => {
              if (item.value === timeValue) {
                this.timeValue = item.name;  
              }
            });
      
            // Set form controls values after initialization
            this.dateTimeDetails.patchValue({
              dateValue: this.dateValue,
              timeValue: this.timeValue,
            });
          } else {
            // If no settings found, set default values
            this.dateValue = this.dateList[0].name;  // Default date format
            this.timeValue = '12 hours';  // Default time format
      
            this.dateTimeDetails.patchValue({
              dateValue: this.dateValue,
              timeValue: this.timeValue,
            });
          }
        }
      },
      error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to get details, try again later.' });
      },
    });
  }

  timeChange(field: any) {
    this.timeValue = field.name;
  }

  dateTimeUpdate() {
    this.clicked = true;
    this.saveBtnLoading = true;

    if (this.dateTimeDetails.valid) {
      let payload = {
        date_format: this.dateValue,
        time_format: this.timeValue === '12 hours' ? '12' : '24',  // Save internal value as '12' or '24'
      };

      this.settings.updateCompanyDataFastApi(payload).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            // Use payload directly to update localStorage
            const companyDetails = JSON.parse(localStorage.getItem('company-details') || '{}');
            companyDetails.company_settings.date_format = payload.date_format; // Use payload for date format
            companyDetails.company_settings.time_format = payload.time_format; // Use payload for time format
            let letter = ['D', 'Y'];
            companyDetails.company_settings.date_format = companyDetails.company_settings.date_format?.toString().replace( 
                new RegExp(letter.join('|'), 'g'),(match: string) => match.toLowerCase());
            companyDetails.company_settings.time_format = companyDetails.company_settings.time_format == '24' ? 'HH:mm' : 'hh:mm';
            localStorage.setItem('company-details', JSON.stringify(companyDetails));

            // Show success message
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.body.detail,
            });

            this.saveBtnLoading = false;
          }
        },
        error: (_err) => {
          this.saveBtnLoading = false;
        },
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: 'Please fill the required data!',
      });
    }
  }
}
