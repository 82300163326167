import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';

@Component({
  selector: 'app-workflow-manage-schedule',
  templateUrl: './workflow-manage-schedule.component.html',
  styleUrls: ['./workflow-manage-schedule.component.css'],
  providers: [MessageService]
})
export class WorkflowManageScheduleComponent implements OnInit {

  @Input() stage: any;
  @Input() EmailTemplateOptions: any;
  @Input() eventStageFormData: any;
  @Output() isTestSelected = new EventEmitter<any>();
  @Output() isFeedbackSelected = new EventEmitter<any>();
  @Output() emitTestId = new EventEmitter<any>();
  @Output() emitContestId = new EventEmitter<any>();
  @Output() isStageUpdate = new EventEmitter<boolean>();

  showSummary: boolean = false;
  isInEvent: boolean = false;
  // disabled: boolean = true;
  showMailSummary: boolean = false;
  manageTemplateLoad: boolean = false;
  manageContentLoad: boolean = false;
  contestList: Array<any> = [];
  formsList: Array<any> = [];
  documentationForms: Array<any> = [];
  feedbackForms: Array<any> = [];
  testForms: Array<any> = [];
  errorPlaceholder: any = [];
  selectedMailTemplate: any = {};
  savedSchedule: any;
  savedTemplateTitle: any;
  selectedContest: any = null;
  selectedForm: any = null;
  userPrivileges: any;
  selectedMailTemplateId: any;
  scheduledBy: string = '';
  testSource: string = '';
  timelimit: number = 0;
  testId: string = '';
  contestName: string = '';
  testName: string = '';
  formName: string = '';
  jobId: string = '';
  eventId: string = ''
  eventName: string = '';
  jobName: string = '';
  schoolCode: string = '';
  testDuration: string = '';

  constructor(private jobServices: JobServicesService, private router: Router, private messageService: MessageService, private globalService: GlobalServicesService, private emailServices: EmailServicesService,) {
    this.schoolCode = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_details?.subdomain : '';
    this.userPrivileges = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventStageFormData'] && this.eventStageFormData) {
      this.testForms = this.eventStageFormData.testForms;
      this.feedbackForms = this.eventStageFormData.feedbackForms;
      this.documentationForms = this.eventStageFormData.documentationForms;

      if ((this.eventStageFormData) && (this.stage)) {
        if (this.stage.type === 'Test' && this.stage.contest?.uuid) {
          this.contestName = this.testForms.filter((form) => form.value === this.stage.contest.uuid)[0].label;
        } else if (this.stage.type === 'Interview' && this.stage.form?.uuid) {
          this.formName = this.feedbackForms.filter((form) => form.value === this.stage.form.uuid)[0].label;
        } else if (this.stage.type === 'Screening' && this.stage.form?.uuid) {
          this.formName = this.documentationForms.filter((form) => form.value === this.stage.form.uuid)[0].label;
        }
      }
    }
  }
  
  async ngOnInit() {
    this.jobId = window.localStorage.getItem("selectedJobId") || '';
    this.eventId = window.localStorage.getItem("selectedEventId") || '';
    this.jobName = window.localStorage.getItem("selectedJobName") || '';
    this.eventName = window.localStorage.getItem("selectedEventName") || '';
    this.isInEvent = this.jobId === this.eventId;
    if(this.stage.type === 'Test'){
      this.showSummary = this.stage.contest?.uuid? true : false;
      this.contestName = this.stage.contest?.name;
    }else if(this.stage.type === 'Interview' || this.stage.type === 'Screening'){
      this.showSummary = this.stage.form?.uuid? true : false;
      this.formName = this.stage.form?.name
    }
    this.showMailSummary = this.stage.email_template?.id? true : false;
    if (this.showMailSummary) {
      this.selectedMailTemplate ={
      templateContent:"-",
      templateTitle: this.stage.email_template.name,
      };
    }
  }

  clearTestAndSchedules = () => {
    if (this.stage.type == 'Test') {
      this.selectedContest = null;
    } else if (this.stage.type == 'Interview' || this.stage.type == 'Screening') {
      this.selectedForm = null;
    }
  }

  saveStageManageContent = () => {
    this.manageContentLoad = true;
    let payload: any = {
      event_uuid: this.jobId,
      name: this.stage.name,
      type: this.stage.type,
    };
    if (this.stage.type === 'Test') {
      payload.contest_uuid = this.selectedContest;
      payload.email_template_id = this.stage.email_template.id;
    } else if (this.stage.type === 'Interview' || 'Screening') {
      payload.form_uuid = this.selectedForm;
      payload.email_template_id = this.stage.email_template.id;
    }
    this.jobServices.updateStageFastApi(this.stage.id, payload).subscribe({
      next: (res) => {
        if(res.status === 200){
          this.globalService.commonGrowl.next({ severity: 'success', summary: 'Success', detail: 'Content updated successfully!' });
          this.isStageUpdate.emit(true);
          this.manageContentLoad = false;
          this.showSummary = true;
        }
      },
      error: () => {
        this.isStageUpdate.emit(false);
        this.manageContentLoad = false;
      }
    })
  }

  validateTemplate() {
    const data = this.selectedMailTemplate;
    const template: string = data.templateContent ?? "";
    const testPlaceholder: any = { User: "${full_name}", UserId: "${user_id}", Test_Link: "${test_link}", Date: "${date}" };
    const interviewPlaceHolder: any = { User: "${full_name}", UserId: "${user_id}", interview_start_date: "${interview_start_time}",interview_end_date: "${interview_end_time}", interview_link: "${interview_link}" };
    const screeningPlaceHolder: any = { User: "${full_name}", UserId: "${user_id}", document_link: "${document_link}" };
    this.errorPlaceholder = [];
    if (this.stage.type === "Test") {

      for (const placeholder in testPlaceholder) {
        if (!template.includes(testPlaceholder[placeholder])) {
          this.errorPlaceholder.push(placeholder);
        }
      }
    }
    else if (this.stage.type === "Interview") {
      for (const placeholder in interviewPlaceHolder) {
        if (!template.includes(interviewPlaceHolder[placeholder])) {
          this.errorPlaceholder.push(placeholder);
        }
      }
    }
    else if (this.stage.type === "Screening") {
      for (const placeholder in screeningPlaceHolder) {
        if (!template.includes(screeningPlaceHolder[placeholder])) {
          this.errorPlaceholder.push(placeholder);
        }
      }
    }
  }
  clearEmailTemplate() {
    this.selectedMailTemplate = {};
  }
  async getSelectedMailTemplate(event: any){
    await this.getSpecificTemplateData(event);
    this.validateTemplate();
  }
  async getSpecificTemplateData(template: any,): Promise<void> {
    if (template.id) {
      return new Promise((resolve, reject) => {
        this.emailServices.getSpecificTemplateFastApi(template.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.selectedMailTemplate ={
                templateContent: response.body.body,
                templateTitle: response.body.name,
              };
              resolve();
            }
          },
          error: (err) => {
            reject(new Error('Error fetching specific template: ' + err));
          }
        });
      });
    }
  }
  saveEmailTemplate() {
    this.manageTemplateLoad = true;
    let payload:any = {
      event_uuid: this.jobId,
      name: this.stage.name,
      type: this.stage.type,
      email_template_id: this.selectedMailTemplateId.id ? this.selectedMailTemplateId.id : null
    };
    if (this.stage.type === 'Test') {
      payload["contest_uuid"] = this.stage.contest.uuid;
    }
    else if (this.stage.type === 'Interview' || 'Screening') {
      payload.form_uuid = this.stage.form.uuid;
    }
    this.jobServices.updateStageFastApi(this.stage.id, payload).subscribe({
      next: (res) => {
        if(res.status === 200){
        this.globalService.commonGrowl.next({ severity: 'success', summary: 'Success', detail: 'Template updated successfully!' });
        this.isStageUpdate.emit(true);
        this.manageTemplateLoad = false;
        this.showMailSummary = true;
        }
      },
      error: () => {
        this.isStageUpdate.emit(false);
        this.manageTemplateLoad = false;
      }
    })
  }
  openManageSchedule = () => {
    if(this.stage.type === 'Test'){
      this.selectedContest =  this.stage.contest?.name;
    }else if(this.stage.type === 'Interview'){
      this.selectedForm = this.stage.form?.name;
    }else if(this.stage.type === 'Screening'){
      this.selectedForm = this.stage.form?.name;
    }
    this.showSummary = false;
  }
}
