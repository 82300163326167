import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CountryStateCityService {

  private jsonUrl = 'assets/Json/countries-states-cities.json'; // Correct path

  constructor(private http: HttpClient) { }

  getCountryStateCityData(): Observable<any> {
    return this.http.get(this.jsonUrl);
  }

}
