import { Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { JobServicesService } from 'src/app/services/job-services.service';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
@Component({
  selector: 'app-workflow-ruleset',
  templateUrl: './workflow-ruleset.component.html',
  styleUrls: ['./workflow-ruleset.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class WorkflowRulesetComponent implements OnInit, OnChanges {

  @Input() stage: any;
  @Input() allStages: any;
  @Input() isTestSelected: any;

  stageTriggerOptions: any = [];
  stageActionOptions: any = [];
  savedWorkflowIds: Array<string> = [];

  sendMailTemplate: any = [];
  specificConditions: any = [];
  stringBasedOperators: Array<any> = [];
  numberBasedOperators: Array<any> = [];
  multiValueOperators: Array<any> = [];
  booleanBasedOperators: Array<any> = [];
  dateBasedOperators: Array<any> = [];
  triggerFields: Array<any> = [];
  stage_rule_condition: any = [];
  stage_condition_operators: any = [];
  stage_rules: any = [];
  selectedTriggers: any[] = [];
  stage_rule_trigger_options: any = [];
  stage_rule_action_options: any = [];

  stageConditions: string[] = [];
  logicConditions: string[] = [];

  jobId: string = '';
  userPrivileges: any;
  eventId: string = '';

  loadingButton: boolean = false;
  loadActionOptions: boolean = false;
  stageError: boolean = false;
  pageLoading: boolean = false;
  loadConditionOptions: boolean = false;

  constructor(
    private jobServices: JobServicesService,
    private emailServices: EmailServicesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalServicesService
  ) {
    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.numberBasedOperators = [
      { sign: '==', clause: '==' },
      { sign: '!=', clause: '!=' },
      { sign: '>=', clause: '>=' },
      { sign: '<=', clause: '<=' },
      { sign: '>', clause: '>' },
      { sign: '<', clause: '<' },
    ]
    this.dateBasedOperators = [
      { sign: '==', clause: '==' },
      { sign: '!=', clause: '!=' },
      { sign: '>=', clause: '>=' },
      { sign: '<=', clause: '<=' },
      { sign: '>', clause: '>' },
      { sign: '<', clause: '<' },
    ]

    this.stringBasedOperators = [
      { sign: '==', clause: '==' },
      { sign: '!=', clause: '!=' },
      { sign: 'Starts with', clause: 'starts_with' },
      { sign: 'Ends with', clause: 'ends_with' },
      { sign: 'Contains', clause: 'contains' },
      { sign: 'Not Contains', clause: 'not_contains' },
      { sign: 'Of Length', clause: 'of_length' },
    ]

    this.multiValueOperators = [
      { sign: 'Contains', clause: 'contains' },
      { sign: 'Not Contains', clause: 'not_contains' },
      { sign: 'Of Length', clause: 'of_length' },
    ]

    this.booleanBasedOperators = [
      { sign: '==', clause: '==' },
      { sign: '!=', clause: '!=' },
    ]
  }
  getEmailTemplate = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.emailServices.getTemplatesFastApi('').subscribe({
        next: (response) => {
          if (response.status === 200) {
            let templateList = response.body.email_templates ? response.body.email_templates : [];
            this.sendMailTemplate = templateList.map((item: any) => ({
              id: item.id,
              isEditable: item.is_editable,
              name: item.name,
            }));
            resolve();
          }
        },
        error: (err) => {
          reject(new Error('Error fetching email templates: ' + err));
        }
      });
    });
  }
  async ngOnInit() {
    this.jobId = window.localStorage.getItem('selectedJobId') || '';
    this.pageLoading = true;

    this.userPrivileges = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
  }

  getStageRuleFormData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.jobServices.getStageRuleFormDataFastApi(this.stage.id, this.eventId).subscribe({
        next: (result: any) => {
          this.stageTriggerOptions = result?.body ? result.body?.triggers : [];
          this.stage_rule_condition = result?.body ? result.body?.condition_types : [];
          this.stage_condition_operators = result?.body ? result.body?.condition_operators : [];
          this.stage_rule_trigger_options = result?.body ? result.body?.dropdown_values : [];
          this.stage_rule_action_options = result?.body ? result.body?.actions : [];
          this.pageLoading = false;
          resolve();
        },
        error: () => {
          this.pageLoading = false;
          reject(new Error('Error fetching stage rule form data'));
        }
      });
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes['stage']){
      await this.getEmailTemplate();
      await this.getStageRuleFormData();
      await this.getStageRuleList();
    }
  }
  getStageRuleList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.pageLoading = true;
      this.jobServices.getAllRulesFastApi(this.stage.id, this.eventId).subscribe({
        next: (result: any) => {
          if (result.status === 200) {
            const stageRules = result.body || [];
            
            if (stageRules.length > 0) {
              this.savedWorkflowIds = stageRules.map((rule: any) => rule.id);
              Promise.all(stageRules.map((apiRule: any) => this.mapApiRuleToStageRule(apiRule))).then(mappedRules => {
                this.stage_rules = mappedRules;
                console.log("Mapped stage rules:", this.stage_rules);
                resolve();
              });
            } else {
              this.addRule(this.stage);
              resolve();
            }
            this.pageLoading = false;
          } else {
            this.pageLoading = false;
            reject(new Error('Failed to fetch rules'));
          }
        },
        error: (error) => {
          this.pageLoading = false;
          console.error('Error in fetching rules', error);
          reject(new Error('Error in fetching rules'));
        }
      });
    });
  }
  async mapApiRuleToStageRule(apiRule: any): Promise<any> {
    // Map the trigger
    const trigger = this.stageTriggerOptions.find((option: any) => option.id === apiRule.trigger.id) || {
      id: apiRule.trigger.id,
      name: apiRule.trigger.value,
      form_type: ''
    };
  
    // Map the stage condition
    const stageCondition = this.stage_rule_condition.find((condition: any) => condition.id === apiRule.applicable.id) || {
      id: apiRule.applicable.id,
      name: apiRule.applicable.value
    };
    // Initialize specificConditions for this rule
    this.specificConditions = [];
    const triggerFormType = trigger.form_type || '';
    this.triggerFields = this.stage_rule_trigger_options[triggerFormType] || [];
    this.triggerFields.forEach((field: any) => {
      this.specificConditions.push({
        name: field.field_label,
        label: field.field_name,
        inputType: field.field_type,
        options: field?.field_options ? field.field_options : []
      });
    });

    // Initialize variables
    let conditionsList = [];
    let elseActionsList = [];
    let logicCondition = this.stage_condition_operators.find((operator: any) => operator.name === "All");
    let logicOperator = [];
  
    if (stageCondition.name === 'For All') {
      // Set default empty values when stageCondition is "For All"
      conditionsList = [{
        specific: this.specificConditions,
        operator: [],
        values: {
          conditionSource: { name: '', label:'', inputType: '', options: [] },
          conditionClause: '',
          conditionValue: { value: '', type: '' },
        }
      }];
  
      elseActionsList = [{
        actions: this.stage_rule_action_options,
        template: [],
        values: {
          actionName: '',
          actionTemplate: '',
          actionTemplateContent: '',
        }
      }];
  
      logicOperator = [];
    } else {
      // Map the logic condition
      logicCondition = this.stage_condition_operators.find((operator: any) => operator.id === apiRule.conditions[0]?.operator.id) || {
        id: apiRule.conditions[0]?.operator.id,
        name: apiRule.conditions[0]?.operator.value
      };
      // Map the conditions list
      conditionsList = (apiRule.conditions[0]?.rules || []).map((rule: any) => {
        const conditionSourceDetails = this.getConditionSourceDetails(rule.property);
        const conditionClause = this.getOperatorsForInputType(conditionSourceDetails.inputType).find((clause: any) => clause.clause === rule.condition) || {
          clause: rule.condition,
          sign: rule.condition
        };
        return {
          specific: this.specificConditions,
          operator: this.getOperatorsForInputType(conditionSourceDetails.inputType),
          values: {
            conditionSource: {
              name: conditionSourceDetails.name,
              label: rule.property,
              inputType: conditionSourceDetails.inputType,
              options: conditionSourceDetails.options
            },
            conditionClause: conditionClause,
            conditionValue: {
              value: rule.value,
              type: conditionSourceDetails.inputType
            }
          }
        };
      });
  
      // Map the else actions list
      elseActionsList = await Promise.all(
        (apiRule.actions.negative || []).map(async (actionItem: any) => {
          const actionName = this.stage_rule_action_options.find(
            (actionOption: any) => actionOption.id === actionItem.action.id
          ) || {
            id: actionItem.action.id,
            name: actionItem.action.value
          };
          const actionTemplate = this.getActionTemplate(actionItem);
          const actionTemplateContent = await this.getActionSpecificTemplateData(actionItem);
  
          let template = [];
          if (actionName.name === 'Send Email' || actionName.name === 'Send Test Link' || actionName.name === 'Send Document Form') {
            template = this.sendMailTemplate;
          } else if (actionName.name === 'Move Stage') {
            template = this.allStages.filter((stage: any) => stage.id !== this.stage.id);
          }
          return {
            actions: this.stage_rule_action_options,
            template: template,
            values: {
              actionName: actionName,
              actionTemplate: actionTemplate,
              actionTemplateContent: actionTemplateContent,
            }
          };
        })
      );
  
      // Populate logicOperator
      if (conditionsList.length > 1) {
        for (let i = 0; i < conditionsList.length - 1; i++) {
          logicOperator.push(logicCondition.name);
        }
      }
    }
  
    // Map the actions list
    const actionsList = await Promise.all(
      (apiRule.actions.positive || []).map(async (actionItem: any) => {
        const actionName = this.stage_rule_action_options.find(
          (actionOption: any) => actionOption.id === actionItem.action.id
        ) || {
          id: actionItem.action.id,
          name: actionItem.action.value
        };
        const actionTemplate = this.getActionTemplate(actionItem);
        const actionTemplateContent = await this.getActionSpecificTemplateData(actionItem);
  
        let template = [];
        if (actionName.name === 'Send Email' || actionName.name === 'Send Test Link' || actionName.name === 'Send Document Form') {
          template = this.sendMailTemplate;
        } else if (actionName.name === 'Move Stage') {
          template = this.allStages.filter((stage: any) => stage.id !== this.stage.id);
        }
        return {
          actions: this.stage_rule_action_options,
          template: template,
          values: {
            actionName: actionName,
            actionTemplate: actionTemplate,
            actionTemplateContent: actionTemplateContent,
          }
        };
      })
    );
  
    // Construct the new rule object
    return {
      id: apiRule.id,
      isShowSummary: true,
      stageId: apiRule.stage_uuid,
      trigger: trigger,
      conditionsList: conditionsList,
      stageCondition: stageCondition,
      logicCondition: logicCondition,
      logicOperator: logicOperator,
      displayAction: true,
      displayElseAction: false,
      actionsList: actionsList,
      elseActionsList: elseActionsList
    };
  }
  
  
  getConditionSourceDetails(propertyName: string): any {
    // Find the field in your triggerFields or stage_rule_trigger_options
    let field = null;
    for (const key in this.stage_rule_trigger_options) {
      const fields = this.stage_rule_trigger_options[key];
      field = fields.find((f: any) => f.field_name === propertyName);
      if (field) break;
    }
    if (field) {
      return {
        name: field.field_label,
        inputType: field.field_type,
        options: field.field_options || []
      };
    }
    return {
      name: '',
      inputType: '',
      options: []
    };
  }
  getActionTemplate(actionItem: any): any {
    if (actionItem.email_template_id) {
      const template = this.sendMailTemplate.find((t: any) => t.id === actionItem.email_template_id);
      return template || { id: actionItem.email_template_id, name: 'Unknown Template' };
    } else if (actionItem.move_stage_uuid) {
      const stage = this.allStages.find((s: any) => s.id === actionItem.move_stage_uuid);
      return stage || { id: actionItem.move_stage_uuid, name: 'Unknown Stage' };
    }
    return null;
  }
  getActionSpecificTemplateData(actionItem: any): Promise<string> {
    return new Promise((resolve, reject) => {
      if (actionItem.email_template_id) {
        this.emailServices.getSpecificTemplateFastApi(actionItem.email_template_id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              resolve(response.body.body);
            } else {
              resolve('');
            }
          },
          error: (err) => {
            console.error('Error fetching specific template: ' + err);
            resolve('');
          }
        });
      } else {
        resolve('');
      }
    });
  }
  
  getOperatorsForInputType(inputType: string): any {
    if(inputType === 'Input' || inputType === 'Email' || inputType === 'Dropdown' || inputType === 'Radio' || inputType === 'File' || inputType === 'Mobile' || inputType === 'TextArea'){
      return this.stringBasedOperators;
    }
    if(inputType === 'Number' || inputType === 'Percentage'){
      return this.numberBasedOperators;
    }
    if(inputType === 'Multi-dropdown'){
      return this.multiValueOperators;
    }
    if(inputType === 'Checkbox'){
      return this.booleanBasedOperators;
    }
    if(inputType === 'Date Picker'){
      return this.dateBasedOperators;
    }
    return [];
  }
  getTriggers = (trigger: any, rule_index?: any) => {
    this.specificConditions = [];
    this.loadConditionOptions = true;
    this.triggerFields = this.stage_rule_trigger_options[trigger.form_type];
    this.triggerFields.forEach((field: any) => {
      this.specificConditions.push({ name: field.field_label, label: field.field_name, inputType: field.field_type, options: field?.field_options ? field.field_options : [] });
    })
    this.stage_rules[rule_index].conditionsList[0].specific = this.specificConditions;
    this.loadConditionOptions = false;
  }
  
  addNewAction = (stage_rule_action_list:any) => {
    stage_rule_action_list.push({
      actions: this.stage_rule_action_options, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
      }
    })
  }

  addNewElseAction = (stage_rule_else_action_list:any) => {
    stage_rule_else_action_list.push({
      actions: this.stage_rule_action_options, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
      }
    })
  }

  removeAction = (action: any,rule_index:any) => {
    this.stage_rules[rule_index].actionsList = this.stage_rules[rule_index].actionsList.filter((val: any) => val !== action)
  }

  removeElseAction = (action: any,rule_index:any) => {
    this.stage_rules[rule_index].elseActionsList = this.stage_rules[rule_index].elseActionsList.filter((val: any) => val !== action)
  }

  addCondition = (condition: any, rule_index: number) => {
    // Ensure conditionsList and logicOperator exist for the specific stage_rule
    if (!this.stage_rules[rule_index].conditionsList) {
      this.stage_rules[rule_index].conditionsList = [];
    }
    
    if (!this.stage_rules[rule_index].logicOperator) {
      this.stage_rules[rule_index].logicOperator = [];
    }
    
    const index = this.stage_rules[rule_index].conditionsList.indexOf(condition);
    // Add a new condition after the current one
    this.stage_rules[rule_index].conditionsList.splice(index + 1, 0, {
      specific: this.specificConditions,
      operator: [],
      values: {
        conditionSource: { name: '',label:'', inputType: '', options: [] },
        conditionClause: '',
        conditionValue: { value: '', type: '' },
      }
    });
    
    // Add a corresponding logic operator
    this.stage_rules[rule_index].logicOperator.splice(index + 1, 0, '');
    console.log("🚀🔥 ~ file: workflow-ruleset.component.ts:438 ~ WorkflowRulesetComponent ~ this.stage_rules:", this.stage_rules)
  }


  removeCondition = (condition: any, rule_index: number) => {
    // Ensure conditionsList exists and has more than one condition
    if (!this.stage_rules[rule_index].conditionsList || this.stage_rules[rule_index].conditionsList.length <= 1) {
      return;  // Do not allow removal if it's the last condition
    }

    const index = this.stage_rules[rule_index].conditionsList.indexOf(condition);

    // Remove the condition from conditionsList
    this.stage_rules[rule_index].conditionsList.splice(index, 1);

    // Remove the corresponding logic operator
    if (this.stage_rules[rule_index].logicOperator) {
      this.stage_rules[rule_index].logicOperator.splice(index - 1, 1);
    }
  }



  clearRuleSet = (rule: any) => {
    rule.trigger = { name: '', id: '', form_type: '' };
    rule.conditionsList = [{
      specific: this.specificConditions, operator: [],
      values: {
        conditionSource: { name: '', inputType: '', options: [] },
        conditionClause: '',
        conditionValue: { value: '', type: '' },
      }
    }];
    rule.stageCondition = this.stage_rule_condition.find((condition: any) => condition.name === 'For All');
    rule.logicCondition = this.stage_condition_operators.find((operator: any) => operator.name === 'All');
    rule.logicOperator = [];
    rule.displayAction = true;
    rule.displayElseAction = false;
    rule.actionsList = [{
      actions: this.stage_rule_action_options, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
      }
    }];
    rule.elseActionsList = [{
      actions: this.stage_rule_action_options, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
      }
    }];
  };
  

  selectOperatorOptions = (event: any, condition: any) => {
    console.log("🚀🔥 ~ file: workflow-ruleset.component.ts:515 ~ WorkflowRulesetComponent ~ condition:", condition)
    const inputType = event.value.inputType;
    if (inputType === "Input" || inputType === "Email" || inputType === "Dropdown" || inputType === "Radio" || inputType === "File" || inputType === "Mobile" || inputType === "TextArea") {
      condition.operator = this.stringBasedOperators
    }
    if (inputType === "Number" || inputType === "Percentage") {
      condition.operator = this.numberBasedOperators
    }
    if (inputType === "Multi-dropdown") { condition.operator = this.multiValueOperators }
    if (inputType === "Checkbox") { condition.operator = this.booleanBasedOperators }
    if (inputType === "Date Picker") { condition.operator = this.dateBasedOperators }
  }

  selectTemplateOptions = (actionEventId: any, action: any) => {
    action.values.actionTemplateContent = '';
    action.values.actionTemplate = '';
    switch (actionEventId) {
      case "Move Stage": {
        this.stageError = !this.allStages.length;
        this.allStages = this.allStages.filter((stage: any) => stage.stageId !== this.stage.stageId);
        action.template = this.allStages;
        break;
      }
      case "Send Email": action.template = this.sendMailTemplate; break;
      case "Send Test Link": action.template = this.sendMailTemplate; break;
      case "Send Document Form": action.template = this.sendMailTemplate; break;
      default: action.template = [];
    }
  }

  setActionValues = async (event: any, action: any) => {
    if (['Send Email', 'Send Test Link', 'Send Document Form'].includes(action.actionName.name)) {
      await this.getSpecificTemplateData(event, action);
    }
  }
 
  async getSpecificTemplateData(template: any,action:any): Promise<void> {
    if (template.id) {
      return new Promise((resolve, reject) => {
        this.emailServices.getSpecificTemplateFastApi(template.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              action.actionTemplateContent = response.body.body;
              resolve(response.body.body);
            }
          },
          error: (err) => {
            reject(new Error('Error fetching specific template: ' + err));
          }
        });
      });
    }
  }


  removeRule = (workflowId: string,event: any, rule_index?: any) => {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.savedWorkflowIds.includes(workflowId)) {
          this.pageLoading = true;
          this.jobServices.deleteRuleFastApi(workflowId,this.stage.id,this.jobId).subscribe({
            next: (result: any) => {
              if(result.status === 200){
                this.pageLoading = false;
                if (result.body) {
                  this.stage_rules = this.stage_rules.filter((rule: any) => rule.id !== workflowId);
                  this.messageService.add({ severity: 'info', summary: 'Deleted', detail: result.body.detail });
                  this.getStageRuleList();
                }
              }
            },
            error: () => {
              this.pageLoading = false;
            }
          })
        } else{
          if(rule_index !== undefined)
          this.stage_rules = this.stage_rules.filter((_rule: any,index: any) => index !== rule_index);
        }
      },
      reject: () => { }
    });
  }
  generateWorkflowPayload(rule_index:any) {
    const stageRule = this.stage_rules[rule_index];
  
    // Map logicCondition to operator code    
    const operatorMapping: any = this.stage_condition_operators.reduce((acc: any, operator: any) => {
      acc[operator.name] = operator.id;
      return acc;
    }, {});

    const payload:any = {
      trigger: stageRule.trigger.id,
      applicable: stageRule.stageCondition.id,
      conditions: [],
      actions: {
        positive: [],
        negative: []
      }
    };
    if (stageRule.stageCondition.name === 'Matching Condition') {
      // Map conditions
      const conditions: any = {
        operator: operatorMapping[stageRule.logicCondition.name] || 10,
        rules: []
      };

      stageRule.conditionsList.forEach((conditionItem: any) => {
        const rule = {
          property: conditionItem.values.conditionSource?.label || '',
          condition: conditionItem.values.conditionClause?.clause || '',
          value: conditionItem.values.conditionValue?.value || ''
        };
        conditions.rules.push(rule);
      });

      payload.conditions.push(conditions);
    }
    // Map positive actions from actionsList
    stageRule.actionsList.forEach((actionItem:any) => {
      const actionData = actionItem.values;
      const action:any = {
        action: actionData.actionName.id
      };
  
      // Check if action is "Send Email" and include email_template_id
      if (actionData.actionTemplateContent) {
        action['email_template_id'] = actionData.actionTemplate.id;
      }
  
      // Check if action is "Move Stage" and include move_stage_uuid
      if (!actionData.actionTemplateContent) {
        if (actionData.actionTemplate && actionData.actionTemplate.uuid) {
          action['move_stage_uuid'] = actionData.actionTemplate.uuid;
        }
      }
  
      payload.actions.positive.push(action);
    });
    if (stageRule.stageCondition.name === 'Matching Condition') {
      // Map negative actions from elseActionsList
      stageRule.elseActionsList.forEach((actionItem: any) => {
        const actionData = actionItem.values;
        const action: any = {
          action: actionData.actionName.id
        };

        // Check if action is "Send Email" and include email_template_id
        if (actionData.actionTemplateContent) {
          action['email_template_id'] = actionData.actionTemplate.id;
        }

        // Check if action is "Move Stage" and include move_stage_uuid
        if (!actionData.actionTemplateContent) {
          if (actionData.actionTemplate && actionData.actionTemplate.uuid) {
            action['move_stage_uuid'] = actionData.actionTemplate.uuid;
          }
        }

        payload.actions.negative.push(action);
      });
    }else{
      payload.actions.negative = [];
    }
    return payload;
  
  }
  

  async saveWorkflow(workFlowId:any,rule_index: any) {
    this.loadingButton = true;
    const payload = this.generateWorkflowPayload(rule_index);
    if (this.savedWorkflowIds.includes(workFlowId)) {
      this.jobServices.updateRuleFastApi(workFlowId, this.stage.id, this.eventId, payload).subscribe({
        next: (response) => {
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
          this.getStageRuleList();
          this.loadingButton = false;
        },
        error: () => {
          this.loadingButton = false;
        }
      })
    } else {
      this.jobServices.saveRuleFastApi(this.stage.id, this.eventId, payload).subscribe({
        next: (res: any) => {
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: res.body.detail,
          });
          this.getStageRuleList();
          this.loadingButton = false;
        },
        error: () => {
          this.loadingButton = false;
        }
      })
    }
  }

  checkValidation = (stage_rule: any) => {
    let inValid: boolean = false;
    // Validate trigger
    if (!stage_rule.trigger.name) {
      inValid = true;
    }

    // Validate stage condition
    if (!stage_rule.stageCondition.name) {
      inValid = true;
    }

    // Validate conditions if stageCondition is 'Matching Condition'
    if (stage_rule.stageCondition.name === 'Matching Condition') {
      if (stage_rule.conditionsList.length === 0) {
        inValid = true;
      } else {
        stage_rule.conditionsList.forEach((condition: any) => {
          if (
            !condition.values.conditionSource?.name ||
            !condition.values.conditionClause?.clause ||
            !condition.values.conditionValue?.value
          ) {
            inValid = true;
          }
        });
      }
      // Validate logic condition
      if (!stage_rule.logicCondition.name) {
        inValid = true;
      }
    }

    // Validate positive actions
    if (stage_rule.actionsList.length === 0) {
      inValid = true;
    } else {
      stage_rule.actionsList.forEach((action: any) => {
        if (!action.values.actionName?.name) {
          inValid = true;
        } else if (action.template.length > 0 && !action.values.actionTemplate) {
          inValid = true;
        }
      });
    }

    // Validate negative actions if they exist
    if (stage_rule.stageCondition.name === 'Matching Condition') {
      stage_rule.elseActionsList.forEach((action: any) => {
        if (!action.values.actionName?.name) {
          inValid = true;
        } else if (action.template.length > 0 && !action.values.actionTemplate) {
          inValid = true;
        }
      });
    }

    return inValid;
  };
  

  addRule = (stage: any) => {
    const newRule = {
      id: null,
      stageId: stage.id,
      isShowSummary: false,
      trigger: { name: '', id: '', form_type: '' },
      conditionsList: [{
        specific: [],
        operator: [],
        values: {
          conditionSource: { name: '', label:'', inputType: '', options: [] },
          conditionClause: '',
          conditionValue: { value: '', type: '' },
        }
      }],
      stageCondition: this.stage_rule_condition.find((operator: any) => operator.name === 'For All'),
      logicCondition: this.stage_condition_operators.find((operator: any) => operator.name === "All"),
      logicOperator: [],
      displayAction: true,
      displayElseAction: false,
      actionsList: [{
        actions: this.stage_rule_action_options, template: [], values: {
          actionName: '',
          actionTemplate: '',
          actionTemplateContent: '',
        }
      }],
      elseActionsList: [{
        actions: this.stage_rule_action_options, template: [], values: {
          actionName: '',
          actionTemplate: '',
          actionTemplateContent: '',
        }
      }]
    };
    this.stage_rules.push(newRule);
    console.log("🚀🔥 ~ file: workflow-ruleset.component.ts:818 ~ WorkflowRulesetComponent ~ this.stage_rules:", this.stage_rules)
  };
  cancelRuleSet(stage_rule: any, rule_index: number) {
    if (stage_rule.id == null) {
      // This was a new rule that hasn't been saved yet; remove it from the list
      this.stage_rules.splice(rule_index, 1);
    } else if (stage_rule.originalCopy) {
      // Restore the original rule from the saved copy
      const original = stage_rule.originalCopy;
      for (const prop in original) {
        if (original.hasOwnProperty(prop)) {
          stage_rule[prop] = JSON.parse(JSON.stringify(original[prop]));
        }
      }
      delete stage_rule.originalCopy;
      stage_rule.isShowSummary = true;
    } else {
      // No original copy exists; just switch back to summary view
      stage_rule.isShowSummary = true;
    }
  }
  editRule(stage_rule: any) {
    // Save a deep copy of the rule
    stage_rule.originalCopy = JSON.parse(JSON.stringify(stage_rule));
    stage_rule.isShowSummary = false;
    console.log("🚀🔥 ~ file: workflow-ruleset.component.ts:839 ~ WorkflowRulesetComponent ~ editRule ~ stage_rule:", stage_rule.stageCondition)
    
  }
  
}
