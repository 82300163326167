import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})
export class JobServicesService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;


  options() {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '',
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }
  constructor(private http: HttpClient,private globalService: GlobalServicesService) { }

  //Fast APIs
  getUsersFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);

    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/users`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getUserByIdFastApi(userId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/users/${userId}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getUserByRoleIdFastApi(roleId: string): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('role_id', roleId);
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/users/list`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
}

  createUserFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/users`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  updateUserFastApi(payload: any, userId: string): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.fast_api_url}/users/${userId}`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  deleteUserFastApi(userId: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`${this.fast_api_url}/users/${userId}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getUserFormDataFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/users/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  //Candidate Fast APIs
  getCandidatesFastApi(eventId: string, page = 1, size = 10, orderBy = 'asc', sortBy = 'candidate_uuid', search?: any): Observable<HttpResponse<any>> { // Updated parameter and default sortBy
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);
      if (search) {
        params = params.set('s', search);
      }

    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/candidates`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // Stage Fast APIs
  getEventStageFormDataFastApi(eventId:string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getAllStagesFastApi(eventId?: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getStageFastApi(eventId?: string, stageId?: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/stages/${eventId}/${stageId}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  addStageFastApi(payload: any,eventId:string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteStageFastApi(stageId: string, eventId:string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateStageFastApi(stageId: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.fast_api_url}/events/${payload.event_uuid}/stages/${stageId}`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // STAGE RULE APIs
  getStageRuleFormDataFastApi(stageId: string,eventId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/rules/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getAllRulesFastApi(stageId: string,eventId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/rules`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  saveRuleFastApi(stageId: string,eventId: string,payload?: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/rules`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteRuleFastApi(ruleId: string,stageId: string,eventId: string,): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/rules/${ruleId}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateRuleFastApi(ruleId: string,stageId: string,eventId: string, payload?: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/stages/${stageId}/rules/${ruleId}`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }  

  getInterviewCandidatesByStatusFastApi(eventId: string, status: string, page = 1, size = 10, search?: any , orderBy = 'asc', sortBy = 'email'): Observable<HttpResponse<any>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('interview_status', status)
      .set('order_by', orderBy)
      .set('sort_by', sortBy);

      if (search) {
        params = params.set('s', search);
      }
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/interviews`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  assignInterviewerFastApi(eventId: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/interviews/assign-interviewer`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  // JOB APIs
  getAllJobs(pageNumber?: number, pageSize?: number): Observable<any> {
    return this.http.get(this.api_url + '/jobs?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  getFilterJobs(pageNumber?: number, pageSize?: number, status?: string): Observable<any> {
    return this.http.get(this.api_url + '/jobs?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&status=' + status, this.options());
  }

  getJob(id: string): Observable<any> {
    return this.http.get(this.api_url + '/jobs/' + id, this.options());
  }

  getJobEvent(eventJobType: string, jobId: string, isInEvent?: boolean): Observable<any> {
    return this.http.get(this.api_url + '/' + eventJobType + '/' + jobId + (isInEvent ? '/' + jobId : ''), this.options());
  }

  addJob(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/jobs', payload, this.options());
  }

  updateJob(payload: any, jobId: string): Observable<any> {
    return this.http.put(this.api_url + '/jobs/' + jobId, payload, this.options());
  }

  deleteJob(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/jobs/' + id, this.options());
  }

  getSchool(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.options());
  }

  createDepartment(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/schools/department', payload, this.options());
  }

  getSpecificJobCandidates(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/job-user-mapping/getSpecificJobCandidates', payload, this.options())
  }

  getAssignedCandidatesById(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/job-user-mapping/getAssignedCandidatesByEvnId', payload, this.options())
  }
  Assignedrecruiter(ids: any, recruiterId: string, userIds: any, eventId: string): Observable<any> {
    let payload = {
      ids,
      recruiterId: recruiterId,
      userIds: userIds,
      eventId: eventId
    }
    return this.http.put(this.api_url + '/job-user-mapping/Assignedrecruiter', payload, this.options())
  }

  getUnAssignedCandidatesById(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/job-user-mapping/getUnAssignedCandidatesByEvnId', payload, this.options())
  }

  // COLUMN APIs
  saveColumns(schoolId: string, payload: any): Observable<any> {
    return this.http.put<any>(this.api_url + '/column/job/' + schoolId, payload, this.options());
  }

  updateColumns(schoolId: string, columnId: string, payload: Array<any>): Observable<any> {
    return this.http.put(this.api_url + '/column/job/' + schoolId + '/' + columnId, payload, this.options());
  }

  deleteColumns(schoolId: string, columnId: string): Observable<any> {
    return this.http.delete(this.api_url + '/column/job/' + schoolId + '/' + columnId, this.options());
  }

  saveColumnCandidate(jobId: string, payload: Array<any>): Observable<any> {
    return this.http.put<any>(this.api_url + '/jobs/column/' + jobId, payload, this.options());
  }

  deleteColumnCandidates(jobId: string, columnId: string): Observable<any> {
    return this.http.delete(this.api_url + '/jobs/column/' + jobId + '/' + columnId, this.options());
  }

  // USER APIs
  createCandidate(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/addCandidate/', payload, this.options());
  }

  createUser(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/add/recruiter', payload, this.options());
  }

  getAllUser(pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    return this.http.get(this.api_url + '/users/?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  getUserByEmail(id: string): Observable<any> {
    return this.http.get(this.api_url + '/users/' + id, this.options());
  }

  getCandidatesByJob(jobId?: string, pageNumber?: number, pageSize?: number,): Observable<any> {
    return this.http.get(this.api_url + '/users/' + jobId + '/candidate/?pageSize=' + pageSize + '&pageNumber=' + pageNumber, this.options());
  }

  getRecruitersByJob(jobId?: string): Observable<any> {
    return this.http.get(this.api_url + '/users/' + jobId + '/recruiter/', this.options());
  }

  getAllRecruiters(userType: string): Observable<any> {
    return this.http.get(this.api_url + '/users/userType/all/' + userType, this.options());
  }

  addRecruiter(jobId?: string, payload?: any): Observable<any> {
    return this.http.put(this.api_url + '/jobs/recruiter/' + jobId, payload, this.options());
  }

  addAccessManagement(eventJobType: string, jobId: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/' + eventJobType + '/accessManagement/' + jobId, payload, this.options());
  }

  removeAccessManagement(eventJobType: string, jobId: string, role_id: any, role_type: any): Observable<any> {
    return this.http.delete(this.api_url + '/' + eventJobType + '/delete/' + jobId + '/' + role_type + '/' + role_id, this.options());
  }

  addManager(jobId?: string, payload?: any): Observable<any> {
    return this.http.put(this.api_url + '/jobs/hiringManager/' + jobId, payload, this.options());
  }

  removeRecruiter(jobId?: string, recruiterId?: string): Observable<any> {
    return this.http.delete(this.api_url + '/jobs/recruiter/' + jobId + '/' + recruiterId, this.options());
  }

  removePanel(jobId?: string, panelId?: string): Observable<any> {
    return this.http.delete(this.api_url + '/event/delete/' + jobId + '/' + 'panelMember/' + panelId, this.options());
  }

  removeManager(jobId?: string, recruiterId?: string): Observable<any> {
    return this.http.delete(this.api_url + '/jobs/hiringManager/' + jobId + '/' + recruiterId, this.options());
  }

  // STAGE APIs
  getAllStages(jobId?: string): Observable<any> {
    return this.http.get(this.api_url + '/stages/' + jobId, this.options());
  }

  getStage(jobId?: string, stageId?: string): Observable<any> {
    return this.http.get(this.api_url + '/stages/' + jobId + '/' + stageId, this.options());
  }

  getCandidateStage(jobId?: string, stageId?: string, schoolId?: any): Observable<any> {
    let options = () => {
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        Origin: window.location.origin,
        Authorization: localStorage.getItem('token') ?? '',
        schoolid: schoolId,
      });
      return { headers };
    }
    return this.http.get(this.api_url + '/stages/' + jobId + '/' + stageId, options());
  }

  addStage(payload?: any): Observable<any> {
    return this.http.post(this.api_url + '/stages/', payload, this.options());
  }

  deleteStage(id?: string): Observable<any> {
    return this.http.delete(this.api_url + '/stages/' + id, this.options());
  }

  updateStage(stageId?: string, payload?: any): Observable<any> {
    return this.http.put(this.api_url + '/stages/' + stageId, payload, this.options());
  }

  // Workflow APIs
  getWorkflow(workflowId?: string): Observable<any> {
    return this.http.get(this.api_url + '/workflow-condition/' + workflowId, this.options());
  }

  saveWorkflow(stageId?: string, payload?: any): Observable<any> {
    return this.http.post(this.api_url + '/workflow-condition/' + stageId, payload, this.options());
  }

  updateWorkflow(workflowId?: string, payload?: any): Observable<any> {
    return this.http.put(this.api_url + '/workflow-condition/' + workflowId, payload, this.options());
  }

  deleteWorkflow(workflowId?: string): Observable<any> {
    return this.http.delete(this.api_url + '/workflow-condition/' + workflowId, this.options());
  }

  getTrigger(triggerId: string): Observable<any> {
    return this.http.get(this.api_url + '/trigger/' + triggerId, this.options());
  }

  getAllAccessMembers(userType?: string): Observable<any> {
    return this.http.get(this.api_url + '/users/userType/all/type' + `${userType ? ('?userType=' + userType) : ''}`, this.options());
  }

  //Workflow Management API
  getWorkflowContests(): Observable<any> {
    return this.http.get(this.api_url + '/test/getContest', this.options());
  }

  getContestTriggerId(c_id: string): Observable<any> {
    return this.http.get(this.api_url + '/test/getCourse/' + c_id, this.options());
  }

  updateStageSchedule(jobId: string, stageId: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/stages/' + jobId + '/' + stageId, payload, this.options());
  }

  updateFeedbackForm(jobId: string, stageId: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/stages/interview/' + jobId + '/' + stageId, payload, this.options());
  }

  getAllInterviewDuration(userId: string, eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/interviewLobby/getInterviewDuration/?' + `userId=${userId}` + `&eventId=${eventId}`, this.options());
  }

  getInterviewCandidatesByStatus(payload: any, eventId: string, status: string, pageNumber?: number, pageSize?: number): Observable<any> {
    return this.http.post(this.api_url + `/interviewLobby/getCandidatesByStatus/${eventId}/${status}?pageSize=${pageSize}&pageNumber=${pageNumber}`, payload, this.options())
  }

  getCandidateResume(eventId: string, userId: string): Observable<any> {
    return this.http.get(`${this.fast_api_url}/events/${eventId}/candidates/${userId}/resume`,
      {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }
    ).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getCandidateFeedback(eventId: string, candidateId: string): Observable<any> {
    return this.http.get(`${this.fast_api_url}/events/${eventId}/candidates/${candidateId}/feedback-tests/details`, 
    this.options_fast_api_with_company_uuid()
    ).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
}

getCandidateActivity(eventId: string, candidateId: string): Observable<any> {
  return this.http.get(`${this.fast_api_url}/events/${eventId}/candidates/${candidateId}/activity`, 
    this.options_fast_api_with_company_uuid()
  ).pipe(
    catchError(this.globalService.handleError.bind(this.globalService))
  );
}

getCandidateForms(eventId: string, candidateId: string): Observable<any> {
  return this.http.get(`${this.fast_api_url}/events/${eventId}/candidates/${candidateId}/forms`, {
    observe: 'response',
    ...this.options_fast_api_with_company_uuid()
  }).pipe(
    catchError(this.globalService.handleError.bind(this.globalService))
  );
}

getCandidateFormDetails(eventId: string, formType: string, formId: number): Observable<any> {
  return this.http.get(`${this.fast_api_url}/events/${eventId}/forms/${formType}/${formId}/details`, {
    observe: 'response',
    ...this.options_fast_api_with_company_uuid()
  }).pipe(
    catchError(this.globalService.handleError.bind(this.globalService))
  );
}
getCandidateInfo(candidateId: string, eventId: string): Observable<HttpResponse<any>> {
  return this.http.get<HttpResponse<any>>(
    `${this.fast_api_url}/candidates/${candidateId}/events/${eventId}?event_details_required=true`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }
  ).pipe(
    catchError(this.globalService.handleError.bind(this.globalService))
  );
}

downloadCandidateAttachments(eventId: string, formType: string,formId: number): Observable<any> {
  const url = `${this.fast_api_url}/events/${eventId}/forms/${formType}/${formId}/download`;
  return this.http.get(url, {
    observe: 'response',
    responseType: 'blob',
    ...this.options_fast_api_with_company_uuid()
  }).pipe(
    catchError(this.globalService.handleError.bind(this.globalService))
  );
}


}
