<div class="form-container">
  <div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
  </div>
  <div class="form-header">
    <p>{{formName}}</p>
    <div class="addStage-buttons clearAndSave-buttons">
      <span class="p-float-label">
        <p-dropdown [options]="applyTemplate" [autoDisplayFirst]="false" [name]="'template'" [(ngModel)]="selectedApplyTemplate"
          optionLabel="label" optionValue="value" [style]="{ width:'20vw', fontSize: '0.9rem' }"
          placeholder="Choose Template" (onChange)="cancelFormBuilder()">
        </p-dropdown>
        <label [for]="'template'">Choose Template<span class="imp">*</span></label>
      </span>
      <p-splitButton label="Save" class="addCandidates-button" [style]="{width: '9rem'}"
        [menuStyle]="{width: 'max-content'}" styleClass="p-button-sm mr-2 mb-2" (onClick)="saveFormBuilder()"
        [model]="this.saveAndTemplate"></p-splitButton>
    </div>
  </div>
  <div class="form-content">
    <div class="form-ele-container">
      <div class="ele-header">
        <p>Form Elements</p>
      </div>
      <div class="ele-playground">
        <span class="p-float-label">
          <p-dropdown [options]="eleType" [(ngModel)]="selectedEleType" optionLabel="label" optionValue="value"
            [style]="{ width:'100%', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
          </p-dropdown>
          <label for="inputtext">Element Type<span class="imp">*</span></label>
        </span>
        <div class="stage-search">
          <span class="p-input-icon-left width-full">
            <em class="pi pi-search"></em>
            <input class="searchbar" (keyup)="searchFormEle($event)" class="searchInput" type="text" pInputText
              placeholder="Search for elements" />
          </span>
        </div>
        <div class="filterSidebarContent" *ngIf="!isSearchActive">
          <p-accordion [activeIndex]="activeTabIndex" [multiple]="isMultiple" [collapseIcon]="'pi pi-minus'"
            [expandIcon]="'pi pi-plus'">
            <!-- Header Section -->
            <p-accordionTab header="Header">
              <ng-container *ngIf="_formFieldsSections.header.length > 0; else noHeaderSections">
                <p-accordion [activeIndex]="innerTabIndexHeader" [multiple]="isMultiple" [collapseIcon]="'pi pi-minus'"
                  [expandIcon]="'pi pi-plus'">
                    <ng-container *ngFor="let section of _formFieldsSections.header">
                    <ng-container
                      *ngIf="section.section_form_fields && section.section_form_fields.length > 0; else noHeaderElements">
                      <div cdkDropList #headerFieldList="cdkDropList" [cdkDropListData]="section.section_form_fields"
                        [cdkDropListConnectedTo]="[sectionHeaderList]" class="drag_field"
                        (cdkDropListDropped)="moveItem($event)" cdkDropListSortingDisabled>
                        <div class="drag_elements" *ngFor="let field of section.section_form_fields" cdkDrag
                          [cdkDragDisabled]="field.disabled" [class.disabled]="field.disabled">
                          <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                          <div>{{ field.label }}</div>
                          <div class="hide_inner_text">Drag & Drop Here the first form elements</div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noHeaderElements>
                      <div class="no-elements-message">No elements available in the Header. Add some fields to get started!
                      </div>
                    </ng-template>
                  </ng-container>
                </p-accordion>
              </ng-container>
              <ng-template #noHeaderSections>
                <div class="no-elements-message">No elements available in the Header. Add some fields to get started!</div>
              </ng-template>
            </p-accordionTab>
        
            <!-- Form Fields Section -->
            <p-accordionTab header="Form Fields">
              <ng-container *ngIf="_formFieldsSections.form_fields.length > 0; else noFormFieldsSections">
                <p-accordion [activeIndex]="innerTabIndexFormFields" [multiple]="isMultiple" [collapseIcon]="'pi pi-minus'"
                  [expandIcon]="'pi pi-plus'">
                  <p-accordionTab *ngFor="let section of _formFieldsSections.form_fields" [header]="section.section_label"
                    class="innerTab">
                    <ng-container
                      *ngIf="section.section_form_fields && section.section_form_fields.length > 0; else noFormFieldsElements">
                      <div cdkDropList #bodyFieldList="cdkDropList" [cdkDropListData]="section.section_form_fields"
                        [cdkDropListConnectedTo]="getConnectedLists()" class="drag_field"
                        (cdkDropListDropped)="moveItem($event)" cdkDropListSortingDisabled>
                        <div class="drag_elements" *ngFor="let field of section.section_form_fields" cdkDrag
                          [cdkDragDisabled]="field.disabled" [class.disabled]="field.disabled">
                          <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                          <div>{{ field.label }}</div>
                          <div class="hide_inner_text">inner fields elements</div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noFormFieldsElements>
                      <div class="no-elements-message">No fields available. Start adding your form fields here!</div>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>
              </ng-container>
              <ng-template #noFormFieldsSections>
                <div class="no-elements-message">No fields available. Start adding your form fields here!</div>
              </ng-template>
            </p-accordionTab>
        
            <!-- Footer Section -->
            <p-accordionTab header="Footer">
              <ng-container *ngIf="_formFieldsSections.footer.length > 0; else noFooterSections">
                <p-accordion [activeIndex]="innerTabIndexFooter" [multiple]="isMultiple" [collapseIcon]="'pi pi-minus'"
                  [expandIcon]="'pi pi-plus'">
                    <ng-container *ngFor="let section of _formFieldsSections.footer">
                      <ng-container *ngIf="section.section_form_fields && section.section_form_fields.length > 0; else noFooterElements">
                        <div cdkDropList #footerFieldList="cdkDropList" [cdkDropListData]="section.section_form_fields"
                          [cdkDropListConnectedTo]="[sectionFooterList]" class="drag_field" (cdkDropListDropped)="moveItem($event)"
                          cdkDropListSortingDisabled>
                          <div class="drag_elements" *ngFor="let field of section.section_form_fields" cdkDrag
                            [cdkDragDisabled]="field.disabled" [class.disabled]="field.disabled">
                            <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                            <div>{{ field.label }}</div>
                            <div class="hide_inner_text">Drag & Drop Here the first form elements</div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #noFooterElements>
                        <div class="no-elements-message">No elements available in the Footer. Add some fields to wrap up your
                          form!</div>
                      </ng-template>
                    </ng-container>
                </p-accordion>
              </ng-container>
              <ng-template #noFooterSections>
                <div class="no-elements-message">No elements available in the Footer. Add some fields to wrap up your form!
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div class="filterSidebarContent" *ngIf="isSearchActive">
          <div *ngFor="let section of _formFieldsSections.header">
            <div cdkDropList #headerFieldList="cdkDropList" [cdkDropListData]="section.section_form_fields"
              [cdkDropListConnectedTo]="[sectionHeaderList]" class="drag_field" (cdkDropListDropped)="moveItem($event)"
              cdkDropListSortingDisabled>
              <div class="drag_elements" *ngFor="let field of section.section_form_fields" cdkDrag
                [cdkDragDisabled]="field.disabled" [class.disabled]="field.disabled">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                <div>{{ field.label }}</div>
                <div class="hide_inner_text">Drag & Drop Here the first form elements</div>
              </div>
            </div>
          </div>
          <div *ngFor="let section of _formFieldsSections.form_fields">
            <div cdkDropList #bodyFieldList="cdkDropList" [cdkDropListData]="section.section_form_fields"
              [cdkDropListConnectedTo]="getConnectedLists()" class="drag_field" (cdkDropListDropped)="moveItem($event)"
              cdkDropListSortingDisabled>
              <div class="drag_elements" *ngFor="let field of section.section_form_fields" cdkDrag
                [cdkDragDisabled]="field.disabled" [class.disabled]="field.disabled">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                <div>{{ field.label }}</div>
                <div class="hide_inner_text">inner fields elements</div>
              </div>
            </div>
          </div>
          <div *ngFor="let section of _formFieldsSections.footer">
            <div cdkDropList #footerFieldList="cdkDropList" [cdkDropListData]="section.section_form_fields"
              [cdkDropListConnectedTo]="[sectionFooterList]" class="drag_field" (cdkDropListDropped)="moveItem($event)"
              cdkDropListSortingDisabled>
              <div class="drag_elements" *ngFor="let field of section.section_form_fields" cdkDrag
                [cdkDragDisabled]="field.disabled" [class.disabled]="field.disabled">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                <div>{{ field.label }}</div>
                <div class="hide_inner_text">Drag & Drop Here the first form elements</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-canvas-container">
      <div class="canvas-header">
        <p>Form Canva</p>
        <div class="canvas-btn-container">
          <button class="p-panel-header-icon panel-view section p-link toggler" (click)="addSectionDialog()">
            <span class="pi pi-credit-card"></span> <span style="cursor: pointer;">Add Section</span>
          </button>
          <button class="p-panel-header-icon panel-view preview p-link toggler" (click)="previewForm()">
            <span class="pi pi-eye"></span>
          </button>
        </div>
      </div>
      <div class="overflow-y-auto canvas-playground">
      <div cdkDropList  #sectionHeaderList="cdkDropList" [cdkDropListData]="availableHeaders" [cdkDropListConnectedTo]="getConnectedHeaderLists()" (cdkDropListDropped)="dropHeaderFooter($event)"
        class="_dragContainer" style="gap: 40px;padding-bottom: 0px;">
        <div class="example-custom-placeholder" *ngIf="availableHeaders.length == 0">
          <div class="inner_text">Drag & Drop Here the first form elements</div>
        </div>
        <div *ngFor="let item of availableHeaders; let i = index" cdkDrag cdkDragBoundary=".canvas-playground"
          class="field wiley-form" style="cursor: move;">
          <div class="example-custom-placeholder" *cdkDragPlaceholder>
            <div class="inner_text">Drag & Drop Here the first form elements</div>
          </div>
          <div *ngIf="item.type == 'Image'">
            <div class="drag_ele_container">
              <div class="drag_icon">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
              </div>
              <div class="drag_content">
                <div class="field_number">
                  <div class="field_txt">{{i + 1}}</div>
                </div>
                <div class="body">
                  <div class="field_container file_upload_container">
                    <div class="field_label">
                      <div>{{item.label}}</div>
                    </div>
                    <div class="file_upload_content">
                      <div class="width-full" *ngIf="!headerUploaded">
                        <div class="form-builder-dropzone" ngx-dropzone
                        [accept]="'image/jpeg, image/jpg, image/png'"
                        [multiple]="false" (change)="onHandleHeaderUpload($event,item.name)">
                            <ngx-dropzone-label *ngIf="!uploadingFile">
                                <div>
                                  <div class="dropzone-label">
                                    <em class="pi pi-cloud-upload"></em>
                                    <p class="upload-text">Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span>
                                        or
                                        Drag & Drop</p>
                                  </div>
                                    <p class="format-text">.jpeg, .jpg, .png Formate only</p>
                                </div>
                            </ngx-dropzone-label>
                            <div *ngIf="uploadingFile">
                                <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                                </p-progressSpinner>
                            </div>
                        </div>  
                    </div>
                    <div *ngIf="headerUploaded">
                        <div class="flex-dev uploaded-container" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
                            <div class="bulk-main" [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
                                <div class="file-icon" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', width: '40px', height: '40px',marginRight: '15px'}">
                                        <p class="pi pi-file template-file-icon" [style]="{fontSize : '24px',display: 'flex',alignItems: 'center', color: 'blue'}"></p>
                                </div>
                                <div class="header-file_info" [style]="{marginRight: '15px'}">
                                    <p class="file-name">{{headerFileName[item.name].file}}</p>
                                </div>
                              <button class="cancel" (click)="onRemoveExcelFile(item)">
                                <p class="cohort-cancel-icon">
                                    <em class="report-download pi pi-times" [style]="{fontSize: '14px', fontWeight: 'bold', color: '#d5d5d5', 
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'}"></em>
                                </p>
                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="footer dropdown-btn-container">
                  <div class="dropdown_btn justify-content-end">
                    <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                      <span class="pi pi-trash"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.type == 'editor'">
            <div class="drag_ele_container">
              <div class="drag_icon">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
              </div>
              <div class="drag_content">
                <div class="field_number">
                  <div class="field_txt">{{i + 1}}</div>
                </div>
                <div class="body">
                  <div class="field_container file_upload_container">
                    <div class="field_label">
                      <div>{{item.label}}</div>
                    </div>
                    <div class="file_upload_content">
                      <p-editor [(ngModel)]="item.content" [style]="{ height: '180px' }"></p-editor>
                    </div>
                  </div>
                </div>
                <div class="footer dropdown-btn-container">
                  <div class="dropdown_btn justify-content-end">
                    <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                      <span class="pi pi-trash"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div cdkDropList class="example-list" #sectionList="cdkDropList" (cdkDropListDropped)="dropSection($event)" >
        <div *ngFor="let section of availableControls;let secInd = index" cdkDragBoundary=".canvas-playground" cdkDrag cdkDropList [id]="'section_' + section.section_id" class="_dragContainer" >
          <div class="section-container">
            <div class="drag_icon section_drag_icon">
              <img src="../../../assets/drag_icon.svg" alt="drag_icon">
            </div>
            <h3>{{ section.section_label }}</h3>
            <div *ngIf="section.section_description" [innerHTML]="section.section_description"></div>
            <div class="section-btn-container">
              <button class="p-panel-header-icon p-link" *ngIf="availableControls.length > 1 " (click)="deleteSection(section,$event,secInd)">
                <span class="pi pi-trash"></span>
              </button>
              <button class="p-panel-header-icon p-link" (click)="editSection(section)">
                <img src="../../../assets/icons-svg/edit.svg" alt="edit">
              </button>
            </div>
          </div>
            <div class="section-custom-placeholder" *cdkDragPlaceholder>
              <div class="inner_text">Section Place Holder</div>
            </div>
          <div cdkDropList #sectionList="cdkDropList" [cdkDropListData]="section.section_form_fields"
            [cdkDropListConnectedTo]="getConnectedLists()" id="sectionList{{secInd}}"
            (cdkDropListDropped)="drop($event)" class="_dragContainer section_inner_container" style="gap: 40px;padding-right: 0;padding-bottom: 0;">
            <div class="section-custom-placeholder" *ngIf="section.section_form_fields.length == 0">
              <div class="inner_text">Drag the field to add it to this section</div>
            </div>
            <div *ngFor="let item of section.section_form_fields; let i = index" cdkDrag class="field wiley-form"
              style="cursor: move;">
              <div class="example-custom-placeholder" *cdkDragPlaceholder>
                <div class="inner_text">Drag & Drop Here the first form elements</div>
              </div>
              <div
                *ngIf="item.type == 'Number' || item.type == 'Percentage' || item.type == 'Input' || item.type == 'Text Area' || item.type == 'Email' || item.type == 'Date Picker' || item.type == 'Mobile'">
                <div class="drag_ele_container">
                  <div class="drag_icon">
                    <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                  </div>
                  <div class="drag_content">
                    <div class="field_number">
                      <div class="field_txt">{{i + 1}}</div>
                    </div>
                    <div class="body">
                      <div class="field_container">
                        <div class="field_heading">Label</div>
                        <div class="field_label">
                          <div *ngIf="!item.editLabel">{{item.label}}</div>
                          <div *ngIf="item.editLabel"><input type="text" pInputText [(ngModel)]="item.tempLabel" /></div>
                          <div class="label-btn-container">
                            <button *ngIf="!item.editLabel" class="p-panel-header-icon p-link" (click)="editLabel(item)">
                              <img src="../../../assets/icons-svg/edit.svg" alt="edit">
                            </button>
                            <button *ngIf="item.editLabel" class="p-panel-header-icon p-link" (click)="saveLabel(item)">
                              <span class="pi pi-check"></span>
                            </button>
                            <button *ngIf="item.editLabel" class="p-panel-header-icon p-link" (click)="cancelEdit(item)">
                              <span class="pi pi-times"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="field_container">
                        <div class="field_heading">Field Type</div>
                        <div class="field_label">{{item.type}}</div>
                      </div>
                    </div>
                    <div class="footer dropdown-btn-container">
                      <div class="dropdown_btn">
                        <div class="setup_btn_container">
                          <div class="switch_container">Add Depended Field<p-inputSwitch styleClass="switch_btn"
                              class="switch_btn" [(ngModel)]="item.isDependant"></p-inputSwitch></div>
                          <div class="switch_container">Mandatory <p-inputSwitch styleClass="switch_btn" class="switch_btn"
                              [(ngModel)]="item.isMandatory"></p-inputSwitch></div>
                        </div>
                        <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                          <span class="pi pi-trash"></span>
                        </button>
                      </div>
                      <div *ngIf="item.isDependant" class="depended_container">
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfFields(section,item)" [(ngModel)]="item.dependant.dependantOn" optionLabel="label"
                            optionValue="name" [style]="{ width:'250px', fontSize: '0.9rem' }"
                            (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Fields<span class="imp">*</span></label>
                        </span>
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfOptions(item.dependant.dependantOn)"
                            [(ngModel)]="item.dependant.dependantValues" optionLabel="label" optionValue="value"
                            [style]="{ width:'250px', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Option<span class="imp">*</span></label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="item.type == 'Multi Input' || item.type == 'Multi Input Email'">
                <div class="drag_ele_container">
                  <div class="drag_icon">
                    <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                  </div>
                  <div class="drag_content">
                    <div class="field_number">
                      <div class="field_txt">{{i + 1}}</div>
                    </div>
                    <div class="body">
                      <div class="field_container" *ngFor="let innerItem of item.form_fields">
                        <div class="field_heading">Label</div>
                        <div class="field_label">
                          <div *ngIf="!innerItem.editLabel">{{innerItem.label}}</div>
                          <div *ngIf="innerItem.editLabel"><input type="text" pInputText [(ngModel)]="innerItem.tempLabel" />
                          </div>
                          <div class="label-btn-container">
                            <button *ngIf="!innerItem.editLabel" class="p-panel-header-icon p-link"
                              (click)="editLabel(innerItem)">
                              <img src="../../../assets/icons-svg/edit.svg" alt="edit">
                            </button>
                            <button *ngIf="innerItem.editLabel" class="p-panel-header-icon p-link" (click)="saveLabel(innerItem)">
                              <span class="pi pi-check"></span>
                            </button>
                            <button *ngIf="innerItem.editLabel" class="p-panel-header-icon p-link"
                              (click)="cancelEdit(innerItem)">
                              <span class="pi pi-times"></span>
                            </button>
                          </div>
                        </div>
                        <div class="setup_btn_container">
                          <div class="switch_container">show <p-inputSwitch styleClass="switch_btn" class="switch_btn"
                              [(ngModel)]="innerItem.isShow"></p-inputSwitch></div>
                          <p-divider layout="vertical"></p-divider>
                          <div class="switch_container">Mandatory <p-inputSwitch styleClass="switch_btn" class="switch_btn"
                              [(ngModel)]="innerItem.isMandatory"></p-inputSwitch></div>
                        </div>
                      </div>
                      <div class="field_container">
                        <div class="field_heading">Field Type</div>
                        <div class="field_label">{{item.type}}</div>
                      </div>
                    </div>
                    <div class="footer dropdown-btn-container">
                      <div class="dropdown_btn">
                        <div class="setup_btn_container">
                          <div class="switch_container">Add Depended Field<p-inputSwitch styleClass="switch_btn"
                              class="switch_btn" [(ngModel)]="item.isDependant"></p-inputSwitch></div>
                        </div>
                        <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                          <span class="pi pi-trash"></span>
                        </button>
                      </div>
                      <div *ngIf="item.isDependant" class="depended_container">
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfFields(section,item)" [(ngModel)]="item.dependant.dependantOn" optionLabel="label"
                            optionValue="name" [style]="{ width:'250px', fontSize: '0.9rem' }"
                            (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Fields<span class="imp">*</span></label>
                        </span>
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfOptions(item.dependant.dependantOn)"
                            [(ngModel)]="item.dependant.dependantValues" optionLabel="label" optionValue="value"
                            [style]="{ width:'250px', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Option<span class="imp">*</span></label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="item.type == 'Dropdown' || item.type == 'Check Box' || item.type == 'Radio'">
                <div class="drag_ele_container">
                  <div class="drag_icon">
                    <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                  </div>
                  <div class="drag_content">
                    <div class="field_number">
                      <div class="field_txt">{{i + 1}}</div>
                    </div>
                    <div class="body">
                      <div class="field_container">
                        <div class="field_heading">Label</div>
                        <div class="field_label">
                          <div *ngIf="!item.editLabel">{{item.label}}</div>
                          <div *ngIf="item.editLabel"><input type="text" pInputText [(ngModel)]="item.tempLabel" /></div>
                          <div class="label-btn-container">
                            <button *ngIf="!item.editLabel" class="p-panel-header-icon p-link" (click)="editLabel(item)">
                              <img src="../../../assets/icons-svg/edit.svg" alt="edit">
                            </button>
                            <button *ngIf="item.editLabel" class="p-panel-header-icon p-link" (click)="saveLabel(item)">
                              <span class="pi pi-check"></span>
                            </button>
                            <button *ngIf="item.editLabel" class="p-panel-header-icon p-link" (click)="cancelEdit(item)">
                              <span class="pi pi-times"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="field_container">
                        <div class="field_heading">Field Type</div>
                        <div class="field_label">{{item.type}}</div>
                      </div>
                      <div class="field_container">
                        <p class="template_text save_txt" (click)="viewChoiceDialog(item,i)">View Choices</p>
                      </div>
                    </div>
                    <div class="footer dropdown-btn-container">
                      <div class="dropdown_btn">
                        <div class="setup_btn_container">
                          <div class="switch_container">Add Depended Field<p-inputSwitch styleClass="switch_btn"
                              class="switch_btn" [(ngModel)]="item.isDependant"></p-inputSwitch></div>
                          <div class="switch_container">Mandatory <p-inputSwitch styleClass="switch_btn" class="switch_btn"
                              [(ngModel)]="item.isMandatory"></p-inputSwitch></div>
                        </div>
                        <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                          <span class="pi pi-trash"></span>
                        </button>
                      </div>
                      <div *ngIf="item.isDependant" class="depended_container">
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfFields(section,item)" [(ngModel)]="item.dependant.dependantOn" optionLabel="label"
                            optionValue="name" [style]="{ width:'250px', fontSize: '0.9rem' }"
                            (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Fields<span class="imp">*</span></label>
                        </span>
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfOptions(item.dependant.dependantOn)"
                            [(ngModel)]="item.dependant.dependantValues" optionLabel="label" optionValue="value"
                            [style]="{ width:'250px', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Option<span class="imp">*</span></label>
                        </span>
                      </div>
                    </div>
                  </div>
          
                </div>
              </div>
              <div *ngIf="item.type == 'File'">
                <div class="drag_ele_container">
                  <div class="drag_icon">
                    <img src="../../../assets/drag_icon.svg" alt="drag_icon">
                  </div>
                  <div class="drag_content">
                    <div class="field_number">
                      <div class="field_txt">{{i + 1}}</div>
                    </div>
                    <div class="body">
                      <div class="field_container">
                        <div class="field_heading">Label</div>
                        <div class="field_label">
                          <div *ngIf="!item.editLabel">{{item.label}}</div>
                          <div *ngIf="item.editLabel"><input type="text" pInputText [(ngModel)]="item.tempLabel" /></div>
                          <div class="label-btn-container">
                            <button *ngIf="!item.editLabel" class="p-panel-header-icon p-link" (click)="editLabel(item)">
                              <img src="../../../assets/icons-svg/edit.svg" alt="edit">
                            </button>
                            <button *ngIf="item.editLabel" class="p-panel-header-icon p-link" (click)="saveLabel(item)">
                              <span class="pi pi-check"></span>
                            </button>
                            <button *ngIf="item.editLabel" class="p-panel-header-icon p-link" (click)="cancelEdit(item)">
                              <span class="pi pi-times"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="field_container">
                        <div class="field_heading">Field Type</div>
                        <div class="field_label">{{item.type}}</div>
                      </div>
                      <div class="field_container">
                        <div class="field_heading">Maximum File Size</div>
                        <div class="field_label">{{item.properties.fileSize.size}}
                          {{item.properties.fileSize.sizeFormat
                          |
                          uppercase}}</div>
                      </div>
                    </div>
                    <div class="footer dropdown-btn-container">
                      <div class="dropdown_btn">
                        <div class="setup_btn_container">
                          <div class="switch_container">Add Depended Field<p-inputSwitch styleClass="switch_btn"
                              class="switch_btn" [(ngModel)]="item.isDependant"></p-inputSwitch></div>
                          <div class="switch_container">Mandatory <p-inputSwitch styleClass="switch_btn" class="switch_btn"
                              [(ngModel)]="item.isMandatory"></p-inputSwitch></div>
                        </div>
                        <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                          <span class="pi pi-trash"></span>
                        </button>
                      </div>
                      <div *ngIf="item.isDependant" class="depended_container">
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfFields(section,item)" [(ngModel)]="item.dependant.dependantOn" optionLabel="label"
                            optionValue="name" [style]="{ width:'250px', fontSize: '0.9rem' }"
                            (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Fields<span class="imp">*</span></label>
                        </span>
                        <span class="p-float-label">
                          <p-dropdown [options]="listOfOptions(item.dependant.dependantOn)"
                            [(ngModel)]="item.dependant.dependantValues" optionLabel="label" optionValue="value"
                            [style]="{ width:'250px', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
                          </p-dropdown>
                          <label for="inputtext">Select Option<span class="imp">*</span></label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div cdkDropList  #sectionFooterList="cdkDropList" [cdkDropListData]="availableFooter" [cdkDropListConnectedTo]="getConnectedFooterLists()" (cdkDropListDropped)="dropHeaderFooter($event)"
        class="_dragContainer" style="gap: 40px;padding-bottom: 0px;">
        <div class="example-custom-placeholder" *ngIf="availableFooter.length == 0">
          <div class="inner_text">Drag & Drop Here the footer field elements</div>
        </div>
        <div *ngFor="let item of availableFooter; let i = index" cdkDrag cdkDragBoundary=".canvas-playground"
          class="field wiley-form" style="cursor: move;">
          <div class="example-custom-placeholder" *cdkDragPlaceholder>
            <div class="inner_text">Drag & Drop Here the first form elements</div>
          </div>
          <div *ngIf="item.type == 'address'">
            <div class="drag_ele_container">
              <div class="drag_icon">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
              </div>
              <div class="drag_content">
                <div class="body">
                  <div class="field_container file_upload_container">
                    <div class="field_label">
                      <div>{{item.label}}</div>
                    </div>
                    <div class="file_upload_content">
                      
                    </div>
                  </div>
                </div>
                <div class="footer dropdown-btn-container">
                  <div class="dropdown_btn justify-content-end">
                    <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                      <span class="pi pi-trash"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.type == 'contact_details'">
            <div class="drag_ele_container">
              <div class="drag_icon">
                <img src="../../../assets/drag_icon.svg" alt="drag_icon">
              </div>
              <div class="drag_content">
                <div class="body">
                  <div class="field_container file_upload_container">
                    <div class="field_label">
                      <div>{{item.label}}</div>
                    </div>
                    <div class="file_upload_content">
                      <p-editor [(ngModel)]="item.content" [style]="{ height: '180px' }"></p-editor>
                    </div>
                  </div>
                </div>
                <div class="footer dropdown-btn-container">
                  <div class="dropdown_btn justify-content-end">
                    <button class="p-panel-header-icon p-link" (click)="deleteField(item,$event,i)">
                      <span class="pi pi-trash"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>

<p-confirmPopup></p-confirmPopup>
<p-dialog [header]="viewChoiceOptions.header" [draggable]="false" [style]="{ width: '40vw'}" [modal]="true"
  [(visible)]="isViewChoice">
  <div class="view-choice-container">
    <div class="view_choice_border">
      <div class="field_heading">Label</div>
      <div class="field_label">
        <div>{{viewChoiceOptions.label}}</div>
      </div>
    </div>
    <div class="view_choice_border" *ngFor="let item of viewChoiceOptions.fieldOptions;let i = index ">
      <div class="field_heading">Option {{i + 1}}</div>
      <div class="field_label">
        {{item.label}}
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [header]="'Apply Template'" [draggable]="false" [resizable]="false" id="overflowNoneDialog"
  [style]="{ width: '40vw',height: '260px'}" [modal]="true" [(visible)]="isApplyTemplate">
  <div class=" dialogContentPadding">
    <span class="p-float-label">
      <p-dropdown [options]="applyTemplate" [(ngModel)]="selectedApplyTemplate" optionLabel="label" optionValue="value"
        [style]="{ width:'25vw', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
      </p-dropdown>
      <label for="inputtext">Select Template<span class="imp">*</span></label>
    </span>
    <div class="primary-btn left-side-align-btn">
      <button pButton pRipple type="button" label="Apply" class="save p-button-outlined"
        (click)="applyTemplateFormBuilder()"></button>
    </div>
  </div>
</p-dialog>
<p-dialog [header]="'Add Section'" [draggable]="false" [resizable]="false" id="overflowNoneDialog"
  [style]="{ width: '40vw'}" [modal]="true" [(visible)]="isAddSection">
  <div class=" dialogContentPadding">
    <span class="p-float-label">
      <input type="text" style="max-width: 100% !important;" [style]="{width: '100%'}" id="inputtext" pInputText
        [(ngModel)]="_newSectionName">
      <label for="inputtext">Section Name<span class="imp">*</span></label>
    </span>
    <p-editor [(ngModel)]="_newSectionDescription" [style]="{ height: '200px' }"></p-editor>
    <div class="primary-btn left-side-align-btn">
      <button pButton pRipple type="button" [label]="updateSection ? 'Update' :'Add'" class="save p-button-outlined"
        (click)="updateSection ? updateSectionFormBuilder() :addSectionFormBuilder()"></button>
    </div>
  </div>
</p-dialog>
<p-dialog [header]="'Save as Template'" [draggable]="false" [resizable]="false" id="overflowNoneDialog"
  [style]="{ width: '40vw',height: '260px'}" [modal]="true" [(visible)]="isSaveTemplate">
  <div class=" dialogContentPadding">
    <span class="radioButtons">
      <p-radioButton value="existing-template" name="existing-template" [(ngModel)]="_selectedTemplateType">
      </p-radioButton><label class="radioBtn"
        [ngClass]="{'active-black-txt': _selectedTemplateType === 'existing-template'}"
        for="existing-template">Overwrite an existing template</label>
    </span>
    <span class="p-float-label" *ngIf="_selectedTemplateType === 'existing-template'">
      <p-dropdown [options]="applyTemplate" [(ngModel)]="selectedApplyTemplate" optionLabel="label" optionValue="value"
        [style]="{ width:'25vw', fontSize: '0.9rem' }" (onChange)="cancelFormBuilder()">
      </p-dropdown>
      <label for="inputtext">Select Template<span class="imp">*</span></label>
    </span>
    <div class="radioButtons">
      <p-radioButton value="new-template" name="new-template" [(ngModel)]="_selectedTemplateType">
      </p-radioButton><label class="radioBtn" for="new-template"
        [ngClass]="{'active-black-txt': _selectedTemplateType === 'new-template'}">Create a New Template</label>
    </div>
    <span class="p-float-label" *ngIf="_selectedTemplateType === 'new-template'">
      <input type="text" [style]="{width: '25vw'}" id="inputtext" pInputText [(ngModel)]="_newTemplateName">
      <label for="inputtext">Template Name<span class="imp">*</span></label>
    </span>
    <div class="primary-btn left-side-align-btn">
      <button pButton pRipple type="button" label="Save" class="save p-button-outlined"
        (click)="applyTemplateFormBuilder()"></button>
    </div>
  </div>
</p-dialog>