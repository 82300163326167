import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { RoleServicesService } from '../../services/role-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedServicesService } from '../../services/shared-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { SettingsService } from 'src/app/services/settings.service';
import { EventServicesService } from 'src/app/services/event-services.service';
@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
  providers: [MessageService],
})
export class RoleListComponent implements OnInit {
  showAddRole: boolean = false;
  rowData: any = null;
  roleList: Array<any> = [];
  pageLimits: Array<any> = [];
  status: MenuItem[] = [];
  selectedRoles: Array<any> = [];
  rolesCol: Array<any> = [];
  __rolesCol: Array<any> = [];
  _selectedColumns: any[] = [];
  colsToDownload: Array<any> = [];
  roleOptions: Array<any> = [];
  eventRole: object = {};
  filteredRoleList: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  userPrivileges: Set<string> = new Set();

  //Filtering Array
  checkedColumns: Array<any> = [];
  selectedStatusElements: Array<any> = [];
  selectedRoleTypeElements: Array<any> = [];
  selectedDepartmentElements: Array<any> = [];

  // Boolean Var
  addRolesSlider: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  totalPages: number = 1;

  // String var
  order_by: string = 'desc';
  sort_by: string = 'id';
  statusSelected: string = 'All';
  statusLabel: string = 'All';
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  columnFieldId: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'roleList';
  privilege: any = {};

  constructor(
    private eventServices: EventServicesService,
    private roleService: RoleServicesService,
    private messageService: MessageService,
    private _sharedService: SharedServicesService,
    private globalService: GlobalServicesService,
    private breadcrumbService: NgDynamicBreadcrumbService
  ) {
    this.pageLimits = [10, 25, 50, 100];

    this.rolesCol = [
      // { index: 1, field: 'id', header: 'Role ID' },
      { index: 2, field: 'role_type', header: 'Role Type' },
      { index: 3, field: 'work_mode', header: 'Work mode' },
      { index: 4, field: 'location', header: 'Location' },
      { index: 5, field: 'opening', header: 'Openings' },
      { index: 6, field: 'hiring_manager', header: 'Hiring manager' }
    ];

    this._selectedColumns = [
      // { index: 1, field: 'id', header: 'Role ID' },
      { index: 2, field: 'role_type', header: 'Role Type' },
      { index: 3, field: 'work_mode', header: 'Work mode' },
      { index: 4, field: 'location', header: 'Location' },
      { index: 5, field: 'opening', header: 'Openings' },
      { index: 6, field: 'hiring_manager', header: 'Hiring manager' }
    ];

    this.roleOptions = [
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.deleteRole(this.eventRole);
        },
      },
    ];
  }

  columnSettings: FormGroup = new FormGroup({
    name: new FormControl(''),
    saved_columns: new FormControl(this.columnsToSave),
  });

  ngOnInit(): void {
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });    
    if (this.userPrivileges.has('job_role.view')) {
      this.getAllRoles(this.statusSelected);
      this.getSavedColumns();
    }
    this.__rolesCol = this.rolesCol;
    this.colsToDownload = this.rolesCol;
    this.checkedColumns = this._selectedColumns;
    this._sharedService.emitChange('');
    this.privilege = this.globalService.privilegeDetails;
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    const breadcrumbs = [
      { label: 'Manage Job Roles', url: '' }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }
  onCloseAddRole(success: boolean) {
    this.showAddRole = false;
    if (success) {
      this.getAllRoles(this.statusSelected);
    }
  }

  getAllRoles = (filterValue?: any) => {
    this.loadingSpinner = true;
    this.roleList = [];
    this.roleService.getJobRolesFastApi(this.pageNumber, this.pageSize, this.order_by, this.sort_by, filterValue).subscribe({
      next: (response: any) => {
        this.roleList = Object.keys(response.body.items).length !== 0 ? response.body.items : [];
        this.roleList.forEach((each) => {
          each.location = [];
          each.hiring_manager = [];
          each.opening = [];
          each.locations.forEach((eachLocation: any) => {
            each.location.push(`${eachLocation.city},${eachLocation.country}`);
            each.hiring_manager.push(`${eachLocation.hire_manager.first_name} ${eachLocation.hire_manager.last_name}`);
            each.opening.push(`${eachLocation.opening}`);
          });

          each.role_type = each.role_type.name;

        });
        this.totalLength = response.body.total || 0;
        this.first = response.body.page || 1;
        this.last = response.body.pages || 10;
        this.pageSize = response.body.size || 10;
        this.totalPages = response.body.pages || 1;
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  };

  deleteRole = (role: any) => {
    this.loadingSpinner = true;
    this.roleService.deleteJobRolesFastApi(role.id.toString()).subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res.body.detail
          });
          this.selectedRoles = [];
          this.getAllRoles(this.statusSelected);
        }
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  };

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      name: this.columnSettings.get("name")?.value,
      saved_columns: this.columnsToSave
    });

    this.eventServices.saveUserPreferencesFastApi(this.columnSettings.value, 'job_role').subscribe({
      next: (result: any) => {
        this.getSavedColumns();
        this.loadingSpinner = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: result.body.detail,
        });
      },
      error: () => {
        this.loadingSpinner = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "unable to save user preferences",
        });
      }
    });
    this.displaySaveColumnSettings = false;
  };

  // getColumnId = () => {
  //   this.roleService.getSchool().subscribe((res) => {
  //     this.columnFieldId = res.data[0].id;
  //   });
  // };

  getSavedColumns = () => {
    this.eventServices.getUserPreferencesFastApi('job_role').subscribe(res => {
      this.savedColumnSettings = res.body.user_preferences ? res.body.user_preferences : []
      this.status = [
        {
          label: 'All',
          command: () => {
            this.statusLabel = 'All';
            this.getRolesByFilter('All');
          }
        },
        ...res.body.filters.role_types.map((item: any) => ({
          label: item.name,
          command: () => {
            this.statusLabel = item.name;
            this.getRolesByFilter(item.id);
          }
        }))
      ];
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.eventServices.deleteUserPreferencesFastApi(columnId).subscribe({
      next: res => {
        this.getSavedColumns();
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  }

  getEventVal = (event: Event) => {
    return (event.target as HTMLInputElement).value;
  };

  onSelectedRoleChange = (value: []) => {
    this.selectedRoles = value;
  };

  // For data export
  exportRole = (fileType?: string) => {
    let checkedColumnFields = ['name'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });

    const dataToExport = this.selectedRoles.length > 0 ? this.selectedRoles : this.roleList;
    let exportData: any = [];
    dataToExport.forEach((role: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((field: string) => {
        if (role.hasOwnProperty(field)) {
          switch (field) {
            case 'location':
              filterData[field] = role.locations
                .map((loc: any) => `${loc.city[0]}, ${loc.state}, ${loc.country}`)
                .join('; ');
              break;
            case 'hiring_manager':
              filterData[field] = role.locations
                .map((loc: any) => `${loc.hire_manager.first_name} ${loc.hire_manager.last_name}`)
                .join('; ');
              break;
            case 'opening':
              filterData[field] = role.locations
                .map((loc: any) => `${loc.opening}`)
                .join('; ');
              break;
            default:
              filterData[field] = role[field];
          }
        }
      });
      exportData.push(filterData);
    });

    this.saveAsExcelFile(exportData, this.exportName, fileType);
  };

  saveAsExcelFile(data: any, fileName: string, type?: string): void {
    if (type === "csv") {
      const headers = Object.keys(data[0]).join(",");
      const rows = data
        .map((obj: any) =>
          Object.values(obj)
            .map(value => this.escapeCSVValue(value))
            .join(",")
        )
        .join("\n");
      const csvContent = `${headers}\n${rows}`;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${fileName}_export_${new Date().getTime()}.csv`);
    } else {
      import("xlsx").then(XLSX => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Roles");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}_export_${new Date().getTime()}.xlsx`);
      });
    }
  }

  private escapeCSVValue(value: any): string {
    if (typeof value === "string" && value.includes(",")) {
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  };

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  };

  applySelectedFilter = () => {
    if (
      this.selectedStatusElements.length ||
      this.selectedRoleTypeElements.length ||
      this.selectedDepartmentElements.length
    ) {
      this.filteredRoleList = [];

      let statusFilter = this.roleList.filter((element) =>
        this.selectedStatusElements.includes(element.status)
      );
      let roleTypeFilter = this.roleList.filter((element) =>
        this.selectedRoleTypeElements.includes(element.role_type)
      );
      let departmentFilter = this.roleList.filter((element) =>
        this.selectedDepartmentElements.includes(element.department)
      );

      this.filteredRoleList = [
        ...new Set([...statusFilter, ...roleTypeFilter, ...departmentFilter]),
      ];
      this.showFilterSidebar = false;
    } else {
      this.filteredRoleList = [];
    }
  };

  clearSelectedFilters = () => {
    this.selectedStatusElements = [];
    this.selectedRoleTypeElements = [];
    this.selectedDepartmentElements = [];
  };

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.rolesCol.filter((e) =>
      savedColumnValue.includes(e.field)
    );
  };

  openRoleDetails = (role: string) => {
    this.showAddRole = true;
    this.rowData = role;
    this.rowData.isUpdate = true;
  };

  // get roles by limit
  getRolesByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllRoles(this.statusSelected);
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this.__rolesCol = this.rolesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.rolesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };

  // get roles by filter
  getRolesByFilter = (id: any) => {
    this.statusSelected = id;
    this.pageNumber = 1;
    this.first = 1;
    this.last =
      this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllRoles(this.statusSelected);
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index ? -1 : 1));
  }

  // For custom pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last =
      this.last + this.pageSize < this.totalLength
        ? this.pageSize + this.last
        : this.totalLength;
    this.pageNumber += 1;
    this.getAllRoles(this.statusSelected);
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? (this.last == this.totalLength ? this.totalLength - this.roleList.length : this.last - this.pageSize) : this.pageSize;
    this.pageNumber -= 1;
    this.getAllRoles(this.statusSelected);
  }

  isLastPage(): boolean {
    return this.pageNumber === this.totalPages;;
  }

  isFirstPage(): boolean {
    return this.first === 1;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.rolesCol.filter((col) => val.includes(col));
  }

  getHeaderVal(col: string) {
    let result = col.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  setEventRoleId(role: any) {
    this.eventRole = role;
  }
  onSort(event: any) {
    if (this.sort_by !== event.field || this.order_by !== (event.order === 1 ? 'asc' : 'desc')) {
      this.sort_by = event.field;
      this.order_by = event.order === 1 ? 'asc' : 'desc';
      this.getAllRoles(this.statusSelected);
    }
  }
}
