import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private loginService: LoginService,
    private route: Router,
    private globalService: GlobalServicesService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const requiredPrivilege = next.data['privilege'];
    return this.globalService.getUserProfileFastApi().pipe(
      map((res: any) => {
        const userPrivileges = res.body.auth_user_permissions || [];
        this.globalService.privilegeDetails = new Set(userPrivileges);
        this.globalService.privilegeDetailsSubject.next(this.globalService.privilegeDetails);    
        localStorage.setItem('userDetailsFastApi', JSON.stringify({
          user_profile: res.body.auth_user_details,
          role_details: res.body.auth_user_role_details
        }));
        const company_settings = res.body.company_settings;
        const letter = ['D', 'Y'];
  
        company_settings.date_format = company_settings.date_format?.toString().replace(
          new RegExp(letter.join('|'), 'g'),
          (match: string) => match.toLowerCase()
        );
        company_settings.time_format = company_settings.time_format == '24' ? 'HH:mm' : 'hh:mm';    
        localStorage.setItem('company-details', JSON.stringify({
          company_metadata: res.body.company_metadata,
          company_settings: res.body.company_settings,
          company_details: res.body.company_details
        }));    
        let hasAccess = true;
        if (requiredPrivilege) {
          if (Array.isArray(requiredPrivilege)) {
            hasAccess = requiredPrivilege.some(priv => userPrivileges.includes(priv));
          } else {
            hasAccess = userPrivileges.includes(requiredPrivilege);
          }
        }
        if (!hasAccess) {
          this.route.navigate(['/error']);
        }
    
        return hasAccess;
      }),
      catchError(() => {
        this.route.navigate(['/login']);
        return of(false);
      })
    );
  }
  removeKeys() {
    for (var attr in localStorage) {
      if (attr != 'schoolData' && attr != 'schoolDetails')
        localStorage.removeItem(attr);
    }
  }
}
