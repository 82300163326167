import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { JobServicesService } from 'src/app/services/job-services.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { EventServicesService } from 'src/app/services/event-services.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { nanoid } from 'nanoid'
import { firstValueFrom } from 'rxjs';

interface Stage {
  id: string,
  name: string,
  type: string,
  stageId: number,
  isActive: boolean,
  isRenameStage: boolean,
  workflowIds: string,
  actions: Array<any>,
  deletable: boolean,
  scheduleTest?: any;
  _stageId?: any;
}

enum ErrorFields {
  email = 'Invalid Email Id',
  cohortName = 'Invalid Cohort Name',
  jobLocation = 'Invalid Job Location',
  jobRole = 'Invalid Job Role',
  emptyEmail = 'Empty Email Id',
  emptycohortName = 'Empty Cohort Name',
  emptyjobLocation = 'Empty Job Location',
  emptyjobRole = 'Empty Job Role'
}

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css'],
  providers: [MessageService],
})
export class WorkflowComponent implements OnInit {

  @Output() stagesEmitter = new EventEmitter<Array<Stage>>();

  stageTypes: Array<any> = [];
  stages: Array<Stage> = [];
  documentationForms: Array<any> = [];
  feedbackForms: Array<any> = [];
  testForms: Array<any> = [];
  selectedStage: any = [];
  applicationTrigger: string = '';
  applicationActions: Array<any> = [];
  testActions: Array<any> = [];
  interviewActions: Array<any> = [];
  screeningActions: Array<any> = [];
  workflowIds: any = [];
  cohortFileSize: any;
  requiredColumns: Array<any> = [];
  cohortFile: Array<any> = [];
  cohortFileCopy: Array<any> = [];
  errorFileData: any;
  errorFile: any;
  templateOptions: Array<any> = [];
  stageActions: any = [];
  selectedMailTemplateId: any;
  selectedMailTemplate: any;
  searchEmail: any;
  emails : any;
  validatedData: any;
  schoolId: any;
  eventStageFormData: any;

  selectedType: any = null;
  selectedForm: any = null;
  stageName: string = '';
  jobName: string = '';
  eventName: string = '';
  jobId: string = '';
  eventId: string = '';
  stageRename: string = '';
  testId: string = '';
  contestId: string = '';
  cohortDownloadRadio: string = 'generic-template';
  s3Domain: string = 'https://s3.amazonaws.com';
  templateValue: Array<any> = [];
  counts: any;
  templateLabel: string = '';


  displayAddStage: boolean = false;
  isLoading: boolean = false;
  isInEvent: boolean = false;
  displayShortlisted: boolean = true;
  displayRuleEngine: boolean = false;
  isTestSelected: boolean = false;
  isFeedbackSelected: boolean = false;
  displayCohort: boolean = false;
  saveBtnLoading: boolean = false;
  finalStage : boolean = false;
  confirmBtnLoading : boolean = false;
  cohortUploaded: boolean = false;
  uploadingFile: boolean = false;
  cohortError: boolean = false;
  clicked: boolean = false;
  moveStep : boolean = false;
  countDiv : boolean = true;
  emailsCleared : boolean = false;
  showBulkCohort: boolean = false;
  _stageId: number = 0;
  privilege: any;

  bucketName: string = this.globalService.assetBucketName;

  uploadCohort: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl('', Validators.required),
    actionId: new FormControl(''),
    eventId: new FormControl(''),
    stageId : new FormControl('')
  })

  constructor(
    private jobService: JobServicesService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private emailServices: EmailServicesService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService,
    private eventServices: EventServicesService,
    private router: Router
  ) {
    this.stageTypes = ['Test', 'Interview', 'Screening', 'Onboarding', 'Others', 'Offer']

    this.requiredColumns = [
      { index: 1, field: 'email', header: 'email' },
      { index: 2, field: 'cohortName', header: 'cohortName' },
      { index: 3, field: 'jobRole', header: 'jobRole' },
      { index: 4, field: 'jobLocation', header: 'jobLocation' }
      ]
  }

  async ngOnInit() {
    this.jobName = window.localStorage.getItem("selectedJobName") || '';
    this.eventName = window.localStorage.getItem("selectedEventName") || '';
    this.jobId = window.localStorage.getItem("selectedJobId") || '';
    this.eventId = window.localStorage.getItem("selectedEventId") || '';
    this.isInEvent = this.jobId === this.eventId;
    this.formData();
    this.getStages();
    this.getEmailTemplates();
    let schoolData = localStorage.getItem('schoolData') || '';
    this.schoolId = JSON.parse(schoolData);
    this.privilege = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
  }
  async getEmailTemplates() {
    try {
      this.emailServices.getTemplatesFastApi('').subscribe({
        next: (response) => {
          if (response.status === 200) {
            let templateList = response.body.email_templates ? response.body.email_templates : [];
            this.templateOptions = templateList.map((item: any) => ({
              id: item.id,
              isEditable: item.is_editable,
              name: item.name,
            }));
          }
        },
        error: (_err) => {
        }
      });
    } catch (error) {
      console.error('Error fetching email templates:', error);
    }
  }
  async formData() {
    this.isLoading = true;
    try {
      const response = await firstValueFrom(this.jobService.getEventStageFormDataFastApi(this.eventId));

      if (response?.status === 200) {
        const mapForms = (forms: any[]) => forms.map((element: any) => ({
          label: element.name,
          value: element.uuid,
      }));
      
      this.documentationForms = mapForms(response.body.documentation_forms);
      this.feedbackForms = mapForms(response.body.feedback_forms);
      this.testForms = mapForms(response.body.contests);
      this.eventServices.setForms(this.documentationForms, this.feedbackForms, this.testForms);
      this.eventStageFormData = {
        documentationForms: this.documentationForms,
        feedbackForms: this.feedbackForms,
        testForms: this.testForms
      };
      console.log("🚀🔥 ~ file: workflow.component.ts:195 ~ WorkflowComponent ~ formData ~ this.documentationForms:", this.documentationForms)
      }
    } catch (error) {
      console.error('Error fetching stages:', error);
    } finally {
      this.isLoading = false;
    }
  }

  async getStages() {
    this.isLoading = true;
    
    try {
        const response = await firstValueFrom(this.jobService.getAllStagesFastApi(this.jobId));
        
        if (response?.status === 200 && response.body?.stages?.length) {
            this.stageActions = response.body.stage_actions;
            this.stages = response.body?.stages || [];
            
            this.stages.forEach((stage: any) => {
                stage.id = stage.uuid;
                stage.name = stage.name;
                stage.type = stage.type;
                stage.stageId = stage.uuid;
                stage.isActive = false;
                stage.isRenameStage = false;
                stage.actions = this.stageActions[stage.type];
                stage.deletable = true;
            });
            
            // this.stages = this.stages.filter((stage: any) => this.privilege.viewManageEventsStages.includes(stage.type));
            
            if (this.stages.length > 0) {
                this.stages[0].isActive = true;
            }
            
            this._stageId = this.stages.length;
            
            let stageCount = 0;
            this.stages.forEach((stage: any) => {
                stageCount += 1;
                stage._stageId = stageCount;
            });
            console.log("🚀🔥 ~ file: workflow.component.ts:158 ~ WorkflowComponent ~ this.stages=response.body.stages.map ~ this.stages:", this.stages);
            
            this.openCurrentStage();
            this.stagesEmitter.emit(this.stages);
        }
    } catch (error) {
        console.error('Error fetching stages:', error);
    } finally {
        this.isLoading = false;
    }
}


  chooseActions = (stage: any): any => {
    switch (true) {
      case stage.stageId == 1: {
        return [
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
          { name: 'Eligible Candidate', actionEventId: 'eligibleCandidate' },
          { name: 'Not Eligible Candidate', actionEventId: 'noteligibleCandidate' },
          { name: 'Waitlist Candidate', actionEventId: 'waitlistCandidate' },
        ];
      };
      case stage.stageType == 'Test': {
        return [
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
          { name: 'Shortlist Candidate', actionEventId: 'shortlistCandidate' },
          { name: 'Reject Candidate', actionEventId: 'rejectCandidate' },
          { name: 'Waitlist Candidate', actionEventId: 'waitlistCandidate' },
          { name: 'Send Test Link', actionEventId: 'sendTestLink' },
        ];
      }
      case stage.stageType == 'Interview': {
        return [
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
          { name: 'Shortlist Candidate', actionEventId: 'shortlistCandidate' },
          { name: 'Reject Candidate', actionEventId: 'rejectCandidate' },
          { name: 'Waitlist Candidate', actionEventId: 'waitlistCandidate' },
        ];
      }
      case stage.stageType == 'Screening': {
        return [
          { name: 'Send Document Form', actionEventId: 'sendDocumentForm' },
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
          { name: 'Shortlist Candidate', actionEventId: 'shortlistCandidate' },
          { name: 'Reject Candidate', actionEventId: 'rejectCandidate' },
          { name: 'Waitlist Candidate', actionEventId: 'waitlistCandidate' },
        ];
      }
      case stage.stageType == 'Others':
      case stage.stageName == 'Vetted Pool': {
        return [
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
          { name: 'Added To Vetted Pool', actionEventId: 'addedToVettedPool' },
          { name: 'Reject Candidate', actionEventId: 'rejectCandidate' },
          { name: 'Shortlist Candidate', actionEventId: 'shortlistCandidate' },
          { name: 'Waitlist Candidate', actionEventId: 'waitlistCandidate' },
        ];
      }
      case stage.stageType == 'OnBoarding': {
        return [
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
        ]
      }
      default: {
        return [
          { name: 'Send Email', actionEventId: 'sendMail' },
          { name: 'Move Stage', actionEventId: 'moveStage' },
          { name: 'Reject Candidate', actionEventId: 'rejectCandidate' },
          { name: 'Shortlist Candidate', actionEventId: 'shortlistCandidate' },
          { name: 'Waitlist Candidate', actionEventId: 'waitlistCandidate' },
        ];
      }
    }
  }

  openCurrentStage = () => {
    this.activatedRoute.queryParamMap.subscribe((param) => {
      let stageId = param.get('stageId') || '';
      let tabView = param.get('view') || '';
      if (stageId.length) this.openStage(stageId);
      else if(tabView == 'workflow') this.openStage(this.stages[0].id)
    })
  }

  async addStage(payload: any) {
    console.log("🚀🔥 ~ Adding Stage with Payload:", payload);
    this.isLoading = true;
    try {
      const response = await firstValueFrom(this.jobService.addStageFastApi(payload,this.eventId));

      if (response.status === 201) {
        await this.getStages();
        const stageId = this.stages[this.stages.length - 1].id;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.detail });

        const navigateTo = this.isInEvent ? 'events/candidates/' : 'jobs/candidates/';
        const entityName = this.isInEvent ? this.eventName : this.jobName;

        this.router.navigate([navigateTo, entityName], { queryParams: { view: 'workflow', stageId } });

        this.closeAddStage();
      }
    } catch (error) {
      console.error('Error adding stage:', error);
    } finally {
      this.isLoading = false;
      this.openCurrentStage();
      this.closeAddStage();
    }
  }

  

  createStage = () => {
    if (!this.stageName) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a stage name!' });
      return;
    }
    if (this.selectedType === 'Test' && !this.selectedForm) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select a test form!' });
      return;
    }
    if ((this.selectedType === 'Screening' || this.selectedType === 'Interview') && !this.selectedForm) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select a form!' });
      return;
    }
    if ((this.selectedType === 'Test' || this.selectedType === 'Interview') && !this.selectedMailTemplateId) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select a mail template!' });
      return;
    }
    this.isLoading = true;
    let payload = {
      event_uuid: this.jobId || this.eventId,
      name: this.stageName,
      type: this.selectedType,
      contest_uuid: this.selectedType === 'Test' ? this.selectedForm : null,
      form_uuid: this.selectedType === 'Screening' || this.selectedType === 'Interview' ? this.selectedForm : null,
      email_template_id: (this.selectedType === 'Test' || this.selectedType === 'Interview') ? this.selectedMailTemplateId.id : null
    };
    console.log("🚀🔥 ~ file: workflow.component.ts:380 ~ WorkflowComponent ~ payload:", payload)

    this.addStage(payload);
  }

  async duplicateStage(stage: any, overlayElement: any) {
    this.isLoading = true;
    let payload = {
        event_uuid: this.jobId || this.eventId,
        name: stage.name,
        type: stage.type,
        test_id: stage.type === 'Test' ? this.testForms : null,
        form_uuid: stage.type === 'Screening' || stage.type === 'Interview' ? this.selectedForm : null,
    };
    overlayElement.hide();
    await this.addStage(payload);
    this.stages.forEach((stage: any) => (stage.isActive = false));
    if (this.stages.length > 0) {
        this.stages[this.stages.length - 1].isActive = true;
    }
    this.isLoading = false;
}


  renameStage = async (stage: any) => {
    const payload = {
      event_uuid: this.jobId,
      name: this.stageRename,
      type: stage.type,
      test_id: stage.type === 'Test' ? this.testId : null,
      form_uuid: (stage.type === 'Screening' || stage.type === 'Interview') ? this.contestId : null,
    };
  
    if (this.stageRename.length && this.stageRename !== stage.name) {
      this.isLoading = true;
  
      try {
        const response = await firstValueFrom(this.jobService.updateStageFastApi(stage.id, payload));
        if (response.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.detail });
          this.stageRename = '';
          await this.getStages();
        }
      } catch (error) {
        console.error('Error updating stage:', error);
      } finally {
        this.isLoading = false;
      }
    } else {
      stage.isRenameStage = false;
    }
  }


deleteStage = (id: string) => {
  this.isLoading = true;
  
  this.jobService.deleteStageFastApi(id,this.eventId).subscribe({
    next: (response) => {
      if(response.status === 200){
        this.stages = this.stages.filter((val: any) => val.id !== id);
        
        if (this.stages.length > 0) {
          this.stages[0].isActive = true;
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.detail });
        this.isLoading = false;
      }
    },
    error: (_err) => {
      this.isLoading = false;
    }
  });
}


  closeAddStage = () => {
    this.selectedType = '';
    this.stageName = '';
    this.selectedForm = '';
    this.selectedMailTemplateId = '';
    this.selectedMailTemplate = '';
    this.displayAddStage = false;
  }

  openStage = (id: string) => {
    if (this.isInEvent) this.router.navigate(['events/candidates/', this.eventName], { queryParams: { view: 'workflow', stageId: id } });
    else this.router.navigate(['jobs/candidates/', this.jobName], { queryParams: { view: 'workflow', stageId: id } });

    this.stages.forEach((stage: any) => {
      if (stage.id === id) {
        stage.isActive = true;
        this.selectedStage = stage
      } else {
        stage.isActive = false;
        stage.isRenameStage = false;
      }
    })
    this.searchEmail = '';
    this.closeAddStage();
  }

  addRule = (stage: any) => {
    stage.workflowIds.push('-');
  }

  removeRule = (event: any, stage: any) => {
    stage.workflowIds = stage.workflowIds.filter((id: string) => id !== event);
    if (stage.workflowIds.length === 0) this.addRule(stage);
  }

  updateRule = (event: any, stage: any) => {
    stage.workflowIds.splice(stage.workflowIds.indexOf('-'), 1, event);
  }

  checkTestSelected = (event: boolean) => {
    this.isTestSelected = event;
  }

  checkFeedbackSelected = (event: boolean) => {
    this.isFeedbackSelected = event;
  }

  searchEmailCandidate = (event: any) => {
    let data = event.target.value;
    this.searchEmail = data.trim().split(/\s+/);
    console.log("🚀🔥 ~ file: workflow.component.ts:510 ~ WorkflowComponent ~ this.searchEmail:", this.searchEmail)
  }

  bulkCohort(){
    this.finalStage = false;
    this.displayCohort = true; 
    if( Array.isArray(this.emails) && this.emails.length){
      this.cohortDownloadRadio = 'selected-candidates'
    } else {
      this.cohortDownloadRadio = 'generic-template';
    }
  }

  back(){
    this.finalStage = false;
    this.templateValue = [];
    this.templateLabel = '';
    this.clicked = false;
  }

  stageFinal(){
    this.clicked =true;
    this.confirmBtnLoading = true;
    this.emailsCleared = false;
    let jobId = localStorage.getItem('selectedJobId')
    let payload = {
      schoolId : this.schoolId.school_id, 
      jobId : jobId, 
      stageId: this.selectedStage.stageId, 
      templateName : this.templateLabel, 
      userDetails : this.validatedData
    }
    this.eventServices.sendOfferCohort(payload).subscribe((res: any) => {
      if(res.status == 200 ){
        if(res.data?.length > 0){
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Sent Successfully!' });
          this.emailsCleared = true;
          this.clicked = false;
          this.confirmBtnLoading = false;
          this.displayCohort = false;
          this.moveStep = false;
          this.cohortUploaded = false;
          this.finalStage = false;
          this.cohortError = false;
          this.uploadingFile = false;
          this.errorFile = [];
          this.errorFileData = [];
          this.cohortFile = [];
          this.cohortFileCopy = [];
        }else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
          this.confirmBtnLoading = false;
        }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
        this.confirmBtnLoading = false;
      }
    })
  }

  stageOne(){
    if(this.moveStep){
      this.finalStage = true;
    }else{
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Successfull File upload required to move further! ' });
    }
    this.emailServices.getTemplates('').subscribe((response: any) => {
      let templates = response.data ? response.data.filter((val: any) => val.category === 'Candidates') : [];
      this.templateOptions = templates.map((item: any) => ({ label: item.templateTitle, value: item.templateContent }))  
    })
    this.templateValue = [];
  }

  emailList(event: any){
      this.emails = event;
  }

  cohortDownload(){
    if(this.cohortDownloadRadio == 'generic-template'){
        this.eventServices.downloadGenericCandidates(this.cohortDownloadRadio).subscribe((res: any) => {
          let result = res.data
          let fileData = result.fileLink;
          window.open(fileData,'_blank')
        })
      }
    else if(this.cohortDownloadRadio == 'selected-candidates'){
      let jobId = localStorage.getItem('selectedJobId')
      let stageId = this.selectedStage.stageId;

      let payload = {
        schoolId : this.schoolId.school_id,
        jobId : jobId,
        stageId : stageId,
        email : this.emails 
      }
      this.eventServices.downloadSelectedCandidates(this.cohortDownloadRadio,payload).subscribe((res: any) => {
        if(res.status == 200){
          let result = res.data;
          if(result.fileLink){
            window.open(result.fileLink,'_blank')
          } else {  
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the candidates!' });
          }
        } else {  
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
        }
      })
      }
    else if(this.cohortDownloadRadio == 'downloadAll'){
        let jobId = localStorage.getItem('selectedJobId')
        let stageId = this.selectedStage.stageId;
        this.eventServices.downloadAllCandidates(this.cohortDownloadRadio,jobId,stageId).subscribe((res: any) => {
          if(res.status == 200 && res.data?.fileLink){
            let result = res.data;
            window.open(result.fileLink,'_blank')
          } else if(res.status == 200 && res.data == 'There are no users in the stage'){
            this.messageService.add({ severity: 'error', summary: 'Error', detail: res.data });
          }else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
          }
        })
      }
  }

  fileUpload(fileCopy: any, file : any) {
    let fileName = fileCopy.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `cohort-upload/${this.schoolId.school_id}/${nanoid(10)}/${fileName}`,
      type: fileCopy.type,
    };
    const blobData: any = fileCopy;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                let signFile = pay;
                let jobId = localStorage.getItem('selectedJobId');
                let stageId = this.selectedStage.stageId;

                this.uploadCohort.controls['Bucket_name'].setValue(signFile.Bucket_name);
                this.uploadCohort.controls['file_name'].setValue(signFile.file_name);
                this.uploadCohort.controls['eventId'].setValue(jobId);
                this.uploadCohort.controls['actionId'].setValue("cohort-upload");
                this.uploadCohort.controls['stageId'].setValue(stageId);

                let payload = this.uploadCohort.value;

                this.eventServices.uploadCohortFile(payload).subscribe((res: any) => {
                  this.uploadingFile = false;
                  this.cohortUploaded = true;
                  let sizeInKb = parseInt(Math.floor(file.file[0].size / 1024).toFixed(2));
                  this.cohortFileSize = sizeInKb > 1024 ? (sizeInKb / 1024).toFixed(2) + ' MB' : sizeInKb + ' KB';
                  let result = res.data;
                  if(res.status == 200 && result.count.errored > 0 && result.count.validated > 0){
                    this.cohortError = true;
                    this.moveStep = false;
                    this.counts = result.count;
                    this.errorFileData = result.erroredJson;
                    this.errorFile = result.erroredFile;
                    this.validatedData = result.validatedData;
                    this.errorCheck();
                    this.messageService.add({ severity: 'warn', summary: 'Partially Correct', detail: 'File is Partially Correct!' });
                  } 
                  else if(res.status == 200 && result.count.validated == 0) {
                    this.cohortError = true;
                    this.moveStep = false;
                    this.counts = result.count;
                    this.errorFileData = result.erroredJson;
                    this.errorFile = result.erroredFile;
                    this.errorCheck();
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All the entries are invalid!' });
                  } 
                  else if(res.status == 200 && result.count.validated > 0 && result.count.errored == 0){
                    this.moveStep = true;
                    this.counts = result.count;
                    this.validatedData = result.validatedData;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Uploaded Successfully!' });
                  }
                  if(res.status == 500 && res.error ){
                    this.countDiv = false;
                    this.moveStep = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error});
                  }
                  else if(res.status == 500 ){
                    this.countDiv = false;
                    this.moveStep = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!'});
                  }
                })
              }
            }
          );
        }
      }
    );
  }

  locationCheck(data: any): boolean {
    let foundInvalidLocation = true;
  
    this.errorFileData.forEach((item: any) => {
      if (item?.invalidLocation && item?.invalidLocation[data] == false) {
        foundInvalidLocation = false;
      }
    });
  
    return foundInvalidLocation;
  }

  errorCheck(){
    let error: any = [];
    let error2: string[] = [];
    this.errorFileData.forEach((element:any) => {
      let errorFields = element.remarks.split(',');
      for(let i = 0; i < errorFields.length; i++){
        if(errorFields[i] == ErrorFields.email || errorFields[i] == ErrorFields.emptyEmail){
          error2.push('email');
        }else if(errorFields[i] == ErrorFields.cohortName || errorFields[i] == ErrorFields.emptycohortName){
          error2.push('cohortName');
        }else if(errorFields[i] == ErrorFields.jobLocation || errorFields[i] == ErrorFields.emptyjobLocation){
          error2.push('jobLocation');
        }else if(errorFields[i] == ErrorFields.jobRole || errorFields[i] == ErrorFields.emptyjobRole){
          error2.push('jobRole');
        }else if(errorFields[i] == 'Candidate is not in the stage'){
          error2.push('Candidate is not in the stage');
        }
      }
      error.push(error2);
      error2 = [];                     
    });
    for(let i = 0; i < this.errorFileData.length; i++){
      this.errorFileData[i].remarks = error[i];
    }
}

  errorReportDownload(){
    window.open(this.errorFile,'_blank')
  }

  cohortClosed(){
    this.cohortDownloadRadio = 'generic';
    this.clicked = false;
    this.cohortUploaded = false;
    this.cohortError = false;
    this.uploadingFile = false;
    this.errorFile = [];
    this.errorFileData = [];
    this.cohortFile = [];
    this.cohortFileCopy = [];

  }
  
  onRemoveExcel(){
    this.cohortUploaded = false;
    this.cohortError = false;
    this.countDiv = true;
    this.counts = 0;
    this.cohortFile = [];
    this.cohortFileCopy = [];
    this.errorFile = [];
    this.errorFileData = [];
  }

  cohortUpload(event: any){
    let cohortFile = {
      file : event.addedFiles,
      time : new Date()
    }
    this.cohortFileCopy = event.addedFiles;
    this.cohortFile[0] = cohortFile;
    this.fileUpload(this.cohortFileCopy[0],this.cohortFile[0])
  }

  templateChange(event: any){
    this.templateLabel = event.value.label;
    this.templateValue = event.value.value;
  }

  candidateCount(event: any)
  {
    this.showBulkCohort = event > 0 ? true : false;
  }
  async getSelectedMailTemplate(event: any){
    await this.getSpecificTemplateData(event);
  }
  async getSpecificTemplateData(template: any,): Promise<void> {
    if (template.id) {
      return new Promise((resolve, reject) => {
        this.emailServices.getSpecificTemplateFastApi(template.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.selectedMailTemplate ={
                templateContent: response.body.body,
                templateTitle: response.body.name,
              };
              resolve();
            }
          },
          error: (err) => {
            reject(new Error('Error fetching specific template: ' + err));
          }
        });
      });
    }
  }
  async getEventStages(event: any){
    if(event){
      await this.getStages();
    }
  }
}
