import { Component, OnInit } from '@angular/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit {
  categories: Array<any> = [];
  isLoading: boolean = true;
  activeComponent: string = 'Primary Account'

  constructor(private breadcrumbService: NgDynamicBreadcrumbService, private globalService: GlobalServicesService) {
    this.globalService.getUserProfileFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          let companyDetails = { company_metadata: res.body.company_metadata,
            company_settings: res.body.company_settings,
            company_details: res.body.company_details,
          };
          localStorage.setItem('company-details', JSON.stringify(companyDetails));
        }
      }
    });
  }

  ngOnInit(): void {
    this.getCategories();
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    const breadcrumbs = [
      { label: 'General Settings', url: '' }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }
  getCategories = () => {
    this.isLoading = false;
    this.categories = [{
      name: 'Primary Account',
      isActive: true
    },
    {
      name: 'Company Details',
      isActive: false
    },
    {
      name: 'Branches',
      isActive: false
    },
    {
      name: 'Date and Time',
      isActive: false
    },
    {
      name: 'Candidate Source Name',
      isActive: false
    },
    {
      name: 'Candidate Source Type',
      isActive: false
    },
    {
      name: 'Event Category',
      isActive: false
    },
    {
      name: 'Event Type',
      isActive: false
    },
    {
      name: 'Role Type',
      isActive: false
    },
    {
      name: 'Access Management',
      isActive: false
    }
    ]
  }
  openCategory = (name: string) => {
    this.activeComponent = name;
    this.categories.forEach((each) => {
      this.activeComponent === each.name ? each.isActive = true : each.isActive = false;
    })
  }
  handleKeydown(event: KeyboardEvent, categoryName: string): void {
    if (event.key === 'Enter') {
      this.openCategory(categoryName);
    }
  }

}
