import { Component, OnInit } from '@angular/core';
import { EventServicesService } from 'src/app/services/event-services.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-cohort-joining',
  templateUrl: './cohort-joining.component.html',
  styleUrls: ['./cohort-joining.component.css']
})
export class CohortJoiningComponent implements OnInit {

  cohortCols: Array<any> = [];
  pageLimits: any;
  cohortList: Array<any> = [];
  selectedCohort: Array<any> = [];
  cohortSpocList: any = [];
  userDetails: any;
  selectedUser: any;
  dateFormat: any;



  loadingSpinner: boolean = false;
  displayAddCohort: boolean = false;
  displayEditCohort: boolean = false;
  clicked: boolean = false;
  addBtnLoading: boolean = false;
  publishBtnLoading: boolean = false;
  isEdited: boolean = false;

  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;

  statusSelected: string = 'All';
  columnFieldId: string = '';


  cohortForm: FormGroup = new FormGroup({
    cohort_name: new FormControl('', [Validators.required]),
    cohort_date: new FormControl(null, [Validators.required]),
    cohort_spoc: new FormControl(null, [Validators.required])
  })

    constructor(private eventServices: EventServicesService, 
      private messageService: MessageService, 
      private confirmationService: ConfirmationService,
      private globalService: GlobalServicesService,
    ) { 
    this.pageLimits = [10, 25, 50, 100];

    this.cohortCols = [
      { index: 1, field: 'cohort_name', header: 'Cohort Name' },
      { index: 2, field: 'cohort_date', header: 'Cohort Date' },
      { index: 3, field: 'cohort_spoc', header: 'Cohort SPOC' },
      { index: 4, field: 'createdAt', header: 'Created On' },
      { index: 5, field: 'createdBy', header: 'Created By' }
    ];
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    this.getCohortDetails();
    this.userDetails = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails') || '') : '';
    
  }

  confirmUpdate() {
    this.confirmationService.confirm({
        message: "The candidates for whom the offer mail already sent with the already created cohort won't be notified.",
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.updateCohort();
        },
    });
}

  updateCohort(){
    this.clicked = true;
    if(this.cohortForm.valid){
      this.publishBtnLoading = true;
      let payload = this.cohortForm.value;
      payload.cohort_spoc = payload.cohort_spoc.id;
      payload['createdBy'] = this.userDetails.name;
      payload['id'] = this.selectedUser.id;
      payload['mappedEventIds'] = this.selectedUser.mappedEventIds;
      payload['schoolId'] = this.selectedUser.schoolId;
      this.eventServices.updateCohortData(payload).subscribe((res:any) => {
        if(res.status == 200){
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully' });
          this.publishBtnLoading = false;
          this.clicked = false;
          this.cohortForm.reset();
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable To Update' });
          setTimeout(()=>{
            this.publishBtnLoading = false;
          }, 2000);
        }
      })
    }
  }

  saveCohort(){
    this.clicked = true;
    if(this.cohortForm.valid){
      this.publishBtnLoading = true;
      let payload = this.cohortForm.value;
      payload.cohort_spoc = payload.cohort_spoc.id;
      payload['createdBy'] = this.userDetails.name;
      this.eventServices.createCohortId(payload).subscribe((res: any) => {
        if(res.status == 200){
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added Successfully' });
          this.publishBtnLoading = false;
          this.clicked = false;
          this.cohortForm.reset();
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable To Add' });
          setTimeout(()=>{
            this.publishBtnLoading = false;
          }, 2000);
        }
      })
    }
  }

  sliderClose(){
    this.displayAddCohort = false;
    this.displayEditCohort = false;
    this.clicked = false;
    this.cohortForm.reset();
    this.getCohortDetails();
  }

  getCohortDetails(){
    this.loadingSpinner = true;
    this.eventServices.getAllCohortPagination(this.pageNumber,this.pageSize).subscribe((response : any) => {
      this.cohortList = response.data ? response.data.items : [];
      this.totalLength = response.data ? response.data.totalItems : 0;
      this.loadingSpinner = false;
    })
    this.eventServices.getAllUser().subscribe(res => {
      let data = res?.data?.items;
      this.cohortSpocList = data.map((value:any) =>{return {id: value.id, name: value.name}});
    })
  }

  editSlider(user: any){
    this.displayEditCohort = true;
    let spoc_name = {id:user.cohort_spoc.id , name: user.cohort_spoc.name};
    this.selectedUser = user;
    this.cohortForm.patchValue({
      cohort_name: user.cohort_name,
      cohort_date: new Date(user.cohort_date),
      cohort_spoc: spoc_name,
    });

  }

  onSelectedCohortChange = (value: []) => {
    this.selectedCohort = value;
  }

  getCohortByLimit = () => {
    this.selectedCohort = [];
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getCohortDetails();
  }

  next(): void {
    this.selectedCohort = [];
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getCohortDetails();
  }
  prev(): void {
    this.selectedCohort = [];
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? ( this.last == this.totalLength ? this.totalLength - this.cohortList.length : this.last - this.pageSize ) : this.pageSize;
    this.pageNumber -= 1;
    this.getCohortDetails();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

}
