import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '', 
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }
  constructor(private http: HttpClient,private globalService: GlobalServicesService) { }

  //Fast API
  getCompanyDataFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/company-details', {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateCompanyDataFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(this.fast_api_url + '/company-details', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getBranchDataFastApi(): Observable<HttpResponse<any>> {
    console.log("🚀🔥 ~ file: settings.service.ts:68 ~ SettingsService ~ getBranchDataFastApi ~ this.fast_api_url:", this.fast_api_url)
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/branches', {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  createBranchDataFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/branches', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getSpecificBranchDataFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/branches/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateSpecificBranchDataFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/branches/' + id, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteSpecificBranchDataFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/branches/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  emailVerifyFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/profile/change-email', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  emailUpdateFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/profile/update-email', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  changePasswordFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/auth/change-password', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  // candidate source name
  getCandidateSourceNameFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);
  
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/candidate-sources', {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getSpecificCandidateSourceFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/candidate-sources/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  addCandidateSourceFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/candidate-sources', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateCandidateSourceFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/candidate-sources/' + id, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteCandidateSourceFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/candidate-sources/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  // candidate source type
  getCandidateSourceTypeFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);
  
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/candidate-source-types', {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getSpecificCandidateSourceTypeFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/candidate-source-types/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  addCandidateSourceTypeFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/candidate-source-types', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateCandidateSourceTypeFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/candidate-source-types/' + id, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteCandidateSourceTypeFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/candidate-source-types/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  // event category
  getEventCategoryFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);
  
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/event-categories', {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getSpecificEventCategoryFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/event-categories/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  addEventCategoryFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/event-categories', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateEventCategoryFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/event-categories/' + id, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteEventCategoryFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/event-categories/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  // event type
  getEventTypeFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);
  
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/event-types', {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  getSpecificEventTypeFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/event-types/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  addEventTypeFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/event-types', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  updateEventTypeFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/event-types/' + id, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  deleteEventTypeFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/event-types/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // role type APIs (Fast API)
  getRoleTypesFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);

    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/role-types', {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getSpecificRoleTypeFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/role-types/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  addRoleTypeFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/role-types', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  updateRoleTypeFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/role-types/' + id, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  deleteRoleTypeFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/role-types/' + id, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  //Company API
  getCompanyData(): Observable<any> {
    return this.http.get(this.api_url + '/company-details/', this.options());
  }
  updateCompanyData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-details/' + id ,payload, this.options());
  }

  //Primary API
  updateAccount(payload: any): Observable<any>{
    return this.http.put(this.api_url + '/primaryAccount/update/details',payload, this.options());
  }

  //Branch API
  getBranchData(): Observable<any> {
    return this.http.get(this.api_url + '/company-branches/', this.options());
  }

  updateBranchData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-branches/' + id ,payload, this.options());
  }

  //Departments API
  getAllDepartmentData(): Observable<any> {
    return this.http.get(this.api_url + '/company-departments/', this.options());
  }

  getDepartmentData(id: string): Observable<any> {
    return this.http.get(this.api_url + '/company-departments/' + id, this.options());
  }

  updateDepartmentData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-departments/' + id ,payload, this.options());
  }

  deleteDepartmentData(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/company-departments/' + id, this.options());
  }

  addDepartmentData(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/company-departments/', payload, this.options());
  }

  dateTimeUpdate(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/schools/date-settings' , payload, this.options());
  }

  //Email
  currentEmail(userId: any, oldEmailId: any): Observable<any> {
    return this.http.post(this.api_url + '/change-email/' + userId, oldEmailId, this.options());
  }

  currentEmailVerify(payload: any):Observable<any> {
    return this.http.put(this.api_url + '/change-email/verify/', payload, this.options());
  }

  newEmail(userId: any, newEmailId: any): Observable<any> {
    return this.http.put(this.api_url + '/change-email/newmail/' + userId, newEmailId, this.options());
  }

  newEmailVerify(payload: any, userId: any): Observable<any> {
    return this.http.put(this.api_url + '/change-email/newOTP/' + userId, payload, this.options());
  }

  otpAuthentication(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/schools/otp-settings', payload, this.options());
  }
}
