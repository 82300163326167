import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { EventServicesService } from 'src/app/services/event-services.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { JobServicesService } from 'src/app/services/job-services.service';

enum EventTypes {
  InCampus = 'In-Campus',
  offCampus = 'Off-Campus',
  walkIn = 'Walk-In',
  poolCampus = 'Pool Campus'
}

@Component({
  selector: 'app-events-add',
  templateUrl: './events-add.component.html',
  styleUrls: ['./events-add.component.css'],
  providers: [MessageService, DatePipe]
})
export class EventsAddComponent implements OnInit, OnChanges {

  @Input() eventData: any;
  @Input() eventJobData: any;
  @Input() eventCloneData: any;
  @Input() isEventCloneData: boolean = false;
  @Input() inEditMode: boolean = false;

  @Output() eventCloned = new EventEmitter<boolean>();

  institutions:  any[] = [];
  cloneEvent: boolean = false;
  clicked: boolean = false;
  saveBtnLoading: boolean = false;
  publishBtnLoading: boolean = false;
  gettingRoles: boolean = false;
  // expInvalid: boolean = false;

  eventTypes: Array<any> = [];
  eventCategories: Array<any> = [];
  jobRoles: Array<any> = [];
  selectedJobRoles: any[] = [];
  selectedInstitutions: any[] = [];
  owners: Array<any> = [];
  ownersDetails: Array<any> = [];
  regForms: Array<any> = [];
  emailTemplates: Array<any> = [];
  // minExp!: number;
  // maxExp!: number;

  minSalary!: number;
  maxSalary!: number;

  //Dates
  eventStartDate: any;
  eventEndDate: any
  registrationStartDate: any;
  registrationEndDate: any;

  currentDate = new Date();
  renderForm = false;

  addEventForm: FormGroup = new FormGroup({
    eventTitle: new FormControl('', Validators.required),
    eventStartDate: new FormControl('', Validators.required),
    eventEndDate: new FormControl('', Validators.required),
    jobRole: new FormControl('', Validators.required),
    //Might Be Needed for Future Ref
    // minWorkExperience: new FormControl('', [Validators.required, Validators.min(0), Validators.max(30)]),
    // maxWorkExperience: new FormControl('', [Validators.required, Validators.min(0), Validators.max(30)]),
    workExperience: new FormControl(null, [ Validators.min(0), Validators.max(50)]),
    minSalary: new FormControl(null, [ Validators.min(0)]),
    maxSalary: new FormControl(null, [ Validators.min(0), (control: AbstractControl) => Validators.min(this.minSalary)(control)]),
    eventType: new FormControl(null, Validators.required),
    eventCategory: new FormControl(null, Validators.required),
    institutionName: new FormControl(null, Validators.required),
    institutionAddress: new FormControl('', Validators.required),
    registrationStartDate: new FormControl('', Validators.required),
    registrationEndDate: new FormControl('', Validators.required),
    reg_form_uuid: new FormControl(null, Validators.required),
    email_template_id: new FormControl(null, Validators.required),
    createdRole: new FormControl(null, Validators.required),
    createdBy: new FormControl(null, Validators.required),
    status: new FormControl(''),
  })

  constructor(
    private eventServices: EventServicesService,
    private messageService: MessageService,
    private jobServices: JobServicesService
  ) {}

  ngOnInit(): void {
    this.getFormData();
  }
  getFormData = async () => {
    this.gettingRoles = true;
    try {
      const response = await this.eventServices.getEventFormDataFastApi().toPromise();

      if (response && response.status === 200 && response.body) {
        this.jobRoles = response.body.job_roles;
        this.owners = response.body.roles;
        this.emailTemplates = response.body.email_templates;
        this.eventCategories = response.body.event_categories;
        this.eventTypes = response.body.event_types;
        // this.eventTypes = [
        //   {
        //     "id": 1,
        //     "name": "lfqDqhMPEkxWuHbgm",
        //     "institution_select_type": "multiple"
        //   },
        //   {
        //     "id": 2,
        //     "name": "yuidzfYUzBdHOCLBAVfp",
        //     "institution_select_type": "single"
        //   }
        // ];
        // this.eventTypes = [
        //   {
        //     "id": 1,
        //     "name": "Event Type 12",
        //     "institution_select_type": "multiple"
        //   },
        //   {
        //     "id": 2,
        //     "name": "Event Type 11",
        //     "institution_select_type": "single"
        //   }
        // ];
        this.institutions = response.body.institutes;
        this.regForms = response.body.forms;
        //   this.regForms = [
        //     {
        //         "uuid": "0cb88586-0cd9-45da-8c38-5dff37ce9a24",
        //         "name": "documentation_form"
        //     }
        // ];
        this.gettingRoles = false;
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
    } finally {
      this.gettingRoles = false;
    }
  };

  onEventTypeChange() {
    const selectedEventType = this.getSelectedEventType();
    if (selectedEventType) {
      // Reset the institutionName control when event type changes
      this.addEventForm.controls['institutionName'].setValue(null);
    }
  }

  getSelectedEventType() {
    const selectedId = this.addEventForm.value.eventType;
    return this.eventTypes.find(eventType => eventType.id === selectedId);
  }
  async ngOnChanges(changes: SimpleChanges) {
    if(changes['eventCloneData']){
      this.setValues(this.eventCloneData);
    }

    if(changes['eventData']){
      // this.inEditMode = Object.keys(this.eventData).length ? true : false;
      if (this.inEditMode) this.setValues(this.eventData);
    }
  }

  async setValues(eventData: any) {
    console.log("🚀🔥 ~ file: events-add.component.ts:172 ~ EventsAddComponent ~ setValues ~ eventData:", eventData)
    let eventDetails: any = {};
    // this.inEditMode = Object.keys(this.eventData).length ? true : false;

    if (this.inEditMode || this.isEventCloneData) {
      this.renderForm = true;

      try {
        const response = await this.eventServices.getSpecificEventFastApi(eventData.id).toPromise();

        if (response?.status === 200 && response.body) {
          const { event_details } = response.body;
          eventDetails = {
            eventTitle: event_details.name,
            jobRole: event_details.job_roles.map((role: any) => role.id),
            workExperience: event_details.work_experience,
            minSalary: event_details.min_salary,
            maxSalary: event_details.max_salary,
            eventType: event_details.event_type.id,
            eventCategory: event_details.event_category.id,
            institutionName: event_details.institutes.map((institute: any) => institute.id),
            institutionAddress: event_details.institution_address,
            reg_form_uuid: event_details.form.uuid,
            email_template_id: event_details.email_template.id,
            createdBy: event_details.owner.id,
          };

          this.addEventForm.patchValue(eventDetails);
          this.selectedJobRoles = eventDetails.jobRole;
          console.log("🚀🔥 ~ file: events-add.component.ts:201 ~ EventsAddComponent ~ setValues ~ eventDetails:", eventDetails)
          this.selectedInstitutions = eventDetails.institutionName;
          console.log("🚀🔥 ~ file: events-add.component.ts:202 ~ EventsAddComponent ~ setValues ~ this.selectedInstitutions:", this.selectedInstitutions)
          if (this.inEditMode) {
            this.renderForm = true;
            this.addEventForm.controls['eventStartDate'].setValue(new Date(event_details.event_start_date));
            this.addEventForm.controls['eventEndDate'].setValue(new Date(event_details.event_end_date));
            this.addEventForm.controls['registrationStartDate'].setValue(new Date(event_details.registration_start_date));
            this.addEventForm.controls['registrationEndDate'].setValue(new Date(event_details.registration_end_date));
            this.eventStartDate = new Date(event_details.event_start_date);
            this.eventEndDate = new Date(event_details.event_end_date);
            this.registrationStartDate = new Date(event_details.registration_start_date);
            this.registrationEndDate = new Date(event_details.registration_end_date);
            this.renderForm = true;

          }
          console.log("🚀🔥 ~ file: events-add.component.ts:210 ~ EventsAddComponent ~ setValues ~ this.registrationEndDate:", this.registrationEndDate)
        } else {
          console.error('Failed to fetch event details: Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    }
  }


  getAllRoles = (eventData: any, action?: string) => {
    this.gettingRoles = true;
    this.eventServices.getAllRoles().subscribe(res => {
      let _jobRoles = res.data ? res.data : [];
      this.jobRoles = [];
      _jobRoles.forEach((role: any) => { this.jobRoles.push({ roleId: role.id, roleName: role.roleName }) });
      if(action === 'renderChip')
      {
        this.selectedJobRoles = (eventData && eventData.jobRole) ? eventData.jobRole : [];
      }
      this.gettingRoles = false;
    })
  }


  getOwners = () => {
    this.eventServices.getAllUser().subscribe(res => {
      this.owners = res.data.items ? res.data.items : [];
    })
  }

  updateEvent = (payload: FormGroup) => {
    this.clicked = true;
    if (this.addEventForm.valid) {
      this.publishBtnLoading = true;
      const updatePayload = this.createPayload();
      this.eventServices.updateEventFastApi(updatePayload, this.eventData.id).subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.detail });
          }
          this.publishBtnLoading = false;
        },
        error: (err) => {
          this.publishBtnLoading = false;
          console.error('Error updating event:', err);
        }
      });
      this.clicked = false;
    }
  }

  publishEvent = (payload: any) => {
    this.clicked = true;
    if (this.addEventForm.valid) {
      this.publishBtnLoading = true;
      const publishPayload = this.createPayload();
      this.eventServices.createEventFastApi(publishPayload).subscribe({
        next: (response) => {
          if (response.status === 201) {
            this.addEventForm.reset();
            this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.detail });
            this.clicked = false;
          }
        },
        error: () => {
          this.publishBtnLoading = false;
        }
      });
      this.clicked = false;
    }
  }

  private createPayload() {
    return {
      name: this.addEventForm.controls['eventTitle'].value,
      event_start_date: this.addEventForm.controls['eventStartDate'].value,
      event_end_date: this.addEventForm.controls['eventEndDate'].value,
      job_roles: this.selectedJobRoles,
      work_experience: this.addEventForm.controls['workExperience'].value,
      min_salary: this.addEventForm.controls['minSalary'].value,
      max_salary: this.addEventForm.controls['maxSalary'].value,
      event_category_id: this.addEventForm.controls['eventCategory'].value,
      event_type_id: this.addEventForm.controls['eventType'].value,
      registration_start_date: this.addEventForm.controls['registrationStartDate'].value,
      registration_end_date: this.addEventForm.controls['registrationEndDate'].value,
      owner_id: this.addEventForm.controls['createdBy'].value,
      form_uuid: this.addEventForm.controls['reg_form_uuid'].value,
      email_template_id: this.addEventForm.controls['email_template_id'].value,
      institution_address: this.addEventForm.controls['institutionAddress'].value,
      institutes: Array.isArray(this.addEventForm.controls['institutionName'].value) 
        ? this.addEventForm.controls['institutionName'].value 
        : [this.addEventForm.controls['institutionName'].value], 
    };
  }

  onPublish() {
  }

  handleDate() {
  }

  resetRegistrationDates() {
    if (!this.renderForm) {
      // Reset registration dates if the form is not rendered
      this.addEventForm.controls['registrationStartDate'].setValue(null);
      this.addEventForm.controls['registrationEndDate'].setValue(null);
    } else {
      // Set renderForm based on inEditMode
      this.renderForm = this.inEditMode;
    }
  }
  getOwnerDetails = (roleId: any) => {
    this.jobServices.getUserByRoleIdFastApi(roleId).subscribe({
      next: (response: any) => {
        const ownerDetails =  Object.keys(response.body.users).length !== 0 ? response.body.users : [];
        this.ownersDetails = ownerDetails.map((owner:any) => ({
          name: `${owner.first_name} ${owner.last_name}`,
          id: owner.id
        }));;
      },
    });
  }
}
function control(control: any): import("@angular/forms").ValidatorFn {
  throw new Error('Function not implemented.');
}

