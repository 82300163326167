import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { JobServicesService } from 'src/app/services/job-services.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { GlobalServicesService } from 'src/app/services/global-services.service';

export enum UserTypes {
  superAdmin = 'Super Admin',
  admin = 'Admin',
  recruiter = 'Recruiter',
  evaluator = 'Evaluator',
  interviewer = 'Interviewer'
}

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
  providers: [MessageService]
})

export class AddUserComponent implements OnChanges, OnInit {

  @Input() data: any;
  associativeRoles: any = [];
  saveBtnLoading: boolean = false;
  showInfo: boolean = false;
  clicked: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  password: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
  userPrivilages: any;
  userRole: string = '';
  userHeader: string = '';
  selectedRoleId!: string;
  dis !: any;
  infoData: any = [];
  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;
  passwordMatchValidator: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    const form = control as FormGroup;
    return form.get('password')?.value === form.get('confirm_password')?.value ? null : { mismatch: true };
  }
  addUserForm: FormGroup;
 
  showPassword: boolean = false;
  edit: boolean = false;
  constructor(private jobServices: JobServicesService, private messageService: MessageService, private globalService: GlobalServicesService) {
    this.userPrivilages = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
    this.userRole = JSON.parse(this.globalService.decryptPrivileges()).userType;
    this.addUserForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      password: new FormControl('', this.data && !this.data.isUpdate ? [Validators.required, Validators.pattern(this.password)] : []),
      confirm_password: new FormControl('', this.data && !this.data.isUpdate ? [Validators.required, Validators.pattern(this.password)] : []),
      mobile_number: new FormControl(null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      role_id: new FormControl(null,[Validators.required])
    }, { validators: this.passwordMatchValidator })
    this.getUserFormData();
  }

  ngOnChanges(): void {
    if (this.data) {
      this.data.isUpdate ? this.edit = true : this.edit = false;
      this.addUserForm.patchValue(this.data)
    }
  }
  ngOnInit(): void {
    (this.data && this.data.isUpdate) ? this.userHeader = 'Update User': this.userHeader = 'Create New User';
  }
  getUserFormData = () => {
    this.jobServices.getUserFormDataFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.infoData = [];
          this.associativeRoles = res.body.roles.map((item: any) => {
            this.infoData.push({ name: item.name, description: item.description, id: item.id });
            return { label: item.name, value: item.id }
          });
        }
      }
    });
  }

  saveUser = () => {
    this.clicked = true;
    if (this.addUserForm.valid) {
      this.saveBtnLoading = true;
      if(this.data && this.data.isUpdate) {
        this.updateUser();
        return;
      }
      this.addUser();
    }
  }
  public updateUser() {
    let payload = this.addUserForm.value;
    this.jobServices.updateUserFastApi(payload, this.data.id).subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
          this.addUserForm.reset();
        }
        this.saveBtnLoading = false;
        this.clicked = false;
      },
      error: (_err: any) => {
        this.saveBtnLoading = false;
        this.clicked = false;
      }
    });
  }
  public addUser() {
    let payload = this.addUserForm.value;
    this.jobServices.createUserFastApi(payload).subscribe({
      next: (res: any) => {
        if (res.status === 201) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
          this.addUserForm.reset();
        }
        this.saveBtnLoading = false;
        this.clicked = false;
      },
      error: (_err: any) => {
        this.saveBtnLoading = false;
        this.clicked = false;
      }
    });
  }

  onSave() { }

  cancel = () => {
    this.addUserForm.reset();
    this.showInfo = false;

  }
  onClick() {

    this.showInfo = true;
    this.selectedRoleId = this.addUserForm.value.role_id;
    for (let data of this.infoData) {
      if (data.id === this.selectedRoleId) {
        this.dis = data.description;
        break;
      }
    }
  }

  restrictNumber(event: any) {
    let e: any = event || window.event; 
    let key = e.keyCode || e.which;

    if (key < 48 || key > 57) { 
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
}

showToggle(className: string)
{
  let passwordInput: any = document.getElementsByClassName(className)[0];
  if (passwordInput.type === "password") {
    passwordInput.type = "text";
    this.showPassword = true;
  } else {
    passwordInput.type = "password";
    this.showPassword = false;
  }
}
}
