import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/services/settings.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-otp-authentication',
  templateUrl: './otp-authentication.component.html',
  styleUrls: ['./otp-authentication.component.css']
})
export class OtpAuthenticationComponent implements OnInit {
  
  //Any Variables
  p1Form: any;
  offerAcc: any;
  documentSub: any;
  beforeInterview: any;
  beforeAssessment: any;

  constructor(private settings: SettingsService, private messageService: MessageService,private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    this.globalService.getSchoolDetails().subscribe(res => {
      this.beforeInterview = res.data[0].otpSettings?.beforeInterview;
      this.p1Form = res.data[0].otpSettings?.registrationForm;
    })
  }

  toggleChange(){
    let payload = {
      registrationForm : this.p1Form,
      beforeInterview : this.beforeInterview
    }
    this.settings.otpAuthentication(payload).subscribe(res => {
      if(res.status != 200){
        this.p1Form = false;
        this.beforeInterview = false;
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong, Please try again!' });
      }
    })
  }

}
