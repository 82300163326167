<div *ngIf="loadingSpinner" class="loadingOverlayRole">
  <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>
<div [ngStyle]="{'visibility':showEditor ? 'visible' : 'hidden'}">
  <div class="roles-add-header">
    <div class="roles-heading">{{ roleHeader }}</div>
    <div class="header-btn">
      <button pButton pRipple type="submit" (click)="submitItem()" [loading]="saveBtnLoading" label="{{ saveStatus }}"
        class="p-button-raised p-button-text button-align"></button>
    </div>
  </div>
  <p-divider class="p-divider"></p-divider>
  <form class="jobForm" [formGroup]="orderForm" (ngSubmit)="onSave()">
    <span class="p-float-label jobForm-elements">
      <input style="width: 100%" type="text" id="inputtext"
        pInputText formControlName="roleName"
        [ngClass]="{'invalid': clicked && orderForm.controls['roleName'].errors}" />
      <label for="inputtext">Role Name <span [style]="{ color: '#E24A47' }">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="orderForm.get('roleName')" name="Role name"></app-handle-error>
    </span>
    <span class="p-float-label jobForm-elements">
      <input [ngClass]="{ 'p-filled': orderForm.value.requirement }" style="width: 100%" type="text" id="inputtext"
        pInputText formControlName="requirement" />
      <label for="inputtext">Requirement</label>
    </span>
    <div class="form-salary jobForm-elements">
      <span class="p-input-icon-right p-float-label jobForm-elements">
        <input #roleTypeDiv readonly style="width: 100%" type="text" pInputText
          inputId="dropdown" formControlName="roleType" [(ngModel)]="selectedRoleType"
          (click)="roleTypeOverlay.toggle($event)" [style]="{ cursor: 'pointer' }"
          [ngClass]="{'invalid': clicked && orderForm.controls['roleType'].errors}" />
          <em class="pi pi-angle-down dropdown" (click)="viewRole()" (keydown.enter)="viewRole()"></em>        <label for="dropdown">Role Type <span [style]="{ color: '#E24A47' }">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="orderForm.get('roleType')" name="Role type"></app-handle-error>
        <p-overlayPanel #roleTypeOverlay>
          <ng-template pTemplate>
            <div *ngFor="let field of roleTypeList">
              <p (click)="selectedRoleType = field.label; roleTypeOverlay.hide()"
                (keydown.enter)="selectedRoleType = field.label; roleTypeOverlay.hide()" class="department-options" tabindex="0">
                {{ field.label }}
              </p>
            </div>
          </ng-template>
        </p-overlayPanel>
      </span>
    </div>
    <div class="form-salary jobForm-elements">
      <span class="p-input-icon-right p-float-label jobForm-elements">
        <input #remoteDiv readonly style="width: 100%" type="text" pInputText
          inputId="dropdown" formControlName="remoteJob" [(ngModel)]="selectedRemote"
          (click)="workModeOverlay.toggle($event)" [style]="{ cursor: 'pointer' }"
          [ngClass]="{'invalid': clicked && orderForm.controls['remoteJob'].errors}" />
          <em class="pi pi-angle-down dropdown" (click)="viewRemote()" (keydown.enter)="viewRemote()"></em>        <label for="dropdown">Work Mode<span [style]="{ color: '#E24A47' }">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="orderForm.get('remoteJob')" name="Work Mode"></app-handle-error>
        <p-overlayPanel #workModeOverlay>
          <ng-template pTemplate>
            <div *ngFor="let field of workModeList">
              <p (click)="selectedRemote = field.label; workModeOverlay.hide()"
                (keydown.enter)="selectedRemote = field.label; workModeOverlay.hide()" class="department-options" tabindex="0">
                {{ field.label }}
              </p>
            </div>
          </ng-template>
        </p-overlayPanel>
      </span>
    </div>
    <textarea class="p-editor-container jobForm-jobDescription" [style]="{ height: '240px' }" id="editor"
      formControlName="roleDescription" placeholder="Role Description"></textarea>
    <div class="profilePhoto form-details">
      <p class="heading">Role Description / Resource Attach</p>
      <div class="fileUpload-dropzone" ngx-dropzone [accept]="'image/JPEG, application/pdf'" [multiple]="false"
        (change)="onSelectMedia($event)">
        <ngx-dropzone-label *ngIf="!uploadingFile">
          <div class="dropzone-label">
            <em class="pi pi-cloud-upload"></em>
            <p class="upload-text">
              Click to
              <span [style]="{ color: 'var(--fc-primary)' }">upload</span> or Drag
              & Drop
            </p>
            <p class="format-text">PDF, JPEG Formats only</p>
          </div>
        </ngx-dropzone-label>
        <div *ngIf="uploadingFile">
          <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
          </p-progressSpinner>
        </div>
        <div *ngIf="attachmentName?.length">
          <ngx-dropzone-preview class="resume-preview" ngProjectAs="ngx-dropzone-preview" *ngFor="let f of mediaFile"
            [file]="f" [removable]="true" (removed)="onRemoveMedia(f)">
            <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
        </div>
      </div>

    </div>
    <div>
      <div formArrayName="locationInformation" *ngFor="let item of formData.controls; let i = index">
        <p-divider *ngIf="i" class="p-divider"></p-divider>
        <div [formGroupName]="i">
          <div style="display: flex; justify-content: space-between; width: 25vw">
            <p class="heading">Location {{ i + 1 }}</p>
            <em *ngIf="formData.controls.length > 1" class="pi pi-trash cursor-pointer" (click)="removeItem(i)" (keydown.enter)="removeItem(i)"></em>
          </div>
          <div class="form-salary jobForm-elements">
            <span class="p-input-icon-right p-float-label jobForm-elements">
              <input #countryDiv readonly [ngClass]="{
                  'p-filled': orderForm.value.locationInformation.at(i).country,
                  'invalid': clicked && locationInformation.controls[i].get('country')?.errors
                }" style="width: 100%" type="text" id="drop-input" inputId="dropdown" formControlName="country"
                (click)="countryOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
              <em (click)="viewCountry()" (keydown.enter)="viewCountry()" class="pi pi-angle-down dropdown"></em>
              <label for="dropdown">country <span [style]="{ color: '#E24A47' }">*</span></label>
              <app-handle-error [clicked]="clicked" [control]="locationInformation.controls[i].get('country')"
                name="country"></app-handle-error>
              <p-overlayPanel #countryOverlay>
                <ng-template pTemplate>
                  <div class="overlay-container" style="overflow-y: auto;max-height: 200px;min-height: 50px;">
                    <div *ngIf="countries.length > 0; else noCountryRecords">
                      <div *ngFor="let field of countries">
                        <p (click)="
                        countryOverlay.hide();
                        onClickCountryChange(field.value, i);
                      " class="department-options" (keydown)="
                        countryOverlay.hide();
                        onClickCountryChange(field.value, i);
                      ">
                          {{ field.label }}
                        </p>
                      </div>
                    </div>
                    <ng-template #noCountryRecords>
                      <p class="department-options">No records found</p>
                    </ng-template>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
          </div>
          <div class="form-salary jobForm-elements">
            <span class="p-input-icon-right p-float-label jobForm-elements">
              <input #stateDiv readonly [ngClass]="{
                  'p-filled': orderForm.value.locationInformation.at(i).state,
                  'invalid': clicked && locationInformation.controls[i].get('state')?.errors
                }" style="width: 100%" type="text" id="drop-input" inputId="dropdown" formControlName="state"
                (click)="stateOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
              <em (click)="viewState()" (keydown.enter)="viewState()" class="pi pi-angle-down dropdown"></em>
              <label for="dropdown">state <span [style]="{ color: '#E24A47' }">*</span></label>
              <app-handle-error [clicked]="clicked" [control]="locationInformation.controls[i].get('state')"
                name="state"></app-handle-error>
              <p-overlayPanel #stateOverlay>
                <ng-template pTemplate>
                  <div class="overlay-container" style="overflow-y: auto;max-height: 200px;min-height: 50px;">
                    <div *ngIf="locationOptions[i].states.length > 0; else noRecords">
                      <div *ngFor="let field of locationOptions[i].states">
                        <p (click)="
                        stateOverlay.hide();
                        onClickStateChange(field.value, i);
                      " class="department-options" (keydown)="
                        stateOverlay.hide();
                        onClickStateChange(field.value, i);">
                          {{ field.label }}
                        </p>
                      
                      </div>
                    </div>
                    <ng-template #noRecords>
                      <p class="department-options">No records found</p>
                    </ng-template>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
          </div>
          <div class="form-salary jobForm-elements">
            <span class="p-input-icon-right p-float-label jobForm-elements">
              <input #cityDiv readonly [ngClass]="{
                  'p-filled': orderForm.value.locationInformation.at(i).city,
                  'invalid': clicked && locationInformation.controls[i].get('city')?.errors
                }" style="width: 100%" type="text" id="drop-input" inputId="dropdown" formControlName="city"
                (click)="cityOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
              <em (click)="viewCity()" (keydown.enter)="viewCity()" class="pi pi-angle-down dropdown"></em>
              <label for="dropdown">city <span [style]="{ color: '#E24A47' }">*</span></label>
              <app-handle-error [clicked]="clicked" [control]="locationInformation.controls[i].get('city')"
                name="city"></app-handle-error>
              <p-overlayPanel #cityOverlay>
                <ng-template pTemplate>
                  <div class="overlay-container" style="overflow-y: auto;max-height: 200px;min-height: 50px;">
                    <div *ngIf="locationOptions[i].cities.length > 0; else noCityRecords">
                      <div *ngFor="let field of locationOptions[i].cities">
                        <p (click)="
                        cityOverlay.hide();
                        onClickCityChange(field.value, i);
                      " class="department-options" (keydown)="
                        cityOverlay.hide();
                        onClickCityChange(field.value, i);
                      ">
                          {{ field.label }}
                        </p>
                      </div>
                    </div>
                    <ng-template #noCityRecords>
                      <p class="department-options">No records found</p>
                    </ng-template>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
          </div>
          <div class="form-salary jobForm-elements">
            <span class="p-input-icon-right p-float-label jobForm-elements">
              <input #hiringManagerDiv readonly [ngClass]="{
                  'p-filled':
                    orderForm.value.locationInformation.at(i).hiringManager,
                    'invalid': clicked && locationInformation.controls[i].get('hiringManager')?.errors
                }" style="width: 100%;" type="text" inputId="dropdown" id="drop-input" formControlName="hiringManager"
                (click)="hiringManagerOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
              <em (click)='viewManager()' (keydown.enter)="viewManager()" class="pi pi-angle-down dropdown"></em>
              <label for="dropdown">Hiring Manager
                <span [style]="{ color: '#E24A47' }">*</span></label>
              <app-handle-error [clicked]="clicked" [control]="locationInformation.controls[i].get('hiringManager')"
                name="Hiring manager"></app-handle-error>
              <p-overlayPanel #hiringManagerOverlay>
                <ng-template pTemplate>
                  <div class="overlay-container" style="overflow-y: scroll; height: 200px;">
                    <div *ngFor="let field of hiringManager">
                      <p (click)="
                          hiringManagerOverlay.hide();
                          updateHiringManagerDetails(['hiringManager', 'hiringManagerId'], i, field)
                        " class="department-options">
                        {{ field.name }}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
          </div>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled': orderForm.value.locationInformation.at(i).businessUnit
              }" style="width: 100%" type="text" id="inputtext" pInputText formControlName="businessUnit" />
            <label for="inputtext">Business Unit <span [style]="{ color: '#E24A47' }">*</span></label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled': orderForm.value.locationInformation.at(i).openings
              }" style="width: 100%" type="number" min="0" id="inputtext" pInputText formControlName="openings" />
            <label for="inputtext">Openings</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).maximumApplicants
              }" style="width: 100%" type="number" min="0" id="inputtext" pInputText formControlName="maximumApplicants" />
            <label for="inputtext">Maximum Applicants</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).maleDiversity
              }" style="width: 100%" type="number" min="0" id="inputtext" pInputText formControlName="maleDiversity" />
            <label for="inputtext">Diversity (Male)</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).femaleDiversity
              }" maleDiversity style="width: 100%" type="number" min="0" id="inputtext" pInputText
              formControlName="femaleDiversity" />
            <label for="inputtext">Diversity (Female)</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).otherDiversity
              }" femaleDiversity style="width: 100%" type="number" min="0" id="inputtext" pInputText
              formControlName="otherDiversity" />
            <label for="inputtext">Diversity (Other)</label>
          </span>
        </div>
      </div>
      <p (click)="addItem()" (keydown.enter)="addItem()" class="add-heading cursor-pointer">
        <em class="pi pi-plus"></em>Add
        <span *ngIf="formData.controls.length">another</span> Location
      </p>
    </div>
  </form>
  <p-toast></p-toast>
</div>