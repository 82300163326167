import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { EventServicesService } from 'src/app/services/event-services.service';
import * as codes from '../../../assets/Json/collegeList.json';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { OtpService } from 'src/app/services/otp.service';
import { environment } from 'src/environments/environment';
import { catchError, EMPTY, EmptyError, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

interface Dependant {
  dependantOn: string;
  dependantValues: any[];
}

interface FormModel {
  readOnly: boolean;
  fieldId: string;
  fieldValue: string;
  fieldOrder: number;
  fieldName: string;
  fieldDescription: string;
  isMandatory: boolean;
  formName: string;
  displaySection: string;
  placeHolder: string;
  fieldType: string;
  fieldOptions: any[];
  fileProperities: FieldProperties;
  answer: any[];
  isValid: boolean;
  visible: boolean;
  groupName?: string;
  disabled?: boolean;
  dependant?: {
    dependantOn: string;
    dependantValues: any[];
  };
}

export interface FieldOptions {
  label: string,
  value: any
}

interface Section {
  section_id: number;
  section_label: string;
  section_form_fields: Field[];
}
export interface FieldProperties {
  file_format?: string[];
  file_max_size?: string;
}

interface Field {
  id: number;
  name: string;
  type: string;
  label: string;
  description: string;
  order_no: number;
  is_required: boolean;
  placeholder: string | null;
  element_type: 'field' | 'group';
  options?: any[];
  form_fields?: Field[];
  properties?: FieldProperties;
}

interface JobRole {
  job_role_id: string;
  job_role_name: string;
  preferred_location: string[];
}

interface RoleOption {
  label: string;
  value: string;
}

interface LocationOption {
  label: string;
  value: string;
}
@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
  providers: [MessageService]
})
export class RegistrationFormComponent implements OnInit {

  templateModels: Array<FormModel> = [];
  templateCollections: Array<Array<FormModel>> = [];
  invalidField: any;
  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;

  pageNumber: number = 1;
  otpTimer: number = 0;

  clicked: boolean = false;
  isFormValid: boolean = false;
  submitBtnLoading = false;
  submitted: boolean = false;
  submittedSuccessfully: boolean = false;
  isLoading: boolean = true;
  emailExists: boolean = false;
  mobileExists: boolean = false;
  inValidLocation: boolean = false;
  formExpired: boolean = false;
  belowAge: boolean = false;
  existingFormSubmit: boolean = false;
  verifyLoading: boolean = false;
  enableVerify: boolean = false;
  duplicateData: any = {};

  countdown: string = '';
  formName: string = '';
  otpValue: string = '';
  verifyChoice: string = '';
  eventId: any;
  eventTitle: any;
  sourceTag: any;
  userOtpData: any;
  // otpResponse: any;
  mobileOtp: any;
  intervalId: any;
  mobileNumber: any;
  countryCode: any;

  registrationStartDate: string = '';
  registrationEndDate: string = '';
  collegeList: any = (codes as any).default;

  schoolCode: string = '';
  companyLogo: string = '';
  inviteId: string = '';

  inEditMode: boolean = false;
  ansChange: any = null;
  jobUserMappingId: string = '';
  invalidUrl: boolean = false;
  offerFormExpired: boolean = false;
  formId: string = '';
  formType: string = '';

  offerFormSuccess: boolean = false;
  offerFormWarning: boolean = false;
  hideForm: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  verifyByEmail: boolean = false;
  emailToVerify: string = '';
  mobileToVerify: string = '';
  p1EmailVerified: boolean = false;
  p1MobileVerified: boolean = false;
  otpSent : boolean = false;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  enableMobile: boolean = false;
  formData: any;
  formLogo: any;
  formCoverImage: any;
  emailValue: any;
  emailConfirmValue: any;
  companyId: any;
  registrationNotStarted: boolean = false;
  candidateEmail: string = '';
  candidateUuid: string = '';
  headerContent: string = '';
  footerContent: string = '';
  fieldRemoved: any = [];
  internalError: boolean = false;
  jobRoles: RoleOption[] = [];
  preferredLocations: LocationOption[] = [];
  instituteList: any;
  certificateList: any;
  languagesList: any;
  skillsList: any;
  sscBoardsList: any;
  intermediateBoardsList: any;
  entranceTestsList: any;
  previousFormDetails:{ [key: string]: any } = {};
  documentationError: boolean = false;
  documentationErrorMsg: string = '';
  stageId: string = '';
  
  constructor(
      private formBuilderServices: FormBuilderService, 
      private messageServices: MessageService, 
      private activatedRoute: ActivatedRoute, 
      private eventService: EventServicesService, 
      private globalService: GlobalServicesService,
      private otpService: OtpService,
      private route: ActivatedRoute,
      private changeDetectorRef: ChangeDetectorRef
    ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.formType = params.get('form_type') ? params.get('form_type')!.toLowerCase() : '';
      if (this.formType !== 'registration' && this.formType !== 'documentation' && this.formType !== 'offer') {
        this.invalidUrl = true;
        this.messageServices.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Invalid URL'
        });
      }
      if (this.formType === 'registration'){
        this.formName = "Registration Form";
      } else if (this.formType === 'documentation'){
        this.formName = 'Extended Details and Documentation Form';
      }
    });
    this.globalService.metadataFastApi().pipe(
      switchMap((response: any) => {
        if (response.body?.company_details) {
          this.companyId = response.body.company_details.uuid;
          localStorage.setItem('companyUUID', response.body.company_details.uuid);
          return this.activatedRoute.queryParamMap;
        }
        return of(null);
      }),
    ).subscribe({
      next: (param) => {
        if (param) {
          this.eventId = param.get('eventId') || '';
          this.formId = param.get('formId') || '';
          this.candidateUuid = param.get('candidateId') || '';
          this.verifyByEmail = true;   
          this.formBuilderServices.getEventSpecificFormDetailsFastApi(this.eventId)
          .pipe(
            switchMap((roleResponse: any) => {
              if (roleResponse?.body?.roles) {
                const roles: JobRole[] = roleResponse.body.roles || [];
                const uniqueLocations = new Set<string>();
                this.preferredLocations = [];
                this.jobRoles = [];
                
                roles.forEach(role => {
                  role.preferred_location.forEach(location => {
                    uniqueLocations.add(location);
                  });
                  this.jobRoles.push({
                    label: role.job_role_name,
                    value: role.job_role_id
                  });
                });
                this.preferredLocations = Array.from(uniqueLocations).map(location => ({
                  label: location,
                  value: location
                }));
              }
              this.instituteList = roleResponse?.body?.institutes || [];
              this.certificateList = roleResponse?.body?.certifications || [];
              this.languagesList = roleResponse?.body?.languages || [];
              this.skillsList = roleResponse?.body?.skills || [];
              this.sscBoardsList = roleResponse?.body?.ssc_boards || [];
              this.intermediateBoardsList = roleResponse?.body?.intermediate_boards || [];
              this.entranceTestsList = roleResponse?.body?.entrance_tests || [];
              
              if (this.candidateUuid) {
                return this.formBuilderServices.validateCandidateFastApi(this.eventId, this.candidateUuid, this.formType === 'documentation' ? this.formId : null)
                .pipe(
                  catchError((error: HttpErrorResponse) => {
                    if (error.status === 400) {
                      switch(error.error?.detail) {
                        case "Document submission deadline has expired":
                          this.documentationError = true;
                          this.documentationErrorMsg = error.error.detail
                          break;
                        case "Candidate has already submitted the document":
                          this.documentationError = true;
                          this.documentationErrorMsg = error.error.detail
                          break;
                        default:
                          this.invalidUrl = true;
                          break;
                      }
                    }
                    else{
                      this.messageServices.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Failed to validate candidate, Check the URL'
                    });
                    this.invalidUrl = true;
                  }
                    this.isLoading = false;
                    return EMPTY;
                  })
                );
              }
              return of(null);
            }),
            switchMap((candidateResponse: any) => {
              if (candidateResponse?.body?.candidate_email) {
                this.candidateEmail = candidateResponse.body.candidate_email;
                this.emailValue = this.candidateEmail;
              }
              if (candidateResponse?.body?.candidate_email && this.formType === 'documentation') {
                this.candidateEmail = candidateResponse.body.candidate_email;
                this.stageId = candidateResponse.body.event_stage_id;
                this.otpRequired = true;
                this.otpVerified = false;
                this.emailToVerify = this.emailValue = this.candidateEmail;
                return EMPTY;
              }
              return this.formBuilderServices.getEventFormDetailsFastApi(this.eventId, this.formId);
            })
          ).subscribe({
            next: (templateResponse: any) => {
              this.formData = templateResponse.body;
              this.formLogo = this.formData.logo;
              this.formCoverImage = this.formData.cover_image;
              this.headerContent = this.formData.header_content;
              this.footerContent = this.formData.footer_content;
              this.transformFormFields();
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.resetErrorFlags();
              
              if (error.status === 500) {
                this.invalidUrl = true;
                this.messageServices.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Something went wrong. Please try again later.'
                });
              } 
              else if (error.status === 404) {
                this.invalidUrl = true;
                this.messageServices.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'The selected event is invalid.'
                });
              }
              else if (error.status === 400) {
                switch(error.error?.detail) {
                  case "Registration was closed":
                    this.formExpired = true;
                    break;
                  case "Registration not started yet":
                    this.registrationNotStarted = true;
                    break;
                  default:
                    this.invalidUrl = true;
                }
              }
            }
          });
        }
      },
      error: (error) => {
        console.error('Error in initialization:', error);
        this.messageServices.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to initialize form'
        });
      }
    });
  }

  validateRegistration(): boolean {
    if (this.registrationStartDate && this.registrationStartDate.length && this.registrationEndDate && this.registrationEndDate.length) {
      return true;
    }
    else {
      return false;
    }
  }

  getTemplates = () => {
    this.isLoading = true;
    this.formBuilderServices.getEventFormDetailsFastApi(this.eventId, this.formId).subscribe({
      next: (res: any) => {
        console.log(res);
        this.formData = res.body;
        this.formLogo = this.formData.logo;
        this.formCoverImage = this.formData.cover_image;
        this.headerContent = this.formData.header_content;
        this.footerContent = this.formData.footer_content;
        this.transformFormFields();
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.resetErrorFlags();
        
        if (error.status === 500) {
          this.invalidUrl = true;
          this.messageServices.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later.'
          });
        } 
        else if (error.status === 404) {
          this.invalidUrl = true;
          this.messageServices.add({
            severity: 'error',
            summary: 'Error',
            detail: 'The selected event is invalid.'
          });
        }
        else if (error.status === 400) {
          switch(error.error?.detail) {
            case "Registration was closed":
              this.formExpired = true;
              break;
            case "Registration not started yet":
              this.registrationNotStarted = true;
              break;
            default:
              this.invalidUrl = true;
          }
        }
      }
    });
  }

  private resetErrorFlags(): void {
    this.formExpired = false;
    this.invalidUrl = false;
    this.registrationNotStarted = false;
  }
  
  transformFormFields(): void {
    try {
      this.templateCollections = [];
      
      if (!this.formData?.form_fields) return;
      
      this.fieldRemoved = []
      this.formData.form_fields.forEach((section: Section) => {
        const sectionFields: FormModel[] = [];
        
        section.section_form_fields?.forEach((field: Field) => {
          if (field.element_type === 'field') {
            sectionFields.push(this.createFieldModel(field, section, this.formData.name));
          } else if (field.element_type === 'group' && field.form_fields) {
            if (field.name === 'primary_number' || field.name === 'primary_number' || field.name === 'parent_number') {
              const filteredFields = field.form_fields.filter(f => f.name !== 'country_code');
              filteredFields.forEach((groupField: Field) => {
              sectionFields.push(this.createFieldModel(groupField, section, this.formData.name, field.name));
              this.fieldRemoved.push('country_code')
              });
            } else {
              field.form_fields.forEach((groupField: Field) => {
              sectionFields.push(this.createFieldModel(groupField, section, this.formData.name, field.name));
              });
            }
          }
        });
  
        if (sectionFields.length > 0) {
          this.templateCollections.push(sectionFields);
        }
      });
    } catch (error) {
      console.error('Error in transformFormFields:', error);
      this.messageServices.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to process form fields'
      });
    }
  }
  
  private createFieldModel(field: Field, section: Section, formName: string, groupName?: string): FormModel {
    const model: FormModel = {
      fieldId: field.id.toString(),
      fieldOrder: field.order_no,
      fieldName: field.label,
      fieldValue: field.name,
      fieldDescription: field.description,
      isMandatory: field.is_required,
      formName: formName,
      displaySection: section.section_label,
      placeHolder: field.placeholder || '',
      fieldType: field.type.toLowerCase(),
      fieldOptions: field.options || [],
      answer: [],
      isValid: !field.is_required,
      readOnly: false,
      visible: true,
      fileProperities: {
        file_format: field.properties?.file_format || [],
        file_max_size: field.properties?.file_max_size || '2MB'
      },
    };

    if (groupName) {
      model.groupName = groupName;
    }

    if (groupName === 'email' && field.label === 'Email ID' && this.candidateEmail) {
      model.answer = [this.candidateEmail];
      model.disabled = true;
      model.readOnly = true;
      model.isValid = true;
    }
    this.setFieldOptions(field, model);

    if (this.previousFormDetails.hasOwnProperty(model.fieldValue) && this.previousFormDetails[model.fieldValue]) {
      if (model.fieldType === 'date picker' && this.previousFormDetails[model.fieldValue]) {
        const dateValue = new Date(this.previousFormDetails[model.fieldValue]);
        const formattedDate = `${('0' + (dateValue.getMonth() + 1)).slice(-2)}-${('0' + dateValue.getDate()).slice(-2)}-${dateValue.getFullYear()}`;
        model.answer = [formattedDate]
        model.placeHolder = formattedDate
      } else if (model.fieldType === 'dropdown' || model.fieldType === 'checkbox') {
        const selectedOption = model.fieldOptions.find(option => option.value === this.previousFormDetails[model.fieldValue]);
        if (selectedOption) {
          model.answer = [selectedOption.value];
          model.placeHolder = selectedOption.label;
        } else {
          model.answer = [];
          model.placeHolder = '';
        }
      } else {
        model.answer = Array.isArray(this.previousFormDetails[model.fieldValue]) 
          ? this.previousFormDetails[model.fieldValue] 
          : [this.previousFormDetails[model.fieldValue]];
        model.placeHolder = model.answer[0]
      }
      
      if (model.isMandatory && model.answer?.length) {
        model.isValid = true;
      }
    }
    
    return model;
  }

  private mapFieldOptions(fieldName: string, list: any[], labelKey: any = [], valueKey: string): any[] {
    return list.map(item => ({
      label: labelKey.map((key: string | number) => item[key]).join(', '),
      value: item[valueKey]
    }));
  }

  private setFieldOptions(field: Field, model: FormModel) {
    switch (field.name) {
      case 'preferred_role':
        model.fieldOptions = this.jobRoles;
        break;
      case 'preferred_location_1':
      case 'preferred_location_2':
        model.fieldOptions = this.preferredLocations;
        break;
      case 'pg_college_name':
      case 'ug_college_name':
      case 'diploma_college_name':
        model.fieldOptions = this.mapFieldOptions(field.name, this.instituteList, ['name', 'city'], 'name');
        break;
      case 'certification_1':
        model.fieldOptions = this.mapFieldOptions(field.name, this.certificateList, ['name'], 'name');
        break;
      case 'known_languages':
        model.fieldOptions = this.mapFieldOptions(field.name, this.languagesList, ['name'], 'name');
        break;
      case 'skill_list':
        model.fieldOptions = this.mapFieldOptions(field.name, this.skillsList, ['name'], 'name');
        break;
      case 'class10_board_name':
        model.fieldOptions = this.mapFieldOptions(field.name, this.sscBoardsList, ['name'], 'name');
        break;
      case 'class12_board_name':
        model.fieldOptions = this.mapFieldOptions(field.name, this.intermediateBoardsList, ['name'], 'name');
        break;
      case 'entrance_test':
        model.fieldOptions = this.mapFieldOptions(field.name, this.entranceTestsList, ['name'], 'name');
        break;
    }
  }

  bindData(event: any, model: FormModel) {
    model.answer = [];
    switch (model.fieldType) {
      case 'checkbox': model.answer = event[event.length - 1]; break;
      case 'email': {
        (model.answer = event)
        if (model.fieldName == 'Email ID') {
          if(!this.candidateEmail){
            model.isValid = true;
            this.emailValue = event;
          }
        }
        if(model.fieldName == 'Confirm email ID'){ 
          model.isValid = true;
          this.emailConfirmValue = event;
        }
        if(this.emailConfirmValue === this.emailValue){
          this.duplicateData.emailDuplicate = true;
        } else {
          this.duplicateData.emailDuplicate = false;
        }
        
      }; break;
      case 'multiinput': model.answer.push(model.fieldOptions?.map((option: FieldOptions) => option.value).join(' ')); break;
      case 'mobile': model.answer = [`${event[0]}-${event[1]}`]; break;
      case 'dropdown': {
        if (model.fieldName == 'Highest Degree - Year of Passing' && event != 'Prior to 2020') {
            model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'input': {
        if (model.fieldName == 'Aadhar Card Number:') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      default: model.answer.push(event);
    }
  }

  enableEmailVerification(event: any) {
    this.verifyChoice = '';
    this.emailToVerify = event.email;
    this.sendOtpByEmail();
    this.verifyChoice = 'email';
  }
  enableMobileVerification(event: any) {
    this.verifyChoice = '';
    this.mobileToVerify = event.mobile;
    this.countryCode = event.countryCode;
    this.mobileNumber = event.number;
    this.verifyChoice = 'mobile';
    this.sendOtpByMobile();
  }

  sendOtpByEmail = () => {
    // this.btnLoading = true;
    const payload = {
      "email" : this.emailToVerify,
    }
    this.otpService.sendCandidateOtpFastApi(payload, this.eventId).subscribe(res => {
      console.log(res)
      if(res.body.detail == 'OTP sent successfully.'){
        // this.otpResponse = res.data;
        this.otpSent = true;
        this.loadResend = false; 
        this.waitToResend = true;
        this.startOtpTimer();
      } else {
        this.otpSent = false;
        this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong!' });
      }
    })
  }
  sendOtpByMobile = () => {
    // this.btnLoading = true;
    let payload = {
      "mobileNumber" : this.mobileToVerify,
    }
    this.otpService.sendOtpByMobile(payload,'registrationForm').subscribe(res => {
      if(res.data){
        this.mobileOtp = res.data;
        this.otpSent = true;
        this.loadResend = false; 
        this.waitToResend = true;
        this.startMobileOtpTimer();
      } else {
        this.otpSent = false;
        this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong!' });
      }
    })
  }

  verifyEmailOtp(){
      this.verifyLoading = true;
      if (this.otpValue.toString().length == 6 && this.enableVerify){
        let payload = { email: this.emailToVerify, otp: this.otpValue };
        this.otpService.verifyCandidateOtpFastApi(payload, this.eventId).subscribe({
          next:(res) => {
            if(res.status == 200){
              this.verifyLoading = false;
              if (res.body.access_token) {
                let accessToken = {
                  access_token: res.body.access_token,
                  token_type: res.body.token_type
                }
                localStorage.setItem('token', JSON.stringify(accessToken));
                this.messageServices.add({ severity: 'success', summary: 'Verified', detail: res.body.detail});
                this.p1EmailVerified = true;
                this.dialogClose();
                if (res?.body?.previous_form_details) {
                  this.previousFormDetails = res.body.previous_form_details;
                  this.transformFormFields()
                }
              } else {
                this.messageServices.add({ severity: 'error', summary: 'Expired', detail: res.body.detail });
              }
            }
          },
          error: (error) => {
            this.verifyLoading = false;
            this.otpValue = '';
            this.p1EmailVerified = false;
            this.messageServices.add({ severity: 'error', summary: 'Error', detail: error.body.detail });
          }
        });
      }
  }

  verifyCheck(event:any){
    if(this.otpValue.length == 6 && !isNaN(Number(event))){
      this.enableVerify = true;
    }else{
      this.enableVerify = false;
    }
  }

  dialogClose(){
    // this.otpResponse = {};
    this.mobileOtp = {};
    this.otpSent = false;
    this.enableVerify = false;
    this.verifyLoading = false;
    this.waitToResend = false;
    this.otpValue = '';
    this.otpTimer = 0;
    clearInterval(this.intervalId);
  }

  verifyMobileOtp(){
      this.verifyLoading = true;
      if (this.otpValue.toString().length == 6 && this.enableVerify){
        let payload = { mobileNumber : this.mobileToVerify, otp: Number(this.otpValue) };
        this.otpService.verifyOtpByMobile(payload,'registrationForm').subscribe(res => {
          if(res.data){
            this.verifyLoading = false;
            switch (res.data) {
              case 'success': {
                  this.messageServices.add({ severity: 'success', summary: 'Verified', detail: 'Mobile Number is Successfully Verified' });
                  this.p1MobileVerified = true;
                  this.dialogClose();
              } break;
              case 'failed': {
                this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Please enter the valid OTP!' });
                // this.invalidOTP = true;
              } break;
              case 'expired': {
                this.messageServices.add({ severity: 'error', summary: 'Expired', detail: 'Your OTP is Expired!' });
                // this.expiredOTP = true;
              }
            }
          }
          else{
            this.otpValue = '';
            this.enableVerify = false;
            this.verifyLoading = false;
            this.p1MobileVerified = false;
            this.messageServices.add({ severity: 'error', summary: 'Expired', detail: 'Something Went Wrong!' });
          }
        })
      }
  }

  resendEmailOtp = () => {
    this.loadResend = true; 
    this.sendOtpByEmail();
  }
  resendMobileOtp = () => {
    this.loadResend = true; 
    this.sendOtpByMobile();
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

    this.intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(this.intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }
  startMobileOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

      this.intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(this.intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  verifyOnSubmit() {
    if(this.formName === 'Registration Form' || this.formName === ' Registration Form' || this.formName === 'Registration Form Non-Technical'){
      if(this.enableMobile){
        if(this.p1EmailVerified && this.p1MobileVerified){
          this.submit();
        }else{
          if(!this.p1EmailVerified){
            this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Please verify your email address' });
          }else if(!this.p1MobileVerified){
            this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Please verify your mobile number.' });
          }
        }  
      }else{
        if(this.p1EmailVerified){
          this.submit();
        }else{
          this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Please verify your email address' });
        }
      }
    } else {
      this.submit();
    }
  }

  submit() {
    this.clicked = true;
    let templateModels = this.templateCollections.flat(1).filter((eachModel: FormModel) => eachModel.visible);
    const validArray = templateModels.map((model: FormModel) => model.isValid);
    this.isFormValid = !validArray.some(valid => valid === false || valid === undefined);
  
    if (this.isFormValid) {
      this.submitBtnLoading = true;
      
      let submitData = templateModels.reduce((acc: any, field: FormModel) => {
        acc[field.fieldValue] = Array.isArray(field.answer) ? field.answer[0] ?? null : field.answer ?? null;;
        return acc;
      }, {});

      if (this.fieldRemoved.length > 0){
        this.fieldRemoved.forEach((field: string) => {
          const mobileNumber = submitData["mobile_number"] || submitData["parents_mobile_number"];
          const selectedField = submitData["mobile_number"] ? "mobile_number" : "parents_mobile_number";        
          let fieldValues;
          if (mobileNumber.includes('-')) {
            fieldValues = mobileNumber.split('-');
            submitData = {
              ...submitData,
              [field]: fieldValues[0],
              [selectedField]: fieldValues[1]
            };
          } else {
            fieldValues = ['91', mobileNumber];
            submitData = {
              ...submitData,
              [field]: fieldValues[0]
            };
          }
        });
      }
      const payload = {
        ...submitData,
      };

      if (this.formType === 'registration') {
        this.formBuilderServices.submitRegistrationFormFastApi(payload, this.eventId)
          .subscribe({
            next: (response) => {
              this.submittedSuccessfully = true;
              this.submitBtnLoading = false;
            },
            error: (error) => {
              this.submitBtnLoading = false;
              this.messageServices.add({
                severity: 'error',
                summary: 'Error',
                detail: error.error.detail
              });
            }
          });
      } else {
        this.formBuilderServices.submitDocumentationFormFastApi(payload, this.stageId)
          .subscribe({
            next: (response) => {
              this.submittedSuccessfully = true;
              this.submitBtnLoading = false;
            },
            error: (error) => {
              this.submitBtnLoading = false;
              this.messageServices.add({
                severity: 'error',
                summary: 'Error',
                detail: error.error.detail
              });
            }
          });
      }
    }
  }

  checkValid(event: any, model: FormModel) {
    model.isValid = event;

    // if (model.fieldName == "Preferred Location 2") {
    //   let templateModels = this.templateCollections.flat(1);
    //   let matchingField: any;
    //   templateModels.forEach((each: FormModel) => { if (each.fieldName == 'Preferred Location 1') matchingField = each })

    //   if (matchingField.answer[0] == model.answer) {
    //     model.isValid = false;
    //     this.inValidLocation = true;
    //   } else {
    //     model.isValid = true
    //     this.inValidLocation = false;
    //   };
    // }

    if(model.formName == 'Registration Form' || model.formName == ' Registration Form' || model.formName == 'Registration Form Non-Technical')
    {
      if(model.fieldName == "Primary Email Address" || model.fieldName == "Alternate Email Address"){
        this.templateModels.forEach((eachModel: FormModel) => {
          if (eachModel.fieldName == 'Alternate Email Address') {
            if( this.duplicateData.emailDuplicate != undefined && !this.duplicateData.emailDuplicate && eachModel.isValid){
              eachModel.isValid = true;
            } else {
              eachModel.isValid = true;
            }
          }
        })
      }
    }

    if (model.fieldName == "Date Of Birth") {
      let year = new Date().getFullYear();
      let dif = year - Number(model.answer[0].split('-')[2]);

      if (dif < 18) {
        model.isValid = false;
        this.belowAge = true;
      } else {
        model.isValid = true;
        this.belowAge = false;
      }
    }
  }

  closeWindow(action: string) {
    if (action === 'success') {
      window.close();
    }
    else {
      this.emailExists = false;
      this.mobileExists = false;
    }
  }

  checkForDependancy = (model: FormModel): boolean => {
    if (!model.dependant) return true;
  
    const templateModels = this.templateCollections.flat(1);
    const dependantField = templateModels.find(
      (eachModel: FormModel) => eachModel.fieldName === model.dependant?.dependantOn
    );
  
    if (dependantField && model.dependant.dependantValues.includes(dependantField.answer[0])) {
      model.visible = true;
      this.invalidField = {};
      return true;
    }
  
    model.visible = false;
    return false;
  }

  prefillForm()
  {
    if(this.otpVerified && this.formType === 'documentation')
    {
      this.getTemplates();
    }

  }
}