import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServicesService } from '../services/global-services.service';
import { LoginService } from '../services/login.service';
import { OtpService } from '../services/otp.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ForgetPasswordComponent implements OnInit {

  @ViewChild('resetLinkEmail') resetLinkEmail: any;
 
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  infoMessage: any = {};
  btnLoading: boolean = false;
  pageLoading: boolean = false;
  redirecting: boolean = false;
  sendResetLinkState: boolean = true;
  clicked: boolean = false;
  displayInfoMessage: boolean = false;
  resetPasswordState : boolean = false;
  showNewPassword : boolean = false;
  showConfirmPassword : boolean = false;
  passwordPattern: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

  otpTimer: number = 0;
  countdown: string = '';
  emailToVerify: string = '';
  reset_token: string = '';

  passwordForm: FormGroup;

  constructor(private otpService: OtpService,private fb: FormBuilder, private loginService: LoginService, private globalService: GlobalServicesService, private router: Router, private messageService: MessageService,private confirmationService: ConfirmationService, private activatedRoute: ActivatedRoute,) { 
    this.passwordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get('newPassword')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (newPassword === confirmPassword) {
      console.log("return null ")
      return null;
    } else {
      console.log("Mismatch Error")
      formGroup.get('confirmPassword')?.setErrors({ mismatch: true });
      return { mismatch: true };
    }
  }

  ngOnInit(): void {
    if (this.router.url.includes('reset-password')) {
      this.activatedRoute.queryParamMap.subscribe(async (params) => {
        this.reset_token = params.get('reset_token') || '';
      });
      this.resetPasswordState = true;
    }
  }

  sendResetLinkByEmail = () => {
      this.btnLoading = true;
      let payload = {
        email: this.emailToVerify.toLowerCase(),
      }
      this.otpService.sendForgotPasswordFastApi(payload).subscribe({
        next: (response) => {
          this.btnLoading = false;
          this.infoMessage = { icon: 'pi pi-check', message: response.body.detail, bgColor: '#55BA45' }
          this.showInfoMessage();
        },
        error: () => {
          this.btnLoading = false;
          this.displayInfoMessage = false;
        }
      });
  }
  showInfoMessage = () => {
    this.displayInfoMessage = true;
    setInterval(() => {
      this.displayInfoMessage = false;
      this.infoMessage = {};
    }, 10000);
  }

  showToggle(passwordInputClass: string, toggleButton: string)
  {
    let passwordInput: any = document.getElementsByClassName(passwordInputClass)[0];
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      toggleButton == 'newPassword' ? this.showNewPassword = true : this.showConfirmPassword = true;
    } else {
      passwordInput.type = "password";
      toggleButton == 'newPassword' ? this.showNewPassword = false : this.showConfirmPassword = false;
    }
  }

  confirm() {
    console.log(this.confirm)
    this.confirmationService.confirm({
        message: 'Are you sure you want to reset your password?',
        accept: () => {
            this.resetPassword()
        }
    });
  }

  resetPassword(){
    this.btnLoading = true;
    let payload = {
      password : this.passwordForm.get('newPassword')?.value,
      confirm_password : this.passwordForm.get('confirmPassword')?.value
    }
    this.loginService.resetPasswordFastApi(this.reset_token,payload).subscribe({
      next: (response) => {
        this.btnLoading = false;
        if(response.status == 200){
        this.redirectLogin();
        }
      },
      error: () => {
        this.btnLoading = false;
      }
    });
  }

  redirectLogin(){
    this.redirecting = true;
    setTimeout(() => {
      this.redirecting = false;
      this.router.navigateByUrl('/login')
    }, 5000)
  }

}
