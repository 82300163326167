import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private socket:any;
    private socket_url: string = environment.HOST.SOCKET_LINK;
    public messageRecieved = new BehaviorSubject<any>(null)
    public connectionId = null; 

    api_url = environment.HOST.API_URL;
    host_api_url = environment.HOST.HOST_API_URL;
    bulk_invite_url = environment.HOST.BULK_INVITE;

    options() {
        const schoolId = localStorage.getItem('schoolData')
          ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
          : '';
        const jobId = window.localStorage.getItem('selectedJobId') || '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Origin: window.location.origin,
          Authorization: localStorage.getItem('token') ?? '', 
          schoolid: schoolId,
          jobId: jobId,
        });
        return { headers };
      }

   
    constructor(private http: HttpClient){
    }

    /* Socket connection */
    public connect(school_id: string , user_id: string){
        this.socket =  new WebSocketSubject({
            url: this.socket_url,
            closeObserver: {
                next: () => {
                    this.connect(school_id, user_id)
                }
            }
        });
        this.socket.next({
            action: 'userconnect',
            data: {
                school_id,
                user_id
            }
        });
        this.socket.subscribe(
            (message: any) => { 
                if (message.connectionId) {
                    this.connectionId = message.connectionId;
                }
                this.messageRecieved.next(message);
            },
            (errored: any) => {},
        );

    }
    
    sendMessageWS(payload:any): Observable<any> {
          return this.http.post(this.api_url + '/interviewLobby/sendMessageWS/', payload, this.options())
      }


    
}
