import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { SharedModule } from '../shared/shared.module';

// Components
import { JobDetailsComponent } from './job-details/job-details.component';
import { CandidateListComponent } from './candidate-list/candidate-list.component';


@NgModule({
  declarations: [
    CandidateListComponent,
    JobDetailsComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RoundProgressModule,
  ],
})
export class JobModule { }
