import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.css'],
  providers: [ConfirmationService],
})
export class FormSettingsComponent implements OnInit {
  isLoading: boolean = false;
  formList: any = [];
  constructor(private breadcrumbService: NgDynamicBreadcrumbService,private _router: Router,private confirmationService: ConfirmationService,) { }

  ngOnInit() {
    this.formList = [
      {title: 'Registration'},
      {title: 'Feedback'},
      {title: 'Documentation'},
    ]
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    const breadcrumbs = [
      { label: 'Form Builder', url: '' }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }
  redirectForm(title: any){
    this._router.navigate(['form-settings/', `${title} Form`])
  }

}
