import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from './../../services/settings.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { SharedServicesService } from 'src/app/services/shared-services.service';
import { Message, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid'
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css'], 
  providers: [SharedServicesService, MessageService]
})

export class CompanyDetailsComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  companyData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  saveDisable: boolean = false;
  profilePic: File[] = [];
  profileBase64: any = '';
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  defaultLogo: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-logo.png`
  logoSrc: any = '';
  logoSrcEdit: any = '';
  companyDetails: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    primary_language: new FormControl('', Validators.required),
    company_category: new FormControl('', Validators.required),
    pan: new FormControl('', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]),
    gst: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)]),
    tin: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{11}$/)]),
    companyLogo: new FormControl('', Validators.required),
    default_currency: new FormControl('', Validators.required),
    default_timezone: new FormControl('', Validators.required),
    sampleValues: new FormGroup({
      pan: new FormControl('ABCDE1234F'),
      gst: new FormControl('22AAAAA0000A1Z5'),
      tin: new FormControl('12345678901')
    })
  });

  companyName: string = '';
  industry: string = '';
  panNumber: string = '';
  gstNumber: string = '';
  tinNumber: string = '';
  defaultCurrency: string = '';
  defaultTimezone: string = '';
  primaryLanguage: string = '';
  companyCategory: string = '';
  panNumberShow: boolean = false;
  gstNumberShow: boolean = false;
  tinNumberShow: boolean = false;
  clicked: boolean = false;

  schoolId: string ='';

  industryList: any = [
    {label : "Research"},
    {label : "Development"},
    {label : "Core"}
  ];
  primaryLanguageList: any = [
    {label : "English"},
  ]
  
  companyCategoryList: any = [ "Public Limited", "Corporate" ];

  constructor(private settings: SettingsService, private fileUploadService: FileUploadService, private sharedService: SharedServicesService, private messageService: MessageService, private globalService:GlobalServicesService) { }

  ngOnInit(): void {
    this.logoSrc = this.logoSrcEdit = this.defaultLogo;
    this.schoolId = localStorage.getItem('schoolData')
    ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
    : '';
    this.getCompanyDetails();
  }

  getCompanyDetails()
  {
    this.settings.getCompanyDataFastApi().subscribe({
      next: (res: any) => {
        if(res.status === 200){
          this.companyData = res.body ? res.body : [];
          this.sharedService.emitChange(res.body?.logo);
          this.logoSrc = this.companyData.logo ?? this.defaultLogo;
          this.logoSrcEdit = this.companyData.logo ?? this.defaultLogo;
          this.userInfo = [
            { field: "Company", value: res.body.name ?? '-' },
            { field: "Industry", value: res.body.industry ?? '-' },
            { field: "Primary Language", value: res.body.primary_language ?? '-' },
            { field: "Company Category", value: res.body.company_category ?? '-' },
            { field: "No. of Branches", value: res.body.branches_count ?? '-' },
            { field: "PAN Number", value: res.body.pan ?? '-' },
            { field: "GST Number", value: res.body.gst ?? '-' },
            { field: "TIN Number", value: res.body.tin ?? '-' },
            { field: "Default Currency", value: res.body.default_currency ?? '-' },
            { field: "Default Timezone", value: res.body.default_timezone ?? '-' }
          ];

        }
      },
      error: (err) => {
        console.error('Error fetching company data:', err);
      }
    });    
  }

  openCompanyDetails()
  {
    this.addTemplateSidebar = true;
    console.log(this.companyData)
    this.companyName = this.companyData.name;
    this.industry = this.companyData.industry;
    this.primaryLanguage = this.companyData.primary_language;
    this.companyCategory = this.companyData.company_category;
    this.panNumber = this.companyData.pan;
    this.gstNumber = this.companyData.gst;
    this.tinNumber = this.companyData.tin;
    this.defaultCurrency = this.companyData.default_currency;
    this.defaultTimezone = this.companyData.default_timezone;
  }

  cancel = () => {
    this.companyDetails.reset();
    this.addTemplateSidebar = false;
  }

  saveCompanyDetails = () => {
    this.saveBtnLoading = true;
    this.clicked = true;
    const payload = this.companyDetails.value;
    console.log("🚀🔥 ~ file: company-details.component.ts:126 ~ CompanyDetailsComponent ~ payload:", payload)

    if(this.companyDetails.valid){
      this.settings.updateCompanyDataFastApi(payload).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            console.log(res);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.body.detail,
            });
          }
          this.saveBtnLoading = false;
        },
        error: (err) => {
          console.error('Error updating company data:', err);
          this.saveBtnLoading = false;
        }
      });
      
    } else{
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the Required Fields', })
      this.saveBtnLoading = false;
    }
  }

  onSelectLogo(event: any, action?: any) {
    this.profilePic.push(...event.addedFiles);
    this.handleUpload(event, action)
  }

  handleUpload(event: any, action?:any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    let width, height;
    reader.readAsDataURL(file);
    reader.onload = (e:any) => {
      let img:any = new Image();      
      img.src = e.target.result;
      const valid = (state:any) =>
      {
        if(state)
        {
          if(action === 'edit')
          {
            this.logoSrcEdit = reader.result ? reader.result : null;
            this.fileUpload(file);
          }
        }
      }
      img.onload = function () {
          width = this.width;
          height = this.height;
          if(width<=800 && height<=400)
          {
            valid(true)
          }
          else
          {
            valid(false)
          }
      }     
    };
  }

  onRemoveProfilePic(event: any) {
    this.profilePic.splice(this.profilePic.indexOf(event), 1);
  }

  deleteFile(action?:any)
{
  if(action === 'edit')
  {
    this.logoSrcEdit = null;
    this.companyDetails.controls['companyLogo'].patchValue("");
  }
}  

showPassword(selector: any) {
  let x:any = document.getElementsByClassName(selector)[0];
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
} 

fileUpload(file: any) {
  let fileName = file.name.split(".");
  let fileExtension = fileName.pop();
  fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
  const pay = {
    Bucket_name: this.bucketName,
    file_name: `general-settings/company-details/company-logo/${this.schoolId}/${fileName}`,
    type: file.type,
  };

  const blobData: any = file;
  this.saveDisable = true;
  this.fileUploadService.getSignedUrl(pay).subscribe(
    (url: any) => {
      if (url) {
        const json = url;
        this.fileUploadService.uploadUsingSignedUrl(
          json.data,
          blobData
        ).subscribe(
          (r: any) => {
            if (r && r.status === 200) {
              this.logoSrcEdit = `https://s3.amazonaws.com/${pay.Bucket_name}/${pay.file_name}`;
              this.companyDetails.controls['companyLogo'].patchValue(this.logoSrcEdit);
              this.saveDisable = false;
            }
          }
        );
      }
    }
  );
}

}

