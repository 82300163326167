import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-candidate-basic-info',
  templateUrl: './candidate-basic-info.component.html',
  styleUrls: ['./candidate-basic-info.component.css']
})
export class CandidateBasicInfoComponent implements OnInit, OnChanges {

  @Input() selectedUser: any;
  @Output() eventClicked = new EventEmitter<{ eventId: string, candidateId: string }>();
  @Output() switchToCandidateInfo = new EventEmitter<any>(); 
  
  personalInfo: Array<any> = [];
  educationQualification: Array<any> = [];
  experience: Array<any> = [];
  eventDetails: Array<{ field: string, details: Array<{ field: string, value: string }>, eventId: string }> = [];
  usersAllEvent: Array<any> = [];

  pdfSrc: string = '';
  signedProfileUrl: string = '';
  dateFormat: string = 'yyyy-MM-dd';

  ENV = environment.HOST.NODE_ENV;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
  }

  ngOnChanges(): void {
    if (!this.selectedUser) {
        return;
    }
    this.loadUserData();
    this.loadEventDetails();

    setTimeout(() => {
        this.cdr.detectChanges();
    }, 100);
  }

  private loadUserData() {
    this.personalInfo = [
      { field: "Gender", value: this.selectedUser?.gender ?? '-' },
      { field: "Date of Birth", value: this.selectedUser?.date_of_birth ? new Date(this.selectedUser.date_of_birth).toLocaleDateString() : '-' },
      { field: "Address", value: this.selectedUser?.currentAddress ?? '-' },
    ];

    this.educationQualification = [
      { field: "Degree", value: this.selectedUser?.degreeDetails?.[0]?.degree ?? '-' },
      { field: "Department", value: this.selectedUser?.degreeDetails?.[0]?.specialization ?? '-' },
      { field: "Batch", value: this.selectedUser?.graduationYear ?? '-' },
    ];

    this.signedProfileUrl = this.selectedUser?.profilePic ?? '';
    this.pdfSrc = this.selectedUser?.resume ?? '';

    this.loadEventDetails();
  }

  private loadEventDetails() {
    if (this.selectedUser?.events?.length) {
      this.eventDetails = this.selectedUser.events.map((event: any) => ({
        field: event.event_name,
        details: [
          { field: "Current Status", value: event.current_status ?? '-' },
          { field: "Stage Name", value: event.stage_name ?? '-' },
          { field: "Joined Date", value: event.joined_date ? new Date(event.joined_date).toLocaleDateString() : '-' },
        ],
        eventId: event.event_uuid
      }));
    }
  }

  onEventClick(eventId: string, candidateId: string) {
    localStorage.setItem('selectedEventId', eventId);
    this.eventClicked.emit({ eventId, candidateId });
    this.switchToCandidateInfo.emit(this.selectedUser);
  }
}
