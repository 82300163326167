<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="pageInfo">
    <div class="settings-header" style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
        <div style="display: flex;justify-content:space-between; align-items: center;">
            <em class="pi pi-calendar" style="font-size: 17px;"></em>
            <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Set Date and Time</p>
        </div>
        <div class="header-btn">
            <button pButton pRipple type="submit" label="Update Changes"
                (click)="dateTimeUpdate()" class="p-button-raised p-button-text button-align"
                [loading]="saveBtnLoading"></button>
        </div>
    </div>
</div>
<div class="info-card">
    <div [style]="{display: 'block', flexWrap: 'wrap'}" [formGroup]="dateTimeDetails">
            <div class="info-element">
                <span class="info-line"></span>
                <div [style]="{display: 'flex', flexDirection: 'column'} ">
                    <p [style]="{color: 'var(--fc-light-dark)'}">Date Format</p>
                    <div class="form-time timeValue-elements">
                        <span class="p-input-icon-right p-float-label timeValue-elements" (click)="dateOverlay.toggle($event)">
                          <input [ngClass]="{ 'p-filled': dateValue, 'invalid': clicked && dateTimeDetails.controls['dateValue'].errors }" style="width: 100%" type="text" pInputText
                            inputId="dropdown" formControlName="dateValue" [(ngModel)]="dateValue" readonly
                             [style]="{ cursor: 'pointer' }" />
                          <em class="pi pi-angle-down dropdown"></em>
                          <label for="dropdown"  [ngStyle]="{ 'color' : (clicked && dateTimeDetails.controls['dateValue'].errors ? '#e24c4c' : '#666666')}">Date
                            <span [style]="{ color: '#E24A47'}" > *</span></label>
                          <p-overlayPanel #dateOverlay>
                            <ng-template pTemplate>
                              <div *ngFor="let field of dateList">
                                <p (click)="dateValue = field.name; dateOverlay.hide()" class="time-options">
                                  {{ field.name }}
                                </p>
                              </div>
                            </ng-template>
                          </p-overlayPanel>
                        </span>
                      </div>
                </div>
            </div>
            <div class="info-element">
                <span class="info-line"></span>
                <div [style]="{display: 'flex', flexDirection: 'column'} ">
                    <p [style]="{color: 'var(--fc-light-dark)'}">Time Format</p>
                    <div class="form-time timeValue-elements">
                        <span class="p-input-icon-right p-float-label timeValue-elements" (click)="timeOverlay.toggle($event)">
                          <input [ngClass]="{ 'p-filled': timeValue, 'invalid': clicked && dateTimeDetails.controls['timeValue'].errors }" 
                          style="width: 100%" type="text" pInputText inputId="dropdown" formControlName="timeValue" [(ngModel)]="timeValue"
                             [style]="{ cursor: 'pointer' }" readonly/>
                          <em class="pi pi-angle-down dropdown"></em>
                          <label for="dropdown" [ngStyle]="{ 'color' : (clicked && dateTimeDetails.controls['timeValue'].errors ? '#e24c4c' : '#666666') }">Time
                            <span [style]="{ color: '#E24A47' }"> *</span></label>
                          <p-overlayPanel #timeOverlay>
                            <ng-template pTemplate>
                              <div *ngFor="let field of timeList">
                                <p (click)="timeChange(field); this.timeOverlay.hide();" class="time-options">
                                  {{ field.name }}
                                </p>
                              </div>
                            </ng-template>
                          </p-overlayPanel>
                        </span>
                      </div>
                </div>
            </div>
    </div>
</div>