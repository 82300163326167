import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.css']
})
export class AccessManagementComponent implements OnInit {

  @ViewChild('recruiter') recruiter!: ElementRef;
  selectedRecruiters: Array<any> = [];
  recruiters: Array<any> = []
  availableRecruiters: Array<any> = [];
  _availableRecruiters: Array<any> = [];

  selectedHiringManagers: Array<any> = [];
  hiringManagers: Array<any> = []
  availableHiringManagers: Array<any> = [];
  _availableHiringManagers: Array<any> = [];

  selectedInterviewers: Array<any> = [];
  interviewers: Array<any> = []
  availableInterviewers: Array<any> = [];
  _availableInterviewers: Array<any> = [];

  selectedTpo: Array<any> = [];
  tpo: Array<any> = []
  availableTpo: Array<any> = [];
  _availableTpo: Array<any> = [];

  panels: Array<any> = [];
  panelMembers: Array<any> = [];

  isLoading: boolean = false;
  readMore: boolean = false;
  editJobSlider: boolean = false;
  isLoadingRecruiter: boolean = false;
  isLoadingInterviewer: boolean = false;
  isLoadingManager: boolean = false;
  isLoadingTpo: boolean = false;
  showPanelAdd: boolean = true;
  addNewMember: boolean = false;
  isInEvent: boolean = false;

  descHeight: number = 0;

  recruiterSearchTerm: string = '';
  interviewerSearchTerm: string = '';
  hiringManagerSearchTerm: string = '';
  tpoSearchTerm: string = '';
  jobId: string = '';
  eventId: string = '';
  eventJobType: string = '';

  allMembers: any = [];
  selectedPanelMembers: string[] = [];
  userRole: string = '';
  userPrivileges: any;

  constructor(private jobService: JobServicesService, private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    this.jobId = window.localStorage.getItem('selectedJobId') || '';
    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.isInEvent = this.jobId === this.eventId;
    this.eventJobType = this.isInEvent ? 'event' : 'jobs';
    this.getRecruiters();
    this.getInterviewers();
    this.userRole = this.globalService.privilegeRoles;
    this.userPrivileges = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
  }

  getRecruiters = () => {
    this.jobService.getAllAccessMembers('recruiter').subscribe(res => {
      let data = res.data.items;
      this.recruiters = data.map((item: any) => {
        return { id: item.id, name: item.name, email: item.email, badge: item.name.split(" ").map((n: string, index: number) => { if (index == 0 || index == item.name.split(" ").length - 1) return n[0]; else return '' }).join("") }
      });
      this._availableRecruiters = JSON.parse(JSON.stringify(this.recruiters))
      this.jobService.getJobEvent(this.eventJobType, this.jobId, this.isInEvent).subscribe(res => {
        this.getHiringManagers();
        this.isLoadingRecruiter = false;
        this.selectedRecruiters = [];
        if (res.data.accessManagement?.recruiter) {
          let selectedRecruitersId = res.data.accessManagement.recruiter;
          selectedRecruitersId.forEach((eachSelected: any) => {
            this.recruiters.forEach((eachRecruiter: any) => {
              if (eachSelected === eachRecruiter.id) {
                this.selectedRecruiters.push(eachRecruiter);
                this._availableRecruiters.forEach((eachAvailableRecruiters: any, index) => {
                  if (eachAvailableRecruiters.id === eachRecruiter.id) {
                    this._availableRecruiters.splice(index, 1);
                  }
                })
              }
            })
          })
        }
        this.availableRecruiters = JSON.parse(JSON.stringify(this._availableRecruiters));
      })
    })
  }

  getInterviewers = () => {
    this.jobService.getAllAccessMembers('interviewer').subscribe(res => {
      let data = res.data.items;
      this.interviewers = data.map((item: any) => {
        return { id: item.id, name: item.name, email: item.email, badge: item.name.split(" ").map((n: string, index: number) => { if (index == 0 || index == item.name.split(" ").length - 1) return n[0]; else return '' }).join("") }
      });
      this._availableInterviewers = JSON.parse(JSON.stringify(this.interviewers))
      this.jobService.getJobEvent(this.eventJobType, this.jobId, this.isInEvent).subscribe(res => {
        this.isLoadingInterviewer = false;
        this.selectedInterviewers = [];
        if (res.data.accessManagement?.interviewer) {
          let selectedInterviewersID = res.data.accessManagement.interviewer;
          selectedInterviewersID.forEach((eachSelected: any) => {
            this.interviewers.forEach((eachInterviewer: any) => {
              if (eachSelected === eachInterviewer.id) {
                this.selectedInterviewers.push(eachInterviewer);
                this._availableInterviewers.forEach((eachAvailableInterviewer: any, index) => {
                  if (eachAvailableInterviewer.id === eachInterviewer.id) {
                    this._availableInterviewers.splice(index, 1);
                  }
                })
              }
            })
          })
        }
        this.availableInterviewers = JSON.parse(JSON.stringify(this._availableInterviewers));
      })
    })
  }

  getHiringManagers = () => {
    this.jobService.getAllAccessMembers('evaluator').subscribe(res => {
      let data = res.data.items;
      this.hiringManagers = data.map((item: any) => {
        return { id: item.id, name: item.name, email: item.email, badge: item.name.split(" ").map((n: string, index: number) => { if (index == 0 || index == item.name.split(" ").length - 1) return n[0]; else return '' }).join("") }
      });
      this._availableHiringManagers = JSON.parse(JSON.stringify(this.hiringManagers))
      this.jobService.getJobEvent(this.eventJobType, this.jobId, this.isInEvent).subscribe(res => {
        this.getTpo();
        this.isLoadingManager = false;
        this.selectedHiringManagers = [];
        if (res.data.accessManagement?.hiringManager) {

          let selectedhiringManagersID = res.data.accessManagement.hiringManager;
          selectedhiringManagersID.forEach((eachSelected: any) => {
            this.hiringManagers.forEach((eachManager: any) => {
              if (eachSelected === eachManager.id) {
                this.selectedHiringManagers.push(eachManager);
                this._availableHiringManagers.forEach((eachAvailableManager: any, index) => {
                  if (eachAvailableManager.id === eachManager.id) {
                    this._availableHiringManagers.splice(index, 1);
                  }
                })
              }
            })
          })
        }
        this.availableHiringManagers = JSON.parse(JSON.stringify(this._availableHiringManagers));
      })
    })
  }

  getTpo = () => {
    this.jobService.getAllAccessMembers('limitedRecruiter').subscribe(res => {
      let data = res.data.items;
      this.tpo = data.map((item: any) => {
        return { id: item.id, name: item.name, email: item.email, badge: item.name.split(" ").map((n: string, index: number) => { if (index == 0 || index == item.name.split(" ").length - 1) return n[0]; else return '' }).join("") }
      });
      this._availableTpo = JSON.parse(JSON.stringify(this.tpo))
      this.jobService.getJobEvent(this.eventJobType, this.jobId, this.isInEvent).subscribe(res => {
        this.getPanelMembers();
        this.isLoadingTpo = false;
        this.selectedTpo = [];
        if (res.data.accessManagement?.limitedRecruiter) {
          let selectedTpoID = res.data.accessManagement.limitedRecruiter;
          selectedTpoID.forEach((eachSelected: any) => {
            this.tpo.forEach((tpo: any) => {
              if (eachSelected === tpo.id) {
                this.selectedTpo.push(tpo);
                this._availableTpo.forEach((eachAvailableTpo: any, index) => {
                  if (eachAvailableTpo.id === tpo.id) {
                    this._availableTpo.splice(index, 1);
                  }
                })
              }
            })
          })
        }
        this.availableTpo = JSON.parse(JSON.stringify(this._availableTpo));
      })
    })
  }

  addRecruiter = (recruiter: any, event: any, overlayElement: any, inPlaceElement: any) => {
    recruiter.tempAdd = true
    this.selectedRecruiters.push(recruiter)
    let payload = { "recruiter": [recruiter.id] }
    this.isLoadingRecruiter = true;
    this.jobService.addAccessManagement(this.eventJobType, this.jobId, payload).subscribe(res => {
      this.getRecruiters();
      this.getPanelMembers();
    })
    this.recruiterSearchTerm = '';
    overlayElement.hide(event);
    inPlaceElement.deactivate();
  }

  addInterviewer = (interviewer: any, event: any, overlayElement: any, inPlaceElement: any) => {
    interviewer.tempAdd = true
    this.selectedInterviewers.push(interviewer)
    let payload = { "interviewer": [interviewer.id] }
    this.isLoadingInterviewer = true;
    this.jobService.addAccessManagement(this.eventJobType, this.jobId, payload).subscribe(res => {
      this.getInterviewers();
    })
    this.interviewerSearchTerm = '';
    overlayElement.hide(event);
    inPlaceElement.deactivate();
  }

  addManager = (manager: any, event: any, overlayElement: any, inPlaceElement: any) => {
    manager.tempAdd = true;
    this.selectedHiringManagers.push(manager)
    let payload = { "hiringManager": [manager.id] }
    this.isLoadingManager = true;
    this.jobService.addAccessManagement(this.eventJobType, this.jobId, payload).subscribe(res => {
      this.getHiringManagers();
      this.getPanelMembers();
    })
    this.hiringManagerSearchTerm = '';
    overlayElement.hide(event);
    inPlaceElement.deactivate();
  }

  addTpo = (tpo: any, event: any, overlayElement: any, inPlaceElement: any) => {
    tpo.tempAdd = true;
    this.selectedTpo.push(tpo)
    let payload = { "limitedRecruiter": [tpo.id] }
    this.isLoadingTpo = true;
    this.jobService.addAccessManagement(this.eventJobType, this.jobId, payload).subscribe(res => {
      this.getTpo();
      this.getPanelMembers();
    })
    overlayElement.hide(event);
    inPlaceElement.deactivate();
  }

  removeRecruiter = (recruiter: any) => {
    this.isLoadingRecruiter = true;
    this.jobService.removeAccessManagement(this.eventJobType, this.jobId, recruiter.id, 'recruiter').subscribe(res => {
      this.getRecruiters();
    })
  }

  removeInterviewer = (interviewer: any) => {
    this.isLoadingInterviewer = true;
    this.jobService.removeAccessManagement(this.eventJobType, this.jobId, interviewer.id, 'interviewer').subscribe(res => {
      this.getInterviewers();
    })
  }

  removeManager = (hiringManager: any) => {
    this.isLoadingManager = true;
    this.jobService.removeAccessManagement(this.eventJobType, this.jobId, hiringManager.id, 'hiringManager').subscribe(res => {
      this.getHiringManagers();
    })
  }

  removeTpo = (recruiter: any) => {
    this.isLoadingTpo = true;
    this.jobService.removeAccessManagement(this.eventJobType, this.jobId, recruiter.id, 'limitedRecruiter').subscribe(res => {
      this.getTpo();
    })
  }

  searchRecruiter = (event: any) => {
    this._availableRecruiters = this.availableRecruiters.filter((val: any) => val.name.toLowerCase().includes(event.toLowerCase()))
    if (this._availableRecruiters.length === 0) {
      this._availableRecruiters = [{ name: "No Records" }];
    }
  }

  searchInterviewer = (event: any) => {
    this._availableInterviewers = this.availableInterviewers.filter((val: any) => val.name.toLowerCase().includes(event.toLowerCase()))
    if (this._availableInterviewers.length === 0) {
      this._availableInterviewers = [{ name: "No Records" }];
    }
  }

  searchManager = (event: any) => {
    this._availableHiringManagers = this.availableHiringManagers.filter((val: any) => val.name.toLowerCase().includes(event.toLowerCase()))
    if (this._availableHiringManagers.length === 0) {
      this._availableHiringManagers = [{ name: "No Records" }];
    }
  }

  searchTpo = (event: any) => {
    this._availableTpo = this.availableTpo.filter((val: any) => val.name.toLowerCase().includes(event.toLowerCase()))
    if (this._availableTpo.length === 0) {
      this._availableTpo = [{ name: "No Records" }];
    }
  }

  joinPanelMembers = (panel: any): string => {
    let members: Array<any> = [];
    panel.map((e: any) => members.push(e.name))
    return members.join();
  }

  hidePanel(event: any, panel: any, index: number) {
    this.showPanelAdd = false;
    panel.tempAdd = true;
    this.jobService.removePanel(this.jobId, panel.panelId).subscribe(res => {
      this.getPanelMembers();
    })
  }

  panelSelect(ip: any) {
    this.addNewMember = true;
    let allPanelMembers: any = [];
    let allPanelMembersString: string = '';
    let fieldName = "Panel " + (this.panels.length + 1).toString();
    let fieldValue: any = [];
    this.allMembers.forEach((each: any, index: number) => {
      if (this.selectedPanelMembers.includes(each.id)) {
        allPanelMembersString += (`${allPanelMembersString.length > 1 ? ', ' : ''}` + each.name);
        allPanelMembers.push({ name: each.name, id: each.id })
        fieldValue.push(each.id);
      }
    })
    ip.deactivate();
    let payload = {
      "panelMember": [
        {
          "fieldName": fieldName,
          "fieldValue": fieldValue
        }
      ]
    }
    this.jobService.addAccessManagement(this.eventJobType, this.jobId, payload).subscribe(res => {
      this.getPanelMembers();
    })
  }

  getPanelMembers() {
    this.jobService.getJobEvent(this.eventJobType, this.jobId, this.isInEvent).subscribe(res => {
      let panelMembers = res.data.accessManagement?.panelMember;
      this.allMembers = [];
      this.allMembers = [...this.selectedTpo, ...this.selectedHiringManagers, ...this.selectedRecruiters, ...this.selectedInterviewers];
      this.panels = [];
      if(panelMembers?.length){
        panelMembers.forEach((eachIndex: any) => {
          let panelMembersData: any = [];
          let allPanelMembersString = '';
          let panelName: string = eachIndex.fieldName.replace('Panel', '');
          let panelId: string = eachIndex.fieldId;
          eachIndex.fieldValue.forEach((eachId: any) => {
            this.allMembers.forEach((eachData: any) => {
              if (eachData.id === eachId) {
                allPanelMembersString += (`${allPanelMembersString.length > 1 ? ', ' : ''}` + eachData.name);
                panelMembersData.push({ name: eachData.name, id: eachData.id })
              }
            })
          })
          let panelMemberStructure = {
            panelName: parseInt(panelName),
            panelId: panelId,
            allPanelMembersString: allPanelMembersString,
            allPanelMembersUpdateString: JSON.parse(JSON.stringify(allPanelMembersString)),
            allPanelMembers: panelMembersData,
            isUpdate: false,
            updateSelectedMembers: JSON.parse(JSON.stringify(panelMembersData))
          }
          this.panels.push(panelMemberStructure);
          this.panels.sort((a: any, b: any) => {
            if (a.panelName < b.panelName) {
              return -1;
            }
            if (a.panelName > b.panelName) {
              return 1;
            }
            return 0;
          })
        })
      }
      this.showPanelAdd = true;
      this.addNewMember = false;
    })
  }

  panelClose(ip: any) {
    this.selectedPanelMembers = [];
    ip.deactivate();
  }

  updatePanel(i: number) {
    this.panels[i].isUpdate = true;
    this.setSelectedMember(this.panels[i])
  }

  setSelectedMember(panel: any, reset?: any) {
    this.selectedPanelMembers = [];
    reset ? panel.updateSelectedMembers = panel.allPanelMembers : '';
    reset ? panel.isUpdate = false : '';
    panel.updateSelectedMembers.forEach((each: any) => {
      this.selectedPanelMembers.push(each.id);
    })
    return true;
  }

  clickedMember(event: any, member: any, panel: any) {
    if (event.target.checked) {
      panel.updateSelectedMembers.push(member)
    }
    else {
      panel.updateSelectedMembers.forEach((each: any, index: number) => {
        each.id === member.id ? panel.updateSelectedMembers.splice(index, 1) : '';
      })
    }
  }

  saveUpdatePanel(panelName: any, panelMember: any) {
    let payload = {
      "panelMember": [
        {
          "fieldName": panelName,
          "fieldValue": panelMember
        }
      ]
    }
    this.jobService.addAccessManagement(this.eventJobType, this.jobId, payload).subscribe(res => {
      this.getPanelMembers();
    })
  }
}