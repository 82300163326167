<div class="registration-form" [ngClass]="{'hidden': otpRequired ? !otpVerified : false}">
    <div class="responsive-design">
        <div class="dimensions">
            <span class="p-float-label">
                <p-dropdown 
                    class="dropdown" 
                    inputId="dimensions-dropdown" 
                    [autoDisplayFirst]="false" 
                    optionLabel="label" 
                    optionValue="value" 
                    [options]="dimensionsArray" 
                    placeholder="Laptop"
                    [(ngModel)]="defaultDimension" 
                    (onChange)="dimensionChange($event)">
                </p-dropdown>
                <label for="dimensions-dropdown">Dimensions <span style="color: #E24A47">*</span></label>
            </span>
        </div>
        <div class="responsive-width">
            <span class="p-float-label">
                <input type="text" id="width" pInputText style="width: 80px;" [(ngModel)]="defaultWidth" disabled="true">
                <label for="width">Width <span [style]="{color: '#E24A47'}">*</span></label>
            </span>  
        </div>
        <p class="separator">X</p>
        <div class="responsive-height">
            <span class="p-float-label" style="margin: 10px;">
                <input type="text" id="height" pInputText style="width: 80px;" [(ngModel)]="defaultHeight" disabled="true">
                <label for="height">Height <span [style]="{color: '#E24A47'}">*</span></label>
            </span>
        </div>
    </div>
    <div class="form-container" #formContainer [style.width]="divWidth" [style.height]="divHeight">
        <div class="form-data" [ngClass]="{'form-data': !isMobilePreview, 'mobileForm-data': isMobilePreview}">
            <h2 class="form-title" #title>{{ formName }}</h2>
        </div>
        <div *ngIf="isLoading" class="loadingOverlay">
            <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s"></p-progressSpinner>
        </div>
        <div class="section-navigator-container">
            <div class="section-navigator-content">
                <div *ngFor="let section of formFieldsSections?.form_fields; let i = index" 
                     [class.active]="i === activeSectionIndex" class="section-navigator"
                     (click)="setActiveSection(i)" (keydown)="handleKeydown($event, i)">
                    {{ section.section_label }}
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading">
            <div class="formAlign" [ngClass]="{'formAlign': !isMobilePreview, 'mobileformAlign': isMobilePreview}" #formAlign>
                <div *ngFor="let section of formFieldsSections?.form_fields; let i = index" style="padding-top: 10px;" #sectionDiv>
                    <div class="sectionFlex">
                        <div class="sectionHeaderBar"></div>
                        <span class="sectionHeader"> {{ section.section_label }} </span>
                    </div>
                    <ng-container *ngFor="let model of section.section_form_fields">
                        <ng-container *ngIf="shouldDisplayField(model)">
                            <ng-container *ngIf="(model.type == 'Multi Input' || model.type == 'Multi Input Email') || model.element_type === 'field'">
                                <div class="field preview-form" [ngClass]="{'preview-form': !isMobilePreview, 'mobilePreview': isMobilePreview}" [ngSwitch]="model.type" #field>
                                    <p class="fieldName" *ngIf="model.type !== 'multiinput' && model.type !== 'sectionHeader' && model.type !== 'dropdown' && model.type !== 'Multi Input Email' && model.type !== 'Multi Input' ">
                                        {{ model.label }} <span *ngIf="model.isMandatory" class="imp">*</span>
                                    </p>
                                    <ng-template [ngSwitchCase]="'Input'">
                                        <input [placeholder]="model.placeholder ?? ''" [(ngModel)]="model.answer" 
                                            [id]="model.id" pInputText style="margin-right: 15px; width: 100%;" [required]="model.isMandatory"
                                            (ngModelChange)="onFieldValueChange($event, model.id)"/>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Number'">
                                        <input [placeholder]="model.placeholder ?? ''" [(ngModel)]="model.answer" [type]="'number'" 
                                            [id]="model.id" pInputText style="margin-right: 15px; width: 100%;" [required]="model.isMandatory"
                                            (ngModelChange)="onFieldValueChange($event, model.id)"/>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Multi Input'">
                                        <div class="multiinputContainer" [ngClass]="{'multiinputContainer': !isMobilePreview, 'mobileMultiInput': isMobilePreview}" #multiinputContainer>
                                            <div *ngFor="let subField of model.form_fields" class="multiinput-field" 
                                                [ngClass]="{'multiinput-field': !isMobilePreview, 'mobileMultiInputField': isMobilePreview}">
                                                <p class="fieldName">
                                                    {{ subField.label }} <span *ngIf="subField.isMandatory" class="imp">*</span>
                                                </p>
                                                <input [id]="subField.id" type="text" pInputText [(ngModel)]="subField.answer" style="width: 100%;"
                                                    [required]="subField.isMandatory" pattern="[a-zA-Z0-9 ]*" 
                                                    (ngModelChange)="onFieldValueChange($event, subField.id)"/>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Multi Input Email'">
                                        <div class="multiinputContainer" [ngClass]="{'multiinputContainer': !isMobilePreview, 'mobileMultiInput': isMobilePreview}" #multiinputContainer>
                                            <div *ngFor="let subField of model.form_fields" class="multiinput-field" 
                                                [ngClass]="{'multiinput-field': !isMobilePreview, 'mobileMultiInputField': isMobilePreview}">
                                                <p class="fieldName">
                                                    {{ subField.label }} <span *ngIf="subField.isMandatory" class="imp">*</span>
                                                </p>
                                                <input [id]="subField.id" type="email" pInputText [(ngModel)]="subField.answer" style="width: 100%;"
                                                    [required]="subField.isMandatory" pattern="[a-zA-Z0-9 ]*" 
                                                    (ngModelChange)="onFieldValueChange($event, subField.id)"/>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Email'">
                                        <div class="email-group">
                                            <input [id]="model.id" type="text" pInputText [placeholder]="model.placeholder" [(ngModel)]="model.answer"
                                                [required]="model.isMandatory" #emailField="ngModel" style="margin-right: 15px; width: 100%;" [pattern]="emailPattern"
                                                (ngModelChange)="onFieldValueChange($event, model.id)"/>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Date Picker'">
                                        <p-calendar [id]="model.id" dateFormat="mm-dd-yy" dataType="string" inputId="dateformat" [style]="{width: '100%'}"
                                            [(ngModel)]="model.answer" [required]="model.isMandatory" #datePickerField="ngModel"
                                            (ngModelChange)="onFieldValueChange($event, model.id)">
                                        </p-calendar>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Checkbox'">
                                        <div *ngFor="let option of model.options ?? []" class="field-checkbox">
                                            <span>
                                                <p-checkbox [id]="model.id" [name]="option.label" [value]="option.value" [inputId]="option.value"
                                                    [(ngModel)]="model.answer" [required]="model.isMandatory" 
                                                    (ngModelChange)="onFieldValueChange($event, model.id)">
                                                </p-checkbox>
                                                <label [for]="option.value">{{ option.label }}</label>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Dropdown'">
                                        <div class="dropdown-handle">
                                            <p-dropdown [id]="model.id" [options]="model.options ?? []" [placeholder]="model.placeholder ?? ''"
                                                [autoDisplayFirst]="false" class="field-dropdown" optionLabel="label" optionValue="value" [(ngModel)]="model.answer"
                                                [required]="model.isMandatory" (ngModelChange)="onFieldValueChange($event, model.id)">
                                            </p-dropdown>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Radio'">
                                        <div *ngFor="let option of model.options ?? []" class="field-radio">
                                            <span>
                                                <p-radioButton [id]="model.id" [inputId]="option.value" [name]="option.label" [value]="option.value"
                                                    [(ngModel)]="model.answer" [required]="model.isMandatory" (ngModelChange)="onFieldValueChange($event, model.id)">
                                                </p-radioButton>
                                                <label [for]="option.value">{{ option.label }}</label>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Boolean Radio'">
                                        <div *ngFor="let option of model.options ?? []" class="field-booleanRadio">
                                            <span>
                                                <p-radioButton [id]="model.id" [inputId]="option.value" [name]="option.label" [value]="option.value"
                                                    [(ngModel)]="model.answer" [required]="model.isMandatory" 
                                                    (ngModelChange)="onFieldValueChange($event, model.id)">
                                                </p-radioButton>
                                                <label [for]="option.value">{{ option.label }}</label>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Mobile'">
                                        <div class="mobile-group" style="display: flex;align-items: center;">
                                            <div class="p-inputgroup mobile-container">
                                                <button type="button" pButton pRipple [label]="selectedCode.isoCode3" [icon]="'pi pi-angle-down'"
                                                    (click)="op.toggle($event)" [style]="{height: '40px'}">
                                                    <p-overlayPanel #op>
                                                        <ng-template pTemplate>
                                                            <span class="p-input-icon-left search-code">
                                                                <em class="pi pi-search"></em>
                                                                <input (ngModelChange)="searchCode($event)"
                                                                    style="width: 100%; padding-left: 30px !important;" pInputText placeholder="Search"
                                                                    type="text" [(ngModel)]="codeSearchTerm"/>
                                                            </span>
                                                            <div *ngFor="let code of _countryCodeList" class="countryCode-overlay">
                                                                <p (click)="selectedCode = code; op.hide()"
                                                                    class="countryCode">
                                                                    {{code.country}} <span [style]="{fontWeight: '600'}"> (+{{ 
                                                                        +code.countryCodes}})</span> </p>
                                                            </div>
                                                        </ng-template>
                                                    </p-overlayPanel>
                                                </button>
                                
                                                <input [id]="model.id"  [(ngModel)]="model.answer"
                                                    [maxlength]="selectedCode.phLength" type="number" [style]="{width: '80%',borderTopLeftRadius: '0px',borderBottomLeftRadius: '0px'}" (keypress)="restrictNumber($event, selectedCode.phLength)"
                                                    [required]="model.isMandatory" #inputField="ngModel" (ngModelChange)="onFieldValueChange($event, model.id)"/>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Multi Text'">
                                        <p-chips id="p1-chips" [(ngModel)]="model.answer" [allowDuplicate]="false" [max]="10" class="multiText" [ngStyle]="{'width': '100%' }"
                                            [required]="model.isMandatory" [id]="model.id" [addOnBlur]="true" [ngClass]="{'multiText': !isMobilePreview, 'mobileMultiTest': isMobilePreview}"
                                            [placeholder]="model.placeholder ?? ''" (ngModelChange)="onFieldValueChange($event, model.id)">
                                        </p-chips>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Date'">
                                        <div class="field-date">
                                            <p-calendar [id]="model.id" [(ngModel)]="model.answer" [placeholder]="model.placeholder ?? ''" [showIcon]="true" [showTime]="false" [timeOnly]="false"
                                                [showButtonBar]="true" dateFormat="dd/mm/yy" [required]="model.isMandatory"
                                                (ngModelChange)="onFieldValueChange($event, model.id)">
                                            </p-calendar>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Percentage'">
                                        <div class="field-percentage">
                                            <p-inputNumber [inputId]="model.id" [(ngModel)]="model.answer" mode="decimal" [min]="0" [max]="100" suffix="%"
                                                [placeholder]="model.placeholder ?? ''" [required]="model.isMandatory" [ngStyle]="{'width':'100%' }"
                                                (ngModelChange)="onFieldValueChange($event, model.id)">
                                            </p-inputNumber>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Text Area'">
                                        <textarea pInputTextarea [(ngModel)]="model.answer" [id]="model.id" [autoResize]="true" [rows]="5" [required]="model.isMandatory"
                                            style="max-height: 50px; overflow: hidden; width: 100%;" (ngModelChange)="onFieldValueChange($event, model.id)"></textarea>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Section Header'">
                                        <p class="fieldName">
                                            {{ model.label }} <span *ngIf="model.isMandatory" class="imp">*</span>
                                            <p-divider class="p-divider"></p-divider>
                                        </p>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'File'">
                                        <div [id]="model.id" class="fileUploadDropzone" 
                                            ngx-dropzone [multiple]="false"  [ngClass]="{'fileUploadDropzone': !isMobilePreview, 'mobileUploadDropzone': isMobilePreview}"
                                            (change)="onSelectfile($event)" [ngClass]="{ hidden: uploadingFile }" #mobileUpload 
                                            (ngModelChange)="onFieldValueChange($event, model.id)">
                                            <ngx-dropzone-label *ngIf="!uploadingFile">
                                                <div class="dropzone-label">
                                                    <em class="pi pi-cloud-upload"></em>
                                                    <p class="upload-text">
                                                        Click to
                                                        <span [style]="{ color: 'var(--fc-primary)'}">upload</span> or Drag & Drop
                                                    </p>
                                                    <p class="format-text">
                                                        {{ model.properties?.fileFormat?.join(', ') ?? '' }} Formats only
                                                    </p>
                                                    <p class="format-text">
                                                        (Less than
                                                        {{ model.properties?.fileSize?.size + (model.properties?.fileSize?.sizeFormat ?? '') }})
                                                    </p>
                                                </div>
                                            </ngx-dropzone-label>
                                            <div *ngIf="uploadingFile">
                                                <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
                                                </p-progressSpinner>
                                            </div>
                                            <div *ngIf="fileBase64?.length" [style]="{width: '50%'}">
                                                <ngx-dropzone-image-preview
                                                    [ngClass]="{'file-preview' : fileType.includes('.pdf') || fileType.includes('.jpg') || fileType.includes('.png') }"
                                                    ngProjectAs="ngx-dropzone-preview" *ngFor="let f of file" [file]="f" [removable]="true"
                                                    (removed)="onRemovefile($event)">
                                                    <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                                                    </ngx-dropzone-label>
                                                </ngx-dropzone-image-preview>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="model.element_type === 'group' && model.type != 'Multi Input' && model.type != 'Multi Input Email'">
                                <div class="group-container">
                                    <div class="group-header">
                                        <span class="fieldName field">{{ model.label }}</span>
                                    </div>
                                    <ng-container *ngFor="let subField of model.form_fields">
                                        <div class="field preview-form" [ngClass]="{'preview-form': !isMobilePreview, 'mobilePreview': isMobilePreview}" [ngSwitch]="subField.type" #field>
                                            <p class="fieldName" *ngIf="subField.type !== 'multiinput' && subField.type !== 'sectionHeader' && subField.type !== 'dropdown' && subField.type !== 'Multi Input Email' && subField.type !== 'Multi Input' ">
                                                {{ subField.label }} <span *ngIf="subField.isMandatory" class="imp">*</span>
                                            </p>
                                            <ng-template [ngSwitchCase]="'Input'">
                                                <input [placeholder]="subField.placeholder ?? ''" [(ngModel)]="subField.answer" 
                                                    [id]="subField.id" pInputText style="margin-right: 15px; width: 100%;" [required]="subField.isMandatory"
                                                    (ngModelChange)="onFieldValueChange($event, subField.id)"/>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Number'">
                                                <input [placeholder]="subField.placeholder ?? ''" [(ngModel)]="subField.answer" [type]="'number'" 
                                                    [id]="subField.id" pInputText style="margin-right: 15px; width: 100%;" [required]="subField.isMandatory"
                                                    (ngModelChange)="onFieldValueChange($event, subField.id)"/>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Email'">
                                                <div class="email-group">
                                                    <input [id]="subField.id" type="text" pInputText [placeholder]="subField.placeholder" [(ngModel)]="subField.answer"
                                                        [required]="subField.isMandatory" #emailField="ngModel" style="margin-right: 15px; width: 100%;" [pattern]="emailPattern"
                                                        (ngModelChange)="onFieldValueChange($event, subField.id)"/>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Date Picker'">
                                                <p-calendar [id]="subField.id" dateFormat="mm-dd-yy" dataType="string" inputId="dateformat" [style]="{width: '100%'}"
                                                    [(ngModel)]="subField.answer" [required]="subField.isMandatory" #datePickerField="ngModel"
                                                    (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                </p-calendar>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Checkbox'">
                                                <div *ngFor="let option of subField.options ?? []" class="field-checkbox">
                                                    <span>
                                                        <p-checkbox [id]="subField.id" [name]="option.label" [value]="option.value" [inputId]="option.value"
                                                            [(ngModel)]="subField.answer" [required]="subField.isMandatory" 
                                                            (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                        </p-checkbox>
                                                        <label [for]="option.value">{{ option.label }}</label>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Dropdown'">
                                                <div class="dropdown-handle">
                                                    <p-dropdown [id]="subField.id" [options]="subField.options ?? []" [placeholder]="subField.placeholder ?? ''"
                                                        [autoDisplayFirst]="false" class="field-dropdown" optionLabel="label" optionValue="value" [(ngModel)]="subField.answer"
                                                        [required]="subField.isMandatory" (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                    </p-dropdown>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Radio'">
                                                <div *ngFor="let option of subField.options ?? []" class="field-radio">
                                                    <span>
                                                        <p-radioButton [id]="subField.id" [inputId]="option.value" [name]="option.label" [value]="option.value"
                                                            [(ngModel)]="subField.answer" [required]="subField.isMandatory" (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                        </p-radioButton>
                                                        <label [for]="option.value">{{ option.label }}</label>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Boolean Radio'">
                                                <div *ngFor="let option of subField.options ?? []" class="field-booleanRadio">
                                                    <span>
                                                        <p-radioButton [id]="subField.id" [inputId]="option.value" [name]="option.label" [value]="option.value"
                                                            [(ngModel)]="subField.answer" [required]="subField.isMandatory" 
                                                            (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                        </p-radioButton>
                                                        <label [for]="option.value">{{ option.label }}</label>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Multi Text'">
                                                <p-chips id="p1-chips" [(ngModel)]="subField.answer" [allowDuplicate]="false" [max]="10" class="multiText" [ngStyle]="{'width': '100%' }"
                                                    [required]="subField.isMandatory" [id]="subField.id" [addOnBlur]="true" [ngClass]="{'multiText': !isMobilePreview, 'mobileMultiTest': isMobilePreview}"
                                                    [placeholder]="subField.placeholder ?? ''" (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                </p-chips>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Date'">
                                                <div class="field-date">
                                                    <p-calendar [id]="subField.id" [(ngModel)]="subField.answer" [placeholder]="subField.placeholder ?? ''" [showIcon]="true" [showTime]="false" [timeOnly]="false"
                                                        [showButtonBar]="true" dateFormat="dd/mm/yy" [required]="subField.isMandatory"
                                                        (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                    </p-calendar>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Percentage'">
                                                <div class="field-percentage">
                                                    <p-inputNumber [inputId]="subField.id" [(ngModel)]="subField.answer" mode="decimal" [min]="0" [max]="100" suffix="%"
                                                        [placeholder]="subField.placeholder ?? ''" [required]="subField.isMandatory" [ngStyle]="{'width':'100%' }"
                                                        (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                    </p-inputNumber>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'Text Area'">
                                                <textarea pInputTextarea [(ngModel)]="subField.answer" [id]="subField.id" [autoResize]="true" [rows]="5" [required]="subField.isMandatory"
                                                    style="max-height: 50px; overflow: hidden; width: 100%;" (ngModelChange)="onFieldValueChange($event, subField.id)"></textarea>
                                            </ng-template>
                                            <ng-template [ngSwitchCase]="'File'">
                                                <div [id]="subField.id" class="fileUploadDropzone" 
                                                    ngx-dropzone [multiple]="false"  [ngClass]="{'fileUploadDropzone': !isMobilePreview, 'mobileUploadDropzone': isMobilePreview}"
                                                    (change)="onSelectfile($event)" [ngClass]="{ hidden: uploadingFile }" #mobileUpload 
                                                    (ngModelChange)="onFieldValueChange($event, subField.id)">
                                                    <ngx-dropzone-label *ngIf="!uploadingFile">
                                                        <div class="dropzone-label">
                                                            <em class="pi pi-cloud-upload"></em>
                                                            <p class="upload-text">
                                                                Click to
                                                                <span [style]="{ color: 'var(--fc-primary)'}">upload</span> or Drag & Drop
                                                            </p>
                                                            <p class="format-text">
                                                                {{ subField.properties?.fileFormat?.join(', ') ?? '' }} Formats only
                                                            </p>
                                                            <p class="format-text">
                                                                (Less than
                                                                {{ subField.properties?.fileSize?.size + (subField.properties?.fileSize?.sizeFormat ?? '') }})
                                                            </p>
                                                        </div>
                                                    </ngx-dropzone-label>
                                                    <div *ngIf="uploadingFile">
                                                        <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
                                                        </p-progressSpinner>
                                                    </div>
                                                    <div *ngIf="fileBase64?.length" [style]="{width: '50%'}">
                                                        <ngx-dropzone-image-preview
                                                            [ngClass]="{'file-preview' : fileType.includes('.pdf') || fileType.includes('.jpg') || fileType.includes('.png') }"
                                                            ngProjectAs="ngx-dropzone-preview" *ngFor="let f of file" [file]="f" [removable]="true"
                                                            (removed)="onRemovefile($event)">
                                                            <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                                                            </ngx-dropzone-label>
                                                        </ngx-dropzone-image-preview>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                    <button pButton pRipple type="button" label="Add Details" class="p-button-outlined" (click)="addGroup(model)"></button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            
        </div>
        <div class="submit-container">
            <div class="submit-form">
                <button pButton pRipple type="button" label="Submit" (click)="OnSubmit()" class="submit-btn"></button>
            </div>
            <div class="clear-form">
                <button pButton pRipple type="button" label="Clear Form" (click)="clearForm()" class="clear-btn"></button>
            </div>
        </div>
    </div>
</div>
