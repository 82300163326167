import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-date-time-settings',
  templateUrl: './date-time-settings.component.html',
  styleUrls: ['./date-time-settings.component.css']
})
export class DateTimeSettingsComponent implements OnInit {

  isLoading: boolean = false;
  saveBtnLoading: boolean = false;
  dateTimeTemplate: boolean = false;
  clicked : boolean = false;
  
  timeValue: string = '';
  dateValue: string = '';

  dateTimeDetails: FormGroup = new FormGroup({
    dateValue: new FormControl('', Validators.required),
    timeValue: new FormControl('', Validators.required),
  });

  timeList: any = [ 
    {name : '12 hours', value : '12'}, 
    {name : '24 hours', value : '24'}, 
  ];
  dateList: any = [ 
    {name: 'DD/MM/YYYY', value:'DD/MM/YYYY'}, 
    {name: 'DD-MM-YYYY', value:'DD-MM-YYYY'}, 
    {name: 'DD/MMM/YY', value:'DD/MMM/YY'}, 
    {name: 'DD-MMM-YY', value:'DD-MMM-YY'}, 
    {name: 'DD/MMM/YYYY', value:'DD/MMM/YYYY'}, 
    {name: 'DD-MMM-YYYY', value:'DD-MMM-YYYY'}, 
    {name: 'YYYY/MM/DD', value:'YYYY/MM/DD'},  
    {name: 'YYYY-MM-DD', value:'YYYY-MM-DD'}
  ];

  constructor(private settings: SettingsService,private messageService: MessageService, private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    let dateValue = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '').company_settings.date_format : '';
    this.dateList.forEach((item:any)=>{
      if(item.value == dateValue){
        this.dateValue = item.name;
      }
    })
    let timeValue = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '').company_settings.time_format : '';
    this.timeList.forEach((item:any) => {
      if(item.value == timeValue){
        this.timeValue = item.name;
      }
    })
  }

  timeChange(field:any){
    this.timeValue = field.value; 
  }
  dateTimeUpdate(){
    this.clicked = true;
    this.saveBtnLoading = true;

    if(this.dateTimeDetails.valid){

      let payload = {
        date_format : this.dateValue,
        time_format : this.timeValue,
      }
      this.settings.updateCompanyDataFastApi(payload).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.body.detail,
            });
            this.settings.getCompanyDataFastApi().subscribe({
              next: (companyRes: any) => {
                if (companyRes.status === 200) {
                  this.saveBtnLoading = false;
                  let dateFor = companyRes.body.date_format;
                  let timeFor = companyRes.body.time_format;
                  let letter = ['D', 'Y'];
                  companyRes.body.dateFormat = dateFor.toString().replace(
                    new RegExp(letter.join('|'), 'g'), (match: string) => match.toLowerCase()
                  );
                  companyRes.body.timeFormat = timeFor === '24 hours' ? 'HH:mm' : 'hh:mm';

                  let schoolArray = [companyRes.body];
                  localStorage.setItem('schoolDetails', JSON.stringify(schoolArray));
                }
              },
            });
          }
        },
        error: (_err) => {
          this.saveBtnLoading = false;
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the required data!', });
    }
  }

}
