import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalServicesService } from './services/global-services.service';
import { SharedServicesService } from './services/shared-services.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { LoginService } from './services/login.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [SharedServicesService, MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'webapp-neo-hire';
  headerTitle: string = '';
  secondTitle: string = '';
  route = '';
  applicationPage: boolean = false;
  registrationForm: boolean = false;
  forgotPassword: boolean = false;
  resetPassword: boolean = false;
  formPreview: boolean = false;
  routeListener: any;
  userDetails: any;
  authorized: boolean = false;
  searchItem: string = '';
  candidatePanel: boolean = false;
  companyLogo: string = '';
  favIcon: any;
  verification: boolean = false;

  constructor(
    private globalService: GlobalServicesService,
    private router: Router,
    private location: Location,
    private loginService: LoginService,
    private messageService: MessageService,
    private sharedService: SharedServicesService,
    private webTitle: Title,
  ) {
    this.router.events.subscribe(() => {
      let nestedPaths = this.location.path().split('/')
      if (nestedPaths[1] === 'events' && nestedPaths.length === 2) {
        this.searchItem = 'Events';
      }
      else if (nestedPaths[1] === 'talentpool-management' && nestedPaths.length === 2) {
        this.searchItem = 'Candidate';
      }
      else {
        this.searchItem = '';
      }
      if (this.location.path() != '') {
        this.route = this.location.path();
        this.applicationPage = this.route.split('/')[1] === "application-form" ? true : false;
        this.candidatePanel = this.route.split('?')[0] === "/candidatePanel" ? true : false;
        this.verification = this.route.replace('?', '/').split('/')[1] === "verification" ? true : false;
        this.forgotPassword = this.route.replace('?', '/').split('/')[1] === "forgotPassword" ? true : false;
        this.resetPassword = this.route.replace('?', '/').split('/')[1] === "reset-password" ? true : false;
        this.registrationForm = this.route.replace('?', '/').split('/')[1] === "registration-form" ? true : false;
        this.formPreview = this.route.split('/')[1] === "form" ? true : false;
      } else {
        this.route = ''
      }
    });

    this.sharedService.changeEmitted$.subscribe(res => {
      this.companyLogo = res;
      console.log(res)
    })
    this.favIcon = document.querySelector('#favIcon');
  }

  ngOnInit(): void {
    this.getSchoolData();

    this.loginService.validToken.subscribe((res: any) => {
      if (res.status === 401) {
        this.messageService.add(res.message);
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 5000)
      }
      if (res.statusMessage === 'loggedOut') {
        this.router.navigateByUrl('/login');
        // window.location.reload();
      }
    })
    this.routeListener = this.router.events.subscribe(() => {
      if (this.location.path() != '') {
        this.route = this.location.path();
        this.applicationPage = this.route.split('/')[1] === "application-form" ? true : false;
      } else {
        this.route = ''
      }
    });
    this.isAuthorized();
    this.globalService.commonGrowl.subscribe((data: any) => {
      const is_key = Object.keys(data).length;
      if (data && is_key) {
        this.messageService.add(data);
      }
    });
  }

  isAuthorized() {
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    if (this.userDetails.email) {
      console.log("🚀🔥 ~ file: app.component.ts:119 ~ AppComponent ~ isAuthorized ~ this.userDetails.email:", this.userDetails.email)
      this.loginService.isLoggedIn(this.userDetails.email).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.authorized = true;
          }
        });
    }

  }
  
   getSchoolData() {
    this.globalService.metadataFastApi().subscribe({
      next: (response: any) => {
        if (response.body?.company_details) {
          this.companyLogo = response.body?.company_details.logo? response.body?.company_details.logo : '';
          this.favIcon.href = response.body?.company_details.fav_icon ? response.body?.company_details.fav_icon : '';
          this.webTitle.setTitle(response.body?.company_details.name ? response.body?.company_details.name : "Neo Hire");
          localStorage.setItem("CompanyDetails",  JSON.stringify((response.body?.company_details)));
          localStorage.setItem("companyUUID",  (response.body?.company_details.uuid));
        }
      },
      error: (_err: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch company details'
        });
      }
    });
  }

  getSchoolDetails(schoolData: any) {
    this.globalService.getSchoolDetails().subscribe((schoolDetails: any) => {
      if (schoolDetails && schoolDetails.data) {
        if(Array.isArray(schoolDetails.data)){
          let dateFor = schoolDetails.data[0].dateFormat;
          let timeFor = schoolDetails.data[0].timeFormat;
          let letter = ['D', 'Y'];
          schoolDetails.data[0].dateFormat = dateFor?.toString().replace( 
            new RegExp(letter.join('|'), 'g'),(match: string) => match.toLowerCase());
          schoolDetails.data[0].timeFormat = timeFor == '24 hours' ? 'HH:mm' : 'hh:mm';
        }
        localStorage.setItem('schoolDetails', JSON.stringify(schoolDetails.data));
        this.globalService.schoolData.next(true);
      }

      this.companyLogo = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_metadata?.logo : '';
    })
  }

  ngOnDestroy() {
    this.routeListener.unsubscribe();
  }
}