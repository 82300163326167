import { Component, Input, OnInit, OnChanges ,ChangeDetectorRef } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { environment } from 'src/environments/environment';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import * as codes from '../../../assets/Json/collegeList.json';
import { EventServicesService } from 'src/app/services/event-services.service';
import { MessageService } from 'primeng/api';
import { CandidateStatus } from '../candidate-list/candidate-list.component';
import { candidateStatus, statusColorMapping} from 'src/app/enum/candidate-status.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';

export interface FormModel {
  fieldId?: string,
  fieldOrder: number,
  fieldName: string,
  fieldDescription: string,
  isMandatory: boolean,
  formName: string,
  displaySection: string,
  placeHolder: string,
  fieldType: string,
  answer: Array<any>,
  isValid?: boolean,
  schoolId?: string,
  dependant?: Dependancy,
  fieldDefaultValue?: Array<any>,
  fieldOptions?: Array<FieldOptions>,
  fileProperities?: FieldProperties,
  visible?: boolean,
}

export interface Dependancy {
  dependantOn: string,
  dependantValues: Array<string>
}

export interface FieldOptions {
  label: string,
  value: any
}

export interface FieldProperties {
  fileFormat: Array<string>,
  fileSize: any
}

@Component({
  selector: 'app-candidate-info',
  templateUrl: './candidate-info.component.html',
  styleUrls: ['./candidate-info.component.css'],
  providers: [MessageService],
})
export class CandidateInfoComponent implements OnInit, OnChanges {

  userInfo: Array<any> = [];
  personalInfo: Array<any> = [];
  educationQualification: Array<any> = [];
  
  eventDetails: Array<any> = [];
  usersAllEvent: Array<any> = [];
  experience: Array<any> = [];
  displayInfo: boolean = true;
  displayResume: boolean = false;
  displayFeedback: boolean = false;
  displayActivity: boolean = false;
  userDetails: any;
  registrationFormId: number | null = null;
  documentationFormId: number | null = null; 

  jobId: string = window.localStorage.getItem('selectedJobId') || '';
  eventId: string = window.localStorage.getItem('selectedEventId') || '';
  isInEvent: boolean = this.jobId === this.eventId;
  signedProfileUrl: string = '';

  candidateStatus: any = {}
  statusColor: Array<any> = [];

  stages: Array<any> = [];
  allUsers: Array<any> = [];
  pdfSrc: string |null = null;
  feedbackDetails: any = [];
  stageNameIdmapping: any = [];
  showDialog: boolean = false;
  paramItems: any;
  isLoadingFeedback: boolean = true;
  isLoading: boolean = false;
  isLoadingResume: boolean = false;
  allFormDetails: any = [];
  isLoadingDetails: boolean = false
  showRegistrationDialog: boolean = false;
  showDocumentationDialog: boolean = false;  
  editAllDetailDialog: boolean = false;
  displayDetails: boolean = false;
  registrationDetails: Array<{ label: string; value: string }> = [];
  documentationDetails: Array<any> = [];
  showRegistrationMenu: boolean = false;
  showDocumentationMenu: boolean = false;
  registrationSubmittedDate: string = "-";
  documentationSubmittedDate: string = "-";
  allFormTemplate: any = [];
  ENV = environment.HOST.NODE_ENV;
  bucketName: string = '';
  userRole: string = '';
  userPrivileges: any = {};
  formLists: any = [];
  formDetails: any = [];
  eventCategory: string = '';
  candidateActivity: any = [];
  timelyActivity: any = [];
  duplicateData: any = {};

  jobName: string = window.localStorage.getItem('selectedJobName') || '';
  @Input() selectedUser: any = {};
  @Input() numberOfStages: number = 0;
  @Input() disableFeedback: any;
  @Input() disableActivity: any;
  @Input() disableStage: any;

  templateModels: Array<FormModel> = [];
  templateCollections: Array<Array<FormModel>> = [];
  invalidField: any;
  ansChange: boolean = false;
  inEditMode: boolean = false;
  clicked: boolean = false;
  isFormValid: boolean = false;
  submitBtnLoading: boolean = false;
  selectedForm: string = '';
  belowAge: boolean = false;
  collegeList: any = (codes as any).default;
  dateFormat: any;
  timeFormat: any;
  combainFormat: any;
  documentLoading: boolean = false;

  constructor(
      private fileUploadService: FileUploadService, 
      private formBuilderService: FormBuilderService, 
      private jobService: JobServicesService, 
      private globalService: GlobalServicesService,
      private eventServices: EventServicesService,
      private messageService: MessageService,
      private cdr: ChangeDetectorRef,
      public  sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    this.timeFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.time_format : '';
    // this.globalService.timeFormat.subscribe((res:any) => {
    //   this.timeFormat = res;
    // })
    this.combainFormat = this.timeFormat == 'hh:mm' ? this.dateFormat  + '  '+ this.timeFormat + ' a' : this.dateFormat  + '  '+ this.timeFormat;
    this.candidateStatus = candidateStatus;

    this.statusColor = [
      { status: 'Applied', color: 'var(--clr-violet-bg-light)' },
      { status: 'scheduled', color: 'var(--clr-violet-bg-light)' },
      { status: 'addedToVettedPool', color: 'var(--clr-violet-bg-light)' },
      { status: 'Slot Sent', color: 'var(--clr-violet-bg-light)' },
      { status: 'Submitted', color: 'var(--clr-violet-bg-light)' },
      { status: 'Joining Confirmed', color: 'var(--clr-violet-bg-light)' },
      { status: 'Offered', color: 'var(--clr-violet-bg-light)' },

      { status: 'Offer Accepted', color: 'var(--clr-green-bg-light)' },
      { status: 'Offer Revision', color: 'var(--clr-blue-bg-light)' },
      { status: 'Offer Rejected', color: 'var(--clr-red-bg-light)' },

      { status: 'selected', color: 'var(--clr-green-bg-light)' },
      { status: 'Shortlist', color: 'var(--clr-green-bg-light)' },
      { status: 'All Clear', color: 'var(--clr-green-bg-light)' },
      { status: 'Eligible', color: 'var(--clr-green-bg-light)' },
      { status: 'Joined', color: 'var(--clr-green-bg-light)' },

      { status: 'Waitlist', color: '#FFE0B4' },
      { status: 'No Show', color: '#FFE0B4' },
      { status: 'Submitted Partially', color: '#FFE0B4' },
      { status: 'Not Joining', color: '#FFE0B4' },

      { status: 'Rejected', color: 'var(--clr-red-bg-light)' },
      { status: 'Not Eligible', color: 'var(--clr-red-bg-light)' },
      { status: 'Not Joined', color: 'var(--clr-red-bg-light)' },

      { status: "Pending", color: 'var(--clr-blue-bg-light)' },
      { status: "Submission Pending", color: 'var(--clr-blue-bg-light)' },
      { status: 'Offer Pending', color: '--clr-blue-bg-light' },
      { status: 'Mail Pending', color: '--clr-blue-bg-light' },

      { status: "attended", color: '#E1E6CC' },
      { status: "Approved Partially", color: '#E1E6CC' },
      { status: "Future Candidate", color: '#E1E6CC' },
      { status: 'Yet To Join', color: '#E1E6CC' },

      { status: 'archived', color: 'var(--clr-grey)' },
      { status: 'On Hold', color: '#7a859275' },
    ];

    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    this.eventCategory = localStorage.getItem('eventCategory') || 'technical';
    this.selectedForm = this.eventCategory == 'nonTechnical' ? 'Registration Form Non-Technical' : 'Registration Form';

    
    if (this.ENV === 'dev') {
      this.bucketName = 'neo-hire';
    } else if (this.ENV === 'acc') {
      this.bucketName = 'neo-hire';
    } else if ( this.ENV === 'prod' ){
      this.bucketName = 'neo-hire';
    }

    this.userRole = this.globalService.privilegeRoles;
    this.userPrivileges = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
  }
  public getStatusColor(status: candidateStatus): string {
    return statusColorMapping[status] || 'transparent';
  }
  ngOnChanges(): void {
    if (!this.selectedUser) {
      return;
    }
    if (this.selectedUser?.id && this.eventId) {
      this.fetchCandidateInfo(); 
    } 
  }
  
  setEventDetails(eventDetails: any){
    this.eventDetails = [
      { field: "Event", value: eventDetails?.eventTitle ?? '-' },
      { field: "Current Stage ", value: (eventDetails?.stageName) + (eventDetails?.stageType ? (" (" + eventDetails?.stageType + ") ") : '') },
      { field: "Current Status", value: eventDetails?.jobStatus ?? '-' },
    ]
  }

  showInfo = () => {
    this.displayInfo = true;
    this.displayResume = false;
    this.displayFeedback = false;
    this.displayActivity = false;
    this.displayDetails = false; 
  }
  
  showResume = () => {
    this.displayResume = true;
    this.displayInfo = false;
    this.displayFeedback = false;
    this.displayActivity = false;
    if (!this.pdfSrc) { 
        this.fetchResumeUrl();
    }
    this.displayDetails = false; 
};
  
showActivity = () => {
  this.displayActivity = true;
  this.displayInfo = false;
  this.displayResume = false;
  this.displayFeedback = false;
  this.getCandidateActvity(); 
  this.displayDetails = false;  
};

  
  showFeedback() {
    this.isLoadingFeedback = true;
    this.displayFeedback = true;
    this.displayActivity = false;
    this.displayInfo = false;
    this.displayResume = false;
    this.displayDetails = false; 
    this.feedbackDetails = [];
    this.jobService.getCandidateFeedback(this.eventId, this.selectedUser.id).subscribe(
        (res: any) => {
            if (res && res.data && res.data.length > 0) {
                this.feedbackDetails = res.data;
                this.feedbackDetails.sort((a: any, b: any) => +new Date(b.updatedAt) - +new Date(a.updatedAt));
                this.feedbackDetails.forEach((each: any) => {
                    each.stageName = this.stageNameIdmapping[each.stageId];
                });
            } else {
                this.feedbackDetails = [];  
            }
            this.isLoadingFeedback = false;
        },
        (error) => {
            this.feedbackDetails = [];
            this.isLoadingFeedback = false;
            this.messageService.add({ 
              severity: 'error', 
              summary: 'Error', 
              detail: error?.message || 'Failed to load feedback. Please try again.' 
          });
        }
    );
}

getCandidateActvity = async () => {
  this.candidateActivity = [];
  this.timelyActivity = [];
  this.isLoading = true;

  try {
    const response = await this.jobService.getCandidateActivity(this.eventId, this.selectedUser.id).toPromise();
    const activityData = response?.data ?? response;

    if (Array.isArray(activityData) && activityData.length > 0) {
      const dateMap: Record<string, any[]> = {};
      this.candidateActivity = activityData.map((activity: any) => {
        const actionText = ['Status Change', 'Move Stage'].includes(activity.action)
          ? activity.action
          : activity.action || 'Unknown Action';

        const oldStage = activity.old_value || '-';
        const newStage = activity.new_value || '-';
        const movedOn = activity.created_at ?? new Date().toISOString();
        const movedDate = activity.created_at?.split('T')[0] ?? '-';

        const formatted = {
          action: actionText,
          remarks: activity.remarks?.trim() || '-',
          createdBy: activity.created_by ?? 'System',
          movedOn,
          movedDate,
          oldStage,
          newStage
        };

        if (!dateMap[movedDate]) dateMap[movedDate] = [];
        dateMap[movedDate].push(formatted);

        return formatted;
      });

      this.timelyActivity = Object.values(dateMap)
        .sort((a, b) => +new Date(b[0].movedOn) - +new Date(a[0].movedOn))
        .map(group => group.sort((a, b) => +new Date(b.movedOn) - +new Date(a.movedOn)));
    } else {
      this.timelyActivity = [];
    }
  } catch (error) {
    this.timelyActivity = [];
  } finally {
    this.isLoading = false;
  }
};


manageCandidateActivity = () => {
  this.candidateActivity.map((activity: any) => {
    if (activity?.action == 'Status Updated') {
      activity.from = this.candidateStatus[activity.current_status];
      activity.to = this.candidateStatus[activity.next_status];
    } else if (activity.movement != "registration" && activity.movement != "movedToEvent" && activity?.action == 'Move To') {
      activity.from = this.getStageName(activity.current_stageId);
      activity.to = this.getStageName(activity.next_stageId);
    }

    if (activity.movement == "manual") {
      activity.userData = this.getUserData(activity.user);
    }
    return activity;
  });
};

getActionLabel(activity: any): string {
  switch (activity.movement) {
    case 'registration': return 'Registration';
    case 'movedToEvent': return 'Moved to Event';
    case 'manual': return 'Manual Action';
    default: return activity.action || 'Activity Update';
  }
}
  getUserData = (id: string) => {
    let userData: any = this.allUsers.filter((each: any) => each.email == id)[0];
    if(userData){
      userData.badge = userData.name.split(" ").map((n: string, index: number) => { if (index == 0 || index == userData.name.split(" ").length - 1) return n[0]; else return '' }).join("");
    }
    return userData;
  }

  getInterviewDuration(): Promise<any>
  {
    return new Promise((resolve, reject)=>{
      this.jobService.getAllInterviewDuration(this.selectedUser.id, this.eventId).subscribe((response: any)=>{
        resolve(response);
      })
    })
  }

  getStageDetails() {
      this.jobService.getAllStages(this.eventId).subscribe(res => {
        this.stages = res.data;
        this.stages.forEach((each: any) => {
          this.stageNameIdmapping[each.stageId] = each.stageName;
        })
        this.getAllUsers();
    })
  }

  getAllUsers() {
      this.eventServices.getAllUser().subscribe(res => {
        this.allUsers = res?.data ? res.data.items : {};
        if(this.selectedUser?.userCohortData?.cohort_spoc && !this.selectedUser?.userCohortData?.cohort_spoc?.id){
          this.selectedUser.userCohortData.cohort_spoc = this.allUsers.filter((user: any) => user.id == this.selectedUser?.userCohortData.cohort_spoc)[0];
        }
        this.getCandidateActvity();
      })
  }

  getStageName(id:string, getType?: boolean){
    let stageName = '-';
    let stageType = '-';
    this.stages.forEach((stage:any) => {
      if(id?.length == 10){
        if(stage.id == id) {
          stageName = stage.stageName; 
          stageType = stage.stageType;
        }
      } else{
        if(stage.stageId == id) {
          stageName = stage.stageName; 
          stageType = stage.stageType;
        }
      }
    });
    if(getType) {
      return stageType;
    }
    else return stageName;
  }

  fetchFeedback(detail: any) {
    this.isLoadingFeedback = true;
    this.paramItems = {
      eventId: this.eventId,
      stageId: detail.stageId,
      userID: this.selectedUser.id,
      candidateEmail: this.selectedUser.email
    }
    this.showDialog = true;
  }

  loadedFeedback(event: any) {
    this.isLoadingFeedback = event
  }


  downloadResume() {
    if (this.pdfSrc) {
      window.open(this.pdfSrc, '_blank'); 
    } else {
      this.messageService.add({ severity: 'warn', summary: 'No Resume Found', detail: 'This candidate has not uploaded a resume.' });
    }
  }
  fetchAllDetails()
  {
    this.formLists = [];
    this.allFormDetails = [];
    // this.showAllDetailDialog = true;
    this.isLoadingDetails = true;
    this.formBuilderService.getCandidateFormDetails(this.selectedUser.id).subscribe((res: any)=>{
      this.isLoadingDetails = false;
      this.allFormDetails = res?.data[0]?.formDetails;
      if(this.allFormDetails[0].formName == 'Registration Form Non-Technical') this.allFormDetails.shift();
      let mobileField: any = ["Primary Mobile Number", "Alternate Mobile Number", "Parent's Mobile Number"]
      this.formLists = [];
      this.allFormDetails.forEach((each: any)=>{
        each.answer = each.answer?.length ? each.answer : ['-'];
        // each.answer[0] = typeof each.answer[0] === 'boolean' ? (each.answer[0] === false ? 'No' : 'Yes') : each.answer[0] ;
        let answer = each.answer;
        if(mobileField.includes(each.fieldName) && answer.length === 2)
        {
          each.answer = [`+${answer[0]+ answer[1]}`];
        }  
      })
      if(this.formLists.length === 0)
      {
        this.allFormDetails.forEach((each: any)=>{
          if(this.formLists.indexOf(each.formName) === -1)
          {
            this.formLists.push(each.formName);
          }
          this.formLists.sort();
          this.formLists.reverse();
          this.selectedForm = this.formLists[0];
        })
      }
      this.allFormDetails.forEach((each: any)=>{
        if(each.answer[0] === "I agree to the terms and conditions below.")
        {
          each.answer[0] = "I agree to the terms and conditions"
        }
      })
    })
  }

  capitalize(eachField: any)
  {
    if(eachField.fieldType !== 'multiInputEmail' && eachField.fieldType !== 'email' && eachField.fieldName !== "Candidate ID" && 
    eachField.fieldName !== "University Serial/Registration Number"  && eachField.fieldName !== "Alternate Email Address")
    {
      return true;
    }
    return false;
  }

  typeCheck(answer: any){
    if(typeof answer === 'boolean'){
      return true;
    }else{
      return false;
    }
  }
  
  editAllDetails()
  {
    this.formLists = [];
    this.editAllDetailDialog = true;
    this.isLoadingDetails = true;
    this.inEditMode = true;
    this.getTemplates();
  }
  getTemplates = () => {
    this.isLoading = true;
    this.isLoadingDetails = true;
    this.allFormDetails = [];
    this.formDetails = [];
    let _templateModels: Array<any> = [];
    this.templateModels = [];
    this.templateCollections = [];
    this.formBuilderService.getTemplate().subscribe(res => {
      res.data.formDetails.forEach((data: any) => {
        if (data.formDetails[0].formName == this.selectedForm) {
          _templateModels = data.formDetails;
        }
      });

      _templateModels.forEach((eachField: any)=>{
        if(eachField.fieldName === 'Highest Degree - College Name')
        {
          eachField.fieldOptions = this.collegeList;
        }
      })

      this.templateModels = _templateModels.map((model: FormModel) => ({ ...model, answer: [], isValid: !model.isMandatory, visible: model.dependant ? false : true }));
      this.getCandidateForm();

      let sections: Array<string> = [];
      let _displaySection = '';

      _templateModels.forEach((model: FormModel) => {
        if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
          _displaySection = model.displaySection;
          sections.push(_displaySection);
        }
      })
    this.templateCollections = [];
      sections.forEach((eachSection: string) => {
        this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
      })
      setTimeout(() => {
        this.isLoading = false;
      }, 3000) 
    })
  }

selectForm(formName: string)
{
  this.selectedForm = formName;
}

  getCandidateForm = () => {
    this.formBuilderService.getCandidateFormDetails(this.selectedUser.id).subscribe((res: any)=>{
      this.isLoading = false;
      this.formDetails = res?.data[0];
      this.allFormDetails = res?.data[0]?.formDetails;
      if(this.formLists.length === 0)
      {
        this.allFormDetails.forEach((each: any)=>{
          if(this.formLists.indexOf(each.formName) === -1)
          {
            this.formLists.push(each.formName);
          }
          this.formLists.sort();
          this.formLists.reverse();
          this.selectedForm = this.formLists[0];
        })
      }
      this.isLoadingDetails = false;
      this.populateAnswers();
    })
  }

  populateAnswers = () => {
    this.templateModels.forEach((eachModel: FormModel) => {
      eachModel.isValid = true;
      this.allFormDetails.forEach((eachData: FormModel) => {
        if (eachModel.fieldName === eachData.fieldName) {
          eachModel.answer = eachData.answer;
        }
      })
    })
    this.ansChange = !this.ansChange;
    this.isLoading = false;
  }

  checkForDependancy = (model: FormModel): boolean => {
    if (model.dependant) {
      let templateModels = this.templateCollections.flat(1);
      let dependantField: any;

      templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName === model.dependant?.dependantOn) dependantField = eachModel;
      })
      if (model.dependant.dependantValues.includes(dependantField.answer[0])) {
        model.visible = true;
        return true;
      }
      else {
        model.visible = false;
        return false;
      }
    } else return true;
  }
  checkValid(event: any, model: FormModel) {
    model.isValid = event;

    if(model.fieldName == "Primary Email Address" || model.fieldName == "Alternate Email Address"){
      this.templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName == 'Alternate Email Address') {
          if( this.duplicateData.emailDuplicate != undefined && !this.duplicateData.emailDuplicate && eachModel.isValid){
            eachModel.isValid = true;
          } else {
            eachModel.isValid = false;
          }
        }
      })
    }

    if (model.fieldName == "Date Of Birth") {
      let year = new Date().getFullYear();
      let dif = year - Number(model.answer[0].split('-')[2]);
      if (dif < 18) {
        model.isValid = false;
        this.belowAge = true;
      } else {
        model.isValid = true;
        this.belowAge = false;
      }
    }
  }
  bindData(event: any, model: FormModel) {
    model.answer = [];
    switch (model.fieldType) {
      case 'checkbox': model.answer = event; break;
      case 'multiText': {
        (model.answer = event)
        if(model.fieldName == 'Alternate Email Address'){
          this.templateModels.forEach((eachModel: FormModel) => {
            if (eachModel.fieldName == 'Primary Email Address') {
              if(event.includes(eachModel.answer[0])){
                model.isValid = false;
                this.duplicateData.emailDuplicate = true;
              } else {
                this.duplicateData.emailDuplicate = false;
              }
            }
          })
        }
      }; break;
      case 'multiinput': {
        model.answer.push(model.fieldOptions?.map((option: FieldOptions) => option.value).join(' '));
      } break;
      case 'dropdown': {
        if (model.fieldName == 'Highest Degree - Year of Passing' && event != 'Prior to 2020') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'input': {
        if (model.fieldName == 'Aadhar Card Number:') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'mobile': model.answer = event; break;
      default: {
        model.answer.push(event)
      }
    }
  }

  submit() {
    this.clicked = true;
    let templateModels = this.templateCollections.flat(1).filter((eachModel: FormModel) => eachModel.visible);
    this.isFormValid = !templateModels.map((model: FormModel) => model.isValid).includes(false);

    if (this.isFormValid) {
      this.submitBtnLoading = true;
      let payload = templateModels;
      let filterRemainingForm: any = [];
      this.formDetails?.formDetails.forEach((each: any, index:number)=>{
        if(each.formName !== this.selectedForm)
        {
          filterRemainingForm.push(each);
        }
      })
      payload = [...payload, ...filterRemainingForm]
      this.formBuilderService.editFormDetails(payload, this.formDetails.id).subscribe((res: any)=>{
        this.submitBtnLoading = false;
        this.clicked = false;
        this.getTemplates();
      })
    } else {
      let invalidFields = templateModels.filter((model: FormModel) => !model.isValid);
      this.invalidField = invalidFields[0];
    }
  }

  downloadFeedback(stageId: number){
    this.isLoading = true;
    this.eventServices.getFeedbackDownloadLink(this.eventId, this.selectedUser.id, stageId).subscribe((res:any) => {
      this.isLoading = false;
      let downloadLink = res?.link;
      window.open(downloadLink);
    })
  }

  downloadDocuments()
  {
    this.documentLoading = true;
    this.formBuilderService.downloadCandidateFormDocs(this.selectedUser.id, this.selectedUser.email).subscribe((res: any)=>{
      this.documentLoading = false;
      if(res.status === 200 && res.data)
      {
        window.open(res.data, '_blank');
      }
      else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
      }
    })
  }

  fetchResumeUrl() 
  {
    this.isLoadingResume = true;
    this.pdfSrc = null; 
    this.jobService.getCandidateResume(this.eventId, this.selectedUser.id).subscribe(
        (response: any) => {
            const res = response?.body ?? response;
            if (res && res.resume) {
                this.pdfSrc = res.resume.toString().trim();
                this.cdr.detectChanges();
            } else {
                this.pdfSrc = null;
            }
            this.isLoadingResume = false;
        },
        (error) => {
            this.isLoadingResume = false;
        }
    );
  }
  
  showDetails() {
    this.displayDetails = true;
    this.displayInfo = false;
    this.displayResume = false;
    this.displayFeedback = false;
    this.displayActivity = false;
  
    this.isLoadingDetails = true;
    this.registrationFormId = null;
    this.documentationFormId = null;
    
    this.jobService.getCandidateForms(this.eventId, this.selectedUser.id).subscribe(
      (response: any) => {
        const data = response.body;
        
        if (data.registration_form.length > 0) {
          this.registrationFormId = data.registration_form[0].form_id;
          this.registrationSubmittedDate = data.registration_form[0].submitted_date || "-";
        } else {
          this.registrationSubmittedDate = "-";
        }
  
        if (data.documentation_form.length > 0) {
          this.documentationFormId = data.documentation_form[0].form_id;
          this.documentationSubmittedDate = data.documentation_form[0].submitted_date || "-";
        } else {
          this.documentationSubmittedDate = "-";
        }
  
        this.isLoadingDetails = false;
        this.cdr.detectChanges();
      },
      (error) => {
        this.isLoadingDetails = false;
      }
    );
  }
  

  fetchCandidateDetails(formType: 'registration' | 'documentation') {
    if (!this.selectedUser?.id || !this.eventId) return;
  
    this.isLoadingDetails = true;
    if (formType === 'registration') this.registrationDetails = [];
    else this.documentationDetails = [];
  
    const formId = formType === 'registration' ? this.registrationFormId : this.documentationFormId;
    if (!formId) {
      this.isLoadingDetails = false;
      return;
    }
  
    this.jobService.getCandidateFormDetails(this.eventId, formType, formId).subscribe(
      (response: any) => {
        const details = response?.body?.form_details
          ? this.processData(response.body.form_details, response.body.candidate_details)
          : [{ label: "No Data Found", value: "-" }];
  
        if (formType === 'registration') this.registrationDetails = details;
        else this.documentationDetails = details;
  
        this.isLoadingDetails = false;
        this.cdr.detectChanges();
      },
      (error) => {
        this.isLoadingDetails = false;
      }
    );
  }
  
  
  processData(formDetails: any, candidateDetails: any): any[] {
    let formattedData: any[] = [];
  
    if (!formDetails || !candidateDetails) {
      return [{ section: "No Data Found", label: "", value: "-" }];
    }
  
    Object.entries(formDetails).forEach(([section, fields]: [string, any]) => {
      let sectionData: any[] = [];
  
      fields.forEach((field: any) => {
        let fieldValue = candidateDetails[field.field_name] ?? "-";
  
        if (typeof fieldValue === 'string' && this.isUrl(fieldValue)) {
          fieldValue = `<a href="${fieldValue}" target="_blank" title="View File"><i class="pi pi-eye"></i></a>`;
        }        
  
        sectionData.push({
          section: section,
          label: field.field_label,
          value: fieldValue
        });
      });
  
      if (sectionData.length > 0) {
        formattedData = [...formattedData, ...sectionData];
      }
    });
  
    return formattedData.length ? formattedData : [{ section: "No Data Found", label: "", value: "-" }];
  }
  isUrl(value: string): boolean {
    const urlPattern = /^(https?:\/\/[^\s]+)/i;
    return urlPattern.test(value);
  }
  
  
  formatFieldName(key: string): string {
    return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }
  
  isFileField(fieldName: string): boolean {
    const fileFields = ["resume_cv", "cover_letter"];
    return fileFields.includes(fieldName);
  }
  

  
  toggleMenu(type: string) {
    if (type === 'registration') {
      this.showRegistrationMenu = !this.showRegistrationMenu;
      this.showDocumentationMenu = false;  
      if (this.showRegistrationMenu && !this.registrationDetails.length) {
        this.fetchCandidateDetails('registration');
      }
    } else if (type === 'documentation') {
      this.showDocumentationMenu = !this.showDocumentationMenu;
      this.showRegistrationMenu = false;
      if (this.showDocumentationMenu && !this.documentationDetails.length) {
        this.fetchCandidateDetails('documentation');
      }
    }
  }
  

  viewRegistration() {
    if (!this.registrationFormId) return;
    this.showRegistrationDialog = true;  
    if (this.registrationDetails.length > 0) return;
  
    this.isLoadingDetails = true;
    this.registrationDetails = [];
  
    this.jobService.getCandidateFormDetails(this.eventId, 'registration', this.registrationFormId).subscribe(
      (response: any) => {
        this.registrationDetails = response?.body?.candidate_details && response?.body?.form_details
          ? this.processData(response.body.form_details, response.body.candidate_details)
          : [{ label: "No Data Found", value: "-" }];
        
        this.isLoadingDetails = false;
        this.cdr.detectChanges();
      },
      (error) => {
        this.isLoadingDetails = false;
        this.cdr.detectChanges();
      }
    );
  }
  

closeDialog() {
  this.displayDetails = false; 
  this.showRegistrationDialog = false;
  this.showDocumentationDialog = false;
  this.registrationDetails = [];
  this.documentationDetails = [];
  this.showRegistrationMenu = false;
  this.showDocumentationMenu = false;
  
  setTimeout(() => {
    this.cdr.detectChanges();
  }, 100);

}
closeDocumentationDialog() {
  this.showDocumentationDialog = false;
  this.documentationDetails = [];
  this.cdr.detectChanges();
}

getGroupedSections(data: any[]): { name: string, fields: any[] }[] {
  const grouped: { [key: string]: any[] } = {};

  data.forEach(item => {
    const section = item.section || 'Other';
    if (!grouped[section]) {
      grouped[section] = [];
    }
    grouped[section].push(item);
  });

  return Object.keys(grouped).map(section => ({
    name: section,
    fields: grouped[section]
  }));
}

downloadAttachments(formType: 'registration' | 'documentation') {
  let formId = formType === 'registration' ? this.registrationFormId : this.documentationFormId;
  if (!formId) {
    return;
  }

  this.jobService.downloadCandidateAttachments(this.eventId, formType, formId).subscribe(
    (response: HttpResponse<Blob>) => {
      if (response.body) {
        const blob = new Blob([response.body], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${formType}-attachments.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    },
    (error) => {
      this.messageService.add({ severity: 'error', summary: 'Download Failed', detail: `Unable to download ${formType} attachments.` });
    }
  );
}



downloadRegistrationDetails() {
  if (!this.registrationDetails || this.registrationDetails.length === 0) {
      this.messageService.add({ severity: 'warn', summary: 'No Data', detail: 'No registration details available for download.' });
      return;
  }

  const csvData = this.convertToCSV(this.registrationDetails);
  this.triggerDownload(csvData, 'registration-details.csv');
}

viewDocumentation() {
  if (!this.documentationFormId) return;

  this.showDocumentationDialog = true;
  if (this.documentationDetails.length > 0) return;

  this.isLoadingDetails = true;
  this.documentationDetails = [];

  this.jobService.getCandidateFormDetails(this.eventId, 'documentation', this.documentationFormId).subscribe(
    (response: any) => {
      this.documentationDetails = response?.body?.candidate_details && response?.body?.form_details
        ? this.processData(response.body.form_details, response.body.candidate_details)
        : [{ label: "No Data Found", value: "-" }];
      
      this.isLoadingDetails = false;
      this.cdr.detectChanges();
    },
    (error) => {
      this.isLoadingDetails = false;
      this.cdr.detectChanges();
    }
  );
}

filterValidData(formData: any): any {
  if (!formData) return [];

  const filteredData = Object.keys(formData)
    .filter(key => formData[key] !== null && formData[key] !== '') 
    .map(key => ({
      label: this.formatFieldName(key),
      value: this.isFileField(key) 
        ? `<a href="${formData[key]}" target="_blank">View File</a>` 
        : formData[key]
    }));

  return filteredData.length ? filteredData : [{ label: "No Data Found", value: "-" }];
}





downloadDocumentDetails() {
  if (!this.documentationDetails || this.documentationDetails.length === 0) {
      this.messageService.add({ severity: 'warn', summary: 'No Data', detail: 'No documentation details available for download.' });
      return;
  }

  const csvData = this.convertToCSV(this.documentationDetails);
  this.triggerDownload(csvData, 'documentation-details.csv');
}
convertToCSV(data: { label: string; value: string }[]): string {
  const header = 'Field,Value\n';
  const rows = data
    .filter(row => !this.containsLink(row.value))
    .map(row => `"${row.label}","${row.value}"`)
    .join('\n');

  return header + rows;
}
containsLink(value: string): boolean {
  if (typeof value !== 'string') return false;
  const urlPattern = /^https?:\/\/\S+/i;
  return urlPattern.test(value);
}


triggerDownload(content: string, fileName: string) {
  const blob = new Blob([content], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
}

fetchCandidateInfo() {
  if (!this.selectedUser?.id || !this.eventId) {
    return;
  }
  this.jobService.getCandidateInfo(this.selectedUser.id, this.eventId).subscribe(
    (response: any) => {
      if (!response?.body?.candidate_details) {
        console.warn("Candidate details not found in response");
        return;
      }

      const candidateData = response.body.candidate_details;
      const stageDetails = response.body?.stages;
      if (stageDetails) {
        this.selectedUser = {
          ...this.selectedUser,
          stage: {
            current_stage: stageDetails.current_stage ?? 0,
            total_stage: stageDetails.total_stage ?? 1
          }
        };
      }
      const currentCity = candidateData?.current_city ?? '-';
      const dateOfBirth = candidateData?.date_of_birth ?? '-';
      this.personalInfo = [
        { field: "Gender", value: candidateData.gender ?? '-' },
        { field: "Date of Birth", value: dateOfBirth }, 
        { field: "Current Address", value: currentCity }, 
      ];

      this.educationQualification = [
        { field: "Degree", value: candidateData?.degreeDetails?.[0]?.degree ?? '-' },
        { field: "Department", value: candidateData?.degreeDetails?.[0]?.specialization ?? '-' },
        { field: "Batch", value: candidateData?.graduationYear ?? '-' },
      ];

      this.signedProfileUrl = candidateData?.profilePic;

      this.cdr.detectChanges();
    },
    (error) => {
      this.messageService.add({ 
        severity: 'warn', 
        summary: 'No Event Found', 
        detail: 'There are no events available at this time.' 
      });
    }
  );
}

}
