import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { saveAs } from 'file-saver';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { MenuItem, MessageService } from 'primeng/api';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Tooltip } from 'primeng/tooltip';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { nanoid } from 'nanoid'
import { candidateStatus, statusColorMapping} from 'src/app/enum/candidate-status.enum';

export enum CandidateStatus {
  applied = 'Applied',
  pending = 'Pending',
  selected = 'Selected',
  rejected = 'Rejected',
  archived = 'Archived',
  shortlisted = 'Shortlisted',
  addedToVettedPool = 'Added To Vetted Pool',
  submitted = 'Submitted',
  scheduled = 'Scheduled',
  allClear = 'All Clear',
  eligible = 'Eligible',
  attended = 'Attended',
  noteligible = 'Not Eligible',
  waitlisted = 'Waitlisted',
  noShow = 'No Show',
  slotSend = 'Slot Sent',
  submittedPartially = 'Submitted Partially',
  submissionPending = 'Submission Pending',
  partiallyApproved = 'Partially Approved',
  joiningConfirmed = 'Joining Confirmed',
  joined = 'Joined',
  notJoining = 'Not Joining',
  notJoined = 'Not Joined',
  yetToJoin = 'Yet To Join',
  mailPending = 'Mail Pending',
  offered = 'Offered',
  offerPending = 'Offer Pending',
  offerRevision = 'Offer Revision',
  offerAccepted = 'Offer Accepted',
  offerDeclined = 'Offer Declined',
  onHold = 'On Hold',
  futureCandidate = 'Future Candidate',
}

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css']
})
export class CandidateListComponent implements OnInit {
  @ViewChild(Tooltip) tooltip!: Tooltip;
  // Booloan Var
  displayCandidates: boolean = true;
  displayJobDetails: boolean = false;
  displayWorkflow: boolean = false;
  displayInterview: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  displayUserDetails: boolean = false;
  isInEvent!: boolean;
  showBulkUpload: boolean = false;
  sendingInvite: boolean = false;
  clicked: boolean = false;
  uploadingFile: boolean = false;
  bulkFile: boolean = false;
  insideSearch: boolean = false;
  cohortUploaded: boolean = false;

  // Array Var
  candidateList: any = [];
  usersCol: Array<any> = [];
  searchEmailIds: Array<any> = [];
  _usersCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  selectedUser: Array<any> = [];
  candidateStatus: any = {}
  // statusColor: Array<any> = [];
  excel: File[] = [];
  stageDetails: Array<any> = [];
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  dateFormat: any;
  timeFormat: any;
  searchPayload: any;


  loadSingleInvite: boolean = false;
  loadExistingUser: boolean = false;
  userExist: boolean = false;
  countDiv: boolean = false;
  eventList: any = [];
  sourceTypeList: any = [];
  sourceNameList: any = [];
  existingUserList: any = [];
  existingUserColumns: any = []
  cohortFileSize: any;
  requiredColumns: Array<any> = [];
  existingRequiredColumns: Array<any> = [];
  cohortFile: Array<any> = [];
  cohortFileCopy: Array<any> = [];
  errorFileData: any;
  errorFile: any;
  templateValue: Array<any> = [];
  counts: any;
  acknowledgeUsers: any;
  bulkAcknowledgeUsers: any = [];
  bulkValidatedData: any = [];
  existingUsers: any;
  mediaFile: File[] = [];
  loadEventList: boolean = false;
  allCategoryEvents: any;
  disableInvite: boolean = false;
  bulkEnable: boolean = false;
  messageEnable: boolean = false;
  fileMessage: any;
  fileUploadPayload: any;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  numberOfStages: number = 0;
  totalPages: number = 1;

  // String Var
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'userList';
  selectedJobId: string = '';
  selectedEventId: string = '';
  bulkUpload!: MenuItem[];
  _selectedCategory: string = 'single-invite';
  // eventRadio: string = 'technical';

  selectedJobName: string = '';
  schoolId: string = '';
  selectedEventName: string = '';
  schoolData: any;
  schoolCode: string = '';
  inviteUrl: string = ''
  eventId: string = '';
  eventName: string = '';
  eventCategory: string = '';
  link: string = '';
  category: any = localStorage.getItem("eventCategory");
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.assetBucketName;

  privilege: any;
  userRole: string = '';

  order_by: string = 'asc';
  sort_by: string = 'candidate_uuid';

  singleInvite: FormGroup = new FormGroup({
    invites: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    emailID: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    source: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required)
  })

  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl(''),
    eventCategory: new FormControl(''),
    actionId: new FormControl('')
  })

  constructor(
    private jobService: JobServicesService,
    private eventService: EventServicesService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: NgDynamicBreadcrumbService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private globalService: GlobalServicesService,
  ) {
    this.pageLimits = [10, 25, 50, 100];

    this.usersCol = [
      // { index: 1, field: 'degree', header: 'Education' },
      { index: 2, field: 'stage_name', header: 'Stage Name' },
      { index: 3, field: 'source', header: 'Source' },
      { index: 4, field: 'owner', header: 'Owner' },
      { index: 5, field: 'status', header: 'Status' },
      { index: 6, field: 'stageId', header: 'Stage' },
      { index: 7, field: 'email', header: 'E-mail' },
      { index: 8, field: 'mobile_number', header: 'Phone' },
      { index: 9, field: 'gender', header: 'Gender', isCapitalize: true },
      { index: 10, field: 'dob', header: 'Date Of Birth' },
      { index: 11, field: 'createdAt', header: 'Created On' },
      { index: 12, field: 'college_name', header: 'College Name' },
    ]

    this._selectedColumns = [
      // { index: 1, field: 'degree', header: 'Education' },
      { index: 2, field: 'stage_name', header: 'Stage Name' },
      { index: 5, field: 'status', header: 'Status' },
      { index: 6, field: 'stage_id', header: 'Stage' },
      { index: 7, field: 'email', header: 'E-mail' },
      { index: 8, field: 'mobile_number', header: 'Phone' },
      { index: 9, field: 'gender', header: 'Gender', isCapitalize: true },
    ]
  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    this.privilege = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
    this.userRole = this.globalService.privilegeRoles;

    this.selectedJobId = window.localStorage.getItem('selectedJobId') || '';
    this.selectedEventId = window.localStorage.getItem('selectedEventId') || '';
    this.selectedJobName = window.localStorage.getItem('selectedJobName') || '';
    this.selectedEventName = window.localStorage.getItem('selectedEventName') || '';
    // let category = localStorage.getItem("eventCategory");
    this.singleInvite.controls['eventCategory'].setValue(this.category);
    this.bulkInviteForm.controls['eventCategory'].setValue(this.category);
    this.isInEvent = this.selectedJobId === this.selectedEventId;
    this.getUsers();
    // this.getSavedColumns(); //pending to check
    this._usersCol = this.usersCol
    this.colsToDownload = this.usersCol;
    this.checkedColumns = this._selectedColumns;
    this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';

    this.schoolId = this.schoolData.school_id;
    this.schoolCode = this.schoolData.school_code;

    this.bulkUpload = [
      {
        label: 'Send Invite', command: () => {
          this.bulkUp();
        }
      },
    ]

    this.candidateStatus = candidateStatus;

    // this.statusColor = [
    //   { status: "Applied", color: 'var(--clr-violet-bg-light)' },
    //   { status: "scheduled", color: 'var(--clr-violet-bg-light)' },
    //   { status: "addedToVettedPool", color: 'var(--clr-violet-bg-light)' },
    //   { status: "Slot Sent", color: 'var(--clr-violet-bg-light)' },
    //   { status: "Submitted", color: 'var(--clr-violet-bg-light)' },
    //   { status: "Joining Confirmed", color: 'var(--clr-violet-bg-light)' },
    //   { status: "Offered", color: 'var(--clr-violet-bg-light)' },

    //   { status: 'Offer Accepted', color: 'var(--clr-green-bg-light)' },
    //   { status: 'Offer Revision', color: 'var(--clr-blue-bg-light)' },
    //   { status: 'Offer Rejected', color: 'var(--clr-red-bg-light)' },

    //   { status: "selected", color: 'var(--clr-green-bg-light)' },
    //   { status: "Shortlist", color: 'var(--clr-green-bg-light)' },
    //   { status: "All Clear", color: 'var(--clr-green-bg-light)' },
    //   { status: "Eligible", color: 'var(--clr-green-bg-light)' },
    //   { status: "Joined", color: 'var(--clr-green-bg-light)' },

    //   { status: "Waitlist", color: '#FFE0B4' },
    //   { status: "No Show", color: '#FFE0B4' },
    //   { status: "Submitted Partially", color: '#FFE0B4' },
    //   { status: "Not Joining", color: '#FFE0B4' },

    //   { status: "Rejected", color: 'var(--clr-red-bg-light)' },
    //   { status: "Not Eligible", color: 'var(--clr-red-bg-light)' },
    //   { status: "Not Joined", color: 'var(--clr-red-bg-light)' },

    //   { status: "Pending", color: 'var(--clr-blue-bg-light)' },
    //   { status: "Submission Pending", color: 'var(--clr-blue-bg-light)' },
    //   { status: 'Offer Pending', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'Mail Pending', color: '--clr-blue-bg-light' },

    //   { status: "attended", color: '#E1E6CC' },
    //   { status: "Approved Partially", color: '#E1E6CC' },
    //   { status: "Future Candidate", color: '#E1E6CC' },
    //   { status: 'Yet To Join', color: '#E1E6CC' },

    //   { status: "archived", color: 'var(--clr-grey)' },
    //   { status: 'On Hold', color: '#7a859275' },

    // ]

    this.routeChange();

    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.eventName = window.localStorage.getItem('selectedEventName') || '';
    this.schoolCode = this.schoolData.school_code;

    let formLink = '';
    this.eventCategory = window.localStorage.getItem('eventCategory') || 'technical';

    if (this.eventCategory == 'technical') formLink = 'registration-form?form=Registration%20Form';
    else if (this.eventCategory == 'nonTechnical') formLink = 'registration-form?form=Registration%20Form%20Non-Technical';

    if (this.schoolCode === 'nstech196' || this.schoolCode === 'wileyedge399' || this.schoolCode === 'kscollege408' || this.schoolCode === 'iamneo372' || this.schoolCode === 'wileyedge537') {
      this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/${formLink}&eventId=${this.eventId}&eventTitle=${this.eventName}`;
    }
    else {
      this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/application-form/${this.isInEvent ? this.selectedEventId : this.selectedJobId}`;
    }
  }
  public getStatusColor(status: candidateStatus): string {
    return statusColorMapping[status] || 'transparent';
  }
  routeChange = async () => {
    let routeChanged = false;
    let tabView = '';
    this._router.events.subscribe(async (path) => {
      routeChanged = true;
      if (path instanceof NavigationEnd) {
        let pathView = path.urlAfterRedirects.split('=')
        tabView = pathView[1] || '';
        await this.tabSwitch(tabView);
      }
    })
    if (!routeChanged) {
      const snapshot: RouterStateSnapshot = this._router.routerState.snapshot;
      const queryParams = snapshot.root.queryParams;
      this.tabSwitch(queryParams['view']);
    };
  }

  tabSwitch = async (tabView: string) => {
    console.log(this.userRole)
    switch (tabView) {
      case 'candidates': {
        if (this.userRole == 'interviewer' || this.userRole == 'evaluator') {
          this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
        } else {
          this.displayCandidates = true;
          this.displayJobDetails = false;
          this.displayWorkflow = false;
          this.displayInterview = false;
        }
        break;
      }
      case 'jobDetails':
      case 'eventDetails': {
        if (this.userRole == 'interviewer' || this.userRole == 'evaluator') {
          this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
        } else {
          this.displayCandidates = false;
          this.displayJobDetails = true;
          this.displayWorkflow = false;
          this.displayInterview = false;
        }
        break;
      };
      case 'workflow': {
        this.displayCandidates = false;
        this.displayJobDetails = false;
        this.displayWorkflow = true;
        this.displayInterview = false;
        break;
      }
      case 'interview': {
        if (this.userRole == 'evaluator') {
          console.log("workflow")
          this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
        } else {
          this.displayCandidates = false;
          this.displayJobDetails = false;
          this.displayWorkflow = false;
          this.displayInterview = true;
        }
        break;
      }
    }
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    this.selectedJobName = window.localStorage.getItem('selectedJobName') || '';
    this.selectedEventName = window.localStorage.getItem('selectedEventName') || '';
    const breadcrumbs = [
      {
        label: this.isInEvent ? 'Manage Events' : 'Manage Drives',
        url: this.isInEvent ? 'events' : 'jobs'
      },
      {
        label: this.isInEvent ? this.selectedEventName : this.selectedJobName,
        url: ''
      },
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  // API Functions
  getUsers = () => {
    this.loadingSpinner = true;
    this.candidateList = [];
    let payload: any = {
      eventId: this.selectedJobId,
      page: this.pageNumber,
      size: this.pageSize,
      order_by: this.order_by,
      sort_by: this.sort_by
    };
    if (this.searchPayload?.search && this.searchPayload.search.length > 0) {
      payload['s'] = this.searchPayload.search.join(' ');
    }
    this.jobService.getCandidatesFastApi(payload.eventId, payload.page, payload.size, payload.order_by, payload.sort_by, payload.s).subscribe({
      next: (result: any) => {
        this.candidateList = result?.body ? result.body?.items : [];
        this.candidateList.forEach((candidate: any) => {
          candidate.id = candidate.candidate_uuid;
          candidate.stageId = candidate.stage.current_stage;
          candidate._stageId = candidate.stage.current_stage;
        });
        this.numberOfStages = this.candidateList.length > 0 ? this.candidateList[0].stage.total_stage : 0;
        this.totalLength = result.body.total || 0;
        this.first = result.body.page || 1;
        this.last = result.body.pages || 10;
        this.pageSize = result.body.size || 10;
        this.totalPages = result.body.pages || 1;
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  }
  getUsersByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getUsers();
  };

  showUserDetails = (user: any) => {
    this.selectedUser = user;
    this.displayUserDetails = true;
  }
  setFormLink() {
    let formLink = '';

    if (this.category == 'technical') formLink = 'registration-form?form=Registration%20Form';
    else if (this.category == 'nonTechnical') formLink = 'registration-form?form=Registration%20Form%20Non-Technical';

    if (this.schoolCode === 'nstech196' || this.schoolCode === 'wileyedge399' || this.schoolCode === 'kscollege408' || this.schoolCode === 'iamneo372' || this.schoolCode === 'wileyedge537') {
      this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/${formLink}&eventId=${this.eventId}&eventTitle=${this.eventName}`;
    }
    else {
      this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/application-form/${this.isInEvent ? this.selectedEventId : this.selectedJobId}`;
    }
    this.bulkInviteForm.controls['link'].setValue(this.link);

    this.link = `${this.inviteUrl}`;
    this.bucketName = this.globalService.assetBucketName;
  }

  candidateSearch(event?: any) {
    this.loadingSpinner = true;
    this.searchEmailIds = (event.target.value).split(' ');
    this.searchPayload = {
      'search': this.searchEmailIds
    }
    this.getUsers();
  }


  sidebarClose() {
    this.singleInvite.reset();
    this.bulkInviteForm.reset();
    this.cohortFile = [];
    this.cohortFileCopy = [];
    this.clicked = false;
    this.sendingInvite = false;
    this.cohortUploaded = false;
    this.excel = [];
    this.bulkFile = false;
    this.loadSingleInvite = false;
    this.countDiv = false;
    this.bulkEnable = false;
    this.messageEnable = false;

  }

  bulkSelected() {
    if (this._selectedCategory === 'bulk-invite') {
      this.clicked = false;
      if (this.excel.length == 0) {
        this.bulkFile = true;
      } else {
        this.bulkFile = false;
      }
    } else if (this._selectedCategory === 'single-invite') {
      this.clicked = false;
      this.bulkFile = false;
    }
  }

  sendInvite() {
    this.loadSingleInvite = true;
    if (this.cohortUploaded) {
      let payload = {
        bucketDetails: {
          Bucket_name: this.bucketName,
          file_name: this.fileUploadPayload.file_name,
        },
        eventTitle: this.eventName,
        eventJobId: this.eventId
      }
      this.eventService.eventBulkUpload(payload).subscribe((res: any) => {
        this.loadSingleInvite = false;
        if (res.status === 200) {
            let result = res.data;
            if (result.message) {
              this.bulkValidatedData = [];
              this.messageService.add({ severity: 'success', summary: 'Success', detail: result.message });
            }
            else if (result.count.errored > 0 && result.count.validated > 0) {
              this.countDiv = true;
              this.counts = result?.count;
              this.errorFile = result.erroredFile;
              this.messageService.add({ severity: 'warn', summary: 'Partially Correct', detail: 'File is Partially Correct!' });
              this.messageEnable = true;
            }
            else if (result.count.errored > 0 && result.count.validated == 0) {
              this.countDiv = true;
              this.counts = result.count;
              this.errorFile = result.erroredFile;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All the entries are invalid!' });
              this.messageEnable = true;
            }

        }
        else if (res.status == 500) {
          this.countDiv = false;
          this.loadSingleInvite = false;
          this.messageEnable = false;
          this.fileMessage = res.error;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
        }
        else {
          this.loadSingleInvite = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
        }
      })
    }
    else {
      this.loadSingleInvite = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload a file to proceed!' });
    }
  }

  fileUpload(fileCopy: any, file: any) {
    let fileName = fileCopy.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `event/Bulk-Invite-Events/${this.schoolId}/${nanoid(10)}/${fileName}`,
      type: fileCopy.type,
    };
    const blobData: any = fileCopy;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r.status === 200) {
                this.fileUploadPayload = pay;
                this.uploadingFile = false;
                this.cohortUploaded = true;
              }
            });
        }
      });
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    if (this.isInEvent) {
      this.eventService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value], this.selectedEventId).subscribe(result => {
        this.savedColumnSettings = result.data.selectedColumns;
      })
    } else
      this.jobService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value]).subscribe(result => {
        this.savedColumnSettings = result.data.selectedColumns;
      })

    this.loadingSpinner = false;
    this.displaySaveColumnSettings = false;

  }

  getSavedColumns = () => {
    this.jobService.getJob(this.selectedJobId).subscribe(res => {
      this.savedColumnSettings = res.data?.selectedColumns;
    })
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService.deleteColumnCandidates(this.selectedJobId, columnId).subscribe(res => {
      this.savedColumnSettings = res.data?.selectedColumns;
      this.loadingSpinner = false;
    })
  }

  // Normal Functions
  addCandidateForm = () => {
    if (this.schoolCode === 'nstech196' || this.schoolCode === 'wileyedge399' || this.schoolCode === 'wileyedge537' || this.schoolCode === 'kscollege408' || this.schoolCode === 'iamneo372') {
      window.open(this.inviteUrl);
    }
    else {
      this._router.navigate(['application-form/', this.isInEvent ? this.selectedEventId : this.selectedJobId]);
    }
  }


  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  download() {
    window.open('assets/Book1.xlsx', 'Download');
  }

  bulkUp() {
    this.showBulkUpload = true;
    // this._selectedCategory = 'single-invite';
    // this.singleInvite.controls['eventCategory'].setValue(this.category);
    this.bulkInviteForm.controls['eventCategory'].setValue(this.category);
    this.setFormLink();
  }

  // handleUpload(event: any, type: string) {
  //   const file = event.addedFiles[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     if (type === 'bulk-invite') {
  //       this.fileUpload(file)
  //     }
  //   };
  // }

  cohortUpload(event: any) {
    let cohortFile = {
      file: event.addedFiles,
      time: new Date()
    }
    this.cohortFileCopy = event.addedFiles;
    this.cohortFile[0] = cohortFile;
    this.fileUpload(this.cohortFileCopy[0], this.cohortFile[0])
  }

  onRemoveExcel() {
    this.cohortUploaded = false;
    this.countDiv = false;
    this.bulkEnable = false;
    this.counts = 0;
    this.cohortFile = [];
    this.cohortFileCopy = [];
    this.bulkAcknowledgeUsers = [];
    this.existingUsers = [];
    this.bulkValidatedData = [];
    this.messageEnable = false;
    this.acknowledgeUsers = [];
  }

  errorReportDownload() {
    window.open(this.errorFile, '_blank')
  }

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  }

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  }

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._usersCol = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.usersCol.filter(e => savedColumnValue.includes(e.field));
  }

  showCandidates = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'candidates' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'candidates' } })
    this.routeChange();
  }

  showJobDetails = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'eventDetails' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'jobDetails' } })
    this.routeChange();
  }

  showWorkflow = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'workflow' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
    this.routeChange();
  }

  showInterview = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'interview' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'interview' } })
    this.routeChange();
  }

  exportCandidate = (type: string) => {
    let checkedColumnFields: any = ['name', 'id'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.candidateList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  sampleData() {

    this.eventService.eventTemplateDownload().subscribe(res => {
      if (res.status == 200) {
        console.log(res.data.fileLink)
        window.open(res.data.fileLink);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong' });
      }
    })
  }

  // Pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getUsers();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? (this.last == this.totalLength ? this.totalLength - this.candidateList.length : this.last - this.pageSize) : this.pageSize;
    this.pageNumber -= 1;
    this.getUsers();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.usersCol.filter(col => val.includes(col));
  }

  getArray(num: number) {
    return new Array(num);
  }

  copyMessage() {
    let val = new URL(this.inviteUrl).href;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.tooltip.activate();
  }
  onKeyPress(event: KeyboardEvent, user: any) {
    if (event.key === 'Enter' || event.key === ' ') {
        this.showUserDetails(user);
    }
}
}
