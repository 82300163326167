<div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div class="header-container">
    <div class="heading">
        <app-ng-dynamic-breadcrumb class="breadCrumb" bgColor="var(--clr-white)" fontSize="16px"
            fontColor="var(--fc-light-dark)" symbol=">"></app-ng-dynamic-breadcrumb>
    </div>

    <div class="header-right">
        <div [ngClass]="showSearchBar ? 'show-searchbar' : 'hide-searchbar'" class="searchBar">
            <em class="pi pi-search" style="font-size: 1.2rem;"></em>
            <p class="searchBar-text">{{searchItem}}</p>
            <input (keyup.enter)="searchList($event)" 
            [(ngModel)]="searchText" (ngModelChange)="searchTermUpdate()" class="searchBar-input" type="text" placeholder="Search {{searchItem}} ...">
        </div>
        <div class="pi pi-bell" style="font-size: 1.2rem; cursor: pointer;"></div>
        <!-- <div class="pi pi-bell" style="font-size: 1.2rem; cursor: pointer;" (click)="notificationOverlay.toggle($event)"></div>
        <p-overlayPanel #notificationOverlay [style]="{height: 'auto',minHeight: '300px', maxHeight: '400px', width: '25rem'}">
            <div class="main-cointainer">
                <div class="notification-header"[style]="{ display: 'flex',justifyContent: 'space-between',padding: '9px'}">
                    <p [style]="{fontSize: '14px',fontWeight: 'bold'}">Notifications</p>
                    <u [style]="{fontSize: '13px', cursor: 'pointer'}">Mark All as read</u>
                </div>
                <div class="notification-body">
                    <div class="notification-heading">
                        <label class="tabs" [ngClass]="{'active': activeTab === 'All'}" (click)="activeTab = 'All'">All 
                            <p class="notification-no" [ngClass]="{'active': activeTab === 'All'}"> 1</p></label>
                        <label class="tabs" [ngClass]="{'active': activeTab === 'Events'}" (click)="activeTab = 'Events'">Events
                            <p class="notification-no" [ngClass]="{'active': activeTab === 'Events'}"> 1</p></label>
                        <label class="tabs" [ngClass]="{'active': activeTab === 'Interview'}" (click)="activeTab = 'Interview'">Interview
                            <p class="notification-no" [ngClass]="{'active': activeTab === 'Interview'}"> 1</p></label>
                    </div>
                    <div class="card-container">
                        <div class="notification-card" (mouseover)="mouseHover = true" (mouseout)="mouseHover = false" 
                            [ngClass]="{'hovered': mouseHover === true}">
                            <div class="notification-pic">
                                <p-avatar class="avatar-align"
                                image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
                            </div>
                            <div class="notification-content" [style]="{width: '250px'}">
                                <div class="user-name" [style]="{paddingBottom: '3px',fontWeight: 'bold'}">R.Douglass Deva</div>
                                <div class="notification-message" [style]="{paddingBottom: '10px'}">Hello there this is testing total no. of invalid candidates 600/1000. 
                                    <u [style]="{cursor: 'pointer', color: 'blue'}" (click)="errorFileDownload()">click here to download</u></div>
                                <div class="notification-footer">
                                    <div class="event" [style]="{fontWeight: 'bold', display: 'flex',fontSize: '11px'}">Event : 
                                        <p [style]="{fontWeight: '100'}">  Sample-Event - Feb 2023</p></div>
                                    <div class="stage" [style]="{fontWeight: 'bold',display: 'flex',fontSize: '11px'}">Stage : 
                                        <p [style]="{fontWeight: '100'}">  Technical Interview</p></div>
                                </div>
                            </div>
                            <div class="notification-time">
                                <p>12.00 PM</p>
                            </div>
                        </div>
                        <div class="notification-card" (mouseover)="mouseHover = true" (mouseout)="mouseHover = false" 
                        [ngClass]="{'hovered': mouseHover === true}">
                            <div class="notification-pic">
                                <p-avatar class="avatar-align"
                                image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
                            </div>
                            <div class="notification-content" [style]="{width: '250px'}">
                                <div class="user-name" [style]="{paddingBottom: '3px',fontWeight: 'bold'}">R.Douglass Deva</div>
                                <div class="notification-message" [style]="{paddingBottom: '10px'}">Hello there this is testing total no. of invalid candidates 600/1000. <u [style]="{cursor: 'pointer', color: 'blue'}">click here to download</u></div>
                                <div class="notification-footer">
                                    <div class="event" [style]="{fontWeight: 'bold', display: 'flex',fontSize: '11px'}">Event : 
                                        <p [style]="{fontWeight: '100'}">  Sample-Event - Feb 2023</p></div>
                                    <div class="stage" [style]="{fontWeight: 'bold',display: 'flex',fontSize: '11px'}">Stage : 
                                        <p [style]="{fontWeight: '100'}">  Technical Interview</p></div>
                                </div>
                            </div>
                            <div class="notification-time">
                                <p>12.00 PM</p>
                            </div>
                        </div>
                        <div class="notification-card">
                            <div class="notification-pic">
                                <p-avatar class="avatar-align"
                                image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
                            </div>
                            <div class="notification-content" [style]="{width: '250px'}">
                                <div class="user-name" [style]="{paddingBottom: '3px',fontWeight: 'bold'}">R.Douglass Deva</div>
                                <div class="notification-message" [style]="{paddingBottom: '10px'}">Hello there this is testing total no. of invalid candidates 600/1000. <u [style]="{cursor: 'pointer', color: 'blue'}">click here to download</u></div>
                                <div class="notification-footer">
                                    <div class="event" [style]="{fontWeight: 'bold', display: 'flex',fontSize: '11px'}">Event : 
                                        <p [style]="{fontWeight: '100'}">  Sample-Event - Feb 2023</p></div>
                                    <div class="stage" [style]="{fontWeight: 'bold',display: 'flex',fontSize: '11px'}">Stage : 
                                        <p [style]="{fontWeight: '100'}">  Technical Interview</p></div>
                                </div>
                            </div>
                            <div class="notification-time">
                                <p>12.00 PM</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </p-overlayPanel> -->
        <div (click)="logoutPanel.toggle($event)" class="profile">
            <p-avatar class="avatar-align"
                image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
            <div class="wrap-text">
                <p [style]="{lineHeight: '17px', fontFamily: 'Poppins'}">{{userName}}</p>
            </div>
            <!-- <p (click)="signOut()">SignOut</p> -->
        </div>
        <p-overlayPanel #logoutPanel>
            <span (click)="signOut(); logoutPanel.hide()" class="logout-panel">Logout <em
                    class="pi pi-sign-out sign-out"></em></span>
        </p-overlayPanel>
    </div>
</div>