import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { EventServicesService } from "../services/event-services.service";
import { ActivatedRoute } from "@angular/router";
import { SocketService } from "../services/socket.service";
import { JobServicesService } from "../services/job-services.service";
import * as moment from 'moment';
import { environment } from "src/environments/environment";
import { GlobalServicesService } from "../services/global-services.service";
import { MessageService } from "primeng/api";
declare var JitsiMeetExternalAPI: any;
@Component({
    selector: 'app-candidate-panel',
    templateUrl: './candidate-panel.component.html',
    styleUrls: ['./candidate-panel.component.css'],
    providers: [MessageService]
})

export class CandidatePanelComponent implements OnDestroy, OnInit {
    eventId: string = '';
    userId: string = '';
    schoolId: string = '';
    jitsiAPI: any;
    interviewStarted: boolean = false;
    userEventData: any;
    stageIdNo: any;
    isLoading: boolean = true;
    stageId: string = '';
    unscheduleTimeErr: boolean = false;
    meetingEnded: boolean = false;
    loadIDE: boolean = false;
    scheduleDT: any;
    dateFormat: any;
    timeFormat: any;
    combainFormat: any;
    currentStatus: string = "";
    candidateEmail: string = '';
    candidateMobile: string = '';
    candidateProfilePic: string = '';
    meetingStatus: any;
    reloadInterview: boolean = false;
    mailVerified: boolean = false;
    mobileVerified: boolean = false;
    private serviceSubs: any[] = [];
    ENV = environment.HOST.NODE_ENV;
    mediaAccess: boolean = true;
    interviewMeeting: any;
    verificationRequired: boolean = true;
    enableScreen: boolean = true;

    constructor(private eventServices: EventServicesService,
        private activatedRoute: ActivatedRoute,
        private socketService: SocketService,
        private jobService: JobServicesService,
        private globalService: GlobalServicesService,
        private messageService: MessageService
    ) { }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        if (this.currentStatus == 'Lobby') {
            this.updateInterviewStatus("Invited")
        }
    };

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: any) {
        this.reloadInterview = true;
        localStorage.setItem('meetingStatus', this.meetingStatus);
        return true;
    }

    ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';

    this.timeFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.time_format : '';

    this.combainFormat = this.timeFormat == 'hh:mm' ? (this.dateFormat).toUpperCase()  + '  '+ this.timeFormat + ' A' : (this.dateFormat).toUpperCase()  + '  '+ this.timeFormat;

        this.activatedRoute.queryParamMap.subscribe(async (params) => {
            this.eventId = params.get('eventId') || '';
            this.userId = params.get('userId') || '';
            this.schoolId = params.get('schoolId') || '';
            this.stageId = params.get('stageId') || '';

            this.eventServices.getUserUnAuth(this.userId).subscribe(res => {
                this.candidateEmail = res.data.email;
                this.candidateProfilePic = res.data.profilePic;
                this.candidateMobile = res.data.countryCode + res.data.mobile_number;
            })
            this.isLoading = false;
            this.jobService.getCandidateStage(this.eventId, this.stageId, this.schoolId).subscribe((stageData) => {
                if (stageData && stageData?.data?.stageId) {
                    let stageIdNo = stageData.data.stageId
                    this.eventServices.getSpcCandidateEntry({
                        eventId: this.eventId,
                        userId: this.userId,
                        schoolId: this.schoolId,
                        stageId: stageIdNo
                    }).subscribe((response) => {
                        if (response?.data?.data) {
                            this.interviewMeeting = response.data.data.status;
                            if(this.interviewMeeting == 'meetingEnd'){
                                this.enableScreen = false;
                                this.verificationRequired = false;
                                this.initiateFuntion();
                            }else{
                                this.enableScreen = false;
                                this.verificationRequired = true;
                            }
                        }
                    })
                    
                }
            })
            // this.initiateFuntion();

        });
    }

    initiateFuntion(verifyEvent?: boolean){
        /* Add the canidate entry in the table  */
        if (this.userId && this.eventId && this.schoolId && this.stageId) {
            this.jobService.getCandidateStage(this.eventId, this.stageId, this.schoolId).subscribe((stageData) => {
                if (stageData && stageData?.data?.stageId) {
                    this.stageIdNo = stageData.data.stageId
                    this.eventServices.getSpcCandidateEntry({
                        eventId: this.eventId,
                        userId: this.userId,
                        schoolId: this.schoolId,
                        stageId: this.stageIdNo
                    }).subscribe(async (response) => {
                        if (response?.data?.data) {
                            this.userEventData = response.data.data;
                            if(verifyEvent){
                                this.mailVerified = verifyEvent;
                                this.mobileVerified = verifyEvent;
                            }
                            this.isLoading = false;
                            let locStatus = localStorage.getItem('meetingStatus');
                            if (locStatus) {
                                this.meetingStatus = locStatus;
                            } else {
                                this.meetingStatus = this.userEventData.status;
                            }
                            if (this.userEventData.status == 'meetingEnd') this.meetingEnded = true;
                            if (this.userEventData.status == 'InMeeting' || this.meetingStatus == 'InMeeting') {
                                this.currentStatus = this.userEventData.status;
                                this.socketService.connect(this.eventId, this.userId);
                                //this.checkMediaAccess();
                                this.initiateCall();
                                // return;
                            } else {
                                if (!this.meetingEnded) {
                                    let currentDT = new Date().getTime();
                                    let meetingDT = new Date(`${this.userEventData.interviewDate.substring(0, 10)} ${this.userEventData.startTime} UTC`).getTime();
                                    this.scheduleDT = moment(new Date(`${this.userEventData.interviewDate.substring(0, 10)}  ${this.userEventData.startTime} UTC`)).format(this.combainFormat)
                                    let meetDiff = Math.round((currentDT - meetingDT) / 60000)
                                    if (meetDiff >= -15) {
                                        let updateStatus: any = await this.updateInterviewStatus('Lobby');
                                        this.isLoading = false;
                                        if (updateStatus) {
                                            // Connect to the socket 
                                            this.currentStatus = 'Lobby';
                                            this.socketService.connect(this.eventId, this.userId);
                                        } else {
                                            alert('Something went wrong1. Please contact Adminstrator')
                                        }
                                    } else {
                                        // alert('Please join in the schedule time');
                                        this.isLoading = false;
                                        this.unscheduleTimeErr = true;
                                    }
                                    // Connect to the socket 
                                    this.socketService.connect(this.eventId, this.userId);
                                    //this.checkMediaAccess();
                                    this.socketHandler();
                                }
                            }
                        } else {
                            alert('Something went wrong. Please contact Adminstrator');
                            this.isLoading = false;
                        }
                    });
                } else {
                    alert('Something went wrong. Please contact Adminstrator');
                    this.isLoading = false;
                }
            })

        } else {
            alert('Not a valid URL please check');
            this.isLoading = false;
        }
    }


    private socketHandler() {
        const sub = this.socketService.messageRecieved.subscribe((message: any) => {
            if (message && message.action) {
                switch (message.action) {
                    case 'call_Initiated': {
                        this.initiateCall();
                        break;
                    }
                }
            }
        });
        this.serviceSubs.push(sub);
    }

    public initiateCall() {
        // this.checkMediaAccess();
        const roomName = this.userEventData.roomName;
        this.interviewStarted = true;
        this.eventServices.getJitsiToken(this.userEventData.name, roomName, true, this.schoolId, this.candidateProfilePic).subscribe(response => {
            const jitsi_div: any = document.getElementById('jaas-container');
            if (!this.jitsiAPI) {
                this.jitsiAPI = new JitsiMeetExternalAPI("8x8.vc", {
                    roomName: response.roomName,
                    parentNode: document.querySelector('#jaas-container'),
                    configOverwrite: {
                        apiLogLevels: ['error'],
                        prejoinPageEnabled: false,
                        startWithAudioMuted: false,
                        startWithVideoMuted: false,
                        hideParticipantsStats: true,
                        hideConferenceTimer: true,
                        disableTileView: true,
                        startScreenSharing: false,
                        toolbarButtons: ['desktop', 'raisehand', 'fullscreen']
                    },

                    interfaceConfigOverwrite: {
                        VIDEO_QUALITY_LABEL_DISABLED: true,
                        FILM_STRIP_MAX_HEIGHT: 0
                    },
                    userInfo: {
                        displayName: this.userEventData.name,
                        email: this.userEventData.email
                    },
                    jwt: response.jwt_token
                });

                this.jitsiAPI.addListener('videoConferenceJoined', async () => {
                    this.meetingStatus = 'InMeeting';
                    this.reloadInterview = false;
                    this.updateInterviewStatus("InMeeting");
                    this.jitsiAPI.executeCommand('toggleShareScreen');
                    let roomInfo = await this.jitsiAPI.getRoomsInfo();
                    if (roomInfo?.rooms?.[0]?.participants?.length) {
                        roomInfo.rooms[0].participants.forEach((element: any) => {
                            if (element.displayName == this.userEventData.name && element.role == 'participant') {
                                this.jitsiAPI.executeCommand('kickParticipant', element.id);
                            }
                        });
                    }
                });

                this.jitsiAPI.addListener('videoConferenceLeft', () => {
                    this.meetingStatus = 'meetingEnd';
                    this.updateInterviewStatus("meetingEnd");
                    this.interviewStarted = false;
                    this.jitsiAPI.dispose();
                    this.jitsiAPI = undefined;
                    this.meetingEnded = true;
                    jitsi_div.style.display = 'none';
                });

                this.jitsiAPI.addListener('participantLeft', async () => {
                    let rooomInfo = await this.jitsiAPI.getRoomsInfo();
                    if (rooomInfo?.rooms?.[0]?.participants?.length == 1) {
                        this.meetingStatus = 'meetingEnd';
                        this.updateInterviewStatus("meetingEnd")
                        this.interviewStarted = false;
                        this.jitsiAPI.dispose();
                        this.jitsiAPI = undefined;
                        this.meetingEnded = true;
                        jitsi_div.style.display = 'none';
                    }
                })
            }
        });
    }

    public updateInterviewStatus(status: string) {
        return new Promise((resolve) => {
            let payload = {
                id: this.userEventData.id,
                status: status,
                userId: this.userId,
                eventId: this.eventId,
                schoolId: this.schoolId,
                stageId: this.stageIdNo
            };

            this.eventServices.updateInterviewStatus(payload).subscribe((response) => {
                if (!response) {
                    alert('Updation fail, Please contact Adminstrator');
                    resolve(false);
                } else {
                    this.currentStatus = status;
                    resolve(true);
                }
            });
        })
    };

    openIDE() {
        this.loadIDE = true;
        const schoolCode = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_details?.subdomain : '';
        let payload = {
            email: this.candidateEmail,
            apikey: 'openide',
            school_code: schoolCode
        }
        let target_host = 'examly.io';
        if (this.ENV === 'dev') {
            target_host = 'exam.ly';
        } else if (this.ENV === 'acc') {
            target_host = 'examly.net';
        }
        this.globalService.getOpenIde(payload).subscribe(res => {
            this.loadIDE = false;
            if (res.data.tokenid) {
                let tokenId = res.data.tokenid;
                let url = `http://${schoolCode}.${target_host}/loginredirect?email=${this.candidateEmail}&tokenid=${tokenId}&ide=ide&eventId=${this.eventId}&userId=${this.userId}&stageId=${this.stageIdNo}`;
                window.open(url, '_blank');
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong! Please try again.' })
            }
        })
    }

    public makeSound() {
        let audio = new Audio('../../assets/audio/participant-joined-3.wav');
        audio.play();
    };

    checkMediaAccess() {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        })
            .then((resp: any) => {
                this.mediaAccess = true;
            })
            .catch((err: any) => {
                this.mediaAccess = false;
                if (confirm("Kindly enable microphone and camera access in your browser")) {
                    setTimeout(() => { this.checkMediaAccess(); }, 5000);
                } else {
                    setTimeout(() => { this.checkMediaAccess(); }, 5000);
                }
            })
    }
}