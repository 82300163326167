<div class="pageInfo">
    <div style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
        <div style="display: flex;justify-content:space-between; align-items: center;">
            <em class="pi pi-map-marker"></em>
            <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Branches</p>
        </div>
        <div>
            <button style="height:38px; font-size:var(--fs-medium)" pButton pRipple type="submit" label="Add New"
                (click)="openAddTemplate()"></button>
        </div>
    </div>
</div>
<div class="info-card">
    <div style="padding:20px 20px 50px 20px" *ngFor="let branch of branchData; index as i">
        <div
            style="padding: 20px 20px 10px 20px; display: flex; justify-content: space-between;align-items: center; border:1px solid var(--clr-primary)">
            <p style="font-size: var(--fs-large);font-weight:var(--fw-semi-bold)">{{ branch.name }}</p>
            <div>
                <em style="margin-right: 10px;padding:10px; background-color:  rgba(102, 102, 102, 0.05);;border-radius: 4px;cursor: pointer;"
                    class="pi pi-trash" (click)="deleteBranch(branch.id)" (keydown)="handleKeyDown($event, branch.id)"></em>
                <em style="margin: 10px;padding:10px;background-color:  rgba(102, 102, 102, 0.05);;border-radius: 4px;cursor: pointer;"
                    class="pi pi-pencil" (click)="editBranch(branch.id)" (keydown)="handleKeyDown($event, branch.id)"></em>
            </div>
        </div>
        <div style="padding:20px;  
        border-left:    1px solid var(--clr-primary);
        border-right:  1px solid var(--clr-primary); 
        border-bottom: 1px solid var(--clr-primary);font-size: var(--fs-medium)">
            <div style="display: flex;margin-bottom: 14px;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-map-marker"></em>
                <p>{{ branch.address_line_1 }} {{ branch.address_line_2 }} {{ branch.area }} {{ branch.city }} {{ branch.state }} {{ branch.postal_code }}</p>
            </div>
            <div style="display: flex;margin-bottom: 10px;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-phone"></em>
                <p>{{ branch.mobile_number }}</p>
            </div>
            <div style="display: flex;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-at"></em>
                <p>{{ branch.email }}</p>
            </div>
        </div>
    </div>
</div>
<p-sidebar class="addTemplate-slider" [(visible)]="addTemplateSidebar"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
    (onHide)="branchDetails.reset(); showPlaceholder = false">
    <div class="addTemplate-header">
        <div class="addTemplate-heading"><em style="margin-right: 12.5px;" class="pi pi-map-marker"></em>Add New Branch
        </div>
        <div class="header-btn">
            <button pButton pRipple type="submit" label="Cancel" (click)='cancel()'
                class="p-button-outlined button-align save-btn"></button>
            <button pButton pRipple type="submit" label="Save" (click)="saveTemplate()"
                class="p-button-raised p-button-text button-align" [loading]="saveBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    <div class="templateForm" [formGroup]="branchDetails">

        <div class="form-salary branchForm-elements">
            <span class="p-input-icon-right p-float-label branchForm-elements">
                <input class="inputtext" style="width: 100%" type="text" pInputText inputId="text"
                    formControlName="name" [(ngModel)]="branchName"
                    [ngClass]="{ 'p-filled': branchName, 'invalid': clicked && branchDetails.controls['name'].errors}"
                    [style]="{ cursor: 'pointer' }" />
                <label for="dropdown">Branch Name<span [style]="{ color: '#E24A47' }"> *</span></label>
                <app-handle-error [clicked]="clicked" [control]="branchDetails.get('name')"
                    name="Branch Name"></app-handle-error>
            </span>
        </div>
        <span class="p-float-label">
            <p-inputMask id="inputtext" mask="9999999999" formControlName="mobile_number">
            </p-inputMask>
            <label for="inputtext">Mobile Number</label>
            <app-handle-error [clicked]="clicked" [control]="branchDetails.get('mobile_number')"
                name="Mobile Number"></app-handle-error>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="email"
                [ngClass]="{'p-filled': branchDetails.value.email, 'invalid': clicked && branchDetails.controls['email'].errors}">
            <label for="template-title">Official Email ID <span [style]="{color: '#E24A47'}"> *</span></label>
            <div *ngIf="(clicked && branchDetails.get('email')?.hasError('pattern'))">
                <span class="error-msg"> Enter a valid Email ID </span>
            </div>
            <app-handle-error [clicked]="clicked" [control]="branchDetails.get('email')"
                name="Official Email ID"></app-handle-error>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="address_line_1"
                [ngClass]="{'p-filled': branchDetails.value.address_line_1, 'invalid': clicked && branchDetails.controls['address_line_1'].errors}">
            <label for="template-title">Address Line 1 <span [style]="{color: '#E24A47'}"> *</span></label>
            <app-handle-error [clicked]="clicked" [control]="branchDetails.get('address_line_1')"
                name="Address Line 1"></app-handle-error>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="address_line_2"
                [ngClass]="{'p-filled': branchDetails.value.address_line_2}">
            <label for="template-title">Address Line 2</label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="area"
                [ngClass]="{'p-filled': branchDetails.value.area}">
            <label for="template-title">Area</label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="city"
                [ngClass]="{'p-filled': branchDetails.value.city}">
            <label for="template-title">City</label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="state"
                [ngClass]="{'p-filled': branchDetails.value.state}">
            <label for="template-title">State</label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="postal_code"
                [ngClass]="{'p-filled': branchDetails.value.postal_code}">
            <label for="template-title">Postal Code</label>
        </span>
    </div>
</p-sidebar>