import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { TalentPoolService } from 'src/app/services/talent-pool.service';
import { nanoid } from 'nanoid'
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-invite-candidate',
    templateUrl: './invite-candidate.component.html',
    styleUrls: ['./invite-candidate.component.css'],
    providers: [MessageService]
})
export class InviteCandidateComponent implements OnInit {
  @Output() inviteSent = new EventEmitter<any>()
  @Output() bulkInviteSent = new EventEmitter<any>()

  showEditor: boolean = true;
  formModified: boolean = false;
  _selectedCategory: string = 'single-invite';
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  eventCategories: any = [];
  loadSingleInvite: boolean = false;
  loadExistingUser: boolean = false;
  userExist: boolean = false;
  cohortUploaded: boolean = false;
  uploadingFile: boolean = false;
  countDiv : boolean = false;
  clicked: boolean = false;
  eventList: any = [];
  genderList: any = [];
  sourceTypeList: any = [];
  sourceNameList: any = [];
  existingUserList: any = [];
  existingUserColumns: any = []
  cohortFileSize: any;
  requiredColumns: Array<any> = [];
  existingRequiredColumns: Array<any> = [];
  cohortFile: Array<any> = [];
  cohortFileCopy: Array<any> = [];
  errorFileData: any;
  errorFile: any;
  templateValue: Array<any> = [];
  counts: any;
  bulkValidatedData: any = [];
  existingUsers: any;
  mediaFile: File[] = [];
  loadEventList: boolean = false;
  allCategoryEvents: any;
  disableInvite: boolean = false;
  bulkEnable: boolean = false;
  messageEnable : boolean = false;
  fileMessage: any;
  fileUploadPayload : any;

  currentDate = new Date();
  userPrivileges: Set<string> = new Set();
  companyId: string = '';
  schoolId: string = '';
  recruiterId: number | null = null;
  errorFileUrl: string | null = null;
  bucketName: string = this.globalService.bucketName;
  static ageValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return { 'ageInvalid': true }; 
    let dateOfBirth: Date;
    if (typeof control.value === 'string') {
      dateOfBirth = new Date(control.value);
    } else {
      dateOfBirth = control.value;
    }
    if (isNaN(dateOfBirth.getTime())) return { 'ageInvalid': true }; 
    const today = new Date();
    const birthYear = dateOfBirth.getFullYear();
    const birthMonth = dateOfBirth.getMonth();
    const birthDay = dateOfBirth.getDate();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();
    let age = currentYear - birthYear;
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--; 
    }
  
    return age < 14 ? { 'ageInvalid': true } : null; // Age should be at least 14
  };
  inviteForm: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    mobileNumber: new FormControl(null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    eventCategory: new FormControl(null, Validators.required),
    gender: new FormControl(null, Validators.required),
    date_of_birth: new FormControl(null, [Validators.required, InviteCandidateComponent.ageValidator]),
    eventName: new FormControl(null, Validators.required),
    eventId: new FormControl('', Validators.required),
    sourceType: new FormControl(null, Validators.required),
    sourceName: new FormControl(null, Validators.required),
  })

  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl('',Validators.required),
    eventCategory: new FormControl('', Validators.required),
    eventName: new FormControl('', Validators.required),
    actionId: new FormControl('')
  })

    constructor(
      private talentPoolService: TalentPoolService, 
      private messageService: MessageService,
      private fileUploadService: FileUploadService,
      private globalService: GlobalServicesService,
      private http: HttpClient
      ) {
       
      this.getInviteFormData();
      this.genderList = [
        { label: 'Male', value: 'male'},
        { label: 'Female', value: 'female'},
        { label: 'Other', value: 'other'},
      ]
        
      this.existingUserColumns = [
        { field: 'firstName', header: 'First Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'mobileNumber', header: 'Mobile Number' },
        { field: 'eventCategory', header: 'Event Category' },
        { field: 'invitedEvents', header: 'Existing Events' },
        { field: 'sourceType', header: 'Source Type' },
        { field: 'sourceName', header: 'Source Name' },
      ]

      this.requiredColumns = [
        { field: 'firstName', header: 'First Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'email', header: 'Email' },
        { field: 'countryCode', header: 'Country Code' },
        { field: 'mobileNumber', header: 'Mobile Number' },
        { field: 'eventCategory', header: 'Event Category' },
        { field: 'invitedEvents', header: 'Existing Events' },
        { field: 'eventName', header: 'Event Name' },
        { field: 'eventId', header: 'Event ID' },
        { field: 'sourceType', header: 'Source Type' },
        { field: 'sourceName', header: 'Source Name' },
        ]

      this.existingRequiredColumns = [
        { field: 'firstName', header: 'First Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'email', header: 'Email' },
        { field: 'countryCode', header: 'Country Code' },
        { field: 'mobileNumber', header: 'Mobile Number' },
        { field: 'eventCategory', header: 'Event Category' },
        { field: 'eventName', header: 'Event Name' },
        { field: 'eventId', header: 'Event ID' },
        { field: 'sourceType', header: 'Source Type' },
        { field: 'sourceName', header: 'Source Name' },
        ]
     }
  getInviteFormData = async () => {
    this.talentPoolService.getCandidateInviteFormDataFastApi('event_id').subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          const mapForms = (forms: any[]) => forms.map((element: any) => ({
            label: element.name,
            value: element.id,
          }));
          const eventCategories = res.body.event_categories || [];
          this.eventCategories = mapForms(eventCategories);
          const sourceTypeList = res.body.candidate_source_types || [];
          this.sourceTypeList = mapForms(sourceTypeList);
          const sourceNameList = res.body.candidate_sources || [];
          this.sourceNameList = mapForms(sourceNameList);
        }
      },
      error: () => {
      }
    });
  }

    ngOnInit() {
      this.companyId = localStorage.getItem('companyUUID') ?? '';
      this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
        this.userPrivileges = privileges;
      }); 
      this.globalService.getUserProfileFastApi().subscribe({
        next: (res: any) => {
          if (res.status === 200 && res.body?.auth_user_details?.id) {
            this.recruiterId = res.body.auth_user_details.id;
          }
        },
        error: (err) => {
          console.error('Error fetching recruiter ID:', err);
        }
      });
      // this.getAllCategoryEvents();
      this.schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '',
      this.inviteForm.valueChanges.subscribe(() => {
        if (this.userExist) {
          this.disableInvite = false; 
        }
        this.inviteForm.valueChanges.subscribe(() => {
          this.disableInvite = false; 
        });
      });  
    }
    formatDate(dateString: string): string {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
    }
    sendInvite(event?: Event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (this.disableInvite || this.loadSingleInvite) return;
      this.disableInvite = true;
      this.loadSingleInvite = true;
      this.errorFile = null;
      
      if (!this.recruiterId) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Recruiter ID not found' });
        return;
      }
    
      if (this._selectedCategory === 'single-invite') {
        let payload = {
          first_name: this.inviteForm.value.firstName,
          last_name: this.inviteForm.value.lastName,
          email: this.inviteForm.value.email,
          gender: this.inviteForm.value.gender.value,
          date_of_birth: this.formatDate(this.inviteForm.value.date_of_birth),
          mobile_number: this.inviteForm.value.mobileNumber,
          candidate_source_id: this.inviteForm.value.sourceName.value,
          candidate_source_type_id: this.inviteForm.value.sourceType.value,
          recruiter_id: this.recruiterId,
        };
        this.talentPoolService.candidateSingleInviteFastApi(this.inviteForm.value.eventName.value, payload).subscribe({
          next: (res: any) => {
            this.loadSingleInvite = false;
            if (res.status === 201) {
              this.userExist = false;
              this.existingUserList = [];
              this.inviteForm.reset();
              this.disableInvite = true;
              this.inviteSent.emit({ severity: 'success', summary: 'Success', detail: res.body.detail });
            } else if (res?.body?.data?.message === 'User exist in other events') {
              this.userExist = true;
              this.disableInvite = true;
              this.existingUserList = res?.body?.data?.body?.data || [];
              this.messageService.add({ severity: 'info', summary: 'Info', detail: res.body.data.message });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
              this.disableInvite = true;
            }
          },
          error: () => {
            this.loadSingleInvite = false;
            this.disableInvite = true;
          }
        });
      } else if (this._selectedCategory === 'bulk-invite') {
        if (!this.cohortUploaded) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload a file to proceed!' });
          this.resetInviteState();
          return;
        }
    
        let payload = {
          file_name: this.fileUploadPayload.file_name,
          recruiter_id: this.recruiterId,
        };
    
        const eventId = this.bulkInviteForm.value.eventName?.value || this.bulkInviteForm.value.eventName;
        if (!eventId || typeof eventId !== 'string') {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Event ID is invalid' });
          this.resetInviteState();
          return;
        }
    
        this.talentPoolService.bulkInviteFastApi(payload, eventId).subscribe({
          next: (res: any) => {
            this.resetInviteState();
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bulk Invite Sent Successfully!' });
            this.clearBulkInviteCache();
          },
          error: (error: any) => {
            this.resetInviteState();
            const errorResponse = error.error;
    
            if (errorResponse?.error_file) {
              this.errorFileUrl = errorResponse.error_file;
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Some records have errors. Download the error report.',
              });
              return;
            }
            if (errorResponse.detail === "File not found") {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'File not found. Please try uploading again.'
              });
              return;
            }
            if (error.status === 500) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Upload data in the provided sample format.'
              });
              return;
            }
    
    
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: errorResponse?.detail || 'Something went wrong.',
            });
          }
        });
      }
    }
    resetInviteState() {
      this.loadSingleInvite = false;
      this.disableInvite = false;
    }
    
    clearBulkInviteCache() {
      this.bulkInviteForm.reset();
      this.bulkInviteForm.controls['file'].setValue('');
      this.cohortUploaded = false;
      this.fileUploadPayload = null;
      this.bulkInviteForm.controls['eventName'].setValue('');
      this.bulkInviteForm.controls['eventCategory'].setValue('');
    }

    getAllEvents(event: any) {
      this.loadEventList = true;
      this.eventList = [];
      this.inviteForm.controls['eventName'].setValue(null);
      this.bulkInviteForm.controls['eventName'].setValue(null);
      this.inviteForm.controls['eventId'].setValue('');
      this.bulkInviteForm.controls['eventCategory'].setValue(event.value);
      let categoryId = event?.value?.value || event?.value;
      if (!categoryId || (typeof categoryId !== 'string' && typeof categoryId !== 'number')) {
          this.loadEventList = false;
          return;
      }  
      this.talentPoolService.getAllEventsByCategory(categoryId).subscribe({
          next: (res: any) => {  
              if (res.status === 200 && res.body?.items) {
                  const events = res.body.items;
                  if (Array.isArray(events) && events.length > 0) {
                      this.eventList = events.map((event: any) => ({
                          label: event.name,
                          value: event.uuid,
                      }));
                  } else {
                      this.eventList = [{ label: "No events found", value: null }];
                  }
              } else {
                  this.eventList = [{ label: "No events found", value: null }];
              }
              this.loadEventList = false;
          },
          error: (error) => {
              this.eventList = [{ label: "No events found", value: null }];
              this.loadEventList = false;
          }
      });
  }
  
    getEventName(eventId: string):string{
      let eventName = '';
      this.allCategoryEvents.forEach((item:any) => {
        if(item.eventId == eventId){
          eventName = item.eventTitle;
        }
      });
      return eventName;
    }

    getAllCategoryEvents = async() => {
      let techEvents = [];
      let nonTechEvents = [];

      let getTechEvents = () => {
        return new Promise((resolve, rejects) => {
          this.talentPoolService.getAllEventsByCategory('technical').subscribe(res => {
            techEvents = res?.data ? res.data : [];
            return resolve(techEvents)
          })
        })
      }
      let getNonTechEvents = () => {
        return new Promise((resolve, rejects) => {
          this.talentPoolService.getAllEventsByCategory('nonTechnical').subscribe(res => {
            nonTechEvents = res?.data ? res.data : [];
            return resolve(nonTechEvents);
          })
        })
      }

      this.allCategoryEvents = (await Promise.all([getTechEvents(), getNonTechEvents()])).flat();
    }

    restrictNumber(event: any) {
      let e: any = event || window.event; 
      let key = e.keyCode || e.which;
  
      if (key < 48 || key > 57 || event.target.value.length >= 10) {
          if (e.preventDefault) e.preventDefault();
          e.returnValue = false;
      }
    }

    //Bulk Invite
    sampleData() {
      this.talentPoolService.bulkInviteFileDownloadFastApi().subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            // Create a Blob from the response body
            const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
            // Extract the filename from Content-Disposition header
            const contentDisposition = res.headers.get('content-disposition');
            let filename = 'bulk_invite_data.xlsx';  // Default filename
    
            if (contentDisposition) {
              const matches = contentDisposition.match(/filename="(.+)"/);
              if (matches?.[1]) {
                filename = matches[1];
              }
            }
    
            saveAs(blob, filename);
          }
        },
        error: (err) => {
          console.error('Error downloading the file:', err);
        }
      });
     
    }
  
    onRemoveExcel(){
      this.cohortUploaded = false;
      this.countDiv = false;
      this.bulkEnable = false;
      this.counts = 0;
      this.cohortFile = [];
      this.cohortFileCopy = [];
      this.existingUsers = [];
      this.bulkValidatedData = [];
      this.messageEnable = false;
      this.errorFileUrl = null;  
    }

    errorReportDownload() {
      if (this.errorFileUrl) {
        window.open(this.errorFileUrl, '_blank');
      }
    }

    cohortUpload(event: any) {
      let file = event.addedFiles[0];
      if (!file) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No file selected' });
        return;
      }
    
      if (file.size > 2 * 1024 * 1024) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File size should be less than 2MB' });
        return;
      }
    
      const generatedFileName = this.generateFileName(file);
      const gcsFilePath = `temp/${this.companyId}/${generatedFileName}`;
    
      const payload = {
        file_path: gcsFilePath,
        bucket_type: "private",
      };
    
      this.uploadingFile = true;
    
      this.fileUploadService.getSignedUrlGCS(payload).subscribe({
        next: (res: any) => {
          if (res?.body?.signed_url) {
            this.uploadFileToGCS(res.body.signed_url, file, payload, generatedFileName);
          } else {
            this.uploadingFile = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get signed URL' });
          }
        },
        error: () => {
          this.uploadingFile = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error fetching signed URL' });
        }
      });
    }    
    
    uploadFileToGCS(signedUrl: string, file: any, payload: any,updatedName:string) {
      const updatedPayload = {
        ...payload,
        file_name: updatedName,
        recruiter_id: this.recruiterId
      };
    
      this.fileUploadService.uploadUsingSignedUrlGCS(signedUrl, file).subscribe({
        next: (response: any) => {
          if (response.status === "success") {
            this.fileUploadPayload = updatedPayload;
            this.uploadingFile = false;
            this.cohortUploaded = true;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: "File uploaded." });
          }
        },
        error: (error) => {
          this.uploadingFile = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file.' });
        }
      });
    }
    generateFileName(file: any): string {
      let fileName = file.name.split(".");
      let fileExtension = fileName.pop();
      const timestamp = new Date().getTime();
      return `${fileName.join("").replace(/\s/g, "").replace(/[^\w\s]/gi, '')}_${timestamp}.${fileExtension}`;
    }    

    removeUploadedFile() {
      this.cohortUploaded = false;
      this.fileUploadPayload = null;
      this.messageService.add({ severity: 'info', summary: 'File Deleted', detail: 'Uploaded file has been removed' });
    }
    eventChange(event: any) {
      this.inviteForm.controls['eventId'].setValue(event.value);
      this.bulkInviteForm.controls['eventName'].setValue(event.value);
    }
}
