<div class="userInfo-container">
    <div class="profileCard-container">
        <div class="profileImg">
            <img src="{{ selectedUser?.profilePic ? signedProfileUrl : 'https://ediindia.ac.in/alumni/web/profile.jpg'}}"
                alt="profile">
        </div>
        <div class="userDetails">
            <p class="userName">{{selectedUser.name}}</p>
            <div class="userInfo">
                <p class="userInfo-text"><em class="pi pi-id-card"></em> {{selectedUser?.id}}</p>
                <span class="divider"></span>
                <p class="userInfo-text"><em class="pi pi-at"></em> {{selectedUser?.email}}</p>
                <span class="divider"></span>
                <p class="userInfo-text" *ngIf="selectedUser?.mobile_number"><em class="pi pi-phone"></em> +91
                    {{selectedUser?.mobile_number}}</p>
            </div>
        </div>
        <div *ngIf="!disableStage" class="userStatus">
            <div>
                <round-progress class="round-progress"
                [radius]="32"
                [stroke]="6"
                [background]="'#DFE3E7'"
                [current]="+(selectedUser?.stage?.current_stage || 0)"
                [max]="+(selectedUser?.stage?.total_stage || 1)"
                [rounded]="true"
                [semicircle]="true"
                [color]="selectedUser?.stage?.current_stage === selectedUser?.stage?.total_stage 
                    ? 'green' 
                    : selectedUser?.stage?.current_stage / selectedUser?.stage?.total_stage > 0.5 
                        ? 'blue' 
                        : 'blue'">
            </round-progress>            
            </div>
            <div [style]="{position: 'relative', top: '-15px', color: 'black'}">
                {{ selectedUser?.stage?.current_stage || 0 }}/{{ selectedUser?.stage?.total_stage || 1 }}
            </div>            
            <span  [attr.data-status]="selectedUser.status +'-tag'" class="status-tag">
                {{candidateStatus[selectedUser.status]}}
            </span>
        </div>
    </div>
    <div class="access-container">
        <div class="tab-menu">
            <span class="tabs {{displayInfo ? 'selected' : ''}}" (click)="showInfo()">Info</span>
            <span class="tabs {{displayResume ? 'selected' : ''}}" (click)="showResume()">Resume</span>
            <span *ngIf="!disableFeedback" class="tabs {{displayFeedback ? 'selected' : ''}}" (click)="showFeedback()">Feedback</span>
            <span *ngIf="!disableActivity" class="tabs {{displayActivity ? 'selected' : ''}}" (click)="showActivity()">Activity</span>
            <span class="tabs {{displayDetails ? 'selected' : ''}}" (click)="showDetails()">Documents</span>
        </div>
        <div class="stage-name-container" *ngIf="selectedUser.stageName && !disableStage">
            <div>
                <p class="stage-heading">Current Stage : </p>
            </div>
            <div style="padding-left: 10px;">
                <p class="stage-type">{{selectedUser.stageName}}</p>
            </div>
        </div>
    </div>
    <div [ngClass]="{'hidden': !displayDetails}" class="details-section">
        <!-- Registration Section -->
        <div class="details-card">
            <div class="details-header">
                <p class="heading">Registration Details</p>
                <i class="pi pi-ellipsis-v" (click)="toggleMenu('registration')"></i>
            </div>
            <p><strong>Submitted Date:</strong> 
                {{ registrationSubmittedDate && registrationSubmittedDate !== '-' ? (registrationSubmittedDate | date: dateFormat) : '-' }}
            </p>
            <div *ngIf="showRegistrationMenu" class="menu-options inline-menu">
                <p (click)="viewRegistration()" [class.disabled]="!registrationFormId">View Details</p>
                <p (click)="downloadRegistrationDetails()" [class.disabled]="!registrationFormId">Download Details</p>
                <p (click)="downloadAttachments('registration')" [class.disabled]="!registrationFormId">Download Attachments</p>
            </div>            
                       
        </div>
        
    
        <!-- Documentation Section -->
        <div class="details-card">
            <div class="details-header">
                <p class="heading">Documentation Details</p>
                <i class="pi pi-ellipsis-v" (click)="toggleMenu('documentation')"></i>
            </div>
            <p><strong>Submitted Date:</strong> 
                {{ documentationSubmittedDate && documentationSubmittedDate !== '-' ? (documentationSubmittedDate | date: dateFormat) : '-' }}
              </p>
                <div *ngIf="showDocumentationMenu" class="menu-options inline-menu">
                <p (click)="viewDocumentation()" [class.disabled]="!documentationFormId">View Details</p>
                <p (click)="downloadDocumentDetails()" [class.disabled]="!documentationFormId">Download Details</p>
                <p (click)="downloadAttachments('documentation')" [class.disabled]="!documentationFormId">Download Attachments</p>
            </div>                                  
        </div>        
    </div>
       

    <div>
        <div style="overflow-y: scroll; height: 70vh;" class="{{displayInfo ? 'userInfo-content' : 'hidden'}} section">

            <div class="info-card">
                <p class="heading">Personal Info</p>
                <div [style]="{display: 'flex', flexWrap: 'wrap'}">
                  <div class="info-element" *ngFor="let info of personalInfo">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'}">
                      <p [style]="{color: 'var(--fc-light-dark)'}">{{info.field}}</p>
                      <p class="capitalize" [style]="{color: 'var(--fc-dark)', width: 'fit-content', fontWeight: '500'}">
                        {{ (info.field == 'Date of Birth' && info.value !== '-' ) ? (info.value | date : dateFormat) : info.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            <div class="info-card">
                <p class="heading">Educational Qualifications</p>
                <div [style]="{display: 'flex', flexWrap: 'wrap'}">
                    <div class="info-element" *ngFor="let info of educationQualification">
                        <span class="info-line"></span>
                        <div [style]="{display: 'flex', flexDirection: 'column'} ">
                            <p [style]="{color: 'var(--fc-light-dark)'}">{{info.field}}</p>
                            <p [style]="{color: 'var(--fc-dark)', width: 'fit-content', fontWeight: '500'}">
                                {{info.value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="resume-container" *ngIf="displayResume">
            <p *ngIf="isLoadingResume">Loading Resume...</p>
            <p *ngIf="!isLoadingResume && !pdfSrc">No Resume Available</p>
            <div *ngIf="pdfSrc">
                <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(pdfSrc)" width="100%" height="600px"></iframe>
            </div>
        </div>
        <div style="overflow-y: scroll; height: 70vh;" class="{{displayFeedback ? 'userFeedback-content' : 'hidden'}} section">
            <div *ngIf="showDialog">
                <p-dialog header="Feedback Details" [modal]="true" [(visible)]="showDialog"
                    [style]="{width: '75vw', height: '80vh'}">
                    <div *ngIf="isLoadingFeedback" class="loading-feedback">
                        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                            animationDuration="0.5s">
                        </p-progressSpinner>
                    </div>
                    <app-feedback-form (isLoadingFeedback)="loadedFeedback($event)" [headerData]="paramItems"
                        [onlyFeedbackView]="true" [inViewMode]="true"></app-feedback-form>
                </p-dialog>
            </div>
            <div *ngIf="!isLoading" class="feedback-details">
                <div class="feedback-detail" *ngFor="let detail of feedbackDetails">
                    <p class="feedback-date">{{(detail.updatedAt | date: 'fullDate') || '-'}}</p>
                    <div class="interview-details">
                        <div class="interview-duration">
                            <div>
                                <p class="feedback-date_startTime">{{detail?.duration ? (detail?.duration?.startDT | date: combainFormat || '-') : '-'}}</p>
                                <p class="feedback-date_endTime"> to</p>
                                <p class="feedback-date_endTime">{{detail?.duration ? (detail?.duration?.endDT | date: combainFormat || '-') : '-'}}</p>
                            </div>
                        </div>
                        <div class="split">
                            <div class="split-bar"></div>
                        </div>
                        <div class="feedback-user_detail">
                            <div class="interrogator-feedback">
                                <div class="interrogator-details">
                                    <div>
                                        <p-avatar class="avatar-align"
                                            image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
                                    </div>
                                    <div>
                                        <p class="interrogator-name">{{detail.interrogatorName}}</p>
                                        <p>{{detail.interrogatorEmail}}</p>
                                        <p class="feedback-submit">{{detail.updatedAt | date: dateFormat || '-'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="view-feedback_container">
                                <div>
                                    <p class="stage-name">{{detail.stageName}}</p>
                                </div>
                                <div class="feedback-options">
                                    <p (click)="downloadFeedback(detail.stageId)"><i class="pi pi-download"></i> Download Feedback</p>
                                    <div (click)="fetchFeedback(detail)" class="view-feedback"><em class="pi pi-file"></em> View Feedback</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-break_section">
                        <hr class="line-break">
                    </div>
                </div>
                <div *ngIf="feedbackDetails.length === 0 && !isLoading" class="no-feedback">
                    <p>Feedback not submitted yet!</p>
                </div>
            </div>
        </div>

        <div class="{{displayActivity && !isLoading ? 'userActivity-content' : 'hidden'}}">
            <div *ngIf="isLoading" class="container-loader">
                <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s"></p-progressSpinner>
            </div>
            <div *ngIf="!isLoading && timelyActivity.length === 0" class="no-activity">
                <p>No activity found</p>  
            </div>
            <div class="candidateActivity" *ngIf="timelyActivity.length">
                <div class="activities" *ngFor="let date of timelyActivity">
                    <p-divider align="left">
                        <div class="activityDate">{{date[0].movedDate | date: dateFormat}}</div>
                    </p-divider>
                    <div *ngFor="let activity of date">
                        <div class="activity-item">
                            <div class="user-profile">
                                <img src="../../../assets/icons-svg/command.svg"
                                     *ngIf="activity.movement != 'manual'; else badge" alt="">
                                <ng-template #badge>{{activity?.userData?.badge ? activity?.userData?.badge : '#'}}</ng-template>
                            </div>
                            <div class="activity-content">
                                <h3 class="user-name">
                                    {{activity.movement == 'manual' ? activity?.userData?.name : 'Hire Automation' }} 
                                    <span class="time">{{activity.movedOn | date: combainFormat}}</span> 
                                </h3>
                                <div class="activity-details">
                                    <div class="action-details" [ngSwitch]="activity.action">
                                        <span *ngSwitchCase="'Status Change'">
                                            <div class="action"><span class="label">Action </span>Status Change</div>
                                            <div class="action-from-to">
                                                <span class="fromStage">{{activity.oldStage}}</span>
                                                <img src="../../../assets/icons-svg/arrow-right-alt.svg" alt="Arrow-right">
                                                <span class="toStage">{{activity.newStage}}</span>
                                            </div>
                                        </span>
                                        <span *ngSwitchCase="'Move Stage'">
                                            <div class="action"><span class="label">Action </span>Move Stage</div>
                                            <div class="action-from-to">
                                                <span class="fromStage">{{activity.oldStage}}</span>
                                                <img src="../../../assets/icons-svg/arrow-right-alt.svg" alt="Arrow-right">
                                                <span class="toStage">{{activity.newStage}}</span>
                                            </div>
                                        </span>
                                        <span *ngSwitchDefault>
                                            <div class="action"><span class="label">Action </span>{{activity.action}}</div>
                                        </span>
                                    </div>
                                    <div class="action-remarks">
                                        <span class="label">Remarks</span>
                                        <p>{{ activity.remarks && activity.remarks.trim() !== '' ? activity.remarks : '-' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>

    

</div>
<!-- Registration Details Dialog -->
<p-dialog *ngIf="showRegistrationDialog" header="Registration Details" [(visible)]="showRegistrationDialog"
    [modal]="true" [style]="{width: '75vw', height: '80vh'}" (onHide)="showRegistrationDialog = false">  

    <div *ngIf="isLoadingDetails" class="loading-feedback">
        <p-progressSpinner strokeWidth="2" animationDuration="0.5s"></p-progressSpinner>
    </div>

    <div *ngIf="!isLoadingDetails">
        <div class="details-container">
          <ng-container *ngIf="registrationDetails.length > 0; else noData">
            <ng-container *ngFor="let section of getGroupedSections(registrationDetails)">
              <div class="details-section-title">{{ section.name }}</div>
              <div *ngFor="let item of section.fields" class="detail-item">
                <strong>{{ item.label }}</strong>
                <span [innerHTML]="item.value"></span>
              </div>
            </ng-container>
          </ng-container>
      
          <ng-template #noData>
            <p>No Data Found</p>
          </ng-template>
        </div>
      </div>
      
</p-dialog>

<!-- Documentation Details Dialog -->
<p-dialog header="Documentation Details" [(visible)]="showDocumentationDialog"
    [modal]="true" [style]="{width: '75vw', height: '80vh'}" (onHide)="closeDocumentationDialog()">


    <div *ngIf="isLoadingDetails" class="loading-feedback">
        <p-progressSpinner strokeWidth="2" animationDuration="0.5s"></p-progressSpinner>
    </div>

    <div *ngIf="!isLoadingDetails">
        <div class="details-container">
          <ng-container *ngIf="documentationDetails.length > 0; else noData">
            <ng-container *ngFor="let section of getGroupedSections(documentationDetails)">
              <div class="details-section-title">{{ section.name }}</div>
              <div *ngFor="let item of section.fields" class="detail-item">
                <strong>{{ item.label }}</strong>
                <span [innerHTML]="item.value"></span>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noData>
            <p>No Data Found</p>
          </ng-template>
        </div>
      </div>
</p-dialog>

<p-toast></p-toast>