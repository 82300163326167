import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalServicesService } from '../services/global-services.service';
import { environment } from 'src/environments/environment';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { FileUploadService } from '../services/file-upload.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  sourceImg = '';
  dashboardActive: boolean = false;
  jobsActive: boolean = true;
  usersActive: boolean = false;
  ENV = environment.HOST.NODE_ENV;
  sideNavOptions: any = [];
  activePage: string = '';
  companyLogo: string = '';
  userPrivileges: Set<string> = new Set();

  constructor(private _router: Router, private globalservice: GlobalServicesService, private breadcrumbService: NgDynamicBreadcrumbService, private fileUploadService: FileUploadService) {}

  ngOnInit() {
      this.globalservice.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
      this.buildSideNav();
    });
    const companyDetails = localStorage.getItem('company-details');
    this.companyLogo = companyDetails ? JSON.parse(companyDetails).company_metadata.logo : '';
    if (this.companyLogo.length) this.sourceImg = this.companyLogo;
  }

  buildSideNav() {

    this.sideNavOptions = [
      { name: 'Manage Events', icon: 'events', isActive: false, url: 'events', view: this.hasPrivilege('event.create') || this.hasPrivilege('event.view') },
      { name: 'Manage Candidate', icon: 'talent-pool', isActive: false, url: 'talentpool-management', view: this.hasPrivilege('candidate.invite') || this.hasPrivilege('candidate.view') },
      { name: 'Manage Job Roles', icon: 'roles', isActive: false, url: 'roles', view: this.hasPrivilege('job_role.create') || this.hasPrivilege('job_role.view') },
      { name: 'Email Template', icon: 'email-template', isActive: false, url: 'email-template', view: this.hasPrivilege('email_template.create') || this.hasPrivilege('email_template.view') },
      { name: 'General Settings', icon: 'settings', isActive: false, url: 'general-settings', view: true },
      { name: 'Manage Content', icon: 'sso-redirect', isActive: false, url: 'sso', view: this.hasPrivilege('iamneo_platform.access') }
    ];
  }

  hasPrivilege(key: string): boolean {
    return this.userPrivileges.has(key);
  }

  updateBreadcrumb(title: string): void {
    const breadcrumbs = [{ label: title, url: '' }];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  iconClick = (option: any) => {
    this.sideNavOptions.forEach((item: any) => {
      if (item.url === option.url) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    });
    if (option.url === 'sso') {
      this.openSSO();
    } else {
      this._router.navigateByUrl(option.url);
    }
  }

  openSSO = () => {
    let payload = {
      source: "neo-hire",
    };
    this.globalservice.ssoLoginFastApi(payload).subscribe((resp: any) => {
      let url = resp.body.url;
      if (resp.body.url) {
        let target_host;
        if (this.ENV === 'dev') {
          target_host = 'exam.ly';
        } else if (this.ENV === 'acc') {
          target_host = 'examly.net';
        } else if ( this.ENV === 'prod' ){
          target_host = 'examly.io';
        }
        url = url.replace('examly.io', target_host);
        window.open(url, '_blank');
      }
    })
  }
}
