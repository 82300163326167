<div *ngIf="isLoading" class="loadingOverlay">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>
<div class="pageInfo">
  <div class="settings-header" style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
      <div style="display: flex;justify-content:space-between; align-items: center;">
          <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">{{activeComponent}}</p>
      </div>
      <button style="cursor: pointer;width: 110px" (click)="edit=false;openSideBar()" pButton pRipple type="submit" label="Add New" tabindex="0">
      </button>
  </div>
</div>
<div class="info-card">
  <div *ngIf="optionValueList.length > 0; else noOptions" class="info-card-container">
    <div class="option-container text-style" *ngFor="let option of optionValueList">
      <div>{{option.name}}</div>
      <div style="display: flex;gap: 10px;">
        <button class="option-delete" (click)="deleteOptions(option.id, $event)">
          <span class="pi pi-trash"></span>
        </button>
        <button class="option-delete" (click)="edit=true;editOption(option)">
          <span class="pi pi-pencil"></span>
        </button>
      </div>
    </div>
  </div>
  <ng-template #noOptions>
    <div class="no-options text-style">No options available.</div>
  </ng-template>
</div>

<p-sidebar *ngIf="addOptionSidebar" class="addTemplate-slider" [(visible)]="addOptionSidebar"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
    (onHide)="addOptionSidebar = false;optionValueForm.reset();" [style]="{width: '50vw'}">
    <div class="addTemplate-header">
        <div class="addTemplate-heading"><em style="margin-right: 12.5px;"
                class="pi"></em>Add Option</div>
        <div class="header-btn">
            <button pButton pRipple type="submit" [label]="edit ? 'Update' : 'Save'" [style]="{width: '110px'}"
                (click)="edit ? updateOption() : saveOption()" class="p-button-raised p-button-text button-align"
                [loading]="saveBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    <div class="templateForm" [formGroup]="optionValueForm">
        <div style="display: flex; align-items: center;">
            <span class="p-float-label templateForm-elements">
                <input class="inputtext" type="text" id="template-title" pInputText formControlName="name"
                    [ngClass]="{'p-filled': optionValueForm.value.name}"
                    [style]="{maxWidth: '32vw !important', width: '400px'}">
                <label for="template-title">{{activeComponent}}</label>
                <app-handle-error [clicked]="clicked" [control]="optionValueForm.get('name')" [name]="activeComponent"></app-handle-error>
            </span>
        </div>
        <div style="display: flex; align-items: center;">
          <span *ngIf="this.activeComponent === 'Event Type'" class="p-float-label templateForm-elements">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name" optionValue="value"
                [options]="institution_select_type" formControlName="institution_select_type" [(ngModel)]="_selectedInstitutionSelectType"
                [required]="true" [style]="{ maxWidth: '21.5vw' }"
                [styleClass]="clicked && optionValueForm.controls['institution_select_type'].errors ? 'invalid' : ''">
            </p-dropdown>
            <label for="dropdown">Institution Type <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="optionValueForm.get('institution_select_type')" name="Institution Type">
            </app-handle-error>
        </span>
        </div>
    </div>
    <p-toast></p-toast>
</p-sidebar>
<p-confirmPopup></p-confirmPopup>