<div class="form-container">
  <div *ngIf="isLoading" class="loadingOverlay">
      <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
      </p-progressSpinner>
  </div>
  <div class="form-header">
      <p>{{formType}}</p>
      <div [style]="{display: 'flex', alignItems:'center'}">
        <span class="p-input-icon-left" [style]="{marginRight: '10px'}">
            <em class="pi pi-search"></em>
            <input class="searchbar" [(ngModel)]="formSearchTerm" (ngModelChange)="searchTemplate()" type="text"
                pInputText placeholder="Search Form" />
        </span>
        <button pButton type="button" label="Create New"
            (click)="openAddFormBuilder()" [style]="{height: '38px'}"></button>
    </div>
  </div>

  <div class="form-content">
    <div class="templates-container">
      <div class="pageInfo">
          <em class="info-button"></em>
          <p>Event Register forms will be grouped here.
          </p>
      </div>
      <div class="form-list-container" *ngFor="let form of _formList">
        <div class="list-title">
          {{form.formTitle}}
        </div>
        <div class="list-btn-container">
          <button class="p-panel-header-icon p-link" (click)="deleteForm(form, $event)">
            <span class="pi pi-trash"></span>
          </button>
          <button class="p-panel-header-icon p-link" (click)="editForm(form)">
            <img src="../../../assets/icons-svg/edit.svg" alt="">
          </button>
          <button class="p-panel-header-icon p-link toggler" (click)="previewForm(form)">
            <span class="pi pi-eye"></span>
          </button>
        </div>
      </div>
  </div>
  </div>
  <p-toast></p-toast>

  <p-confirmPopup></p-confirmPopup>
</div>