<div class="jobDetails-card">
    <p-sidebar class="addJob-slider" [(visible)]="editJobSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
        position="right" (onHide)="getJobDetails()">
        <app-job-add *ngIf="editJobSlider" [jobDetails]="jobDetails"></app-job-add>
    </p-sidebar>
    <div class="card-header">
        <p>{{jobDetails.jobName}}</p>
        <div class="options">
            <em class="pi pi-pencil" (click)="editJobSlider = true"></em>
            <em class="pi pi-table"></em>
            <em class="pi pi-link"></em>
            <em class="pi pi-share-alt"></em>
        </div>
    </div>
    <div class="jobInfo">
        <p><em class="pi pi-map-marker"></em> {{jobDetails.location}}</p>
        <p><em class="pi pi-calendar-times"></em> {{ jobDetails.jobType }}</p>
        <p><em class="pi pi-calendar-minus"></em> {{ jobDetails.workExperience }} {{jobDetails.workExperience > 1 ?
            'years' : 'year'}}</p>
        <p> {{jobDetails.salary ? (jobDetails.salary |
            currency: 'INR' : 'symbol-narrow' : '1.0-0') :
            ((jobDetails.minSalary | currency: 'INR' : 'symbol-narrow' : '1.0-0') + ' - ' + ( jobDetails.maxSalary |
            currency: 'INR' : 'symbol-narrow' : '1.0-0')
            )}}/month</p>
        <p><span>Department: </span>{{ jobDetails.department }}
        </p>
    </div>
    <hr>
    <div class="jobDescription">
        <span>
            <p [style]="{color: 'var(--fc-light-dark)', fontWeight: 'var(--fw-semi-bold)'}"
                [ngClass]="{'limitHeight': !readMore}" class="customJobDescription"
                [innerHtml]="jobDetails.jobDescription">
            </p>
        </span>
        <p (click)="readMore = !readMore"
            [style]="{color: 'var(--fc-primary)', cursor: 'pointer', padding: '10px 0px', width: 'fit-content'}">
            {{readMore ? 'View Less' : 'View More'}}</p>
    </div>
</div>

<div class="accessManagament-card">
    <app-access-management></app-access-management>
</div>