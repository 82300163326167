import { Component, OnInit } from '@angular/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit {
  categories: Array<any> = [];
  isLoading: boolean = true;
  userPrivileges: Set<string> = new Set();
  activeComponent: string = 'Primary Account';
  activeSubOption: string = 'Candidate Source Name'; 

  platformOptions: string[] = [];
  platformOptionPrivileges: { [key: string]: string } = {
    'Candidate Source Name': 'candidate_source.view',
    'Candidate Source Type': 'candidate_source.view',
    'Event Category': 'event_category.view',
    'Event Type': 'event_type.view',
    'Role Type': 'role_type.view'
  };
  

  constructor(
    private breadcrumbService: NgDynamicBreadcrumbService,
    private globalService: GlobalServicesService
  ) {
    this.globalService.getUserProfileFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          let companyDetails = {
            company_metadata: res.body.company_metadata,
            company_settings: res.body.company_settings,
            company_details: res.body.company_details,
          };
          let letter = ['D', 'Y'];
          companyDetails.company_settings.date_format = companyDetails.company_settings.date_format?.toString().replace( 
              new RegExp(letter.join('|'), 'g'),(match: string) => match.toLowerCase());
          companyDetails.company_settings.time_format = companyDetails.company_settings.time_format == '24' ? 'HH:mm' : 'hh:mm';
          localStorage.setItem('company-details', JSON.stringify(companyDetails));
        }
      }
    });
  }

  ngOnInit(): void {
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });
    
    this.getCategories();
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    const breadcrumbs = [{ label: 'General Settings', url: '' }];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  getCategories = () => {
    this.isLoading = false;
    const showPlatformConfig = Object.values(this.platformOptionPrivileges).some(
      privilege => this.userPrivileges.has(privilege)
    );
    this.platformOptions = Object.keys(this.platformOptionPrivileges).filter(
      option => this.userPrivileges.has(this.platformOptionPrivileges[option])
    );
    

    const privilegeMap = [
      { name: 'Primary Account', isActive: true },
      { name: 'Company Details', privilege: 'company_details.view' },
      { name: 'Branches', privilege: 'branch.view' },
      { name: 'Date and Time', privilege: 'date_time_format.view' },
      ...(showPlatformConfig && this.platformOptions.length > 0
        ? [{ name: 'Platform Configuration', isActive: false }]: []),
      { name: 'Access Management', privilege: 'role.view' },
      { name: 'Manage Users', privilege: 'user.view' }
    ];
    this.categories = privilegeMap
    .filter(cat => !cat.privilege || this.userPrivileges.has(cat.privilege))
    .map((cat, index) => ({
      name: cat.name,
      isActive: index === 0 
    }));
  };

  openCategory = (name: string) => {
    this.activeComponent = name;
    this.categories.forEach((each) => {
      each.isActive = this.activeComponent === each.name;
    });

    if (name === 'Platform Configuration' && this.platformOptions.length > 0) {
      this.activeSubOption = this.platformOptions[0];
    }    
  };

  setActiveSubOption(option: string) {
    this.activeSubOption = option;
  }

  handleKeydown(event: KeyboardEvent, categoryName: string): void {
    if (event.key === 'Enter') {
      this.openCategory(categoryName);
    }
  }
}
