import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, NgModule } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import * as codes from '../../../assets/Json/countryTeleCodes.json';
import { MessageService } from 'primeng/api';
import {nanoid} from 'nanoid';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css'],
  providers: [MessageService]
})
export class FieldsComponent implements OnInit, OnChanges {

  @ViewChild('checkboxField') checkboxField: any;
  @ViewChild('radioField') radioField: any;
  @ViewChild('booleanRadioField') booleanRadioField: any;
  @ViewChild('multiEmailField_2') multiEmailField_2: any;
  @ViewChild('multiEmailField_1') multiEmailField_1: any;

  @Input() model: any;
  @Input() ansChange: any;
  @Input() clicked: boolean = false;
  @Input() invalidField: any;
  @Input() inEditMode: boolean = false;
  @Input() inValidLocation: boolean = false;
  @Input() belowAge: boolean = false;
  @Input() duplicateData: any = {};
  @Input() verifyByEmail: any = {};
 @Input() p1EmailVerified: boolean = false;
  @Input() p1MobileVerified: any = {};
  @Input() enableMobile: any = {};
  @Input() companyId: any = {};

  @Output() fieldDataEmitter = new EventEmitter<any>();
  @Output() checkValidator = new EventEmitter<any>();
  @Output() enableEmailVerification = new EventEmitter<any>();
  @Output() enableMobileVerification = new EventEmitter<any>();

  fieldData: any;

  resume: File[] = [];
  file: File[] = [];
  fileBase64: any = '';
  tempAnswer: any = null;
  countryCodeList: any = (codes as any).default;
  _countryCodeList: Array<any> = []
  selectedCode: any = { country: "India", countryCodes: ["91"], isoCode2: "IN", isoCode3: "IND", phLength: 10 };

  fileType: string = '';
  codeSearchTerm: string = '';
  currentDate: any;
  numberInput: any;

  validFields: boolean = false;
  uploadingFile: boolean = false;
  invalidLength: boolean = false;
  formLoading: boolean = false;
  validEmail: boolean = false;
  alphaNumeric: RegExp = /^[a-zA-Z0-9]*$/;
  emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  enableMobileVerify: boolean = false;
  domainList: any;
  alternateEmailCheck: string[] = [];
  invalidEmails: string[] = [];
  selectedCheckboxValue: string | null = null;

  constructor(
    private fileUploadService: FileUploadService, 
    private globalService: GlobalServicesService,
    private messageService: MessageService
  ) { }

  
  ngOnInit(): void {
    this.domainList =[ "gmail.com", "outlook.com", "rediffmail.com", "icloud.com", "zohocorp.com", "zoho.in", "aol.com", "inbox.com", "yahoo.com", "hotmail.com", "live.com", "msn.com", "zoho.com", "yahoo.co.in"];
    if(this.model.fieldType == 'mobile' && this.model.fieldName == 'Primary Mobile Number'){
      if(this.model.answer.length > 1){
        this.enableMobileVerify = true;
      }
    }
    if (this.model.fieldType === 'file') {

      if (this.model.fieldType == 'file') {
        this.fileType = this.model.fileProperities?.file_format.map((each: string) =>  '.'+each.toLowerCase() ).join(',');
      }
    }
    this._countryCodeList = this.countryCodeList;
    if(this.model.fieldName == 'If No - When can you start the internship/training?') this.currentDate = new Date();
    if (this.model?.answer && this.model.answer.length > 0) {
      this.tempAnswer = this.model.answer[0];
    }
  }

  isFieldDisabled(): boolean {
    return !this.p1EmailVerified && this.model.groupName !== 'email';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model'] && this.model?.answer) {
      this.tempAnswer = this.model.answer[0]; 
    }
    if(changes['invalidField']){
      let invalidField = document.getElementById(this.invalidField?.fieldId);
      invalidField?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (this.inEditMode && changes['ansChange']) {
      if (this.model.fieldType == 'dropdown') {
        if(this.model.fieldName === 'Highest Degree - Year of Passing')
        {
          this.model.answer[0] = this.model.answer[0].toString();
        }
        else if(this.model.fieldName === 'What is your Highest Degree?' && this.model.fieldName === 'Registration Form' )
        {
          this.model.answer[0] = this.model.answer[0].replace(/\s/g, '');
        } 
        let label = this.model.answer[0];
        let value = this.model.answer[0];
        if(label === true)
        {
          label = "Yes"
        }
        else if(label === false)
        {
          label = "No"
        }
        if(label && value) this.tempAnswer = { label: label, value: value };
        if(this.model.answer.length == 0 && this.model.isMandatory && this.model.visible){
          this.model.isValid = false;
        }
      }

      else if (this.model.fieldName == 'Alternate Email Address') {
        this.tempAnswer = this.model.answer;
      } 

      else if (this.model.fieldType == 'multiinput') {
        let splitName = this.model.answer[0].split(" ");
        let lastName = splitName.pop();
        let firstName = splitName.join(" ");
        this.model.fieldOptions[0].value = firstName;
        this.model.fieldOptions[1].value = lastName;
      } 
      else if (this.model.fieldType == 'multiInputEmail') {
        this.model.fieldOptions[0].value = this.model.answer[0];
        this.model.fieldOptions[1].value = this.model.answer[0];
        this.tempAnswer = this.model.answer[0];
        this.model.readOnly = true;
      } 
      else if (this.model.fieldType == 'file') {
        this.fileBase64 = this.model.answer[0];
        this.fileType = '.doc';
        let fileName = this.fileBase64?.split('/').pop();
        let blob: any = new Blob([""], { type: 'application/pdf' });
        blob["lastModifiedDate"] = "";
        blob["name"] = fileName;
        this.file = [blob];
      } 
      else if (this.model.fieldType == 'mobile') {
        this.tempAnswer = this.model.answer[1];
        let countryCode = this.model.answer[0]?.replace('+','')
        this.countryCodeList.forEach((each: any)=>{
          if(each.countryCodes[0] === countryCode)
          {
            this.selectedCode = each;
          }
        })
        if(this.model.fieldName === "Primary Mobile Number")
        {
          this.model.readOnly = true;
        }
      } 
      else if(this.model.fieldName === "Candidate ID")
      {
        this.tempAnswer = this.model.answer[0]
        this.model.readOnly = true;
      }
      else {
        this.tempAnswer = this.model.answer[0]
      };
    }
  }

  onCheckboxChange(event: any, value: string) {
    if (event) {
      this.selectedCheckboxValue = value;
    } else {
      this.selectedCheckboxValue = null;
    }
    this.fieldDataEmitter.emit([value]);
    this.checkValidator.emit(!!value);
  }

  onSelectfile(event: any, acl?: boolean) {
    if (this.file.length) this.file.shift();
    this.file.push(...event.addedFiles);
    this.handleUpload(event, acl)
  }

  onRemovefile() {
    this.file = [];
    this.checkValidator.emit(false);
    this.fileBase64 = '';
  }

  handleUpload(event: any, acl?: boolean) {
    const file = event.addedFiles[0];
    const fileName = this.generateFileName(file);
    const renamedFile = new File([file], fileName, {
      type: file.type,
      lastModified: file.lastModified,
    });
    event.addedFiles[0] = renamedFile;
    const reader = new FileReader();
    reader.readAsDataURL(renamedFile);
    reader.onload = () => {
      this.fileUpload(renamedFile, fileName);
    };
  }

  searchCode = (event: any) => {
    if (this.codeSearchTerm.length) {
      this._countryCodeList = this.countryCodeList.filter((val: any) => val.country.toLowerCase().includes(event.toLowerCase()));
    } else this._countryCodeList = this.countryCodeList;
  }

  private generateFileName(file: any): string {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    const timestamp = new Date().getTime();
    return `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}_${timestamp}.${fileExtension}`;
  }

  uploadFileToGCS(signedUrl: string, file: any, filePath: string, filename: string) {
    this.fileUploadService.uploadUsingSignedUrlGCS(signedUrl, file).subscribe({
      next: (response: any) => {
        if (response && response.status === "success") {
          this.fileBase64 = filename;
          this.fieldDataEmitter.emit(this.fileBase64);
          this.checkValidator.emit(true);
          this.uploadingFile = false;
        }
      },
      error: (error) => {
        console.error('Error uploading file to GCS:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file to GCS' });
        this.uploadingFile = false;
      }
    });
  }

  fileUpload(file: any, fileName: string) {
    this.uploadingFile = true;
    const filePath = `temp/${this.companyId}/${fileName}`;
    const payload = {
      file_path: filePath,
      bucket_type:'private'
    };
    const isCandidate = true;
    this.fileUploadService.getSignedUrlGCS(payload, isCandidate).subscribe({
      next: (response: any) => {
        if (response && response.body.signed_url) {
          this.uploadFileToGCS(response.body.signed_url, file, filePath, fileName);
        }
      },
      error: (error) => {
        this.uploadingFile = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error getting signed URL' });
      }
    });
  }
  
  chipHandle(event?: any){
    if(event?.value){
      this.alternateEmailCheck = this.tempAnswer.filter((item:any) => item !== event?.value);  
    }else{
      this.alternateEmailCheck = this.tempAnswer;
    }
    const validEmails: string[] = [];
    this.invalidEmails = [];
    this.alternateEmailCheck.forEach(email => {
      const patternCheck = this.emailPattern.test(email);
      const mailSplit = email.split('@');
      const domainCheck = this.domainList.includes(mailSplit[1]);

      if (patternCheck && domainCheck) {
        validEmails.push(email);
      } else {
        this.invalidEmails.push(email);
      }
    });
    this.fieldDataEmitter.emit(validEmails); // Emit valid emails
    if (this.tempAnswer.length && this.invalidEmails.length === 0) {
      this.checkValidator.emit(true); // All emails are valid
    } else {
      this.checkValidator.emit(false); // Some emails are invalid
    }

  }

  emitValue = (event: any, inputField: any, validLength?: number) => {
    switch (this.model.fieldType) {
      case 'multiinput': {
        let mergedValue = this.model.fieldOptions[0].value + ' ' + this.model.fieldOptions[1].value;
        this.fieldDataEmitter.emit(mergedValue);
        if(this.model.fieldOptions[0].value?.length && this.model.fieldOptions[1].value?.length){
          this.checkValidator.emit(inputField.valid);
        } else {
          this.checkValidator.emit(false);
        }
      } break;
      case 'multiInputEmail': {
        if (this.model.fieldOptions[0].value === this.model.fieldOptions[1].value && this.multiEmailField_1.valid && this.multiEmailField_2) {
          this.fieldDataEmitter.emit(this.model.fieldOptions[1].value)
          this.checkValidator.emit(inputField.valid);
        } else {
          this.checkValidator.emit(false);
        }
      } break;
      case 'dropdown': {
        this.fieldDataEmitter.emit(event.value)
        this.checkValidator.emit(inputField.valid);
        
      } break;
      case 'mobile': {
        this.fieldDataEmitter.emit([this.selectedCode.countryCodes[0], Number(event)]);
        if (this.tempAnswer && this.tempAnswer?.toString().length != validLength) { this.checkValidator.emit(!inputField.valid); this.invalidLength = true; }
        else { this.checkValidator.emit(inputField.valid); this.invalidLength = false; }
      } break;

      case 'email': {
        if (this.tempAnswer && this.emailPattern.test(this.tempAnswer)) { 
          this.checkValidator.emit(true); 
          this.validEmail = true; 
          this.fieldDataEmitter.emit(event)
        } else { 
          this.checkValidator.emit(false); 
          this.validEmail = false; 
        }
      }break;

      case 'multiText': {
        if (this.model.fieldName === 'Alternate Email Address') {
          this.chipHandle();
        }
      } break;
        case 'date picker': {
          if (this.model.fieldName === 'DOB') {
            const selectedDate = new Date(event);
            const today = new Date();
            let age = today.getFullYear() - selectedDate.getFullYear();
            const monthDiff = today.getMonth() - selectedDate.getMonth();
            
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selectedDate.getDate())) {
              age--;
            }

            if (age < 18) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Age must be at least 18 years'
              });
              this.belowAge = true;
            } else {
                this.belowAge = false;
                this.checkValidator.emit(true);
                this.fieldDataEmitter.emit(event);
            }
          } else {
            this.fieldDataEmitter.emit(event);
            this.checkValidator.emit(inputField.valid);
          }
      } break;
      
      case 'input': {
        if (this.model.fieldName === 'Aadhar Card Number') {
          if (this.tempAnswer.toString().length != 12) {
            this.checkValidator.emit(false); this.invalidLength = true;
          } else {
            this.checkValidator.emit(inputField.valid); this.invalidLength = false;
            this.fieldDataEmitter.emit(event)
          }
        }
        else if(this.model.fieldName === 'Pin Code')
        {
          if (this.tempAnswer.toString().length != 6) {
            this.checkValidator.emit(false); this.invalidLength = true;
          } else {
            this.checkValidator.emit(inputField.valid); this.invalidLength = false;
            this.fieldDataEmitter.emit(event)
          }
        } else {
          this.fieldDataEmitter.emit(event)
          this.checkValidator.emit(inputField.valid);
        }
      } break;
      default: {
        this.fieldDataEmitter.emit(event)
        this.checkValidator.emit(inputField.valid);
      };
    }
  }

  convertByte(sizeFormat: string, size: number) {
    let formattedSize = 0;

    if (sizeFormat === 'mb') {
      formattedSize = size * 1024 * 1024;
    }
    else if (sizeFormat === 'kb') {
      formattedSize = size * 1024;
    }

    return formattedSize;
  }

  restrictNumber(event: any, len: number) {
    let e: any = event || window.event; 
    let key = e.keyCode || e.which;

    if (key < 48 || key > 57 || event.target.value.length >= len) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
  }

  restrictCharacters(event: any, len: number) {
    let e: any = event || window.event;

    if( event.target.value.length >= len){
      if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
  }

  verifyEmail(){
    let email = this.tempAnswer;
    this.enableEmailVerification.emit({status: true, email: email});
  }

  verifyMobile(){
    let mobileNumber = this.selectedCode.countryCodes[0] + this.tempAnswer;
    this.enableMobileVerification.emit({status: true, mobile: mobileNumber, countryCode: this.selectedCode.countryCodes[0], number: this.tempAnswer});
  }

  mobileChange(event:any, inputField: any, phLength: number){
    this.emitValue(event, inputField, phLength);
    if(this.tempAnswer.length == phLength && !isNaN(Number(event))) 
    {
      this.enableMobileVerify = true;
    }else{
      this.enableMobileVerify = false;
    }
  }
}
