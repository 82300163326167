import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private route: Router, private globalService: GlobalServicesService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // let userDetails:any = JSON.parse(localStorage.getItem('userDetails') ?? '')
    const userDetails = localStorage.getItem('userDetailsFastApi')
      ? JSON.parse(localStorage.getItem('userDetailsFastApi') || '').user_profile
      : '';
    if (!(userDetails.email)) {
      this.removeKeys();
      this.route.navigateByUrl('/login');
      return false;
    }
    this.globalService.getUserProfileFastApi().subscribe({
      next: (res: any) => {
        localStorage.setItem("userPrivilegeFastApi", JSON.stringify(res.body.auth_user_permissions));
        const userDetails = {
          user_profile : res.body.auth_user_details,
          role_details : res.body.auth_user_role_details
        };
        localStorage.setItem('userDetailsFastApi', JSON.stringify(userDetails));
        const company_details = {
          company_metadata: res.body.company_metadata,
          company_settings: res.body.company_settings,
          company_details: res.body.company_details
        }
        localStorage.setItem('company-details', JSON.stringify(company_details));
      },
      error: (error) => {
        console.log(error); // Handle error case
      }
    });
    // this.loginService.isLoggedIn(userDetails.email).subscribe(
    //   (res: any) => {
    //     console.log("🚀🔥 ~ file: auth.guard.ts:41 ~ AuthGuard ~ res:", res)
    //     if (res.status === 200) {
    //       return true;
    //     }
    //     else {
    //       this.loginService.validToken.next(
    //         {
    //           status: res.status,
    //           message: {
    //             severity: 'error',
    //             summary: 'Session Expired!',
    //           }
    //         }
    //       )
    //       this.removeKeys();
    //       setTimeout(() => {
    //         this.route.navigateByUrl('/login');
    //       }, 4000)
    //       return false;
    //     }
    //   }
    // );
    return true;
  }
  removeKeys() {
    for (var attr in localStorage) {
      if (attr != 'schoolData' && attr != 'schoolDetails')
        localStorage.removeItem(attr);
    }
  }
}
