import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { UserModule } from '../user/user.module';

import { PrimaryAccountComponent } from './primary-account/primary-account.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { BranchesComponent } from './branches/branches.component';
import { DepartmentsComponent } from './departments/departments.component';
import { SharedModule } from '../shared/shared.module';
import { CohortJoiningComponent } from './cohort-joining/cohort-joining.component';
import { DateTimeSettingsComponent } from './date-time-settings/date-time-settings.component';
import { OtpAuthenticationComponent } from './otp-authentication/otp-authentication.component';
import { OptionValueAddComponent } from './option-value-add/option-value-add.component';
import { RoleAndPermissionComponent } from './role-and-permission/role-and-permission.component';



@NgModule({
  declarations: [
    GeneralSettingsComponent,
    PrimaryAccountComponent,
    CompanyDetailsComponent,
    BranchesComponent,
    DepartmentsComponent,
    CohortJoiningComponent,
    DateTimeSettingsComponent,
    OtpAuthenticationComponent,
    OptionValueAddComponent,
    RoleAndPermissionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SharedModule,
    UserModule  
  ]
})
export class SettingsModule { }
