<div class="generalSettings-container">
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s"></p-progressSpinner>
    </div>
    
    <div class="generalSettings-header">
        <p>General Settings</p>
    </div>

    <div class="generalSettings-content">
        <div class="categories">
            <button class="category" 
                    id="category-{{catInx}}" 
                    [ngClass]="{'active' : category.isActive}" 
                    *ngFor="let category of categories; let catInx = index"
                    (click)="openCategory(category.name)" 
                    (keydown)="handleKeydown($event, category.name)" 
                    tabindex="{{catInx}}">
                <p>{{category.name}}</p>
            </button>
        </div>

        <div class="settings-container">
            <div *ngIf="activeComponent === 'Primary Account'">
                <app-primary-account></app-primary-account>
            </div>
            <div *ngIf="activeComponent === 'Company Details'">
                <app-company-details></app-company-details>
            </div>
            <div *ngIf="activeComponent === 'Branches'">
                <app-branches></app-branches>
            </div>
            <div *ngIf="activeComponent === 'Date and Time'">
                <app-date-time-settings></app-date-time-settings>
            </div>
            <div *ngIf="activeComponent === 'Access Management'">
                <app-role-and-permission></app-role-and-permission>
            </div>
            <div *ngIf="activeComponent === 'Manage Users'" class="user-list-wrapper">
                <app-user-list></app-user-list>
            </div>

            <div *ngIf="activeComponent === 'Platform Configuration'" class="platform-config-container">
                <div class="platform-config-menu">
                    <button *ngFor="let option of platformOptions"
                            (click)="setActiveSubOption(option)"
                            [ngClass]="{'active': activeSubOption === option}">
                        {{ option }}
                    </button>
                </div>
                <div class="platform-config-content">
                    <app-option-value-add [activeComponent]="activeSubOption"></app-option-value-add>
                </div>
            </div>
        </div>
    </div>

    <p-toast></p-toast>
</div>
