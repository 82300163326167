import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgOtpInputModule } from 'ng-otp-input';

// Custom Modules 
import { JobModule } from './job/job.module';
import { SharedModule } from './shared/shared.module';
import { EventsModule } from './events/events.module';
import { RolesModule } from './roles/roles.module';
import { UserModule } from './user/user.module';
import { EmailModule } from './email/email.module';
import { SettingsModule } from './settings/settings.module';
import { AppRoutingModule } from './app-routing.module';

//custom Componenets
import { AppComponent } from './app.component';
import { JobListComponent } from './job-list/job-list.component';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PasswordModule } from 'primeng/password';
import { TalentpoolManagementComponent } from './talentpool/talentpool-management/talentpool-management.component';
import { CandidatePanelComponent } from './candidate-panel/candidate-panel.component';
import { LoginOtpVerificationComponent } from './login-otp-verification/login-otp-verification.component';
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
// import { AuthInterceptor } from './auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    JobListComponent,
    HeaderComponent,
    SideNavComponent,
    ApplicationFormComponent,
    DashboardComponent,
    LoginComponent,
    TalentpoolManagementComponent,
    CandidatePanelComponent,
    LoginOtpVerificationComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JobModule,
    UserModule,
    NgxDropzoneModule,
    EmailModule,
    SharedModule,
    EventsModule,
    RolesModule,
    PasswordModule,
    NgOtpInputModule,
    SettingsModule
  ],
  providers: [
    MessageService,
  ],
  bootstrap: [AppComponent],
})
// CookieService,
// {
//   provide: HTTP_INTERCEPTORS,
//   useClass: AuthInterceptor,
//   multi: true
// }
export class AppModule { }
