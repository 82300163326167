<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div *ngIf="userPrivileges.has('event_role.view')" class="events-card">
    <div class="events-header card-header">
        <div>{{eventDetails.eventTitle}}</div>
        <img *ngIf="userPrivileges.has('event_role.update')" src="../../../assets/icons-svg/edit.svg" alt="" (click)="addEventSlider = true">
    </div>
    <ng-container *ngIf="addEventSlider">
        <p-sidebar class="addEvent-slider" [(visible)]="addEventSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
            position="right" (onHide)="getEventDetails();">
            <app-events-add *ngIf="addEventSlider" [inEditMode]="true" [eventData]="eventDetails"></app-events-add>
        </p-sidebar>
    </ng-container>
    <div class="eventDetails">

        <div class="event-timeline timeline-card">
            <div class="timeline-icon">
                <div class="timeline-icon-container">
                    <img src="../../../assets/icons-svg/event-date.svg" alt="Event-date">
                </div>
            </div>
            <div class="timeline-details">
                <p class="timeline-heading">Event Timeline</p>
                <div class="timeline-info">
                    <img src="../../../assets/icons-svg/date.svg" alt="Date">
                    <span class="label"> Start Date :</span>
                    <span class="value">{{eventDetails.event_start_date | date: dateFormat || '-'}}</span>
                </div>
                <div class="timeline-info">
                    <img src="../../../assets/icons-svg/date.svg" alt="Date">
                    <span class="label"> End Date :</span>
                    <span class="value">{{eventDetails.event_end_date | date: dateFormat || '-'}}</span>
                </div>
            </div>
        </div>

        <div class="registration-timeline timeline-card">
            <div class="timeline-icon">
                <div class="timeline-icon-container">
                    <img src="../../../assets/icons-svg/registration.svg" alt="Registration-date">
                </div>
            </div>
            <div class="timeline-details">

                <p class="timeline-heading">Registration Timeline</p>
                <div class="timeline-info">
                    <img src="../../../assets/icons-svg/date.svg" alt="Date">
                    <span class="label"> Start Date :</span>
                    <span class="value">{{eventDetails.registration_start_date | date: dateFormat || '-'}}</span>
                </div>
                <div class="timeline-info">
                    <img src="../../../assets/icons-svg/date.svg" alt="Date">
                    <span class="label"> End Date :</span>
                    <span class="value">{{eventDetails.registration_end_date | date: dateFormat || '-'}}</span>
                </div>
            </div>
        </div>

        <div class="additional-info-card">
            <p class="timeline-heading">Additional Info</p>
            <div class="additional-info-details first-part">
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/experience.svg" alt="Experience">
                    <span class="label">Experience :</span>
                    <span class="value">{{eventDetails.work_experience || 0}} {{(eventDetails.work_experience || 0) > 1 ? 'years' : 'year'}}</span>
                </div>
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/rupees.svg" alt="Minimum Salary">
                    <span class="label">Minimum Salary :</span>
                    <span class="value">{{eventDetails.min_salary || '0'}}/month</span>
                </div>
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/rupees.svg" alt="Maximum Salary">
                    <span class="label">Maximum Salary :</span>
                    <span class="value">{{eventDetails.max_salary || '0'}}/month</span>
                </div>
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/owner.svg" alt="Owner">
                    <span class="label">Owner :</span>
                    <span class="value">{{eventDetails.owner?.first_name}} {{eventDetails.owner?.last_name}}</span>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="additional-info-details first-part">
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/event.svg" alt="Event Type">
                    <span class="label">Event Type :</span>
                    <span class="value capitalize">{{eventDetails.event_type?.name || '--'}}</span>
                </div>
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/Institution.svg" alt="Institution Name">
                    <span class="label">Institution Name :</span>
                    <span class="value">
                        <ng-container *ngIf="eventDetails.institutes?.length; else noInstitution">
                            <span>{{ eventDetails.institutes[0]?.name || '--' }}</span>
                            <span *ngIf="eventDetails.institutes.length > 1">, {{ eventDetails.institutes[1]?.name || '--' }}</span>
                            <span *ngIf="eventDetails.institutes.length > 2" class="remainingItems-badge">
                              <p-badge
                                value="+{{ eventDetails.institutes.length - 2 }}"
                                styleClass="mr-2"
                                (mouseenter)="institutions_op.toggle($event)"
                                (mouseleave)="institutions_op.hide()">
                              </p-badge>
                              <p-overlayPanel #institutions_op>
                                <ng-template pTemplate>
                                  <div class="remainingItems">
                                    <p *ngFor="let institute of eventDetails.institutes.slice(2)">
                                      {{ institute.name || '--' }}
                                    </p>
                                  </div>
                                </ng-template>
                              </p-overlayPanel>
                        </span>
                    </ng-container>
                    <ng-template #noInstitution>
                      --
                    </ng-template>
                    </span>

                </div>
                <div class="additional-info-elememt">
                    <img src="../../../assets/icons-svg/location.svg" alt="Institution Address">
                    <span class="label">Institution Address :</span>
                    <span [ngClass]="{'capitalize': true}" class="value">{{eventDetails.institution_address || '--'}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="userPrivileges.has('job_role.view')" class="jobRole-card">
    <div class="jobRole-header card-header">
        <div>Job Role</div>
        <img *ngIf="userPrivileges.has('job_role.update')" src="../../../assets/icons-svg/edit.svg" alt="" (click)="showAddRole = true">
    </div>
    <div *ngIf="showAddRole">
        <p-sidebar class="addRole-slider" [(visible)]="showAddRole" styleClass="p-sidebar-lg" [showCloseIcon]="true"
            position="right" (onHide)="getEventDetails();">
            <app-role-add [data]="roleDetails"></app-role-add>
        </p-sidebar>
    </div>
    <div class="jobRoleDetails">
        <div class="roles-list">
            <div class="role {{ role.isActive ? 'active' : '' }}" *ngFor="let role of jobRoleList"
                (click)="openRole(role.roleId)">
                <p>{{role.roleName}}</p>
            </div>
        </div>
        <div class="jobRole-container">
            <div class="header jobRoleDetails-header">
                <p>{{roleDetails.name}}</p>
            </div>
            <div class="jobRoleDetails-content">
                <p class="jobRoleDetails-element" [style]="{color: 'var(--fc-light-dark)'}"><span>Requirements :
                    </span>{{roleDetails.requirement}}</p>
                <div class="roleDescription jobRoleDetails-element" #roleDescription>
                    <span>Role Description : <p
                            [style]="{color: 'var(--fc-light-dark)', fontWeight: 'var(--fw-regular)'}"
                            [ngClass]="{'limitHeight': !readMore && roleDescription.clientHeight > 100}" [innerHtml]="roleDetails.description">
                        </p></span>
                    <p (click)="readMore = !readMore" class="viewMore" *ngIf="roleDescription.clientHeight > 100">
                        {{readMore ? 'Read Less' : 'Read More'}}</p>
                </div>
                <p class="jobRoleDetails-element">
                    <span>Attachments: </span>
                    <ng-container *ngIf="roleDetails.attachment; else noAttachment">
                      <a [href]="roleDetails.attachment" target="_blank" class="view-link">
                        View
                      </a>
                    </ng-container>
                    <ng-template #noAttachment>
                      <span>No attachment found</span>
                    </ng-template>
                  </p> 

                <div class="locations-container" [ngClass]="{'scroll': !view_more_location}">
                    <div class="location-card" *ngFor="let location of locationDetails; index as i ">
                        <div class="location-header">
                            <p>Location</p>
                            <span [style]="{fontWeight: 'var(--fw-regular)'}">
                                <span [style]="{color: 'var(--fc-primary)', fontWeight: 'var(--fw-bold)'}">{{i +
                                    1}}</span>
                                OF {{roleDetails.locations?.length}}
                            </span>
                        </div>
                        <div class="locationDetails">
                            <div class="locationItem" *ngFor="let item of locationItems">
                                <label [for]="item.field">{{item.label}}</label>
                                <span [id]="item.field">
                                  <ng-container *ngIf="item.field === 'hire_manager_id'; else defaultDisplay">
                                    {{ location.hire_manager.first_name }} {{ location.hire_manager.last_name }}
                                  </ng-container>
                                  <ng-template #defaultDisplay>
                                    {{ location[item.field] }}
                                  </ng-template>
                                </span>
                              </div> 
                        </div>
                    </div>
                </div>
                <p (click)="toggleLocations()" class="viewMore" *ngIf="roleDetails?.locations?.length > 1">
                    {{view_more_location ? 'View Less' : 'View More'}}
                </p>
            </div>
        </div>
    </div>
</div>

<!-- <div *ngIf="!isLoading" class="accessManagament-card">
    <app-access-management></app-access-management>
</div> -->