<div class="otp-info-message" [style]="{backgroundColor: infoMessage.bgColor}" *ngIf="displayInfoMessage">
    <div class="info-icon"><i [class]="infoMessage.icon" ></i></div>
    <div class="info-content">{{ infoMessage.message }}</div>
</div>
<div class="opt-verification" *ngIf="!resetPasswordState">
    <div class="opt-popup">
        <div class="otp-container">
            <p class="otp-header mt-40" *ngIf="!pageLoading">
                <span *ngIf="sendResetLinkState">Email Verification</span>
            </p>
            <span class="email-field mt-40" style="width: 100%;margin: 30px 0 0;" *ngIf="!pageLoading">
                <div class="p-float-label">
                    <input [(ngModel)]="emailToVerify" class="inputEmail" type="email" id="inputtext" [pattern]="emailPattern" [required]="true" #resetLinkEmail="ngModel" pInputText/>
                    <label for="inputtext">Email ID<span class="imp">*</span></label>
                </div>
            </span>
            <div class="resend-container" *ngIf="resetPasswordState">
                <div class="resend-info">
                    <span (click)="sendResetLinkByEmail()" (keydown)="sendResetLinkByEmail()" class="resent-btn">Resend <strong>Reset Link</strong></span>
                </div>
            </div>
            <div *ngIf="sendResetLinkState && !pageLoading" class="card flex justify-content-center submit-btn mt-40" style="width: 100%; margin-top: 30px;">
                <p-button label="Send Reset Link" [ngClass]="{'disabled': !resetLinkEmail?.valid}" [loading]="btnLoading" (click)="sendResetLinkByEmail()" (keydown)="sendResetLinkByEmail()"></p-button>
            </div>
            <p-progressSpinner *ngIf="pageLoading" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                animationDuration="0.5s">
            </p-progressSpinner>
        </div>
    </div>
</div>
<div class="reset_password-container" *ngIf="resetPasswordState">
    <div class="reset_password-card">
        <div class="reset_password-popup">
            <p class="reset_password-header mt-40" *ngIf="!pageLoading && !redirecting">
                <span>Reset Password</span>
            </p>
            <span class="password-fields mt-40" style="width: 100%;margin: 30px 0 0;" *ngIf="!pageLoading && !redirecting">

                <form [formGroup]="passwordForm">
                    <span class="p-float-label" [style]="{display: 'flex', alignItems: 'center'}"> 
                        <input type="password" class="inputtext newPasswordInput" id="inputtext" pInputText formControlName="newPassword"
                            [ngClass]="{'invalid': clicked && passwordForm.controls['newPassword'].errors}">
                        <span *ngIf="!showNewPassword" class="eye-icon pi pi-eye" (click)="showToggle('newPasswordInput', 'newPassword')" (keydown)="showToggle('newPasswordInput', 'newPassword')"></span>
                        <span *ngIf="showNewPassword" class="eye-icon pi pi-eye-slash" (click)="showToggle('newPasswordInput', 'newPassword')" (keydown)="showToggle('newPasswordInput', 'newPassword')"></span>
                        <label for="inputtext">New Password <span [style]="{color: '#E24A47'}">*</span></label>
                    </span>

                    <span class="p-float-label" [style]="{display: 'flex', alignItems: 'center'}">
                        <input type="password" class="inputtext confirmPasswordInput" id="inputtext" pInputText formControlName="confirmPassword"
                            [ngClass]="{'invalid': clicked && passwordForm.controls['confirmPassword'].errors}" (paste)="false">
                        <span *ngIf="!showConfirmPassword" class="eye-icon pi pi-eye" (click)="showToggle('confirmPasswordInput', 'confirmPassword')" (keydown)="showToggle('confirmPasswordInput', 'confirmPassword')"></span>
                        <span *ngIf="showConfirmPassword" class="eye-icon pi pi-eye-slash" (click)="showToggle('confirmPasswordInput', 'confirmPassword')" (keydown)="showToggle('confirmPasswordInput', 'confirmPassword')"></span>
                        <label for="inputtext">Confirm Password <span [style]="{color: '#E24A47'}">*</span></label>

                    </span>
                    <div class="passwrod-error" *ngIf="passwordForm?.hasError('mismatch') && (passwordForm.get('newPassword')?.touched && passwordForm.get('confirmPassword')?.touched)">
                        Passwords do not match.
                    </div>
                    
                    <div class="passwrod-error" *ngIf="passwordForm.get('newPassword')?.hasError('pattern') && passwordForm.get('newPassword')?.touched">
                        Password must contain a Lowercase, Uppercase, Number, Special character and must be alteast 8 characters
                    </div>
                    <div class="card flex justify-content-center submit-btn mt-40" style="width: 96%; margin-top: 30px;">
                        <p-button label="Reset" (onClick)="confirm()" [loading]="btnLoading" [ngClass]="{'disabled': passwordForm.invalid}" ></p-button>
                    </div>
                    
                </form>
            </span>
            <span *ngIf="redirecting">
                <p class="otp-header mt-40">Password Reset Successfully!</p>
                <div [style]="{display: 'flex', alignItems: 'center', margin: '30px 0', justifyContent: 'center'}">
                    <p>Redirecting to login page...</p>
                    <p-progressSpinner  [style]="{height: '24px', width: '24px'}" strokeWidth="7"
                        animationDuration="0.5s">
                    </p-progressSpinner>
                </div>
            </span>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '30vw'}" header="Confirmation" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-toast></p-toast>