<div class="otp-container">
    <div class="pageInfo">
        <div style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
            <div style="display: flex;justify-content:space-between; align-items: center;">
                <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Mobile OTP Authentication</p>
            </div>
        </div>
    </div>
    <div class="toggle">
        <div class="info-card">
            <div [style]="{display: 'block', flexWrap: 'wrap'}">
                <div class="info-element">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'} ">
                        <p [style]="{color: 'var(--fc-light-dark)',marginBottom: '5px'}">Registration Form</p>
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <p-inputSwitch style="display: flex;" [(ngModel)]="p1Form" (onChange)="toggleChange()"></p-inputSwitch>
                            <p style="margin-left: 5px;color: var(--fc-light-dark);font-size: 14px;">(Enabling this feature mandates OTP verification for the registration form)</p>
                        </div>
                    </div>
                </div>
                <div class="info-element">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'} ">
                        <p [style]="{color: 'var(--fc-light-dark)',marginBottom: '5px'}">Before Interview</p>
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <p-inputSwitch style="display: flex;" [(ngModel)]="beforeInterview" (onChange)="toggleChange()"></p-inputSwitch>
                            <p style="margin-left: 5px;color: var(--fc-light-dark);font-size: 14px;">(Enabling this feature mandates OTP verification before Interview)</p>
                        </div>
                    </div>
                </div>
                
                <!-- Commented For Future Use -->

                <!-- <div class="info-element">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'} ">
                        <p [style]="{color: 'var(--fc-light-dark)',marginBottom: '5px'}">Before Assessment</p>
                        <p-inputSwitch [(ngModel)]="beforeAssessment" [disabled]="true"></p-inputSwitch>
                    </div>
                </div>
                <div class="info-element">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'} ">
                        <p [style]="{color: 'var(--fc-light-dark)',marginBottom: '5px'}">Document Submission Form</p>
                        <p-inputSwitch [(ngModel)]="documentSub" [disabled]="true"></p-inputSwitch>
                    </div>
                </div>
                <div class="info-element">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'} ">
                        <p [style]="{color: 'var(--fc-light-dark)',marginBottom: '5px'}">Offer Acceptance Form</p>
                        <p-inputSwitch [(ngModel)]="offerAcc" [disabled]="true"></p-inputSwitch>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>