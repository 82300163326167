import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { RoleServicesService } from '../../services/role-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedServicesService } from '../../services/shared-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
  providers: [MessageService],
})
export class RoleListComponent implements OnInit {
  showAddRole: boolean = false;
  rowData: any = null;
  roleList: Array<any> = [];
  pageLimits: Array<any> = [];
  status: MenuItem[] = [];
  selectedRoles: Array<any> = [];
  rolesCol: Array<any> = [];
  __rolesCol: Array<any> = [];
  _selectedColumns: any[] = [];
  colsToDownload: Array<any> = [];
  roleOptions: Array<any> = [];
  eventRole: object = {};
  filteredRoleList: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];

  //Filtering Array
  checkedColumns: Array<any> = [];
  selectedStatusElements: Array<any> = [];
  selectedRoleTypeElements: Array<any> = [];
  selectedDepartmentElements: Array<any> = [];

  // Boolean Var
  addRolesSlider: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  totalPages: number = 1;

  // String var
  order_by: string = 'asc';
  sort_by: string = 'id';
  statusSelected: string = 'All';
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  columnFieldId: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'roleList';
  privilege: any = {};

  constructor(
    private roleService: RoleServicesService,
    private messageService: MessageService,
    private _sharedService: SharedServicesService,
    private globalService: GlobalServicesService,
    private breadcrumbService: NgDynamicBreadcrumbService
  ) {
    this.pageLimits = [10, 25, 50, 100];
    this.status = [
      {
        label: 'All',
        command: () => {
          this.getRolesByFilter('All', 'All');
        },
      },
      {
        label: 'FullTime',
        command: () => {
          this.getRolesByFilter('fulltime', 'FullTime');
        },
      },
      {
        label: 'PartTime',
        command: () => {
          this.getRolesByFilter('parttime', 'PartTime');
        },
      },
      {
        label: 'Intern',
        command: () => {
          this.getRolesByFilter('intern', 'Intern');
        },
      },
      {
        label: 'Contract',
        command: () => {
          this.getRolesByFilter('contract', 'Contract');
        },
      },
      {
        label: 'Other',
        command: () => {
          this.getRolesByFilter('other', 'Other');
        },
      },
    ];

    this.rolesCol = [
      // { index: 1, field: 'id', header: 'Role ID' },
      { index: 2, field: 'roleType', header: 'Role Type' },
      { index: 3, field: 'requirement', header: 'Requirement' },
      { index: 4, field: 'location', header: 'Location' },
      { index: 5, field: 'owner', header: 'Owner' },
    ];

    this._selectedColumns = [
      // { index: 1, field: 'id', header: 'Role ID' },
      { index: 2, field: 'roleType', header: 'Role Type' },
      { index: 3, field: 'requirement', header: 'Requirement' },
      { index: 4, field: 'location', header: 'Location' },
      { index: 5, field: 'owner', header: 'Owner' },
    ];

    this.roleOptions = [
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.deleteRole(this.eventRole);
        },
      },
    ];
  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave),
  });

  ngOnInit(): void {
    this.getAllRoles();
    this.getColumnId();
    this.getSavedColumns();
    this.__rolesCol = this.rolesCol;
    this.colsToDownload = this.rolesCol;
    this.checkedColumns = this._selectedColumns;
    this._sharedService.emitChange('');
    this.privilege = this.globalService.privilegeDetails;
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    const breadcrumbs = [
      { label: 'Manage Job Roles', url: '' }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  // API functions
  // get all the roles
  getAllRoles = () => {
    this.loadingSpinner = true;
    this.roleList = [];
    this.roleService.getJobRolesFastApi(this.pageNumber, this.pageSize, this.order_by, this.sort_by).subscribe({
      next: (response: any) => {
        this.roleList = Object.keys(response.body.items).length !== 0 ? response.body.items : [];
        this.roleList.forEach((each) => {
          each.location = [];
          each.locations.forEach((eachLocation: any) => {
            each.location.push(eachLocation.state);
          });
          each.roleType = each.role_type.name;
          // this.status.forEach((eachStatus) => {
          //   eachStatus.label?.toLowerCase() === each.roleType
          //     ? (each.roleType = eachStatus.label)
          //     : null;
          // });
        });
        this.totalLength = response.body.total || 0;
        this.first = response.body.page || 1;
        this.last = response.body.pages || 10;
        this.pageSize = response.body.size || 10;
        this.totalPages = response.body.pages || 1;
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  };

  getFilterRoles = (status?: string) => {
    this.loadingSpinner = true;
    this.roleList = [];
    this.roleService
      .getFilterRoles(this.pageNumber, this.pageSize, status)
      .subscribe((response: any) => {
        this.roleList = response.data ? response.data.items : [];
        this.roleList.forEach((each: any) => {
          each.location = [];
          each.locationInformation.forEach((eachLocation: any) => {
            each.location.push(eachLocation.location);
          });
          this.status.forEach((eachStatus) => {
            eachStatus.label?.toLowerCase() === each.roleType
              ? (each.roleType = eachStatus.label)
              : null;
          });
        });
        this.totalLength = response.data ? response.data.totalItems : 0;
        this.loadingSpinner = false;
      });
  };

  deleteRole = (role: any) => {
    this.loadingSpinner = true;
    this.roleService.deleteJobRolesFastApi(role.id.toString()).subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res.body.detail
          });
          this.selectedRoles = [];
          this.getAllRoles();
        }
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  };

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map((e) => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get('columnName')?.value,
      columnValue: this.columnsToSave,
    });

    this.roleService
      .saveColumns(this.columnFieldId, [this.columnSettings.value])
      .subscribe((result: any) => {
        this.savedColumnSettings = result.data.roleSelectedColumns;
        this.loadingSpinner = false;
      });
    this.displaySaveColumnSettings = false;
  };

  getColumnId = () => {
    this.roleService.getSchool().subscribe((res) => {
      this.columnFieldId = res.data[0].id;
    });
  };

  getSavedColumns = () => {
    this.roleService.getSchool().subscribe((res) => {
      this.savedColumnSettings = res.data[0].roleSelectedColumns
        ? res.data[0].roleSelectedColumns
        : [];
    });
  };

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.roleService
      .deleteColumns(this.columnFieldId, columnId)
      .subscribe((res) => {
        this.savedColumnSettings = res.data.roleSelectedColumns;
        this.loadingSpinner = false;
      });
  };

  getEventVal = (event: Event) => {
    return (event.target as HTMLInputElement).value;
  };

  onSelectedRoleChange = (value: []) => {
    this.selectedRoles = value;
  };

  exportRole = (type: string) => {
    let checkedColumnFields: any = ['roleName'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.roleList.forEach((eachData) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'Excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE = type === 'Excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    let FILE_EXTENSION = type === 'Excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + FILE_EXTENSION
    );
  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  };

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  };

  applySelectedFilter = () => {
    if (
      this.selectedStatusElements.length ||
      this.selectedRoleTypeElements.length ||
      this.selectedDepartmentElements.length
    ) {
      this.filteredRoleList = [];

      let statusFilter = this.roleList.filter((element) =>
        this.selectedStatusElements.includes(element.status)
      );
      let roleTypeFilter = this.roleList.filter((element) =>
        this.selectedRoleTypeElements.includes(element.roleType)
      );
      let departmentFilter = this.roleList.filter((element) =>
        this.selectedDepartmentElements.includes(element.department)
      );

      this.filteredRoleList = [
        ...new Set([...statusFilter, ...roleTypeFilter, ...departmentFilter]),
      ];
      this.showFilterSidebar = false;
    } else {
      this.filteredRoleList = [];
    }
  };

  clearSelectedFilters = () => {
    this.selectedStatusElements = [];
    this.selectedRoleTypeElements = [];
    this.selectedDepartmentElements = [];
  };

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.rolesCol.filter((e) =>
      savedColumnValue.includes(e.field)
    );
  };

  openRoleDetails = (role: string) => {
    this.showAddRole = true;
    this.rowData = role;
    this.rowData.isUpdate = true;
  };

  // get roles by limit
  getRolesByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.statusSelected === 'All' ? this.getAllRoles() : this.getFilterRoles(this.statusSelected.toLowerCase());
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this.__rolesCol = this.rolesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.rolesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };

  // get roles by filter
  getRolesByFilter = (status: string, selectedStatus: string) => {
    this.statusSelected = selectedStatus;
    this.pageNumber = 1;
    this.first = 1;
    this.last =
      this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    status === 'All' ? this.getAllRoles() : this.getFilterRoles(status);
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index ? -1 : 1));
  }

  // For custom pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last =
      this.last + this.pageSize < this.totalLength
        ? this.pageSize + this.last
        : this.totalLength;
    this.pageNumber += 1;
    this.getAllRoles();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? ( this.last == this.totalLength ? this.totalLength - this.roleList.length : this.last - this.pageSize ) : this.pageSize;
    this.pageNumber -= 1;
    this.getAllRoles();
  }

  isLastPage(): boolean {
    return this.pageNumber === this.totalPages;; 
  }

  isFirstPage(): boolean {
    return this.first === 1;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.rolesCol.filter((col) => val.includes(col));
  }

  getHeaderVal(col: string) {
    let result = col.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  setEventRoleId(role: any) {
    this.eventRole = role;
  }
  onSort(event: any) {
    if (this.sort_by !== event.field || this.order_by !== (event.order === 1 ? 'asc' : 'desc')) {
      this.sort_by = event.field;
      this.order_by = event.order === 1 ? 'asc' : 'desc';
      this.statusSelected === 'All' ? this.getAllRoles() : this.getFilterRoles(this.statusSelected.toLowerCase());
    }
  }
}
