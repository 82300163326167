import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject, catchError, throwError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;

  public validToken = new Subject<any>();
  public privilege: any = {};
  options() {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '',
      Origin: window.location.origin,
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }
  constructor(private http: HttpClient,private globalService: GlobalServicesService) { }
  // metadataFastApi(): Observable<any> {
  //   return this.http.get(this.fast_api_url + '/metadata/company', this.options_fast_api());
  // }
  loginFastApi(payload: any): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('email', payload.email);
    formData.append('password', payload.password);
  
    const headers = new HttpHeaders({
      'company-uuid': localStorage.getItem('companyUUID') || '',
    });
  
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/auth', formData, {
      observe: 'response',
      headers
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // rest password fast api
  resetPasswordFastApi(reset_token: string, payload: any): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('reset_token', reset_token.toString());

    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/auth/reset-password`, payload, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  login(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/login', payload, this.options());
  }

  logout(): Observable<any> {
    return this.http.post(this.api_url + '/logout', {}, this.options());
  }

  isLoggedIn(email: string) {
    return this.http.get(this.api_url + '/login/' + email, this.options());
  }

  updatePassword(email:string, payload:any): Observable<any>{
    return this.http.post(this.api_url + '/login/update/recruiter/' + email, payload, this.options())
  }
}
