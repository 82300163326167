<div [ngStyle]="{'visibility':showEditor ? 'visible' : 'hidden'}">
  <div class="job-add-header">
    <div class="job-heading">Invite Candidate</div>
    <div class="send-invite_btn">
      <button pButton pRipple type="submit"
        (click)="sendInvite($event)"
        label="Send Invite"
        class="p-button-outlined button-align invite-btn"
        [disabled]="_selectedCategory == 'single-invite' ? inviteForm.invalid || disableInvite || loadSingleInvite : bulkEnable"
        [ngClass]="_selectedCategory == 'single-invite' ? {'valid': inviteForm.valid, 'invalid': disableInvite } : {'invalid' : bulkEnable}"
        [loading]="loadSingleInvite">
      </button>
    </div>    
    <!-- <div class="send-invite_btn"  *ngIf="_selectedCategory == 'bulk-invite'">
      <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite" [ngClass]="{'invalid' : bulkEnable}" [disabled]="bulkEnable"
        class="p-button-outlined button-align invite-btn valid" [loading]="loadSingleInvite"></button>
    </div> -->
  </div>
  <p-divider class="p-divider"></p-divider>
  <div class="jobForm">
    <div>
      <span class="radioButtons">
        <p-radioButton *ngIf="userPrivileges.has('candidate.invite')" 
                       value="single-invite" 
                       [(ngModel)]="_selectedCategory">
        </p-radioButton>
        <label class="radioBtn" for="single-invite" 
               *ngIf="userPrivileges.has('candidate.invite')">Single-Invite</label>
      
        <p-radioButton *ngIf="userPrivileges.has('candidate.bulk_action')" 
                       value="bulk-invite" 
                       [(ngModel)]="_selectedCategory">
        </p-radioButton>
        <label class="radioBtn" for="bulk-invite" 
               *ngIf="userPrivileges.has('candidate.bulk_action')">Bulk-Invites</label>
      </span>      
      <form *ngIf="_selectedCategory == 'single-invite'" class="departmentForm" [formGroup]="inviteForm">
        <div>
          <span class="p-float-label templateForm-elements input-field" style="margin-top: 40px;">
            <input type="text" id="first-name" pInputText formControlName="firstName">
            <label for="first-name">First Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="last-name" pInputText formControlName="lastName">
            <label for="last-name">Last Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="email" pInputText formControlName="email">
            <label for="email">Email ID <span [style]="{color: '#E24A47'}">*</span></label>
            <span style="display: block; font-size: 12px; color: #E24A47;"
              *ngIf="inviteForm.controls['email'].touched && inviteForm.controls['email'].invalid">
              <span *ngIf="inviteForm.controls['email'].value.length">Invalid Email ID</span>
              <span *ngIf="!inviteForm.controls['email'].value.length">Email ID Required</span>
            </span>
          </span>
          <span class="p-float-label templateForm-elements invite-dropdown input-field">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
              formControlName="gender" [required]="true" [options]="genderList"
              >
            </p-dropdown>
            <label for="eventName">Gender<span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label  eventForm-elements">
            <p-calendar [readonlyInput]="true" formControlName="date_of_birth" dateFormat="mm-dd-yy" dataType="date" id="inputtext" [maxDate]="currentDate"
                [showIcon]="true" [showTime]="false" >
            </p-calendar>
            <label for="inputtext">Date of birth <span class="imp">*</span></label>
        </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="mobileNumber" (keypress)="restrictNumber($event)" autocomplete="off" pInputText
              formControlName="mobileNumber">
            <label for="mobileNumber">Mobile Number <span [style]="{color: '#E24A47'}">*</span></label>
            <span style="display: block; font-size: 12px; color: #E24A47;"
              *ngIf="inviteForm.controls['mobileNumber'].touched && inviteForm.controls['mobileNumber'].invalid">
              <span *ngIf="inviteForm.controls['mobileNumber'].value?.length">Invalid Mobile Number</span>
              <span *ngIf="!inviteForm.controls['mobileNumber'].value?.length">Mobile Number Required</span>
            </span>
          </span>
          <span class="p-float-label templateForm-elements invite-dropdown input-field">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label" 
              formControlName="eventCategory" [required]="true" [options]="eventCategories" (onChange)="getAllEvents($event)">
            </p-dropdown>
            <label for="eventCategory">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
          </span>          
          <span class="p-float-label templateForm-elements invite-dropdown input-field">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
            formControlName="eventName" [required]="true" [options]="eventList" [filter]="true" filterBy="label"
            (onChange)="eventChange($event)" 
            [emptyMessage]="loadEventList ? 'Please Wait...' : 'No events found'">
        </p-dropdown>        
            <label for="eventName">Event Name <span [style]="{color: '#E24A47'}">*</span></label>
        </span>
          <span class="p-float-label templateForm-elements invite-dropdown input-field">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label" 
             formControlName="sourceType" [required]="true" [options]="sourceTypeList">
            </p-dropdown>
            <label for="sourceType">Source Type <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements invite-dropdown input-field">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
              formControlName="sourceName" [required]="true" [options]="sourceNameList">
            </p-dropdown>
            <label for="sourceName">Source Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
        </div>
        <div class="existing-user-table" *ngIf="userExist"> 
          <div class="table-header">
            <div class="table-title"><strong>Note: </strong>Below listed candidate have already present in other events kindly Proceed to acknowledge.</div>
          </div>
          <p-table [columns]="existingUserColumns" [value]="existingUserList" [responsive]="true" [loading]="loadExistingUser"
          [scrollable]="true" scrollDirection="both" responsiveLayout="scroll" dataKey="id" [scrollHeight]="'8rem'" [style]="{width: '54rem', height: '160px' }">
            <ng-template pTemplate="header" let-columns>
              <tr class="table-heading" [style]="{height: '40px'}">
                <th [pSortableColumn]="'email'" pFrozenColumn [style]="{width: '13vw', background: ' var(--clr-primary-accent) !important'}">
                  Email
                  <!-- <p-sortIcon [field]="'email'"></p-sortIcon> -->
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style]="{width: '13vw', background: ' var(--clr-primary-accent) !important'}">
                  {{col.header}}
                  <!-- <p-sor tIcon [field]="col.field"></p-sortIcon> -->
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr class="row-candidate-list">
                <td pFrozenColumn [style]="{width: '13vw'}">
                  <span class="box-data">{{rowData['email']}}</span>
                </td>
                <td *ngFor="let col of columns" [style]="{width: '13vw'}">
                  <!-- {{rowData[col.field]}} -->
                  <span [ngSwitch]="true">
                    <span *ngSwitchCase="col.field === 'invitedEvents'" class="eventName-op">
                      <span *ngIf="rowData[col.field] != null; else elseContent" style=" display: inline-flex; align-items: center; justify-content: flex-start;">
                        {{ getEventName(rowData[col.field][0]) }}

                        <div *ngIf="rowData[col.field].length > 1" class="remainingItems-badge">
                          <p-badge value="+{{rowData[col.field].length - 1}}" styleClass="mr-2"
                            (mouseenter)="events_op.show($event)"></p-badge>
                        </div>
                      </span>
                      <ng-template #elseContent>-</ng-template>
                      <p-overlayPanel #events_op [dismissable]="true">
                        <ng-template pTemplate>
                          <div class="remainingItems" (mouseenter)="events_op.show($event)" (mouseleave)="events_op.hide()">
                            <p *ngFor="let event of rowData[col.field].slice(1)">{{ getEventName(event) }}</p>
                          </div>
                        </ng-template>
                      </p-overlayPanel>
                    </span>
                    
                    <span *ngSwitchDefault class="box-data">
                      {{ rowData[col.field] ? rowData[col.field] : '-' }}
                    </span>
                  </span>
      
                </td>
              </tr>
            </ng-template>
          </p-table>
          <div id="proceedBtn">
            <button pButton pRipple type="submit" (click)="inviteSent.emit({ severity: 'info', summary: 'Message', detail: 'Process Cancelled' })" label="Cancel" style="margin-bottom: 20px; margin-left: 20px !important;"
              class="p-button-outlined button-align invite-btn">
            </button>
        </div>
        </div>
      </form>

      <form *ngIf="_selectedCategory == 'bulk-invite'" [formGroup]="bulkInviteForm">
        <div>
          <p class="template-download-label">Download Template to Invite Candidates</p>
          <div>
            <div class="template-download">
              <div class="template-file_info">
                <p class="pi pi-file-excel template-file-icon"></p>
                <p class="file-name">Sample_Bulk_Invite.xlsx</p>
              </div>
              <p (click)="sampleData()" class="template-download-icon"><em class="pi pi-arrow-down"></em></p>
            </div>
          </div>
          <span class="p-float-label templateForm-elements invite-dropdown input-field" style="margin-top: 20px;">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
              formControlName="eventCategory" [required]="true" [options]="eventCategories" (onChange)="getAllEvents($event)">
            </p-dropdown>
            <label for="eventCategory">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          
          <span class="p-float-label templateForm-elements invite-dropdown input-field">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
              formControlName="eventName" [required]="true" [options]="eventList" [filter]="true" filterBy="label"
              (onChange)="eventChange($event)" 
              [emptyMessage]="loadEventList ? 'Please Wait...' : 'No events found'">
            </p-dropdown>
            <label for="eventName">Event Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>          
          <p class="template-upload-label">Upload File to Invite Candidates in Bulk</p>
          <div class="profilePhoto form-details">
          <div *ngIf="!cohortUploaded">
              <div class="bulkUpload-dropzone" ngx-dropzone
              [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
              [multiple]="false" (change)="cohortUpload($event)">
                  <ngx-dropzone-label *ngIf="!uploadingFile">
                      <div class="dropzone-label">
                          <em class="pi pi-cloud-upload"></em>
                          <p class="upload-text">Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span>
                              or
                              Drag & Drop</p>
                          <p class="format-text">.XLS, .XLSX Formate only</p>
                      </div>
                  </ngx-dropzone-label>
                  <div *ngIf="uploadingFile">
                      <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                      </p-progressSpinner>
                  </div>
              </div>  
          </div>                    
          <div *ngIf="cohortUploaded">
              <div class="flex-dev uploaded-container" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
                  <div class="bulk-main" [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
                      <div class="file-icon" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center',
                          background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', width: '40px', height: '40px',marginRight: '15px'}">
                              <p class="pi pi-file template-file-icon" [style]="{fontSize : '24px',display: 'flex',alignItems: 'center', color: 'blue'}"></p>
                      </div>
                      <div class="cohort-file_info" [style]="{marginRight: '15px'}">
                        <span class="file-name" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 200px;">
                          {{ fileUploadPayload?.file_name }}
                      </span>
                          <p class="file-name" [style]="{color: '#AAAAAA'}">{{cohortFileSize}}</p>
                      </div>
                      <div class="cancel" (click)="onRemoveExcel()">
                          <p class="cohort-cancel-icon">
                          <em class=" report-download pi pi-times" [style]="{fontSize: '14px', fontWeight: 'bold', color: '#d5d5d5', 
                          display: 'flex', alignItems: 'center', justifyContent: 'center'}"></em></p>
                      </div>
                  </div>
              </div>
          </div>
          <div *ngIf="errorFileUrl" class="error-report-container"
          [style]="{marginTop: '25px', marginBottom: '25px', display: 'flex', alignItems: 'center'}">
          <button pButton 
                  type="button" 
                  label="Download Error Report" 
                  icon="pi pi-download" 
                  (click)="errorReportDownload()"
                  [style]="{background: '#FF5E5B', border: 'none'}">
          </button>
      </div>      
          </div>
        </div>
      </form>
  </div>
</div>
<p-toast></p-toast>