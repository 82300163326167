import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { JobServicesService } from 'src/app/services/job-services.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  @ViewChild('recruiter') recruiter!: ElementRef;
  jobDetails: any = [];
  selectedRecruiters: Array<any> = [Infinity];
  recruiters: Array<any> = []
  availableRecruiters: Array<any> = [];
  _availableRecruiters: Array<any> = [];

  selectedHiringManagers: Array<any> = [];
  hiringManagers: Array<any> = []
  availableHiringManagers: Array<any> = [];
  _availableHiringManagers: Array<any> = [];

  selectedTpo: Array<any> = [];
  tpo: Array<any> = []
  availableTpo: Array<any> = [];
  _availableTpo: Array<any> = [];

  panels: Array<any> = [];
  panelMembers: Array<any> = [];

  isLoading: boolean = false;
  readMore: boolean = false;
  editJobSlider: boolean = false;

  descHeight: number = 0;

  recruiterSearchTerm: string = '';
  hiringManagerSearchTerm: string = '';
  tpoSearchTerm: string = '';
  jobId: string = '';

  allMembers: any = [];
  selectedPanelMembers: string[] = [];

  isLoadingRecruiter: boolean = false;
  isLoadingManager: boolean = false;
  isLoadingTpo: boolean = false;

  showPanelAdd: boolean = true;
  addNewMember: boolean = false;

  constructor(private jobService: JobServicesService) { }

  ngOnInit(): void {
    this.jobId = window.localStorage.getItem('selectedJobId') || '';
    this.getJobDetails();
  }

  getJobDetails = () => {
    this.jobService.getJob(this.jobId).subscribe(res => {
      this.jobDetails = res.data;
    })
  }


}
