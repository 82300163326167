import { Component, OnInit } from '@angular/core';
import { EventServicesService } from 'src/app/services/event-services.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { RoleServicesService } from 'src/app/services/role-services.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  providers: [MessageService]
})
export class EventDetailsComponent implements OnInit {

  isLoading: boolean = false;
  readMore: boolean = false;
  view_more_location: boolean = false;
  addEventSlider: boolean = false;
  showAddRole: boolean = false;

  eventDetails: any = {};
  eventJobDetails: any = {};
  roleDetails: any = {};
  locationDetails: any = {};
  locationItems: Array<any> = [];
  jobRoleList: Array<any> = [];
  dateFormat: any;
  userPrivilages: any;
  userRole: string = '';
  userPrivileges: Set<string> = new Set();

  eventId: string = '';
  jobId: string = '';

  locationsLength: number = 0;

  constructor(
    private eventServices: EventServicesService,
    private breadcrumbService: NgDynamicBreadcrumbService,
    private globalService: GlobalServicesService,
    private roleService: RoleServicesService
    ) {
    this.locationItems = [
      { label: 'Country : ', field: 'country' },
      { label: 'State : ', field: 'state' },
      { label: 'City : ', field: 'city' },
      { label: 'Hiring Manager : ', field: 'hire_manager_id' },
      { label: 'Business Unit : ', field: 'business_unit' },
      { label: 'Openings : ', field: 'opening' },
      { label: 'Maximum Applicants :', field: 'max_applicant' },
      { label: 'Diversity Male :', field: 'diversity_male' },
      { label: 'Diversity Female :', field: 'diversity_female' },
      { label: 'Diversity Other :', field: 'diversity_other' },
    ]
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });    
    this.userPrivilages = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
    this.userRole = JSON.parse(this.globalService.decryptPrivileges()).userType;
    this.eventId = window.localStorage.getItem("selectedEventId") || '';
    this.jobId = window.localStorage.getItem("selectedJobId") || '';
    this.getEventDetails();
  }

  toggleLocations = () => {
    this.view_more_location = !this.view_more_location;
    if (!this.view_more_location) {
      this.locationDetails = this.roleDetails.locations.slice(0, 1);
    } else {
      this.locationDetails = this.roleDetails.locations;
    }
  }

  getEventDetails = () => {
    this.isLoading = true;
    this.eventServices.getSpecificEventFastApi(this.eventId).subscribe({
      next: (response: any) => {
        this.eventDetails = response.body ? response.body.event_details : {};
        this.eventDetails.id = this.eventDetails.uuid;
        let _jobRoles = this.eventDetails.job_roles && this.eventDetails.job_roles.length > 0 ? this.eventDetails.job_roles : [];
        this.jobRoleList = _jobRoles.map((role: any) => ({ roleName: role.name, roleId: role.id, isActive: false }));
        if (this.jobRoleList.length > 0) {
            this.jobRoleList[0].isActive = true;
            this.getJobRoles(this.jobRoleList[0].roleId);
        }
        const breadcrumbs = [
          {
            label: 'Manage Events',
            url: 'events'
          },
          {
            label: this.eventDetails.name,
            url: ''
          },
        ];
        this.breadcrumbService.updateBreadcrumb(breadcrumbs);
        localStorage.setItem('selectedEventName', this.eventDetails.name);
        this.isLoading = false;
      },
      error: (_err: any) => {
        this.isLoading = false;
        console.error('Error fetching event details:', _err);
      }
    });
    
  }

  getEventJobDetails = () => {
    this.eventServices.getEventJobById(this.eventId, this.jobId).subscribe(response => {
      this.eventJobDetails = response.data ? response.data : [];
    })
  }

  getJobRoles = (eventId: string) => {
    this.isLoading = true;
    this.roleService.getSpecificJobRolesFastApi(eventId).subscribe({
      next: (response: any) => {
        this.roleDetails = response.body ? response.body : [];
        this.locationsLength = this.roleDetails.locations?.length;
        this.locationDetails = this.roleDetails.locations?.slice(0, 1);
        this.isLoading = false;
      },
      error: (_err: any) => {
        console.error('Error fetching role details:', _err);
        this.isLoading = false;
      }
    });
  };
  

  openRole = (id: string) => {
    this.jobRoleList.forEach((role: any) => {
      if (role.roleId === id) {
        role.isActive = true;
        this.roleDetails = role
      } else {
        role.isActive = false;
      }
    })
    this.getJobRoles(id);
  }
}
