<div class="addUser-header">
    <div class="addUser-heading">{{ userHeader }}</div>
    <div class="header-btn">
        <button pButton pRipple type="submit" label="Cancel" class="p-button-outlined button-align save-btn"
            (click)="cancel()"></button>
        <button pButton pRipple type="submit" label="Save" class="p-button-raised p-button-text button-align"
            (click)="saveUser()" [loading]="saveBtnLoading"></button>
    </div>
</div>
<p-divider class="p-divider"></p-divider>

<form class="userForm" [formGroup]="addUserForm" (ngSubmit)="onSave()">

    <span class="p-float-label userForm-elements">
        <input type="text" class="inputtext" id="inputtext" pInputText formControlName="first_name"
            [ngClass]="{'p-filled': addUserForm.value.first_name,'invalid': clicked && addUserForm.controls['first_name'].errors}">
        <label for="inputtext">First Name <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('first_name')" name="First Name"></app-handle-error>
    </span>
    <span class="p-float-label userForm-elements">
        <input type="text" class="inputtext" id="inputtext" pInputText formControlName="last_name"
            [ngClass]="{'p-filled': addUserForm.value.last_name,'invalid': clicked && addUserForm.controls['last_name'].errors}">
        <label for="inputtext">Last Name <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('last_name')" name="Last Name"></app-handle-error>
    </span>

    <span class="p-float-label userForm-elements">
        <input type="text" class="inputtext" id="inputtext" pInputText formControlName="email"
            [ngClass]="{'p-filled': addUserForm.value.email, 'invalid': clicked && addUserForm.controls['email'].errors}">
        <label for="inputtext">Official Email ID <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('email')" name="Email"></app-handle-error>
        <div *ngIf="(addUserForm.get('email')?.hasError('pattern') && clicked)" class="email-error">
            <span
                [ngClass]="[(!addUserForm.get('email')?.valid && addUserForm.get('email')?.touched && addUserForm.get('email')?.dirty) ? 'visible-element' : 'hidden-element' ]">
                Enter a valid Email ID </span>
        </div>
    </span>

    <span class="p-float-label userForm-elements">
        <input (keypress)="restrictNumber($event)" [maxlength]="10" type="tel" class="inputtext" id="inputtext" pInputText formControlName="mobile_number"
            [ngClass]="{'p-filled': addUserForm.value.mobile_number, 'invalid': clicked && addUserForm.controls['mobile_number'].errors}">
        <label for="inputtext">Mobile Number <span [style]="{color: '#E24A47'}">*</span></label>
        <div class="passwrod-error" *ngIf="clicked && addUserForm.controls['mobile_number'].errors?.['pattern']">
            <p> Mobile Number must contain 10 digits</p>
        </div>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('mobile_number')"
            name="Mobile Number"></app-handle-error>
    </span>
    <ng-container *ngIf="!edit">
        <span class="p-float-label userForm-elements">
            <input type="password" class="inputtext passwordInput" id="inputtext" pInputText formControlName="password"
                [ngClass]="{'p-filled': addUserForm.value.password, 'invalid': clicked && addUserForm.controls['password'].errors}">
            <span *ngIf="!showPassword" class="eye-icon pi pi-eye" (click)="showToggle('passwordInput')" (keydown)="showToggle('passwordInput')"></span>
            <span *ngIf="showPassword" class="eye-icon pi pi-eye-slash" (click)="showToggle('passwordInput')" (keydown)="showToggle('passwordInput')"></span>
            <label for="inputtext">Password <span [style]="{color: '#E24A47'}">*</span></label>
            <div class="passwrod-error" *ngIf="clicked && addUserForm.controls['password'].errors?.['pattern']">
                <p>Password must contain a Lowercase, Uppercase, Number, Special character and must be alteast 8 characters</p>
            </div>
        </span>
        <span class="p-float-label userForm-elements">
            <input type="password" class="inputtext confirm_passwordInput" id="inputtext" pInputText formControlName="confirm_password"
                [ngClass]="{'p-filled': addUserForm.value.confirm_password, 'invalid': clicked && addUserForm.controls['confirm_password'].errors}">
                <span *ngIf="!showPassword" class="eye-icon pi pi-eye" (click)="showToggle('confirm_passwordInput')" (keydown)="showToggle('confirm_passwordInput')"></span>
                <span *ngIf="showPassword" class="eye-icon pi pi-eye-slash" (click)="showToggle('confirm_passwordInput')" (keydown)="showToggle('confirm_passwordInput')"></span>
                <label for="inputtext">Confirm Password <span [style]="{color: '#E24A47'}">*</span></label>
            <div class="passwrod-error" *ngIf="addUserForm?.hasError('mismatch') && (addUserForm.get('password')?.touched && addUserForm.get('confirm_password')?.touched)">
                Passwords do not match.
            </div>
        </span>
    </ng-container>

    <div>
        <span class="p-float-label userForm-elements userForm-description">
            <p-dropdown id="associated-role_dropdown" class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
                optionValue="value" (onChange)="onClick()" [options]="associativeRoles"
                formControlName="role_id" [required]="true"
                [styleClass]="clicked && addUserForm.controls['role_id'].errors ? 'invalid' : ''">
            </p-dropdown>
            <label for="dropdown">Associated Role <span class="imp">*</span></label>
            <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('role_id')" name="User type">
            </app-handle-error>
            <br>
            <em class="info-button" (click)="onClick()" (keydown)="onClick()"></em>
        </span>
        <div class="infoBox" *ngIf="!addUserForm.value.role_id">Please assign the role for this employee</div>
        <div class="infoBox" *ngIf="addUserForm.value.role_id && showInfo">{{ dis }}</div>
    </div>
    <p-toast></p-toast>
</form>