import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  formBuilder_api_url = environment.HOST.FORMBUILDER_API_URL;
  bulk_invite_url = environment.HOST.BULK_INVITE;
  fast_api_url = environment.HOST.FAST_API_URL;

  options() {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      schoolid: schoolId,
    });
    return { headers }
  }
  
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('token');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }

  constructor(private http: HttpClient,private globalService:GlobalServicesService) { }

  getEventFormDetailsFastApi(eventId: string, formId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/forms/${formId}`, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
    }
  
  validateCandidateFastApi(eventId: string, candidateId: string, formId: string | null): Observable<HttpResponse<any>> {
    const url = formId 
    ? `${this.fast_api_url}/candidates/info/${candidateId}/events/${eventId}?form_id=${formId}`
    : `${this.fast_api_url}/candidates/info/${candidateId}/events/${eventId}`;
    
    return this.http.get<HttpResponse<any>>( url, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
    }
  
  submitRegistrationFormFastApi(event_payload: any, eventId: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/forms/registration`,event_payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  submitDocumentationFormFastApi(event_payload: any, stageId: string): Observable<HttpResponse<any>> {
    console.log("event_payload",event_payload)
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/stages/${stageId}/forms/documentation`,event_payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getEventSpecificFormDetailsFastApi(eventId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/event-info`, {
        observe: 'response',
        ...this.options_fast_api_with_company_uuid()
      }).pipe(
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
    }

  getTemplate(): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/formBuilder/getFormTemplate', this.options());
  }

  saveUser(payload: any, formType: string, eventId: any, eventTitle: any, conditionOption: any, sourceTag: any, candidateId?: any): Observable<any> {
    if(eventId && eventTitle)
    {
      if(formType === 'P2' && conditionOption.email && conditionOption.mobile_number && conditionOption.countryCode)
      {
        return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}&countryCode=${conditionOption.countryCode}&mobile_number=${conditionOption.mobile_number}&email=${conditionOption.email}`, payload, this.options());
      }
      else
      {
        if(formType == 'p1resubmission')
        {
          return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}&sourceTag=${sourceTag}&candidateId=${candidateId}`, payload, this.options());
        }
        if(sourceTag)
        {
          return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}&sourceTag=${sourceTag}&candidateId=${candidateId}`, payload, this.options());
        }
        else
        {
          return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}`, payload, this.options());
        }
      }
    }
    else
    {
      if(sourceTag)
      {
        return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}/?sourceTag=${sourceTag}`, payload, this.options());
      }
      else
      {
        return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}`, payload, this.options());
      }
    }
    
  }

  saveOffer(payload: any, jobUserMappingId: string): Observable<any> {
    return this.http.post(this.formBuilder_api_url + '/offerForm/addFormDetails/?jobUserMappingId=' + jobUserMappingId, payload, this.options());
  }

  saveFeedback(payload: any, jobId: string, stageId: number, userId: string): Observable<any> {
    return this.http.post(this.formBuilder_api_url + '/userFeedback/?eventJobId=' + jobId + '&stageId=' + stageId + '&userId=' + userId, payload, this.options());
  }

  getFeedbackform(jobId: string, stageId: number, userId: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/userFeedback/getData/?eventJobId=' + jobId + '&stageId=' + stageId + '&userId=' + userId , this.options());
  }

  updateFeedback(payload: any, jobId: string, stageId: number, userId: string): Observable<any>{
    return this.http.put(this.formBuilder_api_url + '/userFeedback/updateData?eventJobId=' + jobId + '&stageId=' + stageId + '&userId=' + userId , payload, this.options());
  }

  getAllFeedbackform(jobId: string, userId: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/userFeedback/getData/?eventJobId=' + jobId + '&userId=' + userId , this.options());
  }

  getCandidateFormDetails(userId: string, formName?: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/formBuilder/getCandidateFormDetails/' + userId + `${formName ? '?formName='+formName : ''}`, this.options());
  }

  editFormDetails(payload: any, id: any): Observable<any>{
    return this.http.put(this.formBuilder_api_url + '/formBuilder/editFormDetails/' + id , payload, this.options());
  }

  getInviteData(inviteId: string): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/invite/candidates/' + inviteId, this.options());
  }

  downloadCandidateFormDocs(userId: string, email: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + `/formBuilder/downloadCandidateFormDocs/${userId}/${email}`, this.options());
  }
}
