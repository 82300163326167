import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { RoleServicesService } from 'src/app/services/role-services.service';
import { SettingsService } from 'src/app/services/settings.service';


@Component({
  selector: 'app-role-and-permission',
  templateUrl: './role-and-permission.component.html',
  styleUrls: ['./role-and-permission.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class RoleAndPermissionComponent implements OnInit {

  role_and_permission_list: any[] = [];
  module_slug_list: any = [];
  _module_slug_list: any = [];
  moduleList: any = [];
  isLoading: boolean = false;
  clicked: boolean = false;
  addRoleAndPermissionSidebar: boolean = false;
  saveBtnLoading: boolean = false;
  edit: boolean = false;
  saveDisable: boolean = false;
  roleData : any;

  roleAndPermissionForm: FormGroup;
  constructor(private settingService: SettingsService,private roleService: RoleServicesService, private messageService: MessageService, private confirmationService: ConfirmationService,private globalService: GlobalServicesService) {
    this.roleAndPermissionForm = new FormGroup({
      role_name: new FormControl('', [Validators.required]),
      module: new FormControl('', [Validators.required]),
      role_description: new FormControl('', [Validators.required]),
    });
    this.roleAndPermissionFormData();
   }
  ngOnInit() {
    this.getRoleAndPermission();
  }
  roleAndPermissionFormData(){
    this.isLoading = true;
    this.roleService.getRoleAndPermissionFormDataFastApi().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        // this.module_slug_list = res.body.permissions;
        this.module_slug_list = [
          {
            "label": "Roles",
            "slug": "role",
            "value": [
              {
                "id": 1,
                "name": "View Roles",
                "slug": "role.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 2,
                "name": "Create Roles",
                "slug": "role.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 3,
                "name": "Update Roles",
                "slug": "role.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 4,
                "name": "Delete Roles",
                "slug": "role.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Role Types",
            "slug": "role_type",
            "value": [
              {
                "id": 5,
                "name": "View Role Types",
                "slug": "role_type.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 6,
                "name": "Create Role Types",
                "slug": "role_type.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 7,
                "name": "Update Role Types",
                "slug": "role_type.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 8,
                "name": "Delete Role Types",
                "slug": "role_type.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Event Categories",
            "slug": "event_category",
            "value": [
              {
                "id": 9,
                "name": "View Event Categories",
                "slug": "event_category.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 10,
                "name": "Create Event Categories",
                "slug": "event_category.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 11,
                "name": "Update Event Categories",
                "slug": "event_category.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 12,
                "name": "Delete Event Categories",
                "slug": "event_category.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Event Types",
            "slug": "event_type",
            "value": [
              {
                "id": 13,
                "name": "View Event Types",
                "slug": "event_type.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 14,
                "name": "Create Event Types",
                "slug": "event_type.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 15,
                "name": "Update Event Types",
                "slug": "event_type.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 16,
                "name": "Delete Event Types",
                "slug": "event_type.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Users",
            "slug": "user",
            "value": [
              {
                "id": 17,
                "name": "View Users",
                "slug": "user.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 18,
                "name": "Create Users",
                "slug": "user.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 19,
                "name": "Update Users",
                "slug": "user.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 20,
                "name": "Delete Users",
                "slug": "user.delete",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 21,
                "name": "Export Users",
                "slug": "user.export",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Email Categories",
            "slug": "email_category",
            "value": [
              {
                "id": 22,
                "name": "View Email Categories",
                "slug": "email_category.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 23,
                "name": "Create Email Categories",
                "slug": "email_category.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 24,
                "name": "Update Email Categories",
                "slug": "email_category.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 25,
                "name": "Delete Email Categories",
                "slug": "email_category.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Email Templates",
            "slug": "email_template",
            "value": [
              {
                "id": 26,
                "name": "View Email Templates",
                "slug": "email_template.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 27,
                "name": "Create Email Templates",
                "slug": "email_template.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 28,
                "name": "Update Email Templates",
                "slug": "email_template.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 29,
                "name": "Delete Email Templates",
                "slug": "email_template.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Candidate Source/Types",
            "slug": "candidate_source",
            "value": [
              {
                "id": 30,
                "name": "View Candidate Source/Types",
                "slug": "candidate_source.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 31,
                "name": "Create Candidate Source/Types",
                "slug": "candidate_source.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 32,
                "name": "Update Candidate Source/Types",
                "slug": "candidate_source.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 33,
                "name": "Delete Candidate Source/Types",
                "slug": "candidate_source.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          }
        ];
        this.moduleList = this.module_slug_list.map((module: any) => {
          return { label: module.label, value: module.slug };
        });
      },
      error: (error:any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
      }
    });
  }
  getRoleAndPermission() {
    this.isLoading = true;
    this.roleService.getRoleAndPermissionFastApi().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.role_and_permission_list = res.body;
      },
      error: (error:any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
      }
    });
  }
  openSideBar(){
    this.roleData = [];
    this.clicked = false;
    if (this.module_slug_list.length > 0) {
      this.module_slug_list.forEach((module: any) => {
        module.value.forEach((permission: any) => {
          permission.isChecked = false;
        });
        module.isSelectedAll = false;
      });
    }
    this.addRoleAndPermissionSidebar = true;
    this.roleAndPermissionForm.reset();
  }
  cancelRoleAndPermission(){
    this.addRoleAndPermissionSidebar = false;
  }
  getAllPermissions() {
    return this.module_slug_list.flatMap((module:any) => module.permissions);
  }
  saveRoleAndPermission(){
    this.clicked = true;
    if(this.roleAndPermissionForm.invalid){
      return;
    }
    const allPermissions = this.getAllPermissions();
    const createRoleAndPermissionPayload = {
      name: this.roleAndPermissionForm.value.role_name,
      description: this.roleAndPermissionForm.value.role_description,
      permissions: allPermissions,
    };
    this.roleService.createRoleAndPermissionFastApi(createRoleAndPermissionPayload).subscribe({
      next: (response) => {
        if (response.status === 201) {
          this.roleAndPermissionForm.reset();
          this.roleData = [];
          this._module_slug_list = [];
          this.getRoleAndPermission();
          this.clicked = false;
          this.addRoleAndPermissionSidebar = false;
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
        }
        this.saveBtnLoading = false;
      },
      error: (_err) => {
        this.saveBtnLoading = false;
        this.roleData = [];
      }
    });
  }
  updateRoleAndPermission(){
    this.clicked = true;
    if(this.roleAndPermissionForm.invalid){
      return;
    }
    const allPermissions = this.getAllPermissions();
    const updateRoleAndPermissionPayload = {
      name: this.roleAndPermissionForm.value.role_name,
      description: this.roleAndPermissionForm.value.role_description,
      permissions: allPermissions,
    };
    this.roleService.updateRoleAndPermissionFastApi(this.roleData.id,updateRoleAndPermissionPayload).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.roleAndPermissionForm.reset();
          this.roleData = [];
          this._module_slug_list = [];
          this.getRoleAndPermission();
          this.clicked = false;
          this.addRoleAndPermissionSidebar = false;
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
        }
        this.saveBtnLoading = false;
      },
      error: (_err) => {
        this.saveBtnLoading = false;
      }
    });
  } 
  editOption(role:any){
    this.addRoleAndPermissionSidebar = true;
    this.clicked = false;
    this.edit = true;
    this.module_slug_list.forEach((module: any) => {
      module.value.forEach((slug: any) => {
        slug.isChecked = false;
      });
      module.isSelectedAll = false;
      module.permissions = [];
    });
    this.roleService.getSpecificRoleAndPermissionFastApi(role.id).subscribe({
      next: (res: any) => {
        this.roleData = [];
        this.isLoading = false;
        this.roleData = res.body ?? [];
        const rolePermissions = this.roleData.permissions;
        this._module_slug_list = [];
        if (this.module_slug_list.length > 0 && rolePermissions.length > 0) {
          this.module_slug_list.forEach((module: any) => {
            module.permissions = [];
            module.value.forEach((slug: any) => {
              slug.isChecked = rolePermissions.includes(slug.id);
              if (slug.isChecked) {
                module.permissions.push(slug.id);
              }
            });
            module.isSelectedAll = module.value.every((slug: any) => slug.isChecked);
          });
        }
        this.roleAndPermissionForm.patchValue({
          role_name: res.body.name ?? '',
          role_description: res.body.description ?? '',
        });
      },
      error: (error:any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
      }
    });
  }
  updateSelectedCheckboxes(slug: any, selected_all?: boolean) {
    if (selected_all) {
      slug.value.forEach((checkbox: any) => {
        checkbox.isChecked = slug.isSelectedAll;
      });
    }else{
      slug.isSelectedAll = slug.value.every((checkbox: any) => checkbox.isChecked);
    }
    slug.permissions = slug.value
      .filter((checkbox: any) => checkbox.isChecked)
      .map((checkbox: any) => checkbox.id);
    console.log('Updated selected checkboxes for', slug.label, ':', slug.permissions);
  }
  getModuleSlugList(){
    this.roleAndPermissionForm.value.module;
    this._module_slug_list = this.module_slug_list.filter((module: any) => module.slug === this.roleAndPermissionForm.value.module);
    console.log("🚀🔥 ~ file: role-and-permission.component.ts:496 ~ RoleAndPermissionComponent ~ getModuleSlugList ~ this.roleAndPermissionForm.value.module:", this.roleAndPermissionForm.value.module)
  }
}
