import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TalentPoolService } from 'src/app/services/talent-pool.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid'

@Component({
  selector: 'app-talentpool-add',
  templateUrl: './talentpool-add.component.html',
  styleUrls: ['./talentpool-add.component.css'],
  providers: [MessageService]
})
export class TalentpoolAddComponent implements OnInit {

  @Input() data: any;
  showEditor: boolean = true;
  templateData: string = 'https://neo-hire.s3.amazonaws.com/bulk-invite/bulkInvite-Template/Sample_Bulk_Invite.xlsx'
  mediaFile: File[] = [];
  mediaBase64: any = '';
  schoolCode: string = '';
  schoolId: string = '';
  uploadingFile: boolean = false;
  sendingSingleInvite: boolean = false;
  sendingBulkInvite: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;

  inviteUrl: string = '';
  link: string = '';
  inviteForm: FormGroup = new FormGroup({
    invites: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    emailID: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    source: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required)
  })
  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
    actionId: new FormControl('')
  })

  _selectedCategory: string = 'bulk-invite';
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;

  constructor(
    private talentPoolService: TalentPoolService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private globalService: GlobalServicesService
  ) { }

  ngOnInit(): void {
    this.schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    this.schoolCode = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_details?.subdomain : '';
  }

  ngOnDestroy(): void {
    this.inviteForm.reset();
    this.bulkInviteForm.reset();
  }
  
  setFormLink(){
    this.inviteUrl = 
        (this.schoolCode === 'nstech196' || this.schoolCode === 'wileyedge399' || this.schoolCode === 'kscollege408' || this.schoolCode === 'iamneo372' || this.schoolCode === 'wileyedge537') 
        ? (this._selectedCategory == 'single-invite' ? this.inviteForm.controls['eventCategory'].value : this.bulkInviteForm.controls['eventCategory'].value) 
        : 'application-form/invitation'
    this.bulkInviteForm.controls['link'].setValue(this.link);
  
    this.link = `${window.location.protocol}//${window.location.hostname}/${this.inviteUrl}`;
    this.bucketName = this.globalService.bucketName;
  }

  sampleData() {
    let filePath: string = 'manage-candidate/bulk-invite-template/Sample_Bulk_Invite.xlsx'
    let fileData: string = `${this.s3Domain}/${this.globalService.bucketName}/${filePath}`;
    window.open(fileData);
  }

  handleUpload(event: any, type: string) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (type === 'bulk-invite') {
        this.fileUpload(file, type)
      }
    };
  }


  onSelectMedia(event: any) {
    this.mediaFile.push(...event.addedFiles);
    this.handleUpload(event, 'bulk-invite')
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.bulkInviteForm.controls['file'].setValue('');
  }

  sendInvite() {
    if (this._selectedCategory === 'single-invite') {
      this.sendingSingleInvite = true;
      let payload = {
        "firstName": this.inviteForm.controls['firstName'].value,
        "lastName": this.inviteForm.controls['lastName'].value,
        "email": this.inviteForm.controls['emailID'].value,
        "source": this.inviteForm.controls['source'].value,
        "link": this.link
      }
      if(this.schoolCode === 'wileyedge399' || this.schoolCode === 'wileyedge537' || this.schoolCode === 'nstech196')
      {
        payload.source = 'P1-Form-mthree'
      }
      payload.source = payload.source.replace(/ /g, '%20');
      this.talentPoolService.singleInvite(payload).subscribe(res => {
        this.inviteForm.reset();
        this.sendingSingleInvite = false;
        if (res.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invite Sent Successfully' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable To Send Invite' });
        }
      })
    }
    else if (this._selectedCategory === 'bulk-invite') {
      this.sendingBulkInvite = true;
      this.bulkInviteForm.controls['link'].setValue(this.link);
      let payload = this.bulkInviteForm.value;
      payload.source = payload.source.replace(/ /g, '%20');
      payload.actionId = 'bulkInvite';
      delete payload.file;
      if(this.schoolCode === 'wileyedge399' || this.schoolCode === 'wileyedge537' || this.schoolCode === 'nstech196')
      {
        payload.source = 'P1-Form-mthree'
      }
      this.talentPoolService.bulkInvite(payload).subscribe((res) => {
        this.sendingBulkInvite = false;
        this.bulkInviteForm.reset();
        this.mediaFile = [];
        if (res.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invite Sent Successfully' });
        }
        else if(res.status == 500 && res.error) {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: res.error });
        }
        else{
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable To Send Invite' });
        }
      })
    }
  }

  fileUpload(file: any, type: string) {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `manage-candidate/${type}/${this.schoolId}/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              this.uploadingFile = false;
              if (r && r.status === 200) {
                let file = pay;
                this.bulkInviteForm.controls['Bucket_name'].setValue(file.Bucket_name);
                this.bulkInviteForm.controls['file_name'].setValue(file.file_name);
                this.bulkInviteForm.controls['file'].setValue(file);
              }
            }
          );
        }
      }
    );
  }
}
