<div class="roles-header">
  <div class="status">
    <p-splitButton label="Role Type: {{ statusSelected }}" [model]="this.status"
      styleClass="p-button-sm mr-2 mb-2 p-button-outlined p-button-secondary">
    </p-splitButton>
  </div>
  <div>
    <div *ngIf="showAddRole">
      <p-sidebar model="true" class="addRole-slider" [(visible)]="showAddRole" styleClass="p-sidebar-lg"
        [showCloseIcon]="true" position="right" (onHide)="getAllRoles()">
        <div style="position:relative">
          <app-role-add [data]="rowData"></app-role-add>
        </div>
      </p-sidebar>
    </div>
    <button *ngIf="privilege.editManageRoles" pButton pRipple class="p-button-raised" type="button" (click)="showAddRole = true; rowData = null"
      label="New Role"></button>
  </div>
</div>

<div class="table">
  <!-- Filter SideBar -->
  <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
    [style]="{ padding: '0', border: '2px solid white' }" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
    <p class="sidebar-title">Filter</p>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input type="text" pInputText placeholder="Search" [(ngModel)]="filterSearchTerm" />
    </span>

    <div class="filterSidebarContent">
      <p-accordion [multiple]="true" *ngFor="let col of _selectedColumns" [collapseIcon]="'pi pi-minus'">
        <p-accordionTab *ngIf="col.options" header="{{ col.header }}" [ngSwitch]="col.field">
          <span *ngSwitchCase="'status'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox name="status" [value]="option" [inputId]="option" [(ngModel)]="selectedStatusElements">
              </p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>

          <span *ngSwitchCase="'department'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox name="department" [value]="option" [inputId]="option"
                [(ngModel)]="selectedDepartmentElements"></p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>

          <span *ngSwitchCase="'jobType'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox name="jobtype" [value]="option" [inputId]="option" [(ngModel)]="selectedRoleTypeElements">
              </p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>
        </p-accordionTab>
      </p-accordion>

      <div class="sidebar-bottom">
        <div class="sidebar-bottom-apply">
          <p class="sidebar-bottom-text" (click)="displaySaveFilterSettings = true">
            Save Selected Filters
          </p>
          <div class="sidebar-bottom-buttons">
            <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
              (click)="clearSelectedFilters()"></button>
            <button pButton pRipple type="button" label="Apply" (click)="applySelectedFilter()"></button>
          </div>
        </div>

        <div class="sidebar-saveSettings" [ngClass]="{ hidden: !displaySaveFilterSettings }">
          <em class="pi pi-times" (click)="displaySaveFilterSettings = false"></em>
          <div class="p-inputgroup">
            <input type="text" pInputText placeholder="Enter Filter Name" />
            <button type="button" pButton pRipple label="Save"></button>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>

  <!-- Column Settings Sidebar -->
  <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
    [style]="{ padding: '0', border: '2px solid white' }" [modal]="false">
    <p class="sidebar-title">Column Settings</p>

    <div class="{{
        savedColumnSettings.length ? 'savedColumns-continer' : 'hidden'
      }}">
      <p>Saved Column</p>
      <div class="savedColumns" *ngFor="let column of savedColumnSettings"
        (click)="applySavedSettings(column.columnValue)">
        <p>{{ column.columnName }}</p>
        <em class="pi pi-trash" (click)="deleteSavedColumns(column.columnId)"></em>
      </div>
    </div>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input type="text" pInputText placeholder="Search" [(ngModel)]="columnSearchTerm"
        (ngModelChange)="searchColumns($event)" />
    </span>

    <div *ngFor="let col of __rolesCol" class="field-checkbox">
      <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
      <label [for]="col.field" [style]="{ paddingLeft: '10px' }">{{
        col.header
        }}</label>
    </div>

    <div class="sidebar-bottom">
      <div class="{{
          displaySaveColumnSettings ? 'hidden' : 'sidebar-bottom-apply'
        }}">
        <p class="sidebar-bottom-text" (click)="displaySaveColumnSettings = true">
          Save Selected Columns
        </p>
        <div class="sidebar-bottom-buttons">
          <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
            (click)="clearSelectedColumns()"></button>
          <button pButton pRipple type="button" label="Apply" (click)="applySelectedColumns()"></button>
        </div>
      </div>

      <div class="sidebar-saveSettings" [ngClass]="{ hidden: !displaySaveColumnSettings }">
        <em class="pi pi-times" (click)="displaySaveColumnSettings = false"></em>
        <form class="p-inputgroup" [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()">
          <input type="text" pInputText placeholder="Enter Filter Name" formControlName="columnName" />
          <button type="submit" pButton pRipple label="Save"></button>
        </form>
      </div>
    </div>
  </p-sidebar>

  <!-- Download Sidebar -->
  <p-sidebar class="downloadSidebar jobListDownload" [transitionOptions]="'0ms'" [(visible)]="showDownloadSidebar"
    [style]="{ padding: '0', border: '2px solid white' }" [modal]="false">
    <p class="sidebar-title">Download Data</p>

    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input type="text" pInputText placeholder="Search" [(ngModel)]="downloadSearchTerm"
        (ngModelChange)="searchColumns($event)" />
    </span>

    <div *ngFor="let col of colsToDownload" class="field-checkbox">
      <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
      <label [for]="col.field" [style]="{ paddingLeft: '10px' }">{{
        col.header
        }}</label>
    </div>

    <div class="download-container">
      <p>Download as</p>
      <div class="radioButton-group">
        <div class="radioButton">
          <p-radioButton name="exportButtons" value="excel" inputId="excel" [(ngModel)]="downloadAs"></p-radioButton>
          <label for="excel" [style]="{
              color:
                downloadAs === 'excel'
                  ? 'var(--clr-primary)'
                  : 'var(--fc-light-dark)'
            }"><em class="pi pi-file"></em>Excel</label>
        </div>
        <div class="radioButton">
          <p-radioButton name="exportButtons" value="csv" inputId="csv" [(ngModel)]="downloadAs"></p-radioButton>
          <label for="csv" [style]="{
              color:
                downloadAs === 'csv'
                  ? 'var(--clr-primary)'
                  : 'var(--fc-light-dark)'
            }"><em class="pi pi-file-excel"></em>CSV</label>
        </div>
      </div>

      <button pButton pRipple type="button" label="Download" class="p-button-raised"
        (click)="downloadAs === 'excel' ? exportRole('Excel') : exportRole('csv')"></button>
    </div>
  </p-sidebar>

  <!-- Table -->
  <p-table #dt [columns]="_selectedColumns" [value]="filteredRoleList.length ? filteredRoleList : roleList"
    [resizableColumns]="true" [rows]="pageSize" [loading]="loadingSpinner" [scrollable]="true" scrollHeight="76vh"
    scrollDirection="both" responsiveLayout="scroll" dataKey="id" [selection]="selectedRoles"
    (selectionChange)="onSelectedRoleChange($event)" [exportFilename]="exportName" (onSort)="onSort($event)">
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="table-header-left">
          <button pButton class="p-button-text simple-btn{{
              showSettingsSidebar ? '-active' : ''
            }}" icon="pi pi-sliders-h" (click)="
              showSettingsSidebar = true;
              showFilterSidebar = false;
              showDownloadSidebar = false
            "></button>
          <!-- <button pButton class="p-button-text simple-btn{{
              showFilterSidebar ? '-active' : ''
            }}" icon="pi pi-filter" (click)="
              showFilterSidebar = true;
              showSettingsSidebar = false;
              showDownloadSidebar = false
            "></button> -->
          <button pButton class="p-button-text simple-btn{{
              showDownloadSidebar ? '-active' : ''
            }}" icon="pi pi-download" (click)="
              showDownloadSidebar = true;
              showSettingsSidebar = false;
              showFilterSidebar = false
            "></button>
          <p-divider layout="vertical"></p-divider>
          <label>Total List:
            <span [style]="{ color: '#333333' }">{{ totalLength }}</span></label>
        </span>
        <span class="table-menu">
          <p [style]="{ paddingRight: '10px' }">View</p>
          <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="getRolesByLimit()"
            [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"></p-dropdown>
          <p-divider layout="vertical"></p-divider>
          <button pButton class="disabled p-button-secondary p-button-text simple-btn">
            {{ first }} to {{ last }}
          </button>
          <button pButton (click)="prev()" [disabled]="isFirstPage()" icon="pi pi-angle-left"
            class="p-button-secondary p-button-text simple-btn"></button>
          <button pButton (click)="next()" [disabled]="isLastPage()" icon="pi pi-angle-right"
            class="p-button-secondary p-button-text simple-btn"></button>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr class="table-heading" [ngClass]="{
          moveTable:
            showFilterSidebar || showSettingsSidebar || showDownloadSidebar
        }">
        <th scope="col" [style]="{
            width: '5vw',
            justifyContent: 'center',
            padding: 0,
            margin: 0
          }" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th [style]="{ width: '15vw' }" scope="col" pSortableColumn="name" pResizableColumn pFrozenColumn>
          Role Name
          <p-sortIcon class="sort-icon" field="name"></p-sortIcon>
        </th>
        <th [style]="{ width: '10vw' }" scope="col" *ngFor="let role of columns"
          pResizableColumn>
          {{ role.header }}
        </th>
      </tr>
      <tr class="selection-operations-row" *ngIf="selectedRoles.length">
        <td pFrozenColumn class="selection-operations-options">
            <span>{{selectedRoles.length}} Selected</span>
            <button pButton pRipple label="Delete Job Role" (click)="deleteRole(selectedRoles[0])" [style]="{fontWeight: '500'}"
                [disabled]="selectedRoles.length > 1 || selectedRoles[0].candidateCount > 0"></button>
        </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="body" let-role let-columns="columns">
      <tr class="row-data-list" [ngClass]="{
          moveTable:
            showFilterSidebar || showSettingsSidebar || showDownloadSidebar
        }" [style]="{ cursor: 'pointer' }">
        <td [style]="{
            width: '5vw',
            justifyContent: 'center',
            padding: 0,
            margin: 0
          }" pFrozenColumn>
          <p-tableCheckbox [value]="role"></p-tableCheckbox>
        </td>
        <td (click)="privilege.editManageRoles && openRoleDetails(role)" [style]="{ width: '15vw' }" pFrozenColumn>
          {{ role.name }}
        </td>
        <td (click)="privilege.editManageRoles && openRoleDetails(role)" *ngFor="let col of columns" [style]="{ width: '10vw' }">
          <span [ngSwitch]="true">
            <span *ngSwitchCase="col.field === 'location'" class="locations-op">
              {{ role[col.field][0] }}
              <div *ngIf="role[col.field].length > 1" class="remainingItems-badge">
                <p-badge value="+{{ role[col.field].length - 1 }}" styleClass="mr-2" (mouseenter)="op.toggle($event)"
                  (mouseleave)="op.hide()"></p-badge>
              </div>
              <p-overlayPanel #op>
                <ng-template pTemplate>
                  <div class="remainingItems">
                    <p *ngFor="let roles of role[col.field].slice(1)">
                      {{ roles }}
                    </p>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>

            <span *ngSwitchCase="col.field === 'requirement'" class="locations-op">
              <span *ngIf="role[col.field]?.length; else other_content">{{role[col.field]}}</span>
              <ng-template #other_content>-</ng-template>
            </span>

            <span *ngSwitchDefault>
              {{ role[col.field] ? role[col.field] : '-'}}
            </span>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>