<div class="otp-info-message" [style]="{backgroundColor: infoMessage.bgColor}" *ngIf="displayInfoMessage">
    <div class="info-icon"><i [class]="infoMessage.icon" ></i></div>
    <div class="info-content">{{ infoMessage.message }}</div>
</div>
<div class="opt-verification">
    <div class="opt-popup">
        <div class="otp-container">
            <p class="otp-header mt-40" *ngIf="!pageLoading && verifyCategory === 'test'">Welcome {{candidateName}}!</p>
            <div *ngIf="!pageLoading && verifyCategory === 'test'" class="candidate-info">
                <div>
                    <span class="icon pi pi-building"></span>
                    <span class="bold">Company Name:</span>{{CompanyName}}
                </div>
                <div>
                    <span class="icon pi pi-book"></span>
                    <span class="bold">Test Name:</span>{{testName}}
                </div>
                <div>
                    <span class="icon pi pi-clock"></span>
                    <span class="bold">Test Duration:</span>{{testDuration}} minutes
                </div>
            </div>
            <p class="otp-header mt-40" *ngIf="!pageLoading && !enableMobile">
                <span *ngIf="sendOtpState && verifyCategory !== 'test'">Email Verification</span>
                <!-- <span *ngIf="verifyOtpState">OTP Verification</span> -->
            </p>
            <div *ngIf="mobileToVerify.length && enableMobile && !pageLoading">
                <p align="center" [style]="{fontSize: '14px', fontWeight: 'light',color: '#c3c3c3', marginTop: '15px'}">
                    To continue with the verification process, Kindly select either email id or mobile number to verify and proceed.
                </p>
            </div>
            <div class="radioButtons" *ngIf="mobileToVerify.length && enableMobile && !pageLoading">
                <div class="email-radio">
                    <p-radioButton value="email" [(ngModel)]="_selectedCategory" [disabled]="verifyMail">
                    </p-radioButton><label class="radioBtn" for="single-invite">Email ID</label>
                </div>
                <div class="mobile-radio">
                    <p-radioButton value="mobile" [(ngModel)]="_selectedCategory" [disabled]="verifyMobile">
                    </p-radioButton><label class="radioBtn" for="bulk-invite">Mobile Number</label>
                </div>
            </div>

            <span class="email-field mt-40" style="width: 100%;margin: 10px 0 0;" *ngIf="!pageLoading">
                <div class="p-float-label" *ngIf="_selectedCategory == 'email'">
                    <input [(ngModel)]="emailToVerify" [placeholder]="emailToVerify" class="inputEmail" type="email" inputtext pInputText [disabled]="true"/>
                    <label for="inputtext">Email ID<span class="imp">*</span></label>
                </div>
                <div class="p-float-label" *ngIf="_selectedCategory == 'mobile'">
                    <input [(ngModel)]="mobileToVerify" class="inputMobile" type="mobile" id="inputnumber" pInputText [disabled]="true"/>
                    <label for="inputnumber">Mobile Number<span class="imp">*</span></label>
                </div>
            </span>

            <span class="p-float-label otp-field" style="width: 100%;margin-top: 10px;" *ngIf="!pageLoading && verifyOtpState">
                <input [(ngModel)]="otpValue" (keypress)="restrictNumber($event)" #otp_value="ngModel" class="inputOtp" 
                    onwheel="return false;" (ngModelChange)="otpValueChange($event)" style="max-width: 100% !important; width: 100%;" id="inputtext" pInputText/>
                <label for="inputtext">Enter OTP</label>
                <span *ngIf="clicked && invalidOTP" class="otpError" >{{_selectedCategory == 'email' ? 'Please enter the valid OTP sent to your email ID' : 'Please enter the valid OTP sent to your mobile number'}}</span>
                <span *ngIf="clicked && expiredOTP" class="otpError" >OTP Expired</span>
            </span>
            <div class="resend-container" *ngIf="verifyOtpState">
                <div class="otp-info">Please enter 6 digit OTP</div>
                <div class="resend-info">
                    <span *ngIf="waitToResend" class="resent-timer">Resend in <strong>{{countdown}}s</strong></span>
                    <span *ngIf="!waitToResend" (click)="resendOtp()" class="resend-btn">
                        <span *ngIf="!loadResend">Resend</span>
                        <p-progressSpinner *ngIf="loadResend" [style]="{height: '20px', width: '20px', marginRight: '30px'}" strokeWidth="7"
                            animationDuration="0.5s">
                        </p-progressSpinner>
                    </span>
                </div>
            </div>
            

            <div *ngIf="sendOtpState && !pageLoading" class="card flex justify-content-center submit-btn mt-40" style="width: 100%; margin-top: 30px;">
                <p-button label="Send OTP" [loading]="btnLoading" (click)=" verifyCategory ==='test' ? sendTestOtp() : sendOtpByEmail()"></p-button>
            </div>

            <div *ngIf="verifyOtpState && !pageLoading" class="card flex justify-content-center submit-btn mt-40" style="width: 100%; margin-top: 30px;">
                <p-button label="Verify" [loading]="btnLoading" (click)="verifyOtp()" [ngClass]="{'disabled': otpValue?.length != 6}" ></p-button>
            </div>

            <p-progressSpinner *ngIf="pageLoading" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                animationDuration="0.5s">
            </p-progressSpinner>
        </div>
    </div>
</div>