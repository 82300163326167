import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { ConfirmationService, MessageService } from 'primeng/api';

export interface FormList {
  id: string,
  formTitle: string,
  formPreviewLink: string
}
@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css'],
  providers: [ConfirmationService],

})
export class FormListComponent implements OnInit {
  formList: Array<FormList> = [];
  _formList: Array<FormList> = [];

  isLoading: boolean = false;

  formSearchTerm: string = '';
  formType: string = '';
  constructor(
    private breadcrumbService: NgDynamicBreadcrumbService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private router: Router,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.formType = params['formName'].replace(/%20/g,' ');
      console.log("Form Type:", this.formType);
    });
    this.getFormList();
  }
  getFormList() {
    this.isLoading = true;
    // API Call to get form list
    // For demo purpose, using static data
    this.formList = [
      {
        id: '1',
        formTitle: 'Form 1',
        formPreviewLink: 'https://example.com/form1'
      },
      {
        id: '2',
        formTitle: 'Form 2',
        formPreviewLink: 'https://example.com/form2'
      },
      {
        id: '3',
        formTitle: 'Form 3',
        formPreviewLink: 'https://example.com/form3'
      },
      {
        id: '4',
        formTitle: 'Form 4',
        formPreviewLink: 'https://example.com/form4'
      },
      {
        id: '5',
        formTitle: 'Form 5',
        formPreviewLink: 'https://example.com/form5'
      },
      {
        id: '6',
        formTitle: 'Form 6',
        formPreviewLink: 'https://example.com/form6'
      },
      {
        id: '7',
        formTitle: 'Form 7',
        formPreviewLink: 'https://example.com/form7'
      },
      {
        id: '8',
        formTitle: 'Form 8',
        formPreviewLink: 'https://example.com/form8'
      },
      {
        id: '9',
        formTitle: 'Form 9',
        formPreviewLink: 'https://example.com/form9'
      },
      {
        id: '10',
        formTitle: 'Form 10',
        formPreviewLink: 'https://example.com/form10'
      },
      {
        id: '11',
        formTitle: 'Form 11',
        formPreviewLink: 'https://example.com/form11'
      },
      {
        id: '12',
        formTitle: 'Form 12',
        formPreviewLink: 'https://example.com/form12'
      },
      {
        id: '13',
        formTitle: 'Form 13',
        formPreviewLink: 'https://example.com/form13'
      },
      {
        id: '14',
        formTitle: 'Form 14',
        formPreviewLink: 'https://example.com/form14'
      },
      {
        id: '15',
        formTitle: 'Form 15',
        formPreviewLink: 'https://example.com/form15'
      },
      {
        id: '16',
        formTitle: 'Form 16',
        formPreviewLink: 'https://example.com/form16'
      },
      {
        id: '17',
        formTitle: 'Form 17',
        formPreviewLink: 'https://example.com/form17'
      }]
    this._formList = this.formList;
    this.isLoading = false;
  }

  previewForm(form:any){
    console.log('Form-->',form)
    console.log('Form Type--->',this.formType)
    let inviteUrl = 'form/' + this.formType + '/preview'
    window.open(inviteUrl);
  }
  searchTemplate() {
    this._formList = this.formList.filter(val => val.formTitle.toLowerCase().includes(this.formSearchTerm.toLowerCase()));
  }
  openAddFormBuilder() {
    this.router.navigate(['form-builder/',`${this.formType}`])
  }
  deleteForm(form:any,event:any){
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this form?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
        this._formList = this.formList.filter(val => val.id != form.id);
        this.formList = this._formList;
        // this.formBuilderServices.deleteForm(form.id).subscribe((res) => {
        //   res.status == 200 && this.getFormList();
          this.messageService.add({
            severity: 'info',
            summary: 'Deleted',
            detail: 'Form deleted',
          });
        // });
        this.isLoading = false;
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
        });
      },
    });
  }
  editForm(form:any){
    this.router.navigate(['/form-builder', form.id]);
  }
  viewForm(form:any){
    
  }
}
