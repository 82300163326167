import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
    providedIn: 'root'
})

export class OtpService {
    api_url = environment.HOST.API_URL;
    host_api_url = environment.HOST.HOST_API_URL;
    formbuilder_api_url = environment.HOST.FORMBUILDER_API_URL;
    fast_api_url = environment.HOST.FAST_API_URL;
    domain_url = environment.HOST.DOMAIN_URL;

    options() {
        const schoolId = localStorage.getItem('schoolData')
            ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
            : '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Origin: window.location.origin,
            schoolid: schoolId, 
        });
        return { headers };
    }
    options_fast_api() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return { headers };
    }

    constructor(private http: HttpClient,private globalService: GlobalServicesService) { }

    //fast apis
    // verifyOtpFastApi(payload:any):Observable<any>{
    //     return this.http.post(this.fast_api_url + '/auth/verify-otp', payload, this.options_fast_api());
    // }
    // sendOtpFastApi(payload:any):Observable<any>{
    //     return this.http.post(this.fast_api_url + '/auth/resend-otp', payload, this.options_fast_api());
    // }
    options_fast_api_with_company_uuid() {
        const tokenString: string | null = localStorage.getItem('tokenFastApi');
        const token: any = tokenString ? JSON.parse(tokenString) : null;
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token.access_token}` : '',
          'company-uuid': localStorage.getItem('companyUUID') || ''
        });
        return { headers };
      }
    
    verifyOtpFastApi(payload: any): Observable<HttpResponse<any>> {
        return this.http.post<HttpResponse<any>>(this.fast_api_url + '/auth/verify-otp', payload, {
          observe: 'response',
          ...this.options_fast_api_with_company_uuid()
        }).pipe(
          catchError(this.globalService.handleError.bind(this.globalService))
        );
    }
    
    sendOtpFastApi(payload: any): Observable<HttpResponse<any>> {
        return this.http.post<HttpResponse<any>>(this.fast_api_url + '/auth/resend-otp', payload, {
          observe: 'response',
          ...this.options_fast_api_with_company_uuid()
        }).pipe(
          catchError(this.globalService.handleError.bind(this.globalService))
        );
    }
    sendForgotPasswordFastApi(payload: any): Observable<HttpResponse<any>> {
        return this.http.post<HttpResponse<any>>(this.fast_api_url + '/auth/forgot-password', payload, {
          observe: 'response',
          ...this.options_fast_api_with_company_uuid()
        }).pipe(
          catchError(this.globalService.handleError.bind(this.globalService))
        );
    }

    sendOtp(candidateId:string, eventId:string,payload?:any):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/' + candidateId + '/' + eventId, payload ?? {}, this.options());
    }

    sendOtpFP(email:string):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/forget/otp/' + email, {}, this.options());
    }
    
    verifyOtp(email: string, payload:any):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/' + email, payload, this.options());
    }

    getUserById(id:string):Observable<any>{
        return this.http.get(this.formbuilder_api_url + '/formBuilder/getUserById/' + id, this.options());
    }
    
    getUserByEmail(email:string):Observable<any>{
        return this.http.get(this.api_url + '/users/candidate/email/' + email, this.options());
    }

    getJobUserById(id:string):Observable<any>{
        return this.http.get(this.formbuilder_api_url + '/formBuilder/getJobUserById/' + id, this.options());
    }

    sendOtpByEmail(email:string, eventId: string):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/otp/' + email + '/' + eventId, {}, this.options());
    }

    sendOtpByMobile(payload:any,endPoint: string):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/mobileotp/send/'+ endPoint,payload, this.options());
    }

    verifyOtpByMobile(payload:any,endPoint: string):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/mobileotp/verify/'+ endPoint,payload, this.options());
    }

    ssoTestlink(payload:any):Observable<any> {
        return this.http.post(this.api_url + '/test/ssoLogin', payload, this.options());
    }


}