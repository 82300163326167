export const environment = {
  production: false,

  HOST: {
    FAST_API_URL: 'https://api.hire.exam.ly',
    DOMAIN_URL: 'http://hire.examly.localhost:8000',
    // DOMAIN_URL: 'http://hire.srv.localhost:8000',
    API_URL: 'https://neo-hire-xffvl2khoq-uk.a.run.app',
    HOST_API_URL: 'https://api.examly.net/api',
    FORMBUILDER_API_URL: 'https://neo-hire-form-builder-xffvl2khoq-uk.a.run.app',
    NODE_ENV: 'acc',
    BULK_INVITE: 'https://neo-hire-bulk-invite-xffvl2khoq-uk.a.run.app',
    SOCKET_LINK: 'wss://0i8kb3kr78.execute-api.us-east-1.amazonaws.com/dev'
  },
  ENCRYPTION: {
    ENCRYPTION_KEY: "J0RKHRnJTwRIqy5R"
  },
  FEEDBACK: {
    nstech196: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'mthree - Final Interview Feedback Form'
    },
    kscollege408: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'mthree - Final Interview Feedback Form'
    },
    wileyedge537: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'mthree - Final Interview Feedback Form'
    },
    wileyedge399: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'mthree - Final Interview Feedback Form'
    },
    iamneo372: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'Final Interview Feedback Form'
    },
  },
  favicon_Bucket: "dev-users-asset"
};