import { Component, OnChanges, OnInit } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { SocketService } from "../../services/socket.service";
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-interview-panel',
  templateUrl: './interview-panel.component.html',
  styleUrls: ['./interview-panel.component.css', '../workflow/workflow.component.css'],
  providers: [MessageService],
})

export class InterviewPanelComponent implements OnInit, OnChanges {
  jitsiAPI: any;
  interviewStarted: boolean = false;
  userDetails: any;
  schoolId: any;
  eventDetails: any;
  studentId: any;
  sideMenuItems: any = [];
  selectedCandidate: any = {};
  paramItems: any;
  userToEdit: any;

  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  feedbackSlider: boolean = false;
  editFeedback: boolean = false;
  viewFeedBack: boolean = false;
  createFeedBack: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  displayUserDetails: boolean = false;
  isInEvent!: boolean;
  showBulkUpload: boolean = false;
  assignSelected: boolean = false;
  usersList: any;
  displayLobbyCandidates: boolean = true;
  displayLiveCandidates: boolean = false;

  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'userList';
  selectedJobId: string = '';
  selectedEventId: string = '';
  bulkUpload!: MenuItem[];
  items: MenuItem[] = [];
  recruiterList: any = [];
  StatusColour: string = 'green'
  searchEmail: any;
  searchEmailList: string = "";
  formName: string = '';
  userCode: string = '';
  codeLang: string = '';

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  numberOfStages: number = 0;

  candidateList: any = [];
  ongoingList: any = [];
  stages: any = [];
  usersCol: Array<any> = [];
  _usersCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [10, 25, 50, 100];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  selectedUser: Array<any> = [];
  candidateStatus: any = {};
  excel: File[] = [];
  selectedMenuID: string = 'ongoing';
  userType: string = "";
  selectedUsersList: any = [];
  emails: any = [];
  editorOptions: any = [];
  assigneeList: Array<any> = []
  numberOfassigne: string = '';
  recordedVideoView: boolean = false
  submittedCodeView: boolean = false
  videoLink: any = ""
  isLoading: boolean = false;
  mins: any;
  dateFormat: any;
  timeFormat: any;

  constructor(private globalservice: GlobalServicesService,
    private eventServices: EventServicesService,
    private socketService: SocketService,
    private JobServicesService: JobServicesService,
    private _router: Router,
    private sanitizer: DomSanitizer,
    private globalService: GlobalServicesService,
    public messageService: MessageService) {
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '') : '';
    let allPrivilege: any = JSON.parse(this.globalservice.decryptPrivileges());
    this.userType = allPrivilege.userType;

    this.sideMenuItems = [
      { id: 'allCandidates', name: 'All Candidates', isActive: true, view: true },
      { id: 'ongoing', name: 'Ongoing', isActive: (this.userType == 'recruiter') ? true : false, view: true },
      { id: 'assigned', name: 'Assigned', isActive: false, view: true },
      { id: 'unassigned', name: 'Unassigned', isActive: false, view: true },
      { id: 'completed', name: 'Completed', isActive: false, view: true },
    ];



    // this.usersCol = [
    //   { index: 1, field: 'name', header: 'Name' },
    //   { index: 2, field: 'email', header: 'E-mail' },
    //   { index: 3, field: 'mobile_number', header: 'Phone' },
    //   { index: 4, field: 'stageName', header: 'Stage Name' },
    //   { index: 5, field: 'status', header: 'Status' },
    //   { index: 6, field: 'interviewDate', header: 'Interview Date' },
    //   { index: 7, field: 'recruiterName', header: 'Interviewer Name' },
    // ]

    // this._selectedColumns = [
    //   { index: 1, field: 'name', header: 'Name' },
    //   { index: 2, field: 'email', header: 'E-mail' },
    //   { index: 3, field: 'mobile_number', header: 'Phone' },
    //   { index: 4, field: 'stageName', header: 'Stage Name' },
    //   { index: 5, field: 'status', header: 'Status' },
    //   { index: 6, field: 'interviewDate', header: 'Interview Date' },
    //   { index: 7, field: 'recruiterName', header: 'Interviewer Name' },
    // ]

    // this.checkedColumns = this._selectedColumns;

  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    this.timeFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.time_format : '';

    this._usersCol = this.usersCol;
    this.colsToDownload = this.usersCol;
    this.checkedColumns = this._selectedColumns;
    this.selectedEventId = localStorage.getItem('selectedEventId') || '';
    this.schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';

    this.items = [{
      label: 'Options',
      items: [
        {
          label: 'View',
          icon: 'pi pi-eye',
          command: () => {
            this.openFeedback(this.userToEdit, 'view');
          }
        },
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.openFeedback(this.userToEdit, 'edit');
          }
        },
        {
          label: 'Download',
          icon: 'pi pi-download',
          command: () => {
            this.downloadFeedback(this.userToEdit.userId, this.userToEdit.stageId);
          }
        }
      ]
    },]

    let activeMenu = this.sideMenuItems.find((menu: any) => { if (menu.isActive) return menu });
    this.openSideMenu(activeMenu?.id);
    this.getRecruiterListByEvent()

    // if (this.userDetails.id && this.selectedEventId) {
    //   // Connect to the socket 
    //   this.socketService.connect(this.selectedEventId, this.userDetails.id);
    //   this.socketHandler()
    // } else {
    //   alert('Not a valid URL please check')
    // }
    this.addColumns();
  }

  addColumns = () => {
    let testCompletedStage: Array<any> = [];

    this.JobServicesService.getAllStagesFastApi(this.selectedEventId).subscribe(res => {
      this.stages = res.body.stages;

      testCompletedStage = this.stages.filter((stage: any) => stage.type == 'Test');
      testCompletedStage.forEach(stage => {
        this.usersCol.push({ 
          index: this.usersCol.length + 1, 
          field: 'testScore', 
          stageId: stage.uuid, 
          header: stage.name + ' Test Score' 
        });
        
        this._selectedColumns.push({ 
          index: this._selectedColumns.length + 1, 
          field: 'testScore', 
          stageId: stage.uuid, 
          header: stage.name + ' Test Score'
        });
      });

    })
  }

  getRecruiterListByEvent() {
    this.assignSelected = true;
    this.eventServices.getRecruiterListByEventFastApi(this.selectedEventId).subscribe((response) => {
      this.recruiterList.push(response)
      response.body.interviewers.forEach((user: any) => {
        this.assigneeList.push({ 'label': `${user.first_name} ${user.last_name}`, 'value': user.id, 'userId': user.id })
      });
    })
  }

  setColumns = (selectedMenuID: string) => {




    if (selectedMenuID === 'ongoing') {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'action', header: 'Join' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'mobile_number', header: 'Phone' },
        { index: 8, field: 'interviewDate', header: 'Interview Date' },
        { index: 9, field: 'startandEnd', header: 'Interview Time' },
        { index: 10, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 11, field: 'isFeedbackSubmitted', header: 'Feedback' }
      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'action', header: 'Join' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'mobile_number', header: 'Phone' },
        { index: 8, field: 'interviewDate', header: 'Interview Date' },
        { index: 9, field: 'startandEnd', header: 'Interview Time' },
        { index: 10, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 11, field: 'isFeedbackSubmitted', header: 'Feedback' }
      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol];

    } else if (selectedMenuID === 'unassigned') {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' }

      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' }

      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol];

    } else if (selectedMenuID === 'completed') {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },
        { index: 11, field: 'sourceCode', header: 'Submitted Code' },
        { index: 12, field: 'action', header: 'Recording' },
        { index: 13, field: 'mins', header: 'Duration' }
      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },
        { index: 11, field: 'submittedCode', header: 'Submitted Code' },
        { index: 12, field: 'action', header: 'Recording' },
        { index: 13, field: 'mins', header: 'Duration' }
      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol].filter((each) => each.index <= 9);

    } else {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },

      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name', isCapitalize: true },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },
      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol];
    }

  }

  viewRecordedVideo(Link: string) {
    if (Link) {
      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(Link)
    }
    this.recordedVideoView = true
  }

  tableRefresh() {
    this.getDataByLimitPage();
  }

  openSideMenu(selectedMenuID: string, event?: any) {
    this.selectedMenuID = selectedMenuID
    this.checkedColumns = [];
    this.selectedUsersList = [];
    let data = event?.target?.value;
    if (data?.length) {
      data = data.trim();
      this.searchEmail = data?.trim().split(/\s+/);
    }
    else if (this.searchEmailList?.length) {
      data = this.searchEmailList;
      this.searchEmail = data?.trim().split(/\s+/);
    }
    else {
      this.searchEmail = [];
    }
    this.sideMenuItems.map((menu: any) => {
      if (menu.id === selectedMenuID) {
        if (!menu.isActive) {
          this.searchEmailList = "";
          this.searchEmail = [];
        }
        this.pageSize = 10;
        menu.isActive = true;
        this.getUsersByLimit();
      } else {
        menu.isActive = false;
      }
    })
  }

  ngOnChanges(): void {
    this.jitsiAPI.addListener('videoConferenceLeft', () => {
      this.interviewStarted = false;
    })
  }

  splitLobbyCandidate(status: any) {
    this.setColumns(this.selectedMenuID)
    this.pageSize = 10;
    this.first = 1;
    this.last = 0;
    if (status === 'lobby') {
      this.displayLiveCandidates = false;
      this.displayLobbyCandidates = true;
      this.candidateList = this.ongoingList.filter((ele: any) => ele.status == 'Lobby');
      this.totalLength = this.candidateList.length || 0;
      this.searchEmailList = ''
      this.getLobbyUsers();
    }
    else if (status === 'live') {
      this.displayLiveCandidates = true;
      this.displayLobbyCandidates = false;
      this.candidateList = this.ongoingList.filter((ele: any) => ele.status == 'InMeeting');
      this.totalLength = this.candidateList.length || 0;
      this.searchEmailList = ''
      this.getLobbyUsers();
    }
  }

  searchEmailCandidate = (event: any) => {
    let data = event.target.value;
    this.searchEmail = data.trim().split(/\s+/);

  }

  candidateAssign = (assign: any, stagesOp: any) => {
    let payload = {
      "interviewer_id": assign.value,
      "candidate_details": this.selectedUsersList.map((user: any) => { return { "candidate_uuid": user.candidate_uuid, "stage_uuid": user.stage_uuid } }),
    }
    this.JobServicesService.assignInterviewerFastApi( this.selectedEventId, payload).subscribe(res => {
      if (res.body.detail){
        this.getDataByLimitPage();
        this.selectedUsersList = [];
        stagesOp.hide(assign);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
      }
    })
    this.selectedUsersList = [];
    stagesOp.hide(assign);
  }

  getUsersByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = 0;
    this.getDataByLimitPage();
  };

  setlast() {
    if (this.last === 0) {
      this.last = this.pageSize <= this.totalLength ? this.pageSize : this.totalLength;
    }
  }


  next(): void {
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getDataByLimitPage();
  }

  prev(): void {
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize >= this.totalLength ? this.totalLength - this.last : this.pageSize;
    this.pageNumber -= 1;
    this.getDataByLimitPage();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.usersCol.filter(e => savedColumnValue.includes(e.field));
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    // this.jobService.deleteColumnCandidates(this.selectedJobId, columnId).subscribe(res => {
    //   this.savedColumnSettings = res.data?.selectedColumns;
    //   this.loadingSpinner = false;
    // })
  }

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._usersCol = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [
      { index: 1, field: 'name', header: 'Name' },
      { index: 2, field: 'email', header: 'E-mail' }
    ];
  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    // if (this.isInEvent) {
    //   this.eventService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value], this.selectedEventId).subscribe(result => {
    //     this.savedColumnSettings = result.data.selectedColumns;
    //   })
    // } else
    //   this.jobService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value]).subscribe(result => {
    //     this.savedColumnSettings = result.data.selectedColumns;
    //   })

    this.loadingSpinner = false;
    this.displaySaveColumnSettings = false;

  }


  handleSelection = (event: any) => {
    this.selectedUsersList = event;
    this.emails = this.selectedUsersList.map((user: any) => user.email);
  }

  exportCandidate = (type: string) => {
    let checkedColumnFields: any = ['name'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.candidateList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  getEventDetails() {
    this.eventServices.getEventById(this.selectedEventId).subscribe(response => {
      this.eventDetails = response?.data;
      // this.candidateList = [...this.eventDetails]
    })
  }

  openJitsiContainer(data: any) {
    const userID = data.userId
    const eventId = this.selectedEventId
    this.eventServices.checkIfUserInLobby(eventId, userID).subscribe(async (response) => {
      if (response.status) {
        const candidateEmail = data.email;
        const url = this._router.serializeUrl(this._router.createUrlTree(['/jitsiContainer'], {
          queryParams: {
            userID: userID, eventId: eventId, c_userName: this.userDetails.name,
            candidateName: data.name, schoolId: this.schoolId, candidateEmail: candidateEmail,
            stageId: data.stageId, roomName: data.roomName
          }
        }));
        window.open(url, '_blank');

        this.JobServicesService.Assignedrecruiter([data?.id], this.userDetails.id, [data?.userId], this.selectedEventId).subscribe(async (response) => {
          return true
        })
        // this.eventServices.getJobUserMapping(eventId, userID).subscribe(async (response) => {

        //   this.JobServicesService.Assignedrecruiter([response?.data?.id], this.userDetails.id, [response?.data?.userId], this.selectedEventId).subscribe(async (response) => {
        //     console.log("response", response)
        //   })
        // })
      } else {
        this.messageService.add({ severity: 'success', summary: 'Unable to connect', detail: "Candidate not available in the lobby" });
        await this.updateInterviewStatus(data, 'Invited');
        await this.openSideMenu(this.selectedMenuID);
      }
    })
  }

  public updateInterviewStatus(data: any, status: string) {
    return new Promise((resolve) => {
      let payload = {
        id: data.id,
        status: status,
        userId: data.userId,
        eventId: data.eventId,
        schoolId: data.schoolId,
        stageId: data.stageId
      };
      this.eventServices.updateInterviewStatus(payload).subscribe((response) => {
        if (!response) {
          this.messageService.add({ severity: 'error', summary: 'Unable to update', detail: "Updation fail, Please contact Adminstrator" });
          resolve(false);
        } else {
          resolve(true);
        }
      });
    })
  };

  private socketHandler() {
    const sub = this.socketService.messageRecieved.subscribe((message: any) => {
      if (message && message.action) {
        // switch (message.action) {
        //   case "meetingEnd": {
        //     if(this.selectedMenuID == "ongoing"){
        //       this.getLobbyUsers();
        //       this.setColumns('ongoing')
        //       this.ongoingList = [];
        //       }
        //     break;
        //   };
        //   case "candidateStatusUpdate": {
        //     break;
        //   }
        // }
        if (message.action == "candidateStatusUpdate") {
          if (this.selectedMenuID == "ongoing") {
            this.getLobbyUsers();
          }
        } else {
          if (this.selectedMenuID == "ongoing") {
            this.getLobbyUsers();
            this.setColumns('ongoing')
            this.ongoingList = [];
          }
        }
      }
    })
  }

  openFeedback = (user: any, action?: string) => {
    this.loadingSpinner = true;
    this.eventServices.getParticularStage(this.selectedEventId, user.stageId).subscribe(res => {
      if (res.data?.scheduleInterview) this.formName = res.data.scheduleInterview.interviewType;
      this.loadingSpinner = false;
      if (action == 'edit') this.editFeedback = true;
      else if (action == 'view') this.viewFeedBack = true;
      else if (action == 'create') this.createFeedBack = true;
      this.paramItems = {
        eventId: this.selectedEventId,
        stageId: user.stageId,
        userID: user.userId,
        candidateEmail: user.email
      }
      this.feedbackSlider = true;
    })
  }

  downloadFeedback = (userId: string, stageId: number) => {
    this.loadingSpinner = true;
    this.eventServices.getFeedbackDownloadLink(this.selectedEventId, userId, stageId).subscribe((res: any) => {
      this.loadingSpinner = false;
      let downloadLink = res?.link;
      window.open(downloadLink);
    })
  }

  openViewCode = (user: any) => {
    this.userCode = user.submittedCode;
    this.codeLang = user.submittedLanguage;
    this.editorOptions = { theme: 'vs-dark', language: user.submittedLanguage };
    this.submittedCodeView = true;
  }

  showCandidateDetails(userDetail: any, col?: any) {
    if (col?.field === 'name') {
      this.selectedUser = [];
      this.isLoading = true;
      let email = userDetail.email;
      this.eventServices.getUserByEmail(email, this.selectedEventId).subscribe((res: any) => {
        this.isLoading = false;
        this.displayUserDetails = true;
        this.selectedUser = res?.data;
      })
    }
  }

  getDataByLimitPage() {
    switch (this.selectedMenuID) {
      case "allCandidates": {
        this.setColumns(this.selectedMenuID)
        this.getCandidatesByStatus("all");
        break;
      }
      case "ongoing": {
        this.setColumns(this.selectedMenuID)
        this.getCandidatesByStatus(this.selectedMenuID);
        break;
      }
      case "assigned": {
        this.setColumns(this.selectedMenuID)
        this.getCandidatesByStatus(this.selectedMenuID);
        break;
      }
      case "unassigned": {
        this.setColumns(this.selectedMenuID)
        this.getCandidatesByStatus(this.selectedMenuID);
        break;
      }
      case "completed": {
        this.setColumns(this.selectedMenuID)
        this.getCandidatesByStatus(this.selectedMenuID);
        break;
      }
    }
  }

  getCandidatesByStatus(interviewStatus: string) {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    this.JobServicesService.getInterviewCandidatesByStatusFastApi( this.selectedEventId, interviewStatus, this.pageNumber, this.pageSize).subscribe(
    (response: any) => {
      const tempData = response.body.items.map((data: any) => {
        return {
          ...data,
          userId: data.candidate_uuid,
          name: `${data.first_name} ${data.last_name}`.trim(),
          stageName: data.stage_name || '-',
          interviewDate: data.interview_start_at ? 
            moment(data.interview_start_at).format(this.dateFormat.toUpperCase()) : 
            '-',
          startandEnd: data.interview_start_at && data.interview_end_at ? 
            `${moment(data.interview_start_at).format(this.timeFormat === 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment(data.interview_end_at).format(this.timeFormat === 'hh:mm' ? 'hh:mm A' : 'HH:mm')}` : 
            '-',
          recruiterName: data.interviewer_first_name && data.interviewer_last_name ? 
            `${data.interviewer_first_name} ${data.interviewer_last_name}`.trim() : 
            '-',
          status: data.status || '-',
          mobile_number: data.mobile_number || '-',
          email: data.email || '-',
          stage_name: data.stage_name || '-'
        };
      });

      this.candidateList = tempData;
      this.totalLength = response.body.total;
      this.setlast();
      this.loadingSpinner = false;
    });
  }

  //All Candidates List
  // getAllCandidatesByJob() {
  //   this.candidateList = [];
  //   this.totalLength = 0;
  //   this.loadingSpinner = true;

  //   let payload = {
  //     "searchEmail": this.searchEmail,
  //     "recruiterId": null
  //   }
  //   this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'all', this.pageNumber, this.pageSize).subscribe((response: any) => {
  //     let tempData: any = []
  //     response.data.totalItems.map((ele: any) => {
  //       tempData.push(ele)
  //     })
  //     let recruiter_HM: any = {}
  //     this.assigneeList.forEach((list: any) => {
  //       recruiter_HM[`${list.userId}`] = list.label
  //     })

  //     tempData.forEach((data: any) => {
  //       if (data.interviewDate) {
  //         let dateFormat = this.dateFormat.toUpperCase();
  //         data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
  //       }
  //       if (data.startTime && data.endTime) {
  //         data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
  //       } else {
  //         data.startandEnd = '-'
  //       }
  //       data.recruiterName = recruiter_HM[`${data.recruiterId}`] || '-';
  //       if (data.status == null) '-';
  //       if (data.isFeedbackSubmitted) data.isFeedbackSubmitted = 'Submitted';

  //     })
  //     this.candidateList = tempData;
  //     this.totalLength = response.data.totalCount;
  //     this.setlast();
  //     this.loadingSpinner = false;
  //   });
  // }

  //ongoing List
  getLobbyUsers = () => {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    let payload: any = {
      "searchEmail": this.searchEmail
    }
    let lobbyStatus: string = "lobby";
    this.displayLobbyCandidates ? lobbyStatus = "lobby" : null;
    this.displayLiveCandidates ? lobbyStatus = "onGoing" : null;
    if (lobbyStatus === "lobby") {
      payload.recruiterId = null;
    }
    else if (lobbyStatus === "onGoing") {
      lobbyStatus = "inmeeting"
      payload.recruiterId = (this.userType !== 'admin' && this.userType !== 'hiringManager' && this.userType !== 'superAdmin') ? this.userDetails.id : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, lobbyStatus, this.pageNumber, this.pageSize).subscribe((response: any) => {
      let tempData: any = []
      response?.data?.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })
      tempData.forEach((data: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        data.recruiterName = recruiter_HM[`${data.recruiterId}`] ?? '-'
        if (data.status == null) '-';
        if (data?.isFeedbackSubmitted) {
          data.isFeedbackSubmitted = 'Submitted';
        }
      })
      this.candidateList = tempData;
      this.totalLength = response.data.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    })
  }

  //assigned List
  getAssignedCandidatesById() {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;

    let payload = {
      "searchEmail": this.searchEmail,
      "recruiterId": (this.userType !== 'admin' && this.userType !== 'hiringManager' && this.userType !== 'superAdmin') ? this.userDetails.id : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'assigned', this.pageNumber, this.pageSize).subscribe((response: any) => {
      let tempData: any = []
      response?.data?.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })

      tempData.forEach((data: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        data.recruiterName = recruiter_HM[`${data.recruiterId}`] || '-'
        if (data.status == null) '-';
        if (data.isFeedbackSubmitted) data.isFeedbackSubmitted = 'Submitted';
      })
      this.candidateList = tempData;
      this.totalLength = response.data.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    });
  }

  // unassigned List
  getUnAssignedCandidatesById() {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    let payload = {
      "searchEmail": this.searchEmail,
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'unAssigned', this.pageNumber, this.pageSize).subscribe((response: any) => {
      let tempData: any = []
      response?.data.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      tempData.map((data: any, index: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        if (data.recruiterId) {
          tempData.splice(index, 1)
        }
      })
      this.candidateList = tempData;
      this.totalLength = response?.data.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    });
  }

  // completed List
  getCompletedCandidate = () => {
    let start, end, total_seconds, h, m, s;
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    let payload = {
      "searchEmail": this.searchEmail,
      "recruiterId": (this.userType !== 'admin' && this.userType !== 'hiringManager' && this.userType !== 'superAdmin') ? this.userDetails.id : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'meetingend', this.pageNumber, this.pageSize).subscribe((response: any) => {
      let tempData: any = []
      response?.data?.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })

      tempData.forEach((data: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        data.recruiterName = recruiter_HM[`${data.recruiterId}`] || '-'
        if (data.status == null) '-';
        if (data.isFeedbackSubmitted == true) data.isFeedbackSubmitted = 'Submitted';
        if (data.duration) {
          if (data.duration.startDT && data.duration.endDT) {
            start = moment.utc(data.duration.startDT, "DD-MM-YYYY HH:mm:ss");
            end = moment.utc(data.duration.endDT, "DD-MM-YYYY HH:mm:ss");
            total_seconds = (end.valueOf() - start.valueOf()) / 1000;
            h = total_seconds / 3600;
            m = (h - Math.floor(h)) * 60;
            s = (m - Math.floor(m)) * 60;
            let time = {
              h: Math.floor(h),
              m: Math.floor(m),
              s: Math.floor(s)
            };
            data.mins = time.h + ' h ' + time.m + ' m ' + time.s + ' s';
          } else {
            data.mins = '-';
          }
        } else {
          data.mins = '-';
        }
      })
      this.candidateList = tempData;
      this.totalLength = response?.data?.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    })
  }

  capitalize(col: any) {
    let capitalizefield = ["name"];
    if (capitalizefield.includes(col.field)) {
      return true
    }
    return false
  }
}