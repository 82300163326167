<div *ngIf="pageLoading" class="{{'loadingOverlay'}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div *ngFor="let stage_rule of stage_rules; index as rule_index">
    <div *ngIf="!stage_rule.isShowSummary">
        <div class="header ruleSet-header">
            <p>Rule {{rule_index + 1}}</p>
            <div class="ruleSet-header">
                <em class="pi pi-times" *ngIf="stage_rule.id && stage_rules.length >=1" (click)="cancelRuleSet(stage_rules[rule_index], rule_index)" (keydown)="cancelRuleSet(stage_rules[rule_index], rule_index)"></em>
                <em class="pi pi-trash" [style]="{marginLeft: '10px'}" *ngIf="stage_rules.length > 1"
                    (click)="removeRule(stage_rule.id, $event,rule_index)"
                    (keydown)="removeRule(stage_rule.id, $event, rule_index)"></em>
            </div>
        </div>
        <div class="stage-content ruleSet-edit">
            <label class="ruleSet-label triggers-label" for="stage-trigger">When</label>
            <p-dropdown inputId="dropdown" optionLabel="name" [autoDisplayFirst]="false" [(ngModel)]="stage_rules[rule_index].trigger"
                [options]="stageTriggerOptions" class="ruleSet-trigger" (onChange)="getTriggers(stage_rules[rule_index].trigger,rule_index)">
            </p-dropdown>
            <label class="ruleSet-label conditions-label" for="stage-condition">Condition</label>
            <div class="stage-conditions" name="stage-condition">
                <p>Which one would you like to apply the rule?</p>
                <div class="radioButtons">
                    <ng-container *ngFor="let rule_condition of stage_rule_condition">
                        <p-radioButton name="conditions" [value]="rule_condition" [(ngModel)]="stage_rules[rule_index].stageCondition" [inputId]="rule_condition.id + rule_index">
                        </p-radioButton>
                        <label [for]="rule_condition.id + rule_index" [ngClass]="{'selected-radio': stage_rules[rule_index].stageCondition.id === rule_condition.id}">{{rule_condition.name}}</label>
                    </ng-container>
                </div>
                <div class="toggleLogic" *ngIf="stage_rules[rule_index].stageCondition.name === 'Matching Condition' && stage_rules[rule_index].conditionsList.length > 1">
                    <div class="radioButtons">
                        <ng-container *ngFor="let operator of stage_condition_operators">
                            <p-radioButton name="logic_operator" [value]="operator" [(ngModel)]="stage_rules[rule_index].logicCondition" [inputId]="operator.id + rule_index">
                            </p-radioButton>
                            <label [for]="operator.id + rule_index" [ngClass]="{'selected-radio': stage_rules[rule_index].logicCondition.id === operator.id}">{{operator.name}}</label>
                        </ng-container>
                    </div>
                </div>
                <div class="consitionSet" *ngIf="stage_rules[rule_index].stageCondition.name === 'Matching Condition'">
                    <div class="conditionSet-logic">
                        <span *ngFor="let operator of stage_rules[rule_index].logicOperator">{{stage_rules[rule_index].logicCondition.name}}<div class="flow-lines"></div>
                        </span>
                    </div>
                    <div [style]="{display: 'flex', flexDirection: 'column'}">
                        <div class="conditionSet-individual" *ngFor="let condition of this.stage_rules[rule_index].conditionsList">
                            <span class="consitionSet-index">{{this.stage_rules[rule_index].conditionsList.indexOf(condition)+1}}</span>
                            <span class="p-input-icon-right">
                                <em [ngClass]="{'pi pi-spin pi-spinner': loadConditionOptions}"></em>
                                <p-dropdown class="consitionSet-specific" [emptyMessage]="'Please select triggers'"
                                    optionLabel="name" [options]="condition.specific" [autoDisplayFirst]="false"
                                    [(ngModel)]="condition.values.conditionSource"
                                    (onChange)="selectOperatorOptions($event, condition)">
                                </p-dropdown>
                            </span>
                            <p-dropdown class="consitionSet-specific" optionLabel="sign" [options]="condition.operator"
                                [autoDisplayFirst]="false" [(ngModel)]="condition.values.conditionClause">
                            </p-dropdown>
                            <p-dropdown [ngClass]="{'dropdown-input_condition' : condition.values.conditionSource?.options?.length == 0}" [editable]="condition.values.conditionSource?.options?.length == 0"
                                class="consitionSet-specific" [options]="condition.values.conditionSource?.options"
                                [autoDisplayFirst]="false" [(ngModel)]="condition.values.conditionValue.value">
                            </p-dropdown>
    
                            <div class="consitionSet-icons">
                                <em class="pi pi-plus addIcon-plus" (click)="addCondition(condition, rule_index)" (keydown)="addCondition(condition, rule_index)"></em>
                                <em class="pi pi-minus removeIcon-minus"
                                    [ngClass]="{'disabled': stage_rules[rule_index].conditionsList.length <= 1}"
                                    (click)="removeCondition(condition, rule_index)" (keydown)="removeCondition(condition, rule_index)"></em>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="header-tabs" *ngIf="stage_rules[rule_index].stageCondition.name === 'Matching Condition'">
                <button class="tabs positive" [ngClass]="{'selected': stage_rules[rule_index].displayAction}"
                    (click)=" stage_rules[rule_index].displayAction = true; stage_rules[rule_index].displayElseAction = false">Positive</button>
                <button class="tabs negative" [ngClass]="{'selected': stage_rules[rule_index].displayElseAction}"
                    (click)=" stage_rules[rule_index].displayAction = false; stage_rules[rule_index].displayElseAction = true">Negative</button>
            </div>
    
            <label for="stage-action" class="actions-label ruleSet-label"
                *ngIf="stage_rules[rule_index].displayAction || stage_rules[rule_index].stageCondition.name == 'For All' ">Action</label>
            <div class="stage-actions" *ngIf="stage_rules[rule_index].displayAction || stage_rules[rule_index].stageCondition.name == 'For All' ">
                <div class="stage-actions-dropdown" *ngFor="let action of stage_rules[rule_index].actionsList">
                    <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.actions"
                        optionLabel="name" [(ngModel)]="action.values.actionName"
                        (onChange)="selectTemplateOptions($event.value.name, action)">
                    </p-dropdown>
                    <span class="p-input-icon-right">
                        <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.template"
                            (onChange)="setActionValues($event.value, action.values)" optionLabel="name" [filter]="true"
                            filterBy="name" [(ngModel)]="action.values.actionTemplate" *ngIf="action.template.length">
                        </p-dropdown>
                    </span>
                    <span *ngIf="stageError && action.values.actionName.name == 'Move Stage'"
                        [style]="{color: 'red', fontSize: '12px', marginLeft: '1vw'}">No stages to
                        move</span>
                    <em class="pi pi-minus deleteActionIcon removeIcon-minus" *ngIf="stage_rules[rule_index].actionsList.length > 1"
                        (click)="removeAction(action,rule_index)" (keydown)="removeAction(action,rule_index)"></em>
                    <span class="email-preview"
                        *ngIf="action.values.actionName.name === 'Send Email' || action.values.actionName.name === 'Send Test Link' || action.values.actionName.name === 'Send Document Form'"
                        [innerHTML]="action.values.actionTemplateContent">
                    </span>
                </div>
            </div>
            <em class="pi pi-plus addActionIcon addIcon-plus" (click)="addNewAction(stage_rules[rule_index].actionsList)" (keydown)="addNewAction(stage_rules[rule_index].actionsList)"
                *ngIf="stage_rules[rule_index].displayAction || stage_rules[rule_index].stageCondition.name == 'For All' "></em>
    
            <label for="stage-action" class="actions-label ruleSet-label"
                *ngIf="stage_rules[rule_index].displayElseAction && stage_rules[rule_index].stageCondition.name == 'Matching Condition' ">Action</label>
            <div class="stage-actions" *ngIf="stage_rules[rule_index].displayElseAction && stage_rules[rule_index].stageCondition.name == 'Matching Condition' ">
                <div class="stage-actions-dropdown" *ngFor="let action of stage_rules[rule_index].elseActionsList">
                    <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.actions"
                        optionLabel="name" [(ngModel)]="action.values.actionName"
                        (onChange)="selectTemplateOptions($event.value.name, action)">
                    </p-dropdown>
                    <span class="p-input-icon-right">
                        <em [ngClass]="{'pi pi-spin pi-spinner': loadActionOptions}"></em>
                        <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.template"
                            (onChange)="setActionValues($event.value, action.values)" optionLabel="name" [filter]="true"
                            filterBy="name" [(ngModel)]="action.values.actionTemplate" *ngIf="action.template.length">
                        </p-dropdown>
                    </span>
                    <span *ngIf="stageError && action.values.actionName.name == 'Move Stage'"
                        [style]="{color: 'red', fontSize: '12px', marginLeft: '1vw'}">No stages to
                        move</span>
                    <em class="pi pi-minus deleteActionIcon removeIcon-minus" *ngIf="stage_rules[rule_index].elseActionsList.length > 1"
                        (click)="removeElseAction(action,rule_index)" (keydown)="removeElseAction(action,rule_index)"></em>
                    <span class="email-preview"
                        *ngIf="action.values.actionName.name === 'Send Email' || action.values.actionName.actionEventId === 'Send Test Link' || action.values.actionName.actionEventId === 'Send Document Form'"
                        [innerHTML]="action.values.actionTemplateContent">
                    </span>
                </div>
            </div>
            <em class="pi pi-plus addActionIcon addIcon-plus"
                *ngIf="stage_rules[rule_index].displayElseAction && stage_rules[rule_index].stageCondition.name == 'Matching Condition' " (click)="addNewElseAction(stage_rules[rule_index].elseActionsList)" (keydown)="addNewElseAction(stage_rules[rule_index].elseActionsList)"></em>
    
            <div class="stageRuleSet-buttons clearAndSave-buttons">
                <button pButton pRipple type="button" label="Clear" class="clear p-button-outlined p-button-secondary"
                    (click)="clearRuleSet(stage_rules[rule_index])"></button>
                <button pButton pRipple type="button" label="Save" class="save p-button-outlined" [loading]="loadingButton"
                    (click)="saveWorkflow(stage_rule.id,rule_index)" [ngClass]="{'invalid': checkValidation(stage_rules[rule_index])}"
                    [disabled]="checkValidation(stage_rules[rule_index])"></button>
            </div>
        </div>
    </div>
    
    <div class="ruleSet-summary" *ngIf="stage_rule.isShowSummary">
        <div class="header ruleSet-header">
            <p>Rule {{rule_index + 1}}</p>
            <div class="ruleSet-header" *ngIf="userPrivileges.editManageEventsWorkflow">
                <em class="pi pi-pencil" (click)="editRule(stage_rules[rule_index])" (keydown)="editRule(stage_rules[rule_index])"></em>
                <em class="pi pi-trash" [style]="{marginLeft: '10px'}" (click)="removeRule(stage_rule.id, $event, rule_index)" (keydown)="removeRule(stage_rule.id, $event, rule_index)"></em>
            </div>
        </div>
        <div class="stage-content ruleSet-summary">
            <div class="summary-info">
                <p class="info-label">When</p>
                <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!stage_rules[rule_index].trigger"></p-skeleton>
                <p class="info-value" *ngIf="stage_rules[rule_index].trigger">{{stage_rules[rule_index].trigger.name}}</p>
            </div>
            <hr>
            <div class="summary-info">
                <p class="info-label">Condition</p>
                <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!stage_rules[rule_index].stageCondition">
                </p-skeleton>
                <div class="info-value" *ngIf="stage_rules[rule_index].stageCondition">
                    <p>{{stage_rules[rule_index].stageCondition.name}}</p>
                    <div *ngIf="stage_rules[rule_index].stageCondition.name === 'Matching Condition'" class="consitionSet">
                        <div class="conditionSet-logic">
                            <span *ngFor="let operator of stage_rules[rule_index].logicOperator">{{stage_rules[rule_index].logicCondition.name}}<div class="flow-lines">
                                </div>
                            </span>
                        </div>
                        <div [style]="{display: 'flex', flexDirection: 'column'}">
                            <div *ngFor="let condition of this.stage_rules[rule_index].conditionsList" class="conditionSet-summary">
                                <span class="consitionSet-index">{{this.stage_rules[rule_index].conditionsList.indexOf(condition)+1}}</span>
                                <p>{{condition.values.conditionSource.name}}</p>
                                <p>{{condition.values.conditionClause.clause}}</p>
                                <p>
                                    <span >
                                        {{condition.values.conditionValue.value}}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="summary-info">
                <p class="info-label">Positive Action</p>
                <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!stage_rules[rule_index].actionsList"></p-skeleton>
                <div [style]="{width: '100%'}" *ngIf="stage_rules[rule_index].actionsList">
                    <div class="info-value" *ngFor="let action of stage_rules[rule_index].actionsList; index as index">
                        <div [style]="{display: 'flex'}">
                            <p [style]="{width: '15vw'}"> {{action.values.actionName.name }}</p>
                            <p>{{action.values.actionTemplate?.name}}</p>
                        </div>
                        <p-divider *ngIf="stage_rules[rule_index].actionsList.length > 1 && index !== stage_rules[rule_index].actionsList.length - 1"
                            [type]="'dashed'">
                        </p-divider>
                    </div>
                </div>
            </div>
            <hr *ngIf="stage_rules[rule_index].stageCondition.name == 'Matching Condition' && stage_rules[rule_index].elseActionsList.length">
            <div class="summary-info" *ngIf="stage_rules[rule_index].stageCondition.name == 'Matching Condition' && stage_rules[rule_index].elseActionsList.length">
                <p class="info-label">Negative Action</p>
                <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!stage_rules[rule_index].elseActionsList"></p-skeleton>
                <div [style]="{width: '100%'}" *ngIf="stage_rules[rule_index].elseActionsList">
                    <div class="info-value" *ngFor="let action of stage_rules[rule_index].elseActionsList; index as index">
                        <div [style]="{display: 'flex'}">
                            <p [style]="{width: '15vw'}"> {{action.values.actionName.name }}</p>
                            <p>{{action.values.actionTemplate?.name}}</p>
                        </div>
                        <p-divider *ngIf="stage_rules[rule_index].elseActionsList.length > 1 && index !== stage_rules[rule_index].elseActionsList.length - 1"
                            [type]="'dashed'">
                        </p-divider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [style]="{display: 'inline-flex', alignItems: 'center', width: '100%'}">
    <button pButton pRipple type="button" label="Add Rule" (click)="addRule(stage)"
        class="p-button-raised p-button-text addRule-button"></button>
</div>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>