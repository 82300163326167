import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid';
import * as moment from 'moment';
import * as branchFile from '../../../assets/Json/branchList.json';
import * as collegeFile from '../../../assets/Json/collegeList.json';
import * as degreeFile from '../../../assets/Json/degreeList.json';
// import * as domicileFile from '../../../assets/Json/domicileState.json';
// import * as specializationFile from '../../../assets/Json/specializationList.json';
import { CandidateStatus } from '../candidate-list/candidate-list.component';
import { candidateStatus, statusColorMapping} from 'src/app/enum/candidate-status.enum';
import { ThisReceiver } from '@angular/compiler';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';


interface FilterCriteria {
  filterField: string,
  filterValue: string
}

interface InterviewLinkPayload {
  candidate_uuids: string[];
  start_date: string;
  start_time: string;
  duration: string;
}

interface InterviewBulkSchedule {
  error_file?: string;
  detail?: string;
}

@Component({
  selector: 'app-stage-candidates',
  templateUrl: './stage-candidates.component.html',
  styleUrls: ['./stage-candidates.component.css'],
  providers: [MessageService],
})
export class StageCandidatesComponent implements OnInit, OnChanges {
  @ViewChild('cohortOP') cohortOP: any;
  @ViewChild('cohortDropdown') cohortDropdown: any;
  @ViewChild('errorReport') errorReport: any;

  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event: any) {
    if (this.cohortDetails) {
      this.cohortOP.hide();
      this.cohortDropdown.nativeElement.blur();
      this.cohortDropdown.hide();
    }
  }

  @Input() stage: any;
  @Input() stages: any;
  @Input() contestId: string = '';
  @Input() testId: string = '';
  @Input() search: any = [];
  @Input() isFeedbackSelected: any;
  @Input() isTestSelected: any;
  @Input() emailsCleared: any;

  @Output() emptyStage = new EventEmitter<boolean>();
  @Output() emailList = new EventEmitter<any>();
  @Output() candidateCount = new EventEmitter<any>();

  pageLimits: Array<number> = [];
  candidatesCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  candidateList: any = [];
  selectedUsersList: any = [];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  _candidatesCol: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  colsToDownload: Array<any> = [];
  stageList: Array<any> = [];
  sourceData: Array<any> = [];
  emails: Array<string> = [];
  allCandidateDetails: any = [];
  excel: File[] = [];
  cohortExcel: File[] = [];
  template: File[] = [];
  cohortFile: File[] = [];
  cohortErroredFile: Array<any> = [];
  bucketData: any = {};
  statusOptions: Array<any> = [];
  timeSlots: Array<any> = [];
  copyTimeSlots: Array<any> = [];
  candidateStatus: any = {};
  // statusColor: Array<any> = [];
  loggedUserDetails: any = {};
  stageToMove: any = 0;
  activitySliderOptions: any = {};
  cohortStatuses: Array<any> = [];
  _cohortStatuses: Array<any> = [];
  selectedCohortStatus: any = {};
  newCohortStatus: any = {};
  eventUsers: any = [];
  selectedEventDetails: any;
  assignedCohortData: any = [];
  rangeDates: any = [new Date()];
  currentStatus: any;
  showJoiningDate: any;
  selectedJoinDate: any;
  selectedDegree: any;
  selectedBranch: any;
  selectedCollege: any;
  selectedSpecial: any = '';
  selectedYear: any = '';
  selectedRole: any = '';
  selectedArrears: any = '';
  selectedPwd: any = '';
  selectedGender: any = '';
  selectedEligibility: any = '';
  branchList: any = (branchFile as any).default;
  collegeList: any = (collegeFile as any).default;
  degreeList: any = (degreeFile as any).default;
  // specialList: any = (specializationFile as any).default;
  // domicileList: any = (domicileFile as any).default;

  marksPercentList: any;
  genderList: any;
  arrearList: any;
  eligibleList: any;
  pwdList: any;
  yearList: any;
  roleList: any;
  // ugList:any;
  // hscList:any;
  // sslcList:any;



  order_by: string = 'asc';
  sort_by: string = 'candidate_uuid';
  exportName: string = 'candidateList';
  cohortName: string = 'cohort_template';
  jobId: string = '';
  eventId: string = '';
  eventName: string = '';
  columnSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  scheduleRadio: string = 'all';
  companyId: string = '';
  fileBase64: string = '';
  templateFile: string = '';
  activityRemarks: string = '';
  interviewFileName: string = '';
  stageType: string = '';
  cohortNameTerm: string = '';
  delayedStyle: string = '';
  sendMailHeader: string = '';

  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displayUserDetails: boolean = false;
  displaySaveColumnSettings: boolean = false;
  showUploadSlider: boolean = false;
  showScheduleSlider: boolean = false;
  uploadingFile: boolean = false;
  cohortUploadingFile: boolean = false;
  loadingDownload: boolean = false;
  uploadBtnLoading: boolean = false;
  scheduleBtnLoading: boolean = false;
  cohortBtnLoading: boolean = false;
  inEditMode: boolean = false;
  sendLinkSlider: boolean = false;
  clicked: boolean = false;
  templateUploaded: boolean = false;
  cohortLoader: boolean = false;
  templateLoader: boolean = false;
  excelValidation: boolean = false;
  stageActivitySlider: boolean = false;
  locationJobRoleDetails: boolean = false;
  cohortDetails: boolean = false;
  createCohortTable: boolean = false;
  selectCohortTable: boolean = false;
  loadingCohort: boolean = false;
  errorMessage = { isError: false, message: 'Kindly fill all the fields!' };
  clickedCohort: boolean = false;
  show: boolean = false;
  cohortUploadSuccess: boolean = false;
  cohortUploadFailed: boolean = false;
  sendDocumentation: boolean = false;
  filterLoading: boolean = false;
  invalidNumber: boolean = false;
  selectedAllStageCandidates: boolean = false;

  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  timeDuration: number = 0;
  activeTabIndex: number = -1;
  filterCount: number = 0;
  testRange: [number, number] = [0, 100];
  startValue: number = 0;
  endValue: number = 100;
  totalPages: number = 1;
  numberOfStages: number = 0;

  firstValue: any;
  secondValue: any;
  selectedUser: any;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  startTime: any;
  endTime: any;
  templateSize: any;
  fileCopy: any;
  cohortUploadCopy: any;
  dateFormat: any;
  timeFormat: any;
  privilege: any;
  testStartRange: any;
  testEndRange: any;

  currentDate = new Date();
  joiningCurrentDate = new Date();

  ENV = environment.HOST.NODE_ENV;

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave),
  });

  sendLinkForm: FormGroup = new FormGroup({});
  eventCategory: string = '';
  sendEmailSlider: boolean = false;
  sendOfferMail: boolean = false;
  fromAddress = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_metadata?.from_email : '';
  filterCriteria: Array<FilterCriteria> = [];
  assessmentRange: any = [new Date()];
  contestValidityRange: any = [new Date()];
  filterApplied: boolean = false;
  durationSlots = this.generateDurationSlots();
  errorFileUrl: string = '';

  constructor(
    private jobService: JobServicesService,
    private eventServices: EventServicesService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private globalService: GlobalServicesService
  ) {
    this.jobId = localStorage.getItem('selectedJobId') || '';
    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.eventName = window.localStorage.getItem('selectedEventName') || '';

    this.companyId = localStorage.getItem('companyUUID') ?? '';

    this.pageLimits = [10, 25, 50, 100];

    this.candidatesCol = [
      { index: 1, field: 'email', header: 'E-mail' },
      { index: 2, field: 'status', header: 'Status', options: [{ label: 'All', value: '' }], selectedFilterElement: { label: 'All', value: '' } },
      { index: 3, field: 'stageId', header: 'Stage' },
      { index: 4, field: 'degree', header: 'Education' },
      { index: 9, field: 'mobile_number', header: 'Phone' },
      { index: 10, field: 'gender', header: 'Gender' },
      { index: 11, field: 'dob', header: 'Date Of Birth' }
    ]

    this._selectedColumns = [
      { index: 1, field: 'email', header: 'E-mail' },
      { index: 2, field: 'status', header: 'Status', options: [{ label: 'All', value: '' }], selectedFilterElement: { label: 'All', value: '' } },
      { index: 3, field: 'stageId', header: 'Stage' },
      { index: 4, field: 'degree', header: 'Education' },
      { index: 9, field: 'mobile_number', header: 'Phone' },
      { index: 10, field: 'gender', header: 'Gender' },
    ]

    this.timeSlots = this.generateTimeSlots();

    this.sendLinkForm = new FormGroup({
      candidates: new FormControl([]),
      interviewDate: new FormControl(null, [Validators.required]),
      startTime: new FormControl(null, [Validators.required]),
      duration: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    this.timeFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.time_format : '';

    this.privilege = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;

    this.addColumns();
    this.sourceData = [
      {
        fieldName: 'stageId',
        value: this.stage.stageId,
        fieldType: 'number',
      },
      {
        fieldName: 'jobStatus',
        value: 'pending',
        fieldType: 'string',
      },
    ];

    this.genderList = [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Prefer not to say", value: "others" }
    ]

    this.roleList = [
      {
        "label": "Software Development",
        "value": "Software Development"
      },
      {
        "label": "Production Management",
        "value": "Production Management"
      },
      {
        "label": "IT Support Specialist",
        "value": "IT Support Specialist"
      },
      {
        "label": "Site Reliability Engineer",
        "value": "Site Reliability Engineer"
      }
    ]
    this.arrearList = [
      {
        "label": "Yes",
        "value": "Yes"
      },
      {
        "label": "No",
        "value": "No"
      }
    ]
    this.eligibleList = [
      {
        "label": "Yes",
        "value": "Yes"
      },
      {
        "label": "No",
        "value": "No"
      }
    ]

    this.yearList = [
      { "label": "2023", "value": 2023 },
      { "label": "2022", "value": 2022 },
      { "label": "2021", "value": 2021 },
      { "label": "2020", "value": 2020 },
      { "label": "2019", "value": 2019 },
      { "label": "2018", "value": 2018 },
      { "label": "2017", "value": 2017 },
      { "label": "2016", "value": 2016 },
      { "label": "2015", "value": 2015 },
      { "label": "2014", "value": 2014 },
      { "label": "2013", "value": 2013 },
      { "label": "2012", "value": 2012 },
      { "label": "2011", "value": 2011 },
      { "label": "2010", "value": 2010 },
      { "label": "2009", "value": 2009 },
      { "label": "2008", "value": 2008 },
      { "label": "2007", "value": 2007 },
      { "label": "2006", "value": 2006 },
      { "label": "2005", "value": 2005 },
      { "label": "2004", "value": 2004 },
      { "label": "2003", "value": 2003 },
      { "label": "2002", "value": 2002 },
      { "label": "2001", "value": 2001 },
    ]
    this.pwdList = [
      {
        "label": "Yes",
        "value": "Yes"
      },
      {
        "label": "No",
        "value": "No"
      },
      {
        "label": "Do not wish to disclose",
        "value": "Do not wish to disclose"
      },

      {
        "label": "Not yet filled",
        "value": "Not Yet Filled"
      }
    ]

    this.statusOptions = this.stage.actions;
    console.log("🚀🔥 ~ file: stage-candidates.component.ts:405 ~ StageCandidatesComponent ~ ngOnInit ~ this.stage:", this.stage)
    this.candidatesCol.find((col: any) => col.field == 'status').options.push(...this.statusOptions);
    this._selectedColumns.find((col: any) => col.field == 'status').options.push(...this.statusOptions);

    const userDetails = localStorage.getItem('userDetailsFastApi');
    this.loggedUserDetails = userDetails ? JSON.parse(userDetails).user_profile : {};

    this.getCandidates();
    // this.getAllUsers();
    this._candidatesCol = this.candidatesCol;
    this.colsToDownload = this.candidatesCol;
    this.checkedColumns = this._selectedColumns;

    if (this.stages) {
      this.stages.forEach((stage: any) => {
        if (this.stage.stageId !== stage.stageId) {
          this.stageList.push({ name: stage.name, stageId: stage.stageId, type: stage.type });
        }
      });
    }

    this.candidateStatus = candidateStatus;
    this.copyTimeSlots = this.timeSlots;

    // this.statusColor = [
    //   { status: 'Applied', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'scheduled', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'addedToVettedPool', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'Slot Sent', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'Submitted', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'Joining Confirmed', color: 'var(--clr-violet-bg-light)' },
    //   { status: 'Offered', color: 'var(--clr-violet-bg-light)' },

    //   { status: 'Offer Accepted', color: 'var(--clr-green-bg-light)' },
    //   { status: 'Offer Revision', color: 'var(--clr-blue-bg-light)' },
    //   { status: 'Offer Rejected', color: 'var(--clr-red-bg-light)' },

    //   { status: 'selected', color: 'var(--clr-green-bg-light)' },
    //   { status: 'Shortlist', color: 'var(--clr-green-bg-light)' },
    //   { status: 'All Clear', color: 'var(--clr-green-bg-light)' },
    //   { status: 'Eligible', color: 'var(--clr-green-bg-light)' },
    //   { status: 'Joined', color: 'var(--clr-green-bg-light)' },

    //   { status: 'Waitlist', color: '#FFE0B4' },
    //   { status: 'No Show', color: '#FFE0B4' },
    //   { status: 'Submitted Partially', color: '#FFE0B4' },
    //   { status: 'Not Joining', color: '#FFE0B4' },

    //   { status: 'Rejected', color: 'var(--clr-red-bg-light)' },
    //   { status: 'Not Eligible', color: 'var(--clr-red-bg-light)' },
    //   { status: 'Not Joined', color: 'var(--clr-red-bg-light)' },

    //   { status: "Pending", color: 'var(--clr-blue-bg-light)' },
    //   { status: "Submission Pending", color: 'var(--clr-blue-bg-light)' },
    //   { status: 'Offer Pending', color: '--clr-blue-bg-light' },
    //   { status: 'Mail Pending', color: '--clr-blue-bg-light' },

    //   { status: "attended", color: '#E1E6CC' },
    //   { status: "Approved Partially", color: '#E1E6CC' },
    //   { status: "Future Candidate", color: '#E1E6CC' },
    //   { status: 'Yet To Join', color: '#E1E6CC' },

    //   { status: 'archived', color: 'var(--clr-grey)' },
    //   { status: 'On Hold', color: '#7a859275' },
    // ];
    this.eventCategory =
      window.localStorage.getItem('eventCategory') || 'technical';
    // this.getCohortStatuses();
    // this.getEventDetails();
  }

  generateTimeSlots() {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        slots.push({
          hour: hour.toString().padStart(2, '0'),
          minute: minute.toString().padStart(2, '0'),
          value: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
          formattedTime: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        });
      }
    }
    return slots;
  }

  generateDurationSlots() {
    const slots = [];
    for (let minutes = 15; minutes <= 180; minutes += 15) {
      slots.push({
        label: `${Math.floor(minutes/60)}h ${minutes%60}m`,
        value: minutes
      });
    }
    return slots;
  }

  validateEndTime() {
    const startDate = this.sendLinkForm.get('interviewDate')?.value ;
    const startTime = this.sendLinkForm.get('startTime')?.value;
    const duration = this.sendLinkForm.get('duration')?.value;

    if (startDate && startTime && duration) {
      const startDateTime = new Date(startDate);
      const [hours, minutes] = startTime.split(':');
      startDateTime.setHours(parseInt(hours), parseInt(minutes));
      
      const endDateTime = new Date(startDateTime.getTime() + duration * 60000);
      const maxEndTime = new Date(startDateTime.getTime() + 180 * 60000); // 3 hours

      return endDateTime <= maxEndTime;
    }
    return true;
  }

  public getStatusColor(status: candidateStatus): string {
    return statusColorMapping[status] || 'transparent';
  }
  getAllUsers() {
    this.eventServices.getAllUser().subscribe(res => {
      this.eventUsers = res?.data?.items;
    })
  }

  ngOnChanges(): void {
    if (this.search?.length) this.getCandidates();
    if (this.emailsCleared) {
      this.selectedUsersList = [];
    }
  }

  filterUpdate(filterValue: any, filterField: string) {
    if (filterField == 'status') filterField = 'jobStatus';
    const i = this.filterCriteria.findIndex(e => e.filterField === filterField) ?? null;

    if (this.filterCriteria.length && (i >= 0)) {
      this.filterCriteria[i] = { filterField: filterField, filterValue: filterValue };
    } else {
      this.filterCriteria.push({ filterField: filterField, filterValue: filterValue });
    }
    this.filterCriteria = this.filterCriteria.filter((field: FilterCriteria) => field.filterValue != '');
    // this.getCandidates();
  }

  filterHide() {
    this.filterLoading = false;
    this.showFilterSidebar = false;
    this.activeTabIndex = 1;
  }

  applySelectedFilter = () => {
    this.activeTabIndex = -1;
    this.filterLoading = true;
    this.filterApplied = true;
    this.getCandidates();
  };

  dateFilter() {
    if (this.rangeDates.includes(null)) {
      this.messageService.add({ severity: 'error', summary: 'Try Again!', detail: 'Select End Range for Filter !' })
    } else {
      let payload: any = {
        joinedDateRange: {}
      }
      if (this.rangeDates.length == 2) {
        if (this.rangeDates[0]) {
          if (this.rangeDates[1]) {
            this.rangeDates[0].setHours(this.rangeDates[0].getHours() + 5);
            this.rangeDates[0].setMinutes(this.rangeDates[0].getMinutes() + 30);
            this.rangeDates[1].setHours(this.rangeDates[1].getHours() + 5);
            this.rangeDates[1].setMinutes(this.rangeDates[1].getMinutes() + 30);
          }
        }

        let startDate = this.rangeDates[0] ? new Date(this.rangeDates[0]).toISOString() : '';
        
        let endDate = this.rangeDates[1] ? new Date(this.rangeDates[1]).toISOString() : '';

        payload.joinedDateRange = {
          joiningDateStart: startDate,
          joiningDateEnd: endDate
        }
      }
      return payload;
    }
  }
  onSlide(event: any) {
    const [startValue, endValue] = this.testRange;

    if (event.handleIndex === 0 && event.value >= endValue) {
      this.testRange = [endValue, endValue];
    } else if (event.handleIndex === 1 && event.value <= startValue) {
      this.testRange = [startValue, startValue];
    } else {
      this.testRange = [event.values[0], event.values[1]];
    }
    
    this.firstValue = this.testRange[0];
    this.secondValue = this.testRange[1];
  }

  applyFilter() {
    if (this.assessmentRange.includes(null) || this.contestValidityRange.includes(null) || this.testRange.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Try Again!', detail: 'Select End Range for Filter !' })
    } else {
      let payload: any = {
        companyId: this.companyId,
        jobId: this.jobId,
        stageId: this.stage.id,
        stageNumber: this.stage.stageId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        filterCriteria: {}
      };

      console.log(this.firstValue)
      if(this.firstValue >= 0 && this.secondValue >= 0){
          payload.percentageFilter = {
            fromPercent : this.firstValue,
            toPercent : this.secondValue
          }
      }
      
      if (this.contestValidityRange.length == 2) {
        payload.contestValidity = {
          contestStartTime: this.contestValidityRange[0],
          contestEndTime: this.contestValidityRange[1]
        }
      }
      if (this.assessmentRange.length == 2) {
        this.assessmentRange[0].setHours(this.assessmentRange[0].getHours() + 5);
        this.assessmentRange[0].setMinutes(this.assessmentRange[0].getMinutes() + 30);
        this.assessmentRange[1].setHours(this.assessmentRange[1].getHours() + 29);
        this.assessmentRange[1].setMinutes(this.assessmentRange[1].getMinutes() + 29);
        payload.filterCriteria.testStartTime = [
          {
            fieldPath: "starttime",
            operator: ">=",
            fieldValue: this.assessmentRange[0]
          },
          {
            fieldPath: "starttime",
            operator: "<=",
            fieldValue: this.assessmentRange[1]
          }
        ];
      }
      return payload;
    }
  }


  filterShow() {
    this.activeTabIndex = -1;
  }

  clearSelectedFilters = () => {
    this.filterLoading = false;
    this.filterCriteria = [];
    this.filterCount = 0;
    this.testRange = [0,100];
    this.firstValue = undefined;
    this.secondValue = undefined;
    this.assessmentRange = [new Date()];
    this.contestValidityRange = [new Date()];
    this.rangeDates = [new Date()];
    this.selectedDegree = [];
    this.selectedBranch = [];
    this.selectedCollege = [];
    this.selectedSpecial = [];
    this.selectedYear = '';
    this.selectedRole = '';
    this.selectedArrears = '';
    this.selectedPwd = '';
    this.selectedGender = '';
    this.selectedEligibility = '';
    this._selectedColumns.forEach(each => {
      if (each.options) each.selectedFilterElement = { label: 'All', value: '' }
    })
    this.getCandidates();
    console.log(this.rangeDates)
  }

  handleSelection = (event: any) => {
    if(this.selectedUsersList.length < this.last){
      this.selectedAllStageCandidates = false;
    }
    this.emails = event.map((user: any) => user.email);
    if (this.stage.type == 'Offer') {
      let cohortEmails = event.map((user: any) => user.email);
      this.emailList.emit(cohortEmails);
    }
  };

  addColumns = () => {
    let testCompletedStage: Array<any> = [];

    testCompletedStage = this.stages.filter((stage: any) => stage.stageId <= this.stage.stageId && stage.type == 'Test');
    testCompletedStage.forEach(stage => {
      this.candidatesCol.unshift({ index: 1, field: 'testScore', stageId: stage.id, header: stage.name + ' Test Score', testSchedule: stage.scheduleTest });
      this._selectedColumns.unshift({ index: 1, field: 'testScore', stageId: stage.id, header: stage.name + ' Test Score', testSchedule: stage.scheduleTest });
    });

    if (this.stage.type == 'Test') {
      this.candidatesCol.push({
        index: 1,
        field: 'testStartTime',
        header: 'Assessment Started Time',
        date: true
      }, {
        index: 1,
        field: 'testSubmitTime',
        header: 'Assessment Submitted Time',
        date: true
      }, {
        index: 1,
        field: 'contestStartTime',
        header: 'Contest Start Time',
        date: true
      }, {
        index: 1,
        field: 'contestEndTime',
        header: 'Contest End Time',
        date: true
      });
      this._selectedColumns.push({
        index: 1,
        field: 'testStartTime',
        header: 'Assessment Started Time',
        date: true
      }, {
        index: 1,
        field: 'testSubmitTime',
        header: 'Assessment Submitted Time',
        date: true
      }, {
        index: 1,
        field: 'contestStartTime',
        header: 'Contest Start Time',
        date: true
      }, {
        index: 1,
        field: 'contestEndTime',
        header: 'Contest End Time',
        date: true
      });
    }

    if (this.stage.type == 'Screening') {
      this.candidatesCol.splice(6, 0,
        ({ index: 7, field: 'documentPOC', header: 'Document POC' }),
        ({ index: 8, field: 'isDocumentsSubmitted', header: 'Document', })
      );
      this._selectedColumns.splice(6, 0,
        ({ index: 7, field: 'documentPOC', header: 'Document POC' }),
        ({ index: 8, field: 'isDocumentsSubmitted', header: 'Document' })
      );
    }


    if (this.stage.type == 'Interview') {
      this.candidatesCol.splice(2, 0, ({ index: 3, field: 'feedbackStatus', header: 'Feedback Status' }));
      this._selectedColumns.splice(2, 0, ({ index: 3, field: 'feedbackStatus', header: 'Feedback Status' }));
      this.stageType = this.stage.type;
    }

    if (this.stage.type == 'Offer' || this.stage.type == 'OnBoarding') {
      this.candidatesCol.splice(4, 0,
        // ({ index: 7, field: 'offerPOC', header: 'Offer POC' }),
        ({ index: 8, field: 'offerResponse', header: 'Offer Response' }),
        ({ index: 9, field: 'declinedRemarks', header: 'Reason for Declining' })
      );
      this._selectedColumns.splice(4, 0,
        // ({ index: 7, field: 'offerPOC', header: 'Offer POC' }),
        ({ index: 8, field: 'offerResponse', header: 'Offer Response' }),
        ({ index: 9, field: 'declinedRemarks', header: 'Reason for Declining' })
      );
    }
    if (this.stage.type == 'Others') {
      this.stageType = this.stage.type;
    }

    if (this.stage.type == 'OnBoarding') {
      this.candidatesCol.splice(2, 0, ({ index: 3, field: 'joiningDate', header: 'Joining Date' }));
      this._selectedColumns.splice(2, 0, ({ index: 3, field: 'joiningDate', header: 'Joining Date' }));
      this.stageType = this.stage.type;
    }
  };

  templateDownload() {
    let type = this.scheduleRadio;
    this.eventServices.sendInterviewLinkSampleExportFastApi(this.eventId, this.stage.stageId, type).subscribe({
      next: (response: HttpResponse<Blob>) => {
        this.loadingDownload = false;
        const fileName = `Bulk_Interview_Schedule_Template_${type}_${this.stage.name}`;

        if (!response.body) {
          throw new Error('Response body is empty');
        }
        const blob = new Blob([response.body], { type: response.body.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Access denied. You do not have permission to download this file.'
          });
        } else if (error.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Internal server error. Please try again later.'
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An unexpected error occurred. Please try again later.'
          });
        }
        this.loadingDownload = false;
      }
    });
  }

  getEventDetails() {
    this.eventServices.getEventById(this.eventId).subscribe(response => {
      this.selectedEventDetails = response.data ? response.data : {};
    });
  }

  handleUpload(event: any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file);
    };
  }

  fileUpload(file: any) {
    this.uploadingFile = true;
    let fileName = file.name.split('.');
    let fileExtension = fileName.pop();
    fileName = `${fileName
      .join()
      .replace(/\s/g, '')
      .replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    this.bucketData = {
      Bucket_name: this.bucketName,
      file_name: `manage-events/bulk-movestage/${this.companyId}/${this.eventId
        }/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;

    this.fileUploadService
      .getSignedUrl(this.bucketData)
      .subscribe((url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService
            .uploadUsingSignedUrl(json.data, blobData)
            .subscribe((r: any) => {
              this.fileBase64 = `${this.s3Domain}/${this.bucketName}/${this.bucketData.file_name}`;
              this.uploadingFile = false;
            });
        }
      });
  }

  onSelectExcel(event: any) {
    this.excel = event.addedFiles;
    this.handleUpload(event);
  }

  uploadTemplate(event: any) {
    this.template = event.addedFiles;
    this.templateUploaded = true;
    this.templateLoader = true;
    this.fileCopy = this.template[0];

    if (this.fileCopy.size > 2 * 1024 * 1024) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'File size should be less than 2MB'
      });
      return;
    }
    this.templateSize = `${(this.fileCopy.size / 1024).toFixed(2)} KB`;
    const fileName = this.generateFileName(this.fileCopy);
    const filePath = `temp/${this.companyId}/${fileName}`;

    const payload = {
      file_path: filePath,
      bucket_type: 'private'
    };

    this.uploadingFile = true;
    this.fileUploadService.getSignedUrlGCS(payload).subscribe({
      next: (response: any) => {
        if (response && response.body.signed_url) {
          this.uploadFileToGCS(response.body.signed_url, this.fileCopy, filePath, fileName);
        }
      },
      error: (error) => {
        console.error('Error getting signed URL:', error);
        this.uploadingFile = false;
      }
    });
  }

  private generateFileName(file: any): string {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    const timestamp = new Date().getTime();
    return `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}_${timestamp}.${fileExtension}`;
  }

  uploadFileToGCS(signedUrl: string, file: any, filePath: string, filename: string) {
    this.fileUploadService.uploadUsingSignedUrlGCS(signedUrl, file).subscribe({
      next: (response: any) => {
        if (response && response.status === "success") {
          this.uploadingFile = false;
          this.interviewFileName = filename;
        }
      },
      error: (error) => {
        this.uploadingFile = false;
      }
    });
  }

  bulkSchedule() {
    this.errorFileUrl = '';
    this.interviewFileName = '';
    this.templateUploaded = false;
    this.scheduleBtnLoading = false;
    this.template = [];
    this.scheduleRadio = 'all';
  }

  downloadErrorReport() {
    if (this.errorFileUrl) {
      window.open(this.errorFileUrl, '_blank');
    }
  }

  scheduleFileUpload() {
    this.scheduleBtnLoading = true;
    this.errorFileUrl = '';
    if (this.template[0] && this.interviewFileName) {
      const payload = {
        file_name: this.interviewFileName,
      };
      this.eventServices.bulkInterviewLinkFastApi(payload, this.eventId, this.stage.stageId).subscribe({
        next: (response: any) => {
          this.bulkSchedule();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail
          });
        },
        error: (error: HttpErrorResponse) => {
          const errorResponse = error.error as InterviewBulkSchedule;
          this.bulkSchedule();
          if (errorResponse.error_file) {
            this.errorFileUrl = errorResponse.error_file;
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Some records have errors. Please check the error report.'
            });
            return;
          }
  
          if (errorResponse.detail === "File not found") {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'File not found. Please try uploading again.'
            });
            return;
          }
  
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: errorResponse.detail || 'Something went wrong.'
          });
        }
      });

    } else {
      this.scheduleBtnLoading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to Submit',
      });
    }
  }

  onRemoveExcel(event: any) {
    this.excel.splice(this.excel.indexOf(event), 1);
  }

  uploadCandidates = (stageId: number) => {
    if (this.fileBase64.length) {
      this.sourceData[0].value = stageId;
      this.uploadBtnLoading = true;
      let payload = {
        Bucket_name: this.bucketData.Bucket_name,
        file_name: this.bucketData.file_name.replace(/\s/g, ''),
        jobId: this.jobId,
        actionId: 'fileMoveStage',
        sourceData: this.sourceData,
      };
      this.eventServices.candidteBulkMoveStage(payload).subscribe((res) => {
        if (Object.keys(res.data).length !== 0) {
          this.excel = [];
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Candidates Moved Successfully',
          });
        } else
          this.messageService.add({
            severity: 'error',
            summary: 'Failed',
            detail: 'Unable to Move Candidates',
          });
        this.uploadBtnLoading = false;
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: 'Select a file before uploading candidated.',
      });
    }
    // }
  };


  activitySliderSubmit = (options: any) => {
    // console.log('Action Name',options.action,'---',options.toOptions)
    // console.log('Static-->')
    if (options.action == 'moveStage') {
      this.moveCandidates(options.toOptions);
    }
    else if (options.action == 'statusUpdate') {
      this.changeStatus(options.toOptions);
    }
  }

  moveCandidates = async (destinationStage: any) => {
    let destinationStatus = '';
    switch (destinationStage.type) {
      case 'Offer': destinationStatus = 'offerPending'; break;
      case 'Screening': destinationStatus = 'mailPending'; break;
      default: destinationStatus = 'pending';
    }
    this.uploadBtnLoading = true;
    this.loadingSpinner = true;
    let payload: any = {
      current_stage_uuid: this.stage.stageId,
      move_stage_uuid: destinationStage.stageId,
      candidate_uuids: this.selectedUsersList.map((user: any) => user.id),
      remarks: this.activityRemarks,
    };
    this.eventServices.candidateStageMoveStageFastApi(payload,this.stage.id,this.eventId).subscribe({
      next: (result: any) => {
        if (this.selectedUsersList.length == 1) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: result.body.detail,
          });
        } else {
          this.messageService.add({
            severity: 'info',
            summary: 'Message',
            detail: 'Bulk process has been initiated. Kindly refresh after some times.'
          });
        }
        this.getCandidates();
        this.selectedUsersList = [];
        this.uploadBtnLoading = false;
        this.activityRemarks = '';
        this.stageActivitySlider = false;
      },
      error: () => {
        this.selectedUsersList = [];
        this.uploadBtnLoading = false;
        this.activityRemarks = '';
        this.stageActivitySlider = false;
      }
    });
  }


  getCohortStatuses = () => {
    this.eventServices.getAllCohortId().subscribe(res => {
      this.cohortStatuses = res.data;
      this._cohortStatuses = this.cohortStatuses;
      this._cohortStatuses.sort((a: any, b: any) => +new Date(b.createdAt) - +new Date(a.createdAt));
    })
  }



  onHideActivity = () => {
    this.getCandidates();
  }

  moveStageSlider = (stage: any, stageOverlay: any) => {
    this.cohortDetails = stage.type == 'Offer' ? true : false;
    this.activitySliderOptions = {
      header: 'Move Stage',
      action: 'moveStage',
      from: this.stage.name,
      to: stage.name,
      toOptions: stage
    }
    this.stageActivitySlider = true;
    stageOverlay.hide();
  };

  isJobRoleLocationSubmitted() {
    let isValid = true;
    this.selectedUsersList.forEach((user: any) => {
      if (!user?.cohortJobRole || !user?.cohortLocation) {
        isValid = false;
      }
    });
    return isValid;
  }

  changeStatusSlider = (status: any, user?: any) => {

    this.showJoiningDate = status.name;
    if (user) this.selectedUsersList = [user];
    let fromStatus = this.selectedUsersList[0].status;
    this.activitySliderOptions = {
      header: 'Status Update',
      action: 'statusUpdate',
      from: fromStatus,
      to: status.name,
      toOptions: status,
    }
    this.stageActivitySlider = true;
  }

  dateChange() {
    this.sendLinkForm.controls['startTime'].setValue('');
    this.sendLinkForm.controls['duration'].setValue('');
    
    if (this.sendLinkForm.controls['interviewDate'].value.toDateString() === new Date().toDateString()) {
      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 30);
      const currentHour = currentDate.getHours().toString().padStart(2, '0');
      const currentMinute = currentDate.getMinutes().toString().padStart(2, '0');
      const currentTime = `${currentHour}:${currentMinute}`;
      this.timeSlots = this.copyTimeSlots.filter((slot: any) => {
        return slot.value >= currentTime;
      });
    } else {
      this.timeSlots = this.copyTimeSlots;
    }
  }

  joinDateSelection(date: any) {
    this.selectedJoinDate = date;
  }

  sidenavClosed() {
    this.sendLinkForm.reset();
  }

  sendInterviewLink = () => {
    this.clicked = true;
    this.uploadBtnLoading = true;
    const formValue = this.sendLinkForm.value;
    const formattedDate = moment(formValue.interviewDate).format('YYYY-MM-DD');
    const formattedTime = moment(formValue.startTime.value, 'HH:mm').format('hh:mm A');
    const formattedDuration = moment.utc()
      .hours(Math.floor(formValue.duration / 60))
      .minutes(formValue.duration % 60)
      .format('HH:mm');
    
    const payload: InterviewLinkPayload = {
      candidate_uuids: this.selectedUsersList.map((user: any) => user.candidate_uuid),
      start_date: formattedDate,
      start_time: formattedTime,
      duration: formattedDuration
    };
  
    this.eventServices.sendInterviewLinkFastApi(payload, this.eventId, this.stage.stageId).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: response.body.detail,
        });
        this.sendLinkForm.reset();
        this.clicked = false;
        this.sendLinkSlider = false;
        this.getCandidates();
        this.uploadBtnLoading = false;
        this.selectedUsersList = [];
      },
      error: (error) => {
        this.uploadBtnLoading = false;
      }
    });
  };

  // sendTestLink(users: any) {
  //   this.loadingSpinner = true;
  //   if(this.selectedAllStageCandidates){
  //     console.log("Multiple Test Link--->",this.loggedUserDetails)
  //     let payload = { user: this.loggedUserDetails?.email };
  //     this.eventServices.sendTestLinkToAll(this.eventId, this.stage.id, this.eventCategory, payload, this.stage.stageId)
  //       .subscribe((res) => {
  //           if (res.status == 200)
  //             this.messageService.add({
  //                 severity: 'success',
  //             summary: 'Bulk Process has been initiated',
  //             detail: 'We will notify you via email once the process has been completed.',
  //           });
  //         this.loadingSpinner = false;
  //           })
  //       } else {
  //         let payload = { data: users, user: this.loggedUserDetails?.email };
  //         console.log("Single Test Link")
  //     this.eventServices
  //       .sendTestLink(payload, this.eventId, this.stage.id, this.eventCategory)
  //       .subscribe((res) => {
  //         if (res.status == 200)
  //           this.messageService.add({
  //             severity: 'success',
  //             summary: 'Success',
  //             detail: 'Test link sent',
  //           });
  //         this.loadingSpinner = false;
  //         this.getCandidates();
  //         this.selectedUsersList = [];
  //       });
  //   }
  // }
  sendTestLink = async (users: any) => {
    console.log("🚀🔥 ~ file: stage-candidates.component.ts:1241 ~ sendTestLink= ~ users:", users)
    this.loadingSpinner = true;
    let payload: any;
    payload = {
      candidate_uuids: users.map((user: any) => user.id),
    }
    // if (this.selectedAllStageCandidates) {
      // For sending test link to all candidates
      this.eventServices.sendTestLinkFastApi(payload, this.stage.stageId, this.eventId).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Bulk Process has been initiated',
              detail: res.body.detail,
            });
          }
          this.getCandidates();
          this.selectedUsersList = [];
          this.loadingSpinner = false;
        },
        error: () => {
          this.loadingSpinner = false;
        }
      });
  };
  

  changeFormat = (value: string): string => {
    if (!value) {
      return '';
    } else {
      let tempDate = new Date().toISOString().split('T')[0];
      let ap = value.split(' ')[1];
      if (ap == 'PM' || ap == 'pm') {
        let hh = Number(value.split(' ')[0].split(':')[0]);
        if (hh != 12) {
          hh += 12;
          value = hh + ':' + value.split(':')[1].split(' ')[0];
        }
      }
      return new Date(tempDate + ' ' + value.split(' ')[0])
        .toISOString()
        .split('T')[1];
    }
  };

  getCandidates = () => {
    //Cohort Funcitons
    this.cohortBtnLoading = false;
    this.cohortErroredFile = [];
    this.showJoiningDate = '';
    this.selectedJoinDate = '';

    //Move Stage Funcitons
    this.filterLoading = false;
    this.loadingSpinner = true;
    this.emailList.emit([]);
    let copyCandidateList = this.candidateList;
    this.candidateList = [];
    this.selectedAllStageCandidates = false;

    if ( this.filterCriteria.length > 0 ||
      this.assessmentRange.length == 2 || this.contestValidityRange.length == 2 || (this.firstValue >= 0 && this.secondValue >= 0) || this.rangeDates.length == 2) {
      let testPayload: any;
      let joinedPayload: any;

      this.filterCriteria.forEach((item: any) => {
        if (Array.isArray(item.filterValue)) {
          console.log(this.filterCount)
          this.filterCount = this.filterCount + item.filterValue.length;
          console.log(this.filterCount)
        }
        else {
          this.filterCount = this.filterCount + 1;
        }
      })
      // console.log(this.filterCount, 'count')
      if (this.filterCount > 7) {
        this.messageService.add({ severity: 'info', summary: 'Message', detail: 'Please select within a total of 7 filter values to proceed !' });
        this.filterCount = 0;
        this.candidateList = copyCandidateList;
        this.loadingSpinner = false;
      }
      else {
        this.filterCount = 0;
        if (this.assessmentRange.length == 2 || this.contestValidityRange.length == 2 || (this.firstValue >= 0 && this.secondValue >= 0)) {
          testPayload = this.applyFilter();
        }
        if (this.rangeDates.length == 2) {
          joinedPayload = this.dateFilter();
        }
        let payload: any = {};
        let finalPayload: any = {};
        let searchTerm: string = '';
        if (this.search.length) {
          this.search = this.search.filter((item: any) => item != '')
        }
        if (this.search && this.search.length === 1) {
          searchTerm = this.search[0];
        } else {
          payload.search = this.search;
          payload.filterCriteria = this.filterCriteria;
        }
        if (testPayload) {
          finalPayload.testStagePayload = testPayload;
          finalPayload.allStagePayload = payload;
        } else if (joinedPayload) {
          finalPayload.joinStagePayload = joinedPayload;
          finalPayload.allStagePayload = payload;
        } else {
          finalPayload.allStagePayload = payload;
        }

        console.log(finalPayload, 'payload')
        this.eventServices
          .getSearchWiseCandidate(
            finalPayload,
            this.stage.stageId,
            this.jobId,
            searchTerm,
            this.pageNumber,
            this.pageSize
          )
          .subscribe((result: any) => {
            if (result.status == 200) {
              this.candidateList = result.data ? result.data.items : [];
              this.loadingSpinner = false;
              this.candidateList.forEach((item: any) => {
                if (item.jobUserData) {
                  item.status = item.jobUserData.jobStatus;
                }
              })
              this.totalLength = result.data.totalItems || 0;
              if (this.stage.type == 'Offer' || this.stage.type == 'OnBoarding') {
                this.candidateCount.emit(this.totalLength);
                // this.candidateList.forEach((candidate: any) => {
                //   if (candidate?.userCohortData.cohort_spoc) {
                //     candidate.userCohortData.cohort_spoc = this.eventUsers.filter((user: any) => user.id == candidate?.userCohortData.cohort_spoc)[0];
                //   }
                // });
                if (this.rangeDates.length == 2) {
                  this.rangeDates[0].setHours(this.rangeDates[0].getHours() - 5);
                  this.rangeDates[0].setMinutes(this.rangeDates[0].getMinutes() - 30);
                  this.rangeDates[1].setHours(this.rangeDates[1].getHours() - 5);
                  this.rangeDates[1].setMinutes(this.rangeDates[1].getMinutes() - 30);
                }
              }
              if (this.stage.type == 'Test') {
                this.candidateList.forEach((eachCandidate: any) => {
                  eachCandidate.testStartTime = eachCandidate.testScore[0]?.starttime;
                  eachCandidate.testSubmitTime = eachCandidate.testScore[0]?.submittime;
                  if (eachCandidate?.stageUserData) {
                    eachCandidate.contestStartTime = moment.utc(eachCandidate?.stageUserData?.contestStartTime).subtract({ hours: 5, minutes: 30 }).format();
                    eachCandidate.contestEndTime = moment.utc(eachCandidate?.stageUserData?.contestEndTime).subtract({ hours: 5, minutes: 30 }).format();
                  }
                })
                if (this.assessmentRange.length == 2) {
                  this.assessmentRange[0].setHours(this.assessmentRange[0].getHours() - 5);
                  this.assessmentRange[0].setMinutes(this.assessmentRange[0].getMinutes() - 30);
                  this.assessmentRange[1].setHours(this.assessmentRange[1].getHours() - 29);
                  this.assessmentRange[1].setMinutes(this.assessmentRange[1].getMinutes() - 29);
                }
              }
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went wrong!',
              });
            }
          });
      }
    } else {
      let searchTerm: string = '';
      if (this.search.length) {
        this.search = this.search.filter((item: any) => item != '')
      }
      if (this.search && this.search.length === 1) {
        searchTerm = this.search[0];
      } else if(this.search.length > 0)  {
        searchTerm = this.search.join(' ');
      }
      this.eventServices.getEventStageCandidatesFastApi(this.stage.stageId,this.eventId ,this.pageNumber, this.pageSize,  this.order_by, this.sort_by, searchTerm || undefined).subscribe({
        next: (result: any) => {
          this.candidateList = result?.body?.items || [];
          this.candidateList.forEach((candidate: any) => {
            candidate.id = candidate.candidate_uuid ?? '-';
            candidate.name = candidate.candidate?.name ?? '-';
            candidate.email = candidate.candidate?.email ?? '-';
            candidate.dob = candidate.candidate?.dob ?? '-';
            candidate.gender = candidate.candidate?.gender ?? '-';
            candidate.mobile_number = candidate.candidate?.mobile_number ?? '-';
            candidate.stageId = candidate.stage?.current_stage ?? '-';
            candidate.status = candidate.status?.name ?? '-';
          });
          this.numberOfStages = this.candidateList.length > 0 ? this.candidateList[0].stage.total_stage : 0;
          console.log("🚀🔥 ~ file: stage-candidates.component.ts:1494 ~ this.candidateList=result?.body?.items.map ~ this.candidateList:", this.candidateList)
          this.emptyStage.emit(this.candidateList.length === 0);
          if (this.selectedUsersList.length) {
            this.selectedUsersList = this.candidateList.filter((user:any) => 
              this.selectedUsersList.some((each:any) => user.id === each.id)
            );
          }
          if (this.stage.type == 'Test') {
            // this.candidateList.forEach((eachCandidate: any) => {
            //   eachCandidate.testStartTime = eachCandidate.testScore[0]?.starttime;
            //   eachCandidate.testSubmitTime = eachCandidate.testScore[0]?.submittime;
            //   if (eachCandidate?.stageUserData) {
            //     eachCandidate.contestStartTime = moment.utc(eachCandidate?.stageUserData?.contestStartTime).subtract({ hours: 5, minutes: 30 }).format();
            //     eachCandidate.contestEndTime = moment.utc(eachCandidate?.stageUserData?.contestEndTime).subtract({ hours: 5, minutes: 30 }).format();
            //   }
            // })
          }
          this.loadingSpinner = false;
          this.totalLength = result.body.total || 0;
          this.first = result.body.page || 1;
          this.last = result.body.pages || 10;
          this.pageSize = result.body.size || 10;
          this.totalPages = result.body.pages || 1;
        },
        error: () => {
          this.loadingSpinner = false;
        }
      });
    }
  };

  getUsersByLimit = () => {
    this.pageNumber = 1;
    this.first = 1;
    this.last =
      this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    (this.filterApplied) ? this.applySelectedFilter() : this.getCandidates();
  };

  exportCandidate = (type: string) => {
    let checkedColumnFields: any = ['name', 'id'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    if (this.selectedUsersList.length) {
      afterFilter = this.processExcel(this.selectedUsersList, checkedColumnFields, type);
    } else {
      afterFilter = this.processExcel(this.candidateList, checkedColumnFields, type);
    }
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  processExcel = (usersList: Array<any>, checkedColumnFields: any, type: string) => {
    let afterFilter: any = [];
    usersList.forEach((eachData: any) => {
      eachData.feedbackStatus = (eachData.feedbackStatus) ? eachData.feedbackStatus : 'Feedback not Submitted';
      eachData.testScore = (eachData.testScore instanceof Array && eachData.testScore.length > 0) ? (eachData.testScore[0].marks + '/' + eachData.testScore[0].total_marks) : '-';
      let filterData: any = {};
      if (this.stage.type == 'Others') {
        if (!eachData.location) { eachData.location = '-' }
        if (!eachData.jobRole) { eachData.jobRole = '-' }
      }
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
        switch (item) {
          case "cohort_name":
            filterData[item] = eachData.userCohortData[item] ?? '-';
            break;
          case "cohort_date":
            filterData[item] = eachData.userCohortData['date'] ?? '-';
            break;
          case "cohort_spoc":
            filterData[item] = eachData.userCohortData[item]?.email ?? '-';
            break;
        }
      });
      afterFilter.push(filterData);
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  exportAllCandidates = (type: string) => {
    this.loadingDownload = true;
    const payload = {
      "file_type": type,
      "columns": ["candidate_uuid","name", "email", "mobile_number", "gender", "status"],
      "requested_data": "all",
      "ids": null,
      "stage_uuid": this.stage.stageId,
    }
    this.eventServices
      .eventStageCandidateExportFastApi(this.eventId, this.stage.stageId, payload).subscribe({
        next: (response: HttpResponse<Blob>) => {
          this.loadingDownload = false;
          const fileName = `${this.stage.name}_candidates`;

          if (!response.body) {
            throw new Error('Response body is empty');
          }
          const blob = new Blob([response.body], { type: response.body.type });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Access denied. You do not have permission to download this file.'
            });
          } else if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Internal server error. Please try again later.'
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An unexpected error occurred. Please try again later.'
            });
          }
          this.loadingDownload = false;
        }
      });
    };
  exportRequiredFields = (type: string) => {
    this.loadingDownload = true;
    if (this.selectedUsersList.length > 0) {
      let payload = {
        eventId: this.eventId,
        stageId: this.stage.stageId,
        emails: this.emails
      }
      this.eventServices.getMultipleCohortDownload(payload).subscribe(res => {
        let cohortList = res.data;
        this.loadingDownload = false;
        import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(cohortList);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
          };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: type === 'excel' ? 'xlsx' : 'csv',
            type: 'array',
          });
          this.saveCohortExcelFile(excelBuffer, this.cohortName, type);
        });
      })
    } else {
      this.eventServices
        .getCohortDownload(this.eventId, this.stage.stageId)
        .subscribe((res: any) => {
          let cohortList = res.data;
          this.loadingDownload = false;
          import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(cohortList);
            const workbook = {
              Sheets: { data: worksheet },
              SheetNames: ['data'],
            };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: type === 'excel' ? 'xlsx' : 'csv',
              type: 'array',
            });
            this.saveCohortExcelFile(excelBuffer, this.cohortName, type);
          });
        });
    }
  };

  saveCohortExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  async getResultAnalysis(user: any, stageId: any) {
    this.globalService.getScheduleTest(user.email, this.jobId, stageId).subscribe((test: any) => {
      this.loadingSpinner = true;
      if (test.data.length > 0) {
        let scheduleTest = test.data[0];
        let contestId = scheduleTest.test_id.slice(0, 36);
        let testId = scheduleTest.test_id.slice(36, 72);
        let payload = {
          source: 'result-analysis',
        };
        this.globalService.getResultAnalysis(payload, contestId, testId, user.email).subscribe((resp: any) => {
          let url = resp.data.data;
          if (resp.data.data && resp.data.data.includes('https')) {
            let target_host = 'examly.io';
            if (this.ENV === 'dev') {
              target_host = 'exam.ly';
            } else if (this.ENV === 'acc') {
              target_host = 'examly.net';
            }
            url = url.replace('examly.io', target_host);
            window.open(url, '_blank');
            this.loadingSpinner = false;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'An Error occured',
              detail: 'Try again after some time !',
            });
            this.loadingSpinner = false;
          }
        })
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Analysis not found',
          detail: 'Candidate yet to start the test !',
        });
        this.loadingSpinner = false;
      }
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService
      .deleteColumnCandidates(this.jobId, columnId)
      .subscribe((res) => {
        this.savedColumnSettings = res.data?.selectedColumns;
        this.loadingSpinner = false;
      });
  };

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  };

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  };

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._candidatesCol = this.candidatesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.candidatesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.candidatesCol.filter((e) =>
      savedColumnValue.includes(e.field)
    );
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index ? -1 : 1));
  }

  setStatusDropdown = (status: any) => {
    this.statusOptions.forEach((each: any) => {
      if (status == each.name) this.currentStatus = each;
    });
  };

  changeStatus = (status: any) => {
    this.loadingSpinner = true;
    this.uploadBtnLoading = true;

    let payload: any = {
      current_stage_uuid: this.stage.stageId,
      change_status_id : status.id,
      candidate_uuids: this.selectedUsersList.map((user: any) => user.id),
      remarks: this.activityRemarks,
    };
    this.eventServices.candidateStageUpdateStatusFastApi(payload,this.stage.id, this.eventId).subscribe({
      next: (result: any) => {
        if (this.selectedUsersList.length == 1) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: result.body.detail,
          });
        } else {
          this.messageService.add({
            severity: 'info',
            summary: 'Message',
            detail: 'Bulk process has been initiated. Kindly refresh after some times.'
          });
        }
        this.showJoiningDate = '';
        this.selectedJoinDate = '';
        this.selectedUsersList = [];
        this.activityRemarks = '';
        this.stageActivitySlider = false;
        this.uploadBtnLoading = false;
      },
      error: () => {
        this.showJoiningDate = '';
        this.selectedJoinDate = '';
        this.selectedUsersList = [];
        this.activityRemarks = '';
        this.stageActivitySlider = false;
        this.loadingSpinner = false;
        this.uploadBtnLoading = false;
      }
    });
    // if (this.selectedUsersList.length == 1) {
    //   if (this.showJoiningDate == 'joined') {
    //     if (this.selectedJoinDate) {

    //       this.selectedJoinDate.setHours(this.selectedJoinDate.getHours() + 5);
    //       this.selectedJoinDate.setMinutes(this.selectedJoinDate.getMinutes() + 30);

    //       payload.push({ sourceData: this.sourceData, source: [{ data: this.selectedUsersList[0], joiningDate: this.selectedJoinDate }] });

    //       this.eventServices.candidateChangeStatus(this.jobId, status.actionId, payload).subscribe(() => {
    //         this.getCandidates();
    //       })
    //       this.showJoiningDate = '';
    //       this.selectedJoinDate = '';
    //       this.selectedUsersList = [];
    //       this.activityRemarks = '';
    //       this.stageActivitySlider = false;
    //     } else {
    //       this.messageService.add({
    //         severity: 'error',
    //         summary: 'Required',
    //         detail: 'Please select a date to proceed!',
    //       });
    //     }
    //   } else {
    //     payload.push({ sourceData: this.sourceData, source: [{ data: this.selectedUsersList[0] }] });

    //     this.eventServices.candidateChangeStatus(this.jobId, status.actionId, payload).subscribe(() => {
    //       this.getCandidates();
    //     })
    //     this.showJoiningDate = '';
    //     this.selectedJoinDate = '';
    //     this.selectedUsersList = [];
    //     this.activityRemarks = '';
    //     this.stageActivitySlider = false;
    //   }
    // }
    // else {
    //   if (this.showJoiningDate == 'joined') {
    //     if (this.selectedJoinDate) {

    //       this.selectedJoinDate.setHours(this.selectedJoinDate.getHours() + 5);
    //       this.selectedJoinDate.setMinutes(this.selectedJoinDate.getMinutes() + 30);

    //       this.emails = [];
    //       this.selectedUsersList.forEach((eachUser: any) => {
    //         if (eachUser.status != status.value) {
    //           this.emails.push(eachUser.email);
    //         }
    //       });
    //       payload.push({ sourceData: this.sourceData, source: [{ email: this.emails, joiningDate: this.selectedJoinDate }] });
    //       this.eventServices.multipleCandidateChangeStatus(this.jobId, status.actionId, payload).subscribe(() => {
    //         this.messageService.add({ severity: 'info', summary: 'Message', detail: 'Bulk process has been initiated. Kindly refresh after some times.' });
    //         this.loadingSpinner = false;
    //       })
    //       this.showJoiningDate = '';
    //       this.selectedJoinDate = '';
    //       this.selectedUsersList = [];
    //       this.activityRemarks = '';
    //       this.stageActivitySlider = false;
    //     } else {
    //       this.messageService.add({
    //         severity: 'error',
    //         summary: 'Required',
    //         detail: 'Please select a date to proceed!',
    //       });
    //     }
    //   } else {
    //     this.emails = [];
    //     this.selectedUsersList.forEach((eachUser: any) => {
    //       if (eachUser.status != status.value) {
    //         this.emails.push(eachUser.email);
    //       }
    //     });
    //     payload.push({ sourceData: this.sourceData, source: [{ email: this.emails }] });
    //     this.eventServices.multipleCandidateChangeStatus(this.jobId, status.actionId, payload).subscribe(() => {
    //       this.messageService.add({ severity: 'info', summary: 'Message', detail: 'Bulk process has been initiated. Kindly refresh after some times.' });
    //       this.loadingSpinner = false;
    //     })

    //     this.showJoiningDate = '';
    //     this.selectedJoinDate = '';
    //     this.selectedUsersList = [];
    //     this.activityRemarks = '';
    //     this.stageActivitySlider = false;
    //   }
    // }
    // this.uploadBtnLoading = false;
  }

  // Pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last =
      this.last + this.pageSize < this.totalLength
        ? this.pageSize + this.last
        : this.totalLength;
    this.pageNumber += 1;
    (this.filterApplied) ? this.applySelectedFilter() : this.getCandidates();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last =
      this.last - this.pageSize >= this.totalLength
        ? this.totalLength - this.last
        : this.pageSize;
    this.pageNumber -= 1;
    (this.filterApplied) ? this.applySelectedFilter() : this.getCandidates();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.candidatesCol.filter((col) =>
      val.includes(col)
    );
  }

  showCandidateDetails(userDetail: any) {
    this.displayUserDetails = true;
    this.selectedUser = userDetail;
  }

  sendEmail() {
    this.sendEmailSlider = true;
  }

  mailSent(event: boolean) {
    if (event) {
      this.sendEmailSlider = false;
      this.sendOfferMail = false;
      this.sendDocumentation = false;
      this.getCandidates();
      this.selectedUsersList = [];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Mail Sent!' });
    }
  }

  capitalize(col: any) {
    let nonCapitializeField = ["email"]
    if (!(nonCapitializeField.includes(col.field))) {
      return true
    }
    return false
  }

  // applyFilter() {
  //   if(this.assessmentRange.includes(null) || this.contestValidityRange.includes(null)) {
  //     this.messageService.add({ severity: 'error', summary: 'Try Again!', detail: 'Select End Range for Filter !' })
  //   } else {
  //     this.filterApplied = true;
  //     this.loadingSpinner = true;
  //     let payload: any = {
  //       companyId: this.companyId,
  //       jobId: this.jobId,
  //       stageId: this.stage.id,
  //       stageNumber: this.stage.stageId,
  //       pageSize: this.pageSize,
  //       pageNumber: this.pageNumber,
  //       filterCriteria: {}
  //     };
  //     if (this.contestValidityRange.length == 2) {
  //       payload.contestValidity = {
  //         contestStartTime: this.contestValidityRange[0],
  //         contestEndTime: this.contestValidityRange[1]
  //       }
  //     }
  //     if (this.assessmentRange.length == 2) {
  //       this.assessmentRange[0].setHours(this.assessmentRange[0].getHours() + 5);
  //       this.assessmentRange[0].setMinutes(this.assessmentRange[0].getMinutes() + 30);
  //       this.assessmentRange[1].setHours(this.assessmentRange[1].getHours() + 29);
  //       this.assessmentRange[1].setMinutes(this.assessmentRange[1].getMinutes() + 29);
  //       payload.filterCriteria.testStartTime = [
  //         {
  //           fieldPath: "starttime",
  //           operator: ">=",
  //           fieldValue: this.assessmentRange[0]
  //         },
  //         {
  //           fieldPath: "starttime",
  //           operator: "<=",
  //           fieldValue: this.assessmentRange[1]
  //         }
  //       ];
  //     }
  //     this.globalService.assessmentFilter(payload).subscribe((resp: any) => {
  //       this.candidateList = resp?.data ? resp.data.items : [];
  //       this.candidateList.forEach((eachCandidate: any) => {
  //         eachCandidate.testStartTime = eachCandidate.testScore[0]?.starttime;
  //         eachCandidate.testSubmitTime = eachCandidate.testScore[0]?.submittime;
  //         if (eachCandidate?.stageUserData) {
  //           eachCandidate.contestStartTime = moment.utc(eachCandidate?.stageUserData?.contestStartTime).subtract({ hours: 5, minutes: 30 }).format();
  //           eachCandidate.contestEndTime = moment.utc(eachCandidate?.stageUserData?.contestEndTime).subtract({ hours: 5, minutes: 30 }).format();
  //         }
  //       })
  //       if(this.assessmentRange.length == 2){
  //         this.assessmentRange[0].setHours(this.assessmentRange[0].getHours() - 5);
  //         this.assessmentRange[0].setMinutes(this.assessmentRange[0].getMinutes() - 30);
  //         this.assessmentRange[1].setHours(this.assessmentRange[1].getHours() - 29);
  //         this.assessmentRange[1].setMinutes(this.assessmentRange[1].getMinutes() - 29);
  //       }
  //       this.totalLength = resp.data.totalItems || 0;
  //       this.loadingSpinner = false;
  //     })
  //   }
  // }

  getUserName(userId: string) {
    let user = this.eventUsers.filter((user: any) => user.id == userId)[0];
    return user?.name;
  }

  statusButton(status: any) {
    if (this.stage.stageId == 1) {
      if (this.selectedUsersList.length == 1) {
        if (status.value == this.selectedUsersList[0].status) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
}
