import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from './global-services.service';
@Injectable({
  providedIn: 'root',
})
export class RoleServicesService {
  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '', 
      schoolid: schoolId,
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }

  constructor(private http: HttpClient, public globalService: GlobalServicesService,
  ) { }
  // fast APIs
  getJobRolesFastApi(page = 1, size = 10, orderBy = 'desc', sortBy = 'id', filter = 'all'): Observable<HttpResponse<any>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy)
      
    if (typeof filter !== 'string' && typeof filter === 'number') {
      params = params.set('role_type_id', filter);
    }

    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/job-roles`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  createJobRolesFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/job-roles`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  updateJobRolesFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.fast_api_url}/job-roles/${id}`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  deleteJobRolesFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`${this.fast_api_url}/job-roles/${id}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getSpecificJobRolesFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/job-roles/${id}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getJobRolesFormDataFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/job-roles/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getRoleAndPermissionFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/roles`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getSpecificRoleAndPermissionFastApi(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/roles/${id}`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  getRoleAndPermissionFormDataFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/roles/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  createRoleAndPermissionFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/roles`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  updateRoleAndPermissionFastApi(id: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.fast_api_url}/roles/${id}`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  deleteRole(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/roles/' + id, this.options());
  }

  getSchool(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.options());
  }

  saveColumns(id: string, payload: Array<any>): Observable<any> {
    return this.http.put<any>(
      this.api_url + '/column/roles/' + id,
      payload,
      this.options()
    );
  }

  deleteColumns(schoolId: string, columnId: string): Observable<any> {
    return this.http.delete(
      this.api_url + '/column/roles/' + schoolId + '/' + columnId,
      this.options()
    );
  }

  getAllRoles(pageNumber?: number, pageSize?: number): Observable<any> {
    return this.http.get(
      this.api_url +
      '/roles?pageNumber=' +
      pageNumber +
      '&pageSize=' +
      pageSize,
      this.options()
    );
  }

  getFilterRoles(
    pageNumber?: number,
    pageSize?: number,
    status?: string
  ): Observable<any> {
    return this.http.get(
      this.api_url +
      '/roles/all/' +
      status +
      '/?pageNumber=' +
      pageNumber +
      '&pageSize=' +
      pageSize,
      this.options()
    );
  }
  addRoles(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/roles', payload, this.options());
  }
  updateRoles(roleId: string, payload: any): Observable<any> {
    return this.http.put(
      this.api_url + '/roles/' + roleId,
      payload,
      this.options()
    );
  }
}