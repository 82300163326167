import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { MessageService } from 'primeng/api';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { nanoid } from 'nanoid'
import { EventServicesService } from 'src/app/services/event-services.service';

declare let tinymce: any;
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
  providers: [MessageService]
})
export class SendEmailComponent implements OnInit, OnChanges {

  @Input() selectedUsersList: any = [];
  @Input() sendMailHeader: string = '';
  @Input() assignCohortData: boolean = false;
  @Input() sendDocumentation: boolean = false;
  @Output() mailSent = new EventEmitter<boolean>();
  @Input() stage: any;
  
  emails: any = [];
  schoolDetails: any = localStorage.getItem('company-details');
  fromAddress = JSON.parse(this.schoolDetails).company_metadata.from_email;
  bucketName: string = this.globalService.assetBucketName;
  s3Domain: string = 'https://s3.amazonaws.com';
  schoolId: string = '';
  eventId: string = '';
  cohortNameTerm: string = '';
  schoolData: any;
  cohortStatuses: any = {};
  selectedCohortStatus: any = {};
  jobLocationsList: Array<any> = []
  jobRolesList: Array<string> = []
  loggedUser: any = {};

  loader: boolean = false;
  clicked: boolean = false;
  uploadingFile: boolean = false;
  templatesList: any = [];
  eventUsers: any = [];
  selectedLocation: string[] = []; 
  // attachmentFile: File[] = [];
  attachmentFile: any;
  deadlineList: any = [];

  sendEmailSlider: boolean = false;
  sendEmailForm: FormGroup = new FormGroup({
    fromAddress: new FormControl(this.fromAddress),
    toAddress: new FormControl('', [Validators.required]),
    subjectTitle: new FormControl('', [Validators.required]),
    attachment: new FormControl(''),
    template: new FormControl(''),
  })

  sendOfferForm: FormGroup = new FormGroup({
    cohort_id: new FormControl(null, [Validators.required]),
    cohortLocation: new FormControl(null),
    cohortJobRole: new FormControl(null, [Validators.required]),
    // offerPOC: new FormControl(null),
    offerDeadline: new FormControl(null, [Validators.required]),

    fromAddress: new FormControl(this.fromAddress),
    toAddress: new FormControl('', [Validators.required]),
    subjectTitle: new FormControl('', [Validators.required]),
    template: new FormControl(null, [Validators.required]),
  })

  constructor(
    public globalService: GlobalServicesService,
    public emailService: EmailServicesService,
    public messageService: MessageService,
    private fileUploadService: FileUploadService,
    private eventServices: EventServicesService
  ) {
    this.deadlineList = [
      { label : '2 Hours', value : '2 Hours'},
      { label : '4 Hours', value : '4 Hours'},
      { label : '6 Hours', value : '6 Hours'},
      { label : '8 Hours', value : '8 Hours'},
      { label : '10 Hours', value : '10 Hours'},
      { label : '12 Hours', value : '12 Hours'},
      { label : '14 Hours', value : '14 Hours'},
      { label : '16 Hours', value : '16 Hours'},
      { label : '18 Hours', value : '18 Hours'},
      { label : '20 Hours', value : '20 Hours'},
      { label : '22 Hours', value : '22 Hours'},
      { label : '24 Hours', value : '24 Hours'},
      { label : '36 Hours', value : '36 Hours'},
      { label : '48 Hours', value : '48 Hours'},
      { label : '60 Hours', value : '60 Hours'},
      { label : '72 Hours', value : '72 Hours'},
    ]
  }

  ngOnInit() {
    this.loadTinyMce();
    // for (let ind = 0; ind < this.selectedUsersList.length; ind++) {
      //   this.emails.push(this.selectedUsersList[ind].email);
      // }
      console.log("🚀🔥 ~ file: send-email.component.ts:115 ~ ngOnInit ~ this.selectedUsersList:", this.selectedUsersList)
      // this.getEmailTemplates();
      this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';
      this.schoolId = this.schoolData.school_id;
      this.eventId = window.localStorage.getItem('selectedEventId') || '';
      this.loggedUser = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails') || '') : ''
      // this.getAllUsers();
      // this.getCohortStatuses();
      
      if(this.sendDocumentation){
        this.sendEmailForm.addControl('documentCohortLocation', new FormControl(null));
        this.sendEmailForm.addControl('documentCohortJobRole', new FormControl(null));
        this.sendEmailForm.addControl('documentDeadline', new FormControl(null, [Validators.required]));
        this.sendEmailForm.get('template')!.setValidators([Validators.required]);
        this.sendEmailForm.addControl('documentPOC', new FormControl(null, [Validators.required]));
      }
      this.getEventStageFormData();
      console.log("🚀🔥 ~ file: send-email.component.ts:126 ~ ngOnInit ~ this.stage.id, this.eventId:", this.stage, this.eventId)
  }
  getEventStageFormData = () => {
    this.eventServices.getEventStageActionFormDataFastApi(this.eventId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          let templateList = response.body.email_templates ? response.body.email_templates : [];
          this.templatesList = templateList.map((item: any) => ({
            id: item.id,
            templateTitle: item.name,
          }));
          let jobRolesList = response.body.job_roles ? response.body.job_roles : [];
          this.jobRolesList = jobRolesList.map((item: any) => ({
            name: item.name,
            value: item.id,
          }));
          
          this.jobLocationsList = jobRolesList.length > 0 ? jobRolesList.flatMap((item: any) => 
            item.cities.map((location: any) => ({
              name: location,
              value: location,
            }))
          ) : [];
          let eventUsers = response.body.eventUsers ? response.body.eventUsers : [];
          this.eventUsers = eventUsers.map((item: any) => ({
            id: item.id,
            email: item.email,
          }));
        }
      },
      error: (_err) => {
      }
    });
  }
  getEmailTemplates = () => {
    this.emailService.getTemplatesFastApi('').subscribe({
      next: (response) => {
        if (response.status === 200) {
          let templateList = response.body.email_templates ? response.body.email_templates : [];
          this.templatesList = templateList.map((item: any) => ({
            id: item.id,
            isEditable: item.is_editable,
            templateTitle: item.name,
          }));
        }
      },
      error: (_err) => {
      }
    });
  };

  ngOnChanges() {
    this.loadTinyMce();
    console.log("🚀🔥 ~ file: send-email.component.ts:157 ~ ngOnChanges ~ this.selectedUsersList:", this.selectedUsersList)
    this.emails = this.selectedUsersList.map((user: any) => user.email)
    this.sendEmailForm.controls['toAddress'].setValue(this.emails);
    this.sendOfferForm.controls['toAddress'].setValue(this.emails);
  }

  async emailWrite(event: any) {
    await this.getSpecificTemplateData(event.value);
  }
  async getSpecificTemplateData(template: any): Promise<void> {
    if (template.id) {
      return new Promise((resolve, reject) => {
        this.emailService.getSpecificTemplateFastApi(template.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.sendEmailForm.controls['subjectTitle'].setValue(response.body.subject);
              this.sendOfferForm.controls['subjectTitle'].setValue(response.body.subject);
              tinymce.activeEditor.setContent(response.body.body);
              console.log(response.body);
              resolve();
            }
          },
          error: (err) => {
            reject(new Error('Error fetching specific template: ' + err));
          }
        });
      });
    }
  }
  submit = async () => {
    this.clicked = true;
    // if (this.assignCohortData) {
    //   this.loader = true;
    //   let result = await this.assignCohortStatus();
    //   if (result) this.sendMail();
    //   else this.loader = false;
    // } else 
    if (this.sendDocumentation && this.sendEmailForm.valid) {
      let sourceData: any = [{
        fieldName: "sender",
        value: this.fromAddress,
        fieldType: "string"
      },
      {
        fieldName: "receiver_email",
        fieldType: "array",
        value: "email"
      },
      {
        fieldType: "string",
        fieldName: "subject",
        value: this.sendEmailForm.controls['template'].value.templateTitle,
      },
      {
        value: this.sendEmailForm.controls['template'].value.templateContent,
        fieldName: "data",
        fieldType: "string"
      },
      {
        user: this.loggedUser.email,
        comments: 'Documentation Link Sent Successfully',
      }
     ];


      let payload: any;
      let cohortData = {
        documentCohortLocation: this.sendEmailForm.controls['documentCohortLocation'].value,
        documentCohortJobRole: this.sendEmailForm.controls['documentCohortJobRole'].value,
        documentDeadline: this.sendEmailForm.controls['documentDeadline'].value,
        documentPOC: this.sendEmailForm.controls['documentPOC'].value,
      }
      this.loader = true;
      if (this.selectedUsersList.length > 1) {
        let emails = this.selectedUsersList.map((each: any) => each.email)
        payload = [{ sourceData: sourceData, source: [{ email: emails, documentCohortData: cohortData }] }];
        // this.eventServices.multipleCandidateChangeStatus(this.eventId, 'sendDocumentForm', payload).subscribe(res => {
        //   this.loader = false;
        //   this.mailSent.emit(true);
        // })
      } else {
        payload = [{ sourceData: sourceData, source: [{ data: this.selectedUsersList[0] ?? {}, documentCohortData: cohortData }] }]
        // this.eventServices.candidateChangeStatus(this.eventId, 'sendDocumentForm', payload).subscribe(res => {
        //   this.loader = false;
        //   this.mailSent.emit(true);
        // })
      }
    }
    else {
      if (this.sendEmailForm.valid) {
        this.sendMail();
      }
    }
  }

  
  async sendMail() {
    this.loader = true;
    let body = tinymce.get(0).getContent()
      ? tinymce.get(0).getContent()
      : null;

    if(body)
      {
        body = await this.templateImageUpload(
          body
        );
      }
      body = body.replace(/\/\$\{/g, "${");
    let form: any = {}
    form = this.sendEmailForm.value;
    console.log("🚀🔥 ~ file: send-email.component.ts:290 ~ this.selectedUsersList:", this.selectedUsersList)
    let payload: any = {
      candidate_uuids: this.selectedUsersList.map((each: any) => each.candidate_uuid || each.uuid),
      subject: form.subjectTitle,
      body: body
    }
    if (form.attachment) {
      payload['attachment'] = form.attachment;
    }
    if(this.stage){
      this.emailService.stageActionSendEmailFastApi(payload,this.stage.id, this.eventId).subscribe({
        next: (response) => {
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
          this.sendEmailForm.reset();
          tinymce.activeEditor.setContent('');
          this.mailSent.emit(true);
          this.clicked = false;
          this.loader = false;
        },
        error: (_err) => {
          this.loader = false;
        }
      });
    }else{
      this.emailService.sendEmailFastApi(payload).subscribe({
        next: (response) => {
          this.globalService.commonGrowl.next({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
          this.sendEmailForm.reset();
          tinymce.activeEditor.setContent('');
          this.mailSent.emit(true);
          this.clicked = false;
          this.loader = false;
        },
        error: (_err) => {
          this.loader = false;
        }
      });
    }
  }

  handleUpload(event: any, type: string, acl?: boolean) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (type === 'attachment') {
        this.fileUpload(file, type, acl)
      }
    };
  }

  fileUpload(file: any, type: string, acl?: boolean) {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay: any = {
      Bucket_name: this.bucketName,
      file_name: `email-attachment/${type}/${this.schoolId}/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    acl ? pay['ACL'] = 'public-read' : null;
    const blobData: any = file;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              this.uploadingFile = false;
              if (r && r.status === 200) {
                // let s3Url = pay.file_name;
                let attachmentPayload = {
                  "attachmentUrl": pay.file_name,
                  "attachmentName": this.attachmentFile[0].name,
                  "bucketName": this.bucketName
                }
                this.sendEmailForm.controls['attachment'].setValue(attachmentPayload);
              }
            }
          );
        }
      }
    );
  }

  onSelectMedia(event: any, acl?: boolean) {
    this.attachmentFile = event.addedFiles;
    this.handleUpload(event, 'attachment', acl)
  }

  onRemoveMedia(event: any) {
    this.attachmentFile.splice(this.attachmentFile.indexOf(event), 1);
    this.sendEmailForm.controls['attachment'].setValue('');
  }

  async loadTinyMce() {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/0eh36k1drqmz94mh92qtt7utdjc0pmvctldubb7sho99iiva/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    tinymce?.remove();
    tinymce
      .init({
        selector: 'textarea',
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        height: 500,
        menu: '',
        plugins:
          'print preview paste searchreplace autolink ' +
          'directionality code visualblocks visualchars fullscreen link media ' +
          'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
          'wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
          'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
          'insertfile quickimage media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'wrap',
        contextmenu: 'link image imagetools table',
        relative_urls: false,
      })
      .then((editor: any) => { });
  }

  getAllUsers() {
    this.eventServices.getAllUser().subscribe(res => {
      this.eventUsers = res?.data?.items;
    })
  }

  getCohortStatuses = () => {
    this.eventServices.getAllCohortId().subscribe(res => {
      this.cohortStatuses = res.data;
      this.cohortStatuses.sort((a: any, b: any) => +new Date(b.createdAt) - +new Date(a.createdAt));
    })
  }

  assignCohortStatus = (): Promise<any> => {
    return new Promise((resolve, rejects) => {
      if (this.sendOfferForm.valid) {
        let payload = {
          cohortId: this.sendOfferForm.value.cohort_id,
          cohortLocation: this.sendOfferForm.value.cohortLocation,
          cohortJobRole: this.sendOfferForm.value.cohortJobRole,
          offerDeadline: this.sendOfferForm.value.offerDeadline,
          // offerPOC: this.sendOfferForm.value.offerPOC,
          userIds: this.selectedUsersList.map((user: any) => user.id)
        }
        this.eventServices.mapCohortId(this.eventId, payload).subscribe((res: any) => {
          if (Object.keys(res.data.data)) {
            this.selectedUsersList.forEach((candidate: any) => {
              candidate.userCohortData = {
                  cohort_id: res.data.cohortData.id,
                  date: res.data.cohortData.cohort_date,
                  cohort_name:  res.data.cohortData.cohort_name,
                  cohort_spoc: res.data.cohortData.cohort_spoc,
                  cohortLocation: res.data.data[0].cohortLocation,
                  cohortJobRole:  res.data.data[0].cohortJobRole
              }
              candidate.offerDeadlineCount = this.sendOfferForm.value.offerDeadline;
            });
            return resolve(true);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Field Required', detail: 'Something Went Wrong!.' })
            return resolve(false);
          }
        });
      } else {
        // this.messageService.add({severity:'error', summary:'Field Required', detail:'Cohort Data Required.'})
        return resolve(false);
      }
    })
  }

  selectStatus(item: any, cohortOP: any) {
    cohortOP.hide();
    this.cohortNameTerm = item.cohort_name;
    this.selectedCohortStatus = item;
    this.selectedCohortStatus.cohort_date = new Date(this.selectedCohortStatus.cohort_date);
    this.selectedCohortStatus.cohort_spoc = this.eventUsers.filter((each: any) => each.id == this.selectedCohortStatus.cohort_spoc)[0];
  }

  selectCohort = (event: any) => {
    console.log(event)
    this.selectedCohortStatus = event.value;
  }

  templateImageUpload(data: string): Promise<any> {
    return new Promise((resolved, rejected) => {
      const elem: any = document.createElement('div');
      elem.innerHTML = data;
      const imgarray = elem.getElementsByTagName('img');
      const promiseArray = [];
      for (const match of imgarray) {
        if (
          match.src.indexOf('amazonaws.com') === -1 &&
          match.src.indexOf('base64') !== -1
        ) {
          promiseArray.push(
            new Promise((res, rej) => {
              const pay = {
                Bucket_name: this.bucketName,
                file_name:
                  'email-template/' +
                  'email-attachments/' + 
                  this.schoolId +
                  '/' +
                  nanoid(10),
                type: match.src.split(';base64')[0].split(':')[1],
                ACL: 'public-read'
              };
              this.fileUploadService.getSignedUrl(pay).subscribe((url: any) => {
                if (url) {
                  const json = url;
                  this.fileUploadService
                    .uploadUsingSignedUrl(
                      json.data,
                      this.dataURItoBlob(match.src, pay.type)
                    )
                    .subscribe((r: any) => {
                      if (r && r.status === 200) {
                        let url =
                          'https://' +
                          pay.Bucket_name +
                          '.s3.amazonaws.com/' +
                          pay.file_name;
                        match.src = url;
                        res(url);
                      }
                    });
                }
              });
            })
          );
        }
      }
      Promise.all(promiseArray).then((s3Url: any) => {
        resolved(elem.innerHTML);
      });
    });
  }

  dataURItoBlob(dataURI: any, type: any) {
    dataURI = dataURI.split('" ')[0];
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: type });
  }
}