import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { JobServicesService } from '../../services/job-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EventServicesService } from 'src/app/services/event-services.service';
import { TalentPoolService } from 'src/app/services/talent-pool.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import * as branchFile from '../../../assets/Json/branchList.json';
import * as collegeFile from '../../../assets/Json/collegeList.json';
import * as degreeFile from '../../../assets/Json/degreeList.json';
import { CandidateStatus } from 'src/app/job/candidate-list/candidate-list.component';
import { candidateStatus, statusColorMapping} from 'src/app/enum/candidate-status.enum';


@Component({
  selector: 'app-talentpool-management',
  templateUrl: './talentpool-management.component.html',
  styleUrls: ['./talentpool-management.component.css'],
  providers: [MessageService]
})
export class TalentpoolManagementComponent implements OnInit {

  // Array Var
  talentList: Array<any> = [];
  pageLimits: Array<any> = [];
  status: MenuItem[] = [];
  selectedTalent: Array<any> = [];
  talentpoolCol: Array<any> = [];
  invitesCol: Array<any> = [];
  candidatesCol: Array<any> = [];
  _talentpoolCol: Array<any> = [];
  _selectedColumns: any[] = [];
  colsToDownload: Array<any> = [];
  talentOptions: Array<any> = [];
  eventTalent: object = {};
  filteredTalentList: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  sourceData: Array<any> = [];
  eventList: Array<any> = [];
  eventDetails: Array<any> = [];
  _eventDetails: Array<any> = [];
  listAllCandidates: Array<any> = [];
  invitedCandidateList: Array<any> = [];
  allCategoryEvents: any;
  sourceTag: Array<string> = [];
  selectedDegree: Array<any> = [];
  selectedBranch: Array<any> = [];
  selectedCollege: Array<any> = [];
  candidateStatus: any = candidateStatus;
  genderList: any = [];
  pwdList: any = [];
  selectedGender: Array<string> = [];
  selectedPwd: Array<string> = [];

  //Filtering Array
  checkedColumns: Array<any> = [];
  selectedStatusElements: Array<any> = [];
  selectedJobTypeElements: Array<any> = [];
  selectedDepartmentElements: Array<any> = [];
  selectedUsersList: any = [];
  dates: any = [];
  moveToEvent: any;
  selectedTalentpoolTypeElements: Array<any> = [];
  rangeDates: Date[] = [new Date()];
  dateFormat: any;

  // Boolean Var
  addTalentpoolSlider: boolean = false;
  sendEmailSlider: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  moveToSelected: boolean = false;
  isLoading: boolean = false;
  allCandidateSelected: boolean = false;
  // selectAll: boolean = false;
  skipLimit: boolean = true;
  mapChoice: boolean = false;
  unmapChoice: boolean = false;
  filterLoading: boolean = false;
  filterActive: boolean = false;
  totalSkeleton: boolean = true;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  activeTabIndex: number = -1;
  totalPages: number = 1;

  // String var
  statusSelected: string = 'Active';
  eventSearchTerm: string = '';
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  columnFieldId: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'candidateList';
  candidateSearch: string = '';
  order_by: string = 'asc';
  sort_by: string = 'uuid';

  privilege: any = {};
  selectedUser: any;
  displayCandidateDetails: boolean = false;

  branchList: any = (branchFile as any).default;
  collegeList: any = (collegeFile as any).default;
  degreeList: any = (degreeFile as any).default;

  constructor(
    private jobService: JobServicesService,
    private messageService: MessageService,
    private _router: Router,
    private eventService: EventServicesService,
    private talentPoolService: TalentPoolService,
    private globalService: GlobalServicesService,

    private breadcrumbService: NgDynamicBreadcrumbService
    // public shortList: Ng2SearchPipeModule,
  ) {
    this.pageLimits = [10, 25, 50, 100];

    this.candidatesCol = [
      // { index: 1, field: 'id', header: 'Candidate ID' },
      // { index: 2, field: 'name', header: 'Name' },
      { index: 3, field: 'email', header: 'Email' },
      { index: 4, field: 'mobile_number', header: 'Contact Number' },
      { index: 5, field: 'gender', header: 'Gender' },
      { index: 5, field: 'date_of_birth', header: 'Date of Birth' },
      { index: 5, field: 'events', header: 'Event Name' },
      { index: 5, field: 'current_stage_name', header: 'Stage Name' },
      { index: 5, field: 'current_status', header: 'Stage Status' },
      // { index: 6, field: 'sourceTag', header: 'Source Tag' },
      // { index: 7, field: 'status', header: 'Account Status' },
      { index: 8, field: 'joined_date', header: 'Joined Date' },
      // { index: 9, field: 'degree', header: 'Degree' },
      // { index: 10, field: 'degreeBranch', header: 'Specialization' },
      // { index: 11, field: 'graduationYear', header: 'Batch' },
    ]

    this.selectedColumns = [
      // { index: 1, field: 'id', header: 'Candidate ID' },
      // { index: 2, field: 'name', header: 'Name' },
      { index: 3, field: 'email', header: 'Email' },
      { index: 4, field: 'mobile_number', header: 'Contact Number' },
      { index: 5, field: 'events', header: 'Event Name' },
      { index: 6, field: 'sourceTag', header: 'Source Tag' },
      { index: 7, field: 'status', header: 'Account Status' },
      { index: 8, field: 'createdAt', header: 'Joined Date' }
    ]

    this.invitesCol = [
      { index: 3, field: 'email', header: 'Email' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'mobileNumber', header: 'Mobile Number' },
      { field: 'eventCategory', header: 'Event Category' },
      { field: 'status', header: 'Status' },
      { field: 'invitedEvents', header: 'Invited Events' },
      { field: 'sourceType', header: 'Source Type' },
      { field: 'sourceName', header: 'Source Name' },
    ]

    this.genderList = [
      {
        "label": "Male",
        "value": "male"
      },
      {
        "label": "Female",
        "value": "female"
      },
      {
        "label": "Prefer not to say",
        "value": "others"
      }
    ]

    this.pwdList = [
      {
        "label": "Yes",
        "value": "Yes"
      },
      {
        "label": "No",
        "value": "No"
      },
      {
        "label": "Do not wish to disclose",
        "value": "Do not wish to disclose"
      },
      {
        "label": "Not yet filled",
        "value": "Not Yet Filled"
      }
    ]

    this.talentOptions = [
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.deleteTalent(this.eventTalent)
        }
      }
    ]

    this.status = [
      {
        label: 'Active',
        command: () => {
          this.statusSelected = 'Active';
          this.talentpoolCol = this.candidatesCol;
          this._selectedColumns = this.candidatesCol;
          this._talentpoolCol = this.talentpoolCol;
          this.colsToDownload = this.talentpoolCol;
          this.checkedColumns = this._selectedColumns;
          this.selectedUsersList = [];
          this.getAllCandidates();
          this.getSavedColumns();
          // this.getCandidateCount();
        },
      },
      {
        label: 'Invited',
        command: () => {
          this.statusSelected = 'Invited';
          this.talentpoolCol = this.invitesCol;
          this._selectedColumns = this.invitesCol;
          this._talentpoolCol = this.talentpoolCol;
          this.colsToDownload = this.talentpoolCol;
          this.checkedColumns = this._selectedColumns;
          this.selectedUsersList = [];
          this.getInvitedCandidates();
        },
      },
    ];
  }

  searchForm = [
    { name: 'Name', value: '' },
    { name: 'Email', value: '' },
    { name: 'Contact Number', value: '' },
    { name: 'Event Name', value: '' },
    { name: 'Source', value: '' },
    { name: 'Account Status', value: 'All' },
    { name: 'Joined Date', value: '' },
    { name: 'Candidate ID', value: '' }
  ]

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    this.getAllCandidates();
    this.getColumnId();
    this.getSavedColumns();
    this.getAllEventName(); 
    // this.getCandidateCount();
    // this.getAllCategoryEvents();
    this.talentpoolCol = this.candidatesCol;
    this._selectedColumns = this.candidatesCol;
    this._talentpoolCol = this.talentpoolCol;
    this.colsToDownload = this.talentpoolCol;
    this.checkedColumns = this._selectedColumns;
    // this.getEvent();
    this.allCandidateSelected = false;
    this.privilege = this.globalService.privilegeDetails;
    this.updateBreadcrumb();
    this.globalService.searchCandidateList.subscribe((response) => {
      this.talentList = [];
      this.talentList = response ? response.items : [];
      this.talentList.forEach((eachData: any) => {
        eachData.id = eachData.uuid;
      });
      this.totalLength = response.total || 0;
      this.first = response.page || 1;
      this.last = response.pages || 10;
      this.pageSize = response.size || 10;
      this.totalPages = response.pages || 1;
      this.loadingSpinner = false;
    })

  }


  updateBreadcrumb(): void {
    const breadcrumbs = [
      {
        label: 'Manage Candidate',
        url: ''
      }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  allSelected() {
    this.allCandidateSelected = true;
  }


  // API functions
  // get all the jobs
  getAllCandidates = () => {
    this.loadingSpinner = true;
    this.globalService.pageNumber = this.pageNumber;
    this.globalService.pageSize = this.pageSize;
    let searchTerm = this.globalService.searchTerm;
    let searchedEmail = searchTerm.trim().split(/\s+/);
    searchTerm = '';
    if (searchedEmail && searchedEmail.length === 1) {
      searchTerm = searchedEmail[0];
    } else if(searchedEmail.length > 0) {
      searchTerm = searchedEmail.join(' ');
    }
      this.talentPoolService
      .getAllCandidatesFastApi(this.pageNumber, this.pageSize, this.order_by, this.sort_by, searchTerm || undefined)
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.talentList = response.body ? response.body.items : [];
            this.talentList.forEach((eachData: any) => {
              eachData.id = eachData.uuid;
            });
            this.totalLength = response.body.total || 0;
            this.first = response.body.page || 1;
            this.last = response.body.pages || 10;
            this.pageSize = response.body.size || 10;
            this.totalPages = response.body.pages || 1;
            console.log(this.talentList, 'list');
            this.loadingSpinner = false;
          }
        },
        error: () => {
          this.totalLength =  0;
          this.first =  1;
          this.last =  10;
          this.pageSize =  10;
          this.loadingSpinner = false;
        }
      });
    // }
  }

  getInvitedCandidates = () => {
    this.loadingSpinner = true;
    this.totalSkeleton = true;
    this.talentPoolService.getAllInvitedCandidates(this.pageNumber, this.pageSize).subscribe(res => {
      if (res.status == 200) {
        this.talentList = res?.data ? res.data.items : [];
        this.talentList.forEach((item: any) => {
          item.status = 'P1 Form Sent';
        })
        this.totalLength = res.data.totalItems;
        this.totalSkeleton = false;
        this.loadingSpinner = false;
      }
    })
  }

  getFilterTalent = (status?: string) => {
    this.loadingSpinner = true;
    this.talentList = [];
    this.jobService
      .getFilterJobs(this.pageNumber, this.pageSize, status)
      .subscribe((response: any) => {
        // this.talentList = response.data ? response.data.items : [];
        // this.totalLength = response.data ? response.data.totalItems : 0;
        // this.loadingSpinner = false;

        // this.totalLength = this.talentList.length;
        this.loadingSpinner = false;
      });
  };

  deleteTalent = (talent: any) => {
    this.loadingSpinner = true;
    this.jobService.deleteJob(talent.id).subscribe(res => {
      if (res) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Candidate deleted...' });
        this.getAllCandidates();
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to delete the Candidate...' });
      }
    })
    this.loadingSpinner = false;
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    this.jobService.saveColumns(this.columnFieldId, [this.columnSettings.value]).subscribe((result: any) => {
      this.savedColumnSettings = result.data.jobSelectedColumns;
      this.loadingSpinner = false;
    });
    this.displaySaveColumnSettings = false;
  }

  getColumnId = () => {
    this.jobService.getSchool().subscribe(res => {
      this.columnFieldId = res.data[0].id;
    })
  }

  getEvent = () => {
    this.moveToSelected = true;
    this.eventService.getEventDetails().subscribe(res => {
      res.data.forEach((element: any) => {
        if (element.id && element.eventTitle) {
          this.eventDetails.push({
            id: element.id,
            name: element.eventTitle,
          });
        }
      });
      this._eventDetails = this.eventDetails;
    })
  }

  moveEvent(event: any, stagesOP: any) {
    // this.isLoading = true;
    window.localStorage.setItem('eventId', event.value);
    let payload = this.selectedUsersList;
    console.log("🚀🔥 ~ file: talentpool-management.component.ts:449 ~ TalentpoolManagementComponent ~ moveEvent ~ payload:", payload)
    // if (this.allCandidateSelected) {
    //   this.skipLimit = true;
    //   this.talentPoolService.candidateMoveToEventFastApi(payload, event.value, this.skipLimit).subscribe(res => {
    //     if (res.status == 200) {
    //       this.messageService.add({ severity: 'success', summary: 'Success', detail: `Candidate${this.selectedUsersList.length > 1 ? 's' : ''} Moved to ${event.name}.` });
    //       this.selectedUsersList = [];
    //       this.eventSearchTerm = '';
    //       this._eventDetails = this.eventDetails;
    //       this.isLoading = false;
    //       this.getAllCandidates();
    //     }
    //   });
    // } else {
    //   this.skipLimit = false;
    //   this.talentPoolService.moveEvent(payload, event.name, this.skipLimit).subscribe(res => {
    //     if (res.status == 200) {
    //       this.messageService.add({ severity: 'success', summary: 'Success', detail: `Candidate${this.selectedUsersList.length > 1 ? 's' : ''} Moved to ${event.name}.` });
    //       this.selectedUsersList = [];
    //       this.eventSearchTerm = '';
    //       this._eventDetails = this.eventDetails;
    //       this.isLoading = false;
    //       this.getAllCandidates();
    //     }
    //   })
    // }
    stagesOP.hide(event);
  }

  getCandidateCount = () => {
    this.totalSkeleton = true;
    this.talentPoolService.getTalentPoolCount().subscribe(res => {
      this.totalLength = res.data;
      this.totalSkeleton = false;
    })
  }

  // searchData(fieldName: any, i: number) {
  //   let search: string;
  //   if (this.searchForm[i].name == 'Account Status') {
  //     search = this.searchForm[i].value.toLowerCase();
  //   } else if (this.searchForm[i].name == 'Joined Date') {
  //     search = this.dates[i];
  //   } else {
  //     search = this.searchForm[i].value;
  //   }
  //   this.pageSize = 100;
  //   this.talentList = []
  //   this.globalService.getSearchCandidates(this.pageNumber, this.pageSize, search, fieldName).subscribe(res => {
  //     this.talentList = res.data.items
  //     console.log(search, fieldName, i)
  //     console.log(res)
  //     console.log(this.dates,'list')
  //   });

  // }

  event(i: number) {
    this.searchForm.forEach((item, idx) => {
      if (idx != i && item.name != 'Account Status') {
        item.value = ''
      }
    })
  }

  getSavedColumns = () => {
    this.jobService.getSchool().subscribe(res => {
      this.savedColumnSettings = res.data[0].jobSelectedColumns ? res.data[0].jobSelectedColumns : []
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService.deleteColumns(this.columnFieldId, columnId).subscribe(res => {
      this.savedColumnSettings = res.data.jobSelectedColumns;
      this.loadingSpinner = false;
    })
  }

  // Normal Functions
  setStatus = (status: string) => {
    this.statusSelected = status;
  }

  getEventVal = (event: Event) => {
    return (event.target as HTMLInputElement).value;
  }

  onSelectedTalentChange = (value: []) => {
    this.selectedTalent = value;

    if (this.selectedUsersList.length == this.pageSize) {
      this.allCandidateSelected = true;
    } else {
      this.allCandidateSelected = false;
    }
    // console.log(this.selectedUsersList);

  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [
      { index: 1, field: 'id', header: 'Candidate ID' },
      { index: 2, field: 'name', header: 'Name' },
      { index: 3, field: 'email', header: 'Email' }
    ];
  }

  applySelectedFilter = () => {
    this.activeTabIndex = -1;
    if (Array.isArray(this.rangeDates)) {
      if (this.rangeDates[0]) {
        if (this.rangeDates[1]) {
          this.rangeDates[0].setHours(this.rangeDates[0].getHours() + 5);
          this.rangeDates[0].setMinutes(this.rangeDates[0].getMinutes() + 30);
          this.rangeDates[1].setHours(this.rangeDates[1].getHours() + 29);
          this.rangeDates[1].setMinutes(this.rangeDates[1].getMinutes() + 29);
        }
      }
    }

    let startDate = this.rangeDates[0] ? new Date(this.rangeDates[0]).toISOString() : '';
    let endDate = this.rangeDates[1] ? new Date(this.rangeDates[1]).toISOString() : '';

    this.filterLoading = true;
    this.loadingSpinner = true;
    this.totalSkeleton = true;

    let payload = {}
    if (Array.isArray(this.selectedCollege) && this.selectedCollege.length > 0) {
      Object.assign(payload, { 'collegeName': this.selectedCollege });
    }
    if (Array.isArray(this.selectedBranch) && this.selectedBranch.length > 0) {
      Object.assign(payload, { 'degreeBranch': this.selectedBranch });
    }
    if (this.sourceTag.length > 0) {
      Object.assign(payload, { 'sourceTag': [this.sourceTag] });
    }
    if (Array.isArray(this.selectedDegree) && this.selectedDegree.length > 0) {
      Object.assign(payload, { 'degree': this.selectedDegree });
    }
    if (this.rangeDates[0] && this.rangeDates[1]) {
      Object.assign(payload, { 'dateRange': { startDate: startDate, endDate: endDate } });
    }
    if (this.selectedGender.length > 0) {
      Object.assign(payload, { 'gender': [this.selectedGender] });
    }
    if (this.selectedPwd.length > 0) {
      Object.assign(payload, { 'isPWD': [this.selectedPwd] });
    }
    if (this.unmapChoice || this.mapChoice) {
      Object.assign(payload, { 'showCandidates': { unMapped: this.unmapChoice, mapped: this.mapChoice } });
    }
    this.talentPoolService.talentpoolFilter(payload, this.pageNumber, this.pageSize).subscribe((res: any) => {
      this.filterLoading = false;
      this.loadingSpinner = false;
      if (res.status == 200) {
        this.talentList = res.data ? res.data.items : [];
        this.totalLength = res.data.totalItems >= 1 ? res.data.totalItems : '0';
        this.totalSkeleton = false;
        this.filterActive = true;
        if (Array.isArray(this.rangeDates)) {
          if (this.rangeDates[0]) {
            if (this.rangeDates[1]) {
              this.rangeDates[0].setHours(this.rangeDates[0].getHours() - 5);
              this.rangeDates[0].setMinutes(this.rangeDates[0].getMinutes() - 30);
              this.rangeDates[1].setHours(this.rangeDates[1].getHours() - 29);
              this.rangeDates[1].setMinutes(this.rangeDates[1].getMinutes() - 29);
            }
          }
        }
      }
      else {
        this.filterActive = false;
      }
    })
    if (this.selectedStatusElements.length || this.selectedTalentpoolTypeElements.length || this.selectedDepartmentElements.length) {
      this.filteredTalentList = [];

      let statusFilter = this.talentList.filter(element => this.selectedStatusElements.includes(element.status));
      let talentTypeFilter = this.talentList.filter(element => this.selectedTalentpoolTypeElements.includes(element.jobType));
      let departmentFilter = this.talentList.filter(element => this.selectedDepartmentElements.includes(element.department));

      this.filteredTalentList = [...new Set([...statusFilter, ...talentTypeFilter, ...departmentFilter])]
      this.showFilterSidebar = false;

    } else {
      this.filteredTalentList = [];
    }
  };

  clearSelectedFilters = () => {
    this.selectedStatusElements = [];
    this.selectedTalentpoolTypeElements = [];
    this.selectedDepartmentElements = [];
    this.rangeDates = [new Date()];
    this.sourceTag = [];
    this.selectedDegree = [];
    this.selectedBranch = [];
    this.selectedCollege = [];
    this.selectedGender = [];
    this.selectedPwd = [];
    this.mapChoice = false;
    this.unmapChoice = false;
    this.filterLoading = false;
    this.filterActive = false;
    this.getAllCandidates();
    // this.getCandidateCount();
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.talentpoolCol.filter(e => savedColumnValue.includes(e.field));
  }

  openCandidates = (talent: any) => {
    this.selectedUser = talent;
    this.displayCandidateDetails = true;
    console.log(talent)
  }
  // get jobs by limit
  getTalentByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    if (this.filterActive) {
      this.applySelectedFilter();
    } else {
      this.statusSelected == 'Active' ? this.getAllCandidates() : this.getInvitedCandidates();
    }
  };

  getInvitesByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getInvitedCandidates();
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._talentpoolCol = this.talentpoolCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.talentpoolCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  eventSearch(event: any) {
    if (event) {
      this._eventDetails = this.eventDetails.filter(val => val.name.toLowerCase().includes(event.toLowerCase()));
    } else {
      this._eventDetails = this.eventDetails;
    }

  }

  // get jobs by filter
  getTalentByFilter = (status: string) => {
    this.statusSelected = status;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllCandidates();
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  // For custom pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    if (this.filterActive) {
      this.applySelectedFilter();
    } else {
      this.statusSelected == 'Active' ? this.getAllCandidates() : this.getInvitedCandidates();
    }
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? (this.last == this.totalLength ? this.totalLength - this.talentList.length : this.last - this.pageSize) : this.pageSize;
    this.pageNumber -= 1;
    if (this.filterActive) {
      this.applySelectedFilter();
    } else {
      this.statusSelected == 'Active' ? this.getAllCandidates() : this.getInvitedCandidates();
    }
  }

  isLastPage(): boolean {
    return this.pageNumber === this.totalPages;; 
  }

  isFirstPage(): boolean {
    return this.first === 1;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.talentpoolCol.filter(col => val.includes(col));
  }

  getHeaderVal(col: string) {
    let result = col.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  setEventTalentId(talent: any) {
    this.eventTalent = talent;
  }

  exportRole = (type: string) => {
    let checkedColumnFields: any = ['candidateId'];
    console.log(this.checkedColumns)
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.talentList.forEach((eachData) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
        switch (item) {
          case "eventDetails":
            let eventName = this.fetchEventDetails(eachData["eventDetails"]);
            filterData[item] = eventName.join(',');
            break;
          case "specialization":
            filterData[item] = eachData["degreeDetails"][0]["degreeBranch"];
            break;
        }
      });
      afterFilter.push(filterData);
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'Excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  fetchEventDetails = (eventDets: any) => {
    let eventNameArray: any = [];
    eventDets.forEach((item: any) => {
      eventNameArray.push(item.eventTitle)
    });
    return eventNameArray;
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE = type === 'Excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    let FILE_EXTENSION = type === 'Excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + FILE_EXTENSION
    );
  }

  sendEmail() {
    this.sendEmailSlider = true;
  }

  mailSent(event: boolean) {
    if (event) {
      console.log(event)
      this.sendEmailSlider = false;
      this.selectedUsersList = [];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Mail Sent!' });
    }
  }
  getAllEventName() {
    this.moveToSelected = true;
    this.talentPoolService.getCategoryWiseEventsFastApi('3').subscribe({
      next: (res: any) => {
        const eventList = res.body;
        this.eventDetails = eventList.map((element: any) => ({
          name: element.name,
          value: element.uuid,
        }));
        this._eventDetails = this.eventDetails;
        console.log("🚀🔥 ~ file: invite-candidate.component.ts:301 ~ InviteCandidateComponent ~ this.eventList=eventList.map ~ this.eventList:", this.eventList)
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  }
  getEventName(eventId: string): string {
    let eventName = '';
    this.allCategoryEvents.forEach((item: any) => {
      if (item.eventId == eventId) {
        eventName = item.eventTitle;
      }
    });
    return eventName;
  }

  getAllCategoryEvents = async () => {
    let techEvents = [];
    let nonTechEvents = [];

    let getTechEvents = () => {
      return new Promise((resolve, rejects) => {
        this.talentPoolService.getAllEventsByCategory('technical').subscribe(res => {
          techEvents = res?.data ? res.data : [];
          return resolve(techEvents)
        })
      })
    }
    let getNonTechEvents = () => {
      return new Promise((resolve, rejects) => {
        this.talentPoolService.getAllEventsByCategory('nonTechnical').subscribe(res => {
          nonTechEvents = res?.data ? res.data : [];
          return resolve(nonTechEvents);
        })
      })
    }

    this.allCategoryEvents = (await Promise.all([getTechEvents(), getNonTechEvents()])).flat();
  }

  inviteSent(message: any) {
    this.addTalentpoolSlider = false;
    this.messageService.add({ severity: message.severity, summary: message.summary, detail: message.detail })
  }
  bulkInviteSent(message: any) {
    this.addTalentpoolSlider = false;
    this.messageService.add({ severity: message.severity, summary: message.summary, detail: message.detail })
  }

  filterHide() {
    this.filterLoading = false;
    this.showFilterSidebar = false;
    this.activeTabIndex = 1;
  }

  filterShow() {
    this.activeTabIndex = -1;
  }

  printDate() {
    console.log(this.rangeDates);
  }
}
