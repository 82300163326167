<div class="form-container">
  <div *ngIf="isLoading" class="loadingOverlay">
      <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
      </p-progressSpinner>
  </div>
  <div class="form-header">
      <p>Form Builder</p>
  </div>

  <div class="form-content">
      <div *ngFor="let item of formList" class="form-card" (click)="redirectForm(item.title)">
        <div class="form-icon-container">
          <img src="../../../assets/icons-svg/form-card.svg" alt="form-icon">
        </div>
        <div class="form-text">
          <div>{{item.title}}</div>
          <div>Form</div>
        </div>
      </div>
  </div>
  <p-toast></p-toast>

  <p-confirmPopup></p-confirmPopup>
</div>