import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventsListComponent } from './events-list/events-list.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    EventsListComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RoundProgressModule,
  ]
})
export class EventsModule { }
