import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/services/settings.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})

export class BranchesComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  branchData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  branchName: string = '';
  branchList: any;
  edit: boolean = false;
  clicked: boolean = false;
  userPrivileges: Set<string> = new Set();
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  editIndex: any = -1;
  branchDetails: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    mobile_number: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    address_line_1: new FormControl('', Validators.required),
    address_line_2: new FormControl(),
    area: new FormControl(),
    city: new FormControl(),
    state: new FormControl(),
    postal_code: new FormControl()
  })

  constructor(private settings: SettingsService, private messageService: MessageService,private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    this.getBranch();
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });
    
  }
  getBranch() {
    this.settings.getBranchDataFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.branchData = res.body;
          console.log("Branch data fetched successfully:", this.branchData);
        }
      },
      error: (_err) => {
      }
    });
  }

  editBranch(id: number) {
    this.edit = true;
    this.editIndex = id;
    this.addTemplateSidebar = true;
    this.settings.getSpecificBranchDataFastApi(id.toString()).subscribe({
      next: (res: any) => {
        this.branchDetails.patchValue(res.body);
      },
      error: (_err) => {}
    });
  }

  deleteBranch(id: number) {
    this.settings.deleteSpecificBranchDataFastApi(id.toString()).subscribe({
      next: (res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
        this.getBranch();
      },
      error: (_err) => {
      }
    });
  }

  openAddTemplate() {
    this.addTemplateSidebar = true;
  }

  cancel = () => {
    this.branchDetails.reset();
    this.addTemplateSidebar = false;
  }

  saveTemplate = () => {
    this.clicked = true;
    if (this.branchDetails.valid) {
      const apiCall = this.edit ? this.settings.updateSpecificBranchDataFastApi(this.editIndex.toString(), this.branchDetails.value)
                                : this.settings.createBranchDataFastApi(this.branchDetails.value);
      apiCall.subscribe({
        next: (res: any) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.detail });
          this.getBranch();
          this.edit = false;
          this.addTemplateSidebar = false;
          this.clicked = false;
          this.branchDetails.reset();
        },
        error: (err) => {
          console.error("Failed to save branch:", err);
          this.saveBtnLoading = false;
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: 'Please Fill the Required Fields' });
      this.saveBtnLoading = false;
    }
  }
  handleKeyDown(event: KeyboardEvent, id: number) {
    if (event.key === 'Enter') {
      this.editBranch(id);
    }
  }

}


