import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
export interface DepartmentList {
  id?: number,
  departmentName?: string,
  subDepartment: Array<string>,
  schoolId: string,
}
export interface DepartmentCopy {
  departmentName?: string,
  subDepartment: Array<string>,
  schoolId: string,
}

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {

  //boolean variables
  addDepartmentSidebar: boolean = false;
  showPlaceholder: boolean = false;
  saveBtnLoading: boolean = false;
  isLoading: boolean = false;
  submitted: boolean = false;
  clicked: boolean = false;


  // departmentContent !: FormArray;
  departmentDetails !: FormGroup;
  departmentList: Array<DepartmentList> = [];


  //string Variables
  selectedCategory: string = "department";
  contentDetails: string = '';
  subDropdown: string = '';
  subContent: string = '';

  //any variable
  departmentCopy: Array<DepartmentCopy> = [];
  temptCopy: any = [];
  editData: any;

  //number Variables
  id: number = 0;
  isEditable: boolean = false;


  constructor(private settings: SettingsService) { }

  ngOnInit(): void {

    this.departmentDetails = new FormGroup({
      departments: new FormControl(''),
      departmentInput: new FormControl(),
      subDepartmentInput: new FormControl(),
      subDropdown: new FormControl()
    });

    this.settings.getAllDepartmentData().subscribe((res: any) => {
      if (res.data) {
        res.data.forEach((item: any) => {
          this.departmentList = res.data;
        });
      }
      console.log(this.departmentList, 'Department')
    })

  }

  openAddTemplate() {
    this.addDepartmentSidebar = true;
    this.selectedCategory = "department";
    this.departmentCopy = [];
  }

  saveDepartment() {
    this.submitted = true;
    let schoolId: string = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    console.log(this.departmentCopy, 'pushed')
    this.departmentCopy[0].schoolId = schoolId;
    let payload = this.departmentCopy[0]
    if (this.isEditable) {
      let id = this.editData[0].id;
      this.settings.updateDepartmentData(id, this.editData).subscribe();
      console.log(id, this.editData, 'Edit value')
      this.isEditable = false;
    } else {
      this.settings.addDepartmentData(payload).subscribe({});
      this.departmentCopy = [];
    }
    this.addDepartmentSidebar = false;
    this.departmentDetails.reset();
  }

  cancelDepartment() {
    this.addDepartmentSidebar = false;
  }

  addDepartmentName() {
    console.log('Hello', this.contentDetails);
    if (this.contentDetails) {
      let content: DepartmentCopy = {
        subDepartment: [],
        schoolId: localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : ''
      }
      content.departmentName = this.contentDetails;
      this.departmentCopy.push(content);
      this.contentDetails = '';
      console.log(this.departmentCopy, "add");
    }
  }

  deleteDepartmentName(index: number) {
    if (index !== -1) {
      this.departmentCopy.splice(index, 1);
    }
    console.log(this.departmentList)
  }

  addSubDepartmentName() {
    // this.clicked = true;
    this.departmentCopy.map((department: any) => {
      if (department.departmentName == this.subDropdown) {
        department.subDepartment.push(this.subContent)
        this.subContent = '';
        this.subDropdown = '';
      }
    })

    console.log(this.departmentCopy);
  }

  deleteSubDepartmentName(i: number, j: number) {
    if (i !== -1 && j !== -1) {
      this.departmentList[i].subDepartment.splice(j, 1);
    }
  }

  deleteDepartment(department: any) {
    if (this.departmentList.length > 0) {
      // this.departmentList[id].id = 0;
      let id = department.id;
      if (id) {
        let idx: string = id;
        this.settings.deleteDepartmentData(idx).subscribe();
        // this.departmentList.splice(id,1);
        console.log('Success')
        console.log(idx)
        this.departmentList = this.departmentList.filter(item => item.id !== id)
      }
    }
    console.log(department, 'index');
  }

  editDepartment(department: any) {
    this.isEditable = true;
    // console.log(department,id)
    this.temptCopy = this.departmentList
    // this.departmentList = [];
    this.departmentCopy = Array.of(department);
    this.editData = this.departmentCopy;
    // this.departmentList = Array.of(department);
    // this.departmentList[id] = this.departmentCopy[id];
    // console.log(this.departmentCopy[id], this.departmentList[id], 'List')
    this.addDepartmentSidebar = true;
    this.selectedCategory = 'sub-department';
    this.subContent = department.subDepartment[0];
    this.subDropdown = department.departmentName;
    // this.departmentCopy = [];
    this.departmentList = this.temptCopy;
    console.log(this.departmentCopy, 'Edit')
    console.log(this.subContent, this.subDropdown, 'Edit')
  }

}
