import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { JobServicesService } from '../../services/job-services.service';
import { RoleServicesService } from '../../services/role-services.service';
import { GlobalServicesService } from '../../services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
// import * as locationList from '../../../assets/Json/locationsList.json'
import { nanoid } from 'nanoid'
import { CountryStateCityService } from 'src/app/services/country-state-city.service';

declare let tinymce: any;

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.css'],
  providers: [MessageService]
})
export class RoleAddComponent implements OnInit, OnChanges {
  @ViewChild('countryDiv') countryDiv: any;
  @ViewChild('stateDiv') stateDiv: any;
  @ViewChild('cityDiv') cityDiv: any;
  @ViewChild('roleTypeDiv') roleTypeDiv: any;
  @ViewChild('remoteDiv') remoteDiv: any;
  @ViewChild('hiringManagerDiv') hiringManagerDiv: any;
  @Input() data: any;
  isUpdate: boolean = false;
  title = 'formarray';
  orderForm!: FormGroup;
  locationInformation!: FormArray;
  selectedRoleType: string = '';
  selectedRemote: string = '';
  roleTypeList: any;
  workModeList = [
    { label: 'Remote', value: 'Remote' },
    { label: 'In Office', value: 'Office' },
    { label: 'Hybrid', value: 'Hybrid' },
  ];
  countries: any[] = [];
  locationOptions: any[] = [];

  selectedCountries: any[] = [];
  selectedStates: any[] = [];
  selectedCities: any[] = [];  
  jsonData: any = {};
  resume: File[] = [];
  mediaFile: File[] = [];
  mediaBase64: any = '';
  loadingSpinner: boolean = true;
  clicked: boolean = false;
  hiringManager: any = [];
  locationList: any = [];
  roleHeader: string = '';
  saveStatus: string = '';
  attach: any;
  roleDescriptionData: string = '';
  showEditor: boolean = false;
  saveBtnLoading: boolean = false;
  schoolId: string = '';
  uploadingFile: boolean = false;

  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;

  constructor(
    private formBuilder: FormBuilder,
    private jobService: JobServicesService,
    private messageService: MessageService,
    private roleService: RoleServicesService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService,
    private countryStateCityService: CountryStateCityService
  ) { }

  ngOnInit(): void {
    this.schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    this.globalService.removeScript();
    this.hiringManager = [];
    localStorage.setItem('owner', 'Ramesh');
    this.orderForm = new FormGroup({
      roleName: new FormControl('', Validators.required),
      requirement: new FormControl(''),
      roleType: new FormControl('', Validators.required),
      remoteJob: new FormControl('', Validators.required),
      roleDescription: new FormControl(''),
      attach: new FormControl(''),
      locationInformation: new FormArray([]),
    });
    this.locationInformation = this.orderForm.get(
      'locationInformation'
    ) as FormArray;
    this.loadCountryStateCityData();
    this.getHiringMangerAndRoleType();
    (this.data && this.data.isUpdate) ? this.updateAction() : this.saveAction();
    this.loadTinyMce();
  }
  loadCountryStateCityData() {
    this.countryStateCityService.getCountryStateCityData().subscribe(data => {
      this.jsonData = data;
      this.loadCountries();
    });
  }
   loadCountries() {
    this.countries = Object.keys(this.jsonData).map(country => ({
      label: country,
      value: country
    }));
  }

  onCountryChange(country: any, index: number) {
    this.selectedStates[index] = null;
    this.selectedCities[index] = null;
    if (country) {
      this.locationOptions[index].states = Object.keys(this.jsonData[country]).map(state => ({
        label: state,
        value: state
      }));
      this.locationOptions[index].cities = [];
      (this.locationInformation.at(index) as FormGroup).get('state')!.setValue('');
      (this.locationInformation.at(index) as FormGroup).get('city')!.setValue('');
    } else {
      this.locationOptions[index].states = [];
      this.locationOptions[index].cities = [];
      (this.locationInformation.at(index) as FormGroup).get('state')!.setValue('');
      (this.locationInformation.at(index) as FormGroup).get('city')!.setValue('');
    }
  }

  onStateChange(state: any, index: number) {
    this.selectedCities[index] = null;
    const country = this.selectedCountries[index];
    if (state && country) {
      this.locationOptions[index].cities = (this.jsonData[country][state] || []).map((city: any) => ({
        label: city,
        value: city
      }));
      (this.locationInformation.at(index) as FormGroup).get('city')!.setValue('');
    } else {
      this.locationOptions[index].cities = [];
      (this.locationInformation.at(index) as FormGroup).get('city')!.setValue('');
    }
  }
  onClickStateChange(state: any, index: number){
    this.selectedStates[index] = state;
    (this.locationInformation.at(index) as FormGroup).get('state')!.setValue(state);
    this.onStateChange(state, index);
  }
  onClickCountryChange(state: any, index: number){
    this.selectedCountries[index] = state;
    (this.locationInformation.at(index) as FormGroup).get('country')!.setValue(state);
    this.onCountryChange(state, index);
  }
  onClickCityChange(state: any, index: number){
    this.selectedCities[index] = state;
    (this.locationInformation.at(index) as FormGroup).get('city')!.setValue(state);
  }
  ngOnChanges(){
    this.mediaBase64 = this.data?.attach;
    let fileName = this.mediaBase64?.split('/').pop();
    let blob: any = new Blob([""], { type: 'application/pdf' });
    blob["lastModifiedDate"] = "";
    blob["name"] = fileName;
    this.mediaFile = [blob];
  }
  async getHiringMangerAndRoleType() {
    this.loadingSpinner = true;
    let apiMethod = this.roleService.getJobRolesFormDataFastApi(); 
    apiMethod.subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.hiringManager = response.body.hire_managers.map((element: any) => ({
            name: `${element.first_name} ${element.last_name}`,
            id: element.id
          }));
          this.roleTypeList = response.body.role_types.map((element: any) => ({
            label: element.name,
            value: element.id
          }));
        }
      },
      error: (_err) => {
        this.loadingSpinner = false;
      },
      complete: () => {
        this.loadingSpinner = false; 
      }
    });
  }
  async getLocations() {
    this.loadingSpinner = true;
    let apiMethod = this.globalService.getJobRolesLocationFastApi();
    apiMethod.subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.locationList = response.body.locations;
        }
      },
      error: (_err) => {
        this.loadingSpinner = false;
      },
      complete: () => {
        this.loadingSpinner = false;
      }
    });
  }
  updateAction() {
    this.roleHeader = 'Update Role';
    this.saveStatus = 'Update';
  }

  saveAction() {
    this.roleHeader = 'Create New Role';
    this.saveStatus = 'Save';
  }

  async setRoleDetails(data: any) {
    this.isUpdate = true;
    try {
      const response = await this.roleService.getSpecificJobRolesFastApi(data.id.toString()).toPromise();
    
      if (response?.status === 200 && response.body) {
        const data = response.body;
    
        const roleDetails = {
          roleName: data.name,
          requirement: data.requirement,
          roleType: data.role_type_id,
          remoteJob: data.work_mode,
          roleDescription: data.description,
          attach: data.attachment
        };
    
        this.orderForm.patchValue(roleDetails);
        this.selectedRoleType = this.roleTypeList.find((mode: any) => mode.value === data.role_type.id)?.label;
    
        if (data.description) {
          tinymce.activeEditor.setContent(data.description);
        }
        data.locations.forEach((each: any,index: number) => {
          this.locationInformation.push(
            this.formBuilder.group({
              location: each.location ? each.location : '',
              country: each.country ? each.country : '',
              state: each.state ? each.state : '',
              city: each.city ? each.city : '',
              hiringManager: each.hire_manager_id ? this.hiringManager.find((mode: any) => mode.id === each.hire_manager_id)?.name : '',
              hiringManagerId: each.hire_manager_id ? each.hire_manager_id : '',
              businessUnit: each.business_unit ? each.business_unit : '',
              openings: each.opening ? each.opening : '',
              maximumApplicants: each.max_applicant ? each.max_applicant : '',
              maleDiversity: each.diversity_male ? each.diversity_male : '',
              femaleDiversity: each.diversity_female ? each.diversity_female : '',
              otherDiversity: each.diversity_other ? each.diversity_other : '',
            })
          );
          // Initialize locationOptions and selected arrays for this index
        this.locationOptions.push({ states: [], cities: [] });
        this.selectedCountries.push(each.country ? each.country : null);
        this.selectedStates.push(each.state ? each.state : null);
        this.selectedCities.push(each.city ? each.city : null);

        // Populate states for the country
        if (each.country) {
          this.locationOptions[index].states = Object.keys(this.jsonData[each.country]).map(state => ({
            label: state,
            value: state
          }));
        }

        // Populate cities for the state
        if (each.country && each.state) {
          this.locationOptions[index].cities = (this.jsonData[each.country][each.state] || []).map((city: any) => ({
            label: city,
            value: city
          }));

        }

        // Set the form control value for 'city'
        if (each.city) {
          (this.locationInformation.at(index) as FormGroup).get('city')!.setValue(each.city);
        }
        });
      } else {
        console.error('Failed to fetch role details: Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching role data:', error);
    }
    
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      location: new FormControl('',),
      country: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      hiringManager: new FormControl('', Validators.required),
      hiringManagerId: new FormControl('', Validators.required),
      businessUnit: '',
      openings: '',
      maximumApplicants: '',
      maleDiversity: '',
      femaleDiversity: '',
      otherDiversity: ''
    });
  }

  addItem(): void {
    this.locationInformation.push(this.createItem());
    this.locationOptions.push({ states: [], cities: [] });
    this.selectedCountries.push(null);
    this.selectedStates.push(null);
    this.selectedCities.push(null);
  }

  get formData() {
    return <FormArray>this.orderForm.get('locationInformation');
  }

  checkMandatory(data: any) {
    let success = true;
    if (data.roleName && data.roleType) {
      data.locationInformation.forEach((each: any) => {
        if (!(each?.city?.length && each?.country?.length && each?.state?.length && each?.hiringManager?.length)) {
          success = false;
        }
      });
    } else {
      success = false;
    }
    return success;
  }

  submitItem() {
    this.orderForm.value.owner = localStorage.getItem('owner');
    this.orderForm.value.attach = this.mediaFile[0] ? this.mediaBase64 : '';
    this.orderForm.value.roleDescription = tinymce.get(0).getContent()
      ? tinymce.get(0).getContent()
      : null;
    if (this.checkMandatory(this.orderForm.value)) {
      if (this.isUpdate) {
        this.updateRoles();
      } else {
        this.addRoles();
      }
    } else {
      this.clicked = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Validation Failed',
        detail: 'Please Fill Mandatory Fields',
      });
    }
  }

  updateRoles() {
    this.saveBtnLoading = true;
    const updateJobRolePayload = {
      name: this.orderForm.value.roleName,
      role_type_id: this.roleTypeList.find((mode:any) => mode.label === this.orderForm.value.roleType)?.value,
      requirement: this.orderForm.value.requirement,
      description: this.orderForm.value.roleDescription,
      attachment: this.orderForm.value.attach,
      work_mode: this.workModeList.find(mode => mode.label === this.orderForm.value.remoteJob)?.value,
      locations: this.orderForm.value.locationInformation.map((each: any) => ({
        country: each.country ? each.country : '',
        state: each.state ? each.state  : '',
        city: each.city ? Array.isArray(each.city) ? each.city : [each.city] : [],
        hire_manager_id: each.hiringManagerId,
        business_unit: each.businessUnit,
        opening: each.openings ? each.openings : 0,
        max_applicant: each.maximumApplicants || 0,
        diversity_male: each.maleDiversity || 0,
        diversity_female: each.femaleDiversity || 0,
        diversity_other: each.otherDiversity || 0,
      })),
    };
    this.roleService.updateJobRolesFastApi(this.data.id, updateJobRolePayload).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
        }
        this.saveBtnLoading = false;
      },
      error: (_err) => {
        this.saveBtnLoading = false;
      }
    });
  }

  addRoles() {
    this.saveBtnLoading = true;
    const createJobRolePayload = {
      name: this.orderForm.value.roleName,
      role_type_id: this.roleTypeList.find((mode:any) => mode.label === this.orderForm.value.roleType)?.value,
      requirement: this.orderForm.value.requirement,
      description: this.orderForm.value.roleDescription,
      attachment: this.orderForm.value.attach,
      work_mode: this.workModeList.find(mode => mode.label === this.orderForm.value.remoteJob)?.value,
      locations: this.orderForm.value.locationInformation.map((each: any) => ({
        country: each.country ? each.country : '',
        state: each.state ? each.state : '',
        city: each.city ? Array.isArray(each.city) ? each.city : [each.city] : [],
        hire_manager_id: each.hiringManagerId,
        business_unit: each.businessUnit,
        opening: each.openings ? each.openings : 0,
        max_applicant: each.maximumApplicants || 0,
        diversity_male: each.maleDiversity || 0,
        diversity_female: each.femaleDiversity || 0,
        diversity_other: each.otherDiversity || 0,
      })),
    };
    this.roleService.createJobRolesFastApi(createJobRolePayload).subscribe({
      next: (response) => {
        if (response.status === 201) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail,
          });
          this.orderForm.reset();
          this.mediaBase64 = '';
          this.mediaFile = [];
          tinymce.activeEditor.setContent('');
          this.clicked = false;
        }
        this.saveBtnLoading = false;
      },
      error: (_err) => {
        this.saveBtnLoading = false;
      }
    });
  }

  removeItem(i: number) {
    this.locationInformation.removeAt(i);
  }

  handleUpload(event: any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file)
    };
  }

  fileUpload(file: any) {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `manage-job-roles/role-description-media/${this.schoolId}/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                this.mediaBase64 = (this.s3Domain + pay.file_name);
                this.uploadingFile = false;
              }
            }
          );
        }
      }
    );
  }

  onSelectMedia(event: any) {
    this.mediaFile = event.addedFiles;
    this.handleUpload(event);
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
  }

  onSave() { }

  updateLocationDetails(key: any, index: number, value: any) {
    this.locationInformation.at(index).patchValue({
      [key]: value,
    });
  }

  updateHiringManagerDetails(key: any, index: number, value: any) {
    this.locationInformation.at(index).patchValue({
      [key[0]]: value.name,
      [key[1]]: value.id,
    });
  }
  async loadTinyMce() {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/0eh36k1drqmz94mh92qtt7utdjc0pmvctldubb7sho99iiva/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    tinymce.remove();
    tinymce
      .init({
        selector: 'textarea',
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        height: 500,
        menu: '',
        plugins:
          'print preview paste searchreplace autolink ' +
          'directionality code visualblocks visualchars fullscreen link media ' +
          'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
          'wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
          'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
          'insertfile quickimage media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'wrap',
        contextmenu: 'link image imagetools table',
        relative_urls: false
      })
      .then((editor: any) => {
        this.data
          ? this.setRoleDetails(this.data)
          : this.addItem();
        this.showEditor = true;
        this.loadingSpinner = false;
      }
      );
  }
  viewCountry()
  {
    setTimeout(() => {
      let el: any = this.countryDiv.nativeElement;
      el.click();
      }, 200);
  }
  viewState()
  {
    setTimeout(() => {
      let el: any = this.stateDiv.nativeElement;
      el.click();
      }, 200);
  }
  viewCity()
  {
    setTimeout(() => {
      let el: any = this.cityDiv.nativeElement;
      el.click();
      }, 200);
  }
  
  viewRole()
  {
    setTimeout(() => {
      let el: any = this.roleTypeDiv.nativeElement;
      el.click();
      }, 200);
  }

  viewRemote()
  {
    setTimeout(() => {
      let el: any = this.remoteDiv.nativeElement;
      el.click();
      }, 200);
  }
  
  viewManager()
  {
    setTimeout(() => {
      let el: any = this.hiringManagerDiv.nativeElement;
      el.click();
      }, 200);
  }
  
}
