import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { ManageSlotService } from 'src/app/services/manage-slot.service';


@Component({
  selector: 'app-manage-slot',
  templateUrl: './manage-slot.component.html',
  styleUrls: ['./manage-slot.component.css'],
  providers: [MessageService]
})
export class ManageSlotComponent implements OnInit {

  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  addSlotSlider: boolean = false;
  saveBtnLoading: boolean = false;
  clicked: boolean = false;
  isInEvent: boolean = false;

  slotsList: any = [];
  slotsCol: Array<any> = [];
  _slotsCol: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [];
  slotLimits: Array<any> = [];
  checkedColumns: Array<any> = [];
  selectedUsers: Array<any> = [];

  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;

  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'slotsList';
  jobName: string = '';
  eventName: string = '';
  jobId: string = '';
  eventId: string = '';
  stageName: string = '';
  stageId: string = '';

  addSlotForm: FormGroup = new FormGroup({
    Date: new FormControl("", [Validators.required]),
    startTime: new FormControl("", [Validators.required]),
    endTime: new FormControl("", [Validators.required]),
    limit: new FormControl(null, [Validators.required]),
    booked: new FormControl(0),
    jobId: new FormControl()
  })

  constructor(private activatedRoute: ActivatedRoute, private slotServices: ManageSlotService, private messageService: MessageService, private router: Router) {
    this.pageLimits = [10, 25, 50, 100];
    this.slotLimits = [3, 5, 10, 15];

    this.slotsList = [

    ]

    this.slotsCol = [
      { index: 1, field: 'Date', header: 'Date' },
      { index: 2, field: 'startTime', header: 'Start Time' },
      { index: 3, field: 'endTime', header: 'End Time' },
      { index: 4, field: 'limit', header: 'Limit' },
      { index: 5, field: 'booked', header: 'Booked' },
    ]
  }

  ngOnInit(): void {
    this._slotsCol = this.slotsCol
    this.colsToDownload = this.slotsCol;
    this.checkedColumns = this.slotsCol;
    this.activatedRoute.queryParamMap.subscribe((param) => {
      this.stageName = param.get('stageName') || '';
      this.stageId = param.get('stageId') || '';
    })
    this.jobName = window.localStorage.getItem("selectedJobName") || '';
    this.eventName = window.localStorage.getItem("selectedEventName") || '';
    this.jobId = window.localStorage.getItem("selectedJobId") || '';
    this.eventId = window.localStorage.getItem("selectedEventId") || '';
    this.isInEvent = this.jobId === this.eventId;
    this.getAllSlots();
  }

  getAllSlots = () => {
    // this.loadingSpinner = true;
    // this.slotsList = [];
    // this.slotServices.getAllSlots(this.jobId, this.pageNumber, this.pageSize).subscribe(response => {
    //   console.log(response);

    //   this.slotsList = Object.keys(response.data).length !== 0 ? response.data.items : [];
    //   this.totalLength = response.data ? response.data.totalItems : 0;
    //   this.loadingSpinner = false;
    // })
    this.slotsList = [
      {
        "Date": "28 Oct, 2022",
        "startTime": "05:00 PM",
        "endTime": "06:00 PM",
        "limit": 10,
        "booked": 0,
        "jobId": "GNZ76kZZbm",
        "id": 'st0001',
      },
      {
        "Date": "28 Oct, 2022",
        "startTime": "11:00 AM",
        "endTime": "12:00 PM",
        "limit": 15,
        "booked": 2,
        "jobId": "GNZ76kZZbm",
        "id": 'st0002',
      },
      {
        "Date": "25 Nov, 2022",
        "startTime": "02:00 PM",
        "endTime": "03:00 PM",
        "limit": 3,
        "booked": 3,
        "jobId": "GNZ76kZZbm",
        "id": 'st0003',
      },
      {
        "Date": "25 Nov, 2022",
        "startTime": "03:00 PM",
        "endTime": "04:00 PM",
        "limit": 3,
        "booked": 0,
        "jobId": "GNZ76kZZbm",
        "id": 'st0004',
      }
    ]
  }

  getSlotsByLimit = () => {
    this.showFilterSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllSlots();
  };

  gotoWorkflow = () => {
    if (this.isInEvent) this.router.navigate(['events/candidates/', this.eventName], { queryParams: { view: 'workflow', stageId: this.stageId } });
    else this.router.navigate(['jobs/candidates/', this.jobName], { queryParams: { view: 'workflow', stageId: this.stageId } });

  }

  saveSlot = () => {
    this.clicked = true;
    if (this.addSlotForm.valid) {

      // this.saveBtnLoading = true;
      let payload = this.addSlotForm.value;
      payload.jobId = this.jobId;
      this.slotServices.createSlot(payload).subscribe(res => {
        if (Object.keys(res.data).length === 0) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to Save Slot!' });
        else {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Slot Saved Successfully' });
          this.addSlotForm.reset();
        }
        this.saveBtnLoading = false;
      })
    }
  }

  updateSlot = (slotId: string) => {
    // this.saveBtnLoading = true;
    let payload = this.addSlotForm.value;
    payload.jobId = this.jobId;
    this.slotServices.editSlot(slotId, payload).subscribe(res => {
      if (Object.keys(res.data).length === 0) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to Save Slot!' });
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Slot Updated Successfully' });
        this.addSlotForm.reset();
      }
      this.saveBtnLoading = false;
    })
  }

  searchColumns = (searchTerm: string) => {
    if (this.showDownloadSidebar) {
      this.colsToDownload = this.slotsCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  exportSlot = (type: string) => {
    let checkedColumnFields: any = ['roleName'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.slotsList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  next(): void {
    this.showFilterSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getAllSlots();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize >= this.pageSize ? this.totalLength - this.last : this.pageSize;
    this.pageNumber -= 1;
    this.getAllSlots();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

}
