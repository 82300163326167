<div class="application-page">
    <div class="application-header">
        <img src="../../assets/logo.webp" alt="" (click)="redirect()">
    </div>
    <div class="application-body">

        <div class="jobDescription-container">
            <p class="team">Neo Jarvis</p>
            <p class="jobName">{{jobDetails.jobName}}</p>
            <!-- <div class="jobFields">
                <p class="field">{{jobDetails.location }}</p>
                <p class="field">{{jobDetails.workExperience-1 + ' - ' + jobDetails.workExperience}}</p>
                <p class="field">{{jobDetails.jobType }}</p>
            </div> -->
            <div class="jobDescription">
                <span>
                    <p [style]="{color: 'var(--fc-light-dark)', fontWeight: 'var(--fw-semi-bold)'}"
                        class="customJobDescription" [innerHtml]="jobDetails.jobDescription">
                    </p>
                </span>
                <!-- <span>
                    <p [style]="{color: 'var(--fc-light-dark)', fontWeight: 'var(--fw-semi-bold)'}"
                        class="customJobDescription">
                        In simplest terms, a talent pool is a database of potential job candidates. The workers included
                        in the database are typically both highly qualified and have previously expressed interest, in
                        some form, of joining your organization. Talent pools allow organizations to cultivate
                        relationships with workers whose skills and expertise align with company competencies and
                        values. A proper talent pool database contains information about each candidate, including their
                        skills, potential roles they could fill, how well they fit within the corporate culture, and so
                        forth. This way, you do not have the start the recruiting process from scratch for each opening.
                        Instead, whenever a position opens in your organization, you already have a pool of
                        highly-qualified candidates who are already familiar with and interested in your organization,
                        to recruit from. Talent pools differ from talent pipelines. a talent pipeline consists of
                        candidates that have already been deemed qualified for particular positions. A talent pool is a
                        broader group of candidates that have not gone through the full vetting process required to
                        enter the talent pipeline. Ultimately, talent pools are the most effective method for
                        recruiting. Building, maintaining and nurturing a high-quality talent pool is a vital long-term
                        strategy for ensuring your organization is able to satisfy both its short and long-term
                        employment needs.
                    </p>
                </span> -->

            </div>

            <div class="application-form-container">
                <div class="submittedMessage-overlay" *ngIf="submittedSuccessfully">
                    <div class="submittedMessage">
                        <div class="submittedMessage-icon">
                            <div class="box box1"></div>
                            <div class="box box2">
                                <em [style]="{fontSize: '22px', position: 'absolute', zIndex: '1030'} "
                                    class="pi pi-check"></em>
                            </div>
                        </div>
                        <p>Application Submitted Successfully</p>
                        <button pButton pRipple type="button" label="Ok" (click)="resetForm(applicationForm)"></button>
                    </div>
                </div>
                <div class="heading">
                    Application Form
                </div>
                <form class="application-form-content" (ngSubmit)="submitForm()" [formGroup]="userDetailsForm">
                    <div class="personalDetails form-details">
                        <p class="heading" #applicationForm>Personal Details</p>
                        <hr>
                        <span class="p-float-label">
                            <input type="text"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['firstName'].errors}"
                                id="inputtext" pInputText required formControlName="firstName">
                            <label for="inputtext">First Name <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('firstName')"
                                name="First name"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['lastName'].errors}"
                                pInputText required formControlName="lastName">
                            <label for="inputtext">Last Name <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('lastName')"
                                name="Last name"></app-handle-error>
                        </span>
                        <span class="radioButtons">
                            <label [style]="{fontSize: '16px', fontWeight: 'var(--fw-semi-bold)'}">Gender <span
                                    class="imp">*</span></label>
                            <p-radioButton name="gender" value="male" formControlName="gender" inputId="male"
                                [required]="true">
                            </p-radioButton>
                            <label for="male"
                                [style]="{color: userDetailsForm.value.gender === 'male'? 'var(--fc-dark)' : 'var(--fc-light-dark)'}">Male</label>

                            <p-radioButton name="gender" value="female" formControlName="gender" inputId="female"
                                [required]="true">
                            </p-radioButton>
                            <label for="female"
                                [style]="{color: userDetailsForm.value.gender === 'female'? 'var(--fc-dark)' : 'var(--fc-light-dark)'}">Female</label>

                            <p-radioButton name="gender" value="others" formControlName="gender" inputId="others"
                                [required]="true">
                            </p-radioButton>
                            <label for="others"
                                [style]="{color: userDetailsForm.value.gender === 'others'? 'var(--fc-dark)' : 'var(--fc-light-dark)'}">Others</label>
                        </span>
                        <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('gender')" name="Gender">
                        </app-handle-error>
                        <span class="p-float-label">
                            <p-calendar formControlName="dateOfBirth" dateFormat="dd-mm-yy" dataType="string"
                                id="inputtext" [readonlyInput]="true"
                                [inputStyleClass]="clicked && userDetailsForm.controls['dateOfBirth'].errors ? 'invalid' : ''">
                            </p-calendar>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('dateOfBirth')"
                                name="Date of birth"></app-handle-error>
                            <label for="inputtext">Date of Birth <span class="imp">*</span></label>
                        </span>
                        <span class="radioButtons">
                            <label [style]="{fontSize: '16px', fontWeight: 'var(--fw-semi-bold)'}">Marital
                                Status</label>
                            <p-radioButton name="maritalStatus" value="single" formControlName="maritalStatus"
                                inputId="single">
                            </p-radioButton>
                            <label for="single"
                                [style]="{color: userDetailsForm.value.maritalStatus === 'single'? 'var(--fc-dark)' : 'var(--fc-light-dark)'}">Single</label>

                            <p-radioButton name="maritalStatus" value="married" formControlName="maritalStatus"
                                inputId="married">
                            </p-radioButton>
                            <label for="married"
                                [style]="{color: userDetailsForm.value.maritalStatus === 'married'? 'var(--fc-dark)' : 'var(--fc-light-dark)'}">Married</label>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext" pInputText required formControlName="emailId"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['emailId'].errors}">
                            <label for="inputtext">Email ID <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('emailId')"
                                name="Email id"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <p-inputMask id="inputtext" mask="9999999999" [required]="true" formControlName="mobileNum"
                                [styleClass]="clicked && userDetailsForm.controls['dateOfBirth'].errors ? 'invalid' : ''">
                            </p-inputMask>
                            <label for="inputtext">Mobile Number <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('mobileNum')"
                                name="Mobile number"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext" pInputText formControlName="citizenship"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['citizenship'].errors}">
                            <label for="inputtext">Citizenship</label>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext" pInputText required formControlName="currentAddress"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['currentAddress'].errors}">
                            <label for="inputtext">Current Address <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('currentAddress')"
                                name="Current address"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext" pInputText formControlName="location"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['location'].errors}">
                            <label for="inputtext">Location <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('location')"
                                name="Location"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext" pInputText formControlName="permanentAddress"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['permanentAddress'].errors}">
                            <label for="inputtext">Permanent Address <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('permanentAddress')"
                                name="Permenent address"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="inputtext" pInputText formControlName="linguisticSkills">
                            <label for="inputtext">Linguistic Skills</label>
                        </span>
                        <span class="radioButtons" [style]="{display: 'flex', flexWrap: 'wrap'} ">
                            <label
                                [style]="{fontSize: '16px', fontWeight: 'var(--fw-semi-bold)', color: 'var(--fc-light-dark)', width: '100%', marginBottom: '1rem' }">
                                Are you willing to relocate ? <span
                                    [style]="{color: 'var(--fc-dark)', fontWeight: 'var(--fw-semi-bold)' }">
                                    {{jobDetails.location}}</span>
                            </label>
                            <p-radioButton name="relocate" [value]="true" formControlName="relocate" inputId="yes">
                            </p-radioButton>
                            <label for="yes">Yes</label>

                            <p-radioButton name="relocate" [value]="false" formControlName="relocate" inputId="no">
                            </p-radioButton>
                            <label for="no">No</label>
                        </span>
                    </div>

                    <div class="educationDetails form-details">
                        <p class="heading">Education Details</p>
                        <hr>
                        <span class="p-float-label">
                            <p-inputNumber id="inputtext" class="inputNumber" formControlName="percentage10th"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['percentage10th'].errors}"
                                mode="decimal" [min]="0" [max]="100">
                            </p-inputNumber>
                            <label for="inputtext">10th Percentage <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('percentage10th')"
                                name="10th percentage"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <p-inputNumber formControlName="percentage12th" id="inputtext" class="inputNumber"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['percentage12th'].errors}"
                                mode="decimal" [min]="0" [max]="100">
                            </p-inputNumber>
                            <label for="inputtext">12th Percentage <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('percentage12th')"
                                name="12th percentage"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <p-inputNumber formControlName="percentageUG" id="inputtext" class="inputNumber"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['percentageUG'].errors}"
                                mode="decimal" [min]="0" [max]="100">
                            </p-inputNumber>
                            <label for="inputtext">UG Percentage <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('percentageUG')"
                                name="UG percentage"></app-handle-error>
                        </span>
                        <span class="p-float-label">

                            <p-inputNumber formControlName="percentagePG" id="inputtext" class="inputNumber"
                                mode="decimal" [min]="0" [max]="100">
                            </p-inputNumber>
                            <label for="inputtext">PG Percentage </label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('percentagePG')"
                                name="PG percentage"></app-handle-error>
                        </span>
                        <span class="p-float-label">
                            <p-inputNumber id="inputtext" class="inputNumber" formControlName="graduationYear"
                                mode="decimal" [min]="0" [maxlength]="4" mode="decimal" [useGrouping]="false"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['graduationYear'].errors}">
                            </p-inputNumber>
                            <label for="inputtext">Graduation Year <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('graduationYear')"
                                name="Graduation"></app-handle-error>
                        </span>
                        <span>
                            <ng-container formArrayName="degreeDetails" *ngFor="let _ of degreeDetails; index as i">
                                <hr class="CGPA">
                                <div class="degreeDetail">
                                    <div class="degreeDetails-inputs" [formGroupName]="i">
                                        <span class="p-float-label">
                                            <input type="text" id="inputtext" pInputText formControlName="degree" />
                                            <label for="inputtext" *ngIf="i == 0; else degreeLabel">Highest
                                                Qualification
                                                <span class="imp">*</span></label>
                                            <ng-template #degreeLabel>
                                                <label for="inputtext">Other Degree <span class="imp">*</span></label>
                                            </ng-template>
                                        </span>
                                        <!-- <p>{{userDetailsForm.value.degreeDetails.at(i).degree}} value </p>
                                        <p *ngIf="!userDetailsForm.value.degreeDetails.at(i).degree.length">12345678</p>
                                        <app-handle-error [clicked]="clicked" [control]="userDetailsForm.value.degreeDetails.at(i).degree" name="Graduation"></app-handle-error> -->

                                        <span class="p-float-label">
                                            <input type="text" id="inputtext" pInputText
                                                formControlName="specialization"
                                                [ngClass]="{'invalid': clicked && userDetailsForm.untouched}">
                                            <label for="inputtext">Specialization <span class="imp">*</span></label>
                                        </span>
                                        <span class="p-float-label">

                                            <p-inputNumber formControlName="percentage" id="inputtext"
                                                class="inputNumber"
                                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['percentage'].errors}"
                                                mode="decimal" [min]="0" [max]="100">
                                            </p-inputNumber>

                                            <label for="inputtext">Percentage <span class="imp">*</span></label>
                                        </span>
                                    </div>
                                    <em class="pi pi-plus addIcon-plus" *ngIf="i === degreeDetails.length - 1"
                                        (click)="addDegreeDetails()"></em>
                                    <em class="pi pi-minus removeIcon-minus" *ngIf="degreeDetails.length > 1"
                                        (click)="deleteDegreeDetails(i)"></em>

                                </div>
                            </ng-container>
                        </span>
                    </div>
                    <div class="workExperience form-details">
                        <p class="heading">Work Experience</p>
                        <hr>
                        <span class="p-float-label">
                            <p-inputNumber formControlName="yearOfExperience" id="inputtext"
                                [ngClass]="{'invalid': clicked && userDetailsForm.controls['yearOfExperience'].errors}"
                                mode="decimal" [min]="0" [max]="100">
                            </p-inputNumber>
                            <label for="inputtext">Year of experience <span class="imp">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="userDetailsForm.get('yearOfExperience')"
                                name="Year of experience"></app-handle-error>
                        </span>

                        <div *ngIf="userDetailsForm.value.yearOfExperience > 0" class="extra-workExp">
                            <span class="p-float-label">
                                <input type="text" id="inputtext" pInputText formControlName="designation">
                                <label for="inputtext">Designation</label>
                            </span>

                            <span class="p-inputgroup jobForm-elements">
                                <span class="p-inputgroup-addon addon-span rsSign">₹</span>
                                <span class="p-float-label">
                                    <p-inputNumber class="addon-input ctc" formControlName="expectedCTC" id="inputtext"
                                        mode="currency" currency="INR" [min]="0" [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label for="inputgroup">Expected CTC</label>
                                </span>
                            </span>
                            <span class="p-inputgroup jobForm-elements">
                                <span class="p-inputgroup-addon addon-span rsSign">₹</span>
                                <span class="p-float-label">
                                    <p-inputNumber class="addon-input ctc" formControlName="currentCTC" id="inputtext"
                                        mode="currency" currency="INR" [min]="0" [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label for="inputgroup">Current CTC</label>
                                </span>
                            </span>

                            <span class="p-float-label">
                                <input type="text" id="inputtext" pInputText formControlName="previousRole">
                                <label for="inputtext">Previous Role</label>
                            </span>
                            <span class="p-float-label">
                                <input type="number" id="inputtext" pInputText formControlName="previousRolePeriod">
                                <label for="inputtext">Previous Role Period</label>
                            </span>
                        </div>
                    </div>
                    <div class="resume form-details">
                        <p class="heading">Resume <span class="imp">*</span></p>
                        <hr>
                        <div *ngIf="!uploadingResume" class="fileUpload-dropzone" ngx-dropzone
                            [accept]="'application/pdf'" [multiple]="false" (change)="onSelectResume($event)"
                            [ngClass]="{'file-required': clicked && userDetailsForm.controls['yearOfExperience'].errors}">
                            <ngx-dropzone-label formControlName="resumeUpload">
                                <div class="dropzone-label">
                                    <em class="pi pi-cloud-upload"></em>
                                    <p class="upload-text">Click to <span
                                            [style]="{color: 'var(--fc-primary)'}">upload</span> or
                                        Drag & Drop</p>
                                    <p class="format-text">PDF Format only</p>
                                    <p class="format-text">(Less than 50MB)</p>
                                </div>
                            </ngx-dropzone-label>
                            <div *ngIf="resumeBase64.length">
                                <ngx-dropzone-preview class="resume-preview" ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of resume" [file]="f" [removable]="true"
                                    (removed)="onRemoveResume(f)">

                                    <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </div>
                        </div>
                        <div *ngIf="uploadingResume">
                            <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                                animationDuration="0.5s">
                            </p-progressSpinner>
                        </div>
                    </div>
                    <div class="profilePhoto form-details">
                        <p class="heading">Profile Photo <span class="imp">*</span></p>
                        <hr>
                        <div *ngIf="!uploadingProfile" class="fileUpload-dropzone" ngx-dropzone [accept]="'image/*'"
                            [multiple]="false" (change)="onSelectProfilePic($event)" [maxFileSize]="10000000"
                            [ngClass]="{'file-required': clicked && userDetailsForm.controls['yearOfExperience'].errors}">
                            <ngx-dropzone-label formControlName="proPicUpload">
                                <div class="dropzone-label">
                                    <em class="pi pi-cloud-upload"></em>
                                    <p class="upload-text">Click to <span
                                            [style]="{color: 'var(--fc-primary)'}">upload</span> or
                                        Drag & Drop</p>
                                    <p class="format-text">JPG, JPEG, PNG Format only</p>
                                    <p class="format-text">(Less than 50MB)</p>
                                </div>
                            </ngx-dropzone-label>
                            <div *ngIf="profileBase64.length">
                                <ngx-dropzone-image-preview class="profile-preview" ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of profilePic" [file]="f" [removable]="true"
                                    (removed)="onRemoveProfilePic(f)">
                                    <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                                    </ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </div>
                        </div>
                        <div *ngIf="uploadingProfile">
                            <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                                animationDuration="0.5s">
                            </p-progressSpinner>
                        </div>
                    </div>
                    <button pButton pRipple type="submit" label="Submit" class="p-button-raised submit-button"
                        [loading]="loadingButton"></button>
                </form>
            </div>
        </div>
        <p-toast></p-toast>
    </div>