<div class="event-add-header">
    <div class="event-heading">{{inEditMode ? 'Edit Event' : cloneEvent ? ' Clone Event' : 'Create New Event'}}</div>
    <div class="header-btn">
        <button pButton pRipple type="submit"
            (click)="inEditMode ? updateEvent(addEventForm) : publishEvent(addEventForm)"
            [label]="inEditMode ? 'Save' : 'Publish'" class="p-button-raised p-button-text button-align"
            [loading]="publishBtnLoading"></button>
    </div>
</div>
<p-divider class="p-divider"></p-divider>

<form class="eventForm" [formGroup]="addEventForm" (ngSubmit)="onPublish()">
    <span class="p-float-label eventForm-elements">
        <input type="text" id="inputtext" pInputText formControlName="eventTitle"
            [ngClass]="{'invalid': clicked && addEventForm.controls['eventTitle'].errors}">
        <label for="inputtext">Event Title <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('eventTitle')" name="Event title">
        </app-handle-error>
    </span>

    <span class="p-float-label  eventForm-elements">
        <p-calendar [(ngModel)]="eventStartDate" (ngModelChange)="resetRegistrationDates()" [readonlyInput]="true" formControlName="eventStartDate" dateFormat="m/d/y," dataType="date" id="inputtext"
            [minDate]="currentDate" [maxDate]="eventEndDate"[showIcon]="true" [showTime]="true" [hourFormat]="'12'"
            [inputStyleClass]="clicked && addEventForm.controls['eventStartDate'].errors ? 'invalid' : ''">
        </p-calendar>
        <label for="inputtext">Event Start Date <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('eventStartDate')" name="Event start date">
        </app-handle-error>
    </span>

    <span class="p-float-label  eventForm-elements">
        <p-calendar [(ngModel)]="eventEndDate" (ngModelChange)="resetRegistrationDates()" [readonlyInput]="true" formControlName="eventEndDate" dateFormat="m/d/y," dataType="date" id="inputtext" [minDate]="eventStartDate ?? currentDate"
            [showIcon]="true" [showTime]="true" [hourFormat]="'12'" (onSelect)="resetRegistrationDates()"
            [inputStyleClass]="clicked && addEventForm.controls['eventEndDate'].errors ? 'invalid' : ''">
        </p-calendar>
        <label for="inputtext">Event End Date <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('eventEndDate')" name="Event end date">
        </app-handle-error>
    </span>

    <span class="p-float-label p-input-icon-right eventForm-elements">
        <p-multiSelect [options]="jobRoles" formControlName="jobRole" [(ngModel)]="selectedJobRoles" id="chips"
            optionLabel="name" optionValue="id" display="chip" [disabled]="gettingRoles"
            [styleClass]="clicked && addEventForm.controls['jobRole'].errors ? 'invalid' : ''">
        </p-multiSelect>    
        <em [ngClass]="{'pi pi-spin pi-spinner': gettingRoles}" [style]="{fontSize: '20px', fontWeight: '600', marginLeft: '8px'}"></em>
        <label for="chips">Job Role <span class="imp">*</span></label> 
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('jobRole')" name="Job role">
        </app-handle-error>
    </span>

    <div class="p-inputgroup eventForm-elements">
        <span class="p-float-label">
            <p-inputNumber id="inputgroup" class="addon-input" formControlName="workExperience"
                [ngClass]="{'invalid': clicked && addEventForm.controls['workExperience'].errors}"></p-inputNumber>
            <label for="inputgroup">Work Experience</label>
            <span class="p-inputgroup-addon addon-span">Years</span>
        </span>
    </div>
    <div class="passwrod-error" *ngIf="clicked && addEventForm.controls['workExperience'].errors?.['max']">
        <p> Work Experience must be Less than or Equal to 50 </p>
    </div>
    <div class="passwrod-error" *ngIf="clicked && addEventForm.controls['workExperience'].errors?.['min']">
        <p> Valid Work Experience is required </p>
    </div>

    <div class="p-inputgroup eventForm-elements">
        <span class="p-inputgroup-addon addon-span rsSign">₹</span>
        <span class="p-float-label">
            <p-inputNumber type="number" class="addon-input salary" formControlName="minSalary" [(ngModel)]="minSalary"
                [ngClass]="{'invalid': clicked && addEventForm.controls['minSalary'].errors }"></p-inputNumber>
            <label for="inputgroup">Min Salary</label>
        </span>
    </div>
    <div class="passwrod-error" *ngIf="clicked && addEventForm.controls['minSalary'].errors">
        <p> Valid Minimum salary is required</p>
    </div>

    <div class="p-inputgroup eventForm-elements">
        <span class="p-inputgroup-addon addon-span rsSign">₹</span>
        <span class="p-float-label">
            <p-inputNumber type="number" class="addon-input salary" formControlName="maxSalary" [(ngModel)]="maxSalary"
                [ngClass]="{'invalid': (clicked && addEventForm.controls['maxSalary'].errors) || maxSalary < minSalary }"></p-inputNumber>
            <label for="inputgroup">Max Salary</label>
        </span>
    </div>
    <div class="passwrod-error" *ngIf="clicked && addEventForm.controls['maxSalary'].errors">
        <p> Valid Maximum salary is required</p>
    </div>
    <span class="p-float-label eventForm-elements">
        <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name" optionValue="uuid"
            formControlName="reg_form_uuid" [required]="true" [options]="regForms"
            [styleClass]="clicked && addEventForm.controls['reg_form_uuid'].errors ? 'invalid' : ''">
        </p-dropdown>
        <label for="dropdown">Registration Form <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('reg_form_uuid')" name="Event type">
        </app-handle-error>
    </span>
    <span class="p-float-label eventForm-elements">
        <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name" optionValue="id"
            formControlName="email_template_id" [required]="true" [options]="emailTemplates"
            [styleClass]="clicked && addEventForm.controls['email_template_id'].errors ? 'invalid' : ''">
        </p-dropdown>
        <label for="dropdown">Email Template<span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('email_template_id')" name="Event type">
        </app-handle-error>
    </span>

    <span class="p-float-label eventForm-elements" *ngIf="eventData?.eventCategory || !inEditMode">
        <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name" [disabled]="inEditMode"
            optionValue="id" formControlName="eventCategory" [required]="true" [options]="eventCategories"
            [styleClass]="clicked && addEventForm.controls['eventCategory'].errors ? 'invalid' : ''">
        </p-dropdown>
        <label for="dropdown">Event Category <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('eventCategory')" name="Event Category">
        </app-handle-error>
    </span>

    <span class="p-float-label eventForm-elements">
        <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name"
            (onChange)="onEventTypeChange()" optionValue="id" formControlName="eventType" [required]="true"
            [options]="eventTypes" [styleClass]="clicked && addEventForm.controls['eventType'].errors ? 'invalid' : ''">
        </p-dropdown>
        <label for="dropdown">Event Type <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('eventType')" name="Event type">
        </app-handle-error>
    </span>

    <span class="p-float-label eventForm-elements" *ngIf="addEventForm.value.eventType">
        <ng-container *ngIf="getSelectedEventType()?.institution_select_type === 'multiple'; else singleSelect">
            <p-multiSelect [options]="institutions" formControlName="institutionName" id="chips" optionLabel="name" style="width: 500px;"
                optionValue="id" display="chip" [filter]="true" filterBy="label" [virtualScroll]="true" 
                [styleClass]="clicked && addEventForm.controls['institutionName'].errors ? 'invalid' : ''">
            </p-multiSelect>
            <label for="chips">Institution Name <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="addEventForm.get('institutionName')" name="Institution name">
            </app-handle-error>
        </ng-container>
        <ng-template #singleSelect>
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name" optionValue="id" style="width: 500px;"
                formControlName="institutionName" [required]="true" [options]="institutions" [filter]="true" filterBy="name" [virtualScroll]="true"
                [styleClass]="clicked && addEventForm.controls['institutionName'].errors ? 'invalid' : ''">
            </p-dropdown>
            <label for="dropdown">Institution Name <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="addEventForm.get('institutionName')" name="Institution name">
            </app-handle-error>
        </ng-template>
    </span>

    <span class="p-float-label eventForm-elements" *ngIf="addEventForm.value.eventType">
        <input type="text" id="inputtext" pInputText formControlName="institutionAddress"
            [ngClass]="{'invalid': clicked && addEventForm.controls['institutionAddress'].errors}">
        <label for="inputtext">Institution Address <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('institutionAddress')"
            name="Institution address"></app-handle-error>
    </span>

    <span class="p-float-label  eventForm-elements">
        <p-calendar [(ngModel)]="registrationStartDate" [readonlyInput]="true" [disabled]="!(addEventForm.value.eventStartDate && addEventForm.value.eventEndDate)" formControlName="registrationStartDate" dateFormat="m/d/y," dataType="date" id="inputtext" [minDate]="currentDate"
            [maxDate]="addEventForm.value?.registrationEndDate ?? addEventForm.value?.eventEndDate" [showTime]="true" [hourFormat]="'12'" [showIcon]="true"
            [inputStyleClass]="clicked && addEventForm.controls['registrationStartDate'].errors ? 'invalid' : ''">
        </p-calendar>
        <label for="inputtext">Registration Start Date <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('registrationStartDate')"
            name="Registration start date"></app-handle-error>
    </span>

    <span class="p-float-label  eventForm-elements">
        <p-calendar [(ngModel)]="registrationEndDate" [readonlyInput]="true" [disabled]="!(addEventForm.value.eventStartDate && addEventForm.value.eventEndDate)" formControlName="registrationEndDate" dateFormat="m/d/y," dataType="date" id="inputtext" [minDate]="registrationStartDate"
            [maxDate]="eventEndDate" [showIcon]="true" [showTime]="true" [hourFormat]="'12'"
            [inputStyleClass]="clicked && addEventForm.controls['registrationEndDate'].errors ? 'invalid' : ''">
        </p-calendar>
        <label for="inputtext">Registration End Date <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('registrationEndDate')"
            name="Registration end date"></app-handle-error>
    </span>

    <span class="p-float-label eventForm-elements">
        <p-dropdown class="dropdown" inputId="dropdown" [filter]="true" [filterBy]="'name'" [autoDisplayFirst]="false"
            optionLabel="name" (onChange)="getOwnerDetails(addEventForm.controls['createdRole'].value)" optionValue="id" formControlName="createdRole" [options]="owners"
            [styleClass]="clicked && addEventForm.controls['createdRole'].errors ? 'invalid' : ''">
        </p-dropdown>
        <label for="dropdown">Owner Role <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('createdRole')" name="Owner Role"></app-handle-error>
    </span>
    <span class="p-float-label eventForm-elements">
        <p-dropdown class="dropdown" inputId="dropdown" [filter]="true" [filterBy]="'name'" [autoDisplayFirst]="false"
            optionLabel="name" optionValue="id" formControlName="createdBy" [options]="ownersDetails"
            [styleClass]="clicked && addEventForm.controls['createdBy'].errors ? 'invalid' : ''">
        </p-dropdown>
        <label for="dropdown">Owner <span class="imp">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addEventForm.get('createdBy')" name="Owner"></app-handle-error>
    </span>
    <p-toast></p-toast>
</form>