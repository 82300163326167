import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from './../../services/settings.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { SharedServicesService } from 'src/app/services/shared-services.service';
import { Message, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid'
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css'],
  providers: [SharedServicesService, MessageService]
})

export class CompanyDetailsComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  companyData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  saveDisable: boolean = false;
  profilePic: File[] = [];
  profileBase64: any = '';
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  defaultCover: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/cover-image/default-cover.png`;
  defaultLogo: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-logo.png`
  logoSrc: any = '';
  logoSrcEdit: any = '';
  coverImageSrcEdit: string = '';
  companyDetails: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    // primary_language: new FormControl('', Validators.required),
    company_category: new FormControl('', Validators.required),
    pan: new FormControl('', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]),
    gst: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)]),
    tin: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{11}$/)]),
    logo: new FormControl(""),
    cover_image: new FormControl(""),
    // default_currency: new FormControl('', Validators.required),
    // default_timezone: new FormControl('', Validators.required),
    sampleValues: new FormGroup({
      pan: new FormControl('ABCDE1234F'),
      gst: new FormControl('22AAAAA0000A1Z5'),
      tin: new FormControl('12345678901')
    })
  });

  companyName: string = '';
  industry: string = '';
  panNumber: string = '';
  gstNumber: string = '';
  tinNumber: string = '';
  // defaultCurrency: string = '';
  // defaultTimezone: string = '';
  // primaryLanguage: string = '';
  companyCategory: string = '';
  panNumberShow: boolean = false;
  gstNumberShow: boolean = false;
  tinNumberShow: boolean = false;
  clicked: boolean = false;
  companyId: string = '';

  industryList: any = [
    { label: "Research" },
    { label: "Development" },
    { label: "Core" }
  ];
  primaryLanguageList: any = [
    { label: "English" },
  ]

  companyCategoryList: any = ["Public Limited", "Corporate"];

  constructor(private settings: SettingsService, private fileUploadService: FileUploadService, private sharedService: SharedServicesService, private messageService: MessageService, private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    this.logoSrc = this.logoSrcEdit = this.defaultLogo;
    this.companyId = localStorage.getItem('companyUUID') ?? '';
    this.getCompanyDetails();
  }

  getCompanyDetails() {
    this.settings.getCompanyDataFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.companyData = res.body ? res.body : [];
          this.sharedService.emitChange(res.body?.logo);
          this.logoSrc = this.companyData.logo ?? this.defaultLogo;
          this.logoSrcEdit = this.companyData.logo ?? this.defaultLogo;
          this.coverImageSrcEdit = this.companyData.cover_image ?? this.defaultCover;
          this.userInfo = [
            { field: "Company", value: res.body.name ?? '-' },
            { field: "Industry", value: res.body.industry ?? '-' },
            // { field: "Primary Language", value: res.body.primary_language ?? '-' },
            { field: "Company Category", value: res.body.company_category ?? '-' },
            { field: "No. of Branches", value: res.body.branches_count ?? '-' },
            { field: "PAN Number", value: res.body.pan ?? '-' },
            { field: "GST Number", value: res.body.gst ?? '-' },
            { field: "TIN Number", value: res.body.tin ?? '-' },
            // { field: "Default Currency", value: res.body.default_currency ?? '-' },
            // { field: "Default Timezone", value: res.body.default_timezone ?? '-' }
          ];

        }
      },
      error: (err) => {
        console.error('Error fetching company data:', err);
      }
    });
  }

  openCompanyDetails() {
    this.addTemplateSidebar = true;
    console.log(this.companyData)
    this.companyName = this.companyData.name;
    this.industry = this.companyData.industry;
    // this.primaryLanguage = this.companyData.primary_language;
    this.companyCategory = this.companyData.company_category;
    this.panNumber = this.companyData.pan;
    this.gstNumber = this.companyData.gst;
    this.tinNumber = this.companyData.tin;
    // this.defaultCurrency = this.companyData.default_currency;
    // this.defaultTimezone = this.companyData.default_timezone;
  }

  cancel = () => {
    this.companyDetails.reset();
    this.addTemplateSidebar = false;
  }

  saveCompanyDetails = () => {
    this.saveBtnLoading = true;
    this.clicked = true;
    const payload = this.companyDetails.value;
    console.log("🚀🔥 ~ file: company-details.component.ts:126 ~ CompanyDetailsComponent ~ payload:", payload)

    if (this.companyDetails.valid) {
      const payload = this.cleanPayload(this.companyDetails.value);

      this.settings.updateCompanyDataFastApi(payload).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            console.log(res);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.body.detail,
            });
          }
          this.saveBtnLoading = false;
        },
        error: (err) => {
          console.error('Error updating company data:', err);
          this.saveBtnLoading = false;
        }
      });

    } else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the Required Fields', })
      this.saveBtnLoading = false;
    }
  }

  private cleanPayload(formValue: any): any {
    const payload = { ...formValue };

    if (!payload.logo || payload.logo === '') {
      delete payload.logo;
    }

    if (!payload.cover_image || payload.cover_image === '') {
      delete payload.cover_image;
    }

    return payload;
  }

  onSelectLogo(event: any, action?: any) {
    this.profilePic.push(...event.addedFiles);
    this.handleUpload(event, action)
  }
  handleUpload(event: any, type: 'logo' | 'cover') {
    const file = event.addedFiles[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      let img: any = new Image();
      img.src = e.target.result;
      const valid = (state: boolean) => {
        if (state) {
          if (type === 'logo') {
            this.logoSrcEdit = reader.result as string;
            this.getSignedUrlAndUpload(file, 'logo');
          } else {
            this.coverImageSrcEdit = reader.result as string;
            this.getSignedUrlAndUpload(file, 'cover');
          }
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid image size' });
        }
      };

      img.onload = function () {
        const width = this.width;
        const height = this.height;
        const isValid = type === 'logo' ?
          (width <= 800 && height <= 400) :
          (width <= 1920 && height <= 1080);
        valid(isValid);
      };
    };
    reader.readAsDataURL(file);
  }


  onRemoveProfilePic(event: any) {
    this.profilePic.splice(this.profilePic.indexOf(event), 1);
  }

  deleteFile(type: 'logo' | 'cover') {
    if (type === 'logo') {
      this.logoSrcEdit = this.defaultLogo;
      this.companyDetails.get('logo')?.setValue("");
    } else {
      this.coverImageSrcEdit = this.defaultCover;
      this.companyDetails.get('cover_image')?.setValue("");
    }
  }

  showPassword(selector: any) {
    let x: any = document.getElementsByClassName(selector)[0];
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  getSignedUrlAndUpload(file: any, type: 'logo' | 'cover') {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    const timestamp = new Date().getTime();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}_${timestamp}.${fileExtension}`;
    const filePath = `temp/${this.companyId}/${fileName}`;

    const payload = {
      file_path: filePath,
      bucket_type: 'public'
    };

    this.fileUploadService.getSignedUrlGCS(payload).subscribe({
      next: (response: any) => {
        console.log(response, 'response');
        if (response && response.body.signed_url) {
          this.uploadFileToGCS(response.body.signed_url, file, filePath, fileName, type);
        }
      },
      error: (error) => {
        console.error('Error getting signed URL:', error);
      }
    });
  }

  uploadFileToGCS(signedUrl: string, file: any, filePath: string, fileName: string, type: 'logo' | 'cover') {
    this.fileUploadService.uploadUsingSignedUrlGCS(signedUrl, file).subscribe({
      next: (response: any) => {
        if (response && response.status === "success") {
          const gcsUrl = `https://storage.googleapis.com/${this.bucketName}/${filePath}`;
          if (type === 'logo') {
            this.logoSrcEdit = gcsUrl;
            this.companyDetails.get('logo')?.setValue(fileName);
          } else {
            this.coverImageSrcEdit = gcsUrl;
            this.companyDetails.get('cover_image')?.setValue(fileName);
          }
          this.saveDisable = false;
        }
      },
      error: (error) => {
        console.error('Error uploading file to GCS: ', error);
      }
    });
  }

}

