import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})
export class TalentPoolService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  bulk_invite = environment.HOST.BULK_INVITE
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;
  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = localStorage.getItem('eventId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      schoolid: schoolId,
      jobid: jobId
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }

  constructor(private http: HttpClient, private globalService: GlobalServicesService ) { }
  //Fast APIs
  getAllCandidatesFastApi(pageNumber: number = 1, pageSize: number = 10, orderBy: string = 'asc', sortBy: string = 'id', searchEmail = ''): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy)
      .set('s', searchEmail);

    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/candidates`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  //candidate invite APIs
  getCandidateInviteFormDataFastApi(eventId:string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/candidates/form-data`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // Bulk Invite Candidates API
  candidateBulkInviteFastApi(eventId: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/candidates/bulk-invite`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  // Bulk Invite Candidates API
  candidateSingleInviteFastApi(eventId: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/candidates/invite`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  // Bulk Invite File Download API
  bulkInviteFileDownloadFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/candidates/invite/template`, {
      observe: 'response',
      responseType: 'blob' as 'json',  // Assuming it's a file download
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  // Move Candidate to Event API
  candidateMoveToEventFastApi(eventId: string, payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.fast_api_url}/events/${eventId}/candidates/move-to-event`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // Get Category wise Events API
  getCategoryWiseEventsFastApi(categoryId:string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/event-categories/${categoryId}/events`, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  // AddUser  
  addUser(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/', payload, this.options());
  }

  // BulkInvite  
  bulkInvite(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite + '/cloudRun/', payload, this.options());
  }

  singleInvite(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/talentPool/', payload, this.options());
  }

  getAllCandidates(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(this.api_url + '/talentPool/allCandidates?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  moveEvent(payload: any, eventTitle: string, skipLimit: any): Observable<any> {
    return this.http.post(this.api_url + '/talentPool/moveEvent?eventTitle=' + eventTitle, payload, this.options());
  }

  getTalentPoolCount(): Observable<any> {
    return this.http.get(this.api_url + '/talentPool/candidates/getCount', this.options())
  }

  singleInviteWithData(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite + '/invite/single', payload, this.options());
  }

  bulkTemplateDownload(): Observable<any> {
    return this.http.get(this.bulk_invite + '/cloudRun/downloadBulkTemplate', this.options())
  }

  bulkFileUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite + '/cloudRun/manageCandidate/bulk/invite', payload, this.options());
  }
  
  acknowlegdeSingleInvite(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite + '/invite/acknowledge/true', payload, this.options());
  }

  acknowlegdeBulkInvite(payload: any):  Observable<any> {
    return this.http.post(this.bulk_invite + '/cloudRun/acknowledge/', payload, this.options());
  }

  getAllEventsByCategory(category: string): Observable<any> {
    return this.http.get(this.api_url + '/event/getAll/eventsByCategory/' + category, this.options());
  }

  getAllInvitedCandidates(pageNumber: number, pageSize: number):Observable<any> {
    return this.http.get(this.bulk_invite + '/invite/all?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  //Filter API
  getFilter(pageNumber: number, pageSize: number, startDate: any, endDate: any): Observable<any> {
    return this.http.get(this.api_url + '/talentpool/filter/createdDate?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&startDate=' + startDate + '&endDate=' + endDate, this.options());
  }
  
  talentpoolFilter(payload: any, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.post(this.api_url + '/talentpool/filter/talentPool?pageNumber=' + pageNumber + '&pageSize=' + pageSize , payload, this.options());
  }
}
