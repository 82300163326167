<div class="user-header">
    <ng-container *ngIf="addUserSlider">
        <p-sidebar class="addJob-slider" [(visible)]="addUserSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
            position="right" (onHide)="getAllUsers()">
            <app-add-user *ngIf="addUserSlider" [data]="userData"></app-add-user>
        </p-sidebar>
    </ng-container>
    <button *ngIf="privilege.editManageUsers" pButton pRipple label="Add Users" class="addUsers-button" styleClass="p-button-sm mr-2 mb-2" (click)="userData= null;addUserSlider = true;"></button>
</div>

<div class="table">
    <!-- Download Sidebar -->
    <p-sidebar class="downloadSidebar userListDownload" [transitionOptions]="'0ms'" [(visible)]="showDownloadSidebar"
        [style]="{ padding: '0', border: '2px solid white'  }" [modal]="false" (onHide)="downloadHide()">
        <p class="sidebar-title">Download Data</p>

        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" (ngModelChange)="searchColumns($event)" [(ngModel)]="downloadSearchTerm" pInputText
                placeholder="Search" />
        </span>

        <div [style]="{height: '42vh'}">
            <div *ngFor="let col of colsToDownload" class="field-checkbox">
                <p-checkbox [value]="col" name="category" [inputId]="col.field" [(ngModel)]="checkedColumns">
                </p-checkbox>
                <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
            </div>
        </div>

        <div class="download-container">
            <p>Download as</p>
            <div class="radioButton-group">
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" name="exportButtons" inputId="excel" value="excel">
                    </p-radioButton>
                    <label for="excel" class="radioBtn-label"
                        [style]="{color: downloadAs === 'excel' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton value="csv" name="exportButtons" [(ngModel)]="downloadAs" inputId="csv">
                    </p-radioButton>
                    <label for="csv" class="radioBtn-label"
                        [style]="{color: downloadAs === 'csv' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <button pRipple pButton class="p-button-raised"
                (click)="(downloadAs === 'excel') ? exportUser('excel') : exportUser('csv')" type="button"
                label="Download"></button>
        </div>

    </p-sidebar>

    <!-- Filter Sidebar -->
    <p-sidebar [baseZIndex]="0" class="filterSidebar" [transitionOptions]="'0ms'" [modal]="false"
        [(visible)]="showFilterSidebar" ariaCloseLabel="Filter" [style]="{ padding: '0'}">
        <p class="sidebar-title">Filter</p>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input pInputText placeholder="Search" type="text">
        </span>
    </p-sidebar>


    <!-- Table -->
    <p-table #dt responsiveLayout="scroll" dataKey="id" [exportFilename]="exportName" [selection]="selectedUsers"
        (selectionChange)="onSelectedUserChange($event)" [globalFilterFields]="['userType']" [columns]="usersCol" scrollHeight="75vh" [value]="usersList"
        [resizableColumns]="true" [rows]="pageSize" scrollDirection="both" [loading]="loadingSpinner"
        [scrollable]="true" (onSort)="onSort($event)">
        <ng-template pTemplate="caption">
            <div class="caption table-header">
                <span class="table-header-left">
                    <!-- <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}"
                        icon="pi pi-filter" (click)="showFilterSidebar = true; showDownloadSidebar = false">
                    </button> -->
                    <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
                        icon="pi pi-download" (click)="showDownloadSidebar = true; showFilterSidebar = false">
                    </button>
                    <p-divider layout="vertical"></p-divider>
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="getUsersByLimit()"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"></p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton icon="pi pi-angle-left" class="p-button-secondary p-button-text simple-btn"
                        (click)="prev()" [disabled]="isFirstPage()"></button>
                    <button pButton icon="pi pi-angle-right" class="p-button-secondary p-button-text simple-btn"
                        (click)="next()" [disabled]="isLastPage()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template let-columns pTemplate="header">
            <tr [ngClass]="{'moveTable': showFilterSidebar || showDownloadSidebar}" class="table-heading">
                <th pFrozenColumn scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th scope="col" pSortableColumn="first_name" [style]="{width: '15vw'}" pFrozenColumn pResizableColumn>
                    First Name
                    <p-sortIcon class="sort-icon" field="first_name"></p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="last_name" [style]="{width: '15vw'}" pFrozenColumn pResizableColumn>
                    Last Name
                    <p-sortIcon class="sort-icon" field="last_name"></p-sortIcon>
                </th>
                <th [style]="{width: '15vw'}" scope="col" [pSortableColumn]="user.field === 'email' ? user.field : null"
                    *ngFor="let user of columns" pResizableColumn>
                    {{ user.header }}
                    <p-sortIcon class="sort-icon" *ngIf="user.field === 'email'" field="{{ user.field }}"></p-sortIcon>
                </th>
            </tr>
            <tr class="selection-operations-row" *ngIf="selectedUsers.length">
                <td pFrozenColumn class="selection-operations-options">
                    <span>{{selectedUsers.length}} Selected</span>
                    <button pButton pRipple label="Delete User" (click)="deleteUser(selectedUsers[0])" [style]="{fontWeight: '500'}"
                        [disabled]="selectedUsers.length > 1 || selectedUsers[0].candidateCount > 0"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-columns="columns">
            <tr class="row-user-list" [ngClass]="{'moveTable': showFilterSidebar || showDownloadSidebar}"
                [style]="{cursor: 'pointer'}">
                <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td (click)="openUser(user)" [style]="{width: '15vw'}" pFrozenColumn>{{ user.first_name }}</td>
                <td [style]="{width: '15vw'}" pFrozenColumn>{{ user.last_name }}</td>
                <td *ngFor="let col of columns" [style]="{width: '15vw'}">
                    <span [ngSwitch]="true">
                        <span *ngSwitchCase="col.field === 'userType'">
                            {{ userTypes[user[col.field]] }}
                        </span>
                        <span *ngSwitchDefault>
                            {{ user[col.field] ? user[col.field] :'-' }}
                        </span>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-toast></p-toast>
</div>`