<div *ngIf="isLoading" class="loadingOverlay">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>
<div class="pageInfo">
  <div class="settings-header" style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
      <div style="display: flex;justify-content:space-between; align-items: center;">
          <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Access Management</p>
      </div>
      <button style="cursor: pointer;width: 110px" (click)="edit=false;openSideBar()" pButton pRipple type="submit" label="Create New" tabindex="0">
      </button>
  </div>
</div>
<div class="info-card">
  <div class="templates-container" *ngIf="role_and_permission_list.length > 0; else noRoleAndPermission">
    <div class="form-list-container" *ngFor="let role of role_and_permission_list">
      <div class="list-title">
        {{role.name}}
      </div>
      <div class="list-btn-container">
        <button class="p-panel-header-icon p-link" (click)="edit=true;editOption(role)">
          <img src="../../../assets/icons-svg/edit.svg" alt="">
        </button>
      </div>
    </div>
  </div>
  <ng-template #noRoleAndPermission>
    <div class="no-options text-style">No options available.</div>
  </ng-template>
</div>

<p-sidebar *ngIf="addRoleAndPermissionSidebar" class="addTemplate-slider" [(visible)]="addRoleAndPermissionSidebar"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
    (onHide)="addRoleAndPermissionSidebar = false;roleAndPermissionForm.reset();" [style]="{width: '75vw'}">
    <div class="addTemplate-header">
        <div class="addTemplate-heading"><em style="margin-right: 12.5px;" class="pi"></em>Create New Role</div>
        <div class="header-btn">
          <button pButton pRipple type="submit" label="Cancel" (click)='cancelRoleAndPermission()'
            class="p-button-outlined button-align save-btn"></button>
          <button pButton pRipple type="submit" [label]="edit ? 'Update' : 'Save Changes'" (click)="edit ? updateRoleAndPermission() : saveRoleAndPermission()" [disabled]="saveDisable"
            class="p-button-raised p-button-text button-align" [ngClass]="{'save_change_btn': !edit}" [loading]="saveBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    <div class="templateForm" >
      <div [formGroup]="roleAndPermissionForm">
        <span class="p-float-label userForm-elements">
          <input type="text" class="inputtext" [attr.disabled]="edit ? true : undefined" id="inputtext" pInputText
            formControlName="role_name"
            [ngClass]="{'p-filled': roleAndPermissionForm.value.role_name,'invalid': clicked && roleAndPermissionForm.controls['role_name'].errors}">
          <label for="inputtext">Role Name <span [style]="{color: '#E24A47'}">*</span></label>
          <app-handle-error class="err-msg" [clicked]="clicked" [control]="roleAndPermissionForm.get('role_name')"
            name="Role Name"></app-handle-error>
        </span>
        <span class="role-desc-item">
          <label class="desc-label" for="">Description</label>
          <textarea formControlName="role_description" placeholder="Type Role Description here" class="role-desc" rows="5"
            cols="30" pInputTextarea [autoResize]="true"></textarea>
          <app-handle-error class="err-msg" [clicked]="clicked" [control]="roleAndPermissionForm.get('role_description')" name="Description">
          </app-handle-error>
        </span>
        <div>
          <span class="p-float-label userForm-elements userForm-description">
            <p-dropdown id="associated-role_dropdown" class="dropdown" inputId="dropdown" [autoDisplayFirst]="false"
              optionLabel="label" (onChange)="getModuleSlugList()" optionValue="value" [options]="moduleList" formControlName="module" [required]="true"
              [styleClass]="clicked && roleAndPermissionForm.controls['module'].errors ? 'invalid' : ''">
            </p-dropdown>
            <label for="dropdown">Select Module <span class="imp">*</span></label>
            <app-handle-error class="err-msg" [clicked]="clicked" [control]="roleAndPermissionForm.get('module')"
              name="Module">
            </app-handle-error>
            <br>
          </span>
        </div>
      </div>
      <div>
        <div>
          <div class="permission_header"> <span>Permissions</span></div>
          <ng-container *ngIf="_module_slug_list.length > 0; else noSlugList">
            <div class="module_list" *ngFor="let slug of _module_slug_list">
              <div class="permission_container">
                <div class="permission_container_header">
                  <span class="permission_logo"><img src="../../../assets/icons-svg/Institution.svg" alt="module-icon" > {{slug.label}}</span>
                  <span class="permission_select_all">
                    <p-checkbox 
                        name="category" 
                        [(ngModel)]="slug.isSelectedAll" 
                        [binary]="true" 
                        [inputId]="slug.slug"
                        (onChange)="updateSelectedCheckboxes(slug,true)">
                      </p-checkbox>
                      <label [for]="slug.slug" [style]="{paddingLeft: '10px'}">Select All</label>
                  </span>
                </div>
                <div class="permission_container_content">
                  <div *ngFor="let slugCheckbox of slug.value" class="field-checkbox">
                    <p-checkbox 
                      name="category" 
                      [value]="slugCheckbox.id" 
                      [(ngModel)]="slugCheckbox.isChecked" 
                      [binary]="true" 
                      [inputId]="slugCheckbox.slug"
                      (onChange)="updateSelectedCheckboxes(slug,false)">
                    </p-checkbox>
                    <label [for]="slugCheckbox.slug" [style]="{paddingLeft: '10px'}">{{ slugCheckbox.name }}</label>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </ng-container>
          <ng-template class="module_list" #noSlugList>
            <div class="no-options text-style">No options available.</div>
          </ng-template>
        </div>
      </div>
    </div>
    <p-toast></p-toast>
</p-sidebar>
<p-confirmPopup></p-confirmPopup>