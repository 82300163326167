import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CdkDragDrop, CdkDragMove, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { formFieldsSections,FormField,FormFieldsSections } from './form-sections';
import { ActivatedRoute, Router } from '@angular/router';
import {nanoid} from 'nanoid';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.css'],
  providers: [ConfirmationService],

})
export class FormBuilderComponent implements OnInit, AfterViewInit {
  @ViewChildren(CdkDropList) dropListsQuery!: QueryList<CdkDropList>;
  // formSections = formSections;
  formFieldsSections = formFieldsSections;
  _formFieldsSections = formFieldsSections;
  activeTabIndex: number | number[] = 0;
  innerTabIndexHeader: number | number[] = 0;
  innerTabIndexFormFields: number | number[] = 0;
  innerTabIndexFooter: number | number[] = 0;

  isMultiple = false;
  isLoading: boolean = false;
  checked: boolean = false;
  isViewChoice: boolean = false;
  isFormElementMoving: boolean = false;
  isApplyTemplate: boolean = false;
  isSaveTemplate: boolean = false;
  isAddSection: boolean = false;
  isSearchActive: boolean = false;
  uploadingFile: boolean = false;
  headerUploaded: boolean = false;
  updateSection: boolean = false;

  formType: string = '';
  searchFormElement: string = '';
  formName: string = 'Create New Form';


  viewChoiceOptions: any = {};
  headerFileName: any = {};
  fieldList: any = [];
  saveAndTemplate!: MenuItem[];
  
  // {
  //   "fieldLabel": "10th Percentage",
  //   "displaySection": "Education Details",
  //   "fieldDescription": "Kindly enter the value only in percentage. CGPA, GPA are not allowed.",
  //   "fieldType": "percentage",
  //   "placeHolder": "",
  //   "fieldName": "10th_percentage",
  //   "editLabel": false,
  //   "tempLabel": "",
  //   "isMandatory": false,
  //   "isShow": false,
  //   "isDependant": false,
  //   "dependant": {
  //     "dependantOn": null,
  //     "dependantValues": null
  //   }
  // }
  fileUploadPayload : any;
  availableControls: any = [];
  availableHeaders: any = [];
  availableFooter: any = [];
  dropLists: CdkDropList[] = [];
  eleType: Array<any> = [
    { label: 'Registration Form', value: 'reg_form' },
    { label: 'FeedBack Form', value: 'feedback_form' },
    { label: 'Documentation Form', value: 'doc_form' },
  ];
  applyTemplate: Array<any> = [
    { label: 'Template 1', value: 'template1' },
    { label: 'Template 2', value: 'template2' },
    { label: 'Template 3', value: 'template3' },
    { label: 'Template 4', value: 'template4' },
    { label: 'Template 5', value: 'template5' },
    { label: 'Template 6', value: 'template6' },
  ];
  selectedEleType: any[] = [{ label: 'Registration Form', value: 'reg_form' }];
  selectedEle: any[] = [];
  selectedApplyTemplate: any[] = [];

  _selectedTemplateType: string = 'existing-template';
  _newTemplateName: string = '';
  _newSectionName: string = '';
  _newSectionDescription: string = '';
  
  bucketName: string = this.globalService.bucketName;

  _updateSectionId: number = 0;
  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private globalService: GlobalServicesService,
    private cdr: ChangeDetectorRef
  ) { }
  // @HostListener("window:beforeunload", ["$event"])
  // onBeforeUnload(e: { preventDefault: () => void; returnValue: string; }) {
  //   // localStorage.setItem('FormBuilder', JSON.stringify(this.availableControls));
  //   e.preventDefault();
  //   e.returnValue = "";
  //   return;
  // }
  ngOnInit() {
    this.saveAndTemplate = [
      {
        label: 'Save as Template',
        command: () => {
          this.saveTemplateDialog();
        },
      },
    ];
    this.route.params.subscribe((params:any) => {
      this.formType = params['formName'].replace(/%20/g,' ');
      console.log("Form Type:", this.formType);
    });
    if(this.availableControls.length <= 0){
      const newSection = {
        section_id: 1,
        section_label: 'Default Section',
        section_description: '',
        section_form_fields: []
      };
      this.availableControls.push(newSection);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.dropLists = this.dropListsQuery.toArray();
      this.dropListsQuery.changes.subscribe((list: QueryList<CdkDropList>) => {
        this.dropLists = list.toArray();
      });
      this.cdr.detectChanges();
    });
  }
  saveFormBuilder() {
    if (this.availableControls.length <= 0) {
      this.messageService.add({ 
        severity: 'warn', 
        summary: 'Warning', 
        detail: 'Add one of the fields' 
      });
      return;
    }
  
    this.availableControls = this.availableControls.map((item: any) => {
      let newControl = { ...item };
      
      if (!newControl.isDependant) {
        delete newControl.dependant;
      }
      
      delete newControl.tempLabel;
      return newControl;
    });
    console.log("🚀🔥 ~ file: form-builder.component.ts:91 ~ FormBuilderComponent ~ this.availableControls=this.availableControls.map ~ this.availableControls:", this.availableControls)
  }

  previewForm(){
    let inviteUrl = 'form/' + this.formType + '/preview';
    localStorage.setItem('previewTemplate',JSON.stringify(this.availableControls));
    window.open(inviteUrl);
  }

  cancelFormBuilder() { }
  getConnectedLists(): string[] {
    return ['bodyFieldList', ...this.availableControls.map((_: any, i: any) => `sectionList${i}`)];
  }
  getConnectedHeaderLists(): string[] {
    return ['headerFieldList', ...this.availableHeaders.map((_: any, i: any) => `sectionList${i}`)];
  }
  getConnectedFooterLists(): string[] {
    return ['footerFieldList', ...this.availableFooter.map((_: any, i: any) => `sectionList${i}`)];
  }
  dropSection(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.availableControls, event.previousIndex, event.currentIndex);
  }
  drop(event: CdkDragDrop<any[]>): void {
    console.log("🚀🔥 ~ file: form-builder.component.ts:170 ~ FormBuilderComponent ~ drop ~ event:", event)
    if (event.previousContainer === event.container) {
      this.reorderWithinSameContainer(event);
    } else {
      this.handleDragBetweenContainers(event);
    }
  }
  dropHeaderFooter(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      this.reorderWithinSameContainer(event);
    } else {
      const draggedItem = { ...event.previousContainer.data[event.previousIndex] };
      event.container.data.splice(event.currentIndex, 0, draggedItem);
      this.disableItemInHeaderFooterFormFieldsSections(draggedItem.name);
    }
  }

  reorderWithinSameContainer(event: CdkDragDrop<any[]>): void {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  handleDragBetweenContainers(event: CdkDragDrop<any[]>): void {
    const draggedItem = event.previousContainer.data[event.previousIndex];
    let targetSectionId = this.extractSectionId(event.container.id);

    if (targetSectionId !== null) {
      const targetSectionIndex = this.availableControls[targetSectionId];

      if (!targetSectionIndex) {
        console.error('Section not found');
        return;
      }

      this.insertOrMoveItem(event, draggedItem, targetSectionIndex);
    }
  }

  insertOrMoveItem(event: CdkDragDrop<any[]>, draggedItem: any, targetSection: any): void {
    targetSection.section_form_fields.splice(event.currentIndex, 0, { ...draggedItem });
    this.disableItemInFormFieldsSections(draggedItem.id);

    if (event.previousContainer.id.startsWith('sectionList')) {
      this.removeDraggedItemFromPreviousContainer(event);
    }
  }

  removeDraggedItemFromPreviousContainer(event: CdkDragDrop<any[]>): void {
    event.previousContainer.data.splice(event.previousIndex, 1);
  }

  extractSectionId(containerId: string): number | null {
    const match = containerId.match(/sectionList(\d+)/);
    return match ? parseInt(match[1], 10) : null;
  }

  disableItemInFormFieldsSections(itemId: number) {
    this.disableItemInSections(itemId, this.formFieldsSections.form_fields, 'id');
  
    this.disableItemInSections(itemId, this._formFieldsSections.form_fields, 'id');
  
    this.cdr.detectChanges();
  }
  
  disableItemInHeaderFooterFormFieldsSections(itemName: string) {
    this.disableItemInSections(itemName, this.formFieldsSections.header, 'name');
    this.disableItemInSections(itemName, this.formFieldsSections.footer, 'name');
  
    this.disableItemInSections(itemName, this._formFieldsSections.header, 'name');
    this.disableItemInSections(itemName, this._formFieldsSections.footer, 'name');
  
    this.cdr.detectChanges();
  }
  
  private disableItemInSections(itemValue: string | number, sections: any[], key: 'id' | 'name') {
    for (const section of sections) {
      if (this.disableFieldInSection(itemValue, section.section_form_fields, key)) {
        return true;
      }
    }
    return false;
  }

  private disableFieldInSection(itemValue: string | number, fields: any[], key: 'id' | 'name'): boolean {
    for (const field of fields) {
      if (field[key] === itemValue) {
        field.disabled = true;
        return true;
      }
      if (field.element_type === 'group' && field.form_fields) {
        if (this.disableFieldInSection(itemValue, field.form_fields, key)) {
          return true;
        }
      }
    }
    return false;
  }


  listOfFields(section: any, item: any) {
    return section.section_form_fields
      .flatMap((field: any) => {
        if (field.type === 'Multi Input' || field.type === 'Multi Input Email') {
          return field.form_fields;
        }
        return field;
      })
      .filter((ele: any) => ele.type === 'Dropdown' && ele.name !== item.name);
  }
  
  listOfOptions(dependantOn: any) {
    if (!dependantOn) return [];
  
    const field = this.availableControls
      .flatMap((section: { section_form_fields: any; }) => section.section_form_fields)
      .flatMap((field: any) => {
        if (field.type === 'Multi Input' || field.type === 'Multi Input Email') {
          return field.form_fields;
        }
        return field;
      })
      .find((field: { name: any; }) => field.name === dependantOn);
  
    return field ? field.options : [];
  }
  
  
  moveItem(event: any) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    console.log("🚀🔥 ~ file: form-builder.component.ts:71 ~ FormBuilderComponent ~ moveItem ~ availableControls:", this.availableControls)
    console.log("🚀🔥 ~ file: form-builder.component.ts:71 ~ FormBuilderComponent ~ moveItem ~ availableHeaders:", this.availableHeaders)
  }
  getString(value: unknown): string {
    return typeof value === 'string' ? value : '';
  }
  editLabel(item: any) {
    item.tempLabel = item.label;
    item.editLabel = true;
  }

  saveLabel(item: any) {
    item.label = item.tempLabel;
    item.editLabel = false;
  }

  cancelEdit(item: any) {
    item.tempLabel = item.label;
    item.editLabel = false;
  }
  deleteField(item: any, event: MouseEvent, index: number): void {
    console.log("🚀🔥 ~ file: form-builder.component.ts:123 ~ FormBuilderComponent ~ deleteField ~ item:", item);

    this.confirmationService.confirm({
      target: event.target!,
      message: 'Are you sure that you want to delete this field?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.confirmDelete(item, index),
      reject: () => this.messageService.add({
        severity: 'error',
        summary: 'Rejected',
        detail: 'You have rejected',
      })
    });
  }

  confirmDelete(item: any, index: number): void {
    this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Field Deleted' });
    const sectionIndex = this.findSectionIndexContainingField(item);

    if (sectionIndex !== -1) {
      this.removeFieldFromSection(sectionIndex, index);
      this.enableFieldInAllSections(item);
    } else {
      const headerIndex = this.availableHeaders.findIndex((header: any) => header.id === item.id);
      if (headerIndex !== -1) {
        this.availableHeaders.splice(headerIndex, 1);
        this.enableFieldInAllSections(item);
      } else {
        const footerIndex = this.availableFooter.findIndex((footer: any) => footer.id === item.id);
        if (footerIndex !== -1) {
          this.availableFooter.splice(footerIndex, 1);
          this.enableFieldInAllSections(item);
        } else {
          console.error('Field not found in any section, header, or footer');
        }
      }
    }
  }

  findSectionIndexContainingField(item: any): number {
    return this.availableControls.findIndex((section: { section_form_fields: any[]; }) =>
      section.section_form_fields.some((field: { id: any; }) => field.id === item.id)
    );
  }

  removeFieldFromSection(sectionIndex: number, fieldIndex: number): void {
    this.availableControls[sectionIndex].section_form_fields.splice(fieldIndex, 1);
  }

  enableFieldInAllSections(item: any): void {
    const sectionKeys: (keyof FormFieldsSections)[] = ['header', 'form_fields', 'footer'];
    sectionKeys.forEach(sectionKey =>
      this.formFieldsSections[sectionKey].forEach(section =>
        section.section_form_fields.forEach((field: { id: any; disabled: boolean; }) => {
          if (field.id === item.id) {
            field.disabled = false;
          }
        })
      )
    );
  }
  viewChoiceDialog(item:any,index:number){
    this.isViewChoice = true;
    console.log("🚀🔥 ~ file: form-builder.component.ts:139 ~ FormBuilderComponent ~ viewChoiceDialog ~ item:", item);
    this.viewChoiceOptions = {
      header: `Field ${index + 1} - Choices`,
      fieldLabel: item.label,
      fieldOptions: item.options
    };
  }
  applyTemplateFormBuilder(){

  }
  saveTemplateFormBuilder(){
    console.log("🚀🔥 ~ file: form-builder.component.ts:149 ~ FormBuilderComponent ~ saveTemplateFormBuilder ~ this.form")
  }
  applyTemplateDialog(){
    this.isApplyTemplate = true;
  }
  saveTemplateDialog(){
    this.isSaveTemplate = true;
  }
  searchFormEle(event: any) {
    const searchTerm = event.target.value ? event.target.value.trim().toLowerCase() : '';
    this.isMultiple = true;
    this.cdr.detectChanges();

    if (!searchTerm) {
      this.isMultiple = false;
      this.isSearchActive = false;
      this._formFieldsSections = JSON.parse(JSON.stringify(this.formFieldsSections));
      this.activeTabIndex = [0];
      this.innerTabIndexHeader = [0];
      this.innerTabIndexFormFields = [0];
      this.innerTabIndexFooter = [0];
      this.cdr.detectChanges();
      return;
    }

    this.isSearchActive = true;

    let headerActive = false;
    let formFieldsActive = false;
    let footerActive = false;

    const filterFields = (fields: FormField[]): FormField[] => {
      return fields.filter(field => {
        if (field.form_fields) {
          field.form_fields = field.form_fields.filter(subField => subField.label.toLowerCase().includes(searchTerm));
        }
        return field.label.toLowerCase().includes(searchTerm) || (field.form_fields && field.form_fields.length > 0);
      });
    };

    const filterSections = (sections: any[], isHeader: boolean = false) => {
      return sections.map(section => {
        const filteredFields = filterFields(section.section_form_fields);
        if (filteredFields.length > 0) {
          if (isHeader) headerActive = true;
          else if (sections === this.formFieldsSections.form_fields) formFieldsActive = true;
          else if (sections === this.formFieldsSections.footer) footerActive = true;
        }
        return {
          ...section,
          section_form_fields: filteredFields
        };
      }).filter(section => section.section_form_fields.length > 0);
    };

    this._formFieldsSections = JSON.parse(JSON.stringify(this.formFieldsSections));
    this._formFieldsSections.header = filterSections(this.formFieldsSections.header, true);
    this._formFieldsSections.form_fields = filterSections(this.formFieldsSections.form_fields);
    this._formFieldsSections.footer = filterSections(this.formFieldsSections.footer);

    this.cdr.detectChanges();
    console.log("🚀🔥 ~ file: form-builder.component.ts:411 ~ FormBuilderComponent ~ searchFormEle ~ this._formFieldsSections", this._formFieldsSections)
    console.log("🚀🔥 ~ file: form-builder.component.ts:412 ~ FormBuilderComponent ~ searchFormEle ~ this.availableControls", this.availableControls)
  }

  
  addSectionDialog(){
    this.isAddSection = true;
  }
  addSectionFormBuilder(){
    if(!this._newSectionName){
      this.messageService.add({ 
        severity: 'warn', 
        summary: 'Warning', 
        detail: 'Please enter the section name' 
      });
      return;
    }
    const newSection = {
      section_id: this.availableControls.length + 1,
      section_label: this._newSectionName,
      section_description: this._newSectionDescription,
      section_form_fields: []
    };

    this.availableControls.push(newSection);
    this.isAddSection = false;
    this._newSectionName = '';
    this._newSectionDescription = '';

    this.cdr.detectChanges(); // Manually trigger change detection
    // console.log("🚀🔥 ~ file: form-builder.component.ts:169 ~ FormBuilderComponent ~ addSectionFormBuilder ~ this.formSections:", this.formSections)
    console.log("🚀🔥 ~ file: form-builder.component.ts:259 ~ FormBuilderComponent ~ addSectionFormBuilder ~  _newSectionName,_newSectionDescription:",  this._newSectionName,this._newSectionDescription)
  }
  fileUpload(fileCopy: any) {
    let fileName = fileCopy.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `manage-candidate/bulk-invite/SCHOOL-ID/${nanoid(10)}/${fileName}`,
      type: fileCopy.type,
    };
    const blobData: any = fileCopy;
    this.uploadingFile = true;
    // this.fileUploadService.getSignedUrl(pay).subscribe(
    //   (url: any) => {
    //     if (url) {
    //       const json = url;
    //       this.fileUploadService.uploadUsingSignedUrl(
    //         json.data,
    //         blobData
    //       ).subscribe(
    //         (r: any) => {
    //           if (r.status === 200) {
                this.fileUploadPayload = pay;
                this.uploadingFile = false;
                this.headerUploaded = true;
      //         }
      //       });
      //   }
      // });
  }
  onHandleHeaderUpload(event: any,item: any){
    this.headerFileName[item] = {
      file : event.addedFiles[0].name,
      time : new Date()
    }
    console.log("🚀🔥 ~ file: form-builder.component.ts:510 ~ onHandleHeaderUpload ~ this.headerFileName:", this.headerFileName)
    this.fileUpload(event.addedFiles[0])
  }
  onRemoveExcelFile(item: any){
    this.headerFileName[item.name] = null;
    this.headerUploaded = false;
  }
  editSection(item: any){
    this._newSectionName = item.section_label;
    this._newSectionDescription = item.section_description;
    this._updateSectionId = item.section_id;
    this.updateSection = true;
    this.isAddSection = true;
  }
  deleteSection(item: any, event: MouseEvent, index: number){
    this.confirmationService.confirm({
      target: event.target!,
      message: 'Are you sure that you want to delete this section?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.confirmDeleteSection(item, index),
      reject: () => this.messageService.add({
        severity: 'error',
        summary: 'Rejected',
        detail: 'You have rejected',
      })
    });
  }
  confirmDeleteSection(item: any, index: number){
    this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Section Deleted' });
    item.section_form_fields.forEach((field: any) => {
      this.enableFieldInAllSections(field);
    });
    this.availableControls.splice(index, 1);
    
  }
  updateSectionFormBuilder(){
    const sectionIndex = this.availableControls.findIndex((section: { section_id: any; }) => section.section_id === this._updateSectionId);
    this.availableControls[sectionIndex].section_label = this._newSectionName;
    this.availableControls[sectionIndex].section_description = this._newSectionDescription;
    this.isAddSection = false;
    this._newSectionName = '';
    this._newSectionDescription = '';
    this.updateSection = false;
  }
}
