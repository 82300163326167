import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { EventsListComponent } from './events/events-list/events-list.component';
import { JobListComponent } from './job-list/job-list.component';
import { JitsiContaineComponent } from './job/jitsi-container/jitsi-container';
import { CandidateListComponent } from './job/candidate-list/candidate-list.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { EmailTemplateComponent } from './email/email-template/email-template.component';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { ManageSlotComponent } from './job/manage-slot/manage-slot.component';
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component';
import { RegistrationFormComponent } from './forms/registration-form/registration-form.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './route-guard/auth.guard';
import { LoginGuard } from './route-guard/login.guard';
import { TalentpoolManagementComponent } from './talentpool/talentpool-management/talentpool-management.component';
import { CandidatePanelComponent } from './candidate-panel/candidate-panel.component';
import { OtpVerificationComponent } from './job/otp-verification/otp-verification.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { FormSettingsComponent } from './form-creation/form-settings/form-settings.component';
import { FormListComponent } from './form-creation/form-list/form-list.component';
import { FormPreviewComponent } from './form-creation/form-preview/form-preview.component';
import { FormBuilderComponent } from './form-creation/form-builder/form-builder.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'events', pathMatch: 'full' },
  { path: 'application-form/invitation', component: ApplicationFormComponent },
  { path: 'application-form/:jobId', component: ApplicationFormComponent },
  { path: 'events', component: EventsListComponent, canActivate: [AuthGuard], data: { privilege: ['event.view', 'event.create'] } },
  { path: 'jitsiContainer', component: JitsiContaineComponent, canActivate: [AuthGuard], data: { privilege: 'event_interview.join' } },
  // { path: 'jobs', component: JobListComponent, canActivate: [AuthGuard], data: { privilege: 'event.view' } },
  // { path: 'jobs/candidates/:jobName', component: CandidateListComponent, canActivate: [AuthGuard], data: { privilege: 'candidate.view' } },
  { path: 'events/candidates/:eventName', component: CandidateListComponent, canActivate: [AuthGuard], data: { privilege: 'event_candidate.view' } },
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard], data: { privilege:['user.view', 'user.create'] } },
  { path: 'email-template', component: EmailTemplateComponent, canActivate: [AuthGuard], data: { privilege: ['email_template.view', 'email_template.create'] } },
  { path: 'roles', component: RoleListComponent, canActivate: [AuthGuard], data: { privilege: ['job_role.view', 'job_role.create'] } },
  { path: 'talentpool-management', component: TalentpoolManagementComponent, canActivate: [AuthGuard], data: { privilege: ['candidate.view', 'candidate.invite'] } },
  { path: ':jobName/:stageName/manage-slot', component: ManageSlotComponent, canActivate: [AuthGuard], data: { privilege: 'event_stage.update' } },
  { path: 'form/:form_type', component: RegistrationFormComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'forgotPassword', component: ForgetPasswordComponent, canActivate: [LoginGuard] },
  { path: 'reset-password', component: ForgetPasswordComponent, canActivate: [LoginGuard] },
  { path: 'candidatePanel', component: CandidatePanelComponent },
  { path: 'general-settings', component: GeneralSettingsComponent, canActivate: [AuthGuard] },
  { path: 'form-settings', component: FormSettingsComponent, canActivate: [AuthGuard], data: { privilege: 'form_builder.view' } },
  { path: 'form-settings/:formName', component: FormListComponent, canActivate: [AuthGuard], data: { privilege: 'form_builder.view' } },
  { path: 'form/:formName/preview', component: FormPreviewComponent },
  { path: 'form-builder/:formName', component: FormBuilderComponent, canActivate: [AuthGuard], data: { privilege: 'form_builder.update' } },
  { path: 'verification', component: OtpVerificationComponent },
  { path: 'error', component: ErrorPageComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: '/error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
