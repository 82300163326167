// Define the Status enum
export enum candidateStatus {
    applied = 'Applied',
    pending = 'Pending',
    selected = 'Selected',
    rejected = 'Rejected',
    archived = 'Archived',
    shortlisted = 'Shortlisted',
    addedToVettedPool = 'Added To Vetted Pool',
    submitted = 'Submitted',
    scheduled = 'Scheduled',
    allClear = 'All Clear',
    eligible = 'Eligible',
    attended = 'Attended',
    noteligible = 'Not Eligible',
    waitlisted = 'Waitlist',
    noShow = 'No Show',
    slotSend = 'Slot Sent',
    submittedPartially = 'Submitted Partially',
    submissionPending = 'Submission Pending',
    approvedPartially = 'Approved Partially',
    joiningConfirmed = 'Joining Confirmed',
    joined = 'Joined',
    notJoining = 'Not Joining',
    notJoined = 'Not Joined',
    yetToJoin = 'Yet To Join',
    mailPending = 'Mail Pending',
    offered = 'Offered',
    offerPending = 'Offer Pending',
    offerRevision = 'Offer Revision',
    offerAccepted = 'Offer Accepted',
    offerDeclined = 'Offer Declined',
    onHold = 'On Hold',
    futureCandidate = 'Future Candidate',
}
  
export const statusColorMapping: Record<candidateStatus, string> = {
    [candidateStatus.applied]: 'var(--clr-violet-bg-light)',
    [candidateStatus.pending]: 'var(--clr-blue-bg-light)',
    [candidateStatus.selected]: 'var(--clr-green-bg-light)',
    [candidateStatus.rejected]: 'var(--clr-red-bg-light)',
    [candidateStatus.archived]: 'var(--clr-grey)',
    [candidateStatus.shortlisted]: 'var(--clr-green-bg-light)',
    [candidateStatus.addedToVettedPool]: 'var(--clr-violet-bg-light)',
    [candidateStatus.submitted]: 'var(--clr-violet-bg-light)',
    [candidateStatus.scheduled]: 'var(--clr-violet-bg-light)',
    [candidateStatus.allClear]: 'var(--clr-green-bg-light)',
    [candidateStatus.eligible]: 'var(--clr-green-bg-light)',
    [candidateStatus.attended]: '#E1E6CC',
    [candidateStatus.noteligible]: 'var(--clr-red-bg-light)',
    [candidateStatus.waitlisted]: '#FFE0B4',
    [candidateStatus.noShow]: '#FFE0B4',
    [candidateStatus.slotSend]: 'var(--clr-violet-bg-light)',
    [candidateStatus.submittedPartially]: '#FFE0B4',
    [candidateStatus.submissionPending]: 'var(--clr-blue-bg-light)',
    [candidateStatus.approvedPartially]: '#E1E6CC',
    [candidateStatus.joiningConfirmed]: 'var(--clr-violet-bg-light)',
    [candidateStatus.joined]: 'var(--clr-green-bg-light)',
    [candidateStatus.notJoining]: '#FFE0B4',
    [candidateStatus.notJoined]: 'var(--clr-red-bg-light)',
    [candidateStatus.yetToJoin]: '#E1E6CC',
    [candidateStatus.mailPending]: 'var(--clr-blue-bg-light)',
    [candidateStatus.offered]: 'var(--clr-violet-bg-light)',
    [candidateStatus.offerPending]: 'var(--clr-violet-bg-light)',
    [candidateStatus.offerRevision]: 'var(--clr-blue-bg-light)',
    [candidateStatus.offerAccepted]: 'var(--clr-green-bg-light)',
    [candidateStatus.offerDeclined]: 'var(--clr-red-bg-light)',
    [candidateStatus.onHold]: '#7a859275',
    [candidateStatus.futureCandidate]: '#E1E6CC'
};