import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, catchError, map } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;

  options() {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      Origin: window.location.origin,
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }
 
  constructor(private http: HttpClient, private globalService: GlobalServicesService) { }

  getSignedUrl(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/getSignedUrl',payload, this.options());
  }

  options_fast_api_with_company_uuid(isCandidate?:boolean) {
    let tokenString;
    if (isCandidate){
      tokenString = localStorage.getItem('token')? localStorage.getItem('token') : null;
    }else {
      tokenString = localStorage.getItem('tokenFastApi')? localStorage.getItem('tokenFastApi') : null;
    }
   
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }


  getSignedUrlGCS(payload: any, isCandidate?:boolean): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/gcs-signed-url', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid(isCandidate?true:false)
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  uploadUsingSignedUrlGCS(url: string, file: File): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders();
    return this.http.put(url, file, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, file)),
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  
  private getEventMessage(event: HttpEvent<any>, file: File): any {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
      case HttpEventType.Response:
        return this.fileUploadSuccess(event, file);
      default:
        return { status: 'unknown', message: `Unexpected event type: ${event.type}` };
    }
  }
  
  private fileUploadProgress(event: HttpProgressEvent): any {
    const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
    return { status: 'progress', message: `${percentDone}% uploaded.` };
  }
  
  private fileUploadSuccess(event: HttpResponse<any>, file: File): any {
    return { status: 'success', message: `File "${file.name}" was completely uploaded!`, body: event.body };
  }

  uploadUsingSignedUrl(url: string, payload: any) {
    return this.http.put(url, payload, { observe: 'events' });
  }
  getSignedDownloadUrl(payload: any):  Observable<any> {
    return this.http.post(this.api_url + '/getSignedUrl/download',payload, this.options());
  }

getFileByUrl(payload: any)
{
    return this.http.get(payload, this.options());
  }

  getSignedUrlBulkInvite(eventId: string, payload: any): Observable<any> {
    return this.http.post(`${this.fast_api_url}/events/${eventId}/candidates/bulk-invite`, payload, this.options_fast_api_with_company_uuid());
  }  
  
}
