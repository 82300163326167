import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { GlobalServicesService } from './global-services.service';

@Injectable({
  providedIn: 'root'
})

export class EmailServicesService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;

  options() {
    const schoolId = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.school_code : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '', 
      Origin: window.location.origin,
      schoolid: schoolId,
    });
    return { headers };
  }
  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }

  constructor(private http: HttpClient, private globalService: GlobalServicesService ) { }
  // fast APIs
  getEmailCategoriesFastApi(page = 1, size = 10, orderBy = 'asc', sortBy = 'id'): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('order_by', orderBy)
      .set('sort_by', sortBy);

    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/email-categories`, {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  createEmailCategoryFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/email-categories', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  updateEmailCategoryFastApi(payload: any, categoryId: string): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/email-categories/' + categoryId, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  deleteEmailCategoryFastApi(categoryId: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/email-categories/' + categoryId, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getSpecificEmailCategoryFastApi(categoryId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/email-categories/' + categoryId, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getEmailTemplateFormDataFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/email-templates/form-data', {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getTemplatesFastApi(emailCategoryId?: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    if (emailCategoryId) {
      params = params.set('email_category_id', emailCategoryId);
    }
  
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/email-templates', {
      params,
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  createTemplateFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/email-templates', payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  updateTemplateFastApi(payload: any, templateId: string): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(this.fast_api_url + '/email-templates/' + templateId, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  deleteTemplateFastApi(templateId: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(this.fast_api_url + '/email-templates/' + templateId, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  getSpecificTemplateFastApi(templateId: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/email-templates/' + templateId, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // Stage Action Send Email API
  stageActionSendEmailFastApi(payload: any,stageId:string,eventId:string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/${stageId}/actions/send-email`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }
  // Send Email API
  sendEmailFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/candidates/send-email`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError(this.globalService.handleError.bind(this.globalService))
    );
  }

  sendDocumentationEmailFastApi(payload : any, eventId: string, stageId : string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + `/events/${eventId}/stages/${stageId}/actions/send-documentation`, payload, {
      observe: 'response',
      ...this.options_fast_api_with_company_uuid()
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
  // Email APIs
  AddTemplteCategory(payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-template/category/detail', payload, this.options());
  }

  getTemplates(categoryId?: string): Observable<any> {
    return this.http.get(this.api_url + '/company-template/?categoryId=' + categoryId, this.options());
  }

  addTemplate(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/company-template', payload, this.options());
  }

  editTemplate(payload: any, templateId: string): Observable<any> {
    return this.http.put(this.api_url + '/company-template/' + templateId, payload, this.options());
  }

  deleteTemplate(templateId: string): Observable<any> {
    return this.http.delete(this.api_url + '/company-template/' + templateId, this.options());
  }

  sendEmail(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/email-template/sendEmail', payload, this.options());
  }
}
