import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpService } from 'src/app/services/otp.service';
import { nanoid } from 'nanoid';
import { GlobalServicesService } from '../services/global-services.service';

@Component({
  selector: 'app-login-otp-verification',
  templateUrl: './login-otp-verification.component.html',
  styleUrls: ['./login-otp-verification.component.css']
})
export class LoginOtpVerificationComponent implements OnInit {

  @ViewChild('otp_value') otp_value: any;

  @Input() loginEmail: string = '';
  @Input() routingDetails: any;
  @Input() otpRequired: any;
  @Input() userDetails: any;
  @Input() privilegeData: any;

  otpValue: any;
  userName: string = '';
  infoMessage: any = {};

  btnLoading: boolean = false;
  pageLoading: boolean = false;
  sendOtpState: boolean = true;
  verifyOtpState: boolean = true;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  clicked: boolean = false;
  invalidOTP: boolean = false;
  expiredOTP: boolean = false;
  displayInfoMessage: boolean = true;
  loginCheck: boolean = true;

  otpTimer: number = 0;
  countdown: string = '';
  redirectUrl: string = '';
 
  constructor(private otpService: OtpService,private router: Router, private activatedRoute: ActivatedRoute, private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    if(this.loginCheck){
      this.pageLoading = true;
      this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.loginEmail, bgColor: '#55BA45' };
      this.startOtpTimer();
      this.btnLoading = false;
      this.sendOtpState = false;
      this.verifyOtpState = true;
      this.waitToResend = true;
      this.pageLoading = false;
    }
  }

  loginVerifyOtp = () => {
    this.clicked = true;
    if (this.otpValue.toString().length == 6) {
      this.btnLoading = true;
      this.invalidOTP = false;
      this.expiredOTP = false;
      try {
        let payload = { email: this.loginEmail.toLowerCase(), otp: this.otpValue };
        this.otpService.verifyOtpFastApi(payload).subscribe({
          next: (verifyRes) => {
            this.btnLoading = false;
            if (verifyRes.body.access_token) {
              this.invalidOTP = false;
              this.clicked = false;
              let accessToken = {
                access_token: verifyRes.body.access_token,
                token_type: verifyRes.body.token_type
              }
              localStorage.setItem('tokenFastApi', JSON.stringify(accessToken));
              this.globalService.getUserProfileFastApi().subscribe({
                next: (res: any) => {
                  localStorage.removeItem('CompanyDetails');
                  const privilege = res.body.auth_user_permissions || [];
                  const hasEventAccess = privilege.includes('event.view') || privilege.includes('event.create');
                  const redirectPath = hasEventAccess ? '/events' : '/general-settings';
                  this.router.navigateByUrl(redirectPath);

                },
                error: (error) => {
                  console.log(error); 
                }
              });
            }
          },
          error: () => {
            this.infoMessage = { icon: 'pi pi-check', message: 'OTP expired or invalid, Please ', tryAgain: ' Try Again!', bgColor: '#ba4545' }
            this.btnLoading = false;
          }
        });
      } catch (error) {
        console.log('verify error', error)
      }

    } else {
      this.invalidOTP = true;
    }
  }

  resendOtp = (status: string) => {
    try {
      this.loadResend = true;
      let payload = {
        email: this.loginEmail.toLowerCase(),
      }
      this.otpService.sendOtpFastApi(payload).subscribe({
        next: (res: any) => {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.loginEmail, bgColor: '#55BA45' };
          this.loadResend = false;
          this.waitToResend = true;
          if (status != 'tryAgain') {
            this.startOtpTimer();
          }

        },
        error: () => {
          this.loadResend = false;
        }
    });
    } catch (error) {
      console.log('resend error',error)
    }
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;
    const intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  restrictNumber(event: any) {
    let e: any = event || window.event;
    let key = e.keyCode || e.which;

    if (key == 13) {
      this.loginVerifyOtp();
    }
    if (key < 48 || key > 57 || event.target.value.toString().length >= 6) {
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
  }
  otpValueChange(event: any) {
    if (event.length == 0 || event.length == 6) {
      this.invalidOTP = false;
      this.expiredOTP = false;
    }
  }
}
