import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { saveAs } from 'file-saver';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { MenuItem, MessageService } from 'primeng/api';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Tooltip } from 'primeng/tooltip';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { nanoid } from 'nanoid'
import { candidateStatus, statusColorMapping} from 'src/app/enum/candidate-status.enum';
import { HttpErrorResponse } from '@angular/common/http';

export enum CandidateStatus {
  applied = 'Applied',
  pending = 'Pending',
  selected = 'Selected',
  rejected = 'Rejected',
  archived = 'Archived',
  shortlisted = 'Shortlisted',
  addedToVettedPool = 'Added To Vetted Pool',
  submitted = 'Submitted',
  scheduled = 'Scheduled',
  allClear = 'All Clear',
  eligible = 'Eligible',
  attended = 'Attended',
  noteligible = 'Not Eligible',
  waitlisted = 'Waitlisted',
  noShow = 'No Show',
  slotSend = 'Slot Sent',
  submittedPartially = 'Submitted Partially',
  submissionPending = 'Submission Pending',
  partiallyApproved = 'Partially Approved',
  joiningConfirmed = 'Joining Confirmed',
  joined = 'Joined',
  notJoining = 'Not Joining',
  notJoined = 'Not Joined',
  yetToJoin = 'Yet To Join',
  mailPending = 'Mail Pending',
  offered = 'Offered',
  offerPending = 'Offer Pending',
  offerRevision = 'Offer Revision',
  offerAccepted = 'Offer Accepted',
  offerDeclined = 'Offer Declined',
  onHold = 'On Hold',
  futureCandidate = 'Future Candidate',
}

interface BulkUploadError {
  error_file?: string;
  detail?: string;
  force_submit?: boolean;
}
@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css']
})
export class CandidateListComponent implements OnInit {
  @ViewChild(Tooltip) tooltip!: Tooltip;
  // Booloan Var
  displayCandidates: boolean = true;
  displayJobDetails: boolean = false;
  displayWorkflow: boolean = false;
  displayInterview: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  displayUserDetails: boolean = false;
  isInEvent!: boolean;
  showBulkUpload: boolean = false;
  sendingInvite: boolean = false;
  clicked: boolean = false;
  uploadingFile: boolean = false;
  bulkFile: boolean = false;
  insideSearch: boolean = false;
  cohortUploaded: boolean = false;

  // Array Var
  candidateList: any = [];
  usersCol: Array<any> = [];
  searchEmailIds: Array<any> = [];
  _usersCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  selectedCandidates: Array<any> = [];
  selectedUser: Array<any> = [];
  candidateStatus: any = {}
  // statusColor: Array<any> = [];
  excel: File[] = [];
  stageDetails: Array<any> = [];
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  dateFormat: any;
  timeFormat: any;
  searchPayload: any;


  loadSingleInvite: boolean = false;
  loadExistingUser: boolean = false;
  userExist: boolean = false;
  countDiv: boolean = false;
  eventList: any = [];
  sourceTypeList: any = [];
  sourceNameList: any = [];
  existingUserList: any = [];
  existingUserColumns: any = []
  requiredColumns: Array<any> = [];
  existingRequiredColumns: Array<any> = [];
  errorFileData: any;
  errorFile: any;
  templateValue: Array<any> = [];
  counts: any;
  acknowledgeUsers: any;
  bulkAcknowledgeUsers: any = [];
  bulkValidatedData: any = [];
  existingUsers: any;
  mediaFile: File[] = [];
  loadEventList: boolean = false;
  allCategoryEvents: any;
  disableInvite: boolean = false;
  bulkEnable: boolean = false;
  fileMessage: any;
  uploadedFileName: any;
  cohortFileSize: any;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  numberOfStages: number = 0;
  totalPages: number = 1;

  // String Var
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'userList';
  selectedJobId: string = '';
  selectedEventId: string = '';
  bulkUpload!: MenuItem[];
  _selectedCategory: string = 'single-invite';
  // eventRadio: string = 'technical';
  userPrivileges: Set<string> = new Set();
  selectedJobName: string = '';
  schoolId: string = '';
  selectedEventName: string = '';
  schoolData: any;
  schoolCode: string = '';
  inviteUrl: string = ''
  eventId: string = '';
  eventName: string = '';
  eventCategory: string = '';
  link: string = '';
  category: any = localStorage.getItem("eventCategory");
  companyId: string = '';
  errorFileUrl: string | null = null;
  importSuccess: boolean = false;
  recruiterId: any;
  formId: string = '';
  privilege: any;
  userRole: string = '';
  order_by: string = 'asc';
  sort_by: string = 'candidate_uuid';

  singleInvite: FormGroup = new FormGroup({
    invites: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    emailID: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    source: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required)
  })

  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl(''),
    eventCategory: new FormControl(''),
    actionId: new FormControl('')
  })

  constructor(
    private jobService: JobServicesService,
    private eventService: EventServicesService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: NgDynamicBreadcrumbService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private globalService: GlobalServicesService
    ) {
    this.pageLimits = [10, 25, 50, 100];

    this.usersCol = [
      // { index: 1, field: 'degree', header: 'Education' },
      { index: 2, field: 'stage_name', header: 'Stage Name' },
      { index: 3, field: 'candidate_source', header: 'Source' },
      { index: 4, field: 'owner', header: 'Owner' },
      { index: 5, field: 'status', header: 'Status' },
      { index: 6, field: 'stage', header: 'Stage' },
      { index: 7, field: 'email', header: 'E-mail' },
      { index: 8, field: 'mobile_number', header: 'Phone' },
      { index: 9, field: 'gender', header: 'Gender', isCapitalize: true },
      { index: 10, field: 'dob', header: 'Date Of Birth' },
      { index: 11, field: 'created_at', header: 'Created On' },
      { index: 12, field: 'college_name', header: 'College Name' },
    ]

    this._selectedColumns = [
      // { index: 1, field: 'degree', header: 'Education' },
      { index: 2, field: 'stage_name', header: 'Stage Name' },
      { index: 5, field: 'status', header: 'Status' },
      { index: 6, field: 'stage', header: 'Stage' },
      { index: 7, field: 'email', header: 'E-mail' },
      { index: 8, field: 'mobile_number', header: 'Phone' },
      { index: 9, field: 'gender', header: 'Gender', isCapitalize: true },
    ]
  }

  columnSettings: FormGroup = new FormGroup({
    name: new FormControl(''),
    saved_columns: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.companyId = localStorage.getItem('companyUUID') ?? '';
    this.dateFormat = localStorage.getItem('company-details') ? JSON.parse(localStorage.getItem('company-details') || '{}').company_settings?.date_format : '';
    this.privilege = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
    this.userRole = this.globalService.privilegeRoles;
    this.globalService.privilegeDetailsSubject.subscribe((privileges) => {
      this.userPrivileges = privileges;
    });    

    this.selectedJobId = window.localStorage.getItem('selectedJobId') || '';
    this.selectedEventId = window.localStorage.getItem('selectedEventId') || '';
    this.selectedJobName = window.localStorage.getItem('selectedJobName') || '';
    this.selectedEventName = window.localStorage.getItem('selectedEventName') || '';
    // let category = localStorage.getItem("eventCategory");
    this.singleInvite.controls['eventCategory'].setValue(this.category);
    this.bulkInviteForm.controls['eventCategory'].setValue(this.category);
    this.isInEvent = this.selectedJobId === this.selectedEventId;
    this.recruiterId = localStorage.getItem('userDetailsFastApi') ? JSON.parse(localStorage.getItem('userDetailsFastApi') || '{}').user_profile?.id : '';
    this.getUsers();
    // this.getSavedColumns(); //pending to check
    this._usersCol = this.usersCol
    this.colsToDownload = this.usersCol;
    this.checkedColumns = this._selectedColumns;
    this.schoolData = localStorage.getItem('company-details')
    ? JSON.parse(localStorage.getItem('company-details') || '')
    : '';
    this.getSavedColumns()
    this.schoolId = this.schoolData.company_settings.school_code;
    this.schoolCode = this.schoolData.company_details.subdomain;

    this.bulkUpload = [
      {
        label: 'Send Invite', command: () => {
          this.bulkUp();
        }
      },
    ]

    this.candidateStatus = candidateStatus;

    this.routeChange();

    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.eventName = window.localStorage.getItem('selectedEventName') || '';

    this.setFormLink();
  }
  public getStatusColor(status: candidateStatus): string {
    return statusColorMapping[status] || 'transparent';
  }
  routeChange = async () => {
    let routeChanged = false;
    let tabView = '';
    this._router.events.subscribe(async (path) => {
      routeChanged = true;
      if (path instanceof NavigationEnd) {
        let pathView = path.urlAfterRedirects.split('=')
        tabView = pathView[1] || '';
        await this.tabSwitch(tabView);
      }
    })
    if (!routeChanged) {
      const snapshot: RouterStateSnapshot = this._router.routerState.snapshot;
      const queryParams = snapshot.root.queryParams;
      this.tabSwitch(queryParams['view']);
    };
  }

  tabSwitch = async (tabView: string) => {
    console.log(this.userRole)
    switch (tabView) {
      case 'candidates': {
        if (this.userRole == 'interviewer' || this.userRole == 'evaluator') {
          this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
        } else {
          this.displayCandidates = true;
          this.displayJobDetails = false;
          this.displayWorkflow = false;
          this.displayInterview = false;
        }
        break;
      }
      case 'jobDetails':
      case 'eventDetails': {
        if (this.userRole == 'interviewer' || this.userRole == 'evaluator') {
          this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
        } else {
          this.displayCandidates = false;
          this.displayJobDetails = true;
          this.displayWorkflow = false;
          this.displayInterview = false;
        }
        break;
      };
      case 'workflow': {
        this.displayCandidates = false;
        this.displayJobDetails = false;
        this.displayWorkflow = true;
        this.displayInterview = false;
        break;
      }
      case 'interview': {
        if (this.userRole == 'evaluator') {
          console.log("workflow")
          this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
        } else {
          this.displayCandidates = false;
          this.displayJobDetails = false;
          this.displayWorkflow = false;
          this.displayInterview = true;
        }
        break;
      }
    }
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    this.selectedJobName = window.localStorage.getItem('selectedJobName') || '';
    this.selectedEventName = window.localStorage.getItem('selectedEventName') || '';
    const breadcrumbs = [
      {
        label: this.isInEvent ? 'Manage Events' : 'Manage Drives',
        url: this.isInEvent ? 'events' : 'jobs'
      },
      {
        label: this.isInEvent ? this.selectedEventName : this.selectedJobName,
        url: ''
      },
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  // API Functions
  getUsers = () => {
    this.loadingSpinner = true;
    this.candidateList = [];
    let payload: any = {
      eventId: this.selectedJobId,
      page: this.pageNumber,
      size: this.pageSize,
      order_by: this.order_by,
      sort_by: this.sort_by
    };
    if (this.searchPayload?.search && this.searchPayload.search.length > 0) {
      payload['s'] = this.searchPayload.search.join(' ');
    }
    this.jobService.getCandidatesFastApi(payload.eventId, payload.page, payload.size, payload.order_by, payload.sort_by, payload.s).subscribe({
      next: (result: any) => {
        this.candidateList = result?.body ? result.body?.items : [];
        this.candidateList.forEach((candidate: any) => {
          candidate.id = candidate.candidate_uuid ?? '-';
          candidate._stageId = candidate.stage?.current_stage ?? '-';
          candidate.candidate_source = candidate.candidate_source ?? '-';
          candidate.owner = candidate.owner ? 
            `${candidate.owner.first_name ?? ''} ${candidate.owner.last_name ?? ''}`.trim() || '-' 
            : '-';
        });
        this.numberOfStages = this.candidateList.length > 0 ? this.candidateList[0].stage.total_stage : 0;
        this.totalLength = result.body.total || 0;
        this.first = result.body.page || 1;
        this.last = result.body.pages || 10;
        this.pageSize = result.body.size || 10;
        this.totalPages = result.body.pages || 1;
        this.loadingSpinner = false;
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
  }
  getUsersByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getUsers();
  };

  showUserDetails = (user: any) => {
    this.selectedUser = user;
    this.displayUserDetails = true;
  }
  setFormLink() {
    this.eventService.getSpecificEventFastApi(this.eventId).subscribe({
      next: (response: any) => {
        let eventDetails = response.body ? response.body.event_details : {};
        this.formId = eventDetails.form.uuid;
        this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/form/registration?eventId=${this.eventId}&formId=${this.formId}`;
      },
      error: (_err: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error fetching event details',
        });
      }
    });

    this.link = `${this.inviteUrl}`;
  }

  candidateSearch(event?: any) {
    this.loadingSpinner = true;
    this.searchEmailIds = (event.target.value).split(' ');
    this.searchPayload = {
      'search': this.searchEmailIds
    }
    this.getUsers();
  }


  sidebarClose() {
    this.singleInvite.reset();
    this.bulkInviteForm.reset();
    this.clicked = false;
    this.sendingInvite = false;
    this.cohortUploaded = false;
    this.uploadedFileName = '';
    this.cohortFileSize = '';
    this.excel = [];
    this.bulkFile = false;
    this.loadSingleInvite = false;
    this.countDiv = false;
    this.bulkEnable = false;
    this.importSuccess = false;
    this.errorFileUrl = '';

  }

  bulkSelected() {
    if (this._selectedCategory === 'bulk-invite') {
      this.clicked = false;
      if (this.excel.length == 0) {
        this.bulkFile = true;
      } else {
        this.bulkFile = false;
      }
    } else if (this._selectedCategory === 'single-invite') {
      this.clicked = false;
      this.bulkFile = false;
    }
  }

  sendInvite() {
    this.loadSingleInvite = true;
    this.errorFileUrl = null;
    if (this.cohortUploaded) {
      let payload = {
        file_name: this.uploadedFileName,
        recruiter_id: this.recruiterId
      }
      this.eventService.bulkInviteFastApi(payload, this.eventId).subscribe({
        next: (response: any) => {
          this.loadSingleInvite = false;
          this.importSuccess = true;
          this.errorFileUrl = null;
          this.uploadedFileName = '';
          this.cohortFileSize = '';
          this.cohortUploaded = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response.body.detail
          });
        },
        error: (error: HttpErrorResponse) => {
          this.loadSingleInvite = false;
          this.uploadedFileName = '';
          this.importSuccess = false;
          this.cohortFileSize = '';
          this.cohortUploaded = false;
          const errorResponse = error.error as BulkUploadError;
  
          if (errorResponse.error_file) {
            this.errorFileUrl = errorResponse.error_file;
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Some records have errors. Please check the error report.'
            });
            return;
          }
  
          if (errorResponse.detail === "File not found") {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'File not found. Please try uploading again.'
            });
            return;
          }
          if (error.status === 500) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Upload data in the provided sample format.'
            });
            return;
          }
  
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: errorResponse.detail || 'Something went wrong.'
          });
        }
      });
    }
    else {
      this.loadSingleInvite = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload a file to proceed!' });
    }
  }

  downloadErrorReport() {
    if (this.errorFileUrl) {
      window.open(this.errorFileUrl, '_blank');
    }
  }

  fileUpload(event: any) {
    const file = event.addedFiles[0];

    if (file.size > 2 * 1024 * 1024) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'File size should be less than 2MB'
      });
      return;
    }
    this.cohortFileSize = `${(file.size / 1024).toFixed(2)} KB`;
    const fileName = this.generateFileName(file);
    const filePath = `temp/${this.companyId}/${fileName}`;

    const payload = {
      file_path: filePath,
      bucket_type: 'private'
    };

    this.uploadingFile = true;
    this.fileUploadService.getSignedUrlGCS(payload).subscribe({
      next: (response: any) => {
        if (response && response.body.signed_url) {
          this.uploadFileToGCS(response.body.signed_url, file, filePath, fileName);
          
        }
      },
      error: (error) => {
        console.error('Error getting signed URL:', error);
        this.uploadingFile = false;
      }
    });
  }

  private generateFileName(file: any): string {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    const timestamp = new Date().getTime();
    return `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}_${timestamp}.${fileExtension}`;
  }

  uploadFileToGCS(signedUrl: string, file: any, filePath: string, filename: string) {
    this.fileUploadService.uploadUsingSignedUrlGCS(signedUrl, file).subscribe({
      next: (response: any) => {
        if (response && response.status === "success") {
          this.uploadingFile = false;
          this.uploadedFileName = filename;
          this.cohortUploaded = true;
        }
      },
      error: (error) => {
        console.error('Error uploading file to GCS:', error);
        this.uploadingFile = false;
      }
    });
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      name: this.columnSettings.get("name")?.value,
      saved_columns: this.columnsToSave
    });

    this.eventService.saveUserPreferencesFastApi(this.columnSettings.value, 'event_candidate').subscribe({
      next: (result: any) => {
        this.getSavedColumns();
        this.loadingSpinner = false;
        this.columnSettings.reset();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: result.body.detail,
        });
      },
      error: () => {
        this.loadingSpinner = false;
      }
    });
    this.displaySaveColumnSettings = false;
  }

  getSavedColumns = () => {
    this.eventService.getUserPreferencesFastApi('event_candidate').subscribe(res => {
      this.savedColumnSettings = res.body.user_preferences ? res.body.user_preferences : []
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.eventService.deleteUserPreferencesFastApi(columnId).subscribe({
      next: res => {
      this.getSavedColumns();
      this.loadingSpinner = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: res.body.detail,
      });
    },
    error: () => {
      this.loadingSpinner = false; // Handle error case
    }
  });
  }
  // Normal Functions
  addCandidateForm = () => {
    window.open(this.inviteUrl);
  }


  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  download() {
    window.open('assets/Book1.xlsx', 'Download');
  }

  bulkUp() {
    this.showBulkUpload = true;
    // this._selectedCategory = 'single-invite';
    // this.singleInvite.controls['eventCategory'].setValue(this.category);
    this.bulkInviteForm.controls['eventCategory'].setValue(this.category);
    this.setFormLink();
  }

  onRemoveExcel() {
    this.cohortUploaded = false;
    this.countDiv = false;
    this.bulkEnable = false;
    this.counts = 0;
    this.bulkAcknowledgeUsers = [];
    this.existingUsers = [];
    this.bulkValidatedData = [];
    this.acknowledgeUsers = [];
    this.uploadedFileName = '';
    this.importSuccess = false;
  }

  errorReportDownload() {
    window.open(this.errorFile, '_blank')
  }

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  }

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  }

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._usersCol = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.usersCol.filter(e => savedColumnValue.includes(e.field));
  }

  showCandidates = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'candidates' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'candidates' } })
    this.routeChange();
  }

  showJobDetails = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'eventDetails' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'jobDetails' } })
    this.routeChange();
  }

  showWorkflow = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'workflow' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
    this.routeChange();
  }

  showInterview = async () => {
    if (this.isInEvent) await this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'interview' } })
    else await this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'interview' } })
    this.routeChange();
  }
  onSelectedCandidateChange= (value: []) => {
    this.selectedCandidates = value;
  };

  exportCandidate = (type: string) => {
    let checkedColumnFields: any = ['name', 'id'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    const dataToExport = this.selectedCandidates.length > 0 ? this.selectedCandidates : this.candidateList;
    dataToExport.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        if (item === 'stage') {
          filterData['total_stage'] = eachData.stage?.total_stage ?? '-';
          filterData['current_stage'] = eachData.stage?.current_stage ?? '-';
        } else {
          eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
        }
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  sampleData() {
    this.eventService.bulkInviteFileDownloadFastApi().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const contentDisposition = res.headers.get('content-disposition');
          let filename = 'bulk_invite_data.xlsx';
          if (contentDisposition) {
            const matches = contentDisposition.match(/filename="(.+)"/);
            if (matches?.[1]) {
              filename = matches[1];
            }
          }
          saveAs(blob, filename);
        }
      },
      error: (err) => {
        console.error('Error downloading the file:', err);
      }
    });

  }

  // Pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getUsers();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? (this.last == this.totalLength ? this.totalLength - this.candidateList.length : this.last - this.pageSize) : this.pageSize;
    this.pageNumber -= 1;
    this.getUsers();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.usersCol.filter(col => val.includes(col));
  }

  getArray(num: number) {
    return new Array(num);
  }

  copyMessage() {
    let val = new URL(this.inviteUrl).href;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.tooltip.activate();
  }
  onKeyPress(event: KeyboardEvent, user: any) {
    if (event.key === 'Enter' || event.key === ' ') {
        this.showUserDetails(user);
    }
}
}
