import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      Origin: window.location.origin,
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  getSignedUrl(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/getSignedUrl',payload, this.options());
  }

  uploadUsingSignedUrl(url: string, payload: any) {
    return this.http.put(url, payload, { observe: 'events' });
}
  getSignedDownloadUrl(payload: any):  Observable<any> {
    return this.http.post(this.api_url + '/getSignedUrl/download',payload, this.options());
}

getFileByUrl(payload: any)
{
  return this.http.get(payload, this.options());
}
}
