import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-handle-error',
  templateUrl: './handle-error.component.html',
  styleUrls: ['./handle-error.component.css']
})
export class HandleErrorComponent implements OnInit {

  @Input() control: any;
  @Input() name: string = '';
  @Input() clicked: boolean = false;
  @Input() showSampleValues: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  getSampleValue(): string {
    if (!this.control || !this.control.parent) {
      return 'N/A';
    }

    const formGroup = this.control.parent as FormGroup;
    const sampleValues = formGroup.get('sampleValues') as FormGroup;

    if (!sampleValues) {
      return 'N/A';
    }

    let controlName = Object.keys(formGroup.controls).find(key => formGroup.controls[key] === this.control);

    if (!controlName) {
      return 'N/A';
    }
    return sampleValues.get(controlName)?.value || 'N/A';
  }


}
