import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators,AbstractControl, ValidationErrors } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';
import * as locationsList from '../../../assets/Json/locationsList.json'
import { LoginService } from 'src/app/services/login.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { RoleServicesService } from 'src/app/services/role-services.service';

@Component({
  selector: 'app-primary-account',
  templateUrl: './primary-account.component.html',
  styleUrls: ['./primary-account.component.css'],
  providers: [MessageService]
})
export class PrimaryAccountComponent implements OnInit {

  userName: any;
  userRole: any;
  userPrivilege: any;
  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  branchData: any = [];
  currentEmailData: any = [];
  newEmailData: any = [];
  userDetails: any = [];
  module_slug_list: any = [];
  _module_slug_list: any = [];
  moduleList: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  showOtpField: boolean = false;
  verifiedMail: boolean = false;
  isLoading: boolean = true;
  selectedModule: string = '';
  emailForm: FormGroup = new FormGroup({
    oldEmail: new FormControl('', Validators.email),
    newEmail: new FormControl('', Validators.email),
    Otp: new FormControl(),
    emailChange: new FormControl()
  })

  passwordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(),
    newPassword: new FormControl(),
    confirmPassword: new FormControl()
  }, { validators: this.passwordMatchValidator })

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const formGroup = control as FormGroup;
    const newPassword = formGroup.get('newPassword')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { passwordMismatch: true };
  }

  accountDetails: FormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    mobile: new FormControl('', Validators.pattern('^[0-9]{10}$')),
    primaryLocation: new FormControl(),
  })

  sidebarAction: string = '';
  sideActionIcon: string = '';
  otp: string = '';
  emailEdit: string = '';
  passwordEdit: string = '';

  verifiedCurrentEmail: boolean = true;
  currentPassShow: boolean = false;
  newPassShow: boolean = false;
  confirmPassShow: boolean = false;
  clicked: boolean = false;

  passwordMessage: string = '';
  primaryLocation: string = '';
  primaryLocationList: any = (locationsList as any).default;

  constructor(private settings: SettingsService, private messageService: MessageService, private loginService: LoginService, private settingService: SettingsService, private globalService: GlobalServicesService,private roleService: RoleServicesService) { }

  ngOnInit(): void {
    const userDetails = localStorage.getItem('userDetailsFastApi');
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      this.userRole = parsedUserDetails.role_details || '';
      this.userName = parsedUserDetails.user_profile || '';
    } else {
      this.userRole = '';
      this.userName = '';
    }
    this.userPrivilege = localStorage.getItem('userPrivilegeFastApi') ? JSON.parse(localStorage.getItem('userPrivilegeFastApi') || '') : '';
    this.getAccountData();
    this.roleAndPermissionFormData();
  }
  roleAndPermissionFormData(){
    this.isLoading = true;
    this.roleService.getRoleAndPermissionFormDataFastApi().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        // this.module_slug_list = res.body.permissions;
        this.module_slug_list = [
          {
            "label": "Roles",
            "slug": "role",
            "value": [
              {
                "id": 1,
                "name": "View Roles",
                "slug": "role.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 2,
                "name": "Create Roles",
                "slug": "role.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 3,
                "name": "Update Roles",
                "slug": "role.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 4,
                "name": "Delete Roles",
                "slug": "role.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Role Types",
            "slug": "role_type",
            "value": [
              {
                "id": 5,
                "name": "View Role Types",
                "slug": "role_type.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 6,
                "name": "Create Role Types",
                "slug": "role_type.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 7,
                "name": "Update Role Types",
                "slug": "role_type.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 8,
                "name": "Delete Role Types",
                "slug": "role_type.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Event Categories",
            "slug": "event_category",
            "value": [
              {
                "id": 9,
                "name": "View Event Categories",
                "slug": "event_category.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 10,
                "name": "Create Event Categories",
                "slug": "event_category.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 11,
                "name": "Update Event Categories",
                "slug": "event_category.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 12,
                "name": "Delete Event Categories",
                "slug": "event_category.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Event Types",
            "slug": "event_type",
            "value": [
              {
                "id": 13,
                "name": "View Event Types",
                "slug": "event_type.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 14,
                "name": "Create Event Types",
                "slug": "event_type.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 15,
                "name": "Update Event Types",
                "slug": "event_type.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 16,
                "name": "Delete Event Types",
                "slug": "event_type.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Users",
            "slug": "user",
            "value": [
              {
                "id": 17,
                "name": "View Users",
                "slug": "user.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 18,
                "name": "Create Users",
                "slug": "user.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 19,
                "name": "Update Users",
                "slug": "user.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 20,
                "name": "Delete Users",
                "slug": "user.delete",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 21,
                "name": "Export Users",
                "slug": "user.export",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Email Categories",
            "slug": "email_category",
            "value": [
              {
                "id": 22,
                "name": "View Email Categories",
                "slug": "email_category.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 23,
                "name": "Create Email Categories",
                "slug": "email_category.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 24,
                "name": "Update Email Categories",
                "slug": "email_category.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 25,
                "name": "Delete Email Categories",
                "slug": "email_category.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Email Templates",
            "slug": "email_template",
            "value": [
              {
                "id": 26,
                "name": "View Email Templates",
                "slug": "email_template.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 27,
                "name": "Create Email Templates",
                "slug": "email_template.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 28,
                "name": "Update Email Templates",
                "slug": "email_template.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 29,
                "name": "Delete Email Templates",
                "slug": "email_template.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          },
          {
            "label": "Candidate Source/Types",
            "slug": "candidate_source",
            "value": [
              {
                "id": 30,
                "name": "View Candidate Source/Types",
                "slug": "candidate_source.view",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 31,
                "name": "Create Candidate Source/Types",
                "slug": "candidate_source.create",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 32,
                "name": "Update Candidate Source/Types",
                "slug": "candidate_source.update",
                "isChecked": false,
                "isDisabled": false
              },
              {
                "id": 33,
                "name": "Delete Candidate Source/Types",
                "slug": "candidate_source.delete",
                "isChecked": false,
                "isDisabled": false
              }
            ],
            "permissions": [],
            "isSelectedAll": false
          }
        ];
        this.module_slug_list.forEach((module: any) => {
          module.value.forEach((slugValue: any) => {
            slugValue.isChecked = this.userPrivilege.includes(slugValue.slug);
          });
        });
        this.moduleList = this.module_slug_list.map((module: any) => {
          return { label: module.label, value: module.slug };
        });
      },
      error: (error:any) => {
        this.isLoading = false;
        this.globalService.commonGrowl.next({ severity: 'error', summary: 'Error', detail: error.message });
      }
    });
  }

  getAccountData() {
    this.isLoading = true;
    const userDetails = localStorage.getItem('userDetailsFastApi')
      ? JSON.parse(localStorage.getItem('userDetailsFastApi') || '').user_profile
      : '';
    // this.loginService.isLoggedIn(userDetails.email).subscribe(
    //   (res: any) => {
    // this.userDetails = res.data ? res.data : [];
    this.userInfo = [
      { field: "Email/Username", value: userDetails.email ?? '--' },
      { field: "Password", value: '**********' ?? '--' },
      { field: "Phone/Mobile", value: userDetails.mobile_number ?? '--' },
    ]
    this.isLoading = false;
    // });
    return true;
  }

  openAddTemplate(template: string, icon: string) {
    this.sidebarAction = template;
    this.accountDetails.get('email')?.disable();
    this.accountDetails.get('password')?.disable();
    this.sideActionIcon = icon;
    this.addTemplateSidebar = true;
    this.emailEdit = this.userInfo[0]?.value;
    this.passwordEdit = this.userInfo[1]?.value;
    this.accountDetails.controls['mobile'].setValue(this.userDetails.mobile_number);
    this.primaryLocation = this.userInfo[3]?.value;
  }


  saveTemplate = () => {
    this.saveBtnLoading = true;
    let payload = {
      schoolId: localStorage.getItem('schoolData') ? JSON.parse(localStorage.getItem('schoolData') || '').school_id : '',
      userId: localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails') || '').id : '',
      mobile_number: this.accountDetails.controls['mobile'].value,
      email: this.userName.email
    };
    if (!this.accountDetails.controls['mobile'].errors) {
      this.settingService.updateAccount(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved Successfully', })
          setTimeout(() => {
            this.getAccountData();
            this.addTemplateSidebar = false;
          }, 1000)
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went Wrong!', })
        }
        this.saveBtnLoading = false;

      });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Invalid Mobile Number!', })
      this.saveBtnLoading = false;
    }
  }

  onOtpChange(e: any) {
    this.otp = e
  }

  onConfigChange() {
    this.verifiedMail = false;
    this.showOtpField = false;
    this.otp = '';
  }

  showPassword(selector: any) {
    let x: any = document.getElementsByClassName(selector)[0];
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  verifyEmail() {
    if (!this.emailForm.controls['newEmail'].errors) {
      this.verifiedMail = true;
      let newEmail = {
        'new_email': this.emailForm.controls['newEmail'].value
      };
      this.settings.emailVerifyFastApi(newEmail).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
            this.showOtpField = true;
            this.newEmailData = res.body;
          }
        },
      });
      

    }
  }

  verifyOtp() {
    let newPayload = {
      new_email: this.emailForm.controls['newEmail'].value,
      otp: this.otp
    }
    this.onConfigChange();
    this.settings.emailUpdateFastApi(newPayload).subscribe({
      next: (res: any) => {
        if (res.status === 201) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
          this.onOtpChange(" ");
          this.emailForm.reset();
          this.verifiedCurrentEmail = false;
          this.onConfigChange();
          this.addTemplateSidebar = false;
          this.sidebarAction = 'Edit Primary Account';
          // Update user profile in local storage
          // this.globalService.getUserProfileFastApi().subscribe({
          //   next: (profileRes: any) => {
          //     if (profileRes.status === 200) {
          //       let companyDetails = { company_metadata: profileRes.body.company_metadata,
          //         company_settings: profileRes.body.company_settings,
          //         company_details: profileRes.body.company_details,
          //       };
          //       localStorage.setItem('company-details', JSON.stringify(companyDetails));
          //     }
          //   },
          // });
        }
      },
    });
    

  }
  changePassword(){
    console.log("🚀🔥 ~ file: primary-account.component.ts:206 ~ PrimaryAccountComponent ~ changePassword ~ newPayload.this.passwordForm:", this.passwordForm)
    if(this.passwordForm.valid){
      let newPayload = {
        old_password: this.passwordForm.controls['currentPassword'].value,
        new_password: this.passwordForm.controls['newPassword'].value,
        confirm_new_password: this.passwordForm.controls['confirmPassword'].value
      }
      this.onConfigChange();
      this.settings.changePasswordFastApi(newPayload).subscribe({
        next: (res: any) => {
          if (res.status === 201) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.detail });
            this.passwordForm.reset();
            this.sidebarAction = 'Edit Primary Account';
      
            // Fetch and update user profile
            // this.globalService.getUserProfileFastApi().subscribe({
            //   next: (profileRes: any) => {
            //     if (profileRes.status === 200) {
            //       let companyDetails = { company_metadata: profileRes.body.company_metadata,
            //         company_settings: profileRes.body.company_settings,
            //         company_details: profileRes.body.company_details,
            //       };
            //       localStorage.setItem('company-details', JSON.stringify(companyDetails));
            //     }
            //   },
            // });
          }
        },
      });
      
    }else{
      this.clicked = true;
      this.passwordMessage = "Please fill all the fields correctly";
    }
  }
  getModuleSlugList(){
    this._module_slug_list = this.module_slug_list.filter((module: any) => module.slug === this.selectedModule);
    console.log("🚀🔥 ~ file: role-and-permission.component.ts:496 ~ RoleAndPermissionComponent ~ getModuleSlugList ~ this.roleAndPermissionForm.value.module:", this.selectedModule)
  }
}